import React from "react";

import { CommandKContext, ICommandKContextInternal } from "~src/shared/commandK/types";

export const useCommandKInternal = (): ICommandKContextInternal => {
  const ctx = React.useContext(CommandKContext);
  if (ctx === null) {
    throw new Error("Not in a Command K context.");
  }
  return ctx;
};
