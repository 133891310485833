export type ISortOrder = "asc" | "desc";

export enum IFilterOp {
  after = "$after",
  before = "$before",
  contains = "$contains",
  eq = "$eq",
  gt = "$gt",
  gte = "$gte",
  in = "$in",
  is_false = "$is_false",
  is_missing = "$is_missing",
  is_true = "$is_true",
  lt = "$lt",
  lte = "$lte",
  neq = "$neq",
  nin = "$nin",
}

export interface IFilter {
  label: string;
  operator: IFilterOp;
  unary?: boolean;
}

export const Filters: readonly IFilter[] = [
  { label: "Is equal to", operator: IFilterOp.eq },
  { label: "Is in", operator: IFilterOp.in },
  { label: "Is not equal to", operator: IFilterOp.neq },
  { label: "Is not in", operator: IFilterOp.nin },
  { label: "Is greater than", operator: IFilterOp.gt },
  { label: "Is greater than or equal to", operator: IFilterOp.gte },
  { label: "Is less than", operator: IFilterOp.lt },
  { label: "Is less than or equal to", operator: IFilterOp.lte },
  { label: "Is false", operator: IFilterOp.is_false, unary: true },
  { label: "Is true", operator: IFilterOp.is_true, unary: true },
  { label: "Is missing", operator: IFilterOp.is_missing, unary: true },
  { label: "Is older than", operator: IFilterOp.before },
  { label: "Is newer than", operator: IFilterOp.after },
];
