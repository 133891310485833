import { Maybe, permission_enum_enum, user_type_enum_enum } from "~src/__generated__/graphql/types";
import {
  IGOATItemsGetRequest,
  ISortAndFilterListItemsArgs,
} from "~src/shared/legacyGOAT/GOAT/networkTypes";
import { IGOATConfig, ILegacyGOATModel } from "~src/shared/legacyGOAT/GOAT/types";
import { IListModel } from "~src/shared/lists/types/models";
import { INetworkRequest } from "~src/shared/requests/types";
import {
  ICountryCode,
  IIntegrationType,
  IVendor,
  IVendorSalesFunnelStageEnum,
} from "~src/shared/types";

type Empty = Record<string, never>;

export type IRange = Array<{
  value?: string | null;
  count: number;
}>;

export type IGetUserProfileData = Readonly<{
  publicID: string;
  email: string;
  name: string;
  userType: user_type_enum_enum;
  permissions: permission_enum_enum[];
  vendorPublicID: string;
  vendor: Readonly<{
    id: string;
    clientID: string;
    country: Maybe<ICountryCode>;
    isActivated: boolean;
    salesFunnelStage: IVendorSalesFunnelStageEnum | null;
    supportedCurrencies: string[];
  }>;
  investor?: Readonly<{
    id: string;
    clientID: string;
    name: string;
    isDemo: boolean;
  }>;
  flagz: Record<string, boolean> | null;

  /**
   * Timestamp of when the demo was completed.
   */
  completedDemoAt: string | null;
  hasMultiFactorEnabled: boolean;
}>;

/**
 * TODO: This file contains both vendor-only requests and requests for both vendors and
 * admins. Take the requests for both vendors and admins and move them to the
 * `shared/requests` package.
 */

const makeAuthedRequest =
  <Input, Output>(action: string) =>
  (input: Input): INetworkRequest<Input, Output> => ({
    action,
    type: "authed",
    input,
  });

export const authedRequests = {
  // 2FA
  initiateTOTP: makeAuthedRequest<
    { password: string },
    { totpSecret: string; qrImageBase64: string }
  >("multifactor.totp.initiate"),

  completeTOTP: makeAuthedRequest<{ totpCode: string }, { recoveryCodes: Array<string> }>(
    "multifactor.totp.complete",
  ),

  deleteTOTP: makeAuthedRequest<{ password: string }, Empty>("multifactor.totp.delete"),

  // Demo restoration
  checkBackupSession: makeAuthedRequest<Empty, { valid: boolean }>("session.check"),
  restoreBackupSession: makeAuthedRequest<Empty, never>("session.restore"),

  // GOAT
  getGOATItems:
    makeAuthedRequest<
      IGOATItemsGetRequest,
      {
        publicIDs: string[];
      }
    >("goat.items.get"),
  countGOATItems:
    makeAuthedRequest<
      ISortAndFilterListItemsArgs,
      {
        count: number;
      }
    >("goat.items.count"),

  // TODO: Make these user-type-specific later.
  getVendor: makeAuthedRequest<Empty, IVendor>("vendor.get"),
  getUserProfile: makeAuthedRequest<Empty, IGetUserProfileData>("profile.get"),

  logout: makeAuthedRequest<Empty, Empty>("auth.logout"),

  duplicateList:
    makeAuthedRequest<
      {
        parentPublicID: string;
        title: string;
        slug: string;
        config?: IGOATConfig;
      },
      { publicID: string; slug: string }
    >("list.duplicate"),

  deleteList:
    makeAuthedRequest<
      {
        publicIDs: string[];
      },
      Empty
    >("list.delete"),

  changePassword: makeAuthedRequest<
    {
      currentPassword: string;
      newPassword: string;
      totpCode: string;
    },
    never
  >("settings.change_password"),

  getListRange:
    makeAuthedRequest<
      ISortAndFilterListItemsArgs & {
        /**
         * The name of the field to get the range of.
         */
        name: string;
      },
      {
        /**
         * All potential values in the list for the field.
         */
        values: IRange;
      }
    >("list.range.get"),

  createList:
    makeAuthedRequest<
      {
        model: ILegacyGOATModel | IListModel;
        parentPublicID?: string;
        title?: string;
        slug?: string;
        isEphemeral?: boolean;
        isLocked?: boolean;
        // TODO: Why do we have any here?
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        config?: any;
      },
      {
        publicID: string;
        slug: string;
      }
    >("list.create"),

  /**
   * Finalizes file uploads.
   * TODO: Make two copies--one for vendor, one for admin.
   */
  uploadIntegrationFiles: makeAuthedRequest<
    {
      integration: IIntegrationType;
      files: readonly string[];
    },
    never
  >("integration_files.upload"),
};
