import styled from "@emotion/styled";
import React, { RefObject } from "react";

import { ICommandKSection } from "../types/internal";
import { CommandKContentOption } from "./CommandKContentOption";

interface IProps {
  section: ICommandKSection;
  startIndex: number;
  selectedIndex?: number;
  growRef: RefObject<HTMLDivElement>;
  isLastSection: boolean;
}

export const CommandKContentSection: React.FC<IProps> = ({
  section,
  startIndex,
  selectedIndex,
  growRef,
  isLastSection,
}) => {
  return (
    <Wrapper>
      <h3>{section.title}</h3>
      <Elements>
        {section.options.map((option, i) => (
          <CommandKContentOption
            key={option.id}
            index={startIndex + i}
            option={option}
            section={section}
            isLastElement={isLastSection && i === section.options.length - 1}
            isSelected={i === selectedIndex}
            growRef={growRef}
          />
        ))}
      </Elements>
    </Wrapper>
  );
};

const Elements = styled.div`
  display: grid;
  grid-row-gap: 4px;
  grid-auto-flow: row;
`;

const Wrapper = styled.div`
  h3 {
    color: ${(props) => props.theme.old.text.muted};
    ${{
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "28px",
    }};
  }
`;
