import React from "react";

import { ICommandKOption, ICommandKTile } from "~src/shared/commandK/types/internal";
import { IEditableList, IListFilter, IListInfo } from "~src/shared/lists/types";
import { IFilterOp } from "~src/shared/lists/types/operators";

/**
 * The modes available.
 */
export enum ICommandKMode {
  COMMANDS = "COMMANDS",

  LIST_EDIT_COLUMNS = "LIST_EDIT_COLUMNS",
  LIST_FILTER = "LIST_FILTER",
  LIST_FILTER_SELECT_OPERAND = "LIST_FILTER_SELECT_OPERAND",
  LIST_FILTER_SELECT_OPERATOR = "LIST_FILTER_SELECT_OPERATOR",
  LIST_SEARCH = "LIST_SEARCH",
}

/**
 * Arguments required to pass into Command K.
 *
 * NB(usmanm): these type escape hatches are added because typing the entire
 * list builder + cmd K interface is a bunch of work. It's not production
 * ready code so keeping this as is. When we get to actually rolling this out
 * we should clean up the types.
 */
export type ICommandKModeArgs<T extends ICommandKMode> = {
  [ICommandKMode.COMMANDS]: null;
  [ICommandKMode.LIST_FILTER]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    list: IEditableList<any>;
  };
  [ICommandKMode.LIST_EDIT_COLUMNS]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    list: IEditableList<any>;
  };
  [ICommandKMode.LIST_FILTER_SELECT_OPERATOR]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    list: IEditableList<any>;
    column: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    existingFilter?: IListFilter<any>;
  };
  [ICommandKMode.LIST_FILTER_SELECT_OPERAND]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    list: IEditableList<any>;
    column: string;
    operator: IFilterOp;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    existingFilter?: IListFilter<any>;
  };
  [ICommandKMode.LIST_SEARCH]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lists: readonly IListInfo<any>[];
  };
}[T];

export interface IModeState<M extends ICommandKMode> {
  mode: M;
  args: ICommandKModeArgs<M>;
}

/**
 * Holds all state of Command K.
 */
export interface ICommandKState {
  isVisible: boolean;
  searchText: string;
  /**
   * The index of the option currently selected.
   */
  selectedOptionIndex: number;

  searchPlaceholder?: string;
  header: readonly ICommandKTile[];
  /**
   * The previous modes. Used for back button. Stack.
   */
  modeHistory: readonly IModeState<ICommandKMode>[];
  /**
   * Current mode
   */
  modeState?: IModeState<ICommandKMode>;
}

export interface ICommandKContext {
  /**
   * Shows the command modal.
   */
  show: <M extends ICommandKMode>(mode: IModeState<M>, searchText?: string) => void;
  /**
   * Replace the current mode of the command modal.
   */
  replace: <M extends ICommandKMode>(mode: IModeState<M>) => void;
  /**
   * Hides the modal.
   */
  hide: () => void;
  /**
   * Toggles the modal on and off, using the default mode.
   */
  toggle: () => void;

  isVisible: () => boolean;
  currentMode: () => ICommandKMode | undefined;
}

export interface ICommandKContextInternal extends ICommandKContext {
  state: ICommandKState;
  setSearch: (v: string) => void;
  setSelectedOptionIndex: (i: number) => void;
  back: () => void;
  selectOption: (option: ICommandKOption) => void;
  onOptionChecked: (option: ICommandKOption, checked: boolean) => void;
}

export const CommandKContext = React.createContext<ICommandKContextInternal | null>(null);
