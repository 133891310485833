import { OverlayContainer } from "@react-aria/overlays";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";

import { Dialog } from "~src/designSystem/molecules/Dialog";
import {
  getTopOfStepperStack,
  useStepper,
  useStepperState,
} from "~src/shared/stepper/stepperContext";

export const Stepper: React.FC = () => {
  const { stack } = useStepperState();
  const topOfStack = getTopOfStepperStack(stack);
  const { variation, currentStep, clearStepperStack } = useStepper();

  return (
    <>
      <AnimatePresence>
        {topOfStack !== undefined && (
          <OverlayContainer>
            <Dialog
              title={currentStep()?.config?.title ?? ""}
              isOpen
              onClose={currentStep()?.config?.onCloseOverride ?? clearStepperStack}
              isDismissable
              variant={variation}
              sideColumn={currentStep()?.config?.sideColumn}
            >
              {/* TODO: Should make this into a StepperContent + and add animations there */}
              {stack.map((item, i) => {
                return (
                  <AnimatePresence key={`item-${i}`}>
                    <motion.div
                      key="stepper-content"
                      initial={{ opacity: 0, x: "100vw" }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{
                        duration: 0.2,
                        transform: "cubic-bezier(0.18, 0.96, 0.1, 0.99)",
                      }}
                    >
                      <div key={i} hidden={item !== topOfStack}>
                        {item.component}
                      </div>
                    </motion.div>
                  </AnimatePresence>
                );
              })}
            </Dialog>
          </OverlayContainer>
        )}
      </AnimatePresence>
    </>
  );
};
