/* eslint-disable react/style-prop-object */
import React from "react";

export const OperatorIntersection: React.FC = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.875 2.25C7.185 2.25 6.555 2.445 6 2.7675C5.445 2.445 4.815 2.25 4.125 2.25C2.055 2.25 0.375 3.93 0.375 6C0.375 8.07 2.055 9.75 4.125 9.75C4.815 9.75 5.445 9.555 6 9.2325C6.555 9.555 7.185 9.75 7.875 9.75C9.945 9.75 11.625 8.07 11.625 6C11.625 3.93 9.945 2.25 7.875 2.25ZM4.8 8.1375C4.5825 8.205 4.3575 8.25 4.125 8.25C2.88 8.25 1.875 7.245 1.875 6C1.875 4.755 2.88 3.75 4.125 3.75C4.3575 3.75 4.5825 3.795 4.8 3.8625C4.3725 4.47 4.125 5.205 4.125 6C4.125 6.795 4.3725 7.53 4.8 8.1375ZM7.2 8.1375C7.4175 8.205 7.6425 8.25 7.875 8.25C9.12 8.25 10.125 7.245 10.125 6C10.125 4.755 9.12 3.75 7.875 3.75C7.6425 3.75 7.4175 3.795 7.2 3.8625C7.6275 4.47 7.875 5.205 7.875 6C7.875 6.795 7.6275 7.53 7.2 8.1375Z"
    />
  </svg>
);
