/* eslint-disable react/style-prop-object */
import React from "react";

export const IconBackspace: React.FC = () => (
  <svg
    width="13"
    height="10"
    viewBox="0 0 13 10"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.67032 0.7175L12.4203 4.4675C12.5553 4.6025 12.6378 4.79 12.6378 5C12.6378 5.21 12.5553 5.3975 12.4203 5.5325L8.67032 9.2825C8.53532 9.4175 8.34782 9.5 8.13782 9.5H1.38782C0.975317 9.5 0.637817 9.1625 0.637817 8.75V1.25C0.637817 0.8375 0.975317 0.5 1.38782 0.5H8.13782C8.34782 0.5 8.53532 0.5825 8.67032 0.7175ZM7.38782 6.5C7.38782 6.29 7.30532 6.1025 7.17032 5.9675L6.19532 5L7.17032 4.0325C7.30532 3.8975 7.38782 3.71 7.38782 3.5C7.38782 3.0875 7.05032 2.75 6.63782 2.75C6.42782 2.75 6.24032 2.8325 6.10532 2.975L5.13782 3.9425L4.17032 2.9675C4.03532 2.8325 3.84782 2.75 3.63782 2.75C3.22532 2.75 2.88782 3.0875 2.88782 3.5C2.88782 3.71 2.97032 3.8975 3.10532 4.0325L4.08032 5L3.10532 5.9675C2.97032 6.1025 2.88782 6.29 2.88782 6.5C2.88782 6.9125 3.22532 7.25 3.63782 7.25C3.84782 7.25 4.03532 7.1675 4.17032 7.025L5.13782 6.0575L6.10532 7.0325C6.24032 7.1675 6.42782 7.25 6.63782 7.25C7.05032 7.25 7.38782 6.9125 7.38782 6.5Z"
    />
  </svg>
);
