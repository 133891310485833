import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { RefObject, useEffect, useRef } from "react";

import { Checkbox } from "~src/designSystem/atoms/Checkbox";

import { useCommandKInternal } from "../hooks/useCommandKInternal";
import { ICommandKOption, ICommandKSection, ICommandKSectionType } from "../types/internal";

interface IProps {
  option: ICommandKOption;
  section: ICommandKSection;
  index: number;
  isSelected: boolean;
  isLastElement: boolean;
  growRef: RefObject<HTMLDivElement>;
}

export const CommandKContentOption: React.FC<IProps> = (props) => {
  const { option, isSelected, section, index, growRef, isLastElement } = props;
  const { setSelectedOptionIndex, selectOption, onOptionChecked } = useCommandKInternal();
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isSelected) {
      if (growRef.current === null || ref.current === null) {
        return;
      }
      const { offsetTop } = growRef.current;
      const { scrollTop } = growRef.current;

      const optionTop = ref.current.offsetTop;

      // check if this is the last element in the entire list
      if (isLastElement) {
        ref.current.scrollIntoView();
        // handle scrolling up
      } else if (optionTop < offsetTop + scrollTop) {
        ref.current.scrollIntoView();

        // handle scrolling down
      } else if (
        optionTop + ref.current.clientHeight >
        offsetTop + scrollTop + growRef.current.clientHeight
      ) {
        ref.current.scrollIntoView(false);
      }
    }
  }, [isSelected, isLastElement, growRef]);

  return (
    <OptionWrapper
      ref={ref}
      isSelected={isSelected}
      onClick={() => selectOption(option)}
      onMouseEnter={() => setSelectedOptionIndex(index)}
    >
      <OptionLabel>
        {section.type === ICommandKSectionType.CHECKBOXES_REORDERABLE && (
          <Checkbox
            checked={option.checked === true}
            onChange={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onOptionChecked(option, e.target.checked);
            }}
          />
        )}
        <IconWrapper>{option.icon}</IconWrapper>
        <span>{option.label}</span>
      </OptionLabel>
      <OptionDescription>{option.description}</OptionDescription>
    </OptionWrapper>
  );
};

const OptionDescription = styled.div`
  color: ${(props) => props.theme.old.text.muted};
`;

const IconWrapper = styled.div`
  margin-top: 2px;
  margin-right: 8px;
  color: ${(props) => props.theme.old.icon.default};
  svg {
    height: 12px;
    width: 12px;
  }
`;

const OptionLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const OptionWrapper = styled.div<{ isSelected: boolean }>`
  height: 36px;
  border-radius: 3px;
  padding: 0 12px;
  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${props.theme.old["command-k"]["option-hover"]};
    `}
  :hover {
    cursor: pointer;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
`;
