import data from "public/static/assets/animations/maintenance.json";
import React from "react";
import Lottie from "react-lottie-wrapper";

export const MaintenanceImg: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Lottie
      options={defaultOptions}
      width="300px"
      height="initial"
      isStopped={false}
      isPaused={false}
    />
  );
};
