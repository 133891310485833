export enum IPipeRouter {
  /**
   * User is admin
   */
  ADMIN = "admin",

  /**
   * User is investor
   */
  INVESTOR = "investor",

  /**
   * Unauthenticated
   */
  PUBLIC = "p",

  /**
   * User is vendor
   */
  VENDOR = "vendor",
}
