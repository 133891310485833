// Forked from https://github.com/holgerthorup/date-picker
//
// TODO(usmanm): this should be rewritten for clarity. I made enough changes
// that this seems to work, but may need extra work post QA.

import { parse, parseDate as chronoParseDate, ParsedComponents } from "chrono-node";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { sortBy, unionBy } from "lodash";

import { stringIsEmpty } from "~src/shared/helpers/booleanCoercion";

dayjs.extend(quarterOfYear);

type Suggestion = { label: string; date: dayjs.Dayjs };
type TimeInfo = { hour: number; minute: number };

// suggestions ranges
const fixedDate: string[] = ["tomorrow", "today"];
const weekdays: string[] = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const nextUnits: string[] = ["week", "month", "quarter", "year"];
const inUnits: string[] = ["days", "weeks", "months", "years"];
// lookup helpers
// numbers typed as string, using only the first two letters
const stringToInt: Record<string, number> = {
  a: 1,
  an: 1,
  on: 1,
  tw: 2,
  th: 3,
  fo: 4,
  fi: 5,
  si: 6,
  se: 7,
  ei: 8,
  ni: 9,
  te: 10,
};
const months: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const padZero = (n: number): string => {
  const s = String(n);
  if (s.length === 1) return "0" + s;
  return s;
};

const lcIncludes = (haystack: string, needle: string): boolean => {
  return haystack.toLowerCase().includes(needle.toLowerCase());
};

const stringToMonths = (s: string): string | undefined => {
  if (stringIsEmpty(s)) return undefined;
  const matches = months.filter((m) => m.toLowerCase().startsWith(s.toLowerCase()));
  if (matches.length === 0) return undefined;
  return matches.join(" ");
};

const suggestionToDate = (suggestion: string, timeInfo: TimeInfo): dayjs.Dayjs => {
  switch (suggestion) {
    case "last week": {
      return dayjs().startOf("week").subtract(1, "week");
    }
    case "last month": {
      return dayjs().startOf("month").subtract(1, "month");
    }
    case "last quarter": {
      return dayjs().startOf("quarter").subtract(1, "quarter");
    }
    case "last year": {
      return dayjs().startOf("year").subtract(1, "year");
    }
    case "next week": {
      return dayjs().startOf("week").add(1, "week");
    }
    case "next month": {
      return dayjs().startOf("month").add(1, "month");
    }
    case "next quarter": {
      return dayjs().startOf("quarter").add(1, "quarter");
    }
    case "next year": {
      return dayjs().startOf("year").add(1, "year");
    }
  }

  const parseLabel = suggestion + ` ${padZero(timeInfo.hour)}:${padZero(timeInfo.minute)}`;
  const date = dayjs(chronoParseDate(parseLabel));

  return date;
};

type Args = {
  query: string;
  parseTime: boolean;
  hour: number;
  minute: number;
  fallback: string[];
  ref: Date;
  options: { forwardDate: boolean };
};

export function parseDate({
  query = "", // this string to parsed
  parseTime = false, // wether to parseTime, default is false
  hour = 0, // default hour to apply to parsed / suggested dates
  minute = 0, // default minute to apply to parsed / suggested dates
  fallback = [], // default suggestion list, when no query (will be parsed by chrono)
  ref, // reference date for chrono to improve parsing to the right date
  options, // options for chrono, e.g. { forwardDate: true } to optimize for dats in the future (see docs)
}: Args): Suggestion[] {
  const q = stringToMonths(query) ?? query;
  const results = parse(q, ref, options);

  const parts = query.split(" ");
  const firstPart = parts[0] ?? "";
  const shortcut = firstPart;

  const isLast = "last".substr(0, shortcut.length) === shortcut; // beginning of 'this ...'
  const isThis = "this".substr(0, shortcut.length) === shortcut; // beginning of 'this ...'
  const isNext = "next".substr(0, shortcut.length) === shortcut; // beginning of 'next ...'
  const isIn = "in".substr(0, shortcut.length) === shortcut; // beginning of 'in ...'
  const isOn = "on".substr(0, shortcut.length) === shortcut; // beginning of 'on ...'
  const isNumber = Number.isInteger(Number(shortcut.trim())); // beginning with number, e.g. '6' -> '6 days'

  // used to filter suggestion ranges. will capture whatever is after the shortcut
  let what = parts[1] ?? "";

  let stage; // stage is used if 'what' identified to filter range before building suggestions
  let suggestions;

  // calculate suggestions when user inputted a query, else show default
  if (query.length > 0) {
    // if no shortcut, use defaults
    suggestions = fixedDate.filter((v) => lcIncludes(v, firstPart));

    // if using 'this'-shortcut, and no other shortcuts are in play
    if (isThis && !isLast && !isNext && !isIn && !isOn && !isNumber) {
      stage = weekdays.concat(nextUnits);
      if (what) stage = stage.filter((v) => lcIncludes(v, what));
      suggestions = suggestions.concat(stage.map((string) => "this " + string));
    }

    // if using 'next'-shortcut, and no other shortcuts are in play
    else if (isNext && !isLast && !isIn && !isOn && !isThis && !isNumber) {
      stage = weekdays.concat(nextUnits);
      if (what) stage = stage.filter((v) => lcIncludes(v, what));
      suggestions = suggestions.concat(stage.map((string) => "next " + string));
    }

    // if using 'last'-shortcut, and no other shortcuts are in play
    else if (isLast && !isNext && !isIn && !isOn && !isThis && !isNumber) {
      stage = weekdays.concat(nextUnits);
      if (what) stage = stage.filter((v) => lcIncludes(v, what));
      suggestions = suggestions.concat(stage.map((string) => "last " + string));
    }

    // if no shortcut in play, try weekdays
    else if (!isNext && !isIn && !isThis && !isNumber) {
      stage = weekdays;
      if (what) stage = stage.filter((v) => lcIncludes(v, what));
      const strictFilter =
        (parts.filter((v) => v.length > 0).length <= 1 && !isOn) ||
        (parts.filter((v) => v.length > 0).length > 1 && isOn) ||
        results.length > 0;
      stage = isOn
        ? stage
        : strictFilter
        ? stage.filter((string) => string === query || lcIncludes(string, firstPart))
        : stage.filter(
            (string) =>
              string === query ||
              parts.filter((subQuery) => lcIncludes(string, subQuery)).length > 0,
          );
      suggestions = suggestions.concat(stage);
    }

    // if using 'in'-shortcut, or first string is number */
    else if (!isNext && !isThis && !isOn) {
      // checks both numbers and frequently used strings that mean numbers
      const number =
        Number(firstPart) ||
        Number(stringToInt[firstPart.substr(0, 2)]) ||
        (isIn && (Number(what) || (what && Number(stringToInt[what.substr(0, 2)])))) ||
        null;
      stage = inUnits;
      // this is a bit hacky, but it basically just replace the 'what' based on wether query starts with 'in ...'
      what = isIn ? parts[2] ?? "" : what;
      if (what) stage = stage.filter((v) => lcIncludes(v, what));

      // if there is a valid number, we will use that

      // Legacy code, idk if this is a bug or not.
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!isOn) {
        suggestions = suggestions.concat(
          stage.map((string) => {
            const showText = number === 1 || number === null;
            const val = showText ? (string === "hours" ? "an" : "a") : number;
            const unit = showText ? string.substring(0, string.length - 1) : string;
            return "in " + val + " " + unit;
          }),
        );
      }
    }
  }

  // fallback value, to show when no query
  else {
    suggestions = fallback;
  }

  // if there is a result with a known time, use that – else use default
  let defaultTimeInfo: TimeInfo = { hour, minute };
  if (results.length > 0) {
    defaultTimeInfo = getTimeInfo(results[0]?.start, defaultTimeInfo);
  }

  // builds the suggestion object
  suggestions = suggestions
    .filter((v) => v.length > 0)
    .map((label) => {
      const dates = parse(label);
      const timeInfo = getTimeInfo(dates[0]?.start, defaultTimeInfo);
      return { label, date: suggestionToDate(label, timeInfo) };
    });

  // build a stage for the actual results, before creating additional ones
  const actuals = results
    // see filter under suggestions... this is hacky – i know...
    .filter((r) => {
      const startText = r.text.split(" ")[0] ?? "";
      return !["this", "on"].includes(startText);
    })
    .map((r) => {
      return dayjs(r.start.date())
        .set("hour", defaultTimeInfo.hour)
        .set("minute", defaultTimeInfo.minute);
    });

  // if only a single result, we will try creating more based on the known values from our chrono result
  const result = results[0];
  const actual = actuals[0];
  let additional: dayjs.Dayjs[] = [];
  if (
    result !== undefined &&
    actual !== undefined &&
    !isThis &&
    !isNext &&
    !isIn &&
    !isOn &&
    !isNumber
  ) {
    const hasWeekday = result.start.isCertain("weekday");
    const hasMonth = result.start.isCertain("month");
    const hasDay = result.start.isCertain("day");
    const hasYear = result.start.isCertain("year");
    const hasTime = result.start.isCertain("hour");
    if (!(hasYear && hasMonth && hasDay)) {
      if (hasTime || !parseTime) {
        additional = [];
      } else {
        // chosen interval based on what we know from chrono
        const interval = hasWeekday ? "weeks" : hasMonth && hasDay ? "hours" : "days";
        // creates two additional results adding 1 and 2 units to the chosen interval
        additional = [1, 2].map((i) => {
          return actual.clone().add(i, interval);
        });
      }
    }
  }
  // builds the result object
  const combined = actuals.concat(additional).map((date) => {
    return {
      label: date.format("MMMM Do, YYYY"),
      date,
    };
  });

  // merges the suggestions with the results, to make sure no date is shown twice
  // prioritise the suggestions, because it has better labeling
  const deduped = unionBy([...suggestions, ...combined], (s) => s.date.toString());
  return sortBy(deduped, (s) => s.date);
}

const getTimeInfo = (
  components: ParsedComponents | undefined,
  defaultValue: TimeInfo,
): TimeInfo => {
  if (components === undefined) return defaultValue;
  let { hour } = defaultValue;
  if (components.isCertain("hour")) {
    hour = components.get("hour") ?? defaultValue.hour;
  }
  let { minute } = defaultValue;
  if (components.isCertain("minute")) {
    minute = components.get("minute") ?? defaultValue.minute;
  }
  return { hour, minute };
};
