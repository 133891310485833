import { useCallback, useEffect } from "react";

import { useCommandContext } from "~src/shared/command/hooks/useCommandContext";
import { ICommandHandler, ICommandName } from "~src/shared/command/types";

/**
 * Registers a command
 * @param command
 * @param handler If returns false, the event doesn't get handled by the browser.
 * @param dependencies
 */
export const useCommand = <C extends ICommandName>(
  command: C,
  handler: ICommandHandler,
  dependencies: unknown[] = [],
): void => {
  const ctx = useCommandContext();
  const { registerCommand, unregisterCommand } = ctx;
  // NOTE(johnrjj) - Re: linting -- I didn't want to touch this. We should migrate to an off the shelf library
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const commandHandler = useCallback(handler, [command, ...dependencies]);

  useEffect(() => {
    registerCommand(command, commandHandler);
    return () => {
      unregisterCommand(command);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [command, commandHandler]);
};
