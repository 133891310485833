/** e.g. Feb 2020 */
export const monthYearFmt = new Intl.DateTimeFormat("en-US", {
  month: "short",
  year: "numeric",
});

export const monthYearUTCFmt = new Intl.DateTimeFormat("en-US", {
  month: "short",
  year: "numeric",
  timeZone: "UTC",
});

export const monthYearFmtShort = new Intl.DateTimeFormat("en-US", {
  month: "short",
  year: "2-digit",
});

/**
 * Local date format, e.g. Jan 1, 2020
 */
export const dateFmt = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
  year: "numeric",
});

/**
 * "Local" date format but as if the time zone were UTC rather than the actual
 * local timezone.
 */
export const dateFmtUTC = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
  year: "numeric",
  timeZone: "UTC",
});

/**
 * Local date format with two digit year, e.g. Jan 1, 20
 */
export const dateFmtShort = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
  year: "2-digit",
});

/**
 * Local date format without year e.g. Jan 1
 */
export const dateFmtNoYear = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
});

/**
 * Local date format without year e.g. Jan 1 but as if the time zone were UTC rather than the actual
 * local timezone.
 */
export const dateFmtNoYearUTC = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
  timeZone: "UTC",
});

/**
 * Local date format without year and day e.g. Jan
 */
export const dateFmtMonth = new Intl.DateTimeFormat("en-US", {
  month: "short",
});

/** Eg. Mar 18, 2021, 12:43 AM */
export const dateTimeFmt = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
});

/** Eg. Mar 18, 2021, 12:43 AM EDT */
export const dateTimeWithTimezoneFmt = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "short",
});

/** Eg. Mar 19, 2021, 04:43 AM */
export const dateTimeFmtUTC = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  timeZone: "UTC",
});

/** Eg. Mar 19, 2021, 04:43 AM UTC */
export const dateTimeWithTimezoneFmtUTC = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  timeZone: "UTC",
  timeZoneName: "short",
});

/** Eg. 12:42 AM EDT */
export const timeWithTimezoneFmt = new Intl.DateTimeFormat("en-US", {
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "short",
});

/** Monday 4:58 PM */
export const timeFmt = new Intl.DateTimeFormat("en-US", {
  weekday: "long",
  hour: "numeric",
  minute: "numeric",
});

/**
 * Given an accounting end date, will output
 * a string of the three-month range, ending with the endDate.
 *
 * Ex: if endDate == "2020-12-31", then output == "Oct 2020 - Dec 2020"
 * */
export const formatAccountingDate = (endDate: string): JSX.Element => {
  const formattingOptions = {
    year: "numeric",
    month: "short",
  } as const;
  const periodStart = new Date(endDate);
  periodStart.setMonth(periodStart.getMonth() - 2);
  return (
    <>
      {periodStart.toLocaleString("en-us", formattingOptions)} -{" "}
      {new Date(endDate).toLocaleString("en-us", formattingOptions)}
    </>
  );
};

/**
 * get the last date of the month, n+1 months into the past.
 * n=0 means last month
 * Ex: if time = 2021-1-3 and n = 0, then output = 2020-12-31
 */
export const getPreviousMonthLastDate = (time: Date, n?: number): Date => {
  const currTime = new Date(Date.UTC(time.getFullYear(), time.getMonth(), time.getDate()));
  currTime.setDate(1);
  currTime.setMonth(currTime.getMonth() - (n ?? 0));
  currTime.setUTCDate(0);
  return currTime;
};
