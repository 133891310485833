import { Theme, ThemeProvider, useTheme } from "@emotion/react";
import * as React from "react";
import { useMemo } from "react";

export interface IStackItem {
  component: JSX.Element;
  config?: IStackItemConfig;
  theme: Theme;
}

/** Optional Modal customizations for a particular StackItem. */
export interface IStackItemConfig {
  /** If true, close button in the upper right of the modal will be hidden. Defaults to false. */
  isCloseButtonHidden?: boolean;
  /** If true, user will not be able to close the modal by clicking on the overlay (ie. outside of the modal). Defaults to false. */
  isCloseOnOverlayClickDisabled?: boolean;
  /** If true, tiger stripes in the upper right of the modal will be shown. Defaults to false. */
  isStripesVisible?: boolean;
  /** If true, modal overlay will over the entire full page. If false, the overlay will exclude the left navbar. Defaults to false. */
  isOverlayFullPage?: boolean;
  /**
   * If true, modal overlay will be blurred. Defaults to false.
   * Caution: blurred overlay causes signficant performance issues, esp on non-high-end computers.
   * Link to discussion: https://coda.io/d/Pipe_dMhpJ4rQgQ1/2021-03-03_sujGC#_luHNa
   */
  isOverlayBlurred?: boolean;
  /** Completely overrides the modal's onClose logic. Defaults to closing the modal. */
  onClose?: () => void;
  /** Custom width of the modal. Ex. used for ThirdPartyLogin. Defaults to width: 100% (max-width: 557px). */
  width?: string;
  stripesColor?: "stripesForeground" | "stripesBackground";
  /**
   * Where the close button is. Defaults to the right.
   */
  closeButtonPosition?: "left" | "right";

  // Custom z-index of the modal overlay.
  zIndex?: string;

  // Custom border-radius of the ModalContent wrapper.
  borderRadius?: string;
  // Custom border of the ModalContent wrapper
  border?: string;
}

type Action =
  | {
      /**
       * Adds a component to the modal stack. Takes optional modal config for specific component.
       * Config defaults to showing the close button and allowing user to close modal on overlay click. */
      type: "addAndOpenModal";
      /**
       * Currently loaded theme.
       */
      theme: Theme;
      /**
       * Component to display in the modal.
       * Normally, a component is composed of `ModalInner`s — the top being "foreground", and the bottom being "background".
       * For ex, see TestModal.tsx
       * */
      component: JSX.Element;
      /** Optional Modal customizations for a particular StackItem. */
      config?: IStackItemConfig;
    }
  | {
      /** Removes the item at the top of the modal stack. If item is the last item, the modal will close. */
      type: "removeTopOfStack";
    }
  | {
      type: "popStackToIndex";
      index: number;
    }
  | {
      /** Clears the stack and ensures that the modal closes. */
      type: "clearStackAndCloseModal";
    };

type IModalDispatch = (action: Action) => void;
export type IModalState = {
  stack: readonly IStackItem[];
};
type ModalProviderProps = {
  children: React.ReactNode;
};

const ModalStateContext = React.createContext<IModalState | undefined>(undefined);
const ModalDispatchContext = React.createContext<IModalDispatch | undefined>(undefined);

function modalReducer(state: IModalState, action: Action): IModalState {
  switch (action.type) {
    case "addAndOpenModal": {
      return {
        ...state,
        stack: [
          ...state.stack,
          {
            component: action.component,
            config: action.config,
            theme: action.theme,
          },
        ],
      };
    }
    case "removeTopOfStack": {
      return {
        ...state,
        stack: state.stack.slice(0, -1),
      };
    }
    case "popStackToIndex": {
      return {
        ...state,
        stack: state.stack.slice(0, action.index + 1),
      };
    }
    case "clearStackAndCloseModal": {
      return {
        ...state,
        stack: [],
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${(action as unknown as { type: string }).type}`);
    }
  }
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }: ModalProviderProps) => {
  const initialState: IModalState = {
    stack: [],
  };
  const [state, dispatch] = React.useReducer(modalReducer, initialState);
  return (
    <ModalStateContext.Provider value={state}>
      <ModalDispatchContext.Provider value={dispatch}>{children}</ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  );
};

export const useModalState = (): IModalState => {
  const context = React.useContext(ModalStateContext);
  if (context === undefined) {
    throw new Error("useModalState must be used within a ModalProvider");
  }
  return context;
};

interface IModal {
  addAndOpenModal: (cfg: Omit<Action & { type: "addAndOpenModal" }, "type" | "theme">) => void;
  removeTopOfStack: () => void;
  clearStackAndCloseModal: () => void;
  modalState: IModalState;

  popStackToIndex: (index: number) => void;
  currentStackIndex: number;
}

export const useModal = (): IModal => {
  const modalState = React.useContext(ModalStateContext);
  const modalDispatch = React.useContext(ModalDispatchContext);
  if (modalState === undefined || modalDispatch === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  const theme = useTheme();

  const addAndOpenModal: IModal["addAndOpenModal"] = React.useCallback(
    (cfg) =>
      modalDispatch({
        ...cfg,
        type: "addAndOpenModal",
        theme,
        component: <ThemeProvider theme={theme}>{cfg.component}</ThemeProvider>,
      }),
    [modalDispatch, theme],
  );

  const removeTopOfStack = React.useCallback(() => {
    modalDispatch({ type: "removeTopOfStack" });
  }, [modalDispatch]);

  const popStackToIndex = React.useCallback(
    (index: number) => {
      modalDispatch({ type: "popStackToIndex", index });
    },
    [modalDispatch],
  );

  const clearStackAndCloseModal = React.useCallback(() => {
    modalDispatch({ type: "clearStackAndCloseModal" });
  }, [modalDispatch]);

  return useMemo(() => {
    return {
      addAndOpenModal,
      removeTopOfStack,
      clearStackAndCloseModal,
      modalState,
      popStackToIndex,
      currentStackIndex: modalState.stack.length - 1,
    };
  }, [addAndOpenModal, removeTopOfStack, clearStackAndCloseModal, modalState, popStackToIndex]);
};
