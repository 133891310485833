import * as snippet from "@segment/snippet";

import { isWindowDefined } from "../helpers";
import { stringToBool } from "../helpers/booleanCoercion";

const environments = ["production", "development", "test"] as const;

type IEnvironmentType = typeof environments[number];

export interface IEnvironment {
  NODE_ENV: IEnvironmentType;
  /**
   * Ex (non-exhaustive):
   * `local.pipe-dev.com` | `app.pipe-dev.com` | `app.pipe-preview.com` | `app.pipe-sandbox.com` | `app.pipe.com`
   * */
  DOMAIN: string;

  // Third-party
  LOGROCKET_APP_ID?: string;
  HIGHLIGHT_ENVIRONMENT?: string;
  HIGHLIGHT_APP_ID?: string;
  SEGMENT_WRITE_KEY: string;
  GOCARDLESS_CLIENT_ID: string;
  GOCARDLESS_ENV: IGoCardlessEnv;
  PAYPAL_CLIENT_ID: string;
  PAYPAL_ENV: IPayPalEnv;
  STRIPE_OAUTH_CLIENT_ID: string;
  OPTIMIZELY_CLIENT_KEY: string;

  // optional
  MODE: "maintenance" | null;
  SENTRY_DSN: string | null;
  SENTRY_ENVIRONMENT: string | null;

  // computed
  SEGMENT_SNIPPET: string;
  API_URL: string;
}

const goCardlessEnvs = ["sandbox", "production"] as const;
type IGoCardlessEnv = typeof goCardlessEnvs[number];

const payPalEnvs = ["sandbox", "production"] as const;
type IPayPalEnv = typeof payPalEnvs[number];

/**
 * Makes the environment from environment variables. This function ONLY WORKS server side.
 *
 * The server calls this in `makeGetServerSideProps`.
 */
export const makeEnv = (): IEnvironment => {
  if (typeof window !== "undefined") {
    throw new Error("Tried to call makeEnv client side");
  }

  const requiredRaw = {
    NODE_ENV: process.env.NODE_ENV,
    DOMAIN: process.env.DOMAIN,
    // Third-parties
    LOGROCKET_APP_ID: process.env.LOGROCKET_APP_ID,
    SEGMENT_WRITE_KEY: process.env.SEGMENT_WRITE_KEY,
    GOCARDLESS_CLIENT_ID: process.env.GOCARDLESS_CLIENT_ID,
    GOCARDLESS_ENV: process.env.GOCARDLESS_ENV,
    PAYPAL_CLIENT_ID: process.env.PAYPAL_CLIENT_ID,
    PAYPAL_ENV: process.env.PAYPAL_ENV,
    STRIPE_OAUTH_CLIENT_ID: process.env.STRIPE_OAUTH_CLIENT_ID,
    SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
    OPTIMIZELY_CLIENT_KEY: process.env.OPTIMIZELY_CLIENT_KEY,
  };

  // Throw error if any required environment variables are not set.
  Object.entries(requiredRaw).forEach(([key, value]) => {
    if (!stringToBool(value)) {
      throw new Error(`env: ${key} not set, fatal`);
    }
  });

  const required = requiredRaw as { [K in keyof typeof requiredRaw]: string };

  const optional = {
    MODE: process.env.MODE ?? null,
    SENTRY_DSN: process.env.SENTRY_DSN ?? null,
    HIGHLIGHT_ENVIRONMENT: process.env.HIGHLIGHT_ENVIRONMENT,
    HIGHLIGHT_APP_ID: process.env.HIGHLIGHT_APP_ID,
  };

  // validation
  const { NODE_ENV, GOCARDLESS_ENV, PAYPAL_ENV } = required;
  if (!(environments as readonly string[]).includes(NODE_ENV)) {
    throw new Error(`Invalid environment: ${NODE_ENV}`);
  }
  if (!goCardlessEnvs.includes(GOCARDLESS_ENV as IGoCardlessEnv)) {
    throw new Error(`Invalid Plaid env: ${GOCARDLESS_ENV}`);
  }

  if (!payPalEnvs.includes(PAYPAL_ENV as IPayPalEnv)) {
    throw new Error(`Invalid PayPal env: ${PAYPAL_ENV}`);
  }

  const { MODE } = optional;
  if (MODE !== null && MODE !== "maintenance") {
    throw new Error(`Invalid mode: ${optional.MODE}`);
  }

  const env = {
    ...required,
    ...optional,

    NODE_ENV: NODE_ENV as IEnvironmentType,
    GOCARDLESS_ENV: GOCARDLESS_ENV as IGoCardlessEnv,
    PAYPAL_ENV: PAYPAL_ENV as IPayPalEnv,

    // optional
    MODE: MODE as "maintenance" | null,
  };

  const otherConstants = {
    SEGMENT_SNIPPET: (() => {
      const opts = {
        apiKey: env.SEGMENT_WRITE_KEY,
        page: true,
      };
      if (process.env.NODE_ENV === "development") {
        return snippet.max(opts);
      }
      return snippet.min(opts);
    })(),
    API_URL: getAPIURLOfDomain(env.DOMAIN),
  };

  return {
    ...env,
    ...otherConstants,
  };
};

export const BASE_URL = (() => {
  if (typeof window !== "undefined") {
    return window.location.origin;
  }
  return process.env.NODE_ENV === "production"
    ? `https://${process.env.DOMAIN}`
    : `http://${process.env.DOMAIN}:8082`;
})();

/**
 * Gets the URL of the API associated with the given domain.
 */
const getAPIURLOfDomain = (domain: string): string => {
  // Production
  if (domain === "app.pipe.com") {
    return "https://api.pipe.com";
  }
  // Sandbox
  if (domain === "app.pipe-sandbox.com") {
    return "https://api.pipe-sandbox.com";
  }
  // Vercel (Preview)
  if (domain.endsWith(".pipe-preview.com")) {
    return "https://api.pipe-preview.com";
  }
  // Local development (override)
  if (process.env.NEXT_PUBLIC_API_URL__LOCAL_ONLY !== undefined) {
    return process.env.NEXT_PUBLIC_API_URL__LOCAL_ONLY;
  }
  // Staging
  if (domain.endsWith(".pipe-dev.com") || domain === "localhost") {
    return "https://api.pipe-dev.com";
  }

  throw new Error("API_URL could not be calculated.");
};

/**
 * The URL of the API server.
 */
export const makeAPIURL = (): string =>
  isWindowDefined
    ? getAPIURLOfDomain(window.location.hostname)
    : getAPIURLOfDomain(makeEnv().DOMAIN);
