import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";

/**
 * Apollo client that never returns anything. Used to make sure we only do client-side rendering.
 */
export const noopApolloClient: ApolloClient<NormalizedCacheObject> =
  new ApolloClient<NormalizedCacheObject>({
    link: ApolloLink.empty(),
    cache: new InMemoryCache(),
  });
