import React from "react";
import { FaList, FaSearch } from "react-icons/fa";

import { ICommandRunner } from "~src/shared/command/types";

import { ICommandKMode } from "../types";
import {
  ICommandKModeHandler,
  ICommandKModeRendererArgs,
  ICommandKSection,
  ICommandKSectionType,
  ICommandKTile,
} from "../types/internal";
import { fuzzyMatch } from "../utils";

// TODO(usmanm): figure out what to do when lists are being loaded and how to dynamically
// refresh once they have loaded.
const makeListSearchModeSections = ({
  env: { searchText, runCommand },
  args: { lists },
}: ICommandKModeRendererArgs<ICommandKMode.LIST_SEARCH>): readonly ICommandKSection[] => {
  const section: ICommandKSection = {
    title: "Lists",
    options: lists
      .filter(({ title }) => fuzzyMatch(title, searchText))
      .map((list) => ({
        id: list.slug,
        label: list.title,
        icon: <FaList />,
        description: list.slug,
        onSelect: () => {
          runCommand(ICommandRunner.COMMAND_K, "lists/open", { list });
          return null;
        },
      })),
    type: ICommandKSectionType.COMMANDS,
  };

  return [section];
};

export const listSearchHandler: ICommandKModeHandler<ICommandKMode.LIST_SEARCH> = {
  header: () =>
    [
      {
        title: "Search",
        icon: <FaSearch />,
      },
    ] as readonly ICommandKTile[],
  render: (args) => ({
    sections: makeListSearchModeSections(args),
  }),
};
