import LogRocket from "logrocket";
import { v4 as uuidv4 } from "uuid";

import { stringToBool } from "~src/shared/helpers/booleanCoercion";

export const logRocketIdentify = (
  name: string,
  email: string,
  /** any additional user data to send to LogRocket */
  other?: Record<string, unknown>,
): void => {
  // Is this a bug?
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const userID = email ?? `${name ?? "anon"}_${uuidv4()}`;
  LogRocket.identify(userID, {
    name,
    email,
    ...other,
  });
};

type IOptions = NonNullable<Parameters<typeof LogRocket.init>[1]>;

type IRequest = NonNullable<
  ReturnType<NonNullable<NonNullable<IOptions["network"]>["requestSanitizer"]>>
>;

// LogRocket's IRequest.body is typed as "string | undefined" but in reality a
// request body can be many different things, including form or file data.
type IRequestBody = string | undefined | File | FormData;

/**
 * Options for LogRocket.init
 */
export const logRocketOptions: IOptions = {
  network: {
    requestSanitizer: (request: IRequest): IRequest => {
      const { body: _body, ...rest } = request;
      const body: IRequestBody = _body;
      // We only know how to sanitize request bodies with json payloads, any
      // different type should be ignored as we cannot scrub it.
      if (typeof body !== "string") {
        return request;
      }
      // Only sanitize if the request.body mentions "password" | "apikey" |
      // "credentialsJSON". This does not affect the request sent to the API,
      // just how it's recorded in LogRocket.
      if (!body.match(/password|apikey|credentialsJSON/i)) {
        return request;
      }
      // Gracefully handle body parsing failures.
      const bodyObj = JSON.parse(body);
      if (typeof bodyObj === "undefined") {
        return request;
      }
      const sanitizedBody: Record<string, unknown> = {
        action: bodyObj.action,
        sanitized: true,
      };
      if (stringToBool(bodyObj.email)) {
        sanitizedBody.email = bodyObj.email;
      }
      const sanitizedRequest = {
        ...rest,
        body: JSON.stringify(sanitizedBody),
      };
      return sanitizedRequest;
    },
  },
};
