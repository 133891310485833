import stringify from "json-stable-stringify";
import React from "react";
import { FaQuestion } from "react-icons/fa";

import { OldKeyboardShortcutWhenDidWeUseThis } from "~/src/designSystem/deprecated/KeyboardShortcut";
import { ICommandMappingName, ICommandRunner } from "~src/shared/command/types";

import { CommandIcon } from "../icons/CommandIcon";
import { ICommandKMode } from "../types";
import {
  ICommandKModeHandler,
  ICommandKModeRendererArgs,
  ICommandKSection,
  ICommandKSectionType,
  ICommandKTile,
} from "../types/internal";
import { fuzzyMatch } from "../utils";

const lastMappings: ICommandMappingName[] = [
  ICommandMappingName.Goto,
  ICommandMappingName.CommandK,
];

const makeCommandModeSections = ({
  env: { searchText, mapping, runCommand },
}: ICommandKModeRendererArgs<ICommandKMode.COMMANDS>): readonly ICommandKSection[] => {
  const sections: ICommandKSection[] = [];

  const mappingNames = Object.keys(mapping)
    .filter((name: ICommandMappingName) => !lastMappings.includes(name))
    .concat(lastMappings) as ICommandMappingName[];

  mappingNames.forEach((name) => {
    // Is the mapping inactive? If so, skip.
    const commands = mapping[name];
    if (commands === undefined) return;

    const section: ICommandKSection = {
      title: name,
      options: commands
        .filter(
          ({ title, hideInCommandKModal }) =>
            hideInCommandKModal !== true && fuzzyMatch(title, searchText),
        )
        .map(({ Icon, command, title, bindings }) => ({
          id: stringify(command),
          label: title,
          icon: Icon === undefined ? <FaQuestion /> : <Icon />,
          description:
            bindings[0] !== undefined ? (
              <OldKeyboardShortcutWhenDidWeUseThis variant="body" textRepr={bindings[0]} />
            ) : null,
          onSelect: () => {
            runCommand(ICommandRunner.COMMAND_K, command.command, command.args);
            return null;
          },
        })),
      type: ICommandKSectionType.COMMANDS,
    };

    if (section.options.length === 0) return;
    sections.push(section);
  });

  return sections;
};

export const commandsHandler: ICommandKModeHandler<ICommandKMode.COMMANDS> = {
  header: () =>
    [
      {
        title: "Pipe Commands",
        icon: <CommandIcon />,
      },
    ] as readonly ICommandKTile[],
  render: (args) => ({
    sections: makeCommandModeSections(args),
  }),
};
