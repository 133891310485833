import stringify from "json-stable-stringify";

import { renderers } from "~src/designSystem/tables/Table/renderer/native";
import { ITableColumn } from "~src/designSystem/tables/Table/types";

import { IListColumnConfig, IListConfig, IListFilter } from "../types";
import { getListModelConfig, IListColumn, IListModel, IListModelColumnName } from "../types/models";
import { ISortOrder } from "../types/operators";
import { IRenderType } from "../types/types";

export const columnForModel = <M extends IListModel>(
  model: M,
  name: IListModelColumnName<M>,
): IListColumn<M> => {
  const modelConfig = getListModelConfig(model);
  // Ensure that column is valid for this model.
  const column = modelConfig.columns[name];
  return column;
};

const existingColumn = <M extends IListModel>(
  config: IListConfig<M>,
  column: IListModelColumnName<M>,
): boolean => {
  columnForModel(config.model, column);
  const columnNames = config.columns.map(({ name }) => name);
  return columnNames.indexOf(column) !== -1;
};

export const addColumn = <M extends IListModel>(
  config: IListConfig<M>,
  column: IListColumnConfig<M>,
): IListConfig<M> => {
  const exists = existingColumn(config, column.name);
  if (exists) {
    return config;
  }

  return { ...config, columns: [...config.columns, column] };
};

export const removeColumn = <M extends IListModel>(
  config: IListConfig<M>,
  column: IListModelColumnName<M>,
): IListConfig<M> => {
  const exists = existingColumn(config, column);
  if (!exists) {
    return config;
  }

  return {
    ...config,
    columns: config.columns.filter(({ name }) => name !== column),
  };
};

const existingFilter = <M extends IListModel>(
  config: IListConfig<M>,
  filter: IListFilter<M>,
): boolean => {
  columnForModel(config.model, filter.column);

  const s = stringify(filter);
  return config.filters.find((f) => stringify(f) === s) !== undefined;
};

export const addFilter = <M extends IListModel>(
  config: IListConfig<M>,
  filter: IListFilter<M>,
): IListConfig<M> => {
  const exists = existingFilter(config, filter);
  if (exists) return config;

  return { ...config, filters: [...config.filters, filter] };
};

export const removeFilter = <M extends IListModel>(
  config: IListConfig<M>,
  filter: IListFilter<M>,
): IListConfig<M> => {
  const exists = existingFilter(config, filter);
  if (!exists) return config;

  const s = stringify(filter);
  return {
    ...config,
    filters: config.filters.filter((f) => stringify(f) !== s),
  };
};

export const setSort = <M extends IListModel>(
  config: IListConfig<M>,
  column: IListModelColumnName<M>,
  sortOrder?: ISortOrder,
): IListConfig<M> => {
  columnForModel(config.model, column);

  if (sortOrder === undefined) {
    return {
      ...config,
      sort: undefined,
    };
  }

  return {
    ...config,
    sort: {
      column,
      sortOrder,
    },
  };
};

export const configKey = <M extends IListModel>(config: IListConfig<M>): string => {
  const configWithStrippedColumns = {
    ...config,
    columns: config.columns.map((c) => c.name),
    actionColumns: undefined,
  };

  return stringify(configWithStrippedColumns);
};

export const transformTableColumns = <M extends IListModel>(
  config: IListConfig<M>,
): ITableColumn[] => {
  return config.columns
    .map(({ name, title: columnTitle, renderer, width, alignRight, tooltip }): ITableColumn => {
      const column = columnForModel(config.model, name);

      let sortOrder;
      if (config.sort?.column === name) {
        sortOrder = config.sort.sortOrder;
      }

      return {
        title: columnTitle,
        rowKey: name as string,
        renderType: column.render_type,
        renderer: renderer ?? renderers[column.render_type],
        sortOrder,
        width,
        alignRight: alignRight === true,
        tooltip,
      };
    })
    .concat(
      (config.actionColumns ?? []).map(
        (col): ITableColumn => ({
          title: col.title,
          rowKey: col.title,
          renderType: IRenderType.text,
          renderer: col.renderer ?? renderers[IRenderType.text],
          width: col.width,
          alignRight: col.alignRight,
          minWidth: col.minWidth,
        }),
      ),
    );
};
