import React from "react";

import { AuthContext } from "~src/shared/auth/AuthProvider";
import { IGetUserProfileData } from "~src/shared/requests/authedRequests";

export const useMaybeUser = (): IGetUserProfileData | null => React.useContext(AuthContext).user;

/**
 * Gets the currently logged in user
 */
export const useUser = (): IGetUserProfileData => {
  const user = useMaybeUser();
  if (user === null) {
    throw new Error("Must be authenticated to use useUser");
  }
  return user;
};
