import styled from "@emotion/styled";
import React, { useEffect, useMemo, useRef } from "react";

import { useCommand } from "~src/shared/command/hooks/useCommand";
import { useCommandContext } from "~src/shared/command/hooks/useCommandContext";
import { useRunCommand } from "~src/shared/command/hooks/useRunCommand";
import { CommandKContentSection } from "~src/shared/commandK/components/CommandKContentSection";
import { useCommandKInternal } from "~src/shared/commandK/hooks/useCommandKInternal";
import { modeHandlers } from "~src/shared/commandK/modes";
import { ICommandKMode, IModeState } from "~src/shared/commandK/types";
import { ICommandKModeHandler, ICommandKModeResult } from "~src/shared/commandK/types/internal";
import { getSelectedSectionIndex } from "~src/shared/commandK/utils";

export const CommandKContent: React.FC = () => {
  const { state, setSelectedOptionIndex, selectOption } = useCommandKInternal();
  const { isVisible, selectedOptionIndex, searchText, modeState } = state;

  const growRef = useRef<HTMLDivElement>(null);

  const { getMapping } = useCommandContext();
  const mapping = getMapping();
  const runCommand = useRunCommand();

  const rendered: ICommandKModeResult = useMemo(<M extends ICommandKMode>() => {
    if (modeState === undefined) {
      return { sections: [] };
    }
    const { mode, args } = modeState as IModeState<M>;
    const modeHandler = modeHandlers[mode] as ICommandKModeHandler<M>;
    return modeHandler.render({
      env: { mapping, runCommand, searchText },
      args,
    });
  }, [searchText, modeState, mapping, runCommand]);

  useEffect(() => {
    if (growRef.current !== null && selectedOptionIndex === 0) {
      growRef.current.scrollTop = 0;
    }
  }, [selectedOptionIndex]);

  const { sections } = rendered;
  const maxSelectedOptionIndex = sections.reduce((acc, s) => acc + s.options.length, 0) - 1;

  const { sectionIndex, optionIndex } = getSelectedSectionIndex(selectedOptionIndex, sections);

  useCommand(
    "command-k/select",
    () => {
      if (!isVisible) return;

      const selectedOption = sections[sectionIndex]?.options[optionIndex];
      if (!selectedOption) {
        // nothing is selected. noop
        return;
      }

      selectOption(selectedOption);
      return false;
    },
    [isVisible, selectedOptionIndex, sections],
  );

  useCommand(
    "command-k/down",
    () => {
      if (isVisible) {
        setSelectedOptionIndex(Math.min(maxSelectedOptionIndex, selectedOptionIndex + 1));
        return false;
      }
    },
    [isVisible, selectedOptionIndex, maxSelectedOptionIndex, setSelectedOptionIndex],
  );

  useCommand(
    "command-k/up",
    () => {
      if (isVisible) {
        setSelectedOptionIndex(Math.max(0, selectedOptionIndex - 1));
        return false;
      }
    },
    [isVisible, selectedOptionIndex, setSelectedOptionIndex],
  );

  let startIndex = 0;
  return (
    <Grow ref={growRef}>
      <Wrapper>
        {sections.map((section, i) => {
          const elem = (
            <CommandKContentSection
              key={section.title}
              section={section}
              startIndex={startIndex}
              selectedIndex={sectionIndex === i ? optionIndex : undefined}
              isLastSection={i === sections.length - 1}
              growRef={growRef}
            />
          );
          startIndex += section.options.length;
          return elem;
        })}
      </Wrapper>
    </Grow>
  );
};

const Grow = styled.div`
  overflow-y: scroll;
`;

const Wrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.old.border.muted};
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 16px;

  padding: 16px;
  user-select: none;
`;
