import { sortBy } from "lodash";
import React from "react";

import { IconColumnType, IconFilter } from "~src/shared/lists/icons";
import { IEditableList, IListConfig } from "~src/shared/lists/types";
import { getListModelConfig, IListModel } from "~src/shared/lists/types/models";

import { ICommandKMode } from "../types";
import {
  ICommandKModeHandler,
  ICommandKModeRendererArgs,
  ICommandKSection,
  ICommandKSectionType,
  ICommandKTile,
} from "../types/internal";
import { fuzzyMatch } from "../utils";

const makeListEditFiltersModeSections = <M extends IListModel>({
  args,
  env: { searchText },
}: ICommandKModeRendererArgs<ICommandKMode.LIST_FILTER>): readonly ICommandKSection[] => {
  const list = args.list as unknown as IEditableList<M>;
  const { model } = list.config as IListConfig<M>;
  const modelConfig = getListModelConfig(model);

  const columns = sortBy(Object.values(modelConfig.columns), ["title"]);

  return [
    {
      title: "Columns",
      options: columns
        .filter((column) => {
          const name = column.name as string;
          return fuzzyMatch(name, searchText);
        })
        .map((column) => {
          return {
            id: column.name as string,
            label: "TODO",
            icon: <IconColumnType renderType={column.render_type} />,
            description: "Pipe",
            onSelect: () => {
              return {
                mode: ICommandKMode.LIST_FILTER_SELECT_OPERATOR,
                args: { list, column: column.name },
              };
            },
          };
        }),
      type: ICommandKSectionType.OPTIONS,
    },
  ];
};

export const listEditFiltersHandler: ICommandKModeHandler<ICommandKMode.LIST_FILTER> = {
  header: () =>
    [
      {
        title: "Filter",
        icon: <IconFilter />,
      },
    ] as readonly ICommandKTile[],
  render: (args) => ({
    sections: makeListEditFiltersModeSections(args),
  }),
};
