import React from "react";

import { Tooltip } from "~src/designSystem/atoms/Tooltip";
import { dateFmtUTC, dateTimeFmtUTC } from "~src/shared/helpers/formatDate";

interface IProps {
  date: Date | null | undefined | string;
  /**
   * If true, do not display a tooltip here and instead render an inline text node (wrapped in a Fragment)
   */
  noTooltip?: boolean;
  formatter?: Intl.DateTimeFormat;
  tooltipFormatter?: Intl.DateTimeFormat;
}

/**
 * Formats a date.
 */
export const FormattedDate: React.FC<IProps> = ({
  date,
  noTooltip,
  formatter = dateFmtUTC,
  tooltipFormatter = dateTimeFmtUTC,
}) => {
  if (date == null) {
    return <>--</>;
  }
  const theDate = typeof date === "string" ? new Date(date) : date;
  const dateStr = formatter.format(theDate);
  if (noTooltip === true) {
    return <>{dateStr}</>;
  }
  return (
    <Tooltip tooltipContent={tooltipFormatter.format(theDate)}>
      <span>{dateStr}</span>
    </Tooltip>
  );
};
