import * as http from "http";
import * as https from "https";
import nodeFetch, { Response } from "node-fetch";

const httpAgent = new http.Agent({
  keepAlive: true,
});

const httpsAgent = new https.Agent({
  keepAlive: true,
});

const baseOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  } as Record<string, string>,
  // use agent to keep connection alive
  agent: (_parsedURL: URL) => {
    if (_parsedURL.protocol === "http:") {
      return httpAgent;
    }
    return httpsAgent;
  },
};

type IOptions = {
  cookie?: string;
  headers?: Record<string, string>;
};

/** Custom fetch config'd for Pipe API. */
export const serverSideFetch = async <I>(
  url: string,
  payload: I,
  options?: IOptions,
): Promise<Response> => {
  const requestOptions = {
    ...baseOptions,
    headers: { ...baseOptions.headers, ...options?.headers },
    body: JSON.stringify(payload),
  };

  if (options?.cookie !== undefined) {
    requestOptions.headers.cookie = options.cookie;
  }

  return nodeFetch(url, requestOptions);
};
