import { useButton } from "@react-aria/button";
import { OverlayContainer, useOverlayPosition, useOverlayTrigger } from "@react-aria/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { AnimatePresence, motion } from "framer-motion";
import React, { useMemo } from "react";

import { IPortalPlacement, Portal } from "~src/designSystem/internal/Portal";

import { IPopoverPlacement } from "./types";

type IContentFunction = (arg0: { closePopover: () => void }) => React.ReactElement;

export type IPopoverProps = {
  /**
   * How to place the popover portal.
   */
  placement: IPortalPlacement;

  /**
   * Number of pixels in between the popover and the component.
   * @default 8
   */
  offset?: number;

  /**
   * The first child is the trigger element; the second child is the portal content. The
   * portal content can optionally be a function that takes a closePopover value and
   * returns a component.
   */
  children: [React.ReactElement, React.ReactElement | IContentFunction];

  /**
   * Callback to be called on popover button press. Useful for cases where we want to trigger
   * an additional function call when the popover is opened, e.g. track an analytics event.
   */
  onClick?: () => void;
};

// This is a headless popover.
export const Popover: React.FC<IPopoverProps> = (props) => {
  const state = useOverlayTriggerState({});

  const triggerRef = React.useRef(null);
  const overlayRef = React.useRef(null);

  // Get props for the trigger and overlay. This also handles
  // hiding the overlay when a parent element of the trigger scrolls
  // (which invalidates the popover positioning).
  const { triggerProps, overlayProps } = useOverlayTrigger({ type: "dialog" }, state, triggerRef);

  // Get popover positioning props relative to the trigger
  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    placement: props.placement,
    offset: props.offset ?? 8,
    isOpen: state.isOpen,
    shouldFlip: false,
  });

  // useButton ensures that focus management is handled correctly,
  // across all browsers. Focus is restored to the button once the
  // popover closes.
  const { buttonProps } = useButton(
    {
      onPress: () => {
        state.open();
        props.onClick?.();
      },
    },
    triggerRef,
  );

  const [button, content] = props.children;

  const motionProps = usePopoverMotionProps(props.placement);

  return (
    <>
      {React.cloneElement(button, {
        ...buttonProps,
        ...triggerProps,
        ref: triggerRef,
      })}
      <AnimatePresence>
        {state.isOpen && (
          <OverlayContainer>
            <Portal
              {...overlayProps}
              {...positionProps}
              ref={overlayRef}
              isOpen={state.isOpen}
              onClose={state.close}
              isDismissable
            >
              <motion.div key="portal-content" {...motionProps}>
                {typeof content === "function"
                  ? content({ closePopover: () => state.close() })
                  : content}
              </motion.div>
            </Portal>
          </OverlayContainer>
        )}
      </AnimatePresence>
    </>
  );
};

export const usePopoverMotionProps = (
  overlayPlacement: IPopoverPlacement,
): React.ComponentProps<typeof motion.div> => {
  return useMemo(() => {
    const direction = overlayPlacement.split(" ")[0];

    switch (direction) {
      case "bottom":
        return {
          initial: { opacity: 0, y: -4 },
          animate: { opacity: 1, y: 0 },
          exit: { opacity: 0, y: -4 },
          transition: { duration: 0.2 },
        };
      case "top":
        return {
          initial: { opacity: 0, y: 4 },
          animate: { opacity: 1, y: 0 },
          exit: { opacity: 0, y: 4 },
          transition: { duration: 0.2 },
        };
      case "left":
        return {
          initial: { opacity: 0, x: 4 },
          animate: { opacity: 1, x: 0 },
          exit: { opacity: 0, x: 4 },
          transition: { duration: 0.2 },
        };
      case "right":
        return {
          initial: { opacity: 0, x: -4 },
          animate: { opacity: 1, x: 0 },
          exit: { opacity: 0, x: -4 },
          transition: { duration: 0.2 },
        };
      default:
        return {};
    }
  }, [overlayPlacement]);
};
