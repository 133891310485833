import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

import { PipeBlack } from "~src/designSystem/icons/logos/PipeBlack";
import { PipeWhite } from "~src/designSystem/icons/logos/PipeWhite";

export type ILogoProgressLoaderProps = {
  isDark?: boolean;
};

export const LogoProgressLoader: React.FC<ILogoProgressLoaderProps> = ({ isDark }) => {
  return (
    <Wrapper>
      {isDark === true ? <PipeWhite /> : <PipeBlack />}
      <ProgressBar progress={50} goingDark={isDark} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: -20px;
  display: flex;
  align-items: center;
  flex-direction: column;

  svg {
    height: 40px;
    width: 40px;
  }
`;

const progressBarKeys = keyframes`
  0%{
    width: 0
  }
  10%{
    width: 5%
  }
  15%{
    width: 30%
  }
  20%{
    width: 30%
  }
  30%{
    width: 44%
  }
  35%{
    width: 50%
  }
  40%{
    width: 72%
  }
  45%{
    width: 84%
  }
  50%{
    width: 92%
  }
  95%{
    width: 92%
  }
  100%{
    width: 100%
  }
`;

const ProgressBar = styled.div<{ progress?: number; goingDark?: boolean }>`
  width: 200px;
  height: 5px;
  border-radius: 20px;
  margin-top: 30px;
  background: ${(props) => (props.goingDark === true ? "#333" : "#eee")};
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
    background: ${(props) => (props.goingDark === true ? "#fff" : "#111")};
    animation: ${progressBarKeys} 7s linear;
  }
`;
