import { Theme, ThemeProvider, useTheme } from "@emotion/react";
import * as React from "react";
import { useMemo } from "react";

import { IDialogVariation } from "~src/designSystem/molecules/Dialog";

export interface IStackItem {
  component: JSX.Element;
  config?: IStackItemConfig;
  theme: Theme;
}
export interface IStackItemConfig {
  // Title of the current Stepper item
  title?: string;
  // Expandable side column component
  sideColumn?: JSX.Element;
  // Overrides the onClose of the stepper
  onCloseOverride?: () => void;
}

type Action =
  | {
      /**
       * Adds a component to the Stepper stack.
       */
      type: "addAndOpenStepperDialog";
      /**
       * Currently loaded theme.
       */
      theme: Theme;
      /**
       * Component to display in the stepper.
       */
      component: JSX.Element;
      /**
       *
       */
      config?: IStackItemConfig;
    }
  | {
      /** Removes the item from the top of the stepper stack. */
      type: "removeTopOfStack";
    }
  | {
      /** Clears the stepper stack. */
      type: "clearStepperStack";
    };

type IStepperDispatch = (action: Action) => void;
export type IStepperState = {
  stack: readonly IStackItem[];
};

type StepperProviderProps = {
  children: React.ReactNode;
};

const StepperStateContext = React.createContext<IStepperState | undefined>(undefined);
const StepperDispatchContext = React.createContext<IStepperDispatch | undefined>(undefined);

function stepperReducer(state: IStepperState, action: Action): IStepperState {
  switch (action.type) {
    case "addAndOpenStepperDialog": {
      return {
        ...state,
        stack: [
          ...state.stack,
          {
            component: action.component,
            config: action.config,
            theme: action.theme,
          },
        ],
      };
    }
    case "removeTopOfStack": {
      return {
        ...state,
        stack: state.stack.slice(0, -1),
      };
    }
    case "clearStepperStack": {
      return {
        ...state,
        stack: [],
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${(action as unknown as { type: string }).type}`);
    }
  }
}

export const useStepperState = (): IStepperState => {
  const context = React.useContext(StepperStateContext);
  if (context === undefined) {
    throw new Error("useStepper must be used within a StepperProvider");
  }
  return context;
};
interface IStepper {
  addAndOpenStepperDialog: (
    cfg: Omit<Action & { type: "addAndOpenStepperDialog" }, "type" | "theme">,
  ) => void;
  removeTopOfStack: () => void;
  clearStepperStack: () => void;
  stepperState: IStepperState;

  currentStackIndex: number;
  currentStep: () => IStackItem | undefined;
  // Half page or Full page variant. Defaults to half page.
  variation: IDialogVariation;
}

export const StepperProvider: React.FC<StepperProviderProps> = ({
  children,
}: StepperProviderProps) => {
  const initialState: IStepperState = {
    stack: [],
  };
  const [state, dispatch] = React.useReducer(stepperReducer, initialState);
  return (
    <StepperStateContext.Provider value={state}>
      <StepperDispatchContext.Provider value={dispatch}>{children}</StepperDispatchContext.Provider>
    </StepperStateContext.Provider>
  );
};

export const useStepper = (variation?: IDialogVariation): IStepper => {
  const stepperState = React.useContext(StepperStateContext);
  const stepperDispatch = React.useContext(StepperDispatchContext);
  if (stepperState === undefined || stepperDispatch === undefined) {
    throw new Error("useStepper must be used within a StepperProvider");
  }
  const theme = useTheme();

  const addAndOpenStepperDialog: IStepper["addAndOpenStepperDialog"] = React.useCallback(
    (cfg) => {
      stepperDispatch({
        ...cfg,
        type: "addAndOpenStepperDialog",
        theme,
        component: <ThemeProvider theme={theme}>{cfg.component}</ThemeProvider>,
      });
    },
    [stepperDispatch, theme],
  );

  const removeTopOfStack = React.useCallback(() => {
    stepperDispatch({ type: "removeTopOfStack" });
  }, [stepperDispatch]);

  const currentStep = React.useCallback(() => {
    const currentStackIndex = stepperState.stack.length - 1;
    return stepperState.stack[currentStackIndex];
  }, [stepperState]);

  const clearStepperStack = React.useCallback(() => {
    stepperDispatch({ type: "clearStepperStack" });
  }, [stepperDispatch]);

  return useMemo(() => {
    return {
      addAndOpenStepperDialog,
      removeTopOfStack,
      clearStepperStack,
      stepperState,
      currentStackIndex: stepperState.stack.length - 1,
      currentStep,
      variation: variation ?? "halfPage",
    };
  }, [
    addAndOpenStepperDialog,
    currentStep,
    removeTopOfStack,
    clearStepperStack,
    stepperState,
    variation,
  ]);
};

export const getTopOfStepperStack = (stack: readonly IStackItem[]): IStackItem | undefined => {
  return stack[stack.length - 1];
};
