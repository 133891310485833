import { ICommandMapping, IRunCommand } from "~src/shared/command/types";

import { ICommandKMode, ICommandKModeArgs, IModeState } from ".";

export interface ICommandKTile {
  icon: React.ReactNode;
  title: string;
}

/**
 * ID of an option.
 */
export type IOptionID = string;

/**
 * Options on the Command K.
 */
export interface ICommandKOption {
  id: IOptionID;
  icon: React.ReactNode;
  label: string;
  description: React.ReactNode;
  /**
   * Returns the next command K mode, or does nothing.
   */
  onSelect: () => IModeState<ICommandKMode> | null;

  /**
   * Only set for options in command K mode that have a checkbox
   */
  onChecked?: (checked: boolean) => void;
  checked?: boolean;
}

/**
 * The type of section to render.
 */
export enum ICommandKSectionType {
  /**
   * Select options from an ordered list with checkboxes,
   * and allow reordering items in the list.
   */
  CHECKBOXES_REORDERABLE = "CHECKBOXES_REORDERABLE",
  /**
   * Commands to perform once selected.
   */
  COMMANDS = "COMMANDS",
  /**
   * Select a choice from a list of options.
   */
  OPTIONS = "OPTIONS",
}

/**
 * Section of what shows up in Command K.
 */
export interface ICommandKSection {
  title: string;
  options: readonly ICommandKOption[];
  type: ICommandKSectionType;
}

/*
 * Cmd+K Modes
 */

export interface ICommandKModeResult {
  sections: readonly ICommandKSection[];
}

export type ICommandKModeRendererArgs<M extends ICommandKMode> = {
  env: {
    searchText: string;
    mapping: ICommandMapping;
    runCommand: IRunCommand;
  };
  args: ICommandKModeArgs<M>;
};

export interface ICommandKModeHandler<M extends ICommandKMode> {
  searchPlaceholder?: (args: ICommandKModeArgs<M>) => string;
  header: (args: ICommandKModeArgs<M>) => readonly ICommandKTile[];
  render: (v: ICommandKModeRendererArgs<M>) => ICommandKModeResult;
}
