/* eslint-disable react/style-prop-object */
import React from "react";

export const IconDuplicate: React.FC = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 0H3.75C3.3375 0 3 0.3375 3 0.75V2.25H4.5V1.5H10.5V6.75H9.75V8.25H11.25C11.6625 8.25 12 7.9125 12 7.5V0.75C12 0.3375 11.6625 0 11.25 0ZM0.75 3C0.3375 3 0 3.3375 0 3.75V11.25C0 11.6625 0.3375 12 0.75 12H8.25C8.6625 12 9 11.6625 9 11.25V3.75C9 3.3375 8.6625 3 8.25 3H0.75ZM1.5 4.5V10.5H7.5V4.5H1.5Z"
    />
  </svg>
);
