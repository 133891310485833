import { IRPCErrorOptions, RequestError } from "~src/shared/errors/taxonomy";

import { IErrorFields, IErrorMessage } from "../types";

/** Errors from Pipe API response. */
type IErrors<K extends string> = {
  errorMessage: IErrorMessage;
  errorFields: IErrorFields<K>;
};

type ILegacyRPCErrorOptions = Omit<IRPCErrorOptions, "router" | "routePath"> & {
  action?: string;
  type?: string;
  input?: string;
};

/**
 * Custom error to throw. Includes errorMessage and errorFields provided by Pipe API response.
 */
export class LegacyRPCError extends RequestError {
  errorMessage: IErrorMessage;

  errorFields: IErrorFields<string>;

  action?: string;

  type?: string;

  input?: string;

  status: number;

  constructor(message: string, resErrors: IErrors<string>, options: ILegacyRPCErrorOptions) {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const newMessage = resErrors.errorMessage || message || "unknown RPC error";
    super(newMessage, { ...options, code: options.code ?? "REQUESTS/LEGACY_RPC" });

    this.name = "RPCError";
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    this.errorMessage = resErrors.errorMessage || null;
    this.errorFields = resErrors.errorFields;
    this.action = options.action;
    this.type = options.type;
    this.input = options.input;
    this.status = options.status;
  }
}

/**
 * Transient because the backend team will fix 500s.
 */
export class LegacyRPCServerError extends LegacyRPCError {
  constructor(message: string, options: Omit<ILegacyRPCErrorOptions, "status">) {
    super(
      message,
      {
        errorMessage: "Internal Server Error",
        errorFields: null,
      },
      {
        ...options,
        code: options.code ?? "REQUESTS/LEGACY_RPC_SERVER_ERROR",
        transient: true,
        status: 500,
      },
    );
  }
}

export class NotLoggedInError extends LegacyRPCError {
  constructor() {
    super(
      "Login required.",
      {
        errorMessage: "Login required.",
        errorFields: null,
      },
      {
        expected: true,
        status: 401,
      },
    );
  }
}

export class MissingPermissionError extends LegacyRPCError {
  constructor() {
    super(
      "Not allowed to do that.",
      {
        errorMessage: "Not allowed to do that.",
        errorFields: null,
      },
      {
        expected: true,
        status: 403,
      },
    );
  }
}

/**
 * Something happened in the legacy RPC endpoint's fetch section.
 */
export class FailedToFetchError extends LegacyRPCError {
  constructor(message: string, options: Omit<ILegacyRPCErrorOptions, "status">) {
    super(
      message,
      { errorMessage: null, errorFields: null },
      {
        ...options,
        code: options.code ?? "REQUESTS/LEGACY_FETCH",
        status: 400,
      },
    );
  }
}
