import { useContext } from "react";

import { CommandContext } from "~src/shared/command/contexts/CommandProvider";
import { ICommandContext } from "~src/shared/command/types";

export const useCommandContext = (): ICommandContext => {
  const ctx = useContext(CommandContext);
  if (ctx === null) {
    throw new Error("Must be in CommandProvider");
  }
  return ctx;
};
