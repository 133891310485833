/** @jsxImportSource @emotion/react */
import { css, ThemeProvider, useTheme } from "@emotion/react";
import React from "react";

import {
  OldHeadingUseTextInstead,
  OldSubheadingUseTextInstead,
} from "~src/designSystem/deprecated/Heading";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { MaintenanceImg } from "~src/shared/animations/MaintenanceImg";
import { themeDark } from "~src/shared/theme/darkVariant";

/** Page for when Pipe app is on scheduled maintenance. */
export const Maintenance: React.FC = () => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={themeDark}>
      <Flexbox
        fullWidth
        alignItems="center"
        justifyContent="center"
        css={css`
          height: 100vh;
          background: ${theme.components.App.background};
        `}
      >
        <MaintenanceImg />
        <Flexbox padY alignItems="center">
          <OldHeadingUseTextInstead
            css={css`
              color: white;
            `}
          >
            Pipe is currently down for scheduled maintenance.
          </OldHeadingUseTextInstead>
          <OldSubheadingUseTextInstead>
            We expect to be back in a few hours. Thanks for your patience.
          </OldSubheadingUseTextInstead>
        </Flexbox>
      </Flexbox>
    </ThemeProvider>
  );
};
