import { useEffect } from "react";

import { analyticsIdentify } from "~src/shared/analytics/analytics";
import { IEnvironment } from "~src/shared/env";
import { IGetUserProfileData } from "~src/shared/requests/authedRequests";

import { useVendorImpersonateStore } from "../auth/store";

/**
 * The currently loaded public ID for analytics.
 *
 * - undefined: never loaded
 * - null: user is unauthenticated
 * - string: user is authenticated
 */
let analyticsLoadedUserPublicID: string | null | undefined;

/**
 * Initialize analytics services if the user was not set before or if the user changed.
 * Disabled for admins.
 * @param env
 * @param user
 */
export const useInitializeAnalytics = (
  env: IEnvironment | null,
  user: IGetUserProfileData | null,
): void => {
  const impersonateVendorPublicID = useVendorImpersonateStore(
    (state) => state.impersonateVendorPublicID,
  );

  useEffect(() => {
    const nextPublicID = user?.publicID ?? null;
    if (
      user !== null &&
      // Only run analytics for non admins.
      user.userType !== "pipe_admin" &&
      // env must be loaded.
      env !== null &&
      // check if this is the first load
      (typeof analyticsLoadedUserPublicID === "undefined" ||
        // check if the user ID has changed
        analyticsLoadedUserPublicID !== nextPublicID)
    ) {
      // If we are impersonating a user, don't initialize autopilot or segment. But
      // initialize LogRocket.
      analyticsIdentify(env, user, {
        logrocket: true,
        autopilot: impersonateVendorPublicID === null,
        segment: impersonateVendorPublicID === null,
      });
      analyticsLoadedUserPublicID = nextPublicID;
    }
  }, [env, impersonateVendorPublicID, user]);
};
