import { PlaidEnabledCountryCode } from "~src/shared/dataSources/bank/hooks/types";
import { INetworkRequest } from "~src/shared/requests/types";
import {
  ICodatPlatformKey,
  ICountryCode,
  ILegalEntityType,
  IUploadedVendorDataSourceModel,
} from "~src/shared/types";

type Empty = Record<string, never>;

export type IUpdateVendorArgs = {
  publicID: string;
  name?: string;
  mainContactFirstName?: string;
  mainContactLastName?: string;
  mainContactTitle?: string;
  mainContactPhone?: string;
  mainContactEmail?: string;
  website?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  corporateEntity?: string;
  country?: ICountryCode;
  ein?: string;
  paymentMethodPublicID?: string;
  payoutMethodPublicID?: string;
};

export type ITreasuryDeposit = {
  accountID: string;
  sourceAccountID: string;
  amount: number;
};

export type ITreasuryWithdrawal = {
  accountID: string;
  destinationAccountID: string;
  amount: number;
};

export type IUpdateVendorRequest = (
  input: IUpdateVendorArgs,
) => INetworkRequest<Readonly<IUpdateVendorArgs>, Readonly<never>>;

export type IUpdateUserArgs = {
  name?: string;
  phone?: string;
  email?: string;
};

export type IUpdateUserRequest = (
  input: IUpdateUserArgs,
) => INetworkRequest<Readonly<IUpdateUserArgs>, Readonly<never>>;

export type IScheduleEntry = {
  date: string;
  amount: number;
  currency: string;
  count: number;
};

export type Quote = { bidPrice: number; maxPayout: number };

export type IUpsertLegalEntityArgs = {
  name: string;
  tin: string;
  legalEntityType: ILegalEntityType;
  address1: string;
  address2: string;
  city: string;
  state: string | null;
  stateOfIncorporation: string;
  postalCode: string;
  country: string;
};

const makeVendorRequest =
  <I, O>(action: string) =>
  (input: I): INetworkRequest<Readonly<I>, Readonly<O>> => ({
    action,
    type: "vendor",
    input,
  });

export const vendorRequests = {
  trackVisit: makeVendorRequest<Empty, Empty>("visit"),

  upsertLegalEntity: makeVendorRequest<IUpsertLegalEntityArgs, never>(
    "settings.upsert_legal_entity",
  ),

  getTradeTerms:
    makeVendorRequest<
      Empty,
      {
        terms: {
          termLengthMonths: number;
          tradeLimit: number;
          bidPriceBPS: number;
          currency: string;
        }[];
      }
    >("trade.get_terms"),

  enqueueTrade:
    makeVendorRequest<
      {
        revenueStreamIDs: string[] | readonly string[];
        termLength: number;
        expectedPayout?: number;
      },
      {
        count: number;
        balance: number;
        payout: number;
      }
    >("trade.enqueue"),

  getTradeSchedule:
    makeVendorRequest<
      {
        revenueStreamIDs: string[] | readonly string[];
        termLength: number;
      },
      {
        count: number;
        payout: IScheduleEntry;
        payments: IScheduleEntry[];
      }
    >("trade.get_schedule"),

  tradeAction:
    makeVendorRequest<
      {
        id: string;
        type: "accept" | "reject";
      },
      {
        status: string;
        backgroundJobID: string;
      }
    >("trade.action"),

  tradePreview:
    makeVendorRequest<
      {
        payout: number;
        termLength: number;
        currency: string;
      },
      {
        id: string;
        revenueStreams: {
          ID: string;
          currency: string;
          customer: string;
          tradedTermLength: number;
          mrr: number;
          tradedMRR: number;
          payout: number;
        }[];
        balance: number;
        bidPrice: number;
        schedule: {
          payout: IScheduleEntry;
          payments: IScheduleEntry[];
        };
      }
    >("trade.preview"),

  tradeQuotes:
    makeVendorRequest<
      {
        currency: string;
      },
      {
        quotes: Record<string, Quote>;
      }
    >("trade.quotes"),

  addVendorToWaitlist: makeVendorRequest<{ product: string }, never>("waitlist.add_product"),

  treasuryProvisionAccount: makeVendorRequest("treasury.provision_account"),
  treasuryDeposit:
    makeVendorRequest<ITreasuryDeposit, { id: string; time: string }>("treasury.deposit"),
  treasuryWithdrawal: makeVendorRequest<ITreasuryWithdrawal, never>("treasury.withdrawal"),
  updateVendor: makeVendorRequest<IUpdateVendorArgs, never>("vendor.update"),

  updateUser: makeVendorRequest<IUpdateUserArgs, never>("profile.update"),

  inviteUser:
    makeVendorRequest<
      {
        name: string;
        email: string;
        role: string;
      },
      never
    >("invites.send"),

  setUserEnabled:
    makeVendorRequest<
      {
        userPublicID: string;
        enabled: boolean;
      },
      never
    >("user.set_enabled"),

  setUserRole:
    makeVendorRequest<
      {
        userPublicID: string;
        roleName: string;
      },
      never
    >("permissions.set_role"),

  linkAppleAccount:
    makeVendorRequest<{ vendorNumber: string; reporterToken: string }, Empty>(
      "data_sources.apple.link",
    ),

  linkChargebeeAccount: makeVendorRequest<{ subdomain: string; apiKey: string }, Empty>(
    "data_sources.chargebee.link",
  ),

  linkChargifyAccount: makeVendorRequest<{ subdomain: string; apiKey: string }, Empty>(
    "data_sources.chargify.link",
  ),

  linkGoCardlessAccount: makeVendorRequest<{ code: string; redirectURI: string }, Empty>(
    "data_sources.gocardless.link",
  ),

  linkPayPalAccount: makeVendorRequest<{ code: string }, Empty>("data_sources.paypal.link"),

  linkRecurlyAccount: makeVendorRequest<{ subdomain: string; apiKey: string }, Empty>(
    "data_sources.recurly.link",
  ),

  linkStripeAccount: makeVendorRequest<{ code: string }, Empty>("data_sources.stripe.link"),

  linkPlaidItem:
    makeVendorRequest<
      {
        publicToken: string;
        institutionID: string;
        accountMasks: string[];
      },
      Empty
    >("plaid_item.link"),

  relinkPlaidItem:
    makeVendorRequest<
      {
        publicID: string;
      },
      Empty
    >("plaid_item.relink"),

  createPlaidLinkToken:
    makeVendorRequest<
      | {
          // Provide a country code when linking a new item.
          countryCode: PlaidEnabledCountryCode;
        }
      | {
          // Provide a public ID when relinking an existing item.
          publicID: string;
        },
      {
        token: string;
      }
    >("plaid_link_token.create"),

  getCodatLinkURL:
    makeVendorRequest<
      {
        platformKey: ICodatPlatformKey;
      },
      {
        linkURL: string;
        publicID: string;
      }
    >("codat_company.link"),

  completeCodatLink: makeVendorRequest<{ publicID: string }, { linked: boolean }>(
    "codat_company.complete_link",
  ),

  /**
   * Endpoint used to cache queries that vendors make when searching for integrations
   */
  saveIntegrationsQuery: makeVendorRequest<
    {
      query: string;
      context: string;
    },
    never
  >("integration_queries.create"),

  getSubscriptionsOverview:
    makeVendorRequest<
      Empty,
      {
        pendingVendorPaymentSum: number;
        approvedVendorPaymentSum: number;
        /**
         * Total amount of credit allotted used in cents
         * For more info, see: https://www.notion.so/pipetechnologies/Credit-Limits-3af3fb8fb20646e48e597aea8b7017fe
         */
        creditLimit: number;
        /**
         * Total amount of credit used in cents
         * For more info, see: https://www.notion.so/pipetechnologies/Credit-Limits-3af3fb8fb20646e48e597aea8b7017fe
         */
        creditUsage: number;
      }
    >("overview.get"),

  /**
   * Given an uploaded file and categorization,
   * creates an entry in `uploaded_vendor_data` table and kicks
   * off a background job to process the previously uploaded file.
   */
  submitVendorDataFile: makeVendorRequest<
    (
      | {
          manualDataSourcePublicID: string;
        }
      | {
          manualDataSourceLabel: string;
        }
    ) & {
      model: IUploadedVendorDataSourceModel;
      fileSlug: string;
    },
    never
  >("manual_data_source.submit_uploaded_vendor_data"),

  updateManualDataSourceLabel: makeVendorRequest<
    {
      publicID: string;
      label: string;
    },
    never
  >("manual_data_source.update_label"),

  getFileUploadUrl:
    makeVendorRequest<
      {
        fileName: string;
        contentType: string;
      },
      { uploadURL: string; fileName: string }
    >("file_upload_url.get"),

  getFileDownloadURL:
    makeVendorRequest<
      {
        fileName: string;
      },
      {
        downloadURL: string;
      }
    >("file_download_url.get"),

  createAPIKey:
    makeVendorRequest<
      Empty,
      {
        key: string;
        id: string;
      }
    >("apikey.create"),

  deleteAPIKey:
    makeVendorRequest<
      {
        id: string;
      },
      Empty
    >("apikey.delete"),

  updateAPIKeyIPAllowlist: makeVendorRequest<
    {
      apiKeyID: string;
      enabled: boolean;
    },
    Empty
  >("apikey.ip_allowlist.update"),

  addAPIKeyIPAllowlist:
    makeVendorRequest<
      {
        apiKeyID: string;
        ip: string;
      },
      Empty
    >("apikey.ip_allowlist.add"),

  removeAPIKeyIPAllowlist: makeVendorRequest<
    {
      apiKeyID: string;
      ip: string;
    },
    Empty
  >("apikey.ip_allowlist.remove"),

  sandboxUserCreate: makeVendorRequest<
    { name: string; emailUserName: string },
    { email: string; link: string }
  >("apikey.sandbox_user.create"),
  sandboxUserEnabledToggle: makeVendorRequest<{ email: string }, Empty>(
    "apikey.sandbox_user.enabled.toggle",
  ),
  sandboxUserPasswordReset: makeVendorRequest<{ email: string }, { link: string }>(
    "apikey.sandbox_user.password.reset",
  ),
  sandboxUsersList: makeVendorRequest<Empty, [{ name: string; email: string; isEnabled: boolean }]>(
    "apikey.sandbox_users.list",
  ),
};
