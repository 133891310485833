import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useButton } from "@react-aria/button";
import React from "react";

import { LoadingDots } from "~src/designSystem/loading/LoadingDots";
import { IBoxStyles, useBoxProps, withBoxStyles } from "~src/shared/helpers/sharedComponentProps";
import { IPipeTheme } from "~src/shared/theme/darkVariant";

export type IButtonProps = {
  size?: "small" | "medium" | "large";
  kind?: keyof IPipeTheme["components"]["ButtonAria"];
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  type?: "button" | "reset" | "submit";
  loading?: boolean;
} & IBoxStyles &
  Pick<React.HTMLProps<HTMLButtonElement>, "onClick">;

/** This is the new button using React Aria. Intended to replace the button component */
export const Button: React.FC<IButtonProps> = (props) => {
  const ref = React.useRef(null);
  const { buttonProps } = useButton(props, ref);

  const theme = useTheme();

  const boxProps = useBoxProps(props);

  const selectedKind = theme.components.ButtonAria[props.kind || "primary"];

  return (
    <StyledButton
      {...(buttonProps as React.ButtonHTMLAttributes<HTMLButtonElement>)}
      ref={ref}
      kind={props.kind}
      disabled={props.disabled}
      size={props.size}
      className={props.className}
      {...boxProps}
    >
      {props.loading === true && (
        <LoadingContainer>
          <LoadingDots
            size={22}
            frontColor={selectedKind.color.default}
            backColor={`${selectedKind.color.default}88`}
          />
        </LoadingContainer>
      )}
      <span style={{ visibility: props.loading === true ? "hidden" : "visible" }}>
        {props.children}
      </span>
    </StyledButton>
  );
};

const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.button<IButtonProps>`
  position: relative;
  ${(props) => props.theme.textStyles.Regular.Body200}
  border-radius: 6px;

  ${(props) => {
    switch (props.size) {
      case "large":
        return css`
          padding: 12px 16px;
        `;
      case "small":
        return css`
          padding: 4px 8px;
        `;
      case "medium":
      default:
        return css`
          padding: 8px 12px;
        `;
    }
  }};

  ${(props) => {
    const selectedKind = props.theme.components.ButtonAria[props.kind || "primary"];
    return css`
      background: ${selectedKind.background.default};
      color: ${selectedKind.color.default};
      border: 1px solid ${selectedKind.border.default};
      transition: ${props.theme.transition};
      &:hover {
        background: ${selectedKind.background.hover};
        color: ${selectedKind.color.hover};
        border: 1px solid ${selectedKind.border.hover};
        cursor: pointer;
      }
      &:active {
        background: ${selectedKind.background.active};
        color: ${selectedKind.color.active};
        border: 1px solid ${selectedKind.border.active};
      }
      &:disabled {
        background: ${selectedKind.background.disabled};
        color: ${selectedKind.color.disabled};
        border: 1px solid ${selectedKind.border.disabled};
        cursor: initial;
      }
    `;
  }}

  ${(props) => withBoxStyles(props)}
`;
