import React from "react";

import { ICommandRunner } from "~src/shared/command/types";
import { IconColumnType, IconFilter } from "~src/shared/lists/icons";
import { IEditableList, IListConfig, IListFilter } from "~src/shared/lists/types";
import { IListModel, IListModelType } from "~src/shared/lists/types/models";
import { columnForModel } from "~src/shared/lists/utils/config";
import { filtersForDataType } from "~src/shared/lists/utils/operators";

import { OperatorIntersection } from "../../legacyGOAT/goats/icons/operators/OperatorIntersection";
import { ICommandKMode } from "../types";
import {
  ICommandKModeHandler,
  ICommandKModeRendererArgs,
  ICommandKSection,
  ICommandKSectionType,
  ICommandKTile,
} from "../types/internal";
import { fuzzyMatch } from "../utils";

const makeListFilterSelectOperatorModeSections = <M extends IListModel>({
  args,
  env: { searchText, runCommand },
}: ICommandKModeRendererArgs<ICommandKMode.LIST_FILTER_SELECT_OPERATOR>): readonly ICommandKSection[] => {
  const { column, existingFilter } = args;
  const list = args.list as unknown as IEditableList<M>;
  const { model } = list.config as IListConfig<M>;
  const columnInfo = columnForModel(model, column as keyof IListModelType<M>);

  const filters = filtersForDataType(columnInfo.data_type);
  return [
    {
      title: "Operators",
      options: filters
        .filter(({ label }) => fuzzyMatch(label, searchText))
        .map(({ label, operator, unary }) => ({
          id: operator,
          label,
          icon: <OperatorIntersection />,
          description: null,
          onSelect: () => {
            if (unary === true) {
              runCommand(ICommandRunner.COMMAND_K, "list-builder/replace-filter", {
                filter: {
                  column,
                  operator,
                } as IListFilter<IListModel>,
                existingFilter,
              });
              return null;
            }
            return {
              mode: ICommandKMode.LIST_FILTER_SELECT_OPERAND,
              args: {
                list: list as unknown as IEditableList<IListModel>,
                column,
                operator,
              },
            };
          },
        })),
      type: ICommandKSectionType.OPTIONS,
    },
  ];
};

export const listFilterSelectOperatorHandler: ICommandKModeHandler<ICommandKMode.LIST_FILTER_SELECT_OPERATOR> =
  {
    header: (args) => {
      const { model } = args.list.config;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const column = columnForModel(model, args.column as any);

      return [
        {
          title: "Filter",
          icon: <IconFilter />,
        },
        {
          title: column.name, // FIXME
          icon: <IconColumnType renderType={column.render_type} />,
        },
      ] as readonly ICommandKTile[];
    },
    render: (args) => {
      return {
        sections: makeListFilterSelectOperatorModeSections(args),
      };
    },
  };
