const baseOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  cache: "no-store" as const,
  credentials: "include" as const,
};

type IOptions = {
  headers?: Record<string, string>;
};

export const clientSideFetch = async <I>(
  url: string,
  payload: I,
  options?: IOptions,
): Promise<Response> => {
  return fetch(url, {
    ...baseOptions,
    headers: { ...baseOptions.headers, ...options?.headers },
    body: JSON.stringify(payload),
  });
};
