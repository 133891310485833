import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";

import { useCommandKInternal } from "../hooks/useCommandKInternal";
import { CommandKContent } from "./CommandKContent";
import { CommandKSearch } from "./CommandKSearch";
import { CommandKTileGroup } from "./CommandKTileGroup";

export const CommandKModal: React.FC = () => {
  const {
    hide,
    state: { isVisible },
  } = useCommandKInternal();

  return (
    <AnimatePresence>
      {isVisible && (
        <FullPageWrapper onClick={hide}>
          <CommandKWrapper
            key="command-k-modal"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{
              opacity: 1,
              scale: 1,
            }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.1 }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <CommandKTileGroup />
            <CommandKSearch />
            <CommandKContent />
          </CommandKWrapper>
        </FullPageWrapper>
      )}
    </AnimatePresence>
  );
};

const FullPageWrapper = styled(motion.div)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  left: 0;
  top: 0;
`;

const CommandKWrapper = styled(motion.div)`
  margin: 0px auto;
  margin-top: 10vh;
  width: 664px;
  height: 488px;
  background-color: ${(props) => props.theme.old["command-k"].base};
  box-shadow: 0px 4px 50px rgba(135, 135, 135, 0.5);
  backdrop-filter: blur(4px);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
`;
