import styled from "@emotion/styled";
import React from "react";

export type IStatusTagProps = {
  color: IStatusTagColor;
  label?: string;
  children?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
};
export type IStatusTagColor = "red" | "orange" | "yellow" | "green" | "blue" | "purple" | "neutral";

export const StatusTag: React.FC<IStatusTagProps> = styled((props: IStatusTagProps) => {
  return (
    <StyledStatusTag {...props} color={props.color}>
      {props.label ?? props.children}
    </StyledStatusTag>
  );
})``;

const StyledStatusTag = styled.div<IStatusTagProps>`
  display: flex;
  justify-content: start;

  width: max-content;
  height: fit-content;

  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;

  padding: 3px 7px 4px 6px;

  border-radius: 4px;

  background: ${(props) => props.theme.components.StatusTag.base[props.color]};
  color: ${(props) => props.theme.components.StatusTag.text[props.color]};
`;
