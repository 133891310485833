import numeral from "numeral";
import React from "react";

import { ICommandRunner } from "~src/shared/command/types";
import { OperatorIcon } from "~src/shared/legacyGOAT/goats/icons/OperatorIcon";
import { IconColumnType, IconFilter } from "~src/shared/lists/icons";
import { IDataType, IRenderType } from "~src/shared/lists/types/types";
import { columnForModel } from "~src/shared/lists/utils/config";
import { operatorToString } from "~src/shared/lists/utils/operators";

import { ICommandKMode } from "../types";
import {
  ICommandKModeHandler,
  ICommandKModeRendererArgs,
  ICommandKSection,
  ICommandKSectionType,
  ICommandKTile,
} from "../types/internal";
import { parseDate } from "../utils/parseDate";

const timestampHandler = (
  args: ICommandKModeRendererArgs<ICommandKMode.LIST_FILTER_SELECT_OPERAND>,
): readonly ICommandKSection[] => {
  const {
    env: { searchText, runCommand },
    args: { column, operator, existingFilter },
  } = args;

  const suggestions = parseDate({
    query: searchText,
    parseTime: false,
    hour: 0,
    minute: 0,
    fallback: [],
    ref: new Date(),
    options: { forwardDate: true },
  });
  if (suggestions.length === 0) return [];

  return [
    {
      title: "Suggested",
      options: suggestions.map((s) => {
        return {
          id: s.label,
          label: s.label,
          icon: <IconColumnType renderType={IRenderType.date} />,
          description: s.date.format("ll"),
          onSelect: () => {
            let value = s.date.format("YYYY-MM-DDTHH:mm:ss");
            if (value.endsWith("T00:00:00")) {
              value = s.date.format("YYYY-MM-DD");
            }
            runCommand(ICommandRunner.COMMAND_K, "list-builder/replace-filter", {
              filter: {
                column,
                operator,
                value,
              },
              existingFilter,
            });
            return null;
          },
        };
      }),
      type: ICommandKSectionType.COMMANDS,
    },
  ];
};

const numericHandler = (
  args: ICommandKModeRendererArgs<ICommandKMode.LIST_FILTER_SELECT_OPERAND>,
): readonly ICommandKSection[] => {
  const {
    env: { searchText, runCommand },
    args: { column, operator, existingFilter },
  } = args;

  // numeraljs is stupid and doesn't validate negative numbers properly.
  // https://github.com/adamwdraper/Numeral-js/issues/213
  let s = searchText;
  let sign = 1;
  if (s.startsWith("-")) {
    s = s.substring(1);
    sign = -1;
  }

  const n = numeral(s);
  const value = n.value();
  if (value === null) return [];

  return [
    {
      title: "Suggested",
      options: [
        {
          id: String(n.value),
          label: (sign === -1 ? "-" : "") + n.format("0,0"),
          icon: <IconColumnType renderType={IRenderType.numeric} />,
          description: "",
          onSelect: () => {
            runCommand(ICommandRunner.COMMAND_K, "list-builder/replace-filter", {
              filter: {
                column,
                operator,
                value: value * sign,
              },
              existingFilter,
            });
            return null;
          },
        },
      ],
      type: ICommandKSectionType.COMMANDS,
    },
  ];
};

export const listFilterSelectOperandHandler: ICommandKModeHandler<ICommandKMode.LIST_FILTER_SELECT_OPERAND> =
  {
    searchPlaceholder: (args) => {
      const { model } = args.list.config;
      const column = columnForModel(model, args.column);

      const { data_type } = column;
      switch (data_type) {
        case IDataType.timestamp:
          return "Enter a date, then press ⏎";
        case IDataType.numeric:
          return "Enter a number, then press ⏎";
        case IDataType.text:
          return "Type a keyword to filter by, then press ⏎";
        default:
          throw new Error(`unsupported data_type: ${data_type}`);
      }
    },
    header: (args) => {
      const { model } = args.list.config;
      const column = columnForModel(model, args.column);
      return [
        {
          title: "Filter",
          icon: <IconFilter />,
        },
        {
          title: column.name, // FIXME
          icon: <IconColumnType renderType={column.render_type} />,
        },
        {
          title: operatorToString(args.operator),
          icon: <OperatorIcon />,
        },
      ] as readonly ICommandKTile[];
    },
    render: (args) => {
      const { model } = args.args.list.config;
      const column = columnForModel(model, args.args.column);

      const { data_type } = column;
      switch (data_type) {
        case IDataType.timestamp:
          return {
            sections: timestampHandler(args),
          };
        case IDataType.numeric:
          return {
            sections: numericHandler(args),
          };
        case IDataType.text:
          break;
        default:
          throw new Error(`unsupported data_type: ${data_type}`);
      }

      return {
        sections: [],
      };
    },
  };
