import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { SentryLink } from "apollo-link-sentry";
import toast from "react-hot-toast";

import { TypedTypePolicies } from "~src/__generated__/graphql/apolloHelpers";
import { logError, logErrorEventAndToast } from "~src/shared/helpers";

const typePolicies: TypedTypePolicies = [
  "customers",
  "lists",
  "subscription_payments",
  "transactions",
  "users",
  "vendor_payments",
  "vendors",
].reduce(
  (acc, el) => ({
    ...acc,
    [el]: {
      keyFields: ["public_id"],
    },
  }),
  {},
);

export const createClientSideApolloClient = (
  endpoint: string,
  impersonateAsVendorID: string | null,
): ApolloClient<NormalizedCacheObject> => {
  const httpLink = new HttpLink({
    uri: endpoint,
    credentials: "include",
    headers:
      impersonateAsVendorID !== null
        ? { "X-Impersonate-Vendor": impersonateAsVendorID }
        : undefined,
  });

  // TODO(md): Fix this up and integrate it with our erroring system & associated
  // transient/expected codes.
  const errorLink = onError((err) => {
    if (err.networkError != null) {
      if (
        // For now, just do it by hand (dirty!).
        err.networkError.message !== "Failed to fetch" &&
        // I'm not sure how to get the status code (thanks Apollo).
        err.networkError.message !== "Response not successful: Received status code 401"
      ) {
        logError(err.networkError, {
          message: `GQL Network error: ${err.networkError.message}`,
          extra: {
            operationName: err.operation.operationName,
            variables: JSON.stringify(err.operation.variables, null, 2),
          },
          tags: {
            "graphql.operationName": err.operation.operationName,
            "category": "graphql.network",
          },
        });
      }
      toast.error("Failed to fetch data.", { id: "graphql-failed-to-fetch" });
    }
    if (err.graphQLErrors != null && err.graphQLErrors.length > 0) {
      logErrorEventAndToast({
        message: `[GraphQL Error]: ${err.operation.operationName}: ${err.graphQLErrors
          .map((gqlErr) => gqlErr.message)
          .join("; ")}`,
        tags: {
          "graphql.operationName": err.operation.operationName,
          "category": "graphql",
        },
      });
    }
  });

  const link = from([
    errorLink,
    new SentryLink({
      // don't set transaction name b/c it's misleading
      setTransaction: false,
      breadcrumb: {
        includeQuery: true,
        includeError: true,
      },
    }),
    httpLink,
  ]);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache({
      typePolicies,
    }),
  });

  client.defaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  };

  return client;
};
