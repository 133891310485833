import styled from "@emotion/styled";

import { ICommandKTile } from "../types/internal";

export const CommandKTile: React.FC<ICommandKTile> = ({ icon, title }) => {
  return (
    <Tile>
      {icon}
      <span>{title}</span>
    </Tile>
  );
};

const Tile = styled.div`
  display: flex;
  align-items: center;

  line-height: 1;
  margin-right: 8px;
  ${{
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "28px",
  }};
  svg {
    margin-right: 8px;
    color: ${(props) => props.theme.old.icon.default};
  }
  color: ${(props) => props.theme.old.text.default};
  height: 32px;
  padding: 0 8px;
  background-color: ${(props) => props.theme.old.base.default};
  border: 1px solid ${(props) => props.theme.old.border.default};
  border-radius: 3px;
  user-select: none;
`;
