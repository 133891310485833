import styled from "@emotion/styled";
import { KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";
import { Key } from "ts-key-enum";
import { useThrottledCallback } from "use-debounce";

import { useRunCommand } from "~src/shared/command/hooks/useRunCommand";
import { ICommandRunner } from "~src/shared/command/types";
import { useModKey } from "~src/shared/modKey/useModKey";

import { useCommandKInternal } from "../hooks/useCommandKInternal";
import { BackIcon } from "../icons/BackIcon";

export const CommandKSearch: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const isModPressed = useModKey();
  const runCommand = useRunCommand();
  const {
    hide,
    state: { searchText, searchPlaceholder, modeHistory },
    setSearch,
    back,
  } = useCommandKInternal();

  const throttledSetSearch = useThrottledCallback((nextSearch: string) => {
    setSearch(nextSearch);
  }, 100);

  // We don't want to hose the browser by issuing a lot of underlying state changes to the
  // cmd+K modal. So instead we update the rendered search text quickly as people are typing
  // and only after 100ms issue an update to the underlying cmd+K search.
  const [renderedSearch, setRenderedSearch] = useState<string>(searchText);

  const fastSetSearch = useCallback(
    (nextSearch: string) => {
      setRenderedSearch(nextSearch);
      throttledSetSearch(nextSearch);
    },
    [setRenderedSearch, throttledSetSearch],
  );

  // always focus the input
  useEffect(() => {
    if (inputRef.current !== null && inputRef.current !== document.activeElement) {
      inputRef.current.focus();
    }
  });

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      // Since the input is always in focus, we need to capture these shortcuts here.
      // It's looks fugly but is better UX.
      if (e.key === Key.Enter) {
        runCommand(ICommandRunner.KEYBOARD, "command-k/select");
      } else if (e.key === Key.ArrowDown) {
        runCommand(ICommandRunner.KEYBOARD, "command-k/down");
      } else if (e.key === Key.ArrowUp) {
        runCommand(ICommandRunner.KEYBOARD, "command-k/up");
      } else if (e.key === Key.Escape || (e.key === "k" && isModPressed)) {
        hide();
      } else if (e.key === Key.ArrowLeft && isModPressed) {
        back();
      } else {
        return;
      }

      e.preventDefault();
      e.stopPropagation();
    },
    [hide, back, runCommand, isModPressed],
  );

  return (
    <Wrapper>
      {modeHistory.length > 0 && (
        <BackButton onClick={back}>
          <BackIcon />
        </BackButton>
      )}
      <CommandKSearchInput
        ref={inputRef}
        placeholder={searchPlaceholder ?? "Start typing to search..."}
        value={renderedSearch}
        onChange={(e) => {
          fastSetSearch(e.target.value);
        }}
        onKeyDown={onKeyDown}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 0 16px;
  margin-bottom: 17.5px;
`;

const BackButton = styled.button`
  width: 28px;
  height: 28px;
  background: ${(props) => props.theme.old.base.default};
  border-radius: 14px;
  svg {
    width: 6px;
    height: 9px;
    color: ${(props) => props.theme.old.icon.default};
  }
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  :hover {
    background: ${(props) => props.theme.old.border.default};
  }
`;

const CommandKSearchInput = styled.input`
  font-size: 18px;
  line-height: 19px;
  letter-spacing: -0.175px;
  color: ${(props) => props.theme.old.text.emphasized};
  caret-color: ${(props) => props.theme.old["command-k"].cursor};

  ${{
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "28px",
  }};
  ::placeholder {
    color: ${(props) => props.theme.old.text["very-muted"]};
  }

  background-color: transparent;
  border: none;
  box-sizing: border-box;
  width: calc(100% - 16px * 2);
  /* border-bottom: 1px solid ${(props) => props.theme.old.border.muted}; */

  :focus {
    outline: none;
  }

  ::selection {
    background: ${(props) => props.theme.old.text["very-muted"]};
  }
`;
