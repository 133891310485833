import { ICommandKMode } from "../types";
import { ICommandKModeHandler } from "../types/internal";
import { commandsHandler } from "./makeCommandModeSections";
import { listEditColumnsHandler } from "./makeListEditColumnsModeSections";
import { listEditFiltersHandler } from "./makeListEditFiltersModeSections";
import { listFilterSelectOperandHandler } from "./makeListFilterSelectOperandModeSections";
import { listFilterSelectOperatorHandler } from "./makeListFilterSelectOperatorModeSections";
import { listSearchHandler } from "./makeListSearchModeSections";

/**
 * Handlers of all modes possible in the Command K modal.
 * These handle rendering the modal in a given mode.
 *
 * TODO(usmanm): we should probably move the lists modes into src/shared/lists
 */
export const modeHandlers: { [M in ICommandKMode]: ICommandKModeHandler<M> } = {
  [ICommandKMode.COMMANDS]: commandsHandler,
  [ICommandKMode.LIST_EDIT_COLUMNS]: listEditColumnsHandler,
  [ICommandKMode.LIST_FILTER]: listEditFiltersHandler,
  [ICommandKMode.LIST_FILTER_SELECT_OPERATOR]: listFilterSelectOperatorHandler,
  [ICommandKMode.LIST_FILTER_SELECT_OPERAND]: listFilterSelectOperandHandler,
  [ICommandKMode.LIST_SEARCH]: listSearchHandler,
};
