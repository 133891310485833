import { css, keyframes, SerializedStyles } from "@emotion/react";

/**
 * Total number of loops of the animation to execute. This number can be as high as we want.
 */
const TOTAL_LOOPS = 60 * 60;

/**
 * Duration of one loop of the progress bar animation
 */
const LOOP_DURATION_SECONDS = 5;

/**
 * Size of the background image.
 * We use 50% because our gradients usually loop once.
 */
const VIEWPORT_SIZE = 0.5;

/**
 * Total number of times the animation will loop
 */
const TOTAL_LOOP_DURATION = TOTAL_LOOPS * LOOP_DURATION_SECONDS * VIEWPORT_SIZE;

/**
 * The animation of the gradient.
 * It repeats the background TOTAL_LOOPS times.
 */
const GRADIENT_ANIMATION = keyframes`
  from {
    background-position: 0% ${(VIEWPORT_SIZE * 100).toFixed(2)}%;
  }
  to {
    background-position: ${(TOTAL_LOOPS * 100) / VIEWPORT_SIZE - VIEWPORT_SIZE * 100}% ${
  (TOTAL_LOOPS * 100) / VIEWPORT_SIZE
}%;
  }
`;

/**
 * Renders a background. If it's a gradient, it animates it.
 * @param color The color of the background.
 */
export const liveBackground = (color: string): SerializedStyles => {
  if (color.includes("gradient")) {
    return css`
      background: ${color};
      background-size: ${(100 / VIEWPORT_SIZE).toFixed(2)}%;
      animation: ${GRADIENT_ANIMATION} ${TOTAL_LOOP_DURATION}s ease infinite;
    `;
  }
  return css`
    background-color: ${color};
  `;
};
