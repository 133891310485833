import { IListFilter } from "~src/shared/lists/types";
import { getListModelConfig, IListModel } from "~src/shared/lists/types/models";
import { Filters, IFilter, IFilterOp } from "~src/shared/lists/types/operators";
import { IDataType } from "~src/shared/lists/types/types";

export const filterToString = <M extends IListModel>(m: M, lf: IListFilter<M>): string => {
  const filter = Filters.find((f) => f.operator === lf.operator);
  if (filter === undefined) {
    throw new Error(`unknown filter: ${lf.operator}`);
  }

  const modelConfig = getListModelConfig(m);
  const column = modelConfig.columns[lf.column];

  if (filter.unary === true) {
    return `${column.name} ${filter.label.toLowerCase()}`;
  }
  return `${column.name} ${filter.label.toLowerCase()} ${lf.value}`;
};

const filterOpsForDataType: {
  [key in IDataType]: readonly IFilterOp[];
} = {
  [IDataType.boolean]: [IFilterOp.is_false, IFilterOp.is_true, IFilterOp.is_missing],
  [IDataType.numeric]: [
    IFilterOp.eq,
    IFilterOp.gt,
    IFilterOp.gte,
    IFilterOp.lt,
    IFilterOp.lte,
    IFilterOp.neq,
    IFilterOp.is_missing,
  ],
  [IDataType.text]: [
    IFilterOp.contains,
    IFilterOp.eq,
    IFilterOp.in,
    IFilterOp.neq,
    IFilterOp.nin,
    IFilterOp.is_missing,
  ],
  [IDataType.timestamp]: [IFilterOp.after, IFilterOp.before, IFilterOp.is_missing],
};

export const filtersForDataType = (t: IDataType): IFilter[] => {
  const operators = filterOpsForDataType[t];
  return Filters.filter((f) => operators.includes(f.operator));
};

export const operatorToString = (op: IFilterOp): string => {
  const filter = Filters.find((f) => f.operator === op);
  if (filter === undefined) {
    throw new Error(`unknown filter op: ${op}`);
  }
  return filter.label;
};
