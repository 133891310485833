/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { MutableRefObject, useEffect, useRef, useState } from "react";

const invalidSrcs: Set<string> = new Set();

// TODO: Split these up into their own files.

/**
 * Checks asynchronously if an image URL is valid, returning the image
 * @param src
 * @returns
 */
export const createValidatedImageFromSrc = async (
  src?: string,
): Promise<HTMLImageElement | null> => {
  if (!src || invalidSrcs.has(src)) {
    return null;
  }
  const img = document.createElement("img");
  return new Promise((resolve) => {
    let resolved = false;
    img.onerror = () => {
      if (!resolved) {
        resolved = true;
        resolve(null);
        invalidSrcs.add(src);
      }
    };
    img.onload = () => {
      if (!resolved) {
        resolved = true;
        resolve(img);
      }
    };
    img.src = src;
  });
};

/** Hook for checking if an image is valid. Returns isValid which is false until src exists. */
export const useSrcValidator = (src?: string): boolean => {
  const [isValid, setValid] = useState<boolean>(
    typeof window !== "undefined"
      ? src !== undefined && !invalidSrcs.has(src)
      : // server will always return the img tag
        true,
  );
  useEffect(() => {
    if (typeof src !== "string") {
      return;
    }
    const img = document.createElement("img");
    const mounted = { mounted: true };
    img.onerror = () => {
      if (mounted.mounted) {
        setValid(false);
        invalidSrcs.add(src);
      }
    };
    img.onload = () => {
      if (mounted.mounted) {
        setValid(true);
      }
    };
    img.src = src;
    return () => {
      mounted.mounted = false;
    };
  }, [src]);

  return isValid;
};

/** Hook that calls the given function until a condition is reached. Interval defaults to 1s. */
export const usePoll = (
  pollingFunction: () => void,
  until: () => boolean,
  interval?: number,
): void => {
  const ref = useRef<NodeJS.Timeout>(null);
  useEffect(() => {
    (ref as MutableRefObject<NodeJS.Timeout>).current = setInterval(() => {
      if (until() && ref.current) {
        clearInterval(ref.current);
      } else {
        pollingFunction();
      }
    }, interval || 1000);
    const cleanupRef = ref.current;
    return () => {
      if (cleanupRef) {
        clearInterval(cleanupRef);
      }
    };
  });
};

interface ScriptLink {
  type: "link";
  url: string;
}
interface ScriptCode {
  type: "code";
  code: string;
}

type Script = ScriptCode | ScriptLink;

// Appends an async and deferred script tag to the <body> in such a way that it runs.
// Cannot use innerHTML because that will not execute the script.
// Based on https://stackoverflow.com/a/46654077
const useInstallScript = (
  params: Script,
  id: string,
  isDisabled?: boolean,
  reinstall?: boolean,
): void => {
  useEffect(() => {
    if (isDisabled) {
      return;
    }
    const existingScriptTag = document.getElementById(id);
    if (existingScriptTag) {
      return;
    }

    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.id = id;

    switch (params.type) {
      case "link":
        script.src = params.url;
        break;
      case "code":
        script.appendChild(document.createTextNode(params.code));
        break;
    }

    document.body.appendChild(script);
    // If reinstall is true, then every time the parent component is lifecycled we will
    // remove the script. Otherwise, we only install it once.
    if (reinstall) {
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [id, isDisabled, params, reinstall]);
};

export const useInstallScriptLink = (
  url: string,
  id: string,
  isDisabled?: boolean,
  reinstall?: boolean,
): void => {
  const params: ScriptLink = { type: "link", url };
  useInstallScript(params, id, isDisabled, reinstall);
};

export const useInstallScriptCode = (
  code: string,
  id: string,
  isDisabled?: boolean,
  reinstall?: boolean,
): void => {
  const params: ScriptCode = { type: "code", code };
  useInstallScript(params, id, isDisabled, reinstall);
};
