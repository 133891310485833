import LogRocket from "logrocket";

import { IEnvironment } from "~src/shared/env";
import { ConsoleLog, isWindowDefined } from "~src/shared/helpers";
import { stringToBool } from "~src/shared/helpers/booleanCoercion";
import { IGetUserProfileData } from "~src/shared/requests/authedRequests";
import { logRocketIdentify, logRocketOptions } from "~src/shared/thirdParties/logrocket";

type IInitialize = {
  logrocket: boolean;
  autopilot: boolean;
  segment: boolean;
};

/**
 * Function that initializes our frontend analytics services.
 * Currently: "logrocket" | "autopilot" | "segment"
 * @param env
 * @param user
 */
export const analyticsIdentify = (
  env: IEnvironment,
  user: IGetUserProfileData,
  initialize: IInitialize,
): void => {
  const { name, email, publicID: userID } = user;

  ConsoleLog("[AnalyticsIdentify] User:", userID, name, email);
  ConsoleLog("[AnalyticsIdentify] Services: ");

  // Only run in browser
  if (isWindowDefined) {
    // LogRocket
    if (initialize.logrocket && stringToBool(env.LOGROCKET_APP_ID)) {
      ConsoleLog("- LogRocket");
      try {
        LogRocket.init(env.LOGROCKET_APP_ID, logRocketOptions);
        if (stringToBool(email)) {
          const extraData = {
            userType: user.userType,
            isActivated: user.vendor.isActivated,
            salesFunnelStage: user.vendor.salesFunnelStage,
          };
          logRocketIdentify(name, email, extraData);
        }
      } catch (err) {
        ConsoleLog("^LogRocket Error: ", err);
      }
    }

    // Autopilot
    if (initialize.autopilot) {
      ConsoleLog("- Autopilot");
      try {
        window.Autopilot?.run("associate", email);
      } catch (err) {
        ConsoleLog("^Autopilot Error: ", err);
      }
    }

    // Segment
    if (initialize.segment) {
      ConsoleLog("- Segment");
      try {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        window.analytics?.identify(userID, { email });
      } catch (err) {
        ConsoleLog("^Segment Error: ", err);
      }
    }
  }
};
