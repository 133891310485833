import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import Head from "next/head";
import React from "react";

import { LogoProgressLoader } from "../LogoProgressLoader";

/**
 * Full page loading spinner, used for page load transitions.
 */
export const FullPageLoader: React.FC = () => {
  const theme = useTheme();
  return (
    <FullPageLoaderWrapper
      initial={{ opacity: 1 }}
      animate={{ x: 0, transition: { duration: 0.8 } }}
      exit={{ opacity: 0, scale: 0.5, transition: { delay: 1 } }}
    >
      <Head>
        {/* Default title when loading */}
        <title>Pipe</title>
      </Head>
      <LogoProgressLoader isDark={theme.isDark} />
    </FullPageLoaderWrapper>
  );
};

const FullPageLoaderWrapper = styled(motion.div)`
  top: -150vh;
  left: -150vw;
  width: 400vw;
  height: 400vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9001;
  background-color: ${({ theme }) => theme.components.App.background};
`;
