import styled from "@emotion/styled";
import React from "react";

import { useCommandKInternal } from "../hooks/useCommandKInternal";
import { CommandKTile } from "./CommandKTile";

/**
 * Breadcrumb tiles used in the header of Command K.
 */
export const CommandKTileGroup: React.FC = () => {
  const {
    state: { header },
  } = useCommandKInternal();
  return (
    <Wrapper>
      {header.map(({ icon, title }, i) => (
        <CommandKTile key={i} icon={icon} title={title} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
`;
