/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  createInstance as createOptimizelyInstance,
  OptimizelyProvider,
} from "@optimizely/react-sdk";
import { RewriteFrames } from "@sentry/integrations";
import { init as SentryInit } from "@sentry/nextjs";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { AnimatePresence } from "framer-motion";
import { useRouter } from "next/router";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { user_type_enum_enum } from "~src/__generated__/graphql/types";
import { FullPageLoader } from "~src/designSystem/loading/FullPageLoader";
import { useInitializeAnalytics } from "~src/shared/analytics/useAnalytics";
import { IEnvironment } from "~src/shared/env";
import { ConsoleLog } from "~src/shared/helpers";
import { stringToBool } from "~src/shared/helpers/booleanCoercion";
import { configureSentry } from "~src/shared/thirdParties/sentry";
import { vendorRequests } from "~src/vendor/requests";

import { PipeError } from "../errors/taxonomy";
import { callRequest } from "../requests/useRequest";
import { handleAuthedRedirect } from "./handleAuthedRedirect";
import { useAuthProviderStore, useVendorImpersonateStore } from "./store";
import { IAuthenticationData } from "./types";
import { IAuthActions, useAuthActions } from "./useAuthActions";
// eslint-disable-next-line import/no-restricted-paths
import { useAuthenticationData } from "./useAuthenticationData";
import { useLoadEnv } from "./useLoadEnv";
import { useRedirectOnInitialLoad } from "./useRedirectOnInitialLoad";

export enum IPageAuthorization {
  /**
   * Only admins can view this page.
   */
  ADMIN_ONLY = "ADMIN_ONLY",
  /**
   * Must be logged in to view this page.
   */
  AUTHED = "AUTHED",
  /**
   * Only investors can view this page.
   */
  INVESTOR_ONLY = "INVESTOR_ONLY",
  /**
   * Anyone can view this page.
   */
  PUBLIC = "PUBLIC",
  /**
   * Only vendors can view this page.
   */
  VENDOR_ONLY = "VENDOR_ONLY",
}

interface IAuthContext extends IAuthActions, IAuthenticationData {
  /**
   * The runtime environment.
   */
  env: IEnvironment | null;
  /**
   * Forces a user/vendor data refresh before going to a route.
   * This should be used whenever navigating between pages after an authentication
   * event happened.
   */
  pushRoute: (path: string) => Promise<void>;

  revalidate: () => Promise<IAuthenticationData>;
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  vendor: null,
  pushRoute: async () => {},
  login: async () => {
    // this should never happen
    throw new Error("Auth context not ready");
  },
  /**
   * This function refetches the user and vendor in the AuthContext and updates the
   * cross-page cache.
   */
  revalidate: (async () => {}) as unknown as IAuthContext["revalidate"],
  logout: async () => {},
  env: null,
});

type IProps = PropsWithChildren<{
  authorization: IPageAuthorization;
}>;

export const useAuthContext = (): IAuthContext => useContext(AuthContext);

/**
 * Provides authentication data and handles automatic redirects.
 *
 * This component allows us to statically generate all pages on the frontend without needing
 * to check authorization logic server side. This makes initial load times much faster and makes the
 * build easier.
 *
 * If the user, vendor, or redirect destination are loading and the page requires being logged
 * in, the Pipe loading spinner appears.
 *
 * The user/vendor are persisted between different pages; however, they are reloaded
 * and may trigger a redirect if e.g. cookies expire.
 *
 * On initial page load, a redirect is computed before the page is even loaded in the first place.
 */
export const AuthProvider: React.FC<IProps> = ({ children, authorization }) => {
  const { user, vendor, revalidate, isInitialLoadComplete } = useAuthenticationData();

  const setOptimizelyClient = useAuthProviderStore((s) => s.setOptimizelyClient);
  const optimizelyClient = useAuthProviderStore((s) => s.optimizelyClient);

  const isServer = typeof window === "undefined";

  const env = useLoadEnv();
  const router = useRouter();

  // Initialize analytics services
  useInitializeAnalytics(env, user);

  // If optimizely key changes, refresh the optimizely instance
  useEffect(() => {
    if (!env) {
      return;
    }
    // If we already instantiated Optimizely, bail out.
    if (optimizelyClient) {
      return;
    }
    const _optimizelyClient = createOptimizelyInstance({
      // https://app.optimizely.com/v2/projects/20625981132/settings/implementation
      sdkKey: env.OPTIMIZELY_CLIENT_KEY,
      logLevel: "error",
    });
    setOptimizelyClient(_optimizelyClient);
  }, [env, optimizelyClient, env?.OPTIMIZELY_CLIENT_KEY, setOptimizelyClient]);

  useEffect(() => {
    if (env === null || env.SENTRY_ENVIRONMENT === "local" || env.SENTRY_DSN === "none") {
      return;
    }
    // This configures the initialization of Sentry on the browser.
    // https://docs.sentry.io/platforms/javascript/guides/nextjs/

    SentryInit({
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      dsn:
        env.SENTRY_DSN ||
        "https://0ece8e4b2e014cd29dc11d6b50c23ba7@o398490.ingest.sentry.io/5254295",
      environment: env.SENTRY_ENVIRONMENT ?? undefined,
      release: COMMIT_SHA,
      integrations: [
        // KLUDGE: Decode NextJS' encoded URLs before shipping them off to Sentry.
        // LINK: https://github.com/getsentry/sentry/issues/19713#issuecomment-696614341
        new RewriteFrames({
          iteratee: (frame) => {
            if (frame.filename != null) {
              frame.filename = decodeURI(frame.filename);
            }
            return frame;
          },
        }),
        new TracingIntegrations.BrowserTracing({
          tracingOrigins: ["api.pipe.com", "api.pipe-dev.com", "api.pipe-sandbox.com"],
        }),
      ],
      // Adjust this value in production, or use tracesSampler for greater control
      tracesSampleRate: 0.1,
      beforeSend: (event, hint) => {
        const error = hint?.originalException;

        // Don't report errors that come from local/preview.
        if (event.environment === "local" || event.environment === "preview") {
          return null;
        }

        if (!(error instanceof PipeError)) {
          return event;
        }

        // If the error is an internal error object, we can do some more processing to
        // improve the reporting.

        // Don't report transient errors.
        if (error.transient) {
          return null;
        }

        // Add properties of the error object to the event. The code and expected
        // properties will be made tags; the rest will be additional data.
        if (event.tags === undefined) {
          event.tags = {};
        }
        if (event.extra === undefined) {
          event.extra = {};
        }
        Object.keys(error).forEach((k: keyof typeof error) => {
          if (k === "code" || k === "expected") {
            (event.tags as Record<string, unknown>)[k] = error[k];
          } else {
            (event.extra as Record<string, unknown>)[k] = error[k];
          }
        });

        return event;
      },
      // Note: if you want to override the automatic release value, do not set a
      // `release` value here - use the environment variable `SENTRY_RELEASE`, so
      // that it will also get attached to your source maps
    });
  }, [env]);

  useEffect(() => {
    if (
      user === null ||
      env === null ||
      !stringToBool(env.SENTRY_DSN) ||
      env.SENTRY_ENVIRONMENT === "local" ||
      env.SENTRY_DSN === "none"
    ) {
      return;
    }

    // Initialize Sentry logging
    try {
      configureSentry({
        email: user.email,
        userID: user.publicID,
        name: user.name,
      });
    } catch (err) {
      ConsoleLog("Error: ", err);
    }
  }, [user, env]);

  // Call vendorTrack once every time a vendor user accesses the application.
  const impersonateVendorPublicID = useVendorImpersonateStore((s) => s.impersonateVendorPublicID);
  useEffect(() => {
    // If not a vendor user, stop.
    if (user?.userType !== user_type_enum_enum.vendor_admin) {
      return;
    }
    // If impersonating, stop.
    if (impersonateVendorPublicID !== null) {
      return;
    }

    // This is async; let it fire off in the background.
    callRequest(vendorRequests.trackVisit({}));
  }, [user, impersonateVendorPublicID]);

  const pushRoute = useCallback(
    async (nextPath: string) => {
      const { user: nextUser, vendor: nextVendor } = await revalidate();
      const redirect = handleAuthedRedirect({
        user: nextUser,
        vendor: nextVendor,
        path: nextPath,
        options: {
          investorOnly: authorization === IPageAuthorization.INVESTOR_ONLY,
          vendorOnly: authorization === IPageAuthorization.VENDOR_ONLY,
          authedOnly:
            authorization === IPageAuthorization.AUTHED ||
            authorization === IPageAuthorization.VENDOR_ONLY ||
            authorization === IPageAuthorization.INVESTOR_ONLY ||
            authorization === IPageAuthorization.ADMIN_ONLY,
          adminOnly: authorization === IPageAuthorization.ADMIN_ONLY,
        },
      });
      if (redirect !== null && redirect !== nextPath) {
        await router.replace(redirect);
      } else if (router.asPath !== nextPath) {
        await router.push(nextPath);
      }
    },
    [authorization, revalidate, router],
  );

  const { hasComputedInitialRedirect } = useRedirectOnInitialLoad({
    pushRoute,
  });

  // loading checks
  const isLoadingOrRedirecting = !isInitialLoadComplete || !hasComputedInitialRedirect;
  // don't render the page if it requires auth and we don't have a user yet
  // a redirect will happen eventually
  const noUserOrVendor = user === null || vendor === null;

  const { login, logout } = useAuthActions({ revalidate, pushRoute });

  const shouldRenderChildren =
    // ensure the env exists before rendering children
    env !== null &&
    // make sure we have our a/b testing library loaded before we show the page so we know what to show
    optimizelyClient &&
    // If the user does not need to be logged in to view the page,
    // don't require loading.
    (authorization === IPageAuthorization.PUBLIC || !(isLoadingOrRedirecting || noUserOrVendor));

  const authUserCtx = useMemo(() => {
    return {
      user,
      vendor,
      pushRoute,
      login,
      logout,
      env,
      revalidate,
    };
  }, [env, login, logout, pushRoute, revalidate, user, vendor]);

  return (
    <div suppressHydrationWarning>
      {/* AnimatePresence needs to be as close to the Component as possible for page animations to work */}
      <AnimatePresence>
        {!shouldRenderChildren && typeof window !== "undefined" && <FullPageLoader key="loader" />}
      </AnimatePresence>
      {shouldRenderChildren && optimizelyClient && (
        // Optimizely requires authentication so we can identify the user/vendor
        <OptimizelyProvider
          optimizely={optimizelyClient}
          timeout={500}
          user={{
            id: vendor?.publicID ?? "unknown",
            attributes: {
              vendor_public_id: vendor?.publicID ?? "unknown",
              vendor_country: user?.vendor.country ?? "unknown",
            },
          }}
          isServerSide={isServer}
        >
          <AuthContext.Provider value={authUserCtx}>{children}</AuthContext.Provider>
        </OptimizelyProvider>
      )}
    </div>
  );
};
