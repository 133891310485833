import { ApolloProvider } from "@apollo/client";
import { NextPage } from "next";
import React, { useMemo } from "react";

import { Modal } from "~src/designSystem/deprecated/Modal/Modal";

import { useVendorImpersonateStore } from "../auth/store";
import { useEnv } from "../env/useEnv";
import { ModalProvider } from "../modals/modal-context";
import { IPipeRouter } from "../requests/router";
import { Stepper } from "../stepper/Stepper";
import { StepperProvider } from "../stepper/stepperContext";
import initApollo from "./withApolloNext/initApollo";

export const withApollo = <P extends Record<string, unknown>>(apolloRouter: IPipeRouter) => {
  return (Page: NextPage<P>): NextPage<P> => {
    const Wrapper: React.FC<P> = (props: P) => {
      const env = useEnv();

      const client = useMemo(
        () => {
          const { impersonateVendorPublicID } = useVendorImpersonateStore.getState();
          return initApollo(`${env.API_URL}/v1/${apolloRouter}/graphql`, impersonateVendorPublicID);
        },
        // Whenever impersonateVendorPublicID changes, we force refresh the page. We do
        // this to avoid refetching all GraphQL data whenever we (un)impersonate, which
        // occurs whenever we re-instantiate the client here. The attempted refetches
        // occur with an incorrect user, which causes us to spam Sentry with error
        // messages.
        //
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [env],
      );

      return (
        <ApolloProvider client={client}>
          {/* this needs to be inside Apollo because some modals require Apollo cache */}
          <ModalProvider>
            <StepperProvider>
              {/* Why the h*ck is this in Apollo???? Probably because modal makes GQL requests. */}
              {/* We need to do this better. Upcoming refactor! */}
              <Modal />
              <Stepper />
              <Page {...props} />
            </StepperProvider>
          </ModalProvider>
        </ApolloProvider>
      );
    };
    return Wrapper;
  };
};
