export type IErrorOptions = {
  code?: string;
  cause?: Error;
  expected?: boolean;
  transient?: boolean;
};

/**
 * The base error class for all errors in this codebase. All of our internal errors
 * subclass from this. We implement common logic here for our errors to enable error
 * chaining and more complete Sentry reporting.
 */
export class PipeError extends Error {
  /**
   * The error code. This should be in the format of `PACKAGE_NAME/ERROR_CODE`.
   */
  code: string | null;

  /**
   * True if we expect the error to occur from normal user interaction. For example,
   * backend form validation is expected to occasionally error. These errors should be
   * caught and handled in app and not crash the app or report to Sentry.
   */
  expected: boolean;

  /**
   * True if the error is due to a temporary problem on the client, like an error at the
   * network level. These errors are not actionable by frontend engineers.
   */
  transient: boolean;

  /**
   * The cause of this error. When chaining errors, pass in the original error as the
   * cause.
   */
  cause: Error | null;

  /**
   * @param message Error message
   * @param [options]
   */
  constructor(message: string, options?: IErrorOptions) {
    super(message || "");

    this.code = null;
    this.transient = false;
    this.expected = false;
    this.cause = null;

    if (options !== undefined) {
      if (options.code !== undefined) {
        this.code = String(options.code);
      }
      if (options.expected !== undefined) {
        this.expected = true;
      }
      if (options.transient !== undefined) {
        this.transient = true;
      }
      if (options.cause !== undefined) {
        this.cause = options.cause;
      }
    }

    // captureStackTrace is not a standardized function; but it is present in some
    // implementations like V8 and Node.JS. If present, let's call it.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (Error.captureStackTrace != null) {
      Error.captureStackTrace(this, this.constructor);
    }

    if (this.stack != null && options?.cause?.stack != null) {
      this.stack = `${this.stack}\ncaused by: ${options.cause.stack}`;
    }
  }
}
//
// The parent class for all errors arising from application/logic.
export class ApplicationError extends PipeError {
  constructor(message: string, options?: IErrorOptions) {
    super(message, { ...options, code: options?.code ?? "SHARED/BASE/APPLICATION" });
  }
}

// The parent class for all errors arising from network requests.
export class RequestError extends PipeError {
  constructor(message: string, options?: IErrorOptions) {
    super(message, { ...options, code: options?.code ?? "SHARED/BASE/REQUEST" });
  }
}

// The parent class for all errors arising from GraphQL calls to Hasura.
export class GraphQLError extends RequestError {
  constructor(message: string, options?: IErrorOptions) {
    super(message, { ...options, code: options?.code ?? "SHARED/BASE/GRAPHQL" });
  }
}

export type IRPCErrorOptions = IErrorOptions & {
  status: number;
  router: string;
  routePath: string;
};

// The parent class for all errors arising from RPC calls to the backend.
export class RPCError extends RequestError {
  status: IRPCErrorOptions["status"];

  router: IRPCErrorOptions["router"];

  routePath: IRPCErrorOptions["routePath"];

  constructor(message: string, options: IRPCErrorOptions) {
    super(message, { ...options, code: options.code ?? "SHARED/BASE/RPC" });
    this.status = options.status;
    this.router = options.router;
    this.routePath = options.routePath;
  }
}

// Represents form field validation errors from the backend.
export class FieldValidationError extends RPCError {
  constructor(message: string, options: IRPCErrorOptions) {
    super(message, {
      ...options,
      code: options.code ?? "SHARED/BASE/FIELD_VALIDATION_ERROR",
      expected: true,
    });
  }
}

// Represents network errors that occur when fetching.
export class NetworkError extends RPCError {
  constructor(message: string, options: Omit<IRPCErrorOptions, "status">) {
    super(message, {
      ...options,
      code: options.code ?? "SHARED/BASE/NETWORK_ERROR",
      transient: true,
      status: 400,
    });
  }
}

// Represents internal server errors from the backend.
export class InternalServerError extends RPCError {
  constructor(message: string, options: Omit<IRPCErrorOptions, "status">) {
    super(message, {
      ...options,
      code: options.code ?? "SHARED/BASE/INTERNAL_SERVER_ERROR",
      // All 500 errors are reported by the backend to Sentry.
      transient: true,
      status: 500,
    });
  }
}

type IUnknownRPCErrorOptions = IRPCErrorOptions & {
  data: Record<string, unknown>;
};

// Represents unknown non-200 errors from the backend.
export class UnknownRPCError extends RPCError {
  data: IUnknownRPCErrorOptions["data"];

  constructor(message: string, options: IUnknownRPCErrorOptions) {
    super(message, {
      ...options,
      code: options.code ?? "SHARED/BASE/UNKNOWN_RPC",
      status: options.status,
    });
    this.data = options.data;
  }
}
