import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { PropsWithChildren } from "react";

interface IProps extends PropsWithChildren<unknown> {
  onClick: () => void;
  isOverlayFullPage?: boolean;
  isBlurred?: boolean;
  zIndex?: string;
}

export const FullPageBlur: React.FC<IProps> = (props) => {
  const { onClick, isOverlayFullPage, isBlurred = false, zIndex, children } = props;

  return (
    <FullPageBlurInner
      onClick={onClick}
      isOverlayFullPage={isOverlayFullPage}
      zIndex={zIndex}
      initial={{}}
      animate={{
        backdropFilter: isBlurred ? "blur(6px)" : undefined,
        backgroundColor: isBlurred ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0.75)",
      }}
      exit={{}}
      transition={{ duration: 0.1 }}
    >
      {children}
    </FullPageBlurInner>
  );
};

const FullPageBlurInner = styled(motion.div)<{
  isOverlayFullPage?: boolean;
  zIndex?: string;
}>`
  position: fixed;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  z-index: ${(props) => props.zIndex ?? (props.isOverlayFullPage === true ? 60 : 50)};
  overflow-y: scroll;
  ${(props) =>
    props.isOverlayFullPage === false &&
    `
    height: calc(100% - 57px);
    margin-top: 57px;
  `}
`;

export const aboveTheBlur = css`
  z-index: 70;
`;
