/* eslint-disable react/style-prop-object */
import React from "react";

export const IconEdit: React.FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 1C3 0.45 2.55 0 2 0C1.45 0 1 0.45 1 1V4H3V1ZM1 5C0.45 5 0 5.45 0 6V8C0 8.55 0.45 9 1 9H3C3.55 9 4 8.55 4 8V6C4 5.45 3.55 5 3 5H1ZM14 0C14.55 0 15 0.45 15 1V3H13V1C13 0.45 13.45 0 14 0ZM9 1C9 0.45 8.55 0 8 0C7.45 0 7 0.45 7 1V7H9V1ZM2 16C1.45 16 1 15.55 1 15V10H3V15C3 15.55 2.55 16 2 16ZM13 4C12.45 4 12 4.45 12 5V7C12 7.55 12.45 8 13 8H15C15.55 8 16 7.55 16 7V5C16 4.45 15.55 4 15 4H13ZM14 16C13.45 16 13 15.55 13 15V9H15V15C15 15.55 14.55 16 14 16ZM7 8C6.45 8 6 8.45 6 9V11C6 11.55 6.45 12 7 12H9C9.55 12 10 11.55 10 11V9C10 8.45 9.55 8 9 8H7ZM8 16C7.45 16 7 15.55 7 15V13H9V15C9 15.55 8.55 16 8 16Z"
    />
  </svg>
);
