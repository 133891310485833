import { configureScope } from "@sentry/nextjs";
import LogRocket from "logrocket";

interface IInitializeSentry {
  email: string;
  userID: string;
  name: string;
}

/**
 * Initializes Sentry error logging with pre-configured options.
 * */
export const configureSentry = ({ email, userID, name }: IInitializeSentry): void => {
  configureScope((scope) => {
    scope.setUser({ id: userID, email, userID, name });
  });

  // Then pass LogRocket sessionURL, if available, to Sentry
  LogRocket.getSessionURL((sessionURL) => {
    configureScope((scope) => {
      // Adds a new LogRocket section with the url to the session recording
      scope.setContext("LogRocket", {
        sessionURL,
      });
    });
  });
};
