/* eslint-disable react/style-prop-object */
import React from "react";

export const IconCommand: React.FC = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 8H11C12.66 8 14 9.34 14 11C14 12.66 12.66 14 11 14C9.34 14 8 12.66 8 11V10H6V11C6 12.66 4.66 14 3 14C1.34 14 0 12.66 0 11C0 9.34 1.34 8 3 8H4V6H3C1.34 6 0 4.66 0 3C0 1.34 1.34 0 3 0C4.66 0 6 1.34 6 3V4H8V3C8 1.34 9.34 0 11 0C12.66 0 14 1.34 14 3C14 4.66 12.66 6 11 6H10V8ZM12 3C12 2.45 11.55 2 11 2C10.45 2 10 2.45 10 3C10 3.55 10.45 4 11 4C11.55 4 12 3.55 12 3ZM3 12C2.45 12 2 11.55 2 11C2 10.45 2.45 10 3 10C3.55 10 4 10.45 4 11C4 11.55 3.55 12 3 12ZM2 3C2 3.55 2.45 4 3 4C3.55 4 4 3.55 4 3C4 2.45 3.55 2 3 2C2.45 2 2 2.45 2 3ZM8 8H6V6H8V8ZM10 11C10 11.55 10.45 12 11 12C11.55 12 12 11.55 12 11C12 10.45 11.55 10 11 10C10.45 10 10 10.45 10 11Z"
    />
  </svg>
);
