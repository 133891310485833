import { ReactSDKClient } from "@optimizely/react-sdk";
import create from "zustand";
import { persist } from "zustand/middleware";

type IVendorImpersonateStore = {
  impersonateVendorPublicID: string | null;
  setImpersonateVendorPublicID: (publicID: string | null) => void;
};

export const useVendorImpersonateStore = create<IVendorImpersonateStore>(
  persist(
    (set) => ({
      impersonateVendorPublicID: null,
      setImpersonateVendorPublicID: (publicID) =>
        set(() => ({ impersonateVendorPublicID: publicID })),
    }),
    {
      name: "vendor-impersonate-store",
    },
  ),
);

type IAuthProviderStore = {
  optimizelyClient: ReactSDKClient | null;
  setOptimizelyClient: (optimizely: ReactSDKClient | null) => void;
};

/**
 * Since AuthProvider remounts on every page, hoist up any auth data that needs persistance across page here
 * (e.g. Ensures Optimizely client remains static across page renders)
 */
export const useAuthProviderStore = create<IAuthProviderStore>(
  persist(
    (set) => ({
      optimizelyClient: null,
      setOptimizelyClient: (optimizely: ReactSDKClient | null) => {
        set({
          optimizelyClient: optimizely,
        });
      },
    }),
    {
      name: "auth-provider-store",
      // Don't persist the optimizely client, as it is a complex object/non-serializable
      blacklist: ["optimizelyClient"],
    },
  ),
);
