import { ApolloClient, NormalizedCacheObject } from "@apollo/client";

import { createClientSideApolloClient } from "./createClientSideApolloClient";
import { noopApolloClient } from "./noopApolloClient";

let _apolloClient: ApolloClient<NormalizedCacheObject> | null;
let _apolloEndpoint: string;

/**
 * Clears the cached browser apollo client so that the next page load uses a fresh client
 */
export const forceDeleteApolloClient = (): void => {
  // this function is nonsense on SSR
  if (typeof window === "undefined") {
    return;
  }
  _apolloClient = null;
};

export default function initApollo(
  endpoint: string,
  impersonateAsVendorID: string | null,
): ApolloClient<NormalizedCacheObject> {
  // if SSR, use the client that never returns anything
  if (typeof window === "undefined") {
    return noopApolloClient;
  }

  // otherwise, use the apollo client
  if (_apolloClient == null || _apolloEndpoint !== endpoint) {
    _apolloClient = createClientSideApolloClient(endpoint, impersonateAsVendorID);
    _apolloEndpoint = endpoint;
  }

  return _apolloClient;
}
