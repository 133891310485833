/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import React, { ComponentProps } from "react";

import { Button } from "~src/designSystem/atoms/Button";
import { clampPercent } from "~src/shared/helpers/math";
import { IStackItem, useModal, useModalState } from "~src/shared/modals/modal-context";
import { liveBackground } from "~src/shared/styles/liveBackground";
import { IPipeTheme } from "~src/shared/theme/darkVariant";

import { CloseButtonIcon } from "./CloseButtonIcon";

interface IBarProps {
  color: IModalBarColor;
  width: number;
}

type IModalBarColor = keyof Pick<
  IPipeTheme["components"]["Text"],
  "warning" | "error" | "oldGradient"
>;

interface IProps extends Omit<ComponentProps<typeof Wrapper>, "children"> {
  /**
   * Properties of the bar above the modal.
   */
  bar?: IBarProps;
}

/** Responsible for rendering the content of the modal, based on the useModalState() */
export const ModalContent: React.FC<IProps> = (props) => {
  const { bar, ...rest } = props;

  const state = useModalState();
  const { clearStackAndCloseModal } = useModal();

  // IStackItem at the top of the stack.
  const topOfStack = getTopOfStack(state.stack);

  const handleCloseButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    if (topOfStack?.config?.onClose !== undefined) {
      topOfStack.config.onClose();
    } else {
      clearStackAndCloseModal();
    }
  };

  return (
    <Wrapper
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{
        opacity: 1,
        scale: 1,
      }}
      onClick={(event) => event.stopPropagation()}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.1 }}
      width={topOfStack?.config?.width}
      borderRadius={topOfStack?.config?.borderRadius}
      border={topOfStack?.config?.borderRadius}
      {...rest}
    >
      {topOfStack?.config?.isCloseButtonHidden === true ? null : (
        <CloseButton
          className="close-modal"
          closeButtonPosition={topOfStack?.config?.closeButtonPosition ?? "right"}
          onClick={handleCloseButtonClick}
        >
          <CloseButtonIcon
            css={(theme) =>
              css`
                width: 14px;
                height: 14px;
                color: ${theme.old.modalCloseX};
              `
            }
          />
        </CloseButton>
      )}
      {bar !== undefined && <Bar color={bar.color} width={bar.width} />}
      {state.stack.map((item, i) => {
        // We need to render all the items in the stack so that any state associated
        // with the items below the top is not trashed by React. However, we hide the
        // non-top items so that they're not visible.
        return (
          <div key={i} hidden={item !== topOfStack}>
            {item.component}
          </div>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)<{ width?: string; borderRadius?: string; border?: string }>`
  border-radius: ${(props) => (props.borderRadius !== undefined ? props.borderRadius : "4px")};
  border: ${(props) =>
    props.border !== undefined ? props.border : `1px solid ${props.theme.components.Modal.border}`};

  overflow: hidden;
  position: relative;

  width: 100%;
  max-width: 557px;
  margin: 10vh auto;
  transition: width 0.2s ease;

  /* Set both width and max-width to ensure values are overriden */
  width: ${(props) => props.width};
  max-width: ${(props) => props.width};
`;

export const Bar = styled.div<IBarProps>`
  z-index: 21000001;
  height: 3px;
  width: ${(props) => (clampPercent(props.width) * 100).toFixed(2)}%;
  ${(props) => liveBackground(props.theme.components.Text[props.color])}

  position: absolute;
  top: 0;
`;

const CloseButton = styled(Button)<{
  className?: string;
  closeButtonPosition: "left" | "right";
}>`
  position: absolute;

  background-color: transparent;
  &:hover {
    background-color: transparent;
  }

  ${(props) =>
    props.closeButtonPosition === "left"
      ? css`
          left: 20px;
        `
      : css`
          right: 20px;
        `}
  top: 20px;

  padding: 2px;
  z-index: 100000000;
`;

/**
 * IStackItem at the top of the stack.
 * */
export const getTopOfStack = (stack: readonly IStackItem[]): IStackItem | undefined => {
  return stack[stack.length - 1];
};
