import { single } from "fuzzysort";

import { stringIsEmpty } from "~src/shared/helpers/booleanCoercion";

import { ICommandKSection } from "../types/internal";

/**
 * Maps an absolute option index to an index within a section.
 */
export const getSelectedSectionIndex = (
  selectedOptionIndex: number,
  sections: readonly ICommandKSection[],
): {
  /**
   * Index of the selected section.
   */
  sectionIndex: number;
  /**
   * Index of the option within the section.
   */
  optionIndex: number;
} => {
  // The index of the end of each section
  const sectionEnds = sections.map(
    (sect, i) =>
      sections.slice(0, i).reduce((acc, s) => acc + s.options.length, 0) + sect.options.length,
    0,
  );

  // The current section
  const idx = sectionEnds.findIndex((end) => end > selectedOptionIndex);
  // TODO(md): Is this a bug? @usman
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const selectedSectionIndex = Math.max(0, idx ?? sectionEnds.length - 1);

  // The index within the current section
  let selectedSectionEnd;
  if (selectedSectionIndex > 0) {
    selectedSectionEnd = sectionEnds[selectedSectionIndex - 1] ?? 0;
  } else {
    selectedSectionEnd = 0;
  }

  const selectedIndex = selectedOptionIndex - selectedSectionEnd;

  return { sectionIndex: selectedSectionIndex, optionIndex: selectedIndex };
};

export const fuzzyMatch = (s: string, searchText: string): boolean => {
  if (stringIsEmpty(searchText)) return true;

  const result = single(searchText, s);
  return result !== null && result.score > -50;
};
