import { useApolloClient } from "@apollo/client";
import { ErrorResponse } from "@apollo/client/link/error";
import { Event, startTransaction } from "@sentry/nextjs";
import { useRouter } from "next/router";
import { Response as NodeFetchResponse } from "node-fetch";
import { useCallback } from "react";
import toast from "react-hot-toast";

import { forceDeleteApolloClient } from "~src/shared/apollo/withApolloNext/initApollo";
import { useVendorImpersonateStore } from "~src/shared/auth/store";
import { makeAPIURL } from "~src/shared/env";
import { isWindowDefined, logError, logErrorEventAndToast } from "~src/shared/helpers";

import { clientSideFetch } from "./rpc/clientSideFetch";
import {
  FailedToFetchError,
  LegacyRPCError,
  LegacyRPCServerError,
  MissingPermissionError,
  NotLoggedInError,
} from "./rpc/rpcError";
import { serverSideFetch } from "./rpc/serverSideFetch";
import { INetworkRequest, IOutput } from "./types";

/**
 * @deprecated
 * New note: Use callRequest instead.
 * Old note: Use this whenever you might mutate the database.
 */
export const useCallRequestLegacyHammerMode = (): typeof callRequest => {
  const apollo = useApolloClient();
  const router = useRouter();
  return useCallback(
    async <I, O>(req: INetworkRequest<I, O>, options?: ICallRequestOptions) => {
      const res = await callRequest(req, options);
      try {
        if (
          req.action !== "auth.logout" &&
          req.action !== "admin.vendor.demo" &&
          (req.action.includes("admin") || req.action.includes("vendor.update"))
        ) {
          setTimeout(async () => {
            try {
              await apollo.reFetchObservableQueries();
            } catch (error) {
              logErrorEventAndToast({
                message: "reFetchObservableQueries failed",
                extra: {
                  error,
                },
              });
            }
          }, 0);
        }
      } catch (error) {
        const typedError: ErrorResponse = error;
        const { networkError } = typedError;
        // If unauthorized, route to /login
        // Workaround for types https://github.com/apollographql/apollo-link/issues/300
        if (
          networkError != null &&
          "statusCode" in networkError &&
          networkError.statusCode >= 400 &&
          networkError.statusCode < 500
        ) {
          forceDeleteApolloClient();
          await router.push(`/login`);
        } else {
          logErrorEventAndToast({
            message: `Could not refetch queries`,
            tags: {
              action: req.action,
              function: "useCallRequest",
            },
          });
        }
      }
      return res;
    },
    [apollo, router],
  );
};

export interface ICallRequestOptions {
  /**
   * This is only used by the server, and we will probably delete the server routes
   * soon. -mdong
   * @deprecated
   */
  cookie?: string;
  /**
   * If this is true, then the request framework will display an error message in a
   * toast. If this is false, then the request framework will not, leaving error
   * presentation fully up to the client.
   *
   * This is currently true for compatibility reasons, but we will remove toasts from
   * this function completely in the future.
   *
   * @default true
   */
  toastOnError?: boolean;
  /**
   * Handles RPC errors.
   * If this returns true, the error will not be logged.
   */
  handleRPCError?: (err: LegacyRPCError) => boolean | undefined;
  /**
   * Extra data to send to Sentry if an error is thrown
   */
  sentryContext?: Pick<Event, "tags" | "extra">;
}

/**
 * Performs a network request.
 */
export const callRequest = async <I, O>(
  req: INetworkRequest<I, O>,
  options?: ICallRequestOptions,
): Promise<IOutput<O>> => {
  const apiURL = makeAPIURL();
  const url: string = (() => {
    switch (req.type) {
      case "admin":
        return `${apiURL}/v1/admin/rpc/${req.action}`;
      case "vendor":
        return `${apiURL}/v1/vendor/rpc/${req.action}`;
      case "investor":
        return `${apiURL}/v1/investor/rpc/${req.action}`;
      // This is a legacy endpoint.
      case "authed":
        return `${apiURL}/v1/authed/rpc/${req.action}`;
      case "unauth":
        return `${apiURL}/v1/p/rpc/${req.action}`;
    }
  })();

  const requestOptions: { cookie?: string; headers?: Record<string, string> } = {};
  if (options !== undefined && options.cookie !== undefined) {
    requestOptions.cookie = options.cookie;
  }

  const { impersonateVendorPublicID } = useVendorImpersonateStore.getState();
  if (impersonateVendorPublicID !== null) {
    requestOptions.headers = { "X-Impersonate-Vendor": impersonateVendorPublicID };
  }

  const transaction = startTransaction({
    name: "pipe_rpc",
  });
  transaction.setTag("rpc.type", req.type);
  transaction.setTag("rpc.action", req.action);
  transaction.setTag("rpc.isClientSide", "" + isWindowDefined);

  let res;
  try {
    res = await (isWindowDefined
      ? clientSideFetch(url, req.input, requestOptions)
      : serverSideFetch(url, req.input, requestOptions));
  } catch (e) {
    transaction.setData("result.ok", false);
    transaction.finish();
    return {
      ok: false,
      data: null,
      count: 0,
      error: new FailedToFetchError(`Network error when fetching: ${e?.message}.`, {
        cause: e,
        action: req.action,
        type: req.type,
        input: JSON.stringify(req.input, null, 2),
      }),
    };
  }

  const result = await processResponse(res, req);
  transaction.setData("result.ok", result.ok);
  transaction.setData("result.errorMessage", result.error?.errorMessage);
  transaction.setData("result.errorFields", result.error?.errorFields);
  transaction.finish();

  // Log errored requests to Sentry. We are doing this in the short term to match
  // previous behavior; as we migrate to the new request framework, we will stop doing
  // this for every request.
  if (!res.ok) {
    // Only log the error if this function doesn't return true. Very weird. We will need
    // to change this.
    if (result.error !== undefined && options?.handleRPCError?.(result.error) !== true) {
      // Only report unexpected errors. As we migrate away from this framework, we will
      // temporarily flag all known expected errors as expected to clean up Sentry.
      if (!result.error.expected) {
        logError(result.error, {
          extra: {
            ...options?.sentryContext?.extra,
            errorFields: result.error.errorFields,
            status: result.error.status,
            action: req.action,
            type: req.type,
            input: JSON.stringify(req.input, null, 2),
          },
          tags: {
            ...options?.sentryContext?.tags,
            "category": "rpc",
            // we can automatically triage things on sentry by the error code
            "rpc.statusCode": result.error.status.toString(),
          },
        });
      }

      const userMessage =
        result.error.errorMessage ?? "Something went wrong. Our engineers have been notified.";
      toast.error(userMessage, {
        // Don't spam with duplicate errors.
        id: `legacy-rpc-error-${userMessage}`,
      });
    }
  }

  // Return an error if its a code 500 with status transient. Status 500 are reported
  // to Sentry by the backend.
  if (Math.floor(res.status / 100) === 5) {
    return {
      ok: false,
      data: null,
      count: 0,
      error: new LegacyRPCServerError(await res.text(), {
        action: req.action,
        type: req.type,
        input: JSON.stringify(req.input, null, 2),
      }),
    };
  }

  return result;
};

const processResponse = async <I, O>(
  res: Response | NodeFetchResponse,
  req: INetworkRequest<I, O>,
): Promise<IOutput<O>> => {
  // don't process OK empty bodies
  if (res.ok && res.headers.get("content-length") === "0") {
    return {
      ok: true,
      count: 0,
      data: null,
    };
  }

  const resData = await res.json();
  const error = await (async (): Promise<LegacyRPCError | undefined> => {
    if (res.ok) {
      return undefined;
    }

    // TODO: We will generate errors for errors in RPC codegen later. But for now,
    // hardcode them by message strings.
    if (res.status === 401 || (res.status === 403 && resData.errorMessage === "Login required.")) {
      return new NotLoggedInError();
    }
    if (
      res.status === 403 ||
      resData.errorMessage === "Not allowed to do that." ||
      resData.code === "SHARED/RPC/MISSING_PERMISSION"
    ) {
      return new MissingPermissionError();
    }

    return new LegacyRPCError(
      res.statusText,
      {
        errorMessage: resData.code ?? resData.errorMessage,
        errorFields: resData.errorFields,
      },
      {
        action: req.action,
        type: req.type,
        input: JSON.stringify(req.input, null, 2),
        status: res.status,
      },
    );
  })();

  return { data: resData?.data, count: resData?.count, ok: res.ok, error };
};
