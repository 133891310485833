import { useCallback, useMemo, useState } from "react";

import { authedRequests, IGetUserProfileData } from "~src/shared/requests/authedRequests";
import { IOutput } from "~src/shared/requests/types";
import { callRequest } from "~src/shared/requests/useRequest";
import { IVendor } from "~src/shared/types";

import { useVendorImpersonateStore } from "./store";
import { IAuthenticationData } from "./types";

// These values are cached between pages, so we set them in local variables.
let userCached: IOutput<IGetUserProfileData> | null = null;
let vendorCached: IOutput<IVendor> | null = null;

/**
 * Loads data for authentication.
 *
 * This should only be called in the AuthProvider. You might be looking for `useAuthContext`.
 */
export const useAuthenticationData = (): IAuthenticationData & {
  /**
   * Reloads the user/vendor.
   */
  revalidate: () => Promise<IAuthenticationData>;
  /**
   * If true, the user/vendor have finished their initial load.
   */
  isInitialLoadComplete: boolean;
} => {
  const [userData, setUserData] = useState<IOutput<IGetUserProfileData> | null>(userCached);
  const [vendorData, setVendorData] = useState<IOutput<IVendor> | null>(vendorCached);
  const user = userData?.data ?? null;
  const vendor = vendorData?.data ?? null;

  const impersonateVendorPublicID = useVendorImpersonateStore(
    (state) => state.impersonateVendorPublicID,
  );

  // Reloads the user data.
  // This is called on the first page load and on login/logout.
  const revalidateUser = useCallback(async () => {
    const nextUserData = await callRequest(authedRequests.getUserProfile({}), {
      handleRPCError: (err) => {
        if (err.status === 401) {
          return true;
        }
      },
    });

    if (nextUserData.error !== undefined && nextUserData.error.status === 401) {
      setUserData(null);
      userCached = null;
      return null;
    }
    setUserData(nextUserData);
    userCached = nextUserData;
    return nextUserData;
    // TODO(johnrjj): Is the impersonateVendorPublicID required? Some implicit thing?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impersonateVendorPublicID]);

  // Reloads the vendor data.
  // This is called on the first page load and on login/logout.
  const revalidateVendor = useCallback(async () => {
    const nextVendorData = await callRequest(authedRequests.getVendor({}), {
      handleRPCError: (err) => {
        if (err.status === 401) {
          return true;
        }
      },
    });
    if (nextVendorData.error !== undefined && nextVendorData.error.status === 401) {
      setVendorData(null);
      vendorCached = null;
      return null;
    }

    setVendorData(nextVendorData);
    vendorCached = nextVendorData;
    return nextVendorData;
  }, []);

  const revalidate = useCallback(async () => {
    return {
      user: (await revalidateUser())?.data ?? null,
      vendor: (await revalidateVendor())?.data ?? null,
    };
  }, [revalidateUser, revalidateVendor]);

  const isInitialLoadComplete = userData != null && vendorData != null;

  return useMemo(
    () => ({ user, vendor, revalidate, isInitialLoadComplete }),
    [isInitialLoadComplete, revalidate, user, vendor],
  );
};
