import { useRouter } from "next/router";

import { useCommand } from "~src/shared/command/hooks/useCommand";
import { ICommandRunner } from "~src/shared/command/types";
import { ICommandKContextInternal, ICommandKMode, IModeState } from "~src/shared/commandK/types";

export const useCommandKCommands = (ctx: ICommandKContextInternal): void => {
  const { state } = ctx;
  const router = useRouter();
  const path = router.asPath;

  useCommand(
    "command-k/back",
    () => {
      ctx.back();
      return false;
    },
    [ctx],
  );

  useCommand(
    "command-k/exit",
    () => {
      ctx.hide();
      return false;
    },
    [ctx],
  );

  useCommand(
    "command-k/toggle",
    () => {
      // TODO(igm): make command K available on more than just list view
      if (path.startsWith("/customers")) {
        ctx.toggle();
        return false;
      }
    },
    [ctx, path],
  );

  useCommand(
    "command-k/show",
    <M extends ICommandKMode>(_: ICommandRunner, { mode, args }: IModeState<M>) => {
      // TODO(igm): make command K available on more than just list view
      if (path.startsWith("/customers")) {
        if (state.isVisible && state.modeState?.mode === ICommandKMode.COMMANDS) {
          ctx.replace({ mode, args });
        } else {
          ctx.show({ mode, args });
        }
        return false;
      }
    },
    [ctx, state, path],
  );
};
