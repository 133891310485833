/* eslint-disable react/style-prop-object */
import React from "react";

export const CloseButtonIcon: React.FC = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.3475 1.9475C13.5694 1.7241 13.6807 1.45544 13.68 1.14C13.6807 0.824566 13.5694 0.555906 13.3475 0.332504C13.1241 0.110584 12.8554 -0.000737314 12.54 3.68595e-06C12.2246 -0.000738454 11.9559 0.110589 11.7325 0.332504L6.84 5.225L1.9475 0.332504C1.7241 0.110584 1.45544 -0.000737314 1.14 3.68595e-06C0.824566 -0.000738454 0.555906 0.110589 0.332504 0.332504C0.110584 0.555906 -0.000737314 0.824566 3.68595e-06 1.14C-0.000738454 1.45544 0.110589 1.7241 0.332504 1.9475L5.225 6.84L0.332504 11.7325C0.110584 11.9559 -0.000737314 12.2246 3.68595e-06 12.54C-0.000738454 12.8554 0.110589 13.1241 0.332504 13.3475C0.555906 13.5694 0.824566 13.6807 1.14 13.68C1.45544 13.6807 1.7241 13.5694 1.9475 13.3475L6.84 8.455L11.7325 13.3475C11.9559 13.5694 12.2246 13.6807 12.54 13.68C12.8554 13.6807 13.1241 13.5694 13.3475 13.3475C13.5694 13.1241 13.6807 12.8554 13.68 12.54C13.6807 12.2246 13.5694 11.9559 13.3475 11.7325L8.455 6.84L13.3475 1.9475Z" />
  </svg>
);
