/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { capitalize } from "lodash";
import React from "react";

type IShortcutVariant = "tooltip" | "body";

export interface IKeyboardShortcutProps {
  textRepr: string;
  variant?: IShortcutVariant;
}

/**
 * Displays a keyboard shortcut based off of its Mousetrap.js representation.
 */
export const OldKeyboardShortcutWhenDidWeUseThis: React.FC<IKeyboardShortcutProps> = ({
  textRepr,
  variant = "tooltip",
}) => {
  const thens = textRepr.split(" ");
  const parts = thens.map((then) => then.split("+"));
  return (
    <Wrapper>
      {parts
        .map((part, i) => (
          <>
            {part.map((key, j) => (
              <Key variant={variant} key={`${i}-${j}`}>
                {reverseMap[key] ??
                  (key.length > 1 ? (
                    <span
                      css={css`
                        font-size: ${16 / key.length}px;
                      `}
                    >
                      {capitalize(key)}
                    </span>
                  ) : (
                    key.toUpperCase()
                  ))}
              </Key>
            ))}
          </>
        ))
        .reduce((acc, el) => (
          <>
            {acc}
            <span>then</span>
            {el}
          </>
        ))}
    </Wrapper>
  );
};

const reverseMap: Record<string, string> = {
  shift: "⇧",
  command: "⌘",
  control: "^",
  enter: "⏎",
  up: "↑",
  down: "↓",
};

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 4px;
  color: ${(props) => props.theme.old.text.default};
  align-items: center;
`;

const Key = styled.div<{ variant: IShortcutVariant }>`
  ${(props) =>
    props.variant === "body" &&
    css`
      background: ${props.theme.old["keyboard-shortcut"].muted};
      color: ${props.theme.old.text.default};
    `}
  ${(props) =>
    props.variant === "tooltip" &&
    css`
      background: ${props.theme.old["keyboard-shortcut"].default};
      color: ${props.theme.old.tooltip.text};
    `}

  border-radius: 2px;
  width: 16px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${{
    fontSize: "11px",
    fontWeight: 400,
    lineHeight: "18px",
  }};
`;
