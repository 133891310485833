/* eslint-disable react/style-prop-object */
import React from "react";

export const IconEditColumns: React.FC = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 0.00366211H9C9.4125 0.00366211 9.75 0.340951 9.75 0.753194V11.2466C9.75 11.6589 9.4125 11.9962 9 11.9962H6.75C6.3375 11.9962 6 11.6589 6 11.2466V0.753194C6 0.340951 6.3375 0.00366211 6.75 0.00366211ZM3.9675 3.96868C4.1025 3.83377 4.29 3.75132 4.5 3.75132C4.9125 3.75132 5.25 4.08861 5.25 4.50085V7.49898C5.25 7.91122 4.9125 8.24851 4.5 8.24851C4.29 8.24851 4.1025 8.16606 3.9675 8.03114L2.4675 6.53208C2.3325 6.39717 2.25 6.20229 2.25 5.99991C2.25 5.79754 2.3325 5.61016 2.4675 5.46775L3.9675 3.96868Z"
    />
  </svg>
);
