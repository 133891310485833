/* eslint-disable react/style-prop-object */
import React from "react";

export const IconFilter: React.FC = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.5 0.746338H1.5C1.0875 0.746338 0.75 1.08384 0.75 1.49634C0.75 1.70634 0.8325 1.89384 0.9675 2.02884L4.5 5.56134V10.5038C4.5 10.9163 4.8375 11.2538 5.25 11.2538C5.46 11.2538 5.6475 11.1713 5.7825 11.0363L7.2825 9.53634C7.4175 9.40134 7.5 9.21384 7.5 9.00384V5.56134L11.0325 2.02884C11.1675 1.89384 11.25 1.70634 11.25 1.49634C11.25 1.08384 10.9125 0.746338 10.5 0.746338Z" />
  </svg>
);
