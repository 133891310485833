import React, { PropsWithChildren, useCallback, useMemo, useState } from "react";

import { useCommandContext } from "~src/shared/command/hooks/useCommandContext";

import { CommandKModal } from "../components/CommandKModal";
import { useCommandKCommands } from "../hooks/useCommandKCommands";
import { modeHandlers } from "../modes";
import {
  CommandKContext,
  ICommandKContextInternal,
  ICommandKMode,
  ICommandKState,
  IModeState,
} from "../types";
import { ICommandKModeHandler, ICommandKOption, ICommandKTile } from "../types/internal";

const renderHeaderAndSearchPlaceholder = <M extends ICommandKMode>({
  mode,
  args,
}: IModeState<M>): {
  header: readonly ICommandKTile[];
  searchPlaceholder?: string;
} => {
  const modeHandler = modeHandlers[mode] as ICommandKModeHandler<M>;
  return {
    header: modeHandler.header(args),
    searchPlaceholder: modeHandler.searchPlaceholder?.(args),
  };
};

const initialState: ICommandKState = {
  isVisible: false,
  searchText: "",
  selectedOptionIndex: 0,
  header: [],
  modeHistory: [],
};

export const CommandKProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [state, setState] = useState<ICommandKState>(initialState);
  const { getMapping } = useCommandContext();
  const mapping = getMapping();

  const replace = useCallback(
    <M extends ICommandKMode>(modeState: IModeState<M>) => {
      setState({
        ...state,
        isVisible: true,
        searchText: "",
        selectedOptionIndex: 0,
        ...renderHeaderAndSearchPlaceholder(modeState),
        modeState,
      });
    },
    [state],
  );

  const show = useCallback(
    <M extends ICommandKMode>(modeState: IModeState<M>, searchText?: string) => {
      setState({
        ...state,
        isVisible: true,
        searchText: searchText ?? "",
        selectedOptionIndex: 0,

        // push the previous mode onto the history.
        modeHistory: [...(state.modeState ? [state.modeState] : []), ...state.modeHistory],
        ...renderHeaderAndSearchPlaceholder(modeState),
        modeState,
      });
    },
    [state],
  );

  const ctx: ICommandKContextInternal = useMemo(() => {
    const hide = () => {
      setState({
        ...state,
        isVisible: false,
        searchText: "",
        modeState: undefined,
        modeHistory: [], // reset the mode history when hiding
      });
    };

    const selectOption = (option: ICommandKOption) => {
      // If selecting the option triggers a new mode, stack it up.
      const nextModeState = option.onSelect();
      if (nextModeState !== null) {
        if (state.modeState && nextModeState.mode === state.modeState.mode) {
          replace(nextModeState);
        } else {
          show(nextModeState);
        }

        return;
      }

      // If this is a checkbox style option, we should just invert the
      // the current selection.
      if (option.onChecked !== undefined) {
        const checked = option.checked ?? false;
        option.onChecked(!checked);
      }
    };

    const onOptionChecked = (option: ICommandKOption, checked: boolean) => {
      option.onChecked?.(checked);
    };

    return {
      show,
      replace,
      hide,
      state,
      selectOption,
      onOptionChecked,
      setSearch(searchText: string) {
        setState({ ...state, searchText });
      },
      setSelectedOptionIndex(selectedOptionIndex: number) {
        setState({ ...state, selectedOptionIndex });
      },
      toggle() {
        if (state.isVisible) {
          hide();
        } else {
          show({ mode: ICommandKMode.COMMANDS, args: null });
        }
      },
      back() {
        const [prevModeState, ...rest] = state.modeHistory;
        if (prevModeState === undefined) {
          hide();
        } else {
          setState({
            ...state,
            selectedOptionIndex: 0,
            searchText: "",
            ...renderHeaderAndSearchPlaceholder(prevModeState),
            modeHistory: rest,
            modeState: prevModeState,
          });
        }
      },
      isVisible() {
        return state.isVisible;
      },
      currentMode() {
        return state.modeState?.mode;
      },
    };
    // NOTE(johnrjj) - Re: linting -- I didn't want to touch this. We should migrate to an off the shelf library
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, mapping]);

  useCommandKCommands(ctx);

  return (
    <CommandKContext.Provider value={ctx}>
      {children}
      <CommandKModal />
    </CommandKContext.Provider>
  );
};
