import { sortBy } from "lodash";
import React from "react";

import { ICommandRunner } from "~src/shared/command/types";
import { IconColumnType, IconEditColumns } from "~src/shared/lists/icons";
import { IEditableList, IListConfig } from "~src/shared/lists/types";
import { getListModelConfig, IListModel } from "~src/shared/lists/types/models";
import { columnForModel } from "~src/shared/lists/utils/config";

import { ICommandKMode } from "../types";
import {
  ICommandKModeHandler,
  ICommandKModeRendererArgs,
  ICommandKSection,
  ICommandKSectionType,
  ICommandKTile,
} from "../types/internal";
import { fuzzyMatch } from "../utils";

const makeListEditColumnsModeSections = <M extends IListModel>({
  args,
  env: { searchText, runCommand },
}: ICommandKModeRendererArgs<ICommandKMode.LIST_EDIT_COLUMNS>): readonly ICommandKSection[] => {
  const list = args.list as unknown as IEditableList<M>;
  const { model } = list.config as IListConfig<M>;
  const modelConfig = getListModelConfig(model);

  const selectedColumns: ICommandKSection = {
    title: "Selected",
    options: list.config.columns.map(({ name, title }) => {
      const column = columnForModel(model, name);
      return {
        id: name as string,
        label: title,
        icon: <IconColumnType renderType={column.render_type} />,
        description: "Pipe",
        onSelect: () => {
          return null;
        },
        onChecked: (checked) => {
          if (checked === false) {
            runCommand(ICommandRunner.COMMAND_K, "list-builder/remove-column", {
              column: name,
            });
          }
        },
        checked: true,
      };
    }),
    type: ICommandKSectionType.CHECKBOXES_REORDERABLE,
  };

  // TODO(usmanm): categories
  const columns = sortBy(Object.values(modelConfig.columns), ["title"]);

  const rest = [
    {
      title: "Default",
      options: columns
        .filter((column) => {
          const name = column.name as string;
          return (
            list.config.columns.map((col) => col.name as string).indexOf(name) === -1 &&
            fuzzyMatch(name, searchText)
          );
        })
        .map((column) => {
          const name = column.name as string;
          return {
            id: name,
            label: name, // FIXME
            icon: <IconColumnType renderType={column.render_type} />,
            description: "Pipe",
            onSelect: () => {
              runCommand(ICommandRunner.COMMAND_K, "list-builder/add-column", {
                column: column.name,
              });
              return null;
            },
          };
        }),
      type: ICommandKSectionType.OPTIONS,
    },
  ];

  return [selectedColumns, ...rest.filter((s) => s.options.length > 0)];
};

export const listEditColumnsHandler: ICommandKModeHandler<ICommandKMode.LIST_EDIT_COLUMNS> = {
  header: () =>
    [
      {
        title: "Edit Columns",
        icon: <IconEditColumns />,
      },
    ] as readonly ICommandKTile[],
  render: (args) => ({
    sections: makeListEditColumnsModeSections(args),
  }),
};
