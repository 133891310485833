/* eslint-disable react/style-prop-object */
import React from "react";

export const IconSearch: React.FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8868 10.7582L15.5552 13.4365C15.825 13.7063 15.9949 14.0861 15.9949 14.4958C15.9949 15.3253 15.3253 15.9949 14.4958 15.9949C14.0861 15.9949 13.7063 15.825 13.4365 15.5552L10.7582 12.8868C9.67884 13.5764 8.38964 13.9961 7.00051 13.9961C3.13293 13.9961 0.00488281 10.8681 0.00488281 7.00051C0.00488281 3.13293 3.13293 0.00488281 7.00051 0.00488281C10.8681 0.00488281 13.9961 3.13293 13.9961 7.00051C13.9961 8.37965 13.5864 9.66884 12.8868 10.7582ZM2.00363 7.00051C2.00363 9.75879 4.24223 11.9974 7.00051 11.9974C9.75879 11.9974 11.9974 9.75879 11.9974 7.00051C11.9974 4.24223 9.75879 2.00363 7.00051 2.00363C4.24223 2.00363 2.00363 4.24223 2.00363 7.00051Z"
    />
  </svg>
);
