import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { motion, MotionProps } from "framer-motion";

import {
  IBoxStyles,
  IGridStyles,
  withBoxStyles,
  withGridStyles,
} from "~src/shared/helpers/sharedComponentProps";

export type IHeadingProps = {
  /** Defaults to 20px. */
  size?:
    | "64px"
    | "36px"
    | "30px"
    | "28px"
    | "24px"
    | "20px"
    | "16px"
    | "14px"
    | "13px"
    | "12px"
    | "11px"
    | "10px";
  /** defaults to 1.8 */
  lineHeight?: 1 | 1.2 | 1.8 | 2.4;
  inherit?: boolean;
  color?: string;
  family?: string;
  bold?: boolean;
  capitalize?: boolean;
  style?: React.CSSProperties;
  textTransform?: string;
} & IGridStyles &
  IBoxStyles &
  MotionProps;

const defaultStyles = (props: IHeadingProps) => css`
  margin: 0;
  line-height: ${props.lineHeight ?? 1.8};
  text-transform: ${props.textTransform !== undefined
    ? props.textTransform
    : props.capitalize === true && "capitalize"};
  font-size: ${props.size};
  font-family: ${props.family};
  font-weight: ${props.bold !== undefined && "bold"};
  ${withGridStyles(props)}
  ${withBoxStyles(props)}
`;

/**
 * @deprecated Use Text instead.
 */
export const OldHeadingUseTextInstead: React.FC<IHeadingProps> = styled(motion.h1)<IHeadingProps>`
  ${(props) => defaultStyles(props)}
  color: ${(props) => (props.inherit === true ? "inherit" : props.theme.components.Text.title)};
`;

OldHeadingUseTextInstead.defaultProps = {
  size: "20px",
};

/**
 * @deprecated Use Text instead.
 */
export const OldSubheadingUseTextInstead: React.FC<IHeadingProps> = styled(
  motion.h3,
)<IHeadingProps>`
  ${(props) => defaultStyles(props)}
  color: ${(props) =>
    props.color !== undefined
      ? props.color
      : props.inherit === true
      ? "inherit"
      : props.theme.components.Text.subtitle};
  font-weight: normal;
`;

OldSubheadingUseTextInstead.defaultProps = {
  size: "13px",
};
