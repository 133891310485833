import { ThemeProvider } from "@emotion/react";
import { AnimatePresence } from "framer-motion";
import React from "react";

import { useModal, useModalState } from "~src/shared/modals/modal-context";

import { FullPageBlur } from "./FullPageBlur";
import { getTopOfStack, ModalContent } from "./ModalContent";

/**
 * A stack-based modal.
 * Triggered by using useModal.
 */
export const Modal: React.FC = () => {
  const { stack } = useModalState();
  const { removeTopOfStack } = useModal();

  const topOfStack = getTopOfStack(stack);

  const handleOverlayClick = () => {
    if (topOfStack?.config?.isCloseOnOverlayClickDisabled === true) {
      // Don't do anything if isCloseOnOverlayClickDisabled is true
    } else if (topOfStack?.config?.onClose !== undefined) {
      // Call StackItem's onClose if it has it
      topOfStack.config.onClose();
    } else {
      // Else remove the top of the stack
      removeTopOfStack();
    }
  };

  return (
    <AnimatePresence>
      {topOfStack !== undefined && (
        <ThemeProvider theme={topOfStack.theme}>
          <FullPageBlur
            isBlurred={topOfStack.config?.isOverlayBlurred}
            isOverlayFullPage={topOfStack.config?.isOverlayFullPage}
            onClick={handleOverlayClick}
            zIndex={topOfStack.config?.zIndex ?? "150"}
          >
            <ModalContent />
          </FullPageBlur>
        </ThemeProvider>
      )}
    </AnimatePresence>
  );
};
