import React, { useEffect } from "react";

import { IEnvResponse } from "~src/pages/api/env";
import { IEnvironment } from "~src/shared/env";
import { isWindowDefined, logError } from "~src/shared/helpers";

let envCached: IEnvironment | null = null;

/**
 * Loads the relevant server runtime env vars from /api/env, which exposes them.
 */
export const useLoadEnv = (): IEnvironment | null => {
  const [env, setEnv] = React.useState<IEnvironment | null>(envCached);

  useEffect(() => {
    // only load these client side
    if (!isWindowDefined) {
      return;
    }

    // We only need the env once. It is very unlikely to change on subsequent
    // page loads.
    if (envCached !== null) {
      return;
    }

    // we don't have the env. need to fetch from the backend
    // workaround to make analytics work for static pages
    fetch("/api/env")
      .then((res) => res.json())
      .then((resp: IEnvResponse) => {
        setEnv(resp.env);
        envCached = resp.env;
      })
      .catch((err) => logError(err));
  }, []);

  return env;
};
