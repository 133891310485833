import { useCallback } from "react";

import { forceDeleteApolloClient } from "~src/shared/apollo/withApolloNext/initApollo";
import { authedRequests } from "~src/shared/requests/authedRequests";
import { IOutput } from "~src/shared/requests/types";
import { callRequest } from "~src/shared/requests/useRequest";
import { IUnauthedRequestLogin, unauthedRequests } from "~src/unauthed/requests";

import { IAuthenticationData } from "./types";

/**
 * Authentication-related actions
 */
export interface IAuthActions {
  /**
   * Logs in as the given user.
   */
  login: (args: { email: string; password: string }) => Promise<IOutput<IUnauthedRequestLogin>>;
  /**
   * Logs out the current user.
   */
  logout: () => Promise<void>;
}

type IArgs = {
  revalidate: () => Promise<IAuthenticationData>;
  pushRoute: (path: string) => Promise<void>;
};

export const useAuthActions = ({ revalidate, pushRoute }: IArgs): IAuthActions => {
  const login = useCallback(
    async (args: { email: string; password: string }) => {
      const res = await callRequest(unauthedRequests.login(args), {
        handleRPCError: (err) => {
          if (err.errorMessage === "Please re-check your login credentials.") {
            // ignore invalid login attempts
            return true;
          }
        },
      });
      if (res.ok) {
        // re-download the user/vendor to prevent auto-redirecting to login
        await revalidate();
        forceDeleteApolloClient();
      }
      return res;
    },
    [revalidate],
  );

  const logout = useCallback(async () => {
    await callRequest(authedRequests.logout({}));
    forceDeleteApolloClient();
    await pushRoute("/login");
    // TODO(johnrjj) - Is revalidate needed in this dep array?
  }, [pushRoute]);

  return { login, logout };
};
