import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { ImpulseSpinner } from "react-spinners-kit";

type IProps = React.ComponentProps<typeof ImpulseSpinner>;

const _LoadingDots: React.FC<IProps> = (props) => {
  const theme = useTheme();

  return (
    <ImpulseSpinner
      size={30}
      frontColor={theme.components.Loader.Dots.primary}
      backColor={theme.components.Loader.Dots.background}
      {...props}
    />
  );
};

export const LoadingDots = styled(_LoadingDots)``;
