import { useEffect, useState } from "react";

let hasComputedInitialRedirectCached = false;

/**
 * Checks if a redirect should happen on the initial page load.
 */
export const useRedirectOnInitialLoad = ({
  pushRoute,
}: {
  pushRoute: (path: string) => Promise<void>;
}): {
  hasComputedInitialRedirect: boolean;
} => {
  // If true, the redirect destination has been computed for the initial page load.
  // This does not apply to subsequent page loads.
  // This prevents vendors from accessing a buggy pre-loaded admin panel and vice-versa.
  const [hasComputedInitialRedirect, setHasComputedInitialRedirect] = useState<boolean>(
    hasComputedInitialRedirectCached,
  );

  // load initial user data only once
  useEffect(() => {
    if (!hasComputedInitialRedirect && typeof window !== "undefined") {
      (async () => {
        const { search, pathname } = window.location;
        const fullPath = `${pathname}${search}`;
        await pushRoute(fullPath);
        setHasComputedInitialRedirect(true);
        hasComputedInitialRedirectCached = true;
      })();
    }
    // NOTE(johnrjj) - Load only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasComputedInitialRedirect]);

  return {
    hasComputedInitialRedirect,
  };
};
