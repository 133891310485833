/* eslint-disable react/style-prop-object */
import React from "react";

export const BackIcon: React.FC = () => (
  <svg
    width="6"
    height="9"
    viewBox="0 0 6 9"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.5575 4.5L5.025 2.0325C5.1675 1.8975 5.25 1.71 5.25 1.5C5.25 1.0875 4.9125 0.75 4.5 0.75C4.29 0.75 4.1025 0.8325 3.9675 0.9675L0.9675 3.9675C0.8325 4.1025 0.75 4.29 0.75 4.5C0.75 4.71 0.8325 4.8975 0.9675 5.0325L3.9675 8.0325C4.1025 8.1675 4.29 8.25 4.5 8.25C4.9125 8.25 5.25 7.9125 5.25 7.5C5.25 7.29 5.1675 7.1025 5.0325 6.9675L2.5575 4.5Z" />
  </svg>
);
