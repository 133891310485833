import { IEnvironment } from "~src/shared/env";

import { useAuthContext } from "../auth/AuthProvider";

/**
 * Gets the current environment set by server-side runtime environment variables.
 *
 * For more information, see src/lib/env.ts.
 */
export const useEnv = (): IEnvironment => {
  const { env } = useAuthContext();
  if (env === null) {
    throw new Error("Environment not found.");
  }
  return env;
};
