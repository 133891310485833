export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _int4: any;
  _jsonb: any;
  _text: string[];
  bigint: number;
  cidr: any;
  currency: string;
  date: string;
  float8: number;
  json: any;
  jsonb: unknown;
  name: any;
  numeric: number;
  revenue_stream_source: string;
  smallint: any;
  timestamp: string;
  timestamptz: string;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['Boolean']>;
  readonly _gt?: InputMaybe<Scalars['Boolean']>;
  readonly _gte?: InputMaybe<Scalars['Boolean']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['Boolean']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['Boolean']>;
  readonly _lte?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<Scalars['Boolean']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['Int']>;
  readonly _gt?: InputMaybe<Scalars['Int']>;
  readonly _gte?: InputMaybe<Scalars['Int']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['Int']>;
  readonly _lte?: InputMaybe<Scalars['Int']>;
  readonly _neq?: InputMaybe<Scalars['Int']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['String']>;
  readonly _gt?: InputMaybe<Scalars['String']>;
  readonly _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  readonly _ilike?: InputMaybe<Scalars['String']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  readonly _iregex?: InputMaybe<Scalars['String']>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  readonly _like?: InputMaybe<Scalars['String']>;
  readonly _lt?: InputMaybe<Scalars['String']>;
  readonly _lte?: InputMaybe<Scalars['String']>;
  readonly _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  readonly _nilike?: InputMaybe<Scalars['String']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  readonly _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  readonly _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  readonly _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  readonly _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  readonly _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  readonly _similar?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "_int4". All fields are combined with logical 'AND'. */
export type _int4_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['_int4']>;
  readonly _gt?: InputMaybe<Scalars['_int4']>;
  readonly _gte?: InputMaybe<Scalars['_int4']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['_int4']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['_int4']>;
  readonly _lte?: InputMaybe<Scalars['_int4']>;
  readonly _neq?: InputMaybe<Scalars['_int4']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['_int4']>>;
};

/** Boolean expression to compare columns of type "_jsonb". All fields are combined with logical 'AND'. */
export type _jsonb_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['_jsonb']>;
  readonly _gt?: InputMaybe<Scalars['_jsonb']>;
  readonly _gte?: InputMaybe<Scalars['_jsonb']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['_jsonb']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['_jsonb']>;
  readonly _lte?: InputMaybe<Scalars['_jsonb']>;
  readonly _neq?: InputMaybe<Scalars['_jsonb']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['_jsonb']>>;
};

/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _text_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['_text']>;
  readonly _gt?: InputMaybe<Scalars['_text']>;
  readonly _gte?: InputMaybe<Scalars['_text']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['_text']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['_text']>;
  readonly _lte?: InputMaybe<Scalars['_text']>;
  readonly _neq?: InputMaybe<Scalars['_text']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['_text']>>;
};

/** columns and relationships of "account_bank_balance_deltas" */
export type account_bank_balance_deltas = {
  readonly __typename?: 'account_bank_balance_deltas';
  /** An object relationship */
  readonly account?: Maybe<accounts>;
  readonly delta?: Maybe<Scalars['numeric']>;
  readonly delta_adjusted_for_runway?: Maybe<Scalars['numeric']>;
  readonly month?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "account_bank_balance_deltas" */
export type account_bank_balance_deltas_aggregate = {
  readonly __typename?: 'account_bank_balance_deltas_aggregate';
  readonly aggregate?: Maybe<account_bank_balance_deltas_aggregate_fields>;
  readonly nodes: ReadonlyArray<account_bank_balance_deltas>;
};

/** aggregate fields of "account_bank_balance_deltas" */
export type account_bank_balance_deltas_aggregate_fields = {
  readonly __typename?: 'account_bank_balance_deltas_aggregate_fields';
  readonly avg?: Maybe<account_bank_balance_deltas_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<account_bank_balance_deltas_max_fields>;
  readonly min?: Maybe<account_bank_balance_deltas_min_fields>;
  readonly stddev?: Maybe<account_bank_balance_deltas_stddev_fields>;
  readonly stddev_pop?: Maybe<account_bank_balance_deltas_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<account_bank_balance_deltas_stddev_samp_fields>;
  readonly sum?: Maybe<account_bank_balance_deltas_sum_fields>;
  readonly var_pop?: Maybe<account_bank_balance_deltas_var_pop_fields>;
  readonly var_samp?: Maybe<account_bank_balance_deltas_var_samp_fields>;
  readonly variance?: Maybe<account_bank_balance_deltas_variance_fields>;
};


/** aggregate fields of "account_bank_balance_deltas" */
export type account_bank_balance_deltas_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<account_bank_balance_deltas_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account_bank_balance_deltas" */
export type account_bank_balance_deltas_aggregate_order_by = {
  readonly avg?: InputMaybe<account_bank_balance_deltas_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<account_bank_balance_deltas_max_order_by>;
  readonly min?: InputMaybe<account_bank_balance_deltas_min_order_by>;
  readonly stddev?: InputMaybe<account_bank_balance_deltas_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<account_bank_balance_deltas_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<account_bank_balance_deltas_stddev_samp_order_by>;
  readonly sum?: InputMaybe<account_bank_balance_deltas_sum_order_by>;
  readonly var_pop?: InputMaybe<account_bank_balance_deltas_var_pop_order_by>;
  readonly var_samp?: InputMaybe<account_bank_balance_deltas_var_samp_order_by>;
  readonly variance?: InputMaybe<account_bank_balance_deltas_variance_order_by>;
};

/** aggregate avg on columns */
export type account_bank_balance_deltas_avg_fields = {
  readonly __typename?: 'account_bank_balance_deltas_avg_fields';
  readonly delta?: Maybe<Scalars['Float']>;
  readonly delta_adjusted_for_runway?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account_bank_balance_deltas" */
export type account_bank_balance_deltas_avg_order_by = {
  readonly delta?: InputMaybe<order_by>;
  readonly delta_adjusted_for_runway?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "account_bank_balance_deltas". All fields are combined with a logical 'AND'. */
export type account_bank_balance_deltas_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<account_bank_balance_deltas_bool_exp>>;
  readonly _not?: InputMaybe<account_bank_balance_deltas_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<account_bank_balance_deltas_bool_exp>>;
  readonly account?: InputMaybe<accounts_bool_exp>;
  readonly delta?: InputMaybe<numeric_comparison_exp>;
  readonly delta_adjusted_for_runway?: InputMaybe<numeric_comparison_exp>;
  readonly month?: InputMaybe<timestamptz_comparison_exp>;
};

/** aggregate max on columns */
export type account_bank_balance_deltas_max_fields = {
  readonly __typename?: 'account_bank_balance_deltas_max_fields';
  readonly delta?: Maybe<Scalars['numeric']>;
  readonly delta_adjusted_for_runway?: Maybe<Scalars['numeric']>;
  readonly month?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "account_bank_balance_deltas" */
export type account_bank_balance_deltas_max_order_by = {
  readonly delta?: InputMaybe<order_by>;
  readonly delta_adjusted_for_runway?: InputMaybe<order_by>;
  readonly month?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type account_bank_balance_deltas_min_fields = {
  readonly __typename?: 'account_bank_balance_deltas_min_fields';
  readonly delta?: Maybe<Scalars['numeric']>;
  readonly delta_adjusted_for_runway?: Maybe<Scalars['numeric']>;
  readonly month?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "account_bank_balance_deltas" */
export type account_bank_balance_deltas_min_order_by = {
  readonly delta?: InputMaybe<order_by>;
  readonly delta_adjusted_for_runway?: InputMaybe<order_by>;
  readonly month?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "account_bank_balance_deltas". */
export type account_bank_balance_deltas_order_by = {
  readonly account?: InputMaybe<accounts_order_by>;
  readonly delta?: InputMaybe<order_by>;
  readonly delta_adjusted_for_runway?: InputMaybe<order_by>;
  readonly month?: InputMaybe<order_by>;
};

/** select columns of table "account_bank_balance_deltas" */
export enum account_bank_balance_deltas_select_column {
  /** column name */
  delta = 'delta',
  /** column name */
  delta_adjusted_for_runway = 'delta_adjusted_for_runway',
  /** column name */
  month = 'month'
}

/** aggregate stddev on columns */
export type account_bank_balance_deltas_stddev_fields = {
  readonly __typename?: 'account_bank_balance_deltas_stddev_fields';
  readonly delta?: Maybe<Scalars['Float']>;
  readonly delta_adjusted_for_runway?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account_bank_balance_deltas" */
export type account_bank_balance_deltas_stddev_order_by = {
  readonly delta?: InputMaybe<order_by>;
  readonly delta_adjusted_for_runway?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type account_bank_balance_deltas_stddev_pop_fields = {
  readonly __typename?: 'account_bank_balance_deltas_stddev_pop_fields';
  readonly delta?: Maybe<Scalars['Float']>;
  readonly delta_adjusted_for_runway?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account_bank_balance_deltas" */
export type account_bank_balance_deltas_stddev_pop_order_by = {
  readonly delta?: InputMaybe<order_by>;
  readonly delta_adjusted_for_runway?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type account_bank_balance_deltas_stddev_samp_fields = {
  readonly __typename?: 'account_bank_balance_deltas_stddev_samp_fields';
  readonly delta?: Maybe<Scalars['Float']>;
  readonly delta_adjusted_for_runway?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account_bank_balance_deltas" */
export type account_bank_balance_deltas_stddev_samp_order_by = {
  readonly delta?: InputMaybe<order_by>;
  readonly delta_adjusted_for_runway?: InputMaybe<order_by>;
};

/** aggregate sum on columns */
export type account_bank_balance_deltas_sum_fields = {
  readonly __typename?: 'account_bank_balance_deltas_sum_fields';
  readonly delta?: Maybe<Scalars['numeric']>;
  readonly delta_adjusted_for_runway?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "account_bank_balance_deltas" */
export type account_bank_balance_deltas_sum_order_by = {
  readonly delta?: InputMaybe<order_by>;
  readonly delta_adjusted_for_runway?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type account_bank_balance_deltas_var_pop_fields = {
  readonly __typename?: 'account_bank_balance_deltas_var_pop_fields';
  readonly delta?: Maybe<Scalars['Float']>;
  readonly delta_adjusted_for_runway?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account_bank_balance_deltas" */
export type account_bank_balance_deltas_var_pop_order_by = {
  readonly delta?: InputMaybe<order_by>;
  readonly delta_adjusted_for_runway?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type account_bank_balance_deltas_var_samp_fields = {
  readonly __typename?: 'account_bank_balance_deltas_var_samp_fields';
  readonly delta?: Maybe<Scalars['Float']>;
  readonly delta_adjusted_for_runway?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account_bank_balance_deltas" */
export type account_bank_balance_deltas_var_samp_order_by = {
  readonly delta?: InputMaybe<order_by>;
  readonly delta_adjusted_for_runway?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type account_bank_balance_deltas_variance_fields = {
  readonly __typename?: 'account_bank_balance_deltas_variance_fields';
  readonly delta?: Maybe<Scalars['Float']>;
  readonly delta_adjusted_for_runway?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account_bank_balance_deltas" */
export type account_bank_balance_deltas_variance_order_by = {
  readonly delta?: InputMaybe<order_by>;
  readonly delta_adjusted_for_runway?: InputMaybe<order_by>;
};

/** columns and relationships of "accounting_metrics" */
export type accounting_metrics = {
  readonly __typename?: 'accounting_metrics';
  /** End of most recent full period */
  readonly cash_balance?: Maybe<Scalars['bigint']>;
  /** Average over the last 3 fully reported months */
  readonly cogs: Scalars['bigint'];
  readonly currency: Scalars['currency'];
  /** End of most recent full period */
  readonly current_assets?: Maybe<Scalars['bigint']>;
  /** End of most recent full period */
  readonly current_liabilities?: Maybe<Scalars['bigint']>;
  readonly end_date: Scalars['date'];
  /** Average over the last 3 fully reported months */
  readonly gross_margin: Scalars['float8'];
  /** Last completed quarter vs. the quarter before */
  readonly net_income_trend_qoq: Scalars['float8'];
  readonly public_id: Scalars['String'];
  /** Average over the last 3 fully reported months */
  readonly revenue: Scalars['bigint'];
  /** End of most recent full period */
  readonly total_assets: Scalars['bigint'];
  /** Average over the last 3 fully reported months */
  readonly total_expenses: Scalars['bigint'];
  /** End of most recent full period */
  readonly total_liabilities: Scalars['bigint'];
};

/** Boolean expression to filter rows from the table "accounting_metrics". All fields are combined with a logical 'AND'. */
export type accounting_metrics_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<accounting_metrics_bool_exp>>;
  readonly _not?: InputMaybe<accounting_metrics_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<accounting_metrics_bool_exp>>;
  readonly cash_balance?: InputMaybe<bigint_comparison_exp>;
  readonly cogs?: InputMaybe<bigint_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly current_assets?: InputMaybe<bigint_comparison_exp>;
  readonly current_liabilities?: InputMaybe<bigint_comparison_exp>;
  readonly end_date?: InputMaybe<date_comparison_exp>;
  readonly gross_margin?: InputMaybe<float8_comparison_exp>;
  readonly net_income_trend_qoq?: InputMaybe<float8_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly revenue?: InputMaybe<bigint_comparison_exp>;
  readonly total_assets?: InputMaybe<bigint_comparison_exp>;
  readonly total_expenses?: InputMaybe<bigint_comparison_exp>;
  readonly total_liabilities?: InputMaybe<bigint_comparison_exp>;
};

/** Ordering options when selecting data from "accounting_metrics". */
export type accounting_metrics_order_by = {
  readonly cash_balance?: InputMaybe<order_by>;
  readonly cogs?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly current_assets?: InputMaybe<order_by>;
  readonly current_liabilities?: InputMaybe<order_by>;
  readonly end_date?: InputMaybe<order_by>;
  readonly gross_margin?: InputMaybe<order_by>;
  readonly net_income_trend_qoq?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly revenue?: InputMaybe<order_by>;
  readonly total_assets?: InputMaybe<order_by>;
  readonly total_expenses?: InputMaybe<order_by>;
  readonly total_liabilities?: InputMaybe<order_by>;
};

/** select columns of table "accounting_metrics" */
export enum accounting_metrics_select_column {
  /** column name */
  cash_balance = 'cash_balance',
  /** column name */
  cogs = 'cogs',
  /** column name */
  currency = 'currency',
  /** column name */
  current_assets = 'current_assets',
  /** column name */
  current_liabilities = 'current_liabilities',
  /** column name */
  end_date = 'end_date',
  /** column name */
  gross_margin = 'gross_margin',
  /** column name */
  net_income_trend_qoq = 'net_income_trend_qoq',
  /** column name */
  public_id = 'public_id',
  /** column name */
  revenue = 'revenue',
  /** column name */
  total_assets = 'total_assets',
  /** column name */
  total_expenses = 'total_expenses',
  /** column name */
  total_liabilities = 'total_liabilities'
}

/** columns and relationships of "accounts" */
export type accounts = {
  readonly __typename?: 'accounts';
  readonly balance_available: Scalars['bigint'];
  readonly balance_current: Scalars['bigint'];
  readonly balance_limit: Scalars['bigint'];
  /** An array relationship */
  readonly bank_balance_deltas: ReadonlyArray<account_bank_balance_deltas>;
  /** An aggregate relationship */
  readonly bank_balance_deltas_aggregate: account_bank_balance_deltas_aggregate;
  readonly created_at: Scalars['timestamptz'];
  readonly currency: Scalars['currency'];
  /** An array relationship */
  readonly historical_bank_data: ReadonlyArray<historical_bank_data>;
  readonly is_excluded: Scalars['Boolean'];
  readonly mask: Scalars['String'];
  readonly name: Scalars['String'];
  /** An object relationship */
  readonly plaid_item?: Maybe<plaid_items>;
  readonly public_id: Scalars['String'];
  readonly subtype?: Maybe<Scalars['String']>;
  readonly supports_money_movement: Scalars['Boolean'];
  /** An array relationship */
  readonly transactions: ReadonlyArray<transactions>;
  readonly type: Scalars['String'];
  readonly updated_at: Scalars['timestamptz'];
  /** An object relationship */
  readonly vendor: vendors;
  /** An array relationship */
  readonly vendors: ReadonlyArray<vendors>;
  /** An array relationship */
  readonly vendorsByPayoutMethodId: ReadonlyArray<vendors>;
  /** An aggregate relationship */
  readonly vendorsByPayoutMethodId_aggregate: vendors_aggregate;
  /** An aggregate relationship */
  readonly vendors_aggregate: vendors_aggregate;
};


/** columns and relationships of "accounts" */
export type accountsbank_balance_deltasArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<account_bank_balance_deltas_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<account_bank_balance_deltas_order_by>>;
  where?: InputMaybe<account_bank_balance_deltas_bool_exp>;
};


/** columns and relationships of "accounts" */
export type accountsbank_balance_deltas_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<account_bank_balance_deltas_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<account_bank_balance_deltas_order_by>>;
  where?: InputMaybe<account_bank_balance_deltas_bool_exp>;
};


/** columns and relationships of "accounts" */
export type accountshistorical_bank_dataArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<historical_bank_data_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<historical_bank_data_order_by>>;
  where?: InputMaybe<historical_bank_data_bool_exp>;
};


/** columns and relationships of "accounts" */
export type accountstransactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<transactions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<transactions_order_by>>;
  where?: InputMaybe<transactions_bool_exp>;
};


/** columns and relationships of "accounts" */
export type accountsvendorsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendors_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendors_order_by>>;
  where?: InputMaybe<vendors_bool_exp>;
};


/** columns and relationships of "accounts" */
export type accountsvendorsByPayoutMethodIdArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendors_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendors_order_by>>;
  where?: InputMaybe<vendors_bool_exp>;
};


/** columns and relationships of "accounts" */
export type accountsvendorsByPayoutMethodId_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendors_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendors_order_by>>;
  where?: InputMaybe<vendors_bool_exp>;
};


/** columns and relationships of "accounts" */
export type accountsvendors_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendors_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendors_order_by>>;
  where?: InputMaybe<vendors_bool_exp>;
};

/** order by aggregate values of table "accounts" */
export type accounts_aggregate_order_by = {
  readonly avg?: InputMaybe<accounts_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<accounts_max_order_by>;
  readonly min?: InputMaybe<accounts_min_order_by>;
  readonly stddev?: InputMaybe<accounts_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<accounts_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<accounts_stddev_samp_order_by>;
  readonly sum?: InputMaybe<accounts_sum_order_by>;
  readonly var_pop?: InputMaybe<accounts_var_pop_order_by>;
  readonly var_samp?: InputMaybe<accounts_var_samp_order_by>;
  readonly variance?: InputMaybe<accounts_variance_order_by>;
};

/** order by avg() on columns of table "accounts" */
export type accounts_avg_order_by = {
  readonly balance_available?: InputMaybe<order_by>;
  readonly balance_current?: InputMaybe<order_by>;
  readonly balance_limit?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "accounts". All fields are combined with a logical 'AND'. */
export type accounts_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<accounts_bool_exp>>;
  readonly _not?: InputMaybe<accounts_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<accounts_bool_exp>>;
  readonly balance_available?: InputMaybe<bigint_comparison_exp>;
  readonly balance_current?: InputMaybe<bigint_comparison_exp>;
  readonly balance_limit?: InputMaybe<bigint_comparison_exp>;
  readonly bank_balance_deltas?: InputMaybe<account_bank_balance_deltas_bool_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly historical_bank_data?: InputMaybe<historical_bank_data_bool_exp>;
  readonly is_excluded?: InputMaybe<Boolean_comparison_exp>;
  readonly mask?: InputMaybe<String_comparison_exp>;
  readonly name?: InputMaybe<String_comparison_exp>;
  readonly plaid_item?: InputMaybe<plaid_items_bool_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly subtype?: InputMaybe<String_comparison_exp>;
  readonly supports_money_movement?: InputMaybe<Boolean_comparison_exp>;
  readonly transactions?: InputMaybe<transactions_bool_exp>;
  readonly type?: InputMaybe<String_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
  readonly vendors?: InputMaybe<vendors_bool_exp>;
  readonly vendorsByPayoutMethodId?: InputMaybe<vendors_bool_exp>;
};

/** order by max() on columns of table "accounts" */
export type accounts_max_order_by = {
  readonly balance_available?: InputMaybe<order_by>;
  readonly balance_current?: InputMaybe<order_by>;
  readonly balance_limit?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly mask?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly subtype?: InputMaybe<order_by>;
  readonly type?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** order by min() on columns of table "accounts" */
export type accounts_min_order_by = {
  readonly balance_available?: InputMaybe<order_by>;
  readonly balance_current?: InputMaybe<order_by>;
  readonly balance_limit?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly mask?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly subtype?: InputMaybe<order_by>;
  readonly type?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "accounts" */
export type accounts_mutation_response = {
  readonly __typename?: 'accounts_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<accounts>;
};

/** Ordering options when selecting data from "accounts". */
export type accounts_order_by = {
  readonly balance_available?: InputMaybe<order_by>;
  readonly balance_current?: InputMaybe<order_by>;
  readonly balance_limit?: InputMaybe<order_by>;
  readonly bank_balance_deltas_aggregate?: InputMaybe<account_bank_balance_deltas_aggregate_order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly historical_bank_data_aggregate?: InputMaybe<historical_bank_data_aggregate_order_by>;
  readonly is_excluded?: InputMaybe<order_by>;
  readonly mask?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly plaid_item?: InputMaybe<plaid_items_order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly subtype?: InputMaybe<order_by>;
  readonly supports_money_movement?: InputMaybe<order_by>;
  readonly transactions_aggregate?: InputMaybe<transactions_aggregate_order_by>;
  readonly type?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
  readonly vendorsByPayoutMethodId_aggregate?: InputMaybe<vendors_aggregate_order_by>;
  readonly vendors_aggregate?: InputMaybe<vendors_aggregate_order_by>;
};

/** select columns of table "accounts" */
export enum accounts_select_column {
  /** column name */
  balance_available = 'balance_available',
  /** column name */
  balance_current = 'balance_current',
  /** column name */
  balance_limit = 'balance_limit',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  is_excluded = 'is_excluded',
  /** column name */
  mask = 'mask',
  /** column name */
  name = 'name',
  /** column name */
  public_id = 'public_id',
  /** column name */
  subtype = 'subtype',
  /** column name */
  supports_money_movement = 'supports_money_movement',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "accounts" */
export type accounts_set_input = {
  readonly is_excluded?: InputMaybe<Scalars['Boolean']>;
};

/** order by stddev() on columns of table "accounts" */
export type accounts_stddev_order_by = {
  readonly balance_available?: InputMaybe<order_by>;
  readonly balance_current?: InputMaybe<order_by>;
  readonly balance_limit?: InputMaybe<order_by>;
};

/** order by stddev_pop() on columns of table "accounts" */
export type accounts_stddev_pop_order_by = {
  readonly balance_available?: InputMaybe<order_by>;
  readonly balance_current?: InputMaybe<order_by>;
  readonly balance_limit?: InputMaybe<order_by>;
};

/** order by stddev_samp() on columns of table "accounts" */
export type accounts_stddev_samp_order_by = {
  readonly balance_available?: InputMaybe<order_by>;
  readonly balance_current?: InputMaybe<order_by>;
  readonly balance_limit?: InputMaybe<order_by>;
};

/** order by sum() on columns of table "accounts" */
export type accounts_sum_order_by = {
  readonly balance_available?: InputMaybe<order_by>;
  readonly balance_current?: InputMaybe<order_by>;
  readonly balance_limit?: InputMaybe<order_by>;
};

/** order by var_pop() on columns of table "accounts" */
export type accounts_var_pop_order_by = {
  readonly balance_available?: InputMaybe<order_by>;
  readonly balance_current?: InputMaybe<order_by>;
  readonly balance_limit?: InputMaybe<order_by>;
};

/** order by var_samp() on columns of table "accounts" */
export type accounts_var_samp_order_by = {
  readonly balance_available?: InputMaybe<order_by>;
  readonly balance_current?: InputMaybe<order_by>;
  readonly balance_limit?: InputMaybe<order_by>;
};

/** order by variance() on columns of table "accounts" */
export type accounts_variance_order_by = {
  readonly balance_available?: InputMaybe<order_by>;
  readonly balance_current?: InputMaybe<order_by>;
  readonly balance_limit?: InputMaybe<order_by>;
};

/** columns and relationships of "activity_log" */
export type activity_log = {
  readonly __typename?: 'activity_log';
  /** An object relationship */
  readonly activity_log_file_upload?: Maybe<activity_log_file_upload>;
  /** An object relationship */
  readonly activity_log_underwriting_update?: Maybe<activity_log_underwriting_update>;
  readonly created_at: Scalars['timestamptz'];
  /** An object relationship */
  readonly integration_status?: Maybe<activity_log_integration_status>;
  readonly log_type: activity_log_type_enum_enum;
  readonly notes?: Maybe<Scalars['jsonb']>;
  readonly public_id: Scalars['String'];
  /** An array relationship */
  readonly settings_updates: ReadonlyArray<activity_log_settings_update>;
  /** An object relationship */
  readonly snapshot?: Maybe<snapshots>;
  /** An object relationship */
  readonly traded_revenue_stream?: Maybe<activity_log_traded_revenue_streams>;
  /** An object relationship */
  readonly user?: Maybe<users>;
  /** An object relationship */
  readonly vendor: vendors;
};


/** columns and relationships of "activity_log" */
export type activity_lognotesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "activity_log" */
export type activity_logsettings_updatesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<activity_log_settings_update_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<activity_log_settings_update_order_by>>;
  where?: InputMaybe<activity_log_settings_update_bool_exp>;
};

/** order by aggregate values of table "activity_log" */
export type activity_log_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<activity_log_max_order_by>;
  readonly min?: InputMaybe<activity_log_min_order_by>;
};

/** Boolean expression to filter rows from the table "activity_log". All fields are combined with a logical 'AND'. */
export type activity_log_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<activity_log_bool_exp>>;
  readonly _not?: InputMaybe<activity_log_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<activity_log_bool_exp>>;
  readonly activity_log_file_upload?: InputMaybe<activity_log_file_upload_bool_exp>;
  readonly activity_log_underwriting_update?: InputMaybe<activity_log_underwriting_update_bool_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly integration_status?: InputMaybe<activity_log_integration_status_bool_exp>;
  readonly log_type?: InputMaybe<activity_log_type_enum_enum_comparison_exp>;
  readonly notes?: InputMaybe<jsonb_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly settings_updates?: InputMaybe<activity_log_settings_update_bool_exp>;
  readonly snapshot?: InputMaybe<snapshots_bool_exp>;
  readonly traded_revenue_stream?: InputMaybe<activity_log_traded_revenue_streams_bool_exp>;
  readonly user?: InputMaybe<users_bool_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** columns and relationships of "activity_log_file_upload" */
export type activity_log_file_upload = {
  readonly __typename?: 'activity_log_file_upload';
  readonly files: Scalars['_text'];
  readonly integration_type: integration_type_enum_enum;
  readonly public_id: Scalars['String'];
};

/** Boolean expression to filter rows from the table "activity_log_file_upload". All fields are combined with a logical 'AND'. */
export type activity_log_file_upload_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<activity_log_file_upload_bool_exp>>;
  readonly _not?: InputMaybe<activity_log_file_upload_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<activity_log_file_upload_bool_exp>>;
  readonly files?: InputMaybe<_text_comparison_exp>;
  readonly integration_type?: InputMaybe<integration_type_enum_enum_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
};

/** Ordering options when selecting data from "activity_log_file_upload". */
export type activity_log_file_upload_order_by = {
  readonly files?: InputMaybe<order_by>;
  readonly integration_type?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** select columns of table "activity_log_file_upload" */
export enum activity_log_file_upload_select_column {
  /** column name */
  files = 'files',
  /** column name */
  integration_type = 'integration_type',
  /** column name */
  public_id = 'public_id'
}

/** columns and relationships of "activity_log_integration_status" */
export type activity_log_integration_status = {
  readonly __typename?: 'activity_log_integration_status';
  readonly connected: Scalars['Boolean'];
  readonly integration_name: Scalars['String'];
  readonly integration_type: integration_type_enum_enum;
  readonly public_id: Scalars['String'];
};

/** Boolean expression to filter rows from the table "activity_log_integration_status". All fields are combined with a logical 'AND'. */
export type activity_log_integration_status_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<activity_log_integration_status_bool_exp>>;
  readonly _not?: InputMaybe<activity_log_integration_status_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<activity_log_integration_status_bool_exp>>;
  readonly connected?: InputMaybe<Boolean_comparison_exp>;
  readonly integration_name?: InputMaybe<String_comparison_exp>;
  readonly integration_type?: InputMaybe<integration_type_enum_enum_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
};

/** Ordering options when selecting data from "activity_log_integration_status". */
export type activity_log_integration_status_order_by = {
  readonly connected?: InputMaybe<order_by>;
  readonly integration_name?: InputMaybe<order_by>;
  readonly integration_type?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** select columns of table "activity_log_integration_status" */
export enum activity_log_integration_status_select_column {
  /** column name */
  connected = 'connected',
  /** column name */
  integration_name = 'integration_name',
  /** column name */
  integration_type = 'integration_type',
  /** column name */
  public_id = 'public_id'
}

/** order by max() on columns of table "activity_log" */
export type activity_log_max_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** order by min() on columns of table "activity_log" */
export type activity_log_min_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "activity_log". */
export type activity_log_order_by = {
  readonly activity_log_file_upload?: InputMaybe<activity_log_file_upload_order_by>;
  readonly activity_log_underwriting_update?: InputMaybe<activity_log_underwriting_update_order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly integration_status?: InputMaybe<activity_log_integration_status_order_by>;
  readonly log_type?: InputMaybe<order_by>;
  readonly notes?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly settings_updates_aggregate?: InputMaybe<activity_log_settings_update_aggregate_order_by>;
  readonly snapshot?: InputMaybe<snapshots_order_by>;
  readonly traded_revenue_stream?: InputMaybe<activity_log_traded_revenue_streams_order_by>;
  readonly user?: InputMaybe<users_order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "activity_log" */
export enum activity_log_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  log_type = 'log_type',
  /** column name */
  notes = 'notes',
  /** column name */
  public_id = 'public_id'
}

/**
 * typesafe way of representing a field (e.g. leadOwner) getting updated
 *
 *
 * columns and relationships of "activity_log_settings_update"
 */
export type activity_log_settings_update = {
  readonly __typename?: 'activity_log_settings_update';
  readonly field: Scalars['String'];
  readonly new: Scalars['String'];
  readonly old: Scalars['String'];
  readonly public_id: Scalars['String'];
};

/** order by aggregate values of table "activity_log_settings_update" */
export type activity_log_settings_update_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<activity_log_settings_update_max_order_by>;
  readonly min?: InputMaybe<activity_log_settings_update_min_order_by>;
};

/** Boolean expression to filter rows from the table "activity_log_settings_update". All fields are combined with a logical 'AND'. */
export type activity_log_settings_update_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<activity_log_settings_update_bool_exp>>;
  readonly _not?: InputMaybe<activity_log_settings_update_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<activity_log_settings_update_bool_exp>>;
  readonly field?: InputMaybe<String_comparison_exp>;
  readonly new?: InputMaybe<String_comparison_exp>;
  readonly old?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
};

/** order by max() on columns of table "activity_log_settings_update" */
export type activity_log_settings_update_max_order_by = {
  readonly field?: InputMaybe<order_by>;
  readonly new?: InputMaybe<order_by>;
  readonly old?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** order by min() on columns of table "activity_log_settings_update" */
export type activity_log_settings_update_min_order_by = {
  readonly field?: InputMaybe<order_by>;
  readonly new?: InputMaybe<order_by>;
  readonly old?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "activity_log_settings_update". */
export type activity_log_settings_update_order_by = {
  readonly field?: InputMaybe<order_by>;
  readonly new?: InputMaybe<order_by>;
  readonly old?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** select columns of table "activity_log_settings_update" */
export enum activity_log_settings_update_select_column {
  /** column name */
  field = 'field',
  /** column name */
  new = 'new',
  /** column name */
  old = 'old',
  /** column name */
  public_id = 'public_id'
}

/** columns and relationships of "activity_log_traded_revenue_streams" */
export type activity_log_traded_revenue_streams = {
  readonly __typename?: 'activity_log_traded_revenue_streams';
  readonly public_id: Scalars['String'];
  readonly revenue_stream_public_ids: Scalars['_text'];
};

/** Boolean expression to filter rows from the table "activity_log_traded_revenue_streams". All fields are combined with a logical 'AND'. */
export type activity_log_traded_revenue_streams_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<activity_log_traded_revenue_streams_bool_exp>>;
  readonly _not?: InputMaybe<activity_log_traded_revenue_streams_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<activity_log_traded_revenue_streams_bool_exp>>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly revenue_stream_public_ids?: InputMaybe<_text_comparison_exp>;
};

/** Ordering options when selecting data from "activity_log_traded_revenue_streams". */
export type activity_log_traded_revenue_streams_order_by = {
  readonly public_id?: InputMaybe<order_by>;
  readonly revenue_stream_public_ids?: InputMaybe<order_by>;
};

/** select columns of table "activity_log_traded_revenue_streams" */
export enum activity_log_traded_revenue_streams_select_column {
  /** column name */
  public_id = 'public_id',
  /** column name */
  revenue_stream_public_ids = 'revenue_stream_public_ids'
}

export enum activity_log_type_enum_enum {
  /** Vendor has uploaded files */
  file_upload = 'file_upload',
  /** Integration connections and disconnections */
  integration_status = 'integration_status',
  /** Updates to internal custom fields on vendors */
  internal_fields = 'internal_fields',
  /** Internal note */
  note = 'note',
  /** Automated notification sent to vendors */
  notification = 'notification',
  /** Vendor repayment status change */
  repayment_status_update = 'repayment_status_update',
  /** Vendor has traded subscriptions */
  trade = 'trade',
  /** Changes made by the underwriting team */
  underwriting_update = 'underwriting_update',
  /** Settings changes on vendor */
  vendor_settings = 'vendor_settings'
}

/** Boolean expression to compare columns of type "activity_log_type_enum_enum". All fields are combined with logical 'AND'. */
export type activity_log_type_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<activity_log_type_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<activity_log_type_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<activity_log_type_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<activity_log_type_enum_enum>>;
};

/** columns and relationships of "activity_log_underwriting_update" */
export type activity_log_underwriting_update = {
  readonly __typename?: 'activity_log_underwriting_update';
  readonly new_credit_limit?: Maybe<Scalars['bigint']>;
  readonly new_credit_limit_expiration?: Maybe<Scalars['timestamptz']>;
  readonly new_is_activated?: Maybe<Scalars['Boolean']>;
  readonly new_is_declined?: Maybe<Scalars['Boolean']>;
  readonly new_is_on_hold?: Maybe<Scalars['Boolean']>;
  readonly new_max_term?: Maybe<Scalars['Int']>;
  readonly new_rate_months_1?: Maybe<Scalars['Int']>;
  readonly new_rating?: Maybe<asset_rating_enum_enum>;
  readonly new_score?: Maybe<Scalars['float8']>;
  readonly old_credit_limit?: Maybe<Scalars['bigint']>;
  readonly old_credit_limit_expiration?: Maybe<Scalars['timestamptz']>;
  readonly old_is_activated?: Maybe<Scalars['Boolean']>;
  readonly old_is_declined?: Maybe<Scalars['Boolean']>;
  readonly old_is_on_hold?: Maybe<Scalars['Boolean']>;
  readonly old_max_term?: Maybe<Scalars['Int']>;
  readonly old_rate_months_1?: Maybe<Scalars['Int']>;
  readonly old_rating?: Maybe<asset_rating_enum_enum>;
  readonly old_score?: Maybe<Scalars['float8']>;
  readonly public_id: Scalars['String'];
  readonly update_type?: Maybe<activity_log_underwriting_update_type_enum_enum>;
};

/** Boolean expression to filter rows from the table "activity_log_underwriting_update". All fields are combined with a logical 'AND'. */
export type activity_log_underwriting_update_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<activity_log_underwriting_update_bool_exp>>;
  readonly _not?: InputMaybe<activity_log_underwriting_update_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<activity_log_underwriting_update_bool_exp>>;
  readonly new_credit_limit?: InputMaybe<bigint_comparison_exp>;
  readonly new_credit_limit_expiration?: InputMaybe<timestamptz_comparison_exp>;
  readonly new_is_activated?: InputMaybe<Boolean_comparison_exp>;
  readonly new_is_declined?: InputMaybe<Boolean_comparison_exp>;
  readonly new_is_on_hold?: InputMaybe<Boolean_comparison_exp>;
  readonly new_max_term?: InputMaybe<Int_comparison_exp>;
  readonly new_rate_months_1?: InputMaybe<Int_comparison_exp>;
  readonly new_rating?: InputMaybe<asset_rating_enum_enum_comparison_exp>;
  readonly new_score?: InputMaybe<float8_comparison_exp>;
  readonly old_credit_limit?: InputMaybe<bigint_comparison_exp>;
  readonly old_credit_limit_expiration?: InputMaybe<timestamptz_comparison_exp>;
  readonly old_is_activated?: InputMaybe<Boolean_comparison_exp>;
  readonly old_is_declined?: InputMaybe<Boolean_comparison_exp>;
  readonly old_is_on_hold?: InputMaybe<Boolean_comparison_exp>;
  readonly old_max_term?: InputMaybe<Int_comparison_exp>;
  readonly old_rate_months_1?: InputMaybe<Int_comparison_exp>;
  readonly old_rating?: InputMaybe<asset_rating_enum_enum_comparison_exp>;
  readonly old_score?: InputMaybe<float8_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly update_type?: InputMaybe<activity_log_underwriting_update_type_enum_enum_comparison_exp>;
};

/** Ordering options when selecting data from "activity_log_underwriting_update". */
export type activity_log_underwriting_update_order_by = {
  readonly new_credit_limit?: InputMaybe<order_by>;
  readonly new_credit_limit_expiration?: InputMaybe<order_by>;
  readonly new_is_activated?: InputMaybe<order_by>;
  readonly new_is_declined?: InputMaybe<order_by>;
  readonly new_is_on_hold?: InputMaybe<order_by>;
  readonly new_max_term?: InputMaybe<order_by>;
  readonly new_rate_months_1?: InputMaybe<order_by>;
  readonly new_rating?: InputMaybe<order_by>;
  readonly new_score?: InputMaybe<order_by>;
  readonly old_credit_limit?: InputMaybe<order_by>;
  readonly old_credit_limit_expiration?: InputMaybe<order_by>;
  readonly old_is_activated?: InputMaybe<order_by>;
  readonly old_is_declined?: InputMaybe<order_by>;
  readonly old_is_on_hold?: InputMaybe<order_by>;
  readonly old_max_term?: InputMaybe<order_by>;
  readonly old_rate_months_1?: InputMaybe<order_by>;
  readonly old_rating?: InputMaybe<order_by>;
  readonly old_score?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly update_type?: InputMaybe<order_by>;
};

/** select columns of table "activity_log_underwriting_update" */
export enum activity_log_underwriting_update_select_column {
  /** column name */
  new_credit_limit = 'new_credit_limit',
  /** column name */
  new_credit_limit_expiration = 'new_credit_limit_expiration',
  /** column name */
  new_is_activated = 'new_is_activated',
  /** column name */
  new_is_declined = 'new_is_declined',
  /** column name */
  new_is_on_hold = 'new_is_on_hold',
  /** column name */
  new_max_term = 'new_max_term',
  /** column name */
  new_rate_months_1 = 'new_rate_months_1',
  /** column name */
  new_rating = 'new_rating',
  /** column name */
  new_score = 'new_score',
  /** column name */
  old_credit_limit = 'old_credit_limit',
  /** column name */
  old_credit_limit_expiration = 'old_credit_limit_expiration',
  /** column name */
  old_is_activated = 'old_is_activated',
  /** column name */
  old_is_declined = 'old_is_declined',
  /** column name */
  old_is_on_hold = 'old_is_on_hold',
  /** column name */
  old_max_term = 'old_max_term',
  /** column name */
  old_rate_months_1 = 'old_rate_months_1',
  /** column name */
  old_rating = 'old_rating',
  /** column name */
  old_score = 'old_score',
  /** column name */
  public_id = 'public_id',
  /** column name */
  update_type = 'update_type'
}

export enum activity_log_underwriting_update_type_enum_enum {
  decision = 'decision',
  rating = 'rating',
  status = 'status'
}

/** Boolean expression to compare columns of type "activity_log_underwriting_update_type_enum_enum". All fields are combined with logical 'AND'. */
export type activity_log_underwriting_update_type_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<activity_log_underwriting_update_type_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<activity_log_underwriting_update_type_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<activity_log_underwriting_update_type_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<activity_log_underwriting_update_type_enum_enum>>;
};

/** columns and relationships of "api_key_allowed_ips" */
export type api_key_allowed_ips = {
  readonly __typename?: 'api_key_allowed_ips';
  /** An object relationship */
  readonly api_key: api_keys;
  readonly ip: Scalars['cidr'];
};

/** order by aggregate values of table "api_key_allowed_ips" */
export type api_key_allowed_ips_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "api_key_allowed_ips". All fields are combined with a logical 'AND'. */
export type api_key_allowed_ips_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<api_key_allowed_ips_bool_exp>>;
  readonly _not?: InputMaybe<api_key_allowed_ips_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<api_key_allowed_ips_bool_exp>>;
  readonly api_key?: InputMaybe<api_keys_bool_exp>;
  readonly ip?: InputMaybe<cidr_comparison_exp>;
};

/** Ordering options when selecting data from "api_key_allowed_ips". */
export type api_key_allowed_ips_order_by = {
  readonly api_key?: InputMaybe<api_keys_order_by>;
  readonly ip?: InputMaybe<order_by>;
};

/** select columns of table "api_key_allowed_ips" */
export enum api_key_allowed_ips_select_column {
  /** column name */
  ip = 'ip'
}

/** columns and relationships of "api_keys" */
export type api_keys = {
  readonly __typename?: 'api_keys';
  /** An array relationship */
  readonly api_key_allowed_ips: ReadonlyArray<api_key_allowed_ips>;
  readonly created_at: Scalars['timestamptz'];
  /** An object relationship */
  readonly created_by_user: users;
  readonly deleted_at?: Maybe<Scalars['timestamptz']>;
  /**
   * If true, only API calls from IP addresses that have been
   * added to the allowlist will be accepted
   */
  readonly ip_allowlist_enabled: Scalars['Boolean'];
  readonly last_used_at?: Maybe<Scalars['timestamptz']>;
  readonly public_id: Scalars['String'];
  /** An object relationship */
  readonly vendor: vendors;
};


/** columns and relationships of "api_keys" */
export type api_keysapi_key_allowed_ipsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<api_key_allowed_ips_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<api_key_allowed_ips_order_by>>;
  where?: InputMaybe<api_key_allowed_ips_bool_exp>;
};

/** Boolean expression to filter rows from the table "api_keys". All fields are combined with a logical 'AND'. */
export type api_keys_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<api_keys_bool_exp>>;
  readonly _not?: InputMaybe<api_keys_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<api_keys_bool_exp>>;
  readonly api_key_allowed_ips?: InputMaybe<api_key_allowed_ips_bool_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly created_by_user?: InputMaybe<users_bool_exp>;
  readonly deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly ip_allowlist_enabled?: InputMaybe<Boolean_comparison_exp>;
  readonly last_used_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** Ordering options when selecting data from "api_keys". */
export type api_keys_order_by = {
  readonly api_key_allowed_ips_aggregate?: InputMaybe<api_key_allowed_ips_aggregate_order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly created_by_user?: InputMaybe<users_order_by>;
  readonly deleted_at?: InputMaybe<order_by>;
  readonly ip_allowlist_enabled?: InputMaybe<order_by>;
  readonly last_used_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "api_keys" */
export enum api_keys_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  ip_allowlist_enabled = 'ip_allowlist_enabled',
  /** column name */
  last_used_at = 'last_used_at',
  /** column name */
  public_id = 'public_id'
}

/** columns and relationships of "apple_data_sources" */
export type apple_data_sources = {
  readonly __typename?: 'apple_data_sources';
  readonly account_number: Scalars['String'];
  readonly public_id: Scalars['String'];
  readonly vendor_number: Scalars['String'];
};

/** Boolean expression to filter rows from the table "apple_data_sources". All fields are combined with a logical 'AND'. */
export type apple_data_sources_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<apple_data_sources_bool_exp>>;
  readonly _not?: InputMaybe<apple_data_sources_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<apple_data_sources_bool_exp>>;
  readonly account_number?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly vendor_number?: InputMaybe<String_comparison_exp>;
};

/** Ordering options when selecting data from "apple_data_sources". */
export type apple_data_sources_order_by = {
  readonly account_number?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly vendor_number?: InputMaybe<order_by>;
};

/** select columns of table "apple_data_sources" */
export enum apple_data_sources_select_column {
  /** column name */
  account_number = 'account_number',
  /** column name */
  public_id = 'public_id',
  /** column name */
  vendor_number = 'vendor_number'
}

/**
 * Batch of churn swaps/repurchases applied for a vendor. There can only be a single non-applied batch per vendor.
 *
 *
 * columns and relationships of "asset_churn_batches"
 */
export type asset_churn_batches = {
  readonly __typename?: 'asset_churn_batches';
  readonly created_at: Scalars['timestamptz'];
  readonly public_id: Scalars['String'];
};

/** Boolean expression to filter rows from the table "asset_churn_batches". All fields are combined with a logical 'AND'. */
export type asset_churn_batches_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<asset_churn_batches_bool_exp>>;
  readonly _not?: InputMaybe<asset_churn_batches_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<asset_churn_batches_bool_exp>>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
};

/** Ordering options when selecting data from "asset_churn_batches". */
export type asset_churn_batches_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** select columns of table "asset_churn_batches" */
export enum asset_churn_batches_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  public_id = 'public_id'
}

export enum asset_rating_enum_enum {
  A = 'A',
  AA = 'AA',
  AAA = 'AAA',
  B = 'B',
  BB = 'BB',
  BBB = 'BBB',
  C = 'C',
  CC = 'CC',
  CCC = 'CCC',
  D = 'D',
  U = 'U'
}

/** Boolean expression to compare columns of type "asset_rating_enum_enum". All fields are combined with logical 'AND'. */
export type asset_rating_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<asset_rating_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<asset_rating_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<asset_rating_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<asset_rating_enum_enum>>;
};

export enum asset_status_enum_enum {
  /** at least one payment was missed and no more are expected */
  churned = 'churned',
  /** all payments were successfully made and no more are expected */
  completed = 'completed',
  /** at least one expected payment was missed, Pipe is actively investigating the matter */
  investigating = 'investigating',
  /** the payments are being received without issue */
  servicing = 'servicing'
}

/** Boolean expression to compare columns of type "asset_status_enum_enum". All fields are combined with logical 'AND'. */
export type asset_status_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<asset_status_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<asset_status_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<asset_status_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<asset_status_enum_enum>>;
};

/** columns and relationships of "asset_swaps" */
export type asset_swaps = {
  readonly __typename?: 'asset_swaps';
  /** An object relationship */
  readonly asset_churn_batch?: Maybe<asset_churn_batches>;
  readonly churn_detected_at: Scalars['timestamptz'];
  readonly churned_at: Scalars['timestamptz'];
  /** An object relationship */
  readonly churned_investor_asset_receipt: investor_asset_receipts;
  /** An object relationship */
  readonly churned_revenue_stream: revenue_streams;
  /** An object relationship */
  readonly churned_vendor_asset: pipe_agreements;
  readonly created_at: Scalars['timestamptz'];
  /** An object relationship */
  readonly investor: investors;
  readonly public_id: Scalars['String'];
  readonly swapped_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  readonly swapped_investor_asset_receipt?: Maybe<investor_asset_receipts>;
  readonly swapped_months: Scalars['Int'];
  readonly swapped_purchase_amount: Scalars['bigint'];
  readonly swapped_receivable_amount: Scalars['bigint'];
  /** An object relationship */
  readonly swapped_revenue_stream: revenue_streams;
  /** An object relationship */
  readonly swapped_vendor_asset?: Maybe<pipe_agreements>;
  /** An object relationship */
  readonly vendor: vendors;
};

/** Boolean expression to filter rows from the table "asset_swaps". All fields are combined with a logical 'AND'. */
export type asset_swaps_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<asset_swaps_bool_exp>>;
  readonly _not?: InputMaybe<asset_swaps_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<asset_swaps_bool_exp>>;
  readonly asset_churn_batch?: InputMaybe<asset_churn_batches_bool_exp>;
  readonly churn_detected_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly churned_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly churned_investor_asset_receipt?: InputMaybe<investor_asset_receipts_bool_exp>;
  readonly churned_revenue_stream?: InputMaybe<revenue_streams_bool_exp>;
  readonly churned_vendor_asset?: InputMaybe<pipe_agreements_bool_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly investor?: InputMaybe<investors_bool_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly swapped_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly swapped_investor_asset_receipt?: InputMaybe<investor_asset_receipts_bool_exp>;
  readonly swapped_months?: InputMaybe<Int_comparison_exp>;
  readonly swapped_purchase_amount?: InputMaybe<bigint_comparison_exp>;
  readonly swapped_receivable_amount?: InputMaybe<bigint_comparison_exp>;
  readonly swapped_revenue_stream?: InputMaybe<revenue_streams_bool_exp>;
  readonly swapped_vendor_asset?: InputMaybe<pipe_agreements_bool_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** Ordering options when selecting data from "asset_swaps". */
export type asset_swaps_order_by = {
  readonly asset_churn_batch?: InputMaybe<asset_churn_batches_order_by>;
  readonly churn_detected_at?: InputMaybe<order_by>;
  readonly churned_at?: InputMaybe<order_by>;
  readonly churned_investor_asset_receipt?: InputMaybe<investor_asset_receipts_order_by>;
  readonly churned_revenue_stream?: InputMaybe<revenue_streams_order_by>;
  readonly churned_vendor_asset?: InputMaybe<pipe_agreements_order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly investor?: InputMaybe<investors_order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly swapped_at?: InputMaybe<order_by>;
  readonly swapped_investor_asset_receipt?: InputMaybe<investor_asset_receipts_order_by>;
  readonly swapped_months?: InputMaybe<order_by>;
  readonly swapped_purchase_amount?: InputMaybe<order_by>;
  readonly swapped_receivable_amount?: InputMaybe<order_by>;
  readonly swapped_revenue_stream?: InputMaybe<revenue_streams_order_by>;
  readonly swapped_vendor_asset?: InputMaybe<pipe_agreements_order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "asset_swaps" */
export enum asset_swaps_select_column {
  /** column name */
  churn_detected_at = 'churn_detected_at',
  /** column name */
  churned_at = 'churned_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  public_id = 'public_id',
  /** column name */
  swapped_at = 'swapped_at',
  /** column name */
  swapped_months = 'swapped_months',
  /** column name */
  swapped_purchase_amount = 'swapped_purchase_amount',
  /** column name */
  swapped_receivable_amount = 'swapped_receivable_amount'
}

export enum background_job_name_enum_enum {
  alert_mentioned_superusers = 'alert_mentioned_superusers',
  bigquery_sync = 'bigquery_sync',
  /** precompute churned trades */
  churned_trades = 'churned_trades',
  csc_daily_report = 'csc_daily_report',
  csc_validate_ledger = 'csc_validate_ledger',
  delete_codat_company = 'delete_codat_company',
  delete_plaid_item = 'delete_plaid_item',
  delete_sync_account = 'delete_sync_account',
  download_heron_transactions = 'download_heron_transactions',
  /** Download data for a sync account */
  download_sync_account_data = 'download_sync_account_data',
  execute_manual_ops = 'execute_manual_ops',
  fetch_exchange_rates = 'fetch_exchange_rates',
  /** fills a sell order by matching it to an investor */
  fill_sell_order = 'fill_sell_order',
  generate_treasury_yield_payments = 'generate_treasury_yield_payments',
  heron_transactions = 'heron_transactions',
  import_revenue_streams_from_csv = 'import_revenue_streams_from_csv',
  /** sends a vendor or investor-related payment */
  initiate_payment = 'initiate_payment',
  live_rate_vendor = 'live_rate_vendor',
  /** Process an incoming payments webhook */
  payments_webhook = 'payments_webhook',
  predict_excluded_transactions = 'predict_excluded_transactions',
  process_churned_assets = 'process_churned_assets',
  process_uploaded_vendor_data = 'process_uploaded_vendor_data',
  prune_background_jobs = 'prune_background_jobs',
  scrape_clearbit_enrichments = 'scrape_clearbit_enrichments',
  sync_codat_company = 'sync_codat_company',
  sync_industry = 'sync_industry',
  sync_plaid_item = 'sync_plaid_item',
  trade_revenue_streams = 'trade_revenue_streams',
  update_customers = 'update_customers',
  /** Calculate and update underwriting metrics for the specified vendors */
  update_vendor_metrics = 'update_vendor_metrics',
  upload_heron_transactions = 'upload_heron_transactions',
  webhook_update_sync_account_data = 'webhook_update_sync_account_data'
}

/** Boolean expression to compare columns of type "background_job_name_enum_enum". All fields are combined with logical 'AND'. */
export type background_job_name_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<background_job_name_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<background_job_name_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<background_job_name_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<background_job_name_enum_enum>>;
};

export enum background_job_status_enum_enum {
  aborted = 'aborted',
  failed = 'failed',
  pending = 'pending',
  running = 'running',
  succeeded = 'succeeded'
}

/** Boolean expression to compare columns of type "background_job_status_enum_enum". All fields are combined with logical 'AND'. */
export type background_job_status_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<background_job_status_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<background_job_status_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<background_job_status_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<background_job_status_enum_enum>>;
};

/** columns and relationships of "background_jobs" */
export type background_jobs = {
  readonly __typename?: 'background_jobs';
  readonly arguments: Scalars['jsonb'];
  readonly completed_at?: Maybe<Scalars['timestamptz']>;
  readonly created_at: Scalars['timestamptz'];
  readonly error?: Maybe<Scalars['jsonb']>;
  readonly name: background_job_name_enum_enum;
  readonly num_restarts?: Maybe<Scalars['smallint']>;
  readonly public_id: Scalars['String'];
  readonly shard_key: Scalars['String'];
  readonly state: Scalars['jsonb'];
  readonly status?: Maybe<background_job_status_enum_enum>;
  readonly updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "background_jobs" */
export type background_jobsargumentsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "background_jobs" */
export type background_jobserrorArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "background_jobs" */
export type background_jobsstateArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "background_jobs" */
export type background_jobs_aggregate = {
  readonly __typename?: 'background_jobs_aggregate';
  readonly aggregate?: Maybe<background_jobs_aggregate_fields>;
  readonly nodes: ReadonlyArray<background_jobs>;
};

/** aggregate fields of "background_jobs" */
export type background_jobs_aggregate_fields = {
  readonly __typename?: 'background_jobs_aggregate_fields';
  readonly avg?: Maybe<background_jobs_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<background_jobs_max_fields>;
  readonly min?: Maybe<background_jobs_min_fields>;
  readonly stddev?: Maybe<background_jobs_stddev_fields>;
  readonly stddev_pop?: Maybe<background_jobs_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<background_jobs_stddev_samp_fields>;
  readonly sum?: Maybe<background_jobs_sum_fields>;
  readonly var_pop?: Maybe<background_jobs_var_pop_fields>;
  readonly var_samp?: Maybe<background_jobs_var_samp_fields>;
  readonly variance?: Maybe<background_jobs_variance_fields>;
};


/** aggregate fields of "background_jobs" */
export type background_jobs_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<background_jobs_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type background_jobs_avg_fields = {
  readonly __typename?: 'background_jobs_avg_fields';
  readonly num_restarts?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "background_jobs". All fields are combined with a logical 'AND'. */
export type background_jobs_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<background_jobs_bool_exp>>;
  readonly _not?: InputMaybe<background_jobs_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<background_jobs_bool_exp>>;
  readonly arguments?: InputMaybe<jsonb_comparison_exp>;
  readonly completed_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly error?: InputMaybe<jsonb_comparison_exp>;
  readonly name?: InputMaybe<background_job_name_enum_enum_comparison_exp>;
  readonly num_restarts?: InputMaybe<smallint_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly shard_key?: InputMaybe<String_comparison_exp>;
  readonly state?: InputMaybe<jsonb_comparison_exp>;
  readonly status?: InputMaybe<background_job_status_enum_enum_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** aggregate max on columns */
export type background_jobs_max_fields = {
  readonly __typename?: 'background_jobs_max_fields';
  readonly completed_at?: Maybe<Scalars['timestamptz']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly num_restarts?: Maybe<Scalars['smallint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly shard_key?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type background_jobs_min_fields = {
  readonly __typename?: 'background_jobs_min_fields';
  readonly completed_at?: Maybe<Scalars['timestamptz']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly num_restarts?: Maybe<Scalars['smallint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly shard_key?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "background_jobs". */
export type background_jobs_order_by = {
  readonly arguments?: InputMaybe<order_by>;
  readonly completed_at?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly error?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly num_restarts?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly shard_key?: InputMaybe<order_by>;
  readonly state?: InputMaybe<order_by>;
  readonly status?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** select columns of table "background_jobs" */
export enum background_jobs_select_column {
  /** column name */
  arguments = 'arguments',
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  error = 'error',
  /** column name */
  name = 'name',
  /** column name */
  num_restarts = 'num_restarts',
  /** column name */
  public_id = 'public_id',
  /** column name */
  shard_key = 'shard_key',
  /** column name */
  state = 'state',
  /** column name */
  status = 'status',
  /** column name */
  updated_at = 'updated_at'
}

/** columns and relationships of "background_jobs_settings" */
export type background_jobs_settings = {
  readonly __typename?: 'background_jobs_settings';
  readonly created_at: Scalars['timestamptz'];
  readonly is_enabled: Scalars['Boolean'];
  readonly name: background_job_name_enum_enum;
  readonly num_workers: Scalars['Int'];
  readonly poll_interval: Scalars['float8'];
  readonly public_id: Scalars['String'];
  readonly updated_at: Scalars['timestamptz'];
};

/** Boolean expression to filter rows from the table "background_jobs_settings". All fields are combined with a logical 'AND'. */
export type background_jobs_settings_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<background_jobs_settings_bool_exp>>;
  readonly _not?: InputMaybe<background_jobs_settings_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<background_jobs_settings_bool_exp>>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly is_enabled?: InputMaybe<Boolean_comparison_exp>;
  readonly name?: InputMaybe<background_job_name_enum_enum_comparison_exp>;
  readonly num_workers?: InputMaybe<Int_comparison_exp>;
  readonly poll_interval?: InputMaybe<float8_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** input type for incrementing numeric columns in table "background_jobs_settings" */
export type background_jobs_settings_inc_input = {
  readonly num_workers?: InputMaybe<Scalars['Int']>;
  readonly poll_interval?: InputMaybe<Scalars['float8']>;
};

/** response of any mutation on the table "background_jobs_settings" */
export type background_jobs_settings_mutation_response = {
  readonly __typename?: 'background_jobs_settings_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<background_jobs_settings>;
};

/** Ordering options when selecting data from "background_jobs_settings". */
export type background_jobs_settings_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly is_enabled?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly num_workers?: InputMaybe<order_by>;
  readonly poll_interval?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** select columns of table "background_jobs_settings" */
export enum background_jobs_settings_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  is_enabled = 'is_enabled',
  /** column name */
  name = 'name',
  /** column name */
  num_workers = 'num_workers',
  /** column name */
  poll_interval = 'poll_interval',
  /** column name */
  public_id = 'public_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "background_jobs_settings" */
export type background_jobs_settings_set_input = {
  readonly is_enabled?: InputMaybe<Scalars['Boolean']>;
  readonly num_workers?: InputMaybe<Scalars['Int']>;
  readonly poll_interval?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type background_jobs_stddev_fields = {
  readonly __typename?: 'background_jobs_stddev_fields';
  readonly num_restarts?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type background_jobs_stddev_pop_fields = {
  readonly __typename?: 'background_jobs_stddev_pop_fields';
  readonly num_restarts?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type background_jobs_stddev_samp_fields = {
  readonly __typename?: 'background_jobs_stddev_samp_fields';
  readonly num_restarts?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type background_jobs_sum_fields = {
  readonly __typename?: 'background_jobs_sum_fields';
  readonly num_restarts?: Maybe<Scalars['smallint']>;
};

/** aggregate var_pop on columns */
export type background_jobs_var_pop_fields = {
  readonly __typename?: 'background_jobs_var_pop_fields';
  readonly num_restarts?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type background_jobs_var_samp_fields = {
  readonly __typename?: 'background_jobs_var_samp_fields';
  readonly num_restarts?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type background_jobs_variance_fields = {
  readonly __typename?: 'background_jobs_variance_fields';
  readonly num_restarts?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "balance_sheet_reports" */
export type balance_sheet_reports = {
  readonly __typename?: 'balance_sheet_reports';
  readonly currency: Scalars['String'];
  readonly net_assets: Scalars['bigint'];
  readonly public_id: Scalars['String'];
  readonly raw: Scalars['jsonb'];
  readonly report_date: Scalars['date'];
  readonly total_assets: Scalars['bigint'];
  readonly total_equity: Scalars['bigint'];
  readonly total_liabilities: Scalars['bigint'];
};


/** columns and relationships of "balance_sheet_reports" */
export type balance_sheet_reportsrawArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "balance_sheet_reports" */
export type balance_sheet_reports_aggregate_order_by = {
  readonly avg?: InputMaybe<balance_sheet_reports_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<balance_sheet_reports_max_order_by>;
  readonly min?: InputMaybe<balance_sheet_reports_min_order_by>;
  readonly stddev?: InputMaybe<balance_sheet_reports_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<balance_sheet_reports_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<balance_sheet_reports_stddev_samp_order_by>;
  readonly sum?: InputMaybe<balance_sheet_reports_sum_order_by>;
  readonly var_pop?: InputMaybe<balance_sheet_reports_var_pop_order_by>;
  readonly var_samp?: InputMaybe<balance_sheet_reports_var_samp_order_by>;
  readonly variance?: InputMaybe<balance_sheet_reports_variance_order_by>;
};

/** order by avg() on columns of table "balance_sheet_reports" */
export type balance_sheet_reports_avg_order_by = {
  readonly net_assets?: InputMaybe<order_by>;
  readonly total_assets?: InputMaybe<order_by>;
  readonly total_equity?: InputMaybe<order_by>;
  readonly total_liabilities?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "balance_sheet_reports". All fields are combined with a logical 'AND'. */
export type balance_sheet_reports_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<balance_sheet_reports_bool_exp>>;
  readonly _not?: InputMaybe<balance_sheet_reports_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<balance_sheet_reports_bool_exp>>;
  readonly currency?: InputMaybe<String_comparison_exp>;
  readonly net_assets?: InputMaybe<bigint_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly raw?: InputMaybe<jsonb_comparison_exp>;
  readonly report_date?: InputMaybe<date_comparison_exp>;
  readonly total_assets?: InputMaybe<bigint_comparison_exp>;
  readonly total_equity?: InputMaybe<bigint_comparison_exp>;
  readonly total_liabilities?: InputMaybe<bigint_comparison_exp>;
};

/** order by max() on columns of table "balance_sheet_reports" */
export type balance_sheet_reports_max_order_by = {
  readonly currency?: InputMaybe<order_by>;
  readonly net_assets?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly report_date?: InputMaybe<order_by>;
  readonly total_assets?: InputMaybe<order_by>;
  readonly total_equity?: InputMaybe<order_by>;
  readonly total_liabilities?: InputMaybe<order_by>;
};

/** order by min() on columns of table "balance_sheet_reports" */
export type balance_sheet_reports_min_order_by = {
  readonly currency?: InputMaybe<order_by>;
  readonly net_assets?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly report_date?: InputMaybe<order_by>;
  readonly total_assets?: InputMaybe<order_by>;
  readonly total_equity?: InputMaybe<order_by>;
  readonly total_liabilities?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "balance_sheet_reports". */
export type balance_sheet_reports_order_by = {
  readonly currency?: InputMaybe<order_by>;
  readonly net_assets?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly raw?: InputMaybe<order_by>;
  readonly report_date?: InputMaybe<order_by>;
  readonly total_assets?: InputMaybe<order_by>;
  readonly total_equity?: InputMaybe<order_by>;
  readonly total_liabilities?: InputMaybe<order_by>;
};

/** select columns of table "balance_sheet_reports" */
export enum balance_sheet_reports_select_column {
  /** column name */
  currency = 'currency',
  /** column name */
  net_assets = 'net_assets',
  /** column name */
  public_id = 'public_id',
  /** column name */
  raw = 'raw',
  /** column name */
  report_date = 'report_date',
  /** column name */
  total_assets = 'total_assets',
  /** column name */
  total_equity = 'total_equity',
  /** column name */
  total_liabilities = 'total_liabilities'
}

/** order by stddev() on columns of table "balance_sheet_reports" */
export type balance_sheet_reports_stddev_order_by = {
  readonly net_assets?: InputMaybe<order_by>;
  readonly total_assets?: InputMaybe<order_by>;
  readonly total_equity?: InputMaybe<order_by>;
  readonly total_liabilities?: InputMaybe<order_by>;
};

/** order by stddev_pop() on columns of table "balance_sheet_reports" */
export type balance_sheet_reports_stddev_pop_order_by = {
  readonly net_assets?: InputMaybe<order_by>;
  readonly total_assets?: InputMaybe<order_by>;
  readonly total_equity?: InputMaybe<order_by>;
  readonly total_liabilities?: InputMaybe<order_by>;
};

/** order by stddev_samp() on columns of table "balance_sheet_reports" */
export type balance_sheet_reports_stddev_samp_order_by = {
  readonly net_assets?: InputMaybe<order_by>;
  readonly total_assets?: InputMaybe<order_by>;
  readonly total_equity?: InputMaybe<order_by>;
  readonly total_liabilities?: InputMaybe<order_by>;
};

/** order by sum() on columns of table "balance_sheet_reports" */
export type balance_sheet_reports_sum_order_by = {
  readonly net_assets?: InputMaybe<order_by>;
  readonly total_assets?: InputMaybe<order_by>;
  readonly total_equity?: InputMaybe<order_by>;
  readonly total_liabilities?: InputMaybe<order_by>;
};

/** order by var_pop() on columns of table "balance_sheet_reports" */
export type balance_sheet_reports_var_pop_order_by = {
  readonly net_assets?: InputMaybe<order_by>;
  readonly total_assets?: InputMaybe<order_by>;
  readonly total_equity?: InputMaybe<order_by>;
  readonly total_liabilities?: InputMaybe<order_by>;
};

/** order by var_samp() on columns of table "balance_sheet_reports" */
export type balance_sheet_reports_var_samp_order_by = {
  readonly net_assets?: InputMaybe<order_by>;
  readonly total_assets?: InputMaybe<order_by>;
  readonly total_equity?: InputMaybe<order_by>;
  readonly total_liabilities?: InputMaybe<order_by>;
};

/** order by variance() on columns of table "balance_sheet_reports" */
export type balance_sheet_reports_variance_order_by = {
  readonly net_assets?: InputMaybe<order_by>;
  readonly total_assets?: InputMaybe<order_by>;
  readonly total_equity?: InputMaybe<order_by>;
  readonly total_liabilities?: InputMaybe<order_by>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type bigint_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['bigint']>;
  readonly _gt?: InputMaybe<Scalars['bigint']>;
  readonly _gte?: InputMaybe<Scalars['bigint']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['bigint']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['bigint']>;
  readonly _lte?: InputMaybe<Scalars['bigint']>;
  readonly _neq?: InputMaybe<Scalars['bigint']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['bigint']>>;
};

export type calculate_accounting_metrics_args = {
  readonly end_date?: InputMaybe<Scalars['date']>;
  readonly vendor_public_id?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "chargebee_data_sources" */
export type chargebee_data_sources = {
  readonly __typename?: 'chargebee_data_sources';
  readonly public_id: Scalars['String'];
  readonly subdomain: Scalars['String'];
};

/** Boolean expression to filter rows from the table "chargebee_data_sources". All fields are combined with a logical 'AND'. */
export type chargebee_data_sources_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<chargebee_data_sources_bool_exp>>;
  readonly _not?: InputMaybe<chargebee_data_sources_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<chargebee_data_sources_bool_exp>>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly subdomain?: InputMaybe<String_comparison_exp>;
};

/** Ordering options when selecting data from "chargebee_data_sources". */
export type chargebee_data_sources_order_by = {
  readonly public_id?: InputMaybe<order_by>;
  readonly subdomain?: InputMaybe<order_by>;
};

/** select columns of table "chargebee_data_sources" */
export enum chargebee_data_sources_select_column {
  /** column name */
  public_id = 'public_id',
  /** column name */
  subdomain = 'subdomain'
}

/** columns and relationships of "chargify_data_sources" */
export type chargify_data_sources = {
  readonly __typename?: 'chargify_data_sources';
  readonly public_id: Scalars['String'];
  readonly subdomain: Scalars['String'];
};

/** Boolean expression to filter rows from the table "chargify_data_sources". All fields are combined with a logical 'AND'. */
export type chargify_data_sources_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<chargify_data_sources_bool_exp>>;
  readonly _not?: InputMaybe<chargify_data_sources_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<chargify_data_sources_bool_exp>>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly subdomain?: InputMaybe<String_comparison_exp>;
};

/** Ordering options when selecting data from "chargify_data_sources". */
export type chargify_data_sources_order_by = {
  readonly public_id?: InputMaybe<order_by>;
  readonly subdomain?: InputMaybe<order_by>;
};

/** select columns of table "chargify_data_sources" */
export enum chargify_data_sources_select_column {
  /** column name */
  public_id = 'public_id',
  /** column name */
  subdomain = 'subdomain'
}

/** Boolean expression to compare columns of type "cidr". All fields are combined with logical 'AND'. */
export type cidr_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['cidr']>;
  readonly _gt?: InputMaybe<Scalars['cidr']>;
  readonly _gte?: InputMaybe<Scalars['cidr']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['cidr']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['cidr']>;
  readonly _lte?: InputMaybe<Scalars['cidr']>;
  readonly _neq?: InputMaybe<Scalars['cidr']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['cidr']>>;
};

/** columns and relationships of "clearbit_companies" */
export type clearbit_companies = {
  readonly __typename?: 'clearbit_companies';
  readonly alexa_global_rank?: Maybe<Scalars['Int']>;
  readonly alexa_us_rank?: Maybe<Scalars['Int']>;
  readonly amount_raised?: Maybe<Scalars['bigint']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly country?: Maybe<country_code_enum_enum>;
  /** An object relationship */
  readonly customers?: Maybe<customers>;
  readonly estimated_annual_revenue?: Maybe<Scalars['String']>;
  readonly estimated_employees?: Maybe<Scalars['Int']>;
  readonly facebook_likes?: Maybe<Scalars['bigint']>;
  readonly industry?: Maybe<Scalars['String']>;
  readonly logo?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly twitter_followers?: Maybe<Scalars['Int']>;
  readonly type?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "clearbit_companies". All fields are combined with a logical 'AND'. */
export type clearbit_companies_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<clearbit_companies_bool_exp>>;
  readonly _not?: InputMaybe<clearbit_companies_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<clearbit_companies_bool_exp>>;
  readonly alexa_global_rank?: InputMaybe<Int_comparison_exp>;
  readonly alexa_us_rank?: InputMaybe<Int_comparison_exp>;
  readonly amount_raised?: InputMaybe<bigint_comparison_exp>;
  readonly city?: InputMaybe<String_comparison_exp>;
  readonly country?: InputMaybe<country_code_enum_enum_comparison_exp>;
  readonly customers?: InputMaybe<customers_bool_exp>;
  readonly estimated_annual_revenue?: InputMaybe<String_comparison_exp>;
  readonly estimated_employees?: InputMaybe<Int_comparison_exp>;
  readonly facebook_likes?: InputMaybe<bigint_comparison_exp>;
  readonly industry?: InputMaybe<String_comparison_exp>;
  readonly logo?: InputMaybe<String_comparison_exp>;
  readonly state?: InputMaybe<String_comparison_exp>;
  readonly twitter_followers?: InputMaybe<Int_comparison_exp>;
  readonly type?: InputMaybe<String_comparison_exp>;
};

/** Ordering options when selecting data from "clearbit_companies". */
export type clearbit_companies_order_by = {
  readonly alexa_global_rank?: InputMaybe<order_by>;
  readonly alexa_us_rank?: InputMaybe<order_by>;
  readonly amount_raised?: InputMaybe<order_by>;
  readonly city?: InputMaybe<order_by>;
  readonly country?: InputMaybe<order_by>;
  readonly customers?: InputMaybe<customers_order_by>;
  readonly estimated_annual_revenue?: InputMaybe<order_by>;
  readonly estimated_employees?: InputMaybe<order_by>;
  readonly facebook_likes?: InputMaybe<order_by>;
  readonly industry?: InputMaybe<order_by>;
  readonly logo?: InputMaybe<order_by>;
  readonly state?: InputMaybe<order_by>;
  readonly twitter_followers?: InputMaybe<order_by>;
  readonly type?: InputMaybe<order_by>;
};

/** select columns of table "clearbit_companies" */
export enum clearbit_companies_select_column {
  /** column name */
  alexa_global_rank = 'alexa_global_rank',
  /** column name */
  alexa_us_rank = 'alexa_us_rank',
  /** column name */
  amount_raised = 'amount_raised',
  /** column name */
  city = 'city',
  /** column name */
  country = 'country',
  /** column name */
  estimated_annual_revenue = 'estimated_annual_revenue',
  /** column name */
  estimated_employees = 'estimated_employees',
  /** column name */
  facebook_likes = 'facebook_likes',
  /** column name */
  industry = 'industry',
  /** column name */
  logo = 'logo',
  /** column name */
  state = 'state',
  /** column name */
  twitter_followers = 'twitter_followers',
  /** column name */
  type = 'type'
}

/** columns and relationships of "codat_companies" */
export type codat_companies = {
  readonly __typename?: 'codat_companies';
  readonly codat_id: Scalars['String'];
  readonly link_url: Scalars['String'];
  readonly platform_key: codat_platform_key_enum_enum;
  readonly public_id: Scalars['String'];
};

/** Boolean expression to filter rows from the table "codat_companies". All fields are combined with a logical 'AND'. */
export type codat_companies_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<codat_companies_bool_exp>>;
  readonly _not?: InputMaybe<codat_companies_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<codat_companies_bool_exp>>;
  readonly codat_id?: InputMaybe<String_comparison_exp>;
  readonly link_url?: InputMaybe<String_comparison_exp>;
  readonly platform_key?: InputMaybe<codat_platform_key_enum_enum_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
};

/** Ordering options when selecting data from "codat_companies". */
export type codat_companies_order_by = {
  readonly codat_id?: InputMaybe<order_by>;
  readonly link_url?: InputMaybe<order_by>;
  readonly platform_key?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** select columns of table "codat_companies" */
export enum codat_companies_select_column {
  /** column name */
  codat_id = 'codat_id',
  /** column name */
  link_url = 'link_url',
  /** column name */
  platform_key = 'platform_key',
  /** column name */
  public_id = 'public_id'
}

export enum codat_platform_key_enum_enum {
  /** Oracle NetSuite */
  akxx = 'akxx',
  /** Xero */
  gbol = 'gbol',
  /** Wave */
  pbtz = 'pbtz',
  /** QuickBooks Online */
  qhyg = 'qhyg',
  /** Zoho Books */
  rwuv = 'rwuv',
  /** Sage Business Cloud Accounting */
  tgff = 'tgff',
  /** FreshBooks */
  vxvy = 'vxvy',
  /** KashFlow */
  wvzu = 'wvzu'
}

/** Boolean expression to compare columns of type "codat_platform_key_enum_enum". All fields are combined with logical 'AND'. */
export type codat_platform_key_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<codat_platform_key_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<codat_platform_key_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<codat_platform_key_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<codat_platform_key_enum_enum>>;
};

export enum country_code_enum_enum {
  /** Andorra */
  AD = 'AD',
  /** United Arab Emirates */
  AE = 'AE',
  /** Afghanistan */
  AF = 'AF',
  /** Antigua and Barbuda */
  AG = 'AG',
  /** Anguilla */
  AI = 'AI',
  /** Albania */
  AL = 'AL',
  /** Armenia */
  AM = 'AM',
  /** Angola */
  AO = 'AO',
  /** Antarctica */
  AQ = 'AQ',
  /** Argentina */
  AR = 'AR',
  /** American Samoa */
  AS = 'AS',
  /** Austria */
  AT = 'AT',
  /** Australia */
  AU = 'AU',
  /** Aruba */
  AW = 'AW',
  /** Åland Islands */
  AX = 'AX',
  /** Azerbaijan */
  AZ = 'AZ',
  /** Bosnia and Herzegovina */
  BA = 'BA',
  /** Barbados */
  BB = 'BB',
  /** Bangladesh */
  BD = 'BD',
  /** Belgium */
  BE = 'BE',
  /** Burkina Faso */
  BF = 'BF',
  /** Bulgaria */
  BG = 'BG',
  /** Bahrain */
  BH = 'BH',
  /** Burundi */
  BI = 'BI',
  /** Benin */
  BJ = 'BJ',
  /** Saint Barthélemy */
  BL = 'BL',
  /** Bermuda */
  BM = 'BM',
  /** Brunei */
  BN = 'BN',
  /** Bolivia */
  BO = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  BQ = 'BQ',
  /** Brazil */
  BR = 'BR',
  /** Bahamas */
  BS = 'BS',
  /** Bhutan */
  BT = 'BT',
  /** Bouvet Island */
  BV = 'BV',
  /** Botswana */
  BW = 'BW',
  /** Belarus */
  BY = 'BY',
  /** Belize */
  BZ = 'BZ',
  /** Canada */
  CA = 'CA',
  /** Cocos (Keeling) Islands */
  CC = 'CC',
  /** Democratic Republic of the Congo */
  CD = 'CD',
  /** Central African Republic */
  CF = 'CF',
  /** Republic of the Congo */
  CG = 'CG',
  /** Switzerland */
  CH = 'CH',
  /** Côte d'Ivoire */
  CI = 'CI',
  /** Cook Islands */
  CK = 'CK',
  /** Chile */
  CL = 'CL',
  /** Cameroon */
  CM = 'CM',
  /** China */
  CN = 'CN',
  /** Colombia */
  CO = 'CO',
  /** Costa Rica */
  CR = 'CR',
  /** Cuba */
  CU = 'CU',
  /** Cape Verde */
  CV = 'CV',
  /** Curaçao */
  CW = 'CW',
  /** Christmas Island */
  CX = 'CX',
  /** Cyprus */
  CY = 'CY',
  /** Czech Republic */
  CZ = 'CZ',
  /** Germany */
  DE = 'DE',
  /** Djibouti */
  DJ = 'DJ',
  /** Denmark */
  DK = 'DK',
  /** Dominica */
  DM = 'DM',
  /** Dominican Republic */
  DO = 'DO',
  /** Algeria */
  DZ = 'DZ',
  /** Ecuador */
  EC = 'EC',
  /** Estonia */
  EE = 'EE',
  /** Egypt */
  EG = 'EG',
  /** Western Sahara */
  EH = 'EH',
  /** Eritrea */
  ER = 'ER',
  /** Spain */
  ES = 'ES',
  /** Ethiopia */
  ET = 'ET',
  /** Finland */
  FI = 'FI',
  /** Fiji */
  FJ = 'FJ',
  /** Falkland Islands */
  FK = 'FK',
  /** Federated States of Micronesia */
  FM = 'FM',
  /** Faroe Islands */
  FO = 'FO',
  /** France */
  FR = 'FR',
  /** Gabon */
  GA = 'GA',
  /** United Kingdom */
  GB = 'GB',
  /** Grenada */
  GD = 'GD',
  /** Georgia */
  GE = 'GE',
  /** French Guiana */
  GF = 'GF',
  /** Guernsey */
  GG = 'GG',
  /** Ghana */
  GH = 'GH',
  /** Gibraltar */
  GI = 'GI',
  /** Greenland */
  GL = 'GL',
  /** Gambia */
  GM = 'GM',
  /** Guinea */
  GN = 'GN',
  /** Guadeloupe */
  GP = 'GP',
  /** Equatorial Guinea */
  GQ = 'GQ',
  /** Greece */
  GR = 'GR',
  /** South Georgia and the South Sandwich Islands */
  GS = 'GS',
  /** Guatemala */
  GT = 'GT',
  /** Guam */
  GU = 'GU',
  /** Guinea-Bissau */
  GW = 'GW',
  /** Guyana */
  GY = 'GY',
  /** Hong Kong */
  HK = 'HK',
  /** Heard Island and McDonald Islands */
  HM = 'HM',
  /** Honduras */
  HN = 'HN',
  /** Croatia */
  HR = 'HR',
  /** Haiti */
  HT = 'HT',
  /** Hungary */
  HU = 'HU',
  /** Indonesia */
  ID = 'ID',
  /** Ireland */
  IE = 'IE',
  /** Israel */
  IL = 'IL',
  /** Isle of Man */
  IM = 'IM',
  /** India */
  IN = 'IN',
  /** British Indian Ocean Territory */
  IO = 'IO',
  /** Iraq */
  IQ = 'IQ',
  /** Iran */
  IR = 'IR',
  /** Iceland */
  IS = 'IS',
  /** Italy */
  IT = 'IT',
  /** Jersey */
  JE = 'JE',
  /** Jamaica */
  JM = 'JM',
  /** Jordan */
  JO = 'JO',
  /** Japan */
  JP = 'JP',
  /** Kenya */
  KE = 'KE',
  /** Kyrgyzstan */
  KG = 'KG',
  /** Cambodia */
  KH = 'KH',
  /** Kiribati */
  KI = 'KI',
  /** Comoros */
  KM = 'KM',
  /** Saint Kitts and Nevis */
  KN = 'KN',
  /** North Korea */
  KP = 'KP',
  /** South Korea */
  KR = 'KR',
  /** Kuwait */
  KW = 'KW',
  /** Cayman Islands */
  KY = 'KY',
  /** Kazakhstan */
  KZ = 'KZ',
  /** Laos */
  LA = 'LA',
  /** Lebanon */
  LB = 'LB',
  /** Saint Lucia */
  LC = 'LC',
  /** Liechtenstein */
  LI = 'LI',
  /** Sri Lanka */
  LK = 'LK',
  /** Liberia */
  LR = 'LR',
  /** Lesotho */
  LS = 'LS',
  /** Lithuania */
  LT = 'LT',
  /** Luxembourg */
  LU = 'LU',
  /** Latvia */
  LV = 'LV',
  /** Libya */
  LY = 'LY',
  /** Morocco */
  MA = 'MA',
  /** Monaco */
  MC = 'MC',
  /** Republic of Moldova */
  MD = 'MD',
  /** Montenegro */
  ME = 'ME',
  /** Saint Martin */
  MF = 'MF',
  /** Madagascar */
  MG = 'MG',
  /** Marshall Islands */
  MH = 'MH',
  /** North Macedonia */
  MK = 'MK',
  /** Mali */
  ML = 'ML',
  /** Myanmar */
  MM = 'MM',
  /** Mongolia */
  MN = 'MN',
  /** Macao */
  MO = 'MO',
  /** Northern Mariana Islands */
  MP = 'MP',
  /** Martinique */
  MQ = 'MQ',
  /** Mauritania */
  MR = 'MR',
  /** Montserrat */
  MS = 'MS',
  /** Malta */
  MT = 'MT',
  /** Mauritius */
  MU = 'MU',
  /** Maldives */
  MV = 'MV',
  /** Malawi */
  MW = 'MW',
  /** Mexico */
  MX = 'MX',
  /** Malaysia */
  MY = 'MY',
  /** Mozambique */
  MZ = 'MZ',
  /** Namibia */
  NA = 'NA',
  /** New Caledonia */
  NC = 'NC',
  /** Niger */
  NE = 'NE',
  /** Norfolk Island */
  NF = 'NF',
  /** Nigeria */
  NG = 'NG',
  /** Nicaragua */
  NI = 'NI',
  /** Netherlands */
  NL = 'NL',
  /** Norway */
  NO = 'NO',
  /** Nepal */
  NP = 'NP',
  /** Nauru */
  NR = 'NR',
  /** Niue */
  NU = 'NU',
  /** New Zealand */
  NZ = 'NZ',
  /** Oman */
  OM = 'OM',
  /** Panama */
  PA = 'PA',
  /** Peru */
  PE = 'PE',
  /** French Polynesia */
  PF = 'PF',
  /** Papua New Guinea */
  PG = 'PG',
  /** Philippines */
  PH = 'PH',
  /** Pakistan */
  PK = 'PK',
  /** Poland */
  PL = 'PL',
  /** Saint Pierre and Miquelon */
  PM = 'PM',
  /** Pitcairn */
  PN = 'PN',
  /** Puerto Rico */
  PR = 'PR',
  /** Palestine */
  PS = 'PS',
  /** Portugal */
  PT = 'PT',
  /** Palau */
  PW = 'PW',
  /** Paraguay */
  PY = 'PY',
  /** Qatar */
  QA = 'QA',
  /** Réunion */
  RE = 'RE',
  /** Romania */
  RO = 'RO',
  /** Serbia */
  RS = 'RS',
  /** Russian Federation */
  RU = 'RU',
  /** Rwanda */
  RW = 'RW',
  /** Saudi Arabia */
  SA = 'SA',
  /** Solomon Islands */
  SB = 'SB',
  /** Seychelles */
  SC = 'SC',
  /** Sudan */
  SD = 'SD',
  /** Sweden */
  SE = 'SE',
  /** Singapore */
  SG = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha */
  SH = 'SH',
  /** Slovenia */
  SI = 'SI',
  /** Svalbard and Jan Mayen */
  SJ = 'SJ',
  /** Slovakia */
  SK = 'SK',
  /** Sierra Leone */
  SL = 'SL',
  /** San Marino */
  SM = 'SM',
  /** Senegal */
  SN = 'SN',
  /** Somalia */
  SO = 'SO',
  /** Suriname */
  SR = 'SR',
  /** South Sudan */
  SS = 'SS',
  /** Sao Tome and Principe */
  ST = 'ST',
  /** El Salvador */
  SV = 'SV',
  /** Sint Maarten */
  SX = 'SX',
  /** Syria */
  SY = 'SY',
  /** Swaziland */
  SZ = 'SZ',
  /** Turks and Caicos Islands */
  TC = 'TC',
  /** Chad */
  TD = 'TD',
  /** French Southern Territories */
  TF = 'TF',
  /** Togo */
  TG = 'TG',
  /** Thailand */
  TH = 'TH',
  /** Tajikistan */
  TJ = 'TJ',
  /** Tokelau */
  TK = 'TK',
  /** Timor-Leste */
  TL = 'TL',
  /** Turkmenistan */
  TM = 'TM',
  /** Tunisia */
  TN = 'TN',
  /** Tonga */
  TO = 'TO',
  /** Turkey */
  TR = 'TR',
  /** Trinidad and Tobago */
  TT = 'TT',
  /** Tuvalu */
  TV = 'TV',
  /** Taiwan */
  TW = 'TW',
  /** Tanzania */
  TZ = 'TZ',
  /** Ukraine */
  UA = 'UA',
  /** Uganda */
  UG = 'UG',
  /** United States Minor Outlying Islands */
  UM = 'UM',
  /** United States */
  US = 'US',
  /** Uruguay */
  UY = 'UY',
  /** Uzbekistan */
  UZ = 'UZ',
  /** Holy See */
  VA = 'VA',
  /** Saint Vincent and the Grenadines */
  VC = 'VC',
  /** Venezuela */
  VE = 'VE',
  /** British Virgin Islands */
  VG = 'VG',
  /** United States Virgin Islands */
  VI = 'VI',
  /** Vietnam */
  VN = 'VN',
  /** Vanuatu */
  VU = 'VU',
  /** Wallis and Futuna */
  WF = 'WF',
  /** Samoa */
  WS = 'WS',
  /** Yemen */
  YE = 'YE',
  /** Mayotte */
  YT = 'YT',
  /** South Africa */
  ZA = 'ZA',
  /** Zambia */
  ZM = 'ZM',
  /** Zimbabwe */
  ZW = 'ZW'
}

/** Boolean expression to compare columns of type "country_code_enum_enum". All fields are combined with logical 'AND'. */
export type country_code_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<country_code_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<country_code_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<country_code_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<country_code_enum_enum>>;
};

/** columns and relationships of "csv_tasks_for_vendors" */
export type csv_tasks_for_vendors = {
  readonly __typename?: 'csv_tasks_for_vendors';
  readonly expired_count?: Maybe<Scalars['bigint']>;
  readonly pending_count?: Maybe<Scalars['bigint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly vendor?: Maybe<vendors>;
};

/** Boolean expression to filter rows from the table "csv_tasks_for_vendors". All fields are combined with a logical 'AND'. */
export type csv_tasks_for_vendors_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<csv_tasks_for_vendors_bool_exp>>;
  readonly _not?: InputMaybe<csv_tasks_for_vendors_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<csv_tasks_for_vendors_bool_exp>>;
  readonly expired_count?: InputMaybe<bigint_comparison_exp>;
  readonly pending_count?: InputMaybe<bigint_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** Ordering options when selecting data from "csv_tasks_for_vendors". */
export type csv_tasks_for_vendors_order_by = {
  readonly expired_count?: InputMaybe<order_by>;
  readonly pending_count?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "csv_tasks_for_vendors" */
export enum csv_tasks_for_vendors_select_column {
  /** column name */
  expired_count = 'expired_count',
  /** column name */
  pending_count = 'pending_count',
  /** column name */
  public_id = 'public_id'
}

/** Boolean expression to compare columns of type "currency". All fields are combined with logical 'AND'. */
export type currency_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['currency']>;
  readonly _gt?: InputMaybe<Scalars['currency']>;
  readonly _gte?: InputMaybe<Scalars['currency']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['currency']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['currency']>;
  readonly _lte?: InputMaybe<Scalars['currency']>;
  readonly _neq?: InputMaybe<Scalars['currency']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['currency']>>;
};

/** columns and relationships of "customers" */
export type customers = {
  readonly __typename?: 'customers';
  /** An object relationship */
  readonly clearbit_companies?: Maybe<clearbit_companies>;
  readonly created_at: Scalars['timestamp'];
  readonly description_text: Scalars['String'];
  readonly domain: Scalars['String'];
  readonly email: Scalars['String'];
  readonly full_name: Scalars['String'];
  readonly phone: Scalars['String'];
  /** An array relationship */
  readonly pipe_agreements: ReadonlyArray<pipe_agreements>;
  /** An aggregate relationship */
  readonly pipe_agreements_aggregate: pipe_agreements_aggregate;
  readonly public_id: Scalars['String'];
  /** An array relationship */
  readonly revenue_streams: ReadonlyArray<revenue_streams>;
  /** An aggregate relationship */
  readonly revenue_streams_aggregate: revenue_streams_aggregate;
  /** An object relationship */
  readonly vendor: vendors;
};


/** columns and relationships of "customers" */
export type customerspipe_agreementsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_agreements_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_agreements_order_by>>;
  where?: InputMaybe<pipe_agreements_bool_exp>;
};


/** columns and relationships of "customers" */
export type customerspipe_agreements_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_agreements_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_agreements_order_by>>;
  where?: InputMaybe<pipe_agreements_bool_exp>;
};


/** columns and relationships of "customers" */
export type customersrevenue_streamsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<revenue_streams_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<revenue_streams_order_by>>;
  where?: InputMaybe<revenue_streams_bool_exp>;
};


/** columns and relationships of "customers" */
export type customersrevenue_streams_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<revenue_streams_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<revenue_streams_order_by>>;
  where?: InputMaybe<revenue_streams_bool_exp>;
};

/** aggregated selection of "customers" */
export type customers_aggregate = {
  readonly __typename?: 'customers_aggregate';
  readonly aggregate?: Maybe<customers_aggregate_fields>;
  readonly nodes: ReadonlyArray<customers>;
};

/** aggregate fields of "customers" */
export type customers_aggregate_fields = {
  readonly __typename?: 'customers_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<customers_max_fields>;
  readonly min?: Maybe<customers_min_fields>;
};


/** aggregate fields of "customers" */
export type customers_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<customers_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customers" */
export type customers_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<customers_max_order_by>;
  readonly min?: InputMaybe<customers_min_order_by>;
};

/** Boolean expression to filter rows from the table "customers". All fields are combined with a logical 'AND'. */
export type customers_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<customers_bool_exp>>;
  readonly _not?: InputMaybe<customers_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<customers_bool_exp>>;
  readonly clearbit_companies?: InputMaybe<clearbit_companies_bool_exp>;
  readonly created_at?: InputMaybe<timestamp_comparison_exp>;
  readonly description_text?: InputMaybe<String_comparison_exp>;
  readonly domain?: InputMaybe<String_comparison_exp>;
  readonly email?: InputMaybe<String_comparison_exp>;
  readonly full_name?: InputMaybe<String_comparison_exp>;
  readonly phone?: InputMaybe<String_comparison_exp>;
  readonly pipe_agreements?: InputMaybe<pipe_agreements_bool_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly revenue_streams?: InputMaybe<revenue_streams_bool_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** aggregate max on columns */
export type customers_max_fields = {
  readonly __typename?: 'customers_max_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description_text?: Maybe<Scalars['String']>;
  readonly domain?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly full_name?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "customers" */
export type customers_max_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly description_text?: InputMaybe<order_by>;
  readonly domain?: InputMaybe<order_by>;
  readonly email?: InputMaybe<order_by>;
  readonly full_name?: InputMaybe<order_by>;
  readonly phone?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type customers_min_fields = {
  readonly __typename?: 'customers_min_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description_text?: Maybe<Scalars['String']>;
  readonly domain?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly full_name?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "customers" */
export type customers_min_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly description_text?: InputMaybe<order_by>;
  readonly domain?: InputMaybe<order_by>;
  readonly email?: InputMaybe<order_by>;
  readonly full_name?: InputMaybe<order_by>;
  readonly phone?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "customers". */
export type customers_order_by = {
  readonly clearbit_companies?: InputMaybe<clearbit_companies_order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly description_text?: InputMaybe<order_by>;
  readonly domain?: InputMaybe<order_by>;
  readonly email?: InputMaybe<order_by>;
  readonly full_name?: InputMaybe<order_by>;
  readonly phone?: InputMaybe<order_by>;
  readonly pipe_agreements_aggregate?: InputMaybe<pipe_agreements_aggregate_order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly revenue_streams_aggregate?: InputMaybe<revenue_streams_aggregate_order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "customers" */
export enum customers_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  description_text = 'description_text',
  /** column name */
  domain = 'domain',
  /** column name */
  email = 'email',
  /** column name */
  full_name = 'full_name',
  /** column name */
  phone = 'phone',
  /** column name */
  public_id = 'public_id'
}

export enum data_source_provider_enum_enum {
  apple = 'apple',
  chargebee = 'chargebee',
  chargify = 'chargify',
  codat = 'codat',
  gocardless = 'gocardless',
  manual = 'manual',
  paypal = 'paypal',
  plaid = 'plaid',
  recurly = 'recurly',
  shopify = 'shopify',
  stripe = 'stripe'
}

/** Boolean expression to compare columns of type "data_source_provider_enum_enum". All fields are combined with logical 'AND'. */
export type data_source_provider_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<data_source_provider_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<data_source_provider_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<data_source_provider_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<data_source_provider_enum_enum>>;
};

/** columns and relationships of "data_sources" */
export type data_sources = {
  readonly __typename?: 'data_sources';
  /** An object relationship */
  readonly apple_data_source?: Maybe<apple_data_sources>;
  /** An array relationship */
  readonly balance_sheet_reports: ReadonlyArray<balance_sheet_reports>;
  /** An object relationship */
  readonly chargebee_data_source?: Maybe<chargebee_data_sources>;
  /** An object relationship */
  readonly chargify_data_source?: Maybe<chargify_data_sources>;
  /** An object relationship */
  readonly codat_company?: Maybe<codat_companies>;
  readonly country_code?: Maybe<country_code_enum_enum>;
  readonly created_at: Scalars['timestamptz'];
  readonly display_name: Scalars['String'];
  readonly display_subheading?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly gocardless_data_source?: Maybe<gocardless_data_sources>;
  readonly is_accounting: Scalars['Boolean'];
  readonly is_bank: Scalars['Boolean'];
  readonly is_revenue: Scalars['Boolean'];
  readonly last_sync_completed_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  readonly manual_data_source?: Maybe<manual_data_sources>;
  /** An object relationship */
  readonly plaid_item?: Maybe<plaid_items>;
  /** An array relationship */
  readonly profit_and_loss_reports: ReadonlyArray<profit_and_loss_reports>;
  readonly provider: data_source_provider_enum_enum;
  readonly public_id: Scalars['String'];
  /** An object relationship */
  readonly recurly_data_source?: Maybe<recurly_data_sources>;
  /** An object relationship */
  readonly stripe_data_source?: Maybe<stripe_data_sources>;
  readonly user_action_required_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "data_sources" */
export type data_sourcesbalance_sheet_reportsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<balance_sheet_reports_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<balance_sheet_reports_order_by>>;
  where?: InputMaybe<balance_sheet_reports_bool_exp>;
};


/** columns and relationships of "data_sources" */
export type data_sourcesprofit_and_loss_reportsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<profit_and_loss_reports_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<profit_and_loss_reports_order_by>>;
  where?: InputMaybe<profit_and_loss_reports_bool_exp>;
};

/** order by aggregate values of table "data_sources" */
export type data_sources_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<data_sources_max_order_by>;
  readonly min?: InputMaybe<data_sources_min_order_by>;
};

/** Boolean expression to filter rows from the table "data_sources". All fields are combined with a logical 'AND'. */
export type data_sources_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<data_sources_bool_exp>>;
  readonly _not?: InputMaybe<data_sources_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<data_sources_bool_exp>>;
  readonly apple_data_source?: InputMaybe<apple_data_sources_bool_exp>;
  readonly balance_sheet_reports?: InputMaybe<balance_sheet_reports_bool_exp>;
  readonly chargebee_data_source?: InputMaybe<chargebee_data_sources_bool_exp>;
  readonly chargify_data_source?: InputMaybe<chargify_data_sources_bool_exp>;
  readonly codat_company?: InputMaybe<codat_companies_bool_exp>;
  readonly country_code?: InputMaybe<country_code_enum_enum_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly display_name?: InputMaybe<String_comparison_exp>;
  readonly display_subheading?: InputMaybe<String_comparison_exp>;
  readonly gocardless_data_source?: InputMaybe<gocardless_data_sources_bool_exp>;
  readonly is_accounting?: InputMaybe<Boolean_comparison_exp>;
  readonly is_bank?: InputMaybe<Boolean_comparison_exp>;
  readonly is_revenue?: InputMaybe<Boolean_comparison_exp>;
  readonly last_sync_completed_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly manual_data_source?: InputMaybe<manual_data_sources_bool_exp>;
  readonly plaid_item?: InputMaybe<plaid_items_bool_exp>;
  readonly profit_and_loss_reports?: InputMaybe<profit_and_loss_reports_bool_exp>;
  readonly provider?: InputMaybe<data_source_provider_enum_enum_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly recurly_data_source?: InputMaybe<recurly_data_sources_bool_exp>;
  readonly stripe_data_source?: InputMaybe<stripe_data_sources_bool_exp>;
  readonly user_action_required_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** order by max() on columns of table "data_sources" */
export type data_sources_max_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly display_name?: InputMaybe<order_by>;
  readonly display_subheading?: InputMaybe<order_by>;
  readonly last_sync_completed_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly user_action_required_at?: InputMaybe<order_by>;
};

/** order by min() on columns of table "data_sources" */
export type data_sources_min_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly display_name?: InputMaybe<order_by>;
  readonly display_subheading?: InputMaybe<order_by>;
  readonly last_sync_completed_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly user_action_required_at?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "data_sources". */
export type data_sources_order_by = {
  readonly apple_data_source?: InputMaybe<apple_data_sources_order_by>;
  readonly balance_sheet_reports_aggregate?: InputMaybe<balance_sheet_reports_aggregate_order_by>;
  readonly chargebee_data_source?: InputMaybe<chargebee_data_sources_order_by>;
  readonly chargify_data_source?: InputMaybe<chargify_data_sources_order_by>;
  readonly codat_company?: InputMaybe<codat_companies_order_by>;
  readonly country_code?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly display_name?: InputMaybe<order_by>;
  readonly display_subheading?: InputMaybe<order_by>;
  readonly gocardless_data_source?: InputMaybe<gocardless_data_sources_order_by>;
  readonly is_accounting?: InputMaybe<order_by>;
  readonly is_bank?: InputMaybe<order_by>;
  readonly is_revenue?: InputMaybe<order_by>;
  readonly last_sync_completed_at?: InputMaybe<order_by>;
  readonly manual_data_source?: InputMaybe<manual_data_sources_order_by>;
  readonly plaid_item?: InputMaybe<plaid_items_order_by>;
  readonly profit_and_loss_reports_aggregate?: InputMaybe<profit_and_loss_reports_aggregate_order_by>;
  readonly provider?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly recurly_data_source?: InputMaybe<recurly_data_sources_order_by>;
  readonly stripe_data_source?: InputMaybe<stripe_data_sources_order_by>;
  readonly user_action_required_at?: InputMaybe<order_by>;
};

/** select columns of table "data_sources" */
export enum data_sources_select_column {
  /** column name */
  country_code = 'country_code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  display_name = 'display_name',
  /** column name */
  display_subheading = 'display_subheading',
  /** column name */
  is_accounting = 'is_accounting',
  /** column name */
  is_bank = 'is_bank',
  /** column name */
  is_revenue = 'is_revenue',
  /** column name */
  last_sync_completed_at = 'last_sync_completed_at',
  /** column name */
  provider = 'provider',
  /** column name */
  public_id = 'public_id',
  /** column name */
  user_action_required_at = 'user_action_required_at'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type date_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['date']>;
  readonly _gt?: InputMaybe<Scalars['date']>;
  readonly _gte?: InputMaybe<Scalars['date']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['date']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['date']>;
  readonly _lte?: InputMaybe<Scalars['date']>;
  readonly _neq?: InputMaybe<Scalars['date']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['date']>>;
};

/** columns and relationships of "db_admin_indexes" */
export type db_admin_indexes = {
  readonly __typename?: 'db_admin_indexes';
  readonly command?: Maybe<Scalars['String']>;
  readonly defn?: Maybe<Scalars['String']>;
  readonly disk_usage?: Maybe<Scalars['bigint']>;
  readonly n_blocks_done?: Maybe<Scalars['bigint']>;
  readonly n_blocks_total?: Maybe<Scalars['bigint']>;
  readonly n_cols?: Maybe<Scalars['smallint']>;
  readonly n_fetched_tup?: Maybe<Scalars['bigint']>;
  readonly n_read_tup?: Maybe<Scalars['bigint']>;
  readonly n_scans?: Maybe<Scalars['bigint']>;
  readonly name?: Maybe<Scalars['name']>;
  readonly phase?: Maybe<Scalars['String']>;
  readonly relation?: Maybe<Scalars['name']>;
  readonly unique?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "db_admin_indexes". All fields are combined with a logical 'AND'. */
export type db_admin_indexes_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<db_admin_indexes_bool_exp>>;
  readonly _not?: InputMaybe<db_admin_indexes_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<db_admin_indexes_bool_exp>>;
  readonly command?: InputMaybe<String_comparison_exp>;
  readonly defn?: InputMaybe<String_comparison_exp>;
  readonly disk_usage?: InputMaybe<bigint_comparison_exp>;
  readonly n_blocks_done?: InputMaybe<bigint_comparison_exp>;
  readonly n_blocks_total?: InputMaybe<bigint_comparison_exp>;
  readonly n_cols?: InputMaybe<smallint_comparison_exp>;
  readonly n_fetched_tup?: InputMaybe<bigint_comparison_exp>;
  readonly n_read_tup?: InputMaybe<bigint_comparison_exp>;
  readonly n_scans?: InputMaybe<bigint_comparison_exp>;
  readonly name?: InputMaybe<name_comparison_exp>;
  readonly phase?: InputMaybe<String_comparison_exp>;
  readonly relation?: InputMaybe<name_comparison_exp>;
  readonly unique?: InputMaybe<Boolean_comparison_exp>;
};

/** Ordering options when selecting data from "db_admin_indexes". */
export type db_admin_indexes_order_by = {
  readonly command?: InputMaybe<order_by>;
  readonly defn?: InputMaybe<order_by>;
  readonly disk_usage?: InputMaybe<order_by>;
  readonly n_blocks_done?: InputMaybe<order_by>;
  readonly n_blocks_total?: InputMaybe<order_by>;
  readonly n_cols?: InputMaybe<order_by>;
  readonly n_fetched_tup?: InputMaybe<order_by>;
  readonly n_read_tup?: InputMaybe<order_by>;
  readonly n_scans?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly phase?: InputMaybe<order_by>;
  readonly relation?: InputMaybe<order_by>;
  readonly unique?: InputMaybe<order_by>;
};

/** select columns of table "db_admin_indexes" */
export enum db_admin_indexes_select_column {
  /** column name */
  command = 'command',
  /** column name */
  defn = 'defn',
  /** column name */
  disk_usage = 'disk_usage',
  /** column name */
  n_blocks_done = 'n_blocks_done',
  /** column name */
  n_blocks_total = 'n_blocks_total',
  /** column name */
  n_cols = 'n_cols',
  /** column name */
  n_fetched_tup = 'n_fetched_tup',
  /** column name */
  n_read_tup = 'n_read_tup',
  /** column name */
  n_scans = 'n_scans',
  /** column name */
  name = 'name',
  /** column name */
  phase = 'phase',
  /** column name */
  relation = 'relation',
  /** column name */
  unique = 'unique'
}

/** columns and relationships of "db_admin_queries" */
export type db_admin_queries = {
  readonly __typename?: 'db_admin_queries';
  readonly application_name?: Maybe<Scalars['String']>;
  readonly blocking_pids?: Maybe<Scalars['_int4']>;
  readonly blocking_queries?: Maybe<Scalars['_text']>;
  readonly pid?: Maybe<Scalars['Int']>;
  readonly query?: Maybe<Scalars['String']>;
  readonly usename?: Maybe<Scalars['name']>;
};

/** Boolean expression to filter rows from the table "db_admin_queries". All fields are combined with a logical 'AND'. */
export type db_admin_queries_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<db_admin_queries_bool_exp>>;
  readonly _not?: InputMaybe<db_admin_queries_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<db_admin_queries_bool_exp>>;
  readonly application_name?: InputMaybe<String_comparison_exp>;
  readonly blocking_pids?: InputMaybe<_int4_comparison_exp>;
  readonly blocking_queries?: InputMaybe<_text_comparison_exp>;
  readonly pid?: InputMaybe<Int_comparison_exp>;
  readonly query?: InputMaybe<String_comparison_exp>;
  readonly usename?: InputMaybe<name_comparison_exp>;
};

/** Ordering options when selecting data from "db_admin_queries". */
export type db_admin_queries_order_by = {
  readonly application_name?: InputMaybe<order_by>;
  readonly blocking_pids?: InputMaybe<order_by>;
  readonly blocking_queries?: InputMaybe<order_by>;
  readonly pid?: InputMaybe<order_by>;
  readonly query?: InputMaybe<order_by>;
  readonly usename?: InputMaybe<order_by>;
};

/** select columns of table "db_admin_queries" */
export enum db_admin_queries_select_column {
  /** column name */
  application_name = 'application_name',
  /** column name */
  blocking_pids = 'blocking_pids',
  /** column name */
  blocking_queries = 'blocking_queries',
  /** column name */
  pid = 'pid',
  /** column name */
  query = 'query',
  /** column name */
  usename = 'usename'
}

/** columns and relationships of "db_admin_relations" */
export type db_admin_relations = {
  readonly __typename?: 'db_admin_relations';
  readonly bloat_ratio?: Maybe<Scalars['float8']>;
  readonly columns?: Maybe<Scalars['json']>;
  readonly disk_usage?: Maybe<Scalars['bigint']>;
  readonly idx_disk_usage?: Maybe<Scalars['bigint']>;
  readonly idx_scan?: Maybe<Scalars['bigint']>;
  readonly last_autovacuum?: Maybe<Scalars['timestamptz']>;
  readonly n_dead_tup?: Maybe<Scalars['bigint']>;
  readonly n_live_tup?: Maybe<Scalars['bigint']>;
  readonly name?: Maybe<Scalars['name']>;
  readonly seq_scan?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "db_admin_relations" */
export type db_admin_relationscolumnsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "db_admin_relations". All fields are combined with a logical 'AND'. */
export type db_admin_relations_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<db_admin_relations_bool_exp>>;
  readonly _not?: InputMaybe<db_admin_relations_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<db_admin_relations_bool_exp>>;
  readonly bloat_ratio?: InputMaybe<float8_comparison_exp>;
  readonly columns?: InputMaybe<json_comparison_exp>;
  readonly disk_usage?: InputMaybe<bigint_comparison_exp>;
  readonly idx_disk_usage?: InputMaybe<bigint_comparison_exp>;
  readonly idx_scan?: InputMaybe<bigint_comparison_exp>;
  readonly last_autovacuum?: InputMaybe<timestamptz_comparison_exp>;
  readonly n_dead_tup?: InputMaybe<bigint_comparison_exp>;
  readonly n_live_tup?: InputMaybe<bigint_comparison_exp>;
  readonly name?: InputMaybe<name_comparison_exp>;
  readonly seq_scan?: InputMaybe<bigint_comparison_exp>;
};

/** Ordering options when selecting data from "db_admin_relations". */
export type db_admin_relations_order_by = {
  readonly bloat_ratio?: InputMaybe<order_by>;
  readonly columns?: InputMaybe<order_by>;
  readonly disk_usage?: InputMaybe<order_by>;
  readonly idx_disk_usage?: InputMaybe<order_by>;
  readonly idx_scan?: InputMaybe<order_by>;
  readonly last_autovacuum?: InputMaybe<order_by>;
  readonly n_dead_tup?: InputMaybe<order_by>;
  readonly n_live_tup?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly seq_scan?: InputMaybe<order_by>;
};

/** select columns of table "db_admin_relations" */
export enum db_admin_relations_select_column {
  /** column name */
  bloat_ratio = 'bloat_ratio',
  /** column name */
  columns = 'columns',
  /** column name */
  disk_usage = 'disk_usage',
  /** column name */
  idx_disk_usage = 'idx_disk_usage',
  /** column name */
  idx_scan = 'idx_scan',
  /** column name */
  last_autovacuum = 'last_autovacuum',
  /** column name */
  n_dead_tup = 'n_dead_tup',
  /** column name */
  n_live_tup = 'n_live_tup',
  /** column name */
  name = 'name',
  /** column name */
  seq_scan = 'seq_scan'
}

/** columns and relationships of "fingerprints" */
export type fingerprints = {
  readonly __typename?: 'fingerprints';
  readonly created_at: Scalars['timestamptz'];
  readonly ip: Scalars['String'];
  readonly public_id: Scalars['String'];
  readonly source: Scalars['String'];
  readonly updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "fingerprints" */
export type fingerprints_aggregate = {
  readonly __typename?: 'fingerprints_aggregate';
  readonly aggregate?: Maybe<fingerprints_aggregate_fields>;
  readonly nodes: ReadonlyArray<fingerprints>;
};

/** aggregate fields of "fingerprints" */
export type fingerprints_aggregate_fields = {
  readonly __typename?: 'fingerprints_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<fingerprints_max_fields>;
  readonly min?: Maybe<fingerprints_min_fields>;
};


/** aggregate fields of "fingerprints" */
export type fingerprints_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<fingerprints_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fingerprints" */
export type fingerprints_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<fingerprints_max_order_by>;
  readonly min?: InputMaybe<fingerprints_min_order_by>;
};

/** Boolean expression to filter rows from the table "fingerprints". All fields are combined with a logical 'AND'. */
export type fingerprints_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<fingerprints_bool_exp>>;
  readonly _not?: InputMaybe<fingerprints_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<fingerprints_bool_exp>>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly ip?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly source?: InputMaybe<String_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** aggregate max on columns */
export type fingerprints_max_fields = {
  readonly __typename?: 'fingerprints_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly ip?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "fingerprints" */
export type fingerprints_max_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly ip?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly source?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type fingerprints_min_fields = {
  readonly __typename?: 'fingerprints_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly ip?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "fingerprints" */
export type fingerprints_min_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly ip?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly source?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "fingerprints". */
export type fingerprints_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly ip?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly source?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** select columns of table "fingerprints" */
export enum fingerprints_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  ip = 'ip',
  /** column name */
  public_id = 'public_id',
  /** column name */
  source = 'source',
  /** column name */
  updated_at = 'updated_at'
}

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type float8_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['float8']>;
  readonly _gt?: InputMaybe<Scalars['float8']>;
  readonly _gte?: InputMaybe<Scalars['float8']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['float8']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['float8']>;
  readonly _lte?: InputMaybe<Scalars['float8']>;
  readonly _neq?: InputMaybe<Scalars['float8']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['float8']>>;
};

/** columns and relationships of "gocardless_data_sources" */
export type gocardless_data_sources = {
  readonly __typename?: 'gocardless_data_sources';
  readonly organisation_id: Scalars['String'];
  readonly public_id: Scalars['String'];
};

/** Boolean expression to filter rows from the table "gocardless_data_sources". All fields are combined with a logical 'AND'. */
export type gocardless_data_sources_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<gocardless_data_sources_bool_exp>>;
  readonly _not?: InputMaybe<gocardless_data_sources_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<gocardless_data_sources_bool_exp>>;
  readonly organisation_id?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
};

/** Ordering options when selecting data from "gocardless_data_sources". */
export type gocardless_data_sources_order_by = {
  readonly organisation_id?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** select columns of table "gocardless_data_sources" */
export enum gocardless_data_sources_select_column {
  /** column name */
  organisation_id = 'organisation_id',
  /** column name */
  public_id = 'public_id'
}

/**
 * A view of all bank transactions and their associated annotations from Heron Data.
 *
 *
 * columns and relationships of "heron_annotated_transactions"
 */
export type heron_annotated_transactions = {
  readonly __typename?: 'heron_annotated_transactions';
  readonly amount?: Maybe<Scalars['bigint']>;
  readonly currency?: Maybe<Scalars['currency']>;
  readonly date?: Maybe<Scalars['date']>;
  readonly is_pending?: Maybe<Scalars['Boolean']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly merchant_name?: Maybe<Scalars['String']>;
  readonly payment_processor_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly transaction?: Maybe<transactions>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "heron_annotated_transactions". All fields are combined with a logical 'AND'. */
export type heron_annotated_transactions_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<heron_annotated_transactions_bool_exp>>;
  readonly _not?: InputMaybe<heron_annotated_transactions_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<heron_annotated_transactions_bool_exp>>;
  readonly amount?: InputMaybe<bigint_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly date?: InputMaybe<date_comparison_exp>;
  readonly is_pending?: InputMaybe<Boolean_comparison_exp>;
  readonly label?: InputMaybe<String_comparison_exp>;
  readonly merchant_name?: InputMaybe<String_comparison_exp>;
  readonly payment_processor_name?: InputMaybe<String_comparison_exp>;
  readonly transaction?: InputMaybe<transactions_bool_exp>;
  readonly vendor_public_id?: InputMaybe<String_comparison_exp>;
};

/** Ordering options when selecting data from "heron_annotated_transactions". */
export type heron_annotated_transactions_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly date?: InputMaybe<order_by>;
  readonly is_pending?: InputMaybe<order_by>;
  readonly label?: InputMaybe<order_by>;
  readonly merchant_name?: InputMaybe<order_by>;
  readonly payment_processor_name?: InputMaybe<order_by>;
  readonly transaction?: InputMaybe<transactions_order_by>;
  readonly vendor_public_id?: InputMaybe<order_by>;
};

/** select columns of table "heron_annotated_transactions" */
export enum heron_annotated_transactions_select_column {
  /** column name */
  amount = 'amount',
  /** column name */
  currency = 'currency',
  /** column name */
  date = 'date',
  /** column name */
  is_pending = 'is_pending',
  /** column name */
  label = 'label',
  /** column name */
  merchant_name = 'merchant_name',
  /** column name */
  payment_processor_name = 'payment_processor_name',
  /** column name */
  vendor_public_id = 'vendor_public_id'
}

/** columns and relationships of "heron_categories" */
export type heron_categories = {
  readonly __typename?: 'heron_categories';
  readonly heron_id: Scalars['String'];
  readonly label: Scalars['String'];
  readonly public_id: Scalars['String'];
};

/** Boolean expression to filter rows from the table "heron_categories". All fields are combined with a logical 'AND'. */
export type heron_categories_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<heron_categories_bool_exp>>;
  readonly _not?: InputMaybe<heron_categories_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<heron_categories_bool_exp>>;
  readonly heron_id?: InputMaybe<String_comparison_exp>;
  readonly label?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
};

/** Ordering options when selecting data from "heron_categories". */
export type heron_categories_order_by = {
  readonly heron_id?: InputMaybe<order_by>;
  readonly label?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** select columns of table "heron_categories" */
export enum heron_categories_select_column {
  /** column name */
  heron_id = 'heron_id',
  /** column name */
  label = 'label',
  /** column name */
  public_id = 'public_id'
}

/** columns and relationships of "historical_bank_data" */
export type historical_bank_data = {
  readonly __typename?: 'historical_bank_data';
  /** Average monthly burn computed using the last two quarters */
  readonly adjusted_burn?: Maybe<Scalars['bigint']>;
  readonly balance: Scalars['bigint'];
  /** Average monthly burn over the last quarter */
  readonly burn?: Maybe<Scalars['bigint']>;
  readonly currency: Scalars['currency'];
  readonly ledger_timestamp: Scalars['timestamptz'];
  readonly public_id: Scalars['String'];
};

/** order by aggregate values of table "historical_bank_data" */
export type historical_bank_data_aggregate_order_by = {
  readonly avg?: InputMaybe<historical_bank_data_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<historical_bank_data_max_order_by>;
  readonly min?: InputMaybe<historical_bank_data_min_order_by>;
  readonly stddev?: InputMaybe<historical_bank_data_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<historical_bank_data_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<historical_bank_data_stddev_samp_order_by>;
  readonly sum?: InputMaybe<historical_bank_data_sum_order_by>;
  readonly var_pop?: InputMaybe<historical_bank_data_var_pop_order_by>;
  readonly var_samp?: InputMaybe<historical_bank_data_var_samp_order_by>;
  readonly variance?: InputMaybe<historical_bank_data_variance_order_by>;
};

/** order by avg() on columns of table "historical_bank_data" */
export type historical_bank_data_avg_order_by = {
  /** Average monthly burn computed using the last two quarters */
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  /** Average monthly burn over the last quarter */
  readonly burn?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "historical_bank_data". All fields are combined with a logical 'AND'. */
export type historical_bank_data_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<historical_bank_data_bool_exp>>;
  readonly _not?: InputMaybe<historical_bank_data_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<historical_bank_data_bool_exp>>;
  readonly adjusted_burn?: InputMaybe<bigint_comparison_exp>;
  readonly balance?: InputMaybe<bigint_comparison_exp>;
  readonly burn?: InputMaybe<bigint_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly ledger_timestamp?: InputMaybe<timestamptz_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
};

/** order by max() on columns of table "historical_bank_data" */
export type historical_bank_data_max_order_by = {
  /** Average monthly burn computed using the last two quarters */
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  /** Average monthly burn over the last quarter */
  readonly burn?: InputMaybe<order_by>;
  readonly ledger_timestamp?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** order by min() on columns of table "historical_bank_data" */
export type historical_bank_data_min_order_by = {
  /** Average monthly burn computed using the last two quarters */
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  /** Average monthly burn over the last quarter */
  readonly burn?: InputMaybe<order_by>;
  readonly ledger_timestamp?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "historical_bank_data". */
export type historical_bank_data_order_by = {
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  readonly burn?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly ledger_timestamp?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** select columns of table "historical_bank_data" */
export enum historical_bank_data_select_column {
  /** column name */
  adjusted_burn = 'adjusted_burn',
  /** column name */
  balance = 'balance',
  /** column name */
  burn = 'burn',
  /** column name */
  currency = 'currency',
  /** column name */
  ledger_timestamp = 'ledger_timestamp',
  /** column name */
  public_id = 'public_id'
}

/** order by stddev() on columns of table "historical_bank_data" */
export type historical_bank_data_stddev_order_by = {
  /** Average monthly burn computed using the last two quarters */
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  /** Average monthly burn over the last quarter */
  readonly burn?: InputMaybe<order_by>;
};

/** order by stddev_pop() on columns of table "historical_bank_data" */
export type historical_bank_data_stddev_pop_order_by = {
  /** Average monthly burn computed using the last two quarters */
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  /** Average monthly burn over the last quarter */
  readonly burn?: InputMaybe<order_by>;
};

/** order by stddev_samp() on columns of table "historical_bank_data" */
export type historical_bank_data_stddev_samp_order_by = {
  /** Average monthly burn computed using the last two quarters */
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  /** Average monthly burn over the last quarter */
  readonly burn?: InputMaybe<order_by>;
};

/** order by sum() on columns of table "historical_bank_data" */
export type historical_bank_data_sum_order_by = {
  /** Average monthly burn computed using the last two quarters */
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  /** Average monthly burn over the last quarter */
  readonly burn?: InputMaybe<order_by>;
};

/** order by var_pop() on columns of table "historical_bank_data" */
export type historical_bank_data_var_pop_order_by = {
  /** Average monthly burn computed using the last two quarters */
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  /** Average monthly burn over the last quarter */
  readonly burn?: InputMaybe<order_by>;
};

/** order by var_samp() on columns of table "historical_bank_data" */
export type historical_bank_data_var_samp_order_by = {
  /** Average monthly burn computed using the last two quarters */
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  /** Average monthly burn over the last quarter */
  readonly burn?: InputMaybe<order_by>;
};

/** order by variance() on columns of table "historical_bank_data" */
export type historical_bank_data_variance_order_by = {
  /** Average monthly burn computed using the last two quarters */
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  /** Average monthly burn over the last quarter */
  readonly burn?: InputMaybe<order_by>;
};

export enum integration_type_enum_enum {
  accounting = 'accounting',
  bank = 'bank',
  billing_manager = 'billing_manager'
}

/** Boolean expression to compare columns of type "integration_type_enum_enum". All fields are combined with logical 'AND'. */
export type integration_type_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<integration_type_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<integration_type_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<integration_type_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<integration_type_enum_enum>>;
};

/** columns and relationships of "investor_asset_receipts" */
export type investor_asset_receipts = {
  readonly __typename?: 'investor_asset_receipts';
  readonly asset_rating: asset_rating_enum_enum;
  readonly asset_status: asset_status_enum_enum;
  readonly created_at: Scalars['timestamptz'];
  readonly currency: Scalars['currency'];
  readonly ends_at: Scalars['date'];
  readonly exchange_fee_amount: Scalars['bigint'];
  readonly investigating_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  readonly investor: investors;
  /** An array relationship */
  readonly investor_payments: ReadonlyArray<investor_payments>;
  readonly irr_bps?: Maybe<Scalars['Int']>;
  /** An object relationship */
  readonly limit_buy_order?: Maybe<limit_buy_orders>;
  readonly matched_at?: Maybe<Scalars['timestamptz']>;
  readonly metadata: Scalars['jsonb'];
  /** An object relationship */
  readonly pipe_agreement: pipe_agreements;
  readonly public_id: Scalars['String'];
  readonly purchase_amount: Scalars['bigint'];
  readonly receivable_amount: Scalars['bigint'];
  readonly traded_at: Scalars['date'];
  readonly updated_at: Scalars['timestamptz'];
  /** An object relationship */
  readonly vendor: vendors;
};


/** columns and relationships of "investor_asset_receipts" */
export type investor_asset_receiptsinvestor_paymentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<investor_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<investor_payments_order_by>>;
  where?: InputMaybe<investor_payments_bool_exp>;
};


/** columns and relationships of "investor_asset_receipts" */
export type investor_asset_receiptsmetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "investor_asset_receipts" */
export type investor_asset_receipts_aggregate = {
  readonly __typename?: 'investor_asset_receipts_aggregate';
  readonly aggregate?: Maybe<investor_asset_receipts_aggregate_fields>;
  readonly nodes: ReadonlyArray<investor_asset_receipts>;
};

/** aggregate fields of "investor_asset_receipts" */
export type investor_asset_receipts_aggregate_fields = {
  readonly __typename?: 'investor_asset_receipts_aggregate_fields';
  readonly avg?: Maybe<investor_asset_receipts_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<investor_asset_receipts_max_fields>;
  readonly min?: Maybe<investor_asset_receipts_min_fields>;
  readonly stddev?: Maybe<investor_asset_receipts_stddev_fields>;
  readonly stddev_pop?: Maybe<investor_asset_receipts_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<investor_asset_receipts_stddev_samp_fields>;
  readonly sum?: Maybe<investor_asset_receipts_sum_fields>;
  readonly var_pop?: Maybe<investor_asset_receipts_var_pop_fields>;
  readonly var_samp?: Maybe<investor_asset_receipts_var_samp_fields>;
  readonly variance?: Maybe<investor_asset_receipts_variance_fields>;
};


/** aggregate fields of "investor_asset_receipts" */
export type investor_asset_receipts_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<investor_asset_receipts_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "investor_asset_receipts" */
export type investor_asset_receipts_aggregate_order_by = {
  readonly avg?: InputMaybe<investor_asset_receipts_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<investor_asset_receipts_max_order_by>;
  readonly min?: InputMaybe<investor_asset_receipts_min_order_by>;
  readonly stddev?: InputMaybe<investor_asset_receipts_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<investor_asset_receipts_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<investor_asset_receipts_stddev_samp_order_by>;
  readonly sum?: InputMaybe<investor_asset_receipts_sum_order_by>;
  readonly var_pop?: InputMaybe<investor_asset_receipts_var_pop_order_by>;
  readonly var_samp?: InputMaybe<investor_asset_receipts_var_samp_order_by>;
  readonly variance?: InputMaybe<investor_asset_receipts_variance_order_by>;
};

/** aggregate avg on columns */
export type investor_asset_receipts_avg_fields = {
  readonly __typename?: 'investor_asset_receipts_avg_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "investor_asset_receipts" */
export type investor_asset_receipts_avg_order_by = {
  readonly exchange_fee_amount?: InputMaybe<order_by>;
  readonly irr_bps?: InputMaybe<order_by>;
  readonly purchase_amount?: InputMaybe<order_by>;
  readonly receivable_amount?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "investor_asset_receipts". All fields are combined with a logical 'AND'. */
export type investor_asset_receipts_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<investor_asset_receipts_bool_exp>>;
  readonly _not?: InputMaybe<investor_asset_receipts_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<investor_asset_receipts_bool_exp>>;
  readonly asset_rating?: InputMaybe<asset_rating_enum_enum_comparison_exp>;
  readonly asset_status?: InputMaybe<asset_status_enum_enum_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly ends_at?: InputMaybe<date_comparison_exp>;
  readonly exchange_fee_amount?: InputMaybe<bigint_comparison_exp>;
  readonly investigating_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly investor?: InputMaybe<investors_bool_exp>;
  readonly investor_payments?: InputMaybe<investor_payments_bool_exp>;
  readonly irr_bps?: InputMaybe<Int_comparison_exp>;
  readonly limit_buy_order?: InputMaybe<limit_buy_orders_bool_exp>;
  readonly matched_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly metadata?: InputMaybe<jsonb_comparison_exp>;
  readonly pipe_agreement?: InputMaybe<pipe_agreements_bool_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly purchase_amount?: InputMaybe<bigint_comparison_exp>;
  readonly receivable_amount?: InputMaybe<bigint_comparison_exp>;
  readonly traded_at?: InputMaybe<date_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** aggregate max on columns */
export type investor_asset_receipts_max_fields = {
  readonly __typename?: 'investor_asset_receipts_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly ends_at?: Maybe<Scalars['date']>;
  readonly exchange_fee_amount?: Maybe<Scalars['bigint']>;
  readonly investigating_at?: Maybe<Scalars['timestamptz']>;
  readonly irr_bps?: Maybe<Scalars['Int']>;
  readonly matched_at?: Maybe<Scalars['timestamptz']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly purchase_amount?: Maybe<Scalars['bigint']>;
  readonly receivable_amount?: Maybe<Scalars['bigint']>;
  readonly traded_at?: Maybe<Scalars['date']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "investor_asset_receipts" */
export type investor_asset_receipts_max_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly ends_at?: InputMaybe<order_by>;
  readonly exchange_fee_amount?: InputMaybe<order_by>;
  readonly investigating_at?: InputMaybe<order_by>;
  readonly irr_bps?: InputMaybe<order_by>;
  readonly matched_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly purchase_amount?: InputMaybe<order_by>;
  readonly receivable_amount?: InputMaybe<order_by>;
  readonly traded_at?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type investor_asset_receipts_min_fields = {
  readonly __typename?: 'investor_asset_receipts_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly ends_at?: Maybe<Scalars['date']>;
  readonly exchange_fee_amount?: Maybe<Scalars['bigint']>;
  readonly investigating_at?: Maybe<Scalars['timestamptz']>;
  readonly irr_bps?: Maybe<Scalars['Int']>;
  readonly matched_at?: Maybe<Scalars['timestamptz']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly purchase_amount?: Maybe<Scalars['bigint']>;
  readonly receivable_amount?: Maybe<Scalars['bigint']>;
  readonly traded_at?: Maybe<Scalars['date']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "investor_asset_receipts" */
export type investor_asset_receipts_min_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly ends_at?: InputMaybe<order_by>;
  readonly exchange_fee_amount?: InputMaybe<order_by>;
  readonly investigating_at?: InputMaybe<order_by>;
  readonly irr_bps?: InputMaybe<order_by>;
  readonly matched_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly purchase_amount?: InputMaybe<order_by>;
  readonly receivable_amount?: InputMaybe<order_by>;
  readonly traded_at?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "investor_asset_receipts". */
export type investor_asset_receipts_order_by = {
  readonly asset_rating?: InputMaybe<order_by>;
  readonly asset_status?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly ends_at?: InputMaybe<order_by>;
  readonly exchange_fee_amount?: InputMaybe<order_by>;
  readonly investigating_at?: InputMaybe<order_by>;
  readonly investor?: InputMaybe<investors_order_by>;
  readonly investor_payments_aggregate?: InputMaybe<investor_payments_aggregate_order_by>;
  readonly irr_bps?: InputMaybe<order_by>;
  readonly limit_buy_order?: InputMaybe<limit_buy_orders_order_by>;
  readonly matched_at?: InputMaybe<order_by>;
  readonly metadata?: InputMaybe<order_by>;
  readonly pipe_agreement?: InputMaybe<pipe_agreements_order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly purchase_amount?: InputMaybe<order_by>;
  readonly receivable_amount?: InputMaybe<order_by>;
  readonly traded_at?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "investor_asset_receipts" */
export enum investor_asset_receipts_select_column {
  /** column name */
  asset_rating = 'asset_rating',
  /** column name */
  asset_status = 'asset_status',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  ends_at = 'ends_at',
  /** column name */
  exchange_fee_amount = 'exchange_fee_amount',
  /** column name */
  investigating_at = 'investigating_at',
  /** column name */
  irr_bps = 'irr_bps',
  /** column name */
  matched_at = 'matched_at',
  /** column name */
  metadata = 'metadata',
  /** column name */
  public_id = 'public_id',
  /** column name */
  purchase_amount = 'purchase_amount',
  /** column name */
  receivable_amount = 'receivable_amount',
  /** column name */
  traded_at = 'traded_at',
  /** column name */
  updated_at = 'updated_at'
}

/** aggregate stddev on columns */
export type investor_asset_receipts_stddev_fields = {
  readonly __typename?: 'investor_asset_receipts_stddev_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "investor_asset_receipts" */
export type investor_asset_receipts_stddev_order_by = {
  readonly exchange_fee_amount?: InputMaybe<order_by>;
  readonly irr_bps?: InputMaybe<order_by>;
  readonly purchase_amount?: InputMaybe<order_by>;
  readonly receivable_amount?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type investor_asset_receipts_stddev_pop_fields = {
  readonly __typename?: 'investor_asset_receipts_stddev_pop_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "investor_asset_receipts" */
export type investor_asset_receipts_stddev_pop_order_by = {
  readonly exchange_fee_amount?: InputMaybe<order_by>;
  readonly irr_bps?: InputMaybe<order_by>;
  readonly purchase_amount?: InputMaybe<order_by>;
  readonly receivable_amount?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type investor_asset_receipts_stddev_samp_fields = {
  readonly __typename?: 'investor_asset_receipts_stddev_samp_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "investor_asset_receipts" */
export type investor_asset_receipts_stddev_samp_order_by = {
  readonly exchange_fee_amount?: InputMaybe<order_by>;
  readonly irr_bps?: InputMaybe<order_by>;
  readonly purchase_amount?: InputMaybe<order_by>;
  readonly receivable_amount?: InputMaybe<order_by>;
};

/** aggregate sum on columns */
export type investor_asset_receipts_sum_fields = {
  readonly __typename?: 'investor_asset_receipts_sum_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['bigint']>;
  readonly irr_bps?: Maybe<Scalars['Int']>;
  readonly purchase_amount?: Maybe<Scalars['bigint']>;
  readonly receivable_amount?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "investor_asset_receipts" */
export type investor_asset_receipts_sum_order_by = {
  readonly exchange_fee_amount?: InputMaybe<order_by>;
  readonly irr_bps?: InputMaybe<order_by>;
  readonly purchase_amount?: InputMaybe<order_by>;
  readonly receivable_amount?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type investor_asset_receipts_var_pop_fields = {
  readonly __typename?: 'investor_asset_receipts_var_pop_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "investor_asset_receipts" */
export type investor_asset_receipts_var_pop_order_by = {
  readonly exchange_fee_amount?: InputMaybe<order_by>;
  readonly irr_bps?: InputMaybe<order_by>;
  readonly purchase_amount?: InputMaybe<order_by>;
  readonly receivable_amount?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type investor_asset_receipts_var_samp_fields = {
  readonly __typename?: 'investor_asset_receipts_var_samp_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "investor_asset_receipts" */
export type investor_asset_receipts_var_samp_order_by = {
  readonly exchange_fee_amount?: InputMaybe<order_by>;
  readonly irr_bps?: InputMaybe<order_by>;
  readonly purchase_amount?: InputMaybe<order_by>;
  readonly receivable_amount?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type investor_asset_receipts_variance_fields = {
  readonly __typename?: 'investor_asset_receipts_variance_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "investor_asset_receipts" */
export type investor_asset_receipts_variance_order_by = {
  readonly exchange_fee_amount?: InputMaybe<order_by>;
  readonly irr_bps?: InputMaybe<order_by>;
  readonly purchase_amount?: InputMaybe<order_by>;
  readonly receivable_amount?: InputMaybe<order_by>;
};

export enum investor_payment_batch_status_enum_enum {
  /** the disbursal will never be attempted */
  cancelled = 'cancelled',
  /** the disbursal has been successfully transferred to the investor */
  completed = 'completed',
  /** the disbursal has failed to transfer from Pipe to the investor; Pipe is investigating */
  failed = 'failed',
  /** the disbursal of the payment from Pipe to the investor has been initiated but has not yet completed or failed */
  initiated = 'initiated',
  /** the disbursal has not yet been attempted, but will be in the future */
  pending = 'pending'
}

/** Boolean expression to compare columns of type "investor_payment_batch_status_enum_enum". All fields are combined with logical 'AND'. */
export type investor_payment_batch_status_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<investor_payment_batch_status_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<investor_payment_batch_status_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<investor_payment_batch_status_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<investor_payment_batch_status_enum_enum>>;
};

/** columns and relationships of "investor_payment_batches" */
export type investor_payment_batches = {
  readonly __typename?: 'investor_payment_batches';
  readonly account_institution_logo?: Maybe<Scalars['String']>;
  readonly account_institution_name?: Maybe<Scalars['String']>;
  readonly account_mask?: Maybe<Scalars['String']>;
  readonly account_name?: Maybe<Scalars['String']>;
  readonly cleared_at?: Maybe<Scalars['timestamptz']>;
  readonly created_at: Scalars['timestamptz'];
  readonly currency: Scalars['currency'];
  readonly due_at: Scalars['date'];
  readonly failed_at?: Maybe<Scalars['timestamptz']>;
  readonly initiated_at?: Maybe<Scalars['timestamptz']>;
  readonly payment_medium?: Maybe<Scalars['String']>;
  readonly payment_type: investor_payment_type_enum_enum;
  readonly public_id: Scalars['String'];
  readonly status: investor_payment_batch_status_enum_enum;
  readonly updated_at: Scalars['timestamptz'];
};

/** Boolean expression to filter rows from the table "investor_payment_batches". All fields are combined with a logical 'AND'. */
export type investor_payment_batches_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<investor_payment_batches_bool_exp>>;
  readonly _not?: InputMaybe<investor_payment_batches_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<investor_payment_batches_bool_exp>>;
  readonly account_institution_logo?: InputMaybe<String_comparison_exp>;
  readonly account_institution_name?: InputMaybe<String_comparison_exp>;
  readonly account_mask?: InputMaybe<String_comparison_exp>;
  readonly account_name?: InputMaybe<String_comparison_exp>;
  readonly cleared_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly due_at?: InputMaybe<date_comparison_exp>;
  readonly failed_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly initiated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly payment_medium?: InputMaybe<String_comparison_exp>;
  readonly payment_type?: InputMaybe<investor_payment_type_enum_enum_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly status?: InputMaybe<investor_payment_batch_status_enum_enum_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** Ordering options when selecting data from "investor_payment_batches". */
export type investor_payment_batches_order_by = {
  readonly account_institution_logo?: InputMaybe<order_by>;
  readonly account_institution_name?: InputMaybe<order_by>;
  readonly account_mask?: InputMaybe<order_by>;
  readonly account_name?: InputMaybe<order_by>;
  readonly cleared_at?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly due_at?: InputMaybe<order_by>;
  readonly failed_at?: InputMaybe<order_by>;
  readonly initiated_at?: InputMaybe<order_by>;
  readonly payment_medium?: InputMaybe<order_by>;
  readonly payment_type?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly status?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** select columns of table "investor_payment_batches" */
export enum investor_payment_batches_select_column {
  /** column name */
  account_institution_logo = 'account_institution_logo',
  /** column name */
  account_institution_name = 'account_institution_name',
  /** column name */
  account_mask = 'account_mask',
  /** column name */
  account_name = 'account_name',
  /** column name */
  cleared_at = 'cleared_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  due_at = 'due_at',
  /** column name */
  failed_at = 'failed_at',
  /** column name */
  initiated_at = 'initiated_at',
  /** column name */
  payment_medium = 'payment_medium',
  /** column name */
  payment_type = 'payment_type',
  /** column name */
  public_id = 'public_id',
  /** column name */
  status = 'status',
  /** column name */
  updated_at = 'updated_at'
}

export enum investor_payment_type_enum_enum {
  /** money to be paid from the investor to Pipe to purchase an asset */
  payment = 'payment',
  /** money to be paid from Pipe to the investor to service an asset */
  payout = 'payout'
}

/** Boolean expression to compare columns of type "investor_payment_type_enum_enum". All fields are combined with logical 'AND'. */
export type investor_payment_type_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<investor_payment_type_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<investor_payment_type_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<investor_payment_type_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<investor_payment_type_enum_enum>>;
};

/** columns and relationships of "investor_payments" */
export type investor_payments = {
  readonly __typename?: 'investor_payments';
  readonly amount: Scalars['bigint'];
  readonly created_at: Scalars['timestamptz'];
  readonly currency: Scalars['currency'];
  readonly description: Scalars['String'];
  readonly due_at: Scalars['date'];
  readonly metadata: Scalars['jsonb'];
  readonly payment_type: investor_payment_type_enum_enum;
  readonly public_id: Scalars['String'];
  readonly updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "investor_payments" */
export type investor_paymentsmetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "investor_payments" */
export type investor_payments_aggregate_order_by = {
  readonly avg?: InputMaybe<investor_payments_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<investor_payments_max_order_by>;
  readonly min?: InputMaybe<investor_payments_min_order_by>;
  readonly stddev?: InputMaybe<investor_payments_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<investor_payments_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<investor_payments_stddev_samp_order_by>;
  readonly sum?: InputMaybe<investor_payments_sum_order_by>;
  readonly var_pop?: InputMaybe<investor_payments_var_pop_order_by>;
  readonly var_samp?: InputMaybe<investor_payments_var_samp_order_by>;
  readonly variance?: InputMaybe<investor_payments_variance_order_by>;
};

/** order by avg() on columns of table "investor_payments" */
export type investor_payments_avg_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "investor_payments". All fields are combined with a logical 'AND'. */
export type investor_payments_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<investor_payments_bool_exp>>;
  readonly _not?: InputMaybe<investor_payments_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<investor_payments_bool_exp>>;
  readonly amount?: InputMaybe<bigint_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly description?: InputMaybe<String_comparison_exp>;
  readonly due_at?: InputMaybe<date_comparison_exp>;
  readonly metadata?: InputMaybe<jsonb_comparison_exp>;
  readonly payment_type?: InputMaybe<investor_payment_type_enum_enum_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** order by max() on columns of table "investor_payments" */
export type investor_payments_max_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly description?: InputMaybe<order_by>;
  readonly due_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** order by min() on columns of table "investor_payments" */
export type investor_payments_min_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly description?: InputMaybe<order_by>;
  readonly due_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "investor_payments". */
export type investor_payments_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly description?: InputMaybe<order_by>;
  readonly due_at?: InputMaybe<order_by>;
  readonly metadata?: InputMaybe<order_by>;
  readonly payment_type?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** select columns of table "investor_payments" */
export enum investor_payments_select_column {
  /** column name */
  amount = 'amount',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  description = 'description',
  /** column name */
  due_at = 'due_at',
  /** column name */
  metadata = 'metadata',
  /** column name */
  payment_type = 'payment_type',
  /** column name */
  public_id = 'public_id',
  /** column name */
  updated_at = 'updated_at'
}

/** order by stddev() on columns of table "investor_payments" */
export type investor_payments_stddev_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by stddev_pop() on columns of table "investor_payments" */
export type investor_payments_stddev_pop_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by stddev_samp() on columns of table "investor_payments" */
export type investor_payments_stddev_samp_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by sum() on columns of table "investor_payments" */
export type investor_payments_sum_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by var_pop() on columns of table "investor_payments" */
export type investor_payments_var_pop_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by var_samp() on columns of table "investor_payments" */
export type investor_payments_var_samp_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by variance() on columns of table "investor_payments" */
export type investor_payments_variance_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** columns and relationships of "investor_portfolio_settings" */
export type investor_portfolio_settings = {
  readonly __typename?: 'investor_portfolio_settings';
  readonly created_at: Scalars['timestamptz'];
  readonly currency: Scalars['currency'];
  readonly public_id: Scalars['String'];
  readonly updated_at: Scalars['timestamptz'];
  readonly vendor_concentration_max_amount?: Maybe<Scalars['bigint']>;
};

/** Boolean expression to filter rows from the table "investor_portfolio_settings". All fields are combined with a logical 'AND'. */
export type investor_portfolio_settings_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<investor_portfolio_settings_bool_exp>>;
  readonly _not?: InputMaybe<investor_portfolio_settings_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<investor_portfolio_settings_bool_exp>>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly vendor_concentration_max_amount?: InputMaybe<bigint_comparison_exp>;
};

/** Ordering options when selecting data from "investor_portfolio_settings". */
export type investor_portfolio_settings_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly vendor_concentration_max_amount?: InputMaybe<order_by>;
};

/** select columns of table "investor_portfolio_settings" */
export enum investor_portfolio_settings_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  public_id = 'public_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  vendor_concentration_max_amount = 'vendor_concentration_max_amount'
}

/** columns and relationships of "investors" */
export type investors = {
  readonly __typename?: 'investors';
  readonly client_id: Scalars['String'];
  readonly created_at: Scalars['timestamptz'];
  readonly is_dst: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly public_id: Scalars['String'];
  readonly subi_id?: Maybe<Scalars['String']>;
  readonly updated_at: Scalars['timestamptz'];
};

/** Boolean expression to filter rows from the table "investors". All fields are combined with a logical 'AND'. */
export type investors_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<investors_bool_exp>>;
  readonly _not?: InputMaybe<investors_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<investors_bool_exp>>;
  readonly client_id?: InputMaybe<String_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly is_dst?: InputMaybe<Boolean_comparison_exp>;
  readonly name?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly subi_id?: InputMaybe<String_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** Ordering options when selecting data from "investors". */
export type investors_order_by = {
  readonly client_id?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly is_dst?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly subi_id?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** select columns of table "investors" */
export enum investors_select_column {
  /** column name */
  client_id = 'client_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  is_dst = 'is_dst',
  /** column name */
  name = 'name',
  /** column name */
  public_id = 'public_id',
  /** column name */
  subi_id = 'subi_id',
  /** column name */
  updated_at = 'updated_at'
}

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type json_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['json']>;
  readonly _gt?: InputMaybe<Scalars['json']>;
  readonly _gte?: InputMaybe<Scalars['json']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['json']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['json']>;
  readonly _lte?: InputMaybe<Scalars['json']>;
  readonly _neq?: InputMaybe<Scalars['json']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['json']>>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type jsonb_comparison_exp = {
  /** is the column contained in the given json value */
  readonly _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  readonly _contains?: InputMaybe<Scalars['jsonb']>;
  readonly _eq?: InputMaybe<Scalars['jsonb']>;
  readonly _gt?: InputMaybe<Scalars['jsonb']>;
  readonly _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  readonly _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  readonly _has_keys_all?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  readonly _has_keys_any?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['jsonb']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['jsonb']>;
  readonly _lte?: InputMaybe<Scalars['jsonb']>;
  readonly _neq?: InputMaybe<Scalars['jsonb']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['jsonb']>>;
};

/** columns and relationships of "legal_entities" */
export type legal_entities = {
  readonly __typename?: 'legal_entities';
  readonly address1: Scalars['String'];
  readonly address2?: Maybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly country: country_code_enum_enum;
  readonly created_at: Scalars['timestamptz'];
  readonly entity_type: legal_entity_type_enum_enum;
  readonly middesk_business_status?: Maybe<middesk_business_status_enum_enum>;
  readonly name: Scalars['String'];
  readonly postal_code: Scalars['String'];
  readonly public_id: Scalars['String'];
  readonly state?: Maybe<Scalars['String']>;
  readonly state_of_incorporation?: Maybe<Scalars['String']>;
  /** Tax identification number */
  readonly tin?: Maybe<Scalars['String']>;
  readonly updated_at: Scalars['timestamptz'];
  /** An object relationship */
  readonly vendor: vendors;
};

/** aggregated selection of "legal_entities" */
export type legal_entities_aggregate = {
  readonly __typename?: 'legal_entities_aggregate';
  readonly aggregate?: Maybe<legal_entities_aggregate_fields>;
  readonly nodes: ReadonlyArray<legal_entities>;
};

/** aggregate fields of "legal_entities" */
export type legal_entities_aggregate_fields = {
  readonly __typename?: 'legal_entities_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<legal_entities_max_fields>;
  readonly min?: Maybe<legal_entities_min_fields>;
};


/** aggregate fields of "legal_entities" */
export type legal_entities_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<legal_entities_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "legal_entities" */
export type legal_entities_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<legal_entities_max_order_by>;
  readonly min?: InputMaybe<legal_entities_min_order_by>;
};

/** Boolean expression to filter rows from the table "legal_entities". All fields are combined with a logical 'AND'. */
export type legal_entities_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<legal_entities_bool_exp>>;
  readonly _not?: InputMaybe<legal_entities_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<legal_entities_bool_exp>>;
  readonly address1?: InputMaybe<String_comparison_exp>;
  readonly address2?: InputMaybe<String_comparison_exp>;
  readonly city?: InputMaybe<String_comparison_exp>;
  readonly country?: InputMaybe<country_code_enum_enum_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly entity_type?: InputMaybe<legal_entity_type_enum_enum_comparison_exp>;
  readonly middesk_business_status?: InputMaybe<middesk_business_status_enum_enum_comparison_exp>;
  readonly name?: InputMaybe<String_comparison_exp>;
  readonly postal_code?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly state?: InputMaybe<String_comparison_exp>;
  readonly state_of_incorporation?: InputMaybe<String_comparison_exp>;
  readonly tin?: InputMaybe<String_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** aggregate max on columns */
export type legal_entities_max_fields = {
  readonly __typename?: 'legal_entities_max_fields';
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly postal_code?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly state_of_incorporation?: Maybe<Scalars['String']>;
  /** Tax identification number */
  readonly tin?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "legal_entities" */
export type legal_entities_max_order_by = {
  readonly address1?: InputMaybe<order_by>;
  readonly address2?: InputMaybe<order_by>;
  readonly city?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly postal_code?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly state?: InputMaybe<order_by>;
  readonly state_of_incorporation?: InputMaybe<order_by>;
  /** Tax identification number */
  readonly tin?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type legal_entities_min_fields = {
  readonly __typename?: 'legal_entities_min_fields';
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly postal_code?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly state_of_incorporation?: Maybe<Scalars['String']>;
  /** Tax identification number */
  readonly tin?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "legal_entities" */
export type legal_entities_min_order_by = {
  readonly address1?: InputMaybe<order_by>;
  readonly address2?: InputMaybe<order_by>;
  readonly city?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly postal_code?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly state?: InputMaybe<order_by>;
  readonly state_of_incorporation?: InputMaybe<order_by>;
  /** Tax identification number */
  readonly tin?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "legal_entities". */
export type legal_entities_order_by = {
  readonly address1?: InputMaybe<order_by>;
  readonly address2?: InputMaybe<order_by>;
  readonly city?: InputMaybe<order_by>;
  readonly country?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly entity_type?: InputMaybe<order_by>;
  readonly middesk_business_status?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly postal_code?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly state?: InputMaybe<order_by>;
  readonly state_of_incorporation?: InputMaybe<order_by>;
  readonly tin?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "legal_entities" */
export enum legal_entities_select_column {
  /** column name */
  address1 = 'address1',
  /** column name */
  address2 = 'address2',
  /** column name */
  city = 'city',
  /** column name */
  country = 'country',
  /** column name */
  created_at = 'created_at',
  /** column name */
  entity_type = 'entity_type',
  /** column name */
  middesk_business_status = 'middesk_business_status',
  /** column name */
  name = 'name',
  /** column name */
  postal_code = 'postal_code',
  /** column name */
  public_id = 'public_id',
  /** column name */
  state = 'state',
  /** column name */
  state_of_incorporation = 'state_of_incorporation',
  /** column name */
  tin = 'tin',
  /** column name */
  updated_at = 'updated_at'
}

export enum legal_entity_type_enum_enum {
  c_corp = 'c_corp',
  llc = 'llc',
  partnership = 'partnership',
  s_corp = 's_corp',
  sole_proprietor = 'sole_proprietor'
}

/** Boolean expression to compare columns of type "legal_entity_type_enum_enum". All fields are combined with logical 'AND'. */
export type legal_entity_type_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<legal_entity_type_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<legal_entity_type_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<legal_entity_type_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<legal_entity_type_enum_enum>>;
};

export enum limit_buy_order_status_enum_enum {
  /** this order should be actively considered for matching */
  active = 'active',
  /** this order has been canceled by the investor */
  canceled = 'canceled',
  /** this order has been fully exhausted */
  completed = 'completed',
  /** this order has expired and should not be considered for matching */
  expired = 'expired'
}

/** Boolean expression to compare columns of type "limit_buy_order_status_enum_enum". All fields are combined with logical 'AND'. */
export type limit_buy_order_status_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<limit_buy_order_status_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<limit_buy_order_status_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<limit_buy_order_status_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<limit_buy_order_status_enum_enum>>;
};

/** columns and relationships of "limit_buy_orders" */
export type limit_buy_orders = {
  readonly __typename?: 'limit_buy_orders';
  /** the total value of assets the investor is willing to purchase with this order */
  readonly amount_limit: Scalars['bigint'];
  /** the remaining amount to be used to purchase additional assets. starts at amount_limit and is decremented with each matched asset. */
  readonly amount_unfilled: Scalars['bigint'];
  /** matching parameter: the rating of the asset */
  readonly asset_rating: asset_rating_enum_enum;
  readonly created_at: Scalars['timestamptz'];
  readonly currency: Scalars['currency'];
  readonly expires_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  readonly investor: investors;
  readonly irr_bps: Scalars['Int'];
  /** matching parameter: the maximum term in months of the asset, inclusive */
  readonly max_term_months?: Maybe<Scalars['Int']>;
  /** matching parameter: the minimum term in months of the asset, inclusive */
  readonly min_term_months?: Maybe<Scalars['Int']>;
  readonly public_id: Scalars['String'];
  readonly status: limit_buy_order_status_enum_enum;
  readonly updated_at: Scalars['timestamptz'];
};

/** Boolean expression to filter rows from the table "limit_buy_orders". All fields are combined with a logical 'AND'. */
export type limit_buy_orders_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<limit_buy_orders_bool_exp>>;
  readonly _not?: InputMaybe<limit_buy_orders_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<limit_buy_orders_bool_exp>>;
  readonly amount_limit?: InputMaybe<bigint_comparison_exp>;
  readonly amount_unfilled?: InputMaybe<bigint_comparison_exp>;
  readonly asset_rating?: InputMaybe<asset_rating_enum_enum_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly expires_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly investor?: InputMaybe<investors_bool_exp>;
  readonly irr_bps?: InputMaybe<Int_comparison_exp>;
  readonly max_term_months?: InputMaybe<Int_comparison_exp>;
  readonly min_term_months?: InputMaybe<Int_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly status?: InputMaybe<limit_buy_order_status_enum_enum_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** Ordering options when selecting data from "limit_buy_orders". */
export type limit_buy_orders_order_by = {
  readonly amount_limit?: InputMaybe<order_by>;
  readonly amount_unfilled?: InputMaybe<order_by>;
  readonly asset_rating?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly expires_at?: InputMaybe<order_by>;
  readonly investor?: InputMaybe<investors_order_by>;
  readonly irr_bps?: InputMaybe<order_by>;
  readonly max_term_months?: InputMaybe<order_by>;
  readonly min_term_months?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly status?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** select columns of table "limit_buy_orders" */
export enum limit_buy_orders_select_column {
  /** column name */
  amount_limit = 'amount_limit',
  /** column name */
  amount_unfilled = 'amount_unfilled',
  /** column name */
  asset_rating = 'asset_rating',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  expires_at = 'expires_at',
  /** column name */
  irr_bps = 'irr_bps',
  /** column name */
  max_term_months = 'max_term_months',
  /** column name */
  min_term_months = 'min_term_months',
  /** column name */
  public_id = 'public_id',
  /** column name */
  status = 'status',
  /** column name */
  updated_at = 'updated_at'
}

/** columns and relationships of "lists" */
export type lists = {
  readonly __typename?: 'lists';
  /** An array relationship */
  readonly children: ReadonlyArray<lists>;
  /** An aggregate relationship */
  readonly children_aggregate: lists_aggregate;
  /** The configuration on what columns, filter, sorts, etc. are on the list. */
  readonly config?: Maybe<Scalars['jsonb']>;
  readonly created_at: Scalars['timestamp'];
  /** An object relationship */
  readonly created_by: users;
  /** Description of what the list is for or contains. */
  readonly description?: Maybe<Scalars['String']>;
  /** If true, this list is excluded from search results and other lists of lists. */
  readonly is_ephemeral?: Maybe<Scalars['Boolean']>;
  /** If true, this list can only be edited by its creator. */
  readonly is_locked?: Maybe<Scalars['Boolean']>;
  /** The model that this list is viewing, e.g. subscriptions or vendors. */
  readonly model: Scalars['String'];
  /** An object relationship */
  readonly parent?: Maybe<lists>;
  readonly public_id: Scalars['String'];
  /** URL-friendly slug of the list. */
  readonly slug: Scalars['String'];
  /** Title of the list. */
  readonly title: Scalars['String'];
  readonly updated_at: Scalars['timestamp'];
  /** An object relationship */
  readonly vendor: vendors;
};


/** columns and relationships of "lists" */
export type listschildrenArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_order_by>>;
  where?: InputMaybe<lists_bool_exp>;
};


/** columns and relationships of "lists" */
export type listschildren_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_order_by>>;
  where?: InputMaybe<lists_bool_exp>;
};


/** columns and relationships of "lists" */
export type listsconfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "lists" */
export type lists_aggregate = {
  readonly __typename?: 'lists_aggregate';
  readonly aggregate?: Maybe<lists_aggregate_fields>;
  readonly nodes: ReadonlyArray<lists>;
};

/** aggregate fields of "lists" */
export type lists_aggregate_fields = {
  readonly __typename?: 'lists_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<lists_max_fields>;
  readonly min?: Maybe<lists_min_fields>;
};


/** aggregate fields of "lists" */
export type lists_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<lists_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lists" */
export type lists_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<lists_max_order_by>;
  readonly min?: InputMaybe<lists_min_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type lists_append_input = {
  /** The configuration on what columns, filter, sorts, etc. are on the list. */
  readonly config?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "lists". All fields are combined with a logical 'AND'. */
export type lists_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<lists_bool_exp>>;
  readonly _not?: InputMaybe<lists_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<lists_bool_exp>>;
  readonly children?: InputMaybe<lists_bool_exp>;
  readonly config?: InputMaybe<jsonb_comparison_exp>;
  readonly created_at?: InputMaybe<timestamp_comparison_exp>;
  readonly created_by?: InputMaybe<users_bool_exp>;
  readonly description?: InputMaybe<String_comparison_exp>;
  readonly is_ephemeral?: InputMaybe<Boolean_comparison_exp>;
  readonly is_locked?: InputMaybe<Boolean_comparison_exp>;
  readonly model?: InputMaybe<String_comparison_exp>;
  readonly parent?: InputMaybe<lists_bool_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly slug?: InputMaybe<String_comparison_exp>;
  readonly title?: InputMaybe<String_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamp_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type lists_delete_at_path_input = {
  /** The configuration on what columns, filter, sorts, etc. are on the list. */
  readonly config?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type lists_delete_elem_input = {
  /** The configuration on what columns, filter, sorts, etc. are on the list. */
  readonly config?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type lists_delete_key_input = {
  /** The configuration on what columns, filter, sorts, etc. are on the list. */
  readonly config?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "lists_investor_asset_receipts" */
export type lists_investor_asset_receipts = {
  readonly __typename?: 'lists_investor_asset_receipts';
  readonly amount_filled?: Maybe<Scalars['numeric']>;
  readonly asset_rating?: Maybe<Scalars['String']>;
  readonly asset_status?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly currency?: Maybe<Scalars['currency']>;
  readonly ends_at?: Maybe<Scalars['date']>;
  readonly investor_public_id?: Maybe<Scalars['String']>;
  readonly irr_bps?: Maybe<Scalars['Int']>;
  readonly lbo_public_id?: Maybe<Scalars['String']>;
  readonly matched_at?: Maybe<Scalars['timestamptz']>;
  readonly outstanding_balance?: Maybe<Scalars['numeric']>;
  readonly percentage_paid?: Maybe<Scalars['numeric']>;
  readonly pseudonym?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly purchase_amount?: Maybe<Scalars['bigint']>;
  readonly receivable_amount?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "lists_investor_asset_receipts" */
export type lists_investor_asset_receipts_aggregate = {
  readonly __typename?: 'lists_investor_asset_receipts_aggregate';
  readonly aggregate?: Maybe<lists_investor_asset_receipts_aggregate_fields>;
  readonly nodes: ReadonlyArray<lists_investor_asset_receipts>;
};

/** aggregate fields of "lists_investor_asset_receipts" */
export type lists_investor_asset_receipts_aggregate_fields = {
  readonly __typename?: 'lists_investor_asset_receipts_aggregate_fields';
  readonly avg?: Maybe<lists_investor_asset_receipts_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<lists_investor_asset_receipts_max_fields>;
  readonly min?: Maybe<lists_investor_asset_receipts_min_fields>;
  readonly stddev?: Maybe<lists_investor_asset_receipts_stddev_fields>;
  readonly stddev_pop?: Maybe<lists_investor_asset_receipts_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<lists_investor_asset_receipts_stddev_samp_fields>;
  readonly sum?: Maybe<lists_investor_asset_receipts_sum_fields>;
  readonly var_pop?: Maybe<lists_investor_asset_receipts_var_pop_fields>;
  readonly var_samp?: Maybe<lists_investor_asset_receipts_var_samp_fields>;
  readonly variance?: Maybe<lists_investor_asset_receipts_variance_fields>;
};


/** aggregate fields of "lists_investor_asset_receipts" */
export type lists_investor_asset_receipts_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<lists_investor_asset_receipts_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type lists_investor_asset_receipts_avg_fields = {
  readonly __typename?: 'lists_investor_asset_receipts_avg_fields';
  readonly amount_filled?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
  readonly outstanding_balance?: Maybe<Scalars['Float']>;
  readonly percentage_paid?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lists_investor_asset_receipts". All fields are combined with a logical 'AND'. */
export type lists_investor_asset_receipts_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<lists_investor_asset_receipts_bool_exp>>;
  readonly _not?: InputMaybe<lists_investor_asset_receipts_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<lists_investor_asset_receipts_bool_exp>>;
  readonly amount_filled?: InputMaybe<numeric_comparison_exp>;
  readonly asset_rating?: InputMaybe<String_comparison_exp>;
  readonly asset_status?: InputMaybe<String_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly ends_at?: InputMaybe<date_comparison_exp>;
  readonly investor_public_id?: InputMaybe<String_comparison_exp>;
  readonly irr_bps?: InputMaybe<Int_comparison_exp>;
  readonly lbo_public_id?: InputMaybe<String_comparison_exp>;
  readonly matched_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly outstanding_balance?: InputMaybe<numeric_comparison_exp>;
  readonly percentage_paid?: InputMaybe<numeric_comparison_exp>;
  readonly pseudonym?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly purchase_amount?: InputMaybe<bigint_comparison_exp>;
  readonly receivable_amount?: InputMaybe<bigint_comparison_exp>;
};

/** aggregate max on columns */
export type lists_investor_asset_receipts_max_fields = {
  readonly __typename?: 'lists_investor_asset_receipts_max_fields';
  readonly amount_filled?: Maybe<Scalars['numeric']>;
  readonly asset_rating?: Maybe<Scalars['String']>;
  readonly asset_status?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly ends_at?: Maybe<Scalars['date']>;
  readonly investor_public_id?: Maybe<Scalars['String']>;
  readonly irr_bps?: Maybe<Scalars['Int']>;
  readonly lbo_public_id?: Maybe<Scalars['String']>;
  readonly matched_at?: Maybe<Scalars['timestamptz']>;
  readonly outstanding_balance?: Maybe<Scalars['numeric']>;
  readonly percentage_paid?: Maybe<Scalars['numeric']>;
  readonly pseudonym?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly purchase_amount?: Maybe<Scalars['bigint']>;
  readonly receivable_amount?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type lists_investor_asset_receipts_min_fields = {
  readonly __typename?: 'lists_investor_asset_receipts_min_fields';
  readonly amount_filled?: Maybe<Scalars['numeric']>;
  readonly asset_rating?: Maybe<Scalars['String']>;
  readonly asset_status?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly ends_at?: Maybe<Scalars['date']>;
  readonly investor_public_id?: Maybe<Scalars['String']>;
  readonly irr_bps?: Maybe<Scalars['Int']>;
  readonly lbo_public_id?: Maybe<Scalars['String']>;
  readonly matched_at?: Maybe<Scalars['timestamptz']>;
  readonly outstanding_balance?: Maybe<Scalars['numeric']>;
  readonly percentage_paid?: Maybe<Scalars['numeric']>;
  readonly pseudonym?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly purchase_amount?: Maybe<Scalars['bigint']>;
  readonly receivable_amount?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "lists_investor_asset_receipts". */
export type lists_investor_asset_receipts_order_by = {
  readonly amount_filled?: InputMaybe<order_by>;
  readonly asset_rating?: InputMaybe<order_by>;
  readonly asset_status?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly ends_at?: InputMaybe<order_by>;
  readonly investor_public_id?: InputMaybe<order_by>;
  readonly irr_bps?: InputMaybe<order_by>;
  readonly lbo_public_id?: InputMaybe<order_by>;
  readonly matched_at?: InputMaybe<order_by>;
  readonly outstanding_balance?: InputMaybe<order_by>;
  readonly percentage_paid?: InputMaybe<order_by>;
  readonly pseudonym?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly purchase_amount?: InputMaybe<order_by>;
  readonly receivable_amount?: InputMaybe<order_by>;
};

/** select columns of table "lists_investor_asset_receipts" */
export enum lists_investor_asset_receipts_select_column {
  /** column name */
  amount_filled = 'amount_filled',
  /** column name */
  asset_rating = 'asset_rating',
  /** column name */
  asset_status = 'asset_status',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  ends_at = 'ends_at',
  /** column name */
  investor_public_id = 'investor_public_id',
  /** column name */
  irr_bps = 'irr_bps',
  /** column name */
  lbo_public_id = 'lbo_public_id',
  /** column name */
  matched_at = 'matched_at',
  /** column name */
  outstanding_balance = 'outstanding_balance',
  /** column name */
  percentage_paid = 'percentage_paid',
  /** column name */
  pseudonym = 'pseudonym',
  /** column name */
  public_id = 'public_id',
  /** column name */
  purchase_amount = 'purchase_amount',
  /** column name */
  receivable_amount = 'receivable_amount'
}

/** aggregate stddev on columns */
export type lists_investor_asset_receipts_stddev_fields = {
  readonly __typename?: 'lists_investor_asset_receipts_stddev_fields';
  readonly amount_filled?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
  readonly outstanding_balance?: Maybe<Scalars['Float']>;
  readonly percentage_paid?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type lists_investor_asset_receipts_stddev_pop_fields = {
  readonly __typename?: 'lists_investor_asset_receipts_stddev_pop_fields';
  readonly amount_filled?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
  readonly outstanding_balance?: Maybe<Scalars['Float']>;
  readonly percentage_paid?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type lists_investor_asset_receipts_stddev_samp_fields = {
  readonly __typename?: 'lists_investor_asset_receipts_stddev_samp_fields';
  readonly amount_filled?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
  readonly outstanding_balance?: Maybe<Scalars['Float']>;
  readonly percentage_paid?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type lists_investor_asset_receipts_sum_fields = {
  readonly __typename?: 'lists_investor_asset_receipts_sum_fields';
  readonly amount_filled?: Maybe<Scalars['numeric']>;
  readonly irr_bps?: Maybe<Scalars['Int']>;
  readonly outstanding_balance?: Maybe<Scalars['numeric']>;
  readonly percentage_paid?: Maybe<Scalars['numeric']>;
  readonly purchase_amount?: Maybe<Scalars['bigint']>;
  readonly receivable_amount?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type lists_investor_asset_receipts_var_pop_fields = {
  readonly __typename?: 'lists_investor_asset_receipts_var_pop_fields';
  readonly amount_filled?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
  readonly outstanding_balance?: Maybe<Scalars['Float']>;
  readonly percentage_paid?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type lists_investor_asset_receipts_var_samp_fields = {
  readonly __typename?: 'lists_investor_asset_receipts_var_samp_fields';
  readonly amount_filled?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
  readonly outstanding_balance?: Maybe<Scalars['Float']>;
  readonly percentage_paid?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type lists_investor_asset_receipts_variance_fields = {
  readonly __typename?: 'lists_investor_asset_receipts_variance_fields';
  readonly amount_filled?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
  readonly outstanding_balance?: Maybe<Scalars['Float']>;
  readonly percentage_paid?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "lists_investor_payments" */
export type lists_investor_payments = {
  readonly __typename?: 'lists_investor_payments';
  readonly amount?: Maybe<Scalars['bigint']>;
  readonly ar_public_id?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly currency?: Maybe<Scalars['currency']>;
  readonly due_at?: Maybe<Scalars['date']>;
  readonly invd_public_id?: Maybe<Scalars['String']>;
  readonly investor_public_id?: Maybe<Scalars['String']>;
  readonly months_diff?: Maybe<Scalars['Int']>;
  readonly payment_type?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "lists_investor_payments" */
export type lists_investor_payments_aggregate = {
  readonly __typename?: 'lists_investor_payments_aggregate';
  readonly aggregate?: Maybe<lists_investor_payments_aggregate_fields>;
  readonly nodes: ReadonlyArray<lists_investor_payments>;
};

/** aggregate fields of "lists_investor_payments" */
export type lists_investor_payments_aggregate_fields = {
  readonly __typename?: 'lists_investor_payments_aggregate_fields';
  readonly avg?: Maybe<lists_investor_payments_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<lists_investor_payments_max_fields>;
  readonly min?: Maybe<lists_investor_payments_min_fields>;
  readonly stddev?: Maybe<lists_investor_payments_stddev_fields>;
  readonly stddev_pop?: Maybe<lists_investor_payments_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<lists_investor_payments_stddev_samp_fields>;
  readonly sum?: Maybe<lists_investor_payments_sum_fields>;
  readonly var_pop?: Maybe<lists_investor_payments_var_pop_fields>;
  readonly var_samp?: Maybe<lists_investor_payments_var_samp_fields>;
  readonly variance?: Maybe<lists_investor_payments_variance_fields>;
};


/** aggregate fields of "lists_investor_payments" */
export type lists_investor_payments_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<lists_investor_payments_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type lists_investor_payments_avg_fields = {
  readonly __typename?: 'lists_investor_payments_avg_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly months_diff?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lists_investor_payments". All fields are combined with a logical 'AND'. */
export type lists_investor_payments_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<lists_investor_payments_bool_exp>>;
  readonly _not?: InputMaybe<lists_investor_payments_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<lists_investor_payments_bool_exp>>;
  readonly amount?: InputMaybe<bigint_comparison_exp>;
  readonly ar_public_id?: InputMaybe<String_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly due_at?: InputMaybe<date_comparison_exp>;
  readonly invd_public_id?: InputMaybe<String_comparison_exp>;
  readonly investor_public_id?: InputMaybe<String_comparison_exp>;
  readonly months_diff?: InputMaybe<Int_comparison_exp>;
  readonly payment_type?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type lists_investor_payments_max_fields = {
  readonly __typename?: 'lists_investor_payments_max_fields';
  readonly amount?: Maybe<Scalars['bigint']>;
  readonly ar_public_id?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly due_at?: Maybe<Scalars['date']>;
  readonly invd_public_id?: Maybe<Scalars['String']>;
  readonly investor_public_id?: Maybe<Scalars['String']>;
  readonly months_diff?: Maybe<Scalars['Int']>;
  readonly payment_type?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type lists_investor_payments_min_fields = {
  readonly __typename?: 'lists_investor_payments_min_fields';
  readonly amount?: Maybe<Scalars['bigint']>;
  readonly ar_public_id?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly due_at?: Maybe<Scalars['date']>;
  readonly invd_public_id?: Maybe<Scalars['String']>;
  readonly investor_public_id?: Maybe<Scalars['String']>;
  readonly months_diff?: Maybe<Scalars['Int']>;
  readonly payment_type?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "lists_investor_payments". */
export type lists_investor_payments_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly ar_public_id?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly due_at?: InputMaybe<order_by>;
  readonly invd_public_id?: InputMaybe<order_by>;
  readonly investor_public_id?: InputMaybe<order_by>;
  readonly months_diff?: InputMaybe<order_by>;
  readonly payment_type?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** select columns of table "lists_investor_payments" */
export enum lists_investor_payments_select_column {
  /** column name */
  amount = 'amount',
  /** column name */
  ar_public_id = 'ar_public_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  due_at = 'due_at',
  /** column name */
  invd_public_id = 'invd_public_id',
  /** column name */
  investor_public_id = 'investor_public_id',
  /** column name */
  months_diff = 'months_diff',
  /** column name */
  payment_type = 'payment_type',
  /** column name */
  public_id = 'public_id'
}

/** aggregate stddev on columns */
export type lists_investor_payments_stddev_fields = {
  readonly __typename?: 'lists_investor_payments_stddev_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly months_diff?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type lists_investor_payments_stddev_pop_fields = {
  readonly __typename?: 'lists_investor_payments_stddev_pop_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly months_diff?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type lists_investor_payments_stddev_samp_fields = {
  readonly __typename?: 'lists_investor_payments_stddev_samp_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly months_diff?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type lists_investor_payments_sum_fields = {
  readonly __typename?: 'lists_investor_payments_sum_fields';
  readonly amount?: Maybe<Scalars['bigint']>;
  readonly months_diff?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type lists_investor_payments_var_pop_fields = {
  readonly __typename?: 'lists_investor_payments_var_pop_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly months_diff?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type lists_investor_payments_var_samp_fields = {
  readonly __typename?: 'lists_investor_payments_var_samp_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly months_diff?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type lists_investor_payments_variance_fields = {
  readonly __typename?: 'lists_investor_payments_variance_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly months_diff?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "lists_investor_transfers" */
export type lists_investor_transfers = {
  readonly __typename?: 'lists_investor_transfers';
  readonly amount?: Maybe<Scalars['numeric']>;
  readonly bank_name?: Maybe<Scalars['String']>;
  readonly cleared_at?: Maybe<Scalars['timestamptz']>;
  readonly currency?: Maybe<Scalars['currency']>;
  readonly due_at?: Maybe<Scalars['date']>;
  readonly initiated_at?: Maybe<Scalars['timestamptz']>;
  readonly num_payments?: Maybe<Scalars['bigint']>;
  readonly payment_type?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
};

/** aggregated selection of "lists_investor_transfers" */
export type lists_investor_transfers_aggregate = {
  readonly __typename?: 'lists_investor_transfers_aggregate';
  readonly aggregate?: Maybe<lists_investor_transfers_aggregate_fields>;
  readonly nodes: ReadonlyArray<lists_investor_transfers>;
};

/** aggregate fields of "lists_investor_transfers" */
export type lists_investor_transfers_aggregate_fields = {
  readonly __typename?: 'lists_investor_transfers_aggregate_fields';
  readonly avg?: Maybe<lists_investor_transfers_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<lists_investor_transfers_max_fields>;
  readonly min?: Maybe<lists_investor_transfers_min_fields>;
  readonly stddev?: Maybe<lists_investor_transfers_stddev_fields>;
  readonly stddev_pop?: Maybe<lists_investor_transfers_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<lists_investor_transfers_stddev_samp_fields>;
  readonly sum?: Maybe<lists_investor_transfers_sum_fields>;
  readonly var_pop?: Maybe<lists_investor_transfers_var_pop_fields>;
  readonly var_samp?: Maybe<lists_investor_transfers_var_samp_fields>;
  readonly variance?: Maybe<lists_investor_transfers_variance_fields>;
};


/** aggregate fields of "lists_investor_transfers" */
export type lists_investor_transfers_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<lists_investor_transfers_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type lists_investor_transfers_avg_fields = {
  readonly __typename?: 'lists_investor_transfers_avg_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly num_payments?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lists_investor_transfers". All fields are combined with a logical 'AND'. */
export type lists_investor_transfers_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<lists_investor_transfers_bool_exp>>;
  readonly _not?: InputMaybe<lists_investor_transfers_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<lists_investor_transfers_bool_exp>>;
  readonly amount?: InputMaybe<numeric_comparison_exp>;
  readonly bank_name?: InputMaybe<String_comparison_exp>;
  readonly cleared_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly due_at?: InputMaybe<date_comparison_exp>;
  readonly initiated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly num_payments?: InputMaybe<bigint_comparison_exp>;
  readonly payment_type?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly status?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type lists_investor_transfers_max_fields = {
  readonly __typename?: 'lists_investor_transfers_max_fields';
  readonly amount?: Maybe<Scalars['numeric']>;
  readonly bank_name?: Maybe<Scalars['String']>;
  readonly cleared_at?: Maybe<Scalars['timestamptz']>;
  readonly due_at?: Maybe<Scalars['date']>;
  readonly initiated_at?: Maybe<Scalars['timestamptz']>;
  readonly num_payments?: Maybe<Scalars['bigint']>;
  readonly payment_type?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type lists_investor_transfers_min_fields = {
  readonly __typename?: 'lists_investor_transfers_min_fields';
  readonly amount?: Maybe<Scalars['numeric']>;
  readonly bank_name?: Maybe<Scalars['String']>;
  readonly cleared_at?: Maybe<Scalars['timestamptz']>;
  readonly due_at?: Maybe<Scalars['date']>;
  readonly initiated_at?: Maybe<Scalars['timestamptz']>;
  readonly num_payments?: Maybe<Scalars['bigint']>;
  readonly payment_type?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "lists_investor_transfers". */
export type lists_investor_transfers_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly bank_name?: InputMaybe<order_by>;
  readonly cleared_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly due_at?: InputMaybe<order_by>;
  readonly initiated_at?: InputMaybe<order_by>;
  readonly num_payments?: InputMaybe<order_by>;
  readonly payment_type?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly status?: InputMaybe<order_by>;
};

/** select columns of table "lists_investor_transfers" */
export enum lists_investor_transfers_select_column {
  /** column name */
  amount = 'amount',
  /** column name */
  bank_name = 'bank_name',
  /** column name */
  cleared_at = 'cleared_at',
  /** column name */
  currency = 'currency',
  /** column name */
  due_at = 'due_at',
  /** column name */
  initiated_at = 'initiated_at',
  /** column name */
  num_payments = 'num_payments',
  /** column name */
  payment_type = 'payment_type',
  /** column name */
  public_id = 'public_id',
  /** column name */
  status = 'status'
}

/** aggregate stddev on columns */
export type lists_investor_transfers_stddev_fields = {
  readonly __typename?: 'lists_investor_transfers_stddev_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly num_payments?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type lists_investor_transfers_stddev_pop_fields = {
  readonly __typename?: 'lists_investor_transfers_stddev_pop_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly num_payments?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type lists_investor_transfers_stddev_samp_fields = {
  readonly __typename?: 'lists_investor_transfers_stddev_samp_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly num_payments?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type lists_investor_transfers_sum_fields = {
  readonly __typename?: 'lists_investor_transfers_sum_fields';
  readonly amount?: Maybe<Scalars['numeric']>;
  readonly num_payments?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type lists_investor_transfers_var_pop_fields = {
  readonly __typename?: 'lists_investor_transfers_var_pop_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly num_payments?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type lists_investor_transfers_var_samp_fields = {
  readonly __typename?: 'lists_investor_transfers_var_samp_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly num_payments?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type lists_investor_transfers_variance_fields = {
  readonly __typename?: 'lists_investor_transfers_variance_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly num_payments?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "lists_investor_users" */
export type lists_investor_users = {
  readonly __typename?: 'lists_investor_users';
  readonly email?: Maybe<Scalars['String']>;
  readonly investor_public_id?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
};

/** aggregated selection of "lists_investor_users" */
export type lists_investor_users_aggregate = {
  readonly __typename?: 'lists_investor_users_aggregate';
  readonly aggregate?: Maybe<lists_investor_users_aggregate_fields>;
  readonly nodes: ReadonlyArray<lists_investor_users>;
};

/** aggregate fields of "lists_investor_users" */
export type lists_investor_users_aggregate_fields = {
  readonly __typename?: 'lists_investor_users_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<lists_investor_users_max_fields>;
  readonly min?: Maybe<lists_investor_users_min_fields>;
};


/** aggregate fields of "lists_investor_users" */
export type lists_investor_users_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<lists_investor_users_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lists_investor_users". All fields are combined with a logical 'AND'. */
export type lists_investor_users_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<lists_investor_users_bool_exp>>;
  readonly _not?: InputMaybe<lists_investor_users_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<lists_investor_users_bool_exp>>;
  readonly email?: InputMaybe<String_comparison_exp>;
  readonly investor_public_id?: InputMaybe<String_comparison_exp>;
  readonly name?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly status?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type lists_investor_users_max_fields = {
  readonly __typename?: 'lists_investor_users_max_fields';
  readonly email?: Maybe<Scalars['String']>;
  readonly investor_public_id?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type lists_investor_users_min_fields = {
  readonly __typename?: 'lists_investor_users_min_fields';
  readonly email?: Maybe<Scalars['String']>;
  readonly investor_public_id?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "lists_investor_users". */
export type lists_investor_users_order_by = {
  readonly email?: InputMaybe<order_by>;
  readonly investor_public_id?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly status?: InputMaybe<order_by>;
};

/** select columns of table "lists_investor_users" */
export enum lists_investor_users_select_column {
  /** column name */
  email = 'email',
  /** column name */
  investor_public_id = 'investor_public_id',
  /** column name */
  name = 'name',
  /** column name */
  public_id = 'public_id',
  /** column name */
  status = 'status'
}

/** columns and relationships of "lists_limit_buy_orders" */
export type lists_limit_buy_orders = {
  readonly __typename?: 'lists_limit_buy_orders';
  readonly amount_filled_percentage?: Maybe<Scalars['numeric']>;
  readonly amount_limit?: Maybe<Scalars['bigint']>;
  readonly amount_unfilled?: Maybe<Scalars['bigint']>;
  readonly asset_rating?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly created_by_user_name?: Maybe<Scalars['String']>;
  readonly currency?: Maybe<Scalars['currency']>;
  readonly expires_at?: Maybe<Scalars['timestamptz']>;
  readonly irr_bps?: Maybe<Scalars['Int']>;
  readonly is_active?: Maybe<Scalars['Boolean']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
};

/** aggregated selection of "lists_limit_buy_orders" */
export type lists_limit_buy_orders_aggregate = {
  readonly __typename?: 'lists_limit_buy_orders_aggregate';
  readonly aggregate?: Maybe<lists_limit_buy_orders_aggregate_fields>;
  readonly nodes: ReadonlyArray<lists_limit_buy_orders>;
};

/** aggregate fields of "lists_limit_buy_orders" */
export type lists_limit_buy_orders_aggregate_fields = {
  readonly __typename?: 'lists_limit_buy_orders_aggregate_fields';
  readonly avg?: Maybe<lists_limit_buy_orders_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<lists_limit_buy_orders_max_fields>;
  readonly min?: Maybe<lists_limit_buy_orders_min_fields>;
  readonly stddev?: Maybe<lists_limit_buy_orders_stddev_fields>;
  readonly stddev_pop?: Maybe<lists_limit_buy_orders_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<lists_limit_buy_orders_stddev_samp_fields>;
  readonly sum?: Maybe<lists_limit_buy_orders_sum_fields>;
  readonly var_pop?: Maybe<lists_limit_buy_orders_var_pop_fields>;
  readonly var_samp?: Maybe<lists_limit_buy_orders_var_samp_fields>;
  readonly variance?: Maybe<lists_limit_buy_orders_variance_fields>;
};


/** aggregate fields of "lists_limit_buy_orders" */
export type lists_limit_buy_orders_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<lists_limit_buy_orders_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type lists_limit_buy_orders_avg_fields = {
  readonly __typename?: 'lists_limit_buy_orders_avg_fields';
  readonly amount_filled_percentage?: Maybe<Scalars['Float']>;
  readonly amount_limit?: Maybe<Scalars['Float']>;
  readonly amount_unfilled?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lists_limit_buy_orders". All fields are combined with a logical 'AND'. */
export type lists_limit_buy_orders_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<lists_limit_buy_orders_bool_exp>>;
  readonly _not?: InputMaybe<lists_limit_buy_orders_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<lists_limit_buy_orders_bool_exp>>;
  readonly amount_filled_percentage?: InputMaybe<numeric_comparison_exp>;
  readonly amount_limit?: InputMaybe<bigint_comparison_exp>;
  readonly amount_unfilled?: InputMaybe<bigint_comparison_exp>;
  readonly asset_rating?: InputMaybe<String_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly created_by_user_name?: InputMaybe<String_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly expires_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly irr_bps?: InputMaybe<Int_comparison_exp>;
  readonly is_active?: InputMaybe<Boolean_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly status?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type lists_limit_buy_orders_max_fields = {
  readonly __typename?: 'lists_limit_buy_orders_max_fields';
  readonly amount_filled_percentage?: Maybe<Scalars['numeric']>;
  readonly amount_limit?: Maybe<Scalars['bigint']>;
  readonly amount_unfilled?: Maybe<Scalars['bigint']>;
  readonly asset_rating?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly created_by_user_name?: Maybe<Scalars['String']>;
  readonly expires_at?: Maybe<Scalars['timestamptz']>;
  readonly irr_bps?: Maybe<Scalars['Int']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type lists_limit_buy_orders_min_fields = {
  readonly __typename?: 'lists_limit_buy_orders_min_fields';
  readonly amount_filled_percentage?: Maybe<Scalars['numeric']>;
  readonly amount_limit?: Maybe<Scalars['bigint']>;
  readonly amount_unfilled?: Maybe<Scalars['bigint']>;
  readonly asset_rating?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly created_by_user_name?: Maybe<Scalars['String']>;
  readonly expires_at?: Maybe<Scalars['timestamptz']>;
  readonly irr_bps?: Maybe<Scalars['Int']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "lists_limit_buy_orders". */
export type lists_limit_buy_orders_order_by = {
  readonly amount_filled_percentage?: InputMaybe<order_by>;
  readonly amount_limit?: InputMaybe<order_by>;
  readonly amount_unfilled?: InputMaybe<order_by>;
  readonly asset_rating?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly created_by_user_name?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly expires_at?: InputMaybe<order_by>;
  readonly irr_bps?: InputMaybe<order_by>;
  readonly is_active?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly status?: InputMaybe<order_by>;
};

/** select columns of table "lists_limit_buy_orders" */
export enum lists_limit_buy_orders_select_column {
  /** column name */
  amount_filled_percentage = 'amount_filled_percentage',
  /** column name */
  amount_limit = 'amount_limit',
  /** column name */
  amount_unfilled = 'amount_unfilled',
  /** column name */
  asset_rating = 'asset_rating',
  /** column name */
  created_at = 'created_at',
  /** column name */
  created_by_user_name = 'created_by_user_name',
  /** column name */
  currency = 'currency',
  /** column name */
  expires_at = 'expires_at',
  /** column name */
  irr_bps = 'irr_bps',
  /** column name */
  is_active = 'is_active',
  /** column name */
  public_id = 'public_id',
  /** column name */
  status = 'status'
}

/** aggregate stddev on columns */
export type lists_limit_buy_orders_stddev_fields = {
  readonly __typename?: 'lists_limit_buy_orders_stddev_fields';
  readonly amount_filled_percentage?: Maybe<Scalars['Float']>;
  readonly amount_limit?: Maybe<Scalars['Float']>;
  readonly amount_unfilled?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type lists_limit_buy_orders_stddev_pop_fields = {
  readonly __typename?: 'lists_limit_buy_orders_stddev_pop_fields';
  readonly amount_filled_percentage?: Maybe<Scalars['Float']>;
  readonly amount_limit?: Maybe<Scalars['Float']>;
  readonly amount_unfilled?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type lists_limit_buy_orders_stddev_samp_fields = {
  readonly __typename?: 'lists_limit_buy_orders_stddev_samp_fields';
  readonly amount_filled_percentage?: Maybe<Scalars['Float']>;
  readonly amount_limit?: Maybe<Scalars['Float']>;
  readonly amount_unfilled?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type lists_limit_buy_orders_sum_fields = {
  readonly __typename?: 'lists_limit_buy_orders_sum_fields';
  readonly amount_filled_percentage?: Maybe<Scalars['numeric']>;
  readonly amount_limit?: Maybe<Scalars['bigint']>;
  readonly amount_unfilled?: Maybe<Scalars['bigint']>;
  readonly irr_bps?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type lists_limit_buy_orders_var_pop_fields = {
  readonly __typename?: 'lists_limit_buy_orders_var_pop_fields';
  readonly amount_filled_percentage?: Maybe<Scalars['Float']>;
  readonly amount_limit?: Maybe<Scalars['Float']>;
  readonly amount_unfilled?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type lists_limit_buy_orders_var_samp_fields = {
  readonly __typename?: 'lists_limit_buy_orders_var_samp_fields';
  readonly amount_filled_percentage?: Maybe<Scalars['Float']>;
  readonly amount_limit?: Maybe<Scalars['Float']>;
  readonly amount_unfilled?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type lists_limit_buy_orders_variance_fields = {
  readonly __typename?: 'lists_limit_buy_orders_variance_fields';
  readonly amount_filled_percentage?: Maybe<Scalars['Float']>;
  readonly amount_limit?: Maybe<Scalars['Float']>;
  readonly amount_unfilled?: Maybe<Scalars['Float']>;
  readonly irr_bps?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type lists_max_fields = {
  readonly __typename?: 'lists_max_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  /** Description of what the list is for or contains. */
  readonly description?: Maybe<Scalars['String']>;
  /** The model that this list is viewing, e.g. subscriptions or vendors. */
  readonly model?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  /** URL-friendly slug of the list. */
  readonly slug?: Maybe<Scalars['String']>;
  /** Title of the list. */
  readonly title?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "lists" */
export type lists_max_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  /** Description of what the list is for or contains. */
  readonly description?: InputMaybe<order_by>;
  /** The model that this list is viewing, e.g. subscriptions or vendors. */
  readonly model?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  /** URL-friendly slug of the list. */
  readonly slug?: InputMaybe<order_by>;
  /** Title of the list. */
  readonly title?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type lists_min_fields = {
  readonly __typename?: 'lists_min_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  /** Description of what the list is for or contains. */
  readonly description?: Maybe<Scalars['String']>;
  /** The model that this list is viewing, e.g. subscriptions or vendors. */
  readonly model?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  /** URL-friendly slug of the list. */
  readonly slug?: Maybe<Scalars['String']>;
  /** Title of the list. */
  readonly title?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "lists" */
export type lists_min_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  /** Description of what the list is for or contains. */
  readonly description?: InputMaybe<order_by>;
  /** The model that this list is viewing, e.g. subscriptions or vendors. */
  readonly model?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  /** URL-friendly slug of the list. */
  readonly slug?: InputMaybe<order_by>;
  /** Title of the list. */
  readonly title?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "lists" */
export type lists_mutation_response = {
  readonly __typename?: 'lists_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<lists>;
};

/** Ordering options when selecting data from "lists". */
export type lists_order_by = {
  readonly children_aggregate?: InputMaybe<lists_aggregate_order_by>;
  readonly config?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly created_by?: InputMaybe<users_order_by>;
  readonly description?: InputMaybe<order_by>;
  readonly is_ephemeral?: InputMaybe<order_by>;
  readonly is_locked?: InputMaybe<order_by>;
  readonly model?: InputMaybe<order_by>;
  readonly parent?: InputMaybe<lists_order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly slug?: InputMaybe<order_by>;
  readonly title?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** columns and relationships of "lists_payment_tasks" */
export type lists_payment_tasks = {
  readonly __typename?: 'lists_payment_tasks';
  readonly currency?: Maybe<Scalars['currency']>;
  readonly due_at?: Maybe<Scalars['timestamptz']>;
  readonly num_sub_payments?: Maybe<Scalars['bigint']>;
  readonly payment_amount?: Maybe<Scalars['bigint']>;
  readonly payout_amount?: Maybe<Scalars['bigint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly vendor_client_id?: Maybe<Scalars['String']>;
  readonly vendor_name?: Maybe<Scalars['String']>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "lists_payment_tasks" */
export type lists_payment_tasks_aggregate = {
  readonly __typename?: 'lists_payment_tasks_aggregate';
  readonly aggregate?: Maybe<lists_payment_tasks_aggregate_fields>;
  readonly nodes: ReadonlyArray<lists_payment_tasks>;
};

/** aggregate fields of "lists_payment_tasks" */
export type lists_payment_tasks_aggregate_fields = {
  readonly __typename?: 'lists_payment_tasks_aggregate_fields';
  readonly avg?: Maybe<lists_payment_tasks_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<lists_payment_tasks_max_fields>;
  readonly min?: Maybe<lists_payment_tasks_min_fields>;
  readonly stddev?: Maybe<lists_payment_tasks_stddev_fields>;
  readonly stddev_pop?: Maybe<lists_payment_tasks_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<lists_payment_tasks_stddev_samp_fields>;
  readonly sum?: Maybe<lists_payment_tasks_sum_fields>;
  readonly var_pop?: Maybe<lists_payment_tasks_var_pop_fields>;
  readonly var_samp?: Maybe<lists_payment_tasks_var_samp_fields>;
  readonly variance?: Maybe<lists_payment_tasks_variance_fields>;
};


/** aggregate fields of "lists_payment_tasks" */
export type lists_payment_tasks_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<lists_payment_tasks_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type lists_payment_tasks_avg_fields = {
  readonly __typename?: 'lists_payment_tasks_avg_fields';
  readonly num_sub_payments?: Maybe<Scalars['Float']>;
  readonly payment_amount?: Maybe<Scalars['Float']>;
  readonly payout_amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lists_payment_tasks". All fields are combined with a logical 'AND'. */
export type lists_payment_tasks_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<lists_payment_tasks_bool_exp>>;
  readonly _not?: InputMaybe<lists_payment_tasks_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<lists_payment_tasks_bool_exp>>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly due_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly num_sub_payments?: InputMaybe<bigint_comparison_exp>;
  readonly payment_amount?: InputMaybe<bigint_comparison_exp>;
  readonly payout_amount?: InputMaybe<bigint_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly vendor_client_id?: InputMaybe<String_comparison_exp>;
  readonly vendor_name?: InputMaybe<String_comparison_exp>;
  readonly vendor_public_id?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type lists_payment_tasks_max_fields = {
  readonly __typename?: 'lists_payment_tasks_max_fields';
  readonly due_at?: Maybe<Scalars['timestamptz']>;
  readonly num_sub_payments?: Maybe<Scalars['bigint']>;
  readonly payment_amount?: Maybe<Scalars['bigint']>;
  readonly payout_amount?: Maybe<Scalars['bigint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly vendor_client_id?: Maybe<Scalars['String']>;
  readonly vendor_name?: Maybe<Scalars['String']>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type lists_payment_tasks_min_fields = {
  readonly __typename?: 'lists_payment_tasks_min_fields';
  readonly due_at?: Maybe<Scalars['timestamptz']>;
  readonly num_sub_payments?: Maybe<Scalars['bigint']>;
  readonly payment_amount?: Maybe<Scalars['bigint']>;
  readonly payout_amount?: Maybe<Scalars['bigint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly vendor_client_id?: Maybe<Scalars['String']>;
  readonly vendor_name?: Maybe<Scalars['String']>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "lists_payment_tasks". */
export type lists_payment_tasks_order_by = {
  readonly currency?: InputMaybe<order_by>;
  readonly due_at?: InputMaybe<order_by>;
  readonly num_sub_payments?: InputMaybe<order_by>;
  readonly payment_amount?: InputMaybe<order_by>;
  readonly payout_amount?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly vendor_client_id?: InputMaybe<order_by>;
  readonly vendor_name?: InputMaybe<order_by>;
  readonly vendor_public_id?: InputMaybe<order_by>;
};

/** select columns of table "lists_payment_tasks" */
export enum lists_payment_tasks_select_column {
  /** column name */
  currency = 'currency',
  /** column name */
  due_at = 'due_at',
  /** column name */
  num_sub_payments = 'num_sub_payments',
  /** column name */
  payment_amount = 'payment_amount',
  /** column name */
  payout_amount = 'payout_amount',
  /** column name */
  public_id = 'public_id',
  /** column name */
  vendor_client_id = 'vendor_client_id',
  /** column name */
  vendor_name = 'vendor_name',
  /** column name */
  vendor_public_id = 'vendor_public_id'
}

/** aggregate stddev on columns */
export type lists_payment_tasks_stddev_fields = {
  readonly __typename?: 'lists_payment_tasks_stddev_fields';
  readonly num_sub_payments?: Maybe<Scalars['Float']>;
  readonly payment_amount?: Maybe<Scalars['Float']>;
  readonly payout_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type lists_payment_tasks_stddev_pop_fields = {
  readonly __typename?: 'lists_payment_tasks_stddev_pop_fields';
  readonly num_sub_payments?: Maybe<Scalars['Float']>;
  readonly payment_amount?: Maybe<Scalars['Float']>;
  readonly payout_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type lists_payment_tasks_stddev_samp_fields = {
  readonly __typename?: 'lists_payment_tasks_stddev_samp_fields';
  readonly num_sub_payments?: Maybe<Scalars['Float']>;
  readonly payment_amount?: Maybe<Scalars['Float']>;
  readonly payout_amount?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type lists_payment_tasks_sum_fields = {
  readonly __typename?: 'lists_payment_tasks_sum_fields';
  readonly num_sub_payments?: Maybe<Scalars['bigint']>;
  readonly payment_amount?: Maybe<Scalars['bigint']>;
  readonly payout_amount?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type lists_payment_tasks_var_pop_fields = {
  readonly __typename?: 'lists_payment_tasks_var_pop_fields';
  readonly num_sub_payments?: Maybe<Scalars['Float']>;
  readonly payment_amount?: Maybe<Scalars['Float']>;
  readonly payout_amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type lists_payment_tasks_var_samp_fields = {
  readonly __typename?: 'lists_payment_tasks_var_samp_fields';
  readonly num_sub_payments?: Maybe<Scalars['Float']>;
  readonly payment_amount?: Maybe<Scalars['Float']>;
  readonly payout_amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type lists_payment_tasks_variance_fields = {
  readonly __typename?: 'lists_payment_tasks_variance_fields';
  readonly num_sub_payments?: Maybe<Scalars['Float']>;
  readonly payment_amount?: Maybe<Scalars['Float']>;
  readonly payout_amount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "lists_payments" */
export type lists_payments = {
  readonly __typename?: 'lists_payments';
  readonly account_institution_logo?: Maybe<Scalars['String']>;
  readonly account_institution_name?: Maybe<Scalars['String']>;
  readonly account_mask?: Maybe<Scalars['String']>;
  readonly account_name?: Maybe<Scalars['String']>;
  readonly amount?: Maybe<Scalars['bigint']>;
  readonly cleared_at?: Maybe<Scalars['timestamptz']>;
  readonly currency?: Maybe<Scalars['currency']>;
  readonly due_date?: Maybe<Scalars['timestamptz']>;
  readonly initiated_at?: Maybe<Scalars['timestamptz']>;
  readonly num_contracts?: Maybe<Scalars['bigint']>;
  readonly payment_status?: Maybe<Scalars['String']>;
  readonly payment_type?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "lists_payments" */
export type lists_payments_aggregate = {
  readonly __typename?: 'lists_payments_aggregate';
  readonly aggregate?: Maybe<lists_payments_aggregate_fields>;
  readonly nodes: ReadonlyArray<lists_payments>;
};

/** aggregate fields of "lists_payments" */
export type lists_payments_aggregate_fields = {
  readonly __typename?: 'lists_payments_aggregate_fields';
  readonly avg?: Maybe<lists_payments_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<lists_payments_max_fields>;
  readonly min?: Maybe<lists_payments_min_fields>;
  readonly stddev?: Maybe<lists_payments_stddev_fields>;
  readonly stddev_pop?: Maybe<lists_payments_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<lists_payments_stddev_samp_fields>;
  readonly sum?: Maybe<lists_payments_sum_fields>;
  readonly var_pop?: Maybe<lists_payments_var_pop_fields>;
  readonly var_samp?: Maybe<lists_payments_var_samp_fields>;
  readonly variance?: Maybe<lists_payments_variance_fields>;
};


/** aggregate fields of "lists_payments" */
export type lists_payments_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<lists_payments_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type lists_payments_avg_fields = {
  readonly __typename?: 'lists_payments_avg_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly num_contracts?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lists_payments". All fields are combined with a logical 'AND'. */
export type lists_payments_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<lists_payments_bool_exp>>;
  readonly _not?: InputMaybe<lists_payments_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<lists_payments_bool_exp>>;
  readonly account_institution_logo?: InputMaybe<String_comparison_exp>;
  readonly account_institution_name?: InputMaybe<String_comparison_exp>;
  readonly account_mask?: InputMaybe<String_comparison_exp>;
  readonly account_name?: InputMaybe<String_comparison_exp>;
  readonly amount?: InputMaybe<bigint_comparison_exp>;
  readonly cleared_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly due_date?: InputMaybe<timestamptz_comparison_exp>;
  readonly initiated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly num_contracts?: InputMaybe<bigint_comparison_exp>;
  readonly payment_status?: InputMaybe<String_comparison_exp>;
  readonly payment_type?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type lists_payments_max_fields = {
  readonly __typename?: 'lists_payments_max_fields';
  readonly account_institution_logo?: Maybe<Scalars['String']>;
  readonly account_institution_name?: Maybe<Scalars['String']>;
  readonly account_mask?: Maybe<Scalars['String']>;
  readonly account_name?: Maybe<Scalars['String']>;
  readonly amount?: Maybe<Scalars['bigint']>;
  readonly cleared_at?: Maybe<Scalars['timestamptz']>;
  readonly due_date?: Maybe<Scalars['timestamptz']>;
  readonly initiated_at?: Maybe<Scalars['timestamptz']>;
  readonly num_contracts?: Maybe<Scalars['bigint']>;
  readonly payment_status?: Maybe<Scalars['String']>;
  readonly payment_type?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type lists_payments_min_fields = {
  readonly __typename?: 'lists_payments_min_fields';
  readonly account_institution_logo?: Maybe<Scalars['String']>;
  readonly account_institution_name?: Maybe<Scalars['String']>;
  readonly account_mask?: Maybe<Scalars['String']>;
  readonly account_name?: Maybe<Scalars['String']>;
  readonly amount?: Maybe<Scalars['bigint']>;
  readonly cleared_at?: Maybe<Scalars['timestamptz']>;
  readonly due_date?: Maybe<Scalars['timestamptz']>;
  readonly initiated_at?: Maybe<Scalars['timestamptz']>;
  readonly num_contracts?: Maybe<Scalars['bigint']>;
  readonly payment_status?: Maybe<Scalars['String']>;
  readonly payment_type?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "lists_payments". */
export type lists_payments_order_by = {
  readonly account_institution_logo?: InputMaybe<order_by>;
  readonly account_institution_name?: InputMaybe<order_by>;
  readonly account_mask?: InputMaybe<order_by>;
  readonly account_name?: InputMaybe<order_by>;
  readonly amount?: InputMaybe<order_by>;
  readonly cleared_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly due_date?: InputMaybe<order_by>;
  readonly initiated_at?: InputMaybe<order_by>;
  readonly num_contracts?: InputMaybe<order_by>;
  readonly payment_status?: InputMaybe<order_by>;
  readonly payment_type?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** select columns of table "lists_payments" */
export enum lists_payments_select_column {
  /** column name */
  account_institution_logo = 'account_institution_logo',
  /** column name */
  account_institution_name = 'account_institution_name',
  /** column name */
  account_mask = 'account_mask',
  /** column name */
  account_name = 'account_name',
  /** column name */
  amount = 'amount',
  /** column name */
  cleared_at = 'cleared_at',
  /** column name */
  currency = 'currency',
  /** column name */
  due_date = 'due_date',
  /** column name */
  initiated_at = 'initiated_at',
  /** column name */
  num_contracts = 'num_contracts',
  /** column name */
  payment_status = 'payment_status',
  /** column name */
  payment_type = 'payment_type',
  /** column name */
  public_id = 'public_id'
}

/** aggregate stddev on columns */
export type lists_payments_stddev_fields = {
  readonly __typename?: 'lists_payments_stddev_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly num_contracts?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type lists_payments_stddev_pop_fields = {
  readonly __typename?: 'lists_payments_stddev_pop_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly num_contracts?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type lists_payments_stddev_samp_fields = {
  readonly __typename?: 'lists_payments_stddev_samp_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly num_contracts?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type lists_payments_sum_fields = {
  readonly __typename?: 'lists_payments_sum_fields';
  readonly amount?: Maybe<Scalars['bigint']>;
  readonly num_contracts?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type lists_payments_var_pop_fields = {
  readonly __typename?: 'lists_payments_var_pop_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly num_contracts?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type lists_payments_var_samp_fields = {
  readonly __typename?: 'lists_payments_var_samp_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly num_contracts?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type lists_payments_variance_fields = {
  readonly __typename?: 'lists_payments_variance_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly num_contracts?: Maybe<Scalars['Float']>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type lists_prepend_input = {
  /** The configuration on what columns, filter, sorts, etc. are on the list. */
  readonly config?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "lists" */
export enum lists_select_column {
  /** column name */
  config = 'config',
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  is_ephemeral = 'is_ephemeral',
  /** column name */
  is_locked = 'is_locked',
  /** column name */
  model = 'model',
  /** column name */
  public_id = 'public_id',
  /** column name */
  slug = 'slug',
  /** column name */
  title = 'title',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "lists" */
export type lists_set_input = {
  /** The configuration on what columns, filter, sorts, etc. are on the list. */
  readonly config?: InputMaybe<Scalars['jsonb']>;
  /** Description of what the list is for or contains. */
  readonly description?: InputMaybe<Scalars['String']>;
  /** If true, this list is excluded from search results and other lists of lists. */
  readonly is_ephemeral?: InputMaybe<Scalars['Boolean']>;
  /** URL-friendly slug of the list. */
  readonly slug?: InputMaybe<Scalars['String']>;
  /** Title of the list. */
  readonly title?: InputMaybe<Scalars['String']>;
  readonly updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** columns and relationships of "lists_sub_payments" */
export type lists_sub_payments = {
  readonly __typename?: 'lists_sub_payments';
  readonly amount?: Maybe<Scalars['Int']>;
  readonly currency?: Maybe<Scalars['currency']>;
  readonly customer_display?: Maybe<Scalars['String']>;
  readonly customer_email?: Maybe<Scalars['String']>;
  readonly due_at?: Maybe<Scalars['timestamptz']>;
  readonly initiated_at?: Maybe<Scalars['timestamptz']>;
  readonly payment_status?: Maybe<Scalars['String']>;
  readonly payment_type?: Maybe<Scalars['String']>;
  readonly pipe_agreement_id?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly trade_date?: Maybe<Scalars['date']>;
  readonly vendor_payment_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "lists_sub_payments" */
export type lists_sub_payments_aggregate = {
  readonly __typename?: 'lists_sub_payments_aggregate';
  readonly aggregate?: Maybe<lists_sub_payments_aggregate_fields>;
  readonly nodes: ReadonlyArray<lists_sub_payments>;
};

/** aggregate fields of "lists_sub_payments" */
export type lists_sub_payments_aggregate_fields = {
  readonly __typename?: 'lists_sub_payments_aggregate_fields';
  readonly avg?: Maybe<lists_sub_payments_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<lists_sub_payments_max_fields>;
  readonly min?: Maybe<lists_sub_payments_min_fields>;
  readonly stddev?: Maybe<lists_sub_payments_stddev_fields>;
  readonly stddev_pop?: Maybe<lists_sub_payments_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<lists_sub_payments_stddev_samp_fields>;
  readonly sum?: Maybe<lists_sub_payments_sum_fields>;
  readonly var_pop?: Maybe<lists_sub_payments_var_pop_fields>;
  readonly var_samp?: Maybe<lists_sub_payments_var_samp_fields>;
  readonly variance?: Maybe<lists_sub_payments_variance_fields>;
};


/** aggregate fields of "lists_sub_payments" */
export type lists_sub_payments_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<lists_sub_payments_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type lists_sub_payments_avg_fields = {
  readonly __typename?: 'lists_sub_payments_avg_fields';
  readonly amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lists_sub_payments". All fields are combined with a logical 'AND'. */
export type lists_sub_payments_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<lists_sub_payments_bool_exp>>;
  readonly _not?: InputMaybe<lists_sub_payments_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<lists_sub_payments_bool_exp>>;
  readonly amount?: InputMaybe<Int_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly customer_display?: InputMaybe<String_comparison_exp>;
  readonly customer_email?: InputMaybe<String_comparison_exp>;
  readonly due_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly initiated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly payment_status?: InputMaybe<String_comparison_exp>;
  readonly payment_type?: InputMaybe<String_comparison_exp>;
  readonly pipe_agreement_id?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly trade_date?: InputMaybe<date_comparison_exp>;
  readonly vendor_payment_id?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type lists_sub_payments_max_fields = {
  readonly __typename?: 'lists_sub_payments_max_fields';
  readonly amount?: Maybe<Scalars['Int']>;
  readonly customer_display?: Maybe<Scalars['String']>;
  readonly customer_email?: Maybe<Scalars['String']>;
  readonly due_at?: Maybe<Scalars['timestamptz']>;
  readonly initiated_at?: Maybe<Scalars['timestamptz']>;
  readonly payment_status?: Maybe<Scalars['String']>;
  readonly payment_type?: Maybe<Scalars['String']>;
  readonly pipe_agreement_id?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly trade_date?: Maybe<Scalars['date']>;
  readonly vendor_payment_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type lists_sub_payments_min_fields = {
  readonly __typename?: 'lists_sub_payments_min_fields';
  readonly amount?: Maybe<Scalars['Int']>;
  readonly customer_display?: Maybe<Scalars['String']>;
  readonly customer_email?: Maybe<Scalars['String']>;
  readonly due_at?: Maybe<Scalars['timestamptz']>;
  readonly initiated_at?: Maybe<Scalars['timestamptz']>;
  readonly payment_status?: Maybe<Scalars['String']>;
  readonly payment_type?: Maybe<Scalars['String']>;
  readonly pipe_agreement_id?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly trade_date?: Maybe<Scalars['date']>;
  readonly vendor_payment_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "lists_sub_payments". */
export type lists_sub_payments_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly customer_display?: InputMaybe<order_by>;
  readonly customer_email?: InputMaybe<order_by>;
  readonly due_at?: InputMaybe<order_by>;
  readonly initiated_at?: InputMaybe<order_by>;
  readonly payment_status?: InputMaybe<order_by>;
  readonly payment_type?: InputMaybe<order_by>;
  readonly pipe_agreement_id?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly trade_date?: InputMaybe<order_by>;
  readonly vendor_payment_id?: InputMaybe<order_by>;
};

/** select columns of table "lists_sub_payments" */
export enum lists_sub_payments_select_column {
  /** column name */
  amount = 'amount',
  /** column name */
  currency = 'currency',
  /** column name */
  customer_display = 'customer_display',
  /** column name */
  customer_email = 'customer_email',
  /** column name */
  due_at = 'due_at',
  /** column name */
  initiated_at = 'initiated_at',
  /** column name */
  payment_status = 'payment_status',
  /** column name */
  payment_type = 'payment_type',
  /** column name */
  pipe_agreement_id = 'pipe_agreement_id',
  /** column name */
  public_id = 'public_id',
  /** column name */
  trade_date = 'trade_date',
  /** column name */
  vendor_payment_id = 'vendor_payment_id'
}

/** aggregate stddev on columns */
export type lists_sub_payments_stddev_fields = {
  readonly __typename?: 'lists_sub_payments_stddev_fields';
  readonly amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type lists_sub_payments_stddev_pop_fields = {
  readonly __typename?: 'lists_sub_payments_stddev_pop_fields';
  readonly amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type lists_sub_payments_stddev_samp_fields = {
  readonly __typename?: 'lists_sub_payments_stddev_samp_fields';
  readonly amount?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type lists_sub_payments_sum_fields = {
  readonly __typename?: 'lists_sub_payments_sum_fields';
  readonly amount?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type lists_sub_payments_var_pop_fields = {
  readonly __typename?: 'lists_sub_payments_var_pop_fields';
  readonly amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type lists_sub_payments_var_samp_fields = {
  readonly __typename?: 'lists_sub_payments_var_samp_fields';
  readonly amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type lists_sub_payments_variance_fields = {
  readonly __typename?: 'lists_sub_payments_variance_fields';
  readonly amount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "lists_tradeable_contracts" */
export type lists_tradeable_contracts = {
  readonly __typename?: 'lists_tradeable_contracts';
  readonly base_balance?: Maybe<Scalars['bigint']>;
  readonly base_payout?: Maybe<Scalars['Int']>;
  readonly base_rate_bps?: Maybe<Scalars['Int']>;
  readonly currency?: Maybe<Scalars['currency']>;
  readonly customer_display?: Maybe<Scalars['String']>;
  readonly customer_email?: Maybe<Scalars['String']>;
  readonly interval_count?: Maybe<Scalars['Int']>;
  readonly max_tradeable_term_length?: Maybe<Scalars['Int']>;
  readonly mrr?: Maybe<Scalars['bigint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly revenue_per_period?: Maybe<Scalars['bigint']>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "lists_tradeable_contracts" */
export type lists_tradeable_contracts_aggregate = {
  readonly __typename?: 'lists_tradeable_contracts_aggregate';
  readonly aggregate?: Maybe<lists_tradeable_contracts_aggregate_fields>;
  readonly nodes: ReadonlyArray<lists_tradeable_contracts>;
};

/** aggregate fields of "lists_tradeable_contracts" */
export type lists_tradeable_contracts_aggregate_fields = {
  readonly __typename?: 'lists_tradeable_contracts_aggregate_fields';
  readonly avg?: Maybe<lists_tradeable_contracts_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<lists_tradeable_contracts_max_fields>;
  readonly min?: Maybe<lists_tradeable_contracts_min_fields>;
  readonly stddev?: Maybe<lists_tradeable_contracts_stddev_fields>;
  readonly stddev_pop?: Maybe<lists_tradeable_contracts_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<lists_tradeable_contracts_stddev_samp_fields>;
  readonly sum?: Maybe<lists_tradeable_contracts_sum_fields>;
  readonly var_pop?: Maybe<lists_tradeable_contracts_var_pop_fields>;
  readonly var_samp?: Maybe<lists_tradeable_contracts_var_samp_fields>;
  readonly variance?: Maybe<lists_tradeable_contracts_variance_fields>;
};


/** aggregate fields of "lists_tradeable_contracts" */
export type lists_tradeable_contracts_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<lists_tradeable_contracts_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type lists_tradeable_contracts_avg_fields = {
  readonly __typename?: 'lists_tradeable_contracts_avg_fields';
  readonly base_balance?: Maybe<Scalars['Float']>;
  readonly base_payout?: Maybe<Scalars['Float']>;
  readonly base_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly max_tradeable_term_length?: Maybe<Scalars['Float']>;
  readonly mrr?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lists_tradeable_contracts". All fields are combined with a logical 'AND'. */
export type lists_tradeable_contracts_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<lists_tradeable_contracts_bool_exp>>;
  readonly _not?: InputMaybe<lists_tradeable_contracts_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<lists_tradeable_contracts_bool_exp>>;
  readonly base_balance?: InputMaybe<bigint_comparison_exp>;
  readonly base_payout?: InputMaybe<Int_comparison_exp>;
  readonly base_rate_bps?: InputMaybe<Int_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly customer_display?: InputMaybe<String_comparison_exp>;
  readonly customer_email?: InputMaybe<String_comparison_exp>;
  readonly interval_count?: InputMaybe<Int_comparison_exp>;
  readonly max_tradeable_term_length?: InputMaybe<Int_comparison_exp>;
  readonly mrr?: InputMaybe<bigint_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly revenue_per_period?: InputMaybe<bigint_comparison_exp>;
  readonly vendor_public_id?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type lists_tradeable_contracts_max_fields = {
  readonly __typename?: 'lists_tradeable_contracts_max_fields';
  readonly base_balance?: Maybe<Scalars['bigint']>;
  readonly base_payout?: Maybe<Scalars['Int']>;
  readonly base_rate_bps?: Maybe<Scalars['Int']>;
  readonly customer_display?: Maybe<Scalars['String']>;
  readonly customer_email?: Maybe<Scalars['String']>;
  readonly interval_count?: Maybe<Scalars['Int']>;
  readonly max_tradeable_term_length?: Maybe<Scalars['Int']>;
  readonly mrr?: Maybe<Scalars['bigint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly revenue_per_period?: Maybe<Scalars['bigint']>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type lists_tradeable_contracts_min_fields = {
  readonly __typename?: 'lists_tradeable_contracts_min_fields';
  readonly base_balance?: Maybe<Scalars['bigint']>;
  readonly base_payout?: Maybe<Scalars['Int']>;
  readonly base_rate_bps?: Maybe<Scalars['Int']>;
  readonly customer_display?: Maybe<Scalars['String']>;
  readonly customer_email?: Maybe<Scalars['String']>;
  readonly interval_count?: Maybe<Scalars['Int']>;
  readonly max_tradeable_term_length?: Maybe<Scalars['Int']>;
  readonly mrr?: Maybe<Scalars['bigint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly revenue_per_period?: Maybe<Scalars['bigint']>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "lists_tradeable_contracts". */
export type lists_tradeable_contracts_order_by = {
  readonly base_balance?: InputMaybe<order_by>;
  readonly base_payout?: InputMaybe<order_by>;
  readonly base_rate_bps?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly customer_display?: InputMaybe<order_by>;
  readonly customer_email?: InputMaybe<order_by>;
  readonly interval_count?: InputMaybe<order_by>;
  readonly max_tradeable_term_length?: InputMaybe<order_by>;
  readonly mrr?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly revenue_per_period?: InputMaybe<order_by>;
  readonly vendor_public_id?: InputMaybe<order_by>;
};

/** select columns of table "lists_tradeable_contracts" */
export enum lists_tradeable_contracts_select_column {
  /** column name */
  base_balance = 'base_balance',
  /** column name */
  base_payout = 'base_payout',
  /** column name */
  base_rate_bps = 'base_rate_bps',
  /** column name */
  currency = 'currency',
  /** column name */
  customer_display = 'customer_display',
  /** column name */
  customer_email = 'customer_email',
  /** column name */
  interval_count = 'interval_count',
  /** column name */
  max_tradeable_term_length = 'max_tradeable_term_length',
  /** column name */
  mrr = 'mrr',
  /** column name */
  public_id = 'public_id',
  /** column name */
  revenue_per_period = 'revenue_per_period',
  /** column name */
  vendor_public_id = 'vendor_public_id'
}

/** aggregate stddev on columns */
export type lists_tradeable_contracts_stddev_fields = {
  readonly __typename?: 'lists_tradeable_contracts_stddev_fields';
  readonly base_balance?: Maybe<Scalars['Float']>;
  readonly base_payout?: Maybe<Scalars['Float']>;
  readonly base_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly max_tradeable_term_length?: Maybe<Scalars['Float']>;
  readonly mrr?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type lists_tradeable_contracts_stddev_pop_fields = {
  readonly __typename?: 'lists_tradeable_contracts_stddev_pop_fields';
  readonly base_balance?: Maybe<Scalars['Float']>;
  readonly base_payout?: Maybe<Scalars['Float']>;
  readonly base_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly max_tradeable_term_length?: Maybe<Scalars['Float']>;
  readonly mrr?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type lists_tradeable_contracts_stddev_samp_fields = {
  readonly __typename?: 'lists_tradeable_contracts_stddev_samp_fields';
  readonly base_balance?: Maybe<Scalars['Float']>;
  readonly base_payout?: Maybe<Scalars['Float']>;
  readonly base_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly max_tradeable_term_length?: Maybe<Scalars['Float']>;
  readonly mrr?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type lists_tradeable_contracts_sum_fields = {
  readonly __typename?: 'lists_tradeable_contracts_sum_fields';
  readonly base_balance?: Maybe<Scalars['bigint']>;
  readonly base_payout?: Maybe<Scalars['Int']>;
  readonly base_rate_bps?: Maybe<Scalars['Int']>;
  readonly interval_count?: Maybe<Scalars['Int']>;
  readonly max_tradeable_term_length?: Maybe<Scalars['Int']>;
  readonly mrr?: Maybe<Scalars['bigint']>;
  readonly revenue_per_period?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type lists_tradeable_contracts_var_pop_fields = {
  readonly __typename?: 'lists_tradeable_contracts_var_pop_fields';
  readonly base_balance?: Maybe<Scalars['Float']>;
  readonly base_payout?: Maybe<Scalars['Float']>;
  readonly base_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly max_tradeable_term_length?: Maybe<Scalars['Float']>;
  readonly mrr?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type lists_tradeable_contracts_var_samp_fields = {
  readonly __typename?: 'lists_tradeable_contracts_var_samp_fields';
  readonly base_balance?: Maybe<Scalars['Float']>;
  readonly base_payout?: Maybe<Scalars['Float']>;
  readonly base_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly max_tradeable_term_length?: Maybe<Scalars['Float']>;
  readonly mrr?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type lists_tradeable_contracts_variance_fields = {
  readonly __typename?: 'lists_tradeable_contracts_variance_fields';
  readonly base_balance?: Maybe<Scalars['Float']>;
  readonly base_payout?: Maybe<Scalars['Float']>;
  readonly base_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly max_tradeable_term_length?: Maybe<Scalars['Float']>;
  readonly mrr?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "lists_trades" */
export type lists_trades = {
  readonly __typename?: 'lists_trades';
  readonly balance?: Maybe<Scalars['Int']>;
  readonly currency?: Maybe<Scalars['currency']>;
  readonly customer_display?: Maybe<Scalars['String']>;
  readonly customer_email?: Maybe<Scalars['String']>;
  readonly discount_rate_bps?: Maybe<Scalars['Int']>;
  readonly interval_count?: Maybe<Scalars['Int']>;
  readonly is_active?: Maybe<Scalars['Boolean']>;
  readonly is_paid_out?: Maybe<Scalars['Boolean']>;
  readonly mrr?: Maybe<Scalars['bigint']>;
  readonly payout?: Maybe<Scalars['Int']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly revenue_per_period?: Maybe<Scalars['bigint']>;
  readonly starts_date?: Maybe<Scalars['date']>;
  readonly term_length?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "lists_trades" */
export type lists_trades_aggregate = {
  readonly __typename?: 'lists_trades_aggregate';
  readonly aggregate?: Maybe<lists_trades_aggregate_fields>;
  readonly nodes: ReadonlyArray<lists_trades>;
};

/** aggregate fields of "lists_trades" */
export type lists_trades_aggregate_fields = {
  readonly __typename?: 'lists_trades_aggregate_fields';
  readonly avg?: Maybe<lists_trades_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<lists_trades_max_fields>;
  readonly min?: Maybe<lists_trades_min_fields>;
  readonly stddev?: Maybe<lists_trades_stddev_fields>;
  readonly stddev_pop?: Maybe<lists_trades_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<lists_trades_stddev_samp_fields>;
  readonly sum?: Maybe<lists_trades_sum_fields>;
  readonly var_pop?: Maybe<lists_trades_var_pop_fields>;
  readonly var_samp?: Maybe<lists_trades_var_samp_fields>;
  readonly variance?: Maybe<lists_trades_variance_fields>;
};


/** aggregate fields of "lists_trades" */
export type lists_trades_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<lists_trades_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type lists_trades_avg_fields = {
  readonly __typename?: 'lists_trades_avg_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly mrr?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
  readonly term_length?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lists_trades". All fields are combined with a logical 'AND'. */
export type lists_trades_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<lists_trades_bool_exp>>;
  readonly _not?: InputMaybe<lists_trades_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<lists_trades_bool_exp>>;
  readonly balance?: InputMaybe<Int_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly customer_display?: InputMaybe<String_comparison_exp>;
  readonly customer_email?: InputMaybe<String_comparison_exp>;
  readonly discount_rate_bps?: InputMaybe<Int_comparison_exp>;
  readonly interval_count?: InputMaybe<Int_comparison_exp>;
  readonly is_active?: InputMaybe<Boolean_comparison_exp>;
  readonly is_paid_out?: InputMaybe<Boolean_comparison_exp>;
  readonly mrr?: InputMaybe<bigint_comparison_exp>;
  readonly payout?: InputMaybe<Int_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly revenue_per_period?: InputMaybe<bigint_comparison_exp>;
  readonly starts_date?: InputMaybe<date_comparison_exp>;
  readonly term_length?: InputMaybe<Int_comparison_exp>;
};

/** aggregate max on columns */
export type lists_trades_max_fields = {
  readonly __typename?: 'lists_trades_max_fields';
  readonly balance?: Maybe<Scalars['Int']>;
  readonly customer_display?: Maybe<Scalars['String']>;
  readonly customer_email?: Maybe<Scalars['String']>;
  readonly discount_rate_bps?: Maybe<Scalars['Int']>;
  readonly interval_count?: Maybe<Scalars['Int']>;
  readonly mrr?: Maybe<Scalars['bigint']>;
  readonly payout?: Maybe<Scalars['Int']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly revenue_per_period?: Maybe<Scalars['bigint']>;
  readonly starts_date?: Maybe<Scalars['date']>;
  readonly term_length?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type lists_trades_min_fields = {
  readonly __typename?: 'lists_trades_min_fields';
  readonly balance?: Maybe<Scalars['Int']>;
  readonly customer_display?: Maybe<Scalars['String']>;
  readonly customer_email?: Maybe<Scalars['String']>;
  readonly discount_rate_bps?: Maybe<Scalars['Int']>;
  readonly interval_count?: Maybe<Scalars['Int']>;
  readonly mrr?: Maybe<Scalars['bigint']>;
  readonly payout?: Maybe<Scalars['Int']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly revenue_per_period?: Maybe<Scalars['bigint']>;
  readonly starts_date?: Maybe<Scalars['date']>;
  readonly term_length?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "lists_trades". */
export type lists_trades_order_by = {
  readonly balance?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly customer_display?: InputMaybe<order_by>;
  readonly customer_email?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly interval_count?: InputMaybe<order_by>;
  readonly is_active?: InputMaybe<order_by>;
  readonly is_paid_out?: InputMaybe<order_by>;
  readonly mrr?: InputMaybe<order_by>;
  readonly payout?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly revenue_per_period?: InputMaybe<order_by>;
  readonly starts_date?: InputMaybe<order_by>;
  readonly term_length?: InputMaybe<order_by>;
};

/** select columns of table "lists_trades" */
export enum lists_trades_select_column {
  /** column name */
  balance = 'balance',
  /** column name */
  currency = 'currency',
  /** column name */
  customer_display = 'customer_display',
  /** column name */
  customer_email = 'customer_email',
  /** column name */
  discount_rate_bps = 'discount_rate_bps',
  /** column name */
  interval_count = 'interval_count',
  /** column name */
  is_active = 'is_active',
  /** column name */
  is_paid_out = 'is_paid_out',
  /** column name */
  mrr = 'mrr',
  /** column name */
  payout = 'payout',
  /** column name */
  public_id = 'public_id',
  /** column name */
  revenue_per_period = 'revenue_per_period',
  /** column name */
  starts_date = 'starts_date',
  /** column name */
  term_length = 'term_length'
}

/** aggregate stddev on columns */
export type lists_trades_stddev_fields = {
  readonly __typename?: 'lists_trades_stddev_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly mrr?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
  readonly term_length?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type lists_trades_stddev_pop_fields = {
  readonly __typename?: 'lists_trades_stddev_pop_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly mrr?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
  readonly term_length?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type lists_trades_stddev_samp_fields = {
  readonly __typename?: 'lists_trades_stddev_samp_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly mrr?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
  readonly term_length?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type lists_trades_sum_fields = {
  readonly __typename?: 'lists_trades_sum_fields';
  readonly balance?: Maybe<Scalars['Int']>;
  readonly discount_rate_bps?: Maybe<Scalars['Int']>;
  readonly interval_count?: Maybe<Scalars['Int']>;
  readonly mrr?: Maybe<Scalars['bigint']>;
  readonly payout?: Maybe<Scalars['Int']>;
  readonly revenue_per_period?: Maybe<Scalars['bigint']>;
  readonly term_length?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type lists_trades_var_pop_fields = {
  readonly __typename?: 'lists_trades_var_pop_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly mrr?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
  readonly term_length?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type lists_trades_var_samp_fields = {
  readonly __typename?: 'lists_trades_var_samp_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly mrr?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
  readonly term_length?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type lists_trades_variance_fields = {
  readonly __typename?: 'lists_trades_variance_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly mrr?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
  readonly term_length?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "lists_users" */
export type lists_users = {
  readonly __typename?: 'lists_users';
  readonly email?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly role?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly status_if_enabled?: Maybe<Scalars['String']>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "lists_users" */
export type lists_users_aggregate = {
  readonly __typename?: 'lists_users_aggregate';
  readonly aggregate?: Maybe<lists_users_aggregate_fields>;
  readonly nodes: ReadonlyArray<lists_users>;
};

/** aggregate fields of "lists_users" */
export type lists_users_aggregate_fields = {
  readonly __typename?: 'lists_users_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<lists_users_max_fields>;
  readonly min?: Maybe<lists_users_min_fields>;
};


/** aggregate fields of "lists_users" */
export type lists_users_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<lists_users_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lists_users". All fields are combined with a logical 'AND'. */
export type lists_users_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<lists_users_bool_exp>>;
  readonly _not?: InputMaybe<lists_users_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<lists_users_bool_exp>>;
  readonly email?: InputMaybe<String_comparison_exp>;
  readonly name?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly role?: InputMaybe<String_comparison_exp>;
  readonly status?: InputMaybe<String_comparison_exp>;
  readonly status_if_enabled?: InputMaybe<String_comparison_exp>;
  readonly vendor_public_id?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type lists_users_max_fields = {
  readonly __typename?: 'lists_users_max_fields';
  readonly email?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly role?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly status_if_enabled?: Maybe<Scalars['String']>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type lists_users_min_fields = {
  readonly __typename?: 'lists_users_min_fields';
  readonly email?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly role?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly status_if_enabled?: Maybe<Scalars['String']>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "lists_users". */
export type lists_users_order_by = {
  readonly email?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly role?: InputMaybe<order_by>;
  readonly status?: InputMaybe<order_by>;
  readonly status_if_enabled?: InputMaybe<order_by>;
  readonly vendor_public_id?: InputMaybe<order_by>;
};

/** select columns of table "lists_users" */
export enum lists_users_select_column {
  /** column name */
  email = 'email',
  /** column name */
  name = 'name',
  /** column name */
  public_id = 'public_id',
  /** column name */
  role = 'role',
  /** column name */
  status = 'status',
  /** column name */
  status_if_enabled = 'status_if_enabled',
  /** column name */
  vendor_public_id = 'vendor_public_id'
}

/** columns and relationships of "lists_vendor_bank_transactions" */
export type lists_vendor_bank_transactions = {
  readonly __typename?: 'lists_vendor_bank_transactions';
  readonly account_display_name?: Maybe<Scalars['String']>;
  readonly account_public_id?: Maybe<Scalars['String']>;
  readonly amount?: Maybe<Scalars['bigint']>;
  readonly currency?: Maybe<Scalars['currency']>;
  readonly date?: Maybe<Scalars['date']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly is_pending?: Maybe<Scalars['Boolean']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly merchant_name?: Maybe<Scalars['String']>;
  readonly payment_processor_name?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "lists_vendor_bank_transactions" */
export type lists_vendor_bank_transactions_aggregate = {
  readonly __typename?: 'lists_vendor_bank_transactions_aggregate';
  readonly aggregate?: Maybe<lists_vendor_bank_transactions_aggregate_fields>;
  readonly nodes: ReadonlyArray<lists_vendor_bank_transactions>;
};

/** aggregate fields of "lists_vendor_bank_transactions" */
export type lists_vendor_bank_transactions_aggregate_fields = {
  readonly __typename?: 'lists_vendor_bank_transactions_aggregate_fields';
  readonly avg?: Maybe<lists_vendor_bank_transactions_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<lists_vendor_bank_transactions_max_fields>;
  readonly min?: Maybe<lists_vendor_bank_transactions_min_fields>;
  readonly stddev?: Maybe<lists_vendor_bank_transactions_stddev_fields>;
  readonly stddev_pop?: Maybe<lists_vendor_bank_transactions_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<lists_vendor_bank_transactions_stddev_samp_fields>;
  readonly sum?: Maybe<lists_vendor_bank_transactions_sum_fields>;
  readonly var_pop?: Maybe<lists_vendor_bank_transactions_var_pop_fields>;
  readonly var_samp?: Maybe<lists_vendor_bank_transactions_var_samp_fields>;
  readonly variance?: Maybe<lists_vendor_bank_transactions_variance_fields>;
};


/** aggregate fields of "lists_vendor_bank_transactions" */
export type lists_vendor_bank_transactions_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<lists_vendor_bank_transactions_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type lists_vendor_bank_transactions_avg_fields = {
  readonly __typename?: 'lists_vendor_bank_transactions_avg_fields';
  readonly amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lists_vendor_bank_transactions". All fields are combined with a logical 'AND'. */
export type lists_vendor_bank_transactions_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<lists_vendor_bank_transactions_bool_exp>>;
  readonly _not?: InputMaybe<lists_vendor_bank_transactions_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<lists_vendor_bank_transactions_bool_exp>>;
  readonly account_display_name?: InputMaybe<String_comparison_exp>;
  readonly account_public_id?: InputMaybe<String_comparison_exp>;
  readonly amount?: InputMaybe<bigint_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly date?: InputMaybe<date_comparison_exp>;
  readonly description?: InputMaybe<String_comparison_exp>;
  readonly is_pending?: InputMaybe<Boolean_comparison_exp>;
  readonly label?: InputMaybe<String_comparison_exp>;
  readonly merchant_name?: InputMaybe<String_comparison_exp>;
  readonly payment_processor_name?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly vendor_public_id?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type lists_vendor_bank_transactions_max_fields = {
  readonly __typename?: 'lists_vendor_bank_transactions_max_fields';
  readonly account_display_name?: Maybe<Scalars['String']>;
  readonly account_public_id?: Maybe<Scalars['String']>;
  readonly amount?: Maybe<Scalars['bigint']>;
  readonly date?: Maybe<Scalars['date']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly merchant_name?: Maybe<Scalars['String']>;
  readonly payment_processor_name?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type lists_vendor_bank_transactions_min_fields = {
  readonly __typename?: 'lists_vendor_bank_transactions_min_fields';
  readonly account_display_name?: Maybe<Scalars['String']>;
  readonly account_public_id?: Maybe<Scalars['String']>;
  readonly amount?: Maybe<Scalars['bigint']>;
  readonly date?: Maybe<Scalars['date']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly merchant_name?: Maybe<Scalars['String']>;
  readonly payment_processor_name?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "lists_vendor_bank_transactions". */
export type lists_vendor_bank_transactions_order_by = {
  readonly account_display_name?: InputMaybe<order_by>;
  readonly account_public_id?: InputMaybe<order_by>;
  readonly amount?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly date?: InputMaybe<order_by>;
  readonly description?: InputMaybe<order_by>;
  readonly is_pending?: InputMaybe<order_by>;
  readonly label?: InputMaybe<order_by>;
  readonly merchant_name?: InputMaybe<order_by>;
  readonly payment_processor_name?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly vendor_public_id?: InputMaybe<order_by>;
};

/** select columns of table "lists_vendor_bank_transactions" */
export enum lists_vendor_bank_transactions_select_column {
  /** column name */
  account_display_name = 'account_display_name',
  /** column name */
  account_public_id = 'account_public_id',
  /** column name */
  amount = 'amount',
  /** column name */
  currency = 'currency',
  /** column name */
  date = 'date',
  /** column name */
  description = 'description',
  /** column name */
  is_pending = 'is_pending',
  /** column name */
  label = 'label',
  /** column name */
  merchant_name = 'merchant_name',
  /** column name */
  payment_processor_name = 'payment_processor_name',
  /** column name */
  public_id = 'public_id',
  /** column name */
  vendor_public_id = 'vendor_public_id'
}

/** aggregate stddev on columns */
export type lists_vendor_bank_transactions_stddev_fields = {
  readonly __typename?: 'lists_vendor_bank_transactions_stddev_fields';
  readonly amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type lists_vendor_bank_transactions_stddev_pop_fields = {
  readonly __typename?: 'lists_vendor_bank_transactions_stddev_pop_fields';
  readonly amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type lists_vendor_bank_transactions_stddev_samp_fields = {
  readonly __typename?: 'lists_vendor_bank_transactions_stddev_samp_fields';
  readonly amount?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type lists_vendor_bank_transactions_sum_fields = {
  readonly __typename?: 'lists_vendor_bank_transactions_sum_fields';
  readonly amount?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type lists_vendor_bank_transactions_var_pop_fields = {
  readonly __typename?: 'lists_vendor_bank_transactions_var_pop_fields';
  readonly amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type lists_vendor_bank_transactions_var_samp_fields = {
  readonly __typename?: 'lists_vendor_bank_transactions_var_samp_fields';
  readonly amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type lists_vendor_bank_transactions_variance_fields = {
  readonly __typename?: 'lists_vendor_bank_transactions_variance_fields';
  readonly amount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "low_end_experiment_candidates" */
export type low_end_experiment_candidates = {
  readonly __typename?: 'low_end_experiment_candidates';
  readonly public_id: Scalars['String'];
  readonly tier: low_end_experiment_tier_enum_enum;
  /** An object relationship */
  readonly vendor: vendors;
};

/** Boolean expression to filter rows from the table "low_end_experiment_candidates". All fields are combined with a logical 'AND'. */
export type low_end_experiment_candidates_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<low_end_experiment_candidates_bool_exp>>;
  readonly _not?: InputMaybe<low_end_experiment_candidates_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<low_end_experiment_candidates_bool_exp>>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly tier?: InputMaybe<low_end_experiment_tier_enum_enum_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** Ordering options when selecting data from "low_end_experiment_candidates". */
export type low_end_experiment_candidates_order_by = {
  readonly public_id?: InputMaybe<order_by>;
  readonly tier?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "low_end_experiment_candidates" */
export enum low_end_experiment_candidates_select_column {
  /** column name */
  public_id = 'public_id',
  /** column name */
  tier = 'tier'
}

export enum low_end_experiment_tier_enum_enum {
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3'
}

/** Boolean expression to compare columns of type "low_end_experiment_tier_enum_enum". All fields are combined with logical 'AND'. */
export type low_end_experiment_tier_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<low_end_experiment_tier_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<low_end_experiment_tier_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<low_end_experiment_tier_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<low_end_experiment_tier_enum_enum>>;
};

/** columns and relationships of "manual_data_sources" */
export type manual_data_sources = {
  readonly __typename?: 'manual_data_sources';
  readonly label: Scalars['String'];
  readonly public_id: Scalars['String'];
  /** An array relationship */
  readonly uploaded_vendor_data: ReadonlyArray<uploaded_vendor_data>;
  /** An aggregate relationship */
  readonly uploaded_vendor_data_aggregate: uploaded_vendor_data_aggregate;
  /** An object relationship */
  readonly vendor: vendors;
};


/** columns and relationships of "manual_data_sources" */
export type manual_data_sourcesuploaded_vendor_dataArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<uploaded_vendor_data_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<uploaded_vendor_data_order_by>>;
  where?: InputMaybe<uploaded_vendor_data_bool_exp>;
};


/** columns and relationships of "manual_data_sources" */
export type manual_data_sourcesuploaded_vendor_data_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<uploaded_vendor_data_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<uploaded_vendor_data_order_by>>;
  where?: InputMaybe<uploaded_vendor_data_bool_exp>;
};

/** order by aggregate values of table "manual_data_sources" */
export type manual_data_sources_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<manual_data_sources_max_order_by>;
  readonly min?: InputMaybe<manual_data_sources_min_order_by>;
};

/** Boolean expression to filter rows from the table "manual_data_sources". All fields are combined with a logical 'AND'. */
export type manual_data_sources_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<manual_data_sources_bool_exp>>;
  readonly _not?: InputMaybe<manual_data_sources_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<manual_data_sources_bool_exp>>;
  readonly label?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly uploaded_vendor_data?: InputMaybe<uploaded_vendor_data_bool_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** order by max() on columns of table "manual_data_sources" */
export type manual_data_sources_max_order_by = {
  readonly label?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** order by min() on columns of table "manual_data_sources" */
export type manual_data_sources_min_order_by = {
  readonly label?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "manual_data_sources". */
export type manual_data_sources_order_by = {
  readonly label?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly uploaded_vendor_data_aggregate?: InputMaybe<uploaded_vendor_data_aggregate_order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "manual_data_sources" */
export enum manual_data_sources_select_column {
  /** column name */
  label = 'label',
  /** column name */
  public_id = 'public_id'
}

/** columns and relationships of "manual_ops" */
export type manual_ops = {
  readonly __typename?: 'manual_ops';
  readonly arguments: Scalars['jsonb'];
  /** An object relationship */
  readonly background_job?: Maybe<background_jobs>;
  readonly created_at: Scalars['timestamptz'];
  readonly error?: Maybe<Scalars['jsonb']>;
  readonly ip_address: Scalars['String'];
  readonly name: Scalars['String'];
  readonly notifications?: Maybe<Scalars['_text']>;
  readonly public_id: Scalars['String'];
  readonly status: Scalars['String'];
  readonly updated_at: Scalars['timestamptz'];
  /** An object relationship */
  readonly user: users;
};


/** columns and relationships of "manual_ops" */
export type manual_opsargumentsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "manual_ops" */
export type manual_opserrorArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "manual_ops". All fields are combined with a logical 'AND'. */
export type manual_ops_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<manual_ops_bool_exp>>;
  readonly _not?: InputMaybe<manual_ops_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<manual_ops_bool_exp>>;
  readonly arguments?: InputMaybe<jsonb_comparison_exp>;
  readonly background_job?: InputMaybe<background_jobs_bool_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly error?: InputMaybe<jsonb_comparison_exp>;
  readonly ip_address?: InputMaybe<String_comparison_exp>;
  readonly name?: InputMaybe<String_comparison_exp>;
  readonly notifications?: InputMaybe<_text_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly status?: InputMaybe<String_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly user?: InputMaybe<users_bool_exp>;
};

/** Ordering options when selecting data from "manual_ops". */
export type manual_ops_order_by = {
  readonly arguments?: InputMaybe<order_by>;
  readonly background_job?: InputMaybe<background_jobs_order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly error?: InputMaybe<order_by>;
  readonly ip_address?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly notifications?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly status?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly user?: InputMaybe<users_order_by>;
};

/** select columns of table "manual_ops" */
export enum manual_ops_select_column {
  /** column name */
  arguments = 'arguments',
  /** column name */
  created_at = 'created_at',
  /** column name */
  error = 'error',
  /** column name */
  ip_address = 'ip_address',
  /** column name */
  name = 'name',
  /** column name */
  notifications = 'notifications',
  /** column name */
  public_id = 'public_id',
  /** column name */
  status = 'status',
  /** column name */
  updated_at = 'updated_at'
}

export enum middesk_business_status_enum_enum {
  approved = 'approved',
  in_audit = 'in_audit',
  in_review = 'in_review',
  open = 'open',
  pending = 'pending',
  rejected = 'rejected'
}

/** Boolean expression to compare columns of type "middesk_business_status_enum_enum". All fields are combined with logical 'AND'. */
export type middesk_business_status_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<middesk_business_status_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<middesk_business_status_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<middesk_business_status_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<middesk_business_status_enum_enum>>;
};

/** mutation root */
export type mutation_root = {
  readonly __typename?: 'mutation_root';
  /** insert data into the table: "vendor_custom_fields" */
  readonly insert_vendor_custom_fields?: Maybe<vendor_custom_fields_mutation_response>;
  /** insert a single row into the table: "vendor_custom_fields" */
  readonly insert_vendor_custom_fields_one?: Maybe<vendor_custom_fields>;
  /** update data of the table: "accounts" */
  readonly update_accounts?: Maybe<accounts_mutation_response>;
  /** update data of the table: "background_jobs_settings" */
  readonly update_background_jobs_settings?: Maybe<background_jobs_settings_mutation_response>;
  /** update data of the table: "lists" */
  readonly update_lists?: Maybe<lists_mutation_response>;
  /** update data of the table: "ratings_concerns" */
  readonly update_ratings_concerns?: Maybe<ratings_concerns_mutation_response>;
  /** update data of the table: "transactions" */
  readonly update_transactions?: Maybe<transactions_mutation_response>;
  /** update data of the table: "users" */
  readonly update_users?: Maybe<users_mutation_response>;
  /** update data of the table: "vendor_custom_fields" */
  readonly update_vendor_custom_fields?: Maybe<vendor_custom_fields_mutation_response>;
  /** update data of the table: "vendors" */
  readonly update_vendors?: Maybe<vendors_mutation_response>;
};


/** mutation root */
export type mutation_rootinsert_vendor_custom_fieldsArgs = {
  objects: ReadonlyArray<vendor_custom_fields_insert_input>;
  on_conflict?: InputMaybe<vendor_custom_fields_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_vendor_custom_fields_oneArgs = {
  object: vendor_custom_fields_insert_input;
  on_conflict?: InputMaybe<vendor_custom_fields_on_conflict>;
};


/** mutation root */
export type mutation_rootupdate_accountsArgs = {
  _set?: InputMaybe<accounts_set_input>;
  where: accounts_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_background_jobs_settingsArgs = {
  _inc?: InputMaybe<background_jobs_settings_inc_input>;
  _set?: InputMaybe<background_jobs_settings_set_input>;
  where: background_jobs_settings_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_listsArgs = {
  _append?: InputMaybe<lists_append_input>;
  _delete_at_path?: InputMaybe<lists_delete_at_path_input>;
  _delete_elem?: InputMaybe<lists_delete_elem_input>;
  _delete_key?: InputMaybe<lists_delete_key_input>;
  _prepend?: InputMaybe<lists_prepend_input>;
  _set?: InputMaybe<lists_set_input>;
  where: lists_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_ratings_concernsArgs = {
  _set?: InputMaybe<ratings_concerns_set_input>;
  where: ratings_concerns_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_transactionsArgs = {
  _set?: InputMaybe<transactions_set_input>;
  where: transactions_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_usersArgs = {
  _set?: InputMaybe<users_set_input>;
  where: users_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_vendor_custom_fieldsArgs = {
  _append?: InputMaybe<vendor_custom_fields_append_input>;
  _delete_at_path?: InputMaybe<vendor_custom_fields_delete_at_path_input>;
  _delete_elem?: InputMaybe<vendor_custom_fields_delete_elem_input>;
  _delete_key?: InputMaybe<vendor_custom_fields_delete_key_input>;
  _prepend?: InputMaybe<vendor_custom_fields_prepend_input>;
  _set?: InputMaybe<vendor_custom_fields_set_input>;
  where: vendor_custom_fields_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_vendorsArgs = {
  _set?: InputMaybe<vendors_set_input>;
  where: vendors_bool_exp;
};

/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export type name_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['name']>;
  readonly _gt?: InputMaybe<Scalars['name']>;
  readonly _gte?: InputMaybe<Scalars['name']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['name']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['name']>;
  readonly _lte?: InputMaybe<Scalars['name']>;
  readonly _neq?: InputMaybe<Scalars['name']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['name']>>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type numeric_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['numeric']>;
  readonly _gt?: InputMaybe<Scalars['numeric']>;
  readonly _gte?: InputMaybe<Scalars['numeric']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['numeric']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['numeric']>;
  readonly _lte?: InputMaybe<Scalars['numeric']>;
  readonly _neq?: InputMaybe<Scalars['numeric']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['numeric']>>;
};

/** column ordering options */
export enum order_by {
  /** in ascending order, nulls last */
  asc = 'asc',
  /** in ascending order, nulls first */
  asc_nulls_first = 'asc_nulls_first',
  /** in ascending order, nulls last */
  asc_nulls_last = 'asc_nulls_last',
  /** in descending order, nulls first */
  desc = 'desc',
  /** in descending order, nulls first */
  desc_nulls_first = 'desc_nulls_first',
  /** in descending order, nulls last */
  desc_nulls_last = 'desc_nulls_last'
}

export enum payment_status_enum_enum {
  /** see modern treasury docs */
  approved = 'approved',
  /** see modern treasury docs */
  cancelled = 'cancelled',
  /** see modern treasury docs */
  completed = 'completed',
  /** see modern treasury docs */
  denied = 'denied',
  /** see modern treasury docs */
  failed = 'failed',
  /** see modern treasury docs */
  needs_approval = 'needs_approval',
  /** not yet sent to modern treasury */
  pending = 'pending',
  /** see modern treasury docs */
  processing = 'processing',
  /** see modern treasury docs */
  returned = 'returned',
  /** see modern treasury docs */
  reversed = 'reversed',
  /** see modern treasury docs */
  sent = 'sent'
}

/** Boolean expression to compare columns of type "payment_status_enum_enum". All fields are combined with logical 'AND'. */
export type payment_status_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<payment_status_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<payment_status_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<payment_status_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<payment_status_enum_enum>>;
};

export enum payment_subtype_enum_enum {
  /** Payment made by the vendor for the subscription */
  payment = 'payment',
  /** Payout FOR piping a subscription. */
  payout = 'payout',
  /** Rebate for when a subscription must be unpiped, i.e. a churn occured. */
  rebate = 'rebate',
  /** Payment created as part of an asset swap which is expected to be offset by another swap payment on a different asset */
  swap = 'swap'
}

/** Boolean expression to compare columns of type "payment_subtype_enum_enum". All fields are combined with logical 'AND'. */
export type payment_subtype_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<payment_subtype_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<payment_subtype_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<payment_subtype_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<payment_subtype_enum_enum>>;
};

export enum permission_enum_enum {
  /** Calculate a vendor's suggested decision from their financial data. */
  action__calculate_suggested_decision = 'action__calculate_suggested_decision',
  /** Enable read only impersonation of a vendor account. */
  action__impersonate_vendors = 'action__impersonate_vendors',
  /** Initiate or update vendor payments. */
  action__pay_vendor = 'action__pay_vendor',
  /** Pipe tradeable contracts. */
  action__trade_contracts = 'action__trade_contracts',
  /** Enable vendors to create, delete and modify api keys for the Connect API */
  resource__api_keys__modify = 'resource__api_keys__modify',
  /** Enable vendors to create, delete and edit sandbox users for testing the Connect API */
  resource__sandbox_user__modify = 'resource__sandbox_user__modify',
  /** Create a snapshot. */
  resource__snapshot__create = 'resource__snapshot__create',
  /** Mutate user invitations. */
  resource__user_invites__write = 'resource__user_invites__write',
  /** Enable/Disable a user. */
  resource__users__disable = 'resource__users__disable',
  /** Modify the roles a user has. */
  resource__users_roles__write = 'resource__users_roles__write',
  /** Change the company details of the vendor. */
  resource__vendor_company_details__write = 'resource__vendor_company_details__write',
  /** Modify a vendor's decision and rating. */
  resource__vendor_decision__write = 'resource__vendor_decision__write',
  /** Read vendor financial data. */
  resource__vendor_financial_data__read = 'resource__vendor_financial_data__read',
  /** Link/Unlink vendor integrations. */
  resource__vendor_integrations__write = 'resource__vendor_integrations__write',
  /** Modify a vendor's trading requirements. */
  resource__vendor_requirements__write = 'resource__vendor_requirements__write',
  /** Permission corresponding to the role. */
  role__vendor_administrator = 'role__vendor_administrator',
  /** Membership in the Engineering team. */
  team__engineering = 'team__engineering',
  /** Membership in the Finance team. */
  team__finance = 'team__finance',
  /** Membership in the Legal team. */
  team__legal = 'team__legal',
  /** Membership in the Sales team. */
  team__sales = 'team__sales'
}

/** Boolean expression to compare columns of type "permission_enum_enum". All fields are combined with logical 'AND'. */
export type permission_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<permission_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<permission_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<permission_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<permission_enum_enum>>;
};

/**
 * A pipe_agreements row represents a duration for which a revenue_stream was piped.
 *
 *
 * columns and relationships of "pipe_agreements"
 */
export type pipe_agreements = {
  readonly __typename?: 'pipe_agreements';
  readonly cached_current_balance: Scalars['Int'];
  readonly cached_current_intervals: Scalars['Int'];
  readonly cached_current_payout: Scalars['Int'];
  readonly created_at: Scalars['timestamp'];
  readonly currency: Scalars['currency'];
  /** An object relationship */
  readonly customer: customers;
  readonly discount_rate_bps: Scalars['Int'];
  readonly initial_balance: Scalars['Int'];
  readonly initial_intervals: Scalars['Int'];
  readonly initial_payout: Scalars['Int'];
  readonly intended_end_date: Scalars['date'];
  /** An array relationship */
  readonly investor_asset_receipts: ReadonlyArray<investor_asset_receipts>;
  /** An aggregate relationship */
  readonly investor_asset_receipts_aggregate: investor_asset_receipts_aggregate;
  readonly ip_address: Scalars['String'];
  readonly public_id: Scalars['String'];
  /** An object relationship */
  readonly revenue_stream: revenue_streams;
  /** An object relationship */
  readonly sell_order?: Maybe<sell_orders>;
  readonly starts_date: Scalars['date'];
  readonly stopped_at?: Maybe<Scalars['timestamp']>;
  readonly stopped_at_reason?: Maybe<Scalars['String']>;
  /** An array relationship */
  readonly subscription_payments: ReadonlyArray<subscription_payments>;
  /** An aggregate relationship */
  readonly subscription_payments_aggregate: subscription_payments_aggregate;
  readonly updated_at: Scalars['timestamp'];
  /** An object relationship */
  readonly user: users;
  /** An object relationship */
  readonly vendor: vendors;
};


/**
 * A pipe_agreements row represents a duration for which a revenue_stream was piped.
 *
 *
 * columns and relationships of "pipe_agreements"
 */
export type pipe_agreementsinvestor_asset_receiptsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<investor_asset_receipts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<investor_asset_receipts_order_by>>;
  where?: InputMaybe<investor_asset_receipts_bool_exp>;
};


/**
 * A pipe_agreements row represents a duration for which a revenue_stream was piped.
 *
 *
 * columns and relationships of "pipe_agreements"
 */
export type pipe_agreementsinvestor_asset_receipts_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<investor_asset_receipts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<investor_asset_receipts_order_by>>;
  where?: InputMaybe<investor_asset_receipts_bool_exp>;
};


/**
 * A pipe_agreements row represents a duration for which a revenue_stream was piped.
 *
 *
 * columns and relationships of "pipe_agreements"
 */
export type pipe_agreementssubscription_paymentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<subscription_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<subscription_payments_order_by>>;
  where?: InputMaybe<subscription_payments_bool_exp>;
};


/**
 * A pipe_agreements row represents a duration for which a revenue_stream was piped.
 *
 *
 * columns and relationships of "pipe_agreements"
 */
export type pipe_agreementssubscription_payments_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<subscription_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<subscription_payments_order_by>>;
  where?: InputMaybe<subscription_payments_bool_exp>;
};

/** aggregated selection of "pipe_agreements" */
export type pipe_agreements_aggregate = {
  readonly __typename?: 'pipe_agreements_aggregate';
  readonly aggregate?: Maybe<pipe_agreements_aggregate_fields>;
  readonly nodes: ReadonlyArray<pipe_agreements>;
};

/** aggregate fields of "pipe_agreements" */
export type pipe_agreements_aggregate_fields = {
  readonly __typename?: 'pipe_agreements_aggregate_fields';
  readonly avg?: Maybe<pipe_agreements_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<pipe_agreements_max_fields>;
  readonly min?: Maybe<pipe_agreements_min_fields>;
  readonly stddev?: Maybe<pipe_agreements_stddev_fields>;
  readonly stddev_pop?: Maybe<pipe_agreements_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<pipe_agreements_stddev_samp_fields>;
  readonly sum?: Maybe<pipe_agreements_sum_fields>;
  readonly var_pop?: Maybe<pipe_agreements_var_pop_fields>;
  readonly var_samp?: Maybe<pipe_agreements_var_samp_fields>;
  readonly variance?: Maybe<pipe_agreements_variance_fields>;
};


/** aggregate fields of "pipe_agreements" */
export type pipe_agreements_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<pipe_agreements_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pipe_agreements" */
export type pipe_agreements_aggregate_order_by = {
  readonly avg?: InputMaybe<pipe_agreements_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<pipe_agreements_max_order_by>;
  readonly min?: InputMaybe<pipe_agreements_min_order_by>;
  readonly stddev?: InputMaybe<pipe_agreements_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<pipe_agreements_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<pipe_agreements_stddev_samp_order_by>;
  readonly sum?: InputMaybe<pipe_agreements_sum_order_by>;
  readonly var_pop?: InputMaybe<pipe_agreements_var_pop_order_by>;
  readonly var_samp?: InputMaybe<pipe_agreements_var_samp_order_by>;
  readonly variance?: InputMaybe<pipe_agreements_variance_order_by>;
};

/** aggregate avg on columns */
export type pipe_agreements_avg_fields = {
  readonly __typename?: 'pipe_agreements_avg_fields';
  readonly cached_current_balance?: Maybe<Scalars['Float']>;
  readonly cached_current_intervals?: Maybe<Scalars['Float']>;
  readonly cached_current_payout?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly initial_balance?: Maybe<Scalars['Float']>;
  readonly initial_intervals?: Maybe<Scalars['Float']>;
  readonly initial_payout?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pipe_agreements" */
export type pipe_agreements_avg_order_by = {
  readonly cached_current_balance?: InputMaybe<order_by>;
  readonly cached_current_intervals?: InputMaybe<order_by>;
  readonly cached_current_payout?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly initial_balance?: InputMaybe<order_by>;
  readonly initial_intervals?: InputMaybe<order_by>;
  readonly initial_payout?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "pipe_agreements". All fields are combined with a logical 'AND'. */
export type pipe_agreements_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<pipe_agreements_bool_exp>>;
  readonly _not?: InputMaybe<pipe_agreements_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<pipe_agreements_bool_exp>>;
  readonly cached_current_balance?: InputMaybe<Int_comparison_exp>;
  readonly cached_current_intervals?: InputMaybe<Int_comparison_exp>;
  readonly cached_current_payout?: InputMaybe<Int_comparison_exp>;
  readonly created_at?: InputMaybe<timestamp_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly customer?: InputMaybe<customers_bool_exp>;
  readonly discount_rate_bps?: InputMaybe<Int_comparison_exp>;
  readonly initial_balance?: InputMaybe<Int_comparison_exp>;
  readonly initial_intervals?: InputMaybe<Int_comparison_exp>;
  readonly initial_payout?: InputMaybe<Int_comparison_exp>;
  readonly intended_end_date?: InputMaybe<date_comparison_exp>;
  readonly investor_asset_receipts?: InputMaybe<investor_asset_receipts_bool_exp>;
  readonly ip_address?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly revenue_stream?: InputMaybe<revenue_streams_bool_exp>;
  readonly sell_order?: InputMaybe<sell_orders_bool_exp>;
  readonly starts_date?: InputMaybe<date_comparison_exp>;
  readonly stopped_at?: InputMaybe<timestamp_comparison_exp>;
  readonly stopped_at_reason?: InputMaybe<String_comparison_exp>;
  readonly subscription_payments?: InputMaybe<subscription_payments_bool_exp>;
  readonly updated_at?: InputMaybe<timestamp_comparison_exp>;
  readonly user?: InputMaybe<users_bool_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** aggregate max on columns */
export type pipe_agreements_max_fields = {
  readonly __typename?: 'pipe_agreements_max_fields';
  readonly cached_current_balance?: Maybe<Scalars['Int']>;
  readonly cached_current_intervals?: Maybe<Scalars['Int']>;
  readonly cached_current_payout?: Maybe<Scalars['Int']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly discount_rate_bps?: Maybe<Scalars['Int']>;
  readonly initial_balance?: Maybe<Scalars['Int']>;
  readonly initial_intervals?: Maybe<Scalars['Int']>;
  readonly initial_payout?: Maybe<Scalars['Int']>;
  readonly intended_end_date?: Maybe<Scalars['date']>;
  readonly ip_address?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly starts_date?: Maybe<Scalars['date']>;
  readonly stopped_at?: Maybe<Scalars['timestamp']>;
  readonly stopped_at_reason?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "pipe_agreements" */
export type pipe_agreements_max_order_by = {
  readonly cached_current_balance?: InputMaybe<order_by>;
  readonly cached_current_intervals?: InputMaybe<order_by>;
  readonly cached_current_payout?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly initial_balance?: InputMaybe<order_by>;
  readonly initial_intervals?: InputMaybe<order_by>;
  readonly initial_payout?: InputMaybe<order_by>;
  readonly intended_end_date?: InputMaybe<order_by>;
  readonly ip_address?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly starts_date?: InputMaybe<order_by>;
  readonly stopped_at?: InputMaybe<order_by>;
  readonly stopped_at_reason?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type pipe_agreements_min_fields = {
  readonly __typename?: 'pipe_agreements_min_fields';
  readonly cached_current_balance?: Maybe<Scalars['Int']>;
  readonly cached_current_intervals?: Maybe<Scalars['Int']>;
  readonly cached_current_payout?: Maybe<Scalars['Int']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly discount_rate_bps?: Maybe<Scalars['Int']>;
  readonly initial_balance?: Maybe<Scalars['Int']>;
  readonly initial_intervals?: Maybe<Scalars['Int']>;
  readonly initial_payout?: Maybe<Scalars['Int']>;
  readonly intended_end_date?: Maybe<Scalars['date']>;
  readonly ip_address?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly starts_date?: Maybe<Scalars['date']>;
  readonly stopped_at?: Maybe<Scalars['timestamp']>;
  readonly stopped_at_reason?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "pipe_agreements" */
export type pipe_agreements_min_order_by = {
  readonly cached_current_balance?: InputMaybe<order_by>;
  readonly cached_current_intervals?: InputMaybe<order_by>;
  readonly cached_current_payout?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly initial_balance?: InputMaybe<order_by>;
  readonly initial_intervals?: InputMaybe<order_by>;
  readonly initial_payout?: InputMaybe<order_by>;
  readonly intended_end_date?: InputMaybe<order_by>;
  readonly ip_address?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly starts_date?: InputMaybe<order_by>;
  readonly stopped_at?: InputMaybe<order_by>;
  readonly stopped_at_reason?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "pipe_agreements". */
export type pipe_agreements_order_by = {
  readonly cached_current_balance?: InputMaybe<order_by>;
  readonly cached_current_intervals?: InputMaybe<order_by>;
  readonly cached_current_payout?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly customer?: InputMaybe<customers_order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly initial_balance?: InputMaybe<order_by>;
  readonly initial_intervals?: InputMaybe<order_by>;
  readonly initial_payout?: InputMaybe<order_by>;
  readonly intended_end_date?: InputMaybe<order_by>;
  readonly investor_asset_receipts_aggregate?: InputMaybe<investor_asset_receipts_aggregate_order_by>;
  readonly ip_address?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly revenue_stream?: InputMaybe<revenue_streams_order_by>;
  readonly sell_order?: InputMaybe<sell_orders_order_by>;
  readonly starts_date?: InputMaybe<order_by>;
  readonly stopped_at?: InputMaybe<order_by>;
  readonly stopped_at_reason?: InputMaybe<order_by>;
  readonly subscription_payments_aggregate?: InputMaybe<subscription_payments_aggregate_order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly user?: InputMaybe<users_order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "pipe_agreements" */
export enum pipe_agreements_select_column {
  /** column name */
  cached_current_balance = 'cached_current_balance',
  /** column name */
  cached_current_intervals = 'cached_current_intervals',
  /** column name */
  cached_current_payout = 'cached_current_payout',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  discount_rate_bps = 'discount_rate_bps',
  /** column name */
  initial_balance = 'initial_balance',
  /** column name */
  initial_intervals = 'initial_intervals',
  /** column name */
  initial_payout = 'initial_payout',
  /** column name */
  intended_end_date = 'intended_end_date',
  /** column name */
  ip_address = 'ip_address',
  /** column name */
  public_id = 'public_id',
  /** column name */
  starts_date = 'starts_date',
  /** column name */
  stopped_at = 'stopped_at',
  /** column name */
  stopped_at_reason = 'stopped_at_reason',
  /** column name */
  updated_at = 'updated_at'
}

/** aggregate stddev on columns */
export type pipe_agreements_stddev_fields = {
  readonly __typename?: 'pipe_agreements_stddev_fields';
  readonly cached_current_balance?: Maybe<Scalars['Float']>;
  readonly cached_current_intervals?: Maybe<Scalars['Float']>;
  readonly cached_current_payout?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly initial_balance?: Maybe<Scalars['Float']>;
  readonly initial_intervals?: Maybe<Scalars['Float']>;
  readonly initial_payout?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pipe_agreements" */
export type pipe_agreements_stddev_order_by = {
  readonly cached_current_balance?: InputMaybe<order_by>;
  readonly cached_current_intervals?: InputMaybe<order_by>;
  readonly cached_current_payout?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly initial_balance?: InputMaybe<order_by>;
  readonly initial_intervals?: InputMaybe<order_by>;
  readonly initial_payout?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type pipe_agreements_stddev_pop_fields = {
  readonly __typename?: 'pipe_agreements_stddev_pop_fields';
  readonly cached_current_balance?: Maybe<Scalars['Float']>;
  readonly cached_current_intervals?: Maybe<Scalars['Float']>;
  readonly cached_current_payout?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly initial_balance?: Maybe<Scalars['Float']>;
  readonly initial_intervals?: Maybe<Scalars['Float']>;
  readonly initial_payout?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "pipe_agreements" */
export type pipe_agreements_stddev_pop_order_by = {
  readonly cached_current_balance?: InputMaybe<order_by>;
  readonly cached_current_intervals?: InputMaybe<order_by>;
  readonly cached_current_payout?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly initial_balance?: InputMaybe<order_by>;
  readonly initial_intervals?: InputMaybe<order_by>;
  readonly initial_payout?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type pipe_agreements_stddev_samp_fields = {
  readonly __typename?: 'pipe_agreements_stddev_samp_fields';
  readonly cached_current_balance?: Maybe<Scalars['Float']>;
  readonly cached_current_intervals?: Maybe<Scalars['Float']>;
  readonly cached_current_payout?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly initial_balance?: Maybe<Scalars['Float']>;
  readonly initial_intervals?: Maybe<Scalars['Float']>;
  readonly initial_payout?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "pipe_agreements" */
export type pipe_agreements_stddev_samp_order_by = {
  readonly cached_current_balance?: InputMaybe<order_by>;
  readonly cached_current_intervals?: InputMaybe<order_by>;
  readonly cached_current_payout?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly initial_balance?: InputMaybe<order_by>;
  readonly initial_intervals?: InputMaybe<order_by>;
  readonly initial_payout?: InputMaybe<order_by>;
};

/** aggregate sum on columns */
export type pipe_agreements_sum_fields = {
  readonly __typename?: 'pipe_agreements_sum_fields';
  readonly cached_current_balance?: Maybe<Scalars['Int']>;
  readonly cached_current_intervals?: Maybe<Scalars['Int']>;
  readonly cached_current_payout?: Maybe<Scalars['Int']>;
  readonly discount_rate_bps?: Maybe<Scalars['Int']>;
  readonly initial_balance?: Maybe<Scalars['Int']>;
  readonly initial_intervals?: Maybe<Scalars['Int']>;
  readonly initial_payout?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pipe_agreements" */
export type pipe_agreements_sum_order_by = {
  readonly cached_current_balance?: InputMaybe<order_by>;
  readonly cached_current_intervals?: InputMaybe<order_by>;
  readonly cached_current_payout?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly initial_balance?: InputMaybe<order_by>;
  readonly initial_intervals?: InputMaybe<order_by>;
  readonly initial_payout?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type pipe_agreements_var_pop_fields = {
  readonly __typename?: 'pipe_agreements_var_pop_fields';
  readonly cached_current_balance?: Maybe<Scalars['Float']>;
  readonly cached_current_intervals?: Maybe<Scalars['Float']>;
  readonly cached_current_payout?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly initial_balance?: Maybe<Scalars['Float']>;
  readonly initial_intervals?: Maybe<Scalars['Float']>;
  readonly initial_payout?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "pipe_agreements" */
export type pipe_agreements_var_pop_order_by = {
  readonly cached_current_balance?: InputMaybe<order_by>;
  readonly cached_current_intervals?: InputMaybe<order_by>;
  readonly cached_current_payout?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly initial_balance?: InputMaybe<order_by>;
  readonly initial_intervals?: InputMaybe<order_by>;
  readonly initial_payout?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type pipe_agreements_var_samp_fields = {
  readonly __typename?: 'pipe_agreements_var_samp_fields';
  readonly cached_current_balance?: Maybe<Scalars['Float']>;
  readonly cached_current_intervals?: Maybe<Scalars['Float']>;
  readonly cached_current_payout?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly initial_balance?: Maybe<Scalars['Float']>;
  readonly initial_intervals?: Maybe<Scalars['Float']>;
  readonly initial_payout?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "pipe_agreements" */
export type pipe_agreements_var_samp_order_by = {
  readonly cached_current_balance?: InputMaybe<order_by>;
  readonly cached_current_intervals?: InputMaybe<order_by>;
  readonly cached_current_payout?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly initial_balance?: InputMaybe<order_by>;
  readonly initial_intervals?: InputMaybe<order_by>;
  readonly initial_payout?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type pipe_agreements_variance_fields = {
  readonly __typename?: 'pipe_agreements_variance_fields';
  readonly cached_current_balance?: Maybe<Scalars['Float']>;
  readonly cached_current_intervals?: Maybe<Scalars['Float']>;
  readonly cached_current_payout?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly initial_balance?: Maybe<Scalars['Float']>;
  readonly initial_intervals?: Maybe<Scalars['Float']>;
  readonly initial_payout?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pipe_agreements" */
export type pipe_agreements_variance_order_by = {
  readonly cached_current_balance?: InputMaybe<order_by>;
  readonly cached_current_intervals?: InputMaybe<order_by>;
  readonly cached_current_payout?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly initial_balance?: InputMaybe<order_by>;
  readonly initial_intervals?: InputMaybe<order_by>;
  readonly initial_payout?: InputMaybe<order_by>;
};

/** columns and relationships of "pipe_proposals" */
export type pipe_proposals = {
  readonly __typename?: 'pipe_proposals';
  readonly balance?: Maybe<Scalars['bigint']>;
  readonly currency?: Maybe<Scalars['currency']>;
  /** An object relationship */
  readonly customer?: Maybe<customers>;
  readonly customer_description_text?: Maybe<Scalars['String']>;
  readonly customer_domain?: Maybe<Scalars['String']>;
  readonly customer_email?: Maybe<Scalars['String']>;
  readonly customer_full_name?: Maybe<Scalars['String']>;
  readonly customer_public_id?: Maybe<Scalars['String']>;
  readonly discount_rate_bps?: Maybe<Scalars['Int']>;
  readonly interval_count?: Maybe<Scalars['Int']>;
  readonly interval_unit?: Maybe<Scalars['String']>;
  readonly months_to_pipe?: Maybe<Scalars['Int']>;
  readonly payout?: Maybe<Scalars['bigint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly revenue_per_period?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  readonly revenue_stream?: Maybe<revenue_streams>;
  readonly revenue_stream_source?: Maybe<Scalars['revenue_stream_source']>;
  readonly valid_until?: Maybe<Scalars['date']>;
  /** An object relationship */
  readonly vendor?: Maybe<vendors>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "pipe_proposals" */
export type pipe_proposals_aggregate = {
  readonly __typename?: 'pipe_proposals_aggregate';
  readonly aggregate?: Maybe<pipe_proposals_aggregate_fields>;
  readonly nodes: ReadonlyArray<pipe_proposals>;
};

/** aggregate fields of "pipe_proposals" */
export type pipe_proposals_aggregate_fields = {
  readonly __typename?: 'pipe_proposals_aggregate_fields';
  readonly avg?: Maybe<pipe_proposals_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<pipe_proposals_max_fields>;
  readonly min?: Maybe<pipe_proposals_min_fields>;
  readonly stddev?: Maybe<pipe_proposals_stddev_fields>;
  readonly stddev_pop?: Maybe<pipe_proposals_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<pipe_proposals_stddev_samp_fields>;
  readonly sum?: Maybe<pipe_proposals_sum_fields>;
  readonly var_pop?: Maybe<pipe_proposals_var_pop_fields>;
  readonly var_samp?: Maybe<pipe_proposals_var_samp_fields>;
  readonly variance?: Maybe<pipe_proposals_variance_fields>;
};


/** aggregate fields of "pipe_proposals" */
export type pipe_proposals_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<pipe_proposals_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pipe_proposals" */
export type pipe_proposals_aggregate_order_by = {
  readonly avg?: InputMaybe<pipe_proposals_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<pipe_proposals_max_order_by>;
  readonly min?: InputMaybe<pipe_proposals_min_order_by>;
  readonly stddev?: InputMaybe<pipe_proposals_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<pipe_proposals_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<pipe_proposals_stddev_samp_order_by>;
  readonly sum?: InputMaybe<pipe_proposals_sum_order_by>;
  readonly var_pop?: InputMaybe<pipe_proposals_var_pop_order_by>;
  readonly var_samp?: InputMaybe<pipe_proposals_var_samp_order_by>;
  readonly variance?: InputMaybe<pipe_proposals_variance_order_by>;
};

/** aggregate avg on columns */
export type pipe_proposals_avg_fields = {
  readonly __typename?: 'pipe_proposals_avg_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly months_to_pipe?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pipe_proposals" */
export type pipe_proposals_avg_order_by = {
  readonly balance?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly interval_count?: InputMaybe<order_by>;
  readonly months_to_pipe?: InputMaybe<order_by>;
  readonly payout?: InputMaybe<order_by>;
  readonly revenue_per_period?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "pipe_proposals". All fields are combined with a logical 'AND'. */
export type pipe_proposals_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<pipe_proposals_bool_exp>>;
  readonly _not?: InputMaybe<pipe_proposals_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<pipe_proposals_bool_exp>>;
  readonly balance?: InputMaybe<bigint_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly customer?: InputMaybe<customers_bool_exp>;
  readonly customer_description_text?: InputMaybe<String_comparison_exp>;
  readonly customer_domain?: InputMaybe<String_comparison_exp>;
  readonly customer_email?: InputMaybe<String_comparison_exp>;
  readonly customer_full_name?: InputMaybe<String_comparison_exp>;
  readonly customer_public_id?: InputMaybe<String_comparison_exp>;
  readonly discount_rate_bps?: InputMaybe<Int_comparison_exp>;
  readonly interval_count?: InputMaybe<Int_comparison_exp>;
  readonly interval_unit?: InputMaybe<String_comparison_exp>;
  readonly months_to_pipe?: InputMaybe<Int_comparison_exp>;
  readonly payout?: InputMaybe<bigint_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly revenue_per_period?: InputMaybe<bigint_comparison_exp>;
  readonly revenue_stream?: InputMaybe<revenue_streams_bool_exp>;
  readonly revenue_stream_source?: InputMaybe<revenue_stream_source_comparison_exp>;
  readonly valid_until?: InputMaybe<date_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
  readonly vendor_public_id?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type pipe_proposals_max_fields = {
  readonly __typename?: 'pipe_proposals_max_fields';
  readonly balance?: Maybe<Scalars['bigint']>;
  readonly customer_description_text?: Maybe<Scalars['String']>;
  readonly customer_domain?: Maybe<Scalars['String']>;
  readonly customer_email?: Maybe<Scalars['String']>;
  readonly customer_full_name?: Maybe<Scalars['String']>;
  readonly customer_public_id?: Maybe<Scalars['String']>;
  readonly discount_rate_bps?: Maybe<Scalars['Int']>;
  readonly interval_count?: Maybe<Scalars['Int']>;
  readonly interval_unit?: Maybe<Scalars['String']>;
  readonly months_to_pipe?: Maybe<Scalars['Int']>;
  readonly payout?: Maybe<Scalars['bigint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly revenue_per_period?: Maybe<Scalars['bigint']>;
  readonly valid_until?: Maybe<Scalars['date']>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "pipe_proposals" */
export type pipe_proposals_max_order_by = {
  readonly balance?: InputMaybe<order_by>;
  readonly customer_description_text?: InputMaybe<order_by>;
  readonly customer_domain?: InputMaybe<order_by>;
  readonly customer_email?: InputMaybe<order_by>;
  readonly customer_full_name?: InputMaybe<order_by>;
  readonly customer_public_id?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly interval_count?: InputMaybe<order_by>;
  readonly interval_unit?: InputMaybe<order_by>;
  readonly months_to_pipe?: InputMaybe<order_by>;
  readonly payout?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly revenue_per_period?: InputMaybe<order_by>;
  readonly valid_until?: InputMaybe<order_by>;
  readonly vendor_public_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type pipe_proposals_min_fields = {
  readonly __typename?: 'pipe_proposals_min_fields';
  readonly balance?: Maybe<Scalars['bigint']>;
  readonly customer_description_text?: Maybe<Scalars['String']>;
  readonly customer_domain?: Maybe<Scalars['String']>;
  readonly customer_email?: Maybe<Scalars['String']>;
  readonly customer_full_name?: Maybe<Scalars['String']>;
  readonly customer_public_id?: Maybe<Scalars['String']>;
  readonly discount_rate_bps?: Maybe<Scalars['Int']>;
  readonly interval_count?: Maybe<Scalars['Int']>;
  readonly interval_unit?: Maybe<Scalars['String']>;
  readonly months_to_pipe?: Maybe<Scalars['Int']>;
  readonly payout?: Maybe<Scalars['bigint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly revenue_per_period?: Maybe<Scalars['bigint']>;
  readonly valid_until?: Maybe<Scalars['date']>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "pipe_proposals" */
export type pipe_proposals_min_order_by = {
  readonly balance?: InputMaybe<order_by>;
  readonly customer_description_text?: InputMaybe<order_by>;
  readonly customer_domain?: InputMaybe<order_by>;
  readonly customer_email?: InputMaybe<order_by>;
  readonly customer_full_name?: InputMaybe<order_by>;
  readonly customer_public_id?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly interval_count?: InputMaybe<order_by>;
  readonly interval_unit?: InputMaybe<order_by>;
  readonly months_to_pipe?: InputMaybe<order_by>;
  readonly payout?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly revenue_per_period?: InputMaybe<order_by>;
  readonly valid_until?: InputMaybe<order_by>;
  readonly vendor_public_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "pipe_proposals". */
export type pipe_proposals_order_by = {
  readonly balance?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly customer?: InputMaybe<customers_order_by>;
  readonly customer_description_text?: InputMaybe<order_by>;
  readonly customer_domain?: InputMaybe<order_by>;
  readonly customer_email?: InputMaybe<order_by>;
  readonly customer_full_name?: InputMaybe<order_by>;
  readonly customer_public_id?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly interval_count?: InputMaybe<order_by>;
  readonly interval_unit?: InputMaybe<order_by>;
  readonly months_to_pipe?: InputMaybe<order_by>;
  readonly payout?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly revenue_per_period?: InputMaybe<order_by>;
  readonly revenue_stream?: InputMaybe<revenue_streams_order_by>;
  readonly revenue_stream_source?: InputMaybe<order_by>;
  readonly valid_until?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
  readonly vendor_public_id?: InputMaybe<order_by>;
};

/** select columns of table "pipe_proposals" */
export enum pipe_proposals_select_column {
  /** column name */
  balance = 'balance',
  /** column name */
  currency = 'currency',
  /** column name */
  customer_description_text = 'customer_description_text',
  /** column name */
  customer_domain = 'customer_domain',
  /** column name */
  customer_email = 'customer_email',
  /** column name */
  customer_full_name = 'customer_full_name',
  /** column name */
  customer_public_id = 'customer_public_id',
  /** column name */
  discount_rate_bps = 'discount_rate_bps',
  /** column name */
  interval_count = 'interval_count',
  /** column name */
  interval_unit = 'interval_unit',
  /** column name */
  months_to_pipe = 'months_to_pipe',
  /** column name */
  payout = 'payout',
  /** column name */
  public_id = 'public_id',
  /** column name */
  revenue_per_period = 'revenue_per_period',
  /** column name */
  revenue_stream_source = 'revenue_stream_source',
  /** column name */
  valid_until = 'valid_until',
  /** column name */
  vendor_public_id = 'vendor_public_id'
}

/** aggregate stddev on columns */
export type pipe_proposals_stddev_fields = {
  readonly __typename?: 'pipe_proposals_stddev_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly months_to_pipe?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pipe_proposals" */
export type pipe_proposals_stddev_order_by = {
  readonly balance?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly interval_count?: InputMaybe<order_by>;
  readonly months_to_pipe?: InputMaybe<order_by>;
  readonly payout?: InputMaybe<order_by>;
  readonly revenue_per_period?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type pipe_proposals_stddev_pop_fields = {
  readonly __typename?: 'pipe_proposals_stddev_pop_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly months_to_pipe?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "pipe_proposals" */
export type pipe_proposals_stddev_pop_order_by = {
  readonly balance?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly interval_count?: InputMaybe<order_by>;
  readonly months_to_pipe?: InputMaybe<order_by>;
  readonly payout?: InputMaybe<order_by>;
  readonly revenue_per_period?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type pipe_proposals_stddev_samp_fields = {
  readonly __typename?: 'pipe_proposals_stddev_samp_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly months_to_pipe?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "pipe_proposals" */
export type pipe_proposals_stddev_samp_order_by = {
  readonly balance?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly interval_count?: InputMaybe<order_by>;
  readonly months_to_pipe?: InputMaybe<order_by>;
  readonly payout?: InputMaybe<order_by>;
  readonly revenue_per_period?: InputMaybe<order_by>;
};

/** aggregate sum on columns */
export type pipe_proposals_sum_fields = {
  readonly __typename?: 'pipe_proposals_sum_fields';
  readonly balance?: Maybe<Scalars['bigint']>;
  readonly discount_rate_bps?: Maybe<Scalars['Int']>;
  readonly interval_count?: Maybe<Scalars['Int']>;
  readonly months_to_pipe?: Maybe<Scalars['Int']>;
  readonly payout?: Maybe<Scalars['bigint']>;
  readonly revenue_per_period?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "pipe_proposals" */
export type pipe_proposals_sum_order_by = {
  readonly balance?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly interval_count?: InputMaybe<order_by>;
  readonly months_to_pipe?: InputMaybe<order_by>;
  readonly payout?: InputMaybe<order_by>;
  readonly revenue_per_period?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type pipe_proposals_var_pop_fields = {
  readonly __typename?: 'pipe_proposals_var_pop_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly months_to_pipe?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "pipe_proposals" */
export type pipe_proposals_var_pop_order_by = {
  readonly balance?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly interval_count?: InputMaybe<order_by>;
  readonly months_to_pipe?: InputMaybe<order_by>;
  readonly payout?: InputMaybe<order_by>;
  readonly revenue_per_period?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type pipe_proposals_var_samp_fields = {
  readonly __typename?: 'pipe_proposals_var_samp_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly months_to_pipe?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "pipe_proposals" */
export type pipe_proposals_var_samp_order_by = {
  readonly balance?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly interval_count?: InputMaybe<order_by>;
  readonly months_to_pipe?: InputMaybe<order_by>;
  readonly payout?: InputMaybe<order_by>;
  readonly revenue_per_period?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type pipe_proposals_variance_fields = {
  readonly __typename?: 'pipe_proposals_variance_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly discount_rate_bps?: Maybe<Scalars['Float']>;
  readonly interval_count?: Maybe<Scalars['Float']>;
  readonly months_to_pipe?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pipe_proposals" */
export type pipe_proposals_variance_order_by = {
  readonly balance?: InputMaybe<order_by>;
  readonly discount_rate_bps?: InputMaybe<order_by>;
  readonly interval_count?: InputMaybe<order_by>;
  readonly months_to_pipe?: InputMaybe<order_by>;
  readonly payout?: InputMaybe<order_by>;
  readonly revenue_per_period?: InputMaybe<order_by>;
};

/** columns and relationships of "plaid_items" */
export type plaid_items = {
  readonly __typename?: 'plaid_items';
  /** An array relationship */
  readonly accounts: ReadonlyArray<accounts>;
  /** Base64-encoded 152x152 PNG. */
  readonly institution_logo?: Maybe<Scalars['String']>;
  readonly institution_name: Scalars['String'];
  readonly item_id: Scalars['String'];
  readonly public_id: Scalars['String'];
  /** An object relationship */
  readonly vendor: vendors;
};


/** columns and relationships of "plaid_items" */
export type plaid_itemsaccountsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<accounts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<accounts_order_by>>;
  where?: InputMaybe<accounts_bool_exp>;
};

/** aggregated selection of "plaid_items" */
export type plaid_items_aggregate = {
  readonly __typename?: 'plaid_items_aggregate';
  readonly aggregate?: Maybe<plaid_items_aggregate_fields>;
  readonly nodes: ReadonlyArray<plaid_items>;
};

/** aggregate fields of "plaid_items" */
export type plaid_items_aggregate_fields = {
  readonly __typename?: 'plaid_items_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<plaid_items_max_fields>;
  readonly min?: Maybe<plaid_items_min_fields>;
};


/** aggregate fields of "plaid_items" */
export type plaid_items_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<plaid_items_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "plaid_items" */
export type plaid_items_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<plaid_items_max_order_by>;
  readonly min?: InputMaybe<plaid_items_min_order_by>;
};

/** Boolean expression to filter rows from the table "plaid_items". All fields are combined with a logical 'AND'. */
export type plaid_items_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<plaid_items_bool_exp>>;
  readonly _not?: InputMaybe<plaid_items_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<plaid_items_bool_exp>>;
  readonly accounts?: InputMaybe<accounts_bool_exp>;
  readonly institution_logo?: InputMaybe<String_comparison_exp>;
  readonly institution_name?: InputMaybe<String_comparison_exp>;
  readonly item_id?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** aggregate max on columns */
export type plaid_items_max_fields = {
  readonly __typename?: 'plaid_items_max_fields';
  /** Base64-encoded 152x152 PNG. */
  readonly institution_logo?: Maybe<Scalars['String']>;
  readonly institution_name?: Maybe<Scalars['String']>;
  readonly item_id?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "plaid_items" */
export type plaid_items_max_order_by = {
  /** Base64-encoded 152x152 PNG. */
  readonly institution_logo?: InputMaybe<order_by>;
  readonly institution_name?: InputMaybe<order_by>;
  readonly item_id?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type plaid_items_min_fields = {
  readonly __typename?: 'plaid_items_min_fields';
  /** Base64-encoded 152x152 PNG. */
  readonly institution_logo?: Maybe<Scalars['String']>;
  readonly institution_name?: Maybe<Scalars['String']>;
  readonly item_id?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "plaid_items" */
export type plaid_items_min_order_by = {
  /** Base64-encoded 152x152 PNG. */
  readonly institution_logo?: InputMaybe<order_by>;
  readonly institution_name?: InputMaybe<order_by>;
  readonly item_id?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "plaid_items". */
export type plaid_items_order_by = {
  readonly accounts_aggregate?: InputMaybe<accounts_aggregate_order_by>;
  readonly institution_logo?: InputMaybe<order_by>;
  readonly institution_name?: InputMaybe<order_by>;
  readonly item_id?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "plaid_items" */
export enum plaid_items_select_column {
  /** column name */
  institution_logo = 'institution_logo',
  /** column name */
  institution_name = 'institution_name',
  /** column name */
  item_id = 'item_id',
  /** column name */
  public_id = 'public_id'
}

/** columns and relationships of "profit_and_loss_reports" */
export type profit_and_loss_reports = {
  readonly __typename?: 'profit_and_loss_reports';
  readonly currency: Scalars['String'];
  readonly from_date: Scalars['date'];
  readonly gross_profit: Scalars['bigint'];
  readonly net_operating_profit: Scalars['bigint'];
  readonly net_other_income: Scalars['bigint'];
  readonly net_profit: Scalars['bigint'];
  readonly public_id: Scalars['String'];
  readonly raw: Scalars['jsonb'];
  readonly to_date: Scalars['date'];
  readonly total_cost_of_sales: Scalars['bigint'];
  readonly total_expenses: Scalars['bigint'];
  readonly total_income: Scalars['bigint'];
  readonly total_other_expenses: Scalars['bigint'];
  readonly total_other_income: Scalars['bigint'];
};


/** columns and relationships of "profit_and_loss_reports" */
export type profit_and_loss_reportsrawArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "profit_and_loss_reports" */
export type profit_and_loss_reports_aggregate_order_by = {
  readonly avg?: InputMaybe<profit_and_loss_reports_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<profit_and_loss_reports_max_order_by>;
  readonly min?: InputMaybe<profit_and_loss_reports_min_order_by>;
  readonly stddev?: InputMaybe<profit_and_loss_reports_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<profit_and_loss_reports_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<profit_and_loss_reports_stddev_samp_order_by>;
  readonly sum?: InputMaybe<profit_and_loss_reports_sum_order_by>;
  readonly var_pop?: InputMaybe<profit_and_loss_reports_var_pop_order_by>;
  readonly var_samp?: InputMaybe<profit_and_loss_reports_var_samp_order_by>;
  readonly variance?: InputMaybe<profit_and_loss_reports_variance_order_by>;
};

/** order by avg() on columns of table "profit_and_loss_reports" */
export type profit_and_loss_reports_avg_order_by = {
  readonly gross_profit?: InputMaybe<order_by>;
  readonly net_operating_profit?: InputMaybe<order_by>;
  readonly net_other_income?: InputMaybe<order_by>;
  readonly net_profit?: InputMaybe<order_by>;
  readonly total_cost_of_sales?: InputMaybe<order_by>;
  readonly total_expenses?: InputMaybe<order_by>;
  readonly total_income?: InputMaybe<order_by>;
  readonly total_other_expenses?: InputMaybe<order_by>;
  readonly total_other_income?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "profit_and_loss_reports". All fields are combined with a logical 'AND'. */
export type profit_and_loss_reports_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<profit_and_loss_reports_bool_exp>>;
  readonly _not?: InputMaybe<profit_and_loss_reports_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<profit_and_loss_reports_bool_exp>>;
  readonly currency?: InputMaybe<String_comparison_exp>;
  readonly from_date?: InputMaybe<date_comparison_exp>;
  readonly gross_profit?: InputMaybe<bigint_comparison_exp>;
  readonly net_operating_profit?: InputMaybe<bigint_comparison_exp>;
  readonly net_other_income?: InputMaybe<bigint_comparison_exp>;
  readonly net_profit?: InputMaybe<bigint_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly raw?: InputMaybe<jsonb_comparison_exp>;
  readonly to_date?: InputMaybe<date_comparison_exp>;
  readonly total_cost_of_sales?: InputMaybe<bigint_comparison_exp>;
  readonly total_expenses?: InputMaybe<bigint_comparison_exp>;
  readonly total_income?: InputMaybe<bigint_comparison_exp>;
  readonly total_other_expenses?: InputMaybe<bigint_comparison_exp>;
  readonly total_other_income?: InputMaybe<bigint_comparison_exp>;
};

/** order by max() on columns of table "profit_and_loss_reports" */
export type profit_and_loss_reports_max_order_by = {
  readonly currency?: InputMaybe<order_by>;
  readonly from_date?: InputMaybe<order_by>;
  readonly gross_profit?: InputMaybe<order_by>;
  readonly net_operating_profit?: InputMaybe<order_by>;
  readonly net_other_income?: InputMaybe<order_by>;
  readonly net_profit?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly to_date?: InputMaybe<order_by>;
  readonly total_cost_of_sales?: InputMaybe<order_by>;
  readonly total_expenses?: InputMaybe<order_by>;
  readonly total_income?: InputMaybe<order_by>;
  readonly total_other_expenses?: InputMaybe<order_by>;
  readonly total_other_income?: InputMaybe<order_by>;
};

/** order by min() on columns of table "profit_and_loss_reports" */
export type profit_and_loss_reports_min_order_by = {
  readonly currency?: InputMaybe<order_by>;
  readonly from_date?: InputMaybe<order_by>;
  readonly gross_profit?: InputMaybe<order_by>;
  readonly net_operating_profit?: InputMaybe<order_by>;
  readonly net_other_income?: InputMaybe<order_by>;
  readonly net_profit?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly to_date?: InputMaybe<order_by>;
  readonly total_cost_of_sales?: InputMaybe<order_by>;
  readonly total_expenses?: InputMaybe<order_by>;
  readonly total_income?: InputMaybe<order_by>;
  readonly total_other_expenses?: InputMaybe<order_by>;
  readonly total_other_income?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "profit_and_loss_reports". */
export type profit_and_loss_reports_order_by = {
  readonly currency?: InputMaybe<order_by>;
  readonly from_date?: InputMaybe<order_by>;
  readonly gross_profit?: InputMaybe<order_by>;
  readonly net_operating_profit?: InputMaybe<order_by>;
  readonly net_other_income?: InputMaybe<order_by>;
  readonly net_profit?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly raw?: InputMaybe<order_by>;
  readonly to_date?: InputMaybe<order_by>;
  readonly total_cost_of_sales?: InputMaybe<order_by>;
  readonly total_expenses?: InputMaybe<order_by>;
  readonly total_income?: InputMaybe<order_by>;
  readonly total_other_expenses?: InputMaybe<order_by>;
  readonly total_other_income?: InputMaybe<order_by>;
};

/** select columns of table "profit_and_loss_reports" */
export enum profit_and_loss_reports_select_column {
  /** column name */
  currency = 'currency',
  /** column name */
  from_date = 'from_date',
  /** column name */
  gross_profit = 'gross_profit',
  /** column name */
  net_operating_profit = 'net_operating_profit',
  /** column name */
  net_other_income = 'net_other_income',
  /** column name */
  net_profit = 'net_profit',
  /** column name */
  public_id = 'public_id',
  /** column name */
  raw = 'raw',
  /** column name */
  to_date = 'to_date',
  /** column name */
  total_cost_of_sales = 'total_cost_of_sales',
  /** column name */
  total_expenses = 'total_expenses',
  /** column name */
  total_income = 'total_income',
  /** column name */
  total_other_expenses = 'total_other_expenses',
  /** column name */
  total_other_income = 'total_other_income'
}

/** order by stddev() on columns of table "profit_and_loss_reports" */
export type profit_and_loss_reports_stddev_order_by = {
  readonly gross_profit?: InputMaybe<order_by>;
  readonly net_operating_profit?: InputMaybe<order_by>;
  readonly net_other_income?: InputMaybe<order_by>;
  readonly net_profit?: InputMaybe<order_by>;
  readonly total_cost_of_sales?: InputMaybe<order_by>;
  readonly total_expenses?: InputMaybe<order_by>;
  readonly total_income?: InputMaybe<order_by>;
  readonly total_other_expenses?: InputMaybe<order_by>;
  readonly total_other_income?: InputMaybe<order_by>;
};

/** order by stddev_pop() on columns of table "profit_and_loss_reports" */
export type profit_and_loss_reports_stddev_pop_order_by = {
  readonly gross_profit?: InputMaybe<order_by>;
  readonly net_operating_profit?: InputMaybe<order_by>;
  readonly net_other_income?: InputMaybe<order_by>;
  readonly net_profit?: InputMaybe<order_by>;
  readonly total_cost_of_sales?: InputMaybe<order_by>;
  readonly total_expenses?: InputMaybe<order_by>;
  readonly total_income?: InputMaybe<order_by>;
  readonly total_other_expenses?: InputMaybe<order_by>;
  readonly total_other_income?: InputMaybe<order_by>;
};

/** order by stddev_samp() on columns of table "profit_and_loss_reports" */
export type profit_and_loss_reports_stddev_samp_order_by = {
  readonly gross_profit?: InputMaybe<order_by>;
  readonly net_operating_profit?: InputMaybe<order_by>;
  readonly net_other_income?: InputMaybe<order_by>;
  readonly net_profit?: InputMaybe<order_by>;
  readonly total_cost_of_sales?: InputMaybe<order_by>;
  readonly total_expenses?: InputMaybe<order_by>;
  readonly total_income?: InputMaybe<order_by>;
  readonly total_other_expenses?: InputMaybe<order_by>;
  readonly total_other_income?: InputMaybe<order_by>;
};

/** order by sum() on columns of table "profit_and_loss_reports" */
export type profit_and_loss_reports_sum_order_by = {
  readonly gross_profit?: InputMaybe<order_by>;
  readonly net_operating_profit?: InputMaybe<order_by>;
  readonly net_other_income?: InputMaybe<order_by>;
  readonly net_profit?: InputMaybe<order_by>;
  readonly total_cost_of_sales?: InputMaybe<order_by>;
  readonly total_expenses?: InputMaybe<order_by>;
  readonly total_income?: InputMaybe<order_by>;
  readonly total_other_expenses?: InputMaybe<order_by>;
  readonly total_other_income?: InputMaybe<order_by>;
};

/** order by var_pop() on columns of table "profit_and_loss_reports" */
export type profit_and_loss_reports_var_pop_order_by = {
  readonly gross_profit?: InputMaybe<order_by>;
  readonly net_operating_profit?: InputMaybe<order_by>;
  readonly net_other_income?: InputMaybe<order_by>;
  readonly net_profit?: InputMaybe<order_by>;
  readonly total_cost_of_sales?: InputMaybe<order_by>;
  readonly total_expenses?: InputMaybe<order_by>;
  readonly total_income?: InputMaybe<order_by>;
  readonly total_other_expenses?: InputMaybe<order_by>;
  readonly total_other_income?: InputMaybe<order_by>;
};

/** order by var_samp() on columns of table "profit_and_loss_reports" */
export type profit_and_loss_reports_var_samp_order_by = {
  readonly gross_profit?: InputMaybe<order_by>;
  readonly net_operating_profit?: InputMaybe<order_by>;
  readonly net_other_income?: InputMaybe<order_by>;
  readonly net_profit?: InputMaybe<order_by>;
  readonly total_cost_of_sales?: InputMaybe<order_by>;
  readonly total_expenses?: InputMaybe<order_by>;
  readonly total_income?: InputMaybe<order_by>;
  readonly total_other_expenses?: InputMaybe<order_by>;
  readonly total_other_income?: InputMaybe<order_by>;
};

/** order by variance() on columns of table "profit_and_loss_reports" */
export type profit_and_loss_reports_variance_order_by = {
  readonly gross_profit?: InputMaybe<order_by>;
  readonly net_operating_profit?: InputMaybe<order_by>;
  readonly net_other_income?: InputMaybe<order_by>;
  readonly net_profit?: InputMaybe<order_by>;
  readonly total_cost_of_sales?: InputMaybe<order_by>;
  readonly total_expenses?: InputMaybe<order_by>;
  readonly total_income?: InputMaybe<order_by>;
  readonly total_other_expenses?: InputMaybe<order_by>;
  readonly total_other_income?: InputMaybe<order_by>;
};

export type query_root = {
  readonly __typename?: 'query_root';
  /** fetch data from the table: "account_bank_balance_deltas" */
  readonly account_bank_balance_deltas: ReadonlyArray<account_bank_balance_deltas>;
  /** fetch aggregated fields from the table: "account_bank_balance_deltas" */
  readonly account_bank_balance_deltas_aggregate: account_bank_balance_deltas_aggregate;
  /** fetch data from the table: "accounting_metrics" */
  readonly accounting_metrics: ReadonlyArray<accounting_metrics>;
  /** An array relationship */
  readonly accounts: ReadonlyArray<accounts>;
  /** fetch data from the table: "activity_log" */
  readonly activity_log: ReadonlyArray<activity_log>;
  /** fetch data from the table: "activity_log_file_upload" */
  readonly activity_log_file_upload: ReadonlyArray<activity_log_file_upload>;
  /** fetch data from the table: "activity_log_integration_status" */
  readonly activity_log_integration_status: ReadonlyArray<activity_log_integration_status>;
  /** fetch data from the table: "activity_log_settings_update" */
  readonly activity_log_settings_update: ReadonlyArray<activity_log_settings_update>;
  /** fetch data from the table: "activity_log_traded_revenue_streams" */
  readonly activity_log_traded_revenue_streams: ReadonlyArray<activity_log_traded_revenue_streams>;
  /** fetch data from the table: "activity_log_underwriting_update" */
  readonly activity_log_underwriting_update: ReadonlyArray<activity_log_underwriting_update>;
  /** An array relationship */
  readonly api_key_allowed_ips: ReadonlyArray<api_key_allowed_ips>;
  /** fetch data from the table: "api_keys" */
  readonly api_keys: ReadonlyArray<api_keys>;
  /** fetch data from the table: "apple_data_sources" */
  readonly apple_data_sources: ReadonlyArray<apple_data_sources>;
  /** fetch data from the table: "asset_churn_batches" */
  readonly asset_churn_batches: ReadonlyArray<asset_churn_batches>;
  /** fetch data from the table: "asset_swaps" */
  readonly asset_swaps: ReadonlyArray<asset_swaps>;
  /** fetch data from the table: "background_jobs" */
  readonly background_jobs: ReadonlyArray<background_jobs>;
  /** fetch aggregated fields from the table: "background_jobs" */
  readonly background_jobs_aggregate: background_jobs_aggregate;
  /** fetch data from the table: "background_jobs_settings" */
  readonly background_jobs_settings: ReadonlyArray<background_jobs_settings>;
  /** An array relationship */
  readonly balance_sheet_reports: ReadonlyArray<balance_sheet_reports>;
  /** execute function "calculate_accounting_metrics" which returns "accounting_metrics" */
  readonly calculate_accounting_metrics: ReadonlyArray<accounting_metrics>;
  /** fetch data from the table: "chargebee_data_sources" */
  readonly chargebee_data_sources: ReadonlyArray<chargebee_data_sources>;
  /** fetch data from the table: "chargify_data_sources" */
  readonly chargify_data_sources: ReadonlyArray<chargify_data_sources>;
  /** fetch data from the table: "clearbit_companies" */
  readonly clearbit_companies: ReadonlyArray<clearbit_companies>;
  /** fetch data from the table: "codat_companies" */
  readonly codat_companies: ReadonlyArray<codat_companies>;
  /** fetch data from the table: "csv_tasks_for_vendors" */
  readonly csv_tasks_for_vendors: ReadonlyArray<csv_tasks_for_vendors>;
  /** An array relationship */
  readonly customers: ReadonlyArray<customers>;
  /** An aggregate relationship */
  readonly customers_aggregate: customers_aggregate;
  /** An array relationship */
  readonly data_sources: ReadonlyArray<data_sources>;
  /** fetch data from the table: "db_admin_indexes" */
  readonly db_admin_indexes: ReadonlyArray<db_admin_indexes>;
  /** fetch data from the table: "db_admin_queries" */
  readonly db_admin_queries: ReadonlyArray<db_admin_queries>;
  /** fetch data from the table: "db_admin_relations" */
  readonly db_admin_relations: ReadonlyArray<db_admin_relations>;
  /** fetch data from the table: "fingerprints" */
  readonly fingerprints: ReadonlyArray<fingerprints>;
  /** fetch aggregated fields from the table: "fingerprints" */
  readonly fingerprints_aggregate: fingerprints_aggregate;
  /** fetch data from the table: "gocardless_data_sources" */
  readonly gocardless_data_sources: ReadonlyArray<gocardless_data_sources>;
  /** fetch data from the table: "heron_annotated_transactions" */
  readonly heron_annotated_transactions: ReadonlyArray<heron_annotated_transactions>;
  /** fetch data from the table: "heron_categories" */
  readonly heron_categories: ReadonlyArray<heron_categories>;
  /** An array relationship */
  readonly historical_bank_data: ReadonlyArray<historical_bank_data>;
  /** An array relationship */
  readonly investor_asset_receipts: ReadonlyArray<investor_asset_receipts>;
  /** An aggregate relationship */
  readonly investor_asset_receipts_aggregate: investor_asset_receipts_aggregate;
  /** fetch data from the table: "investor_payment_batches" */
  readonly investor_payment_batches: ReadonlyArray<investor_payment_batches>;
  /** An array relationship */
  readonly investor_payments: ReadonlyArray<investor_payments>;
  /** fetch data from the table: "investor_portfolio_settings" */
  readonly investor_portfolio_settings: ReadonlyArray<investor_portfolio_settings>;
  /** fetch data from the table: "investors" */
  readonly investors: ReadonlyArray<investors>;
  /** An array relationship */
  readonly legal_entities: ReadonlyArray<legal_entities>;
  /** An aggregate relationship */
  readonly legal_entities_aggregate: legal_entities_aggregate;
  /** fetch data from the table: "limit_buy_orders" */
  readonly limit_buy_orders: ReadonlyArray<limit_buy_orders>;
  /** An array relationship */
  readonly lists: ReadonlyArray<lists>;
  /** An aggregate relationship */
  readonly lists_aggregate: lists_aggregate;
  /** fetch data from the table: "lists_investor_asset_receipts" */
  readonly lists_investor_asset_receipts: ReadonlyArray<lists_investor_asset_receipts>;
  /** fetch aggregated fields from the table: "lists_investor_asset_receipts" */
  readonly lists_investor_asset_receipts_aggregate: lists_investor_asset_receipts_aggregate;
  /** fetch data from the table: "lists_investor_payments" */
  readonly lists_investor_payments: ReadonlyArray<lists_investor_payments>;
  /** fetch aggregated fields from the table: "lists_investor_payments" */
  readonly lists_investor_payments_aggregate: lists_investor_payments_aggregate;
  /** fetch data from the table: "lists_investor_transfers" */
  readonly lists_investor_transfers: ReadonlyArray<lists_investor_transfers>;
  /** fetch aggregated fields from the table: "lists_investor_transfers" */
  readonly lists_investor_transfers_aggregate: lists_investor_transfers_aggregate;
  /** fetch data from the table: "lists_investor_users" */
  readonly lists_investor_users: ReadonlyArray<lists_investor_users>;
  /** fetch aggregated fields from the table: "lists_investor_users" */
  readonly lists_investor_users_aggregate: lists_investor_users_aggregate;
  /** fetch data from the table: "lists_limit_buy_orders" */
  readonly lists_limit_buy_orders: ReadonlyArray<lists_limit_buy_orders>;
  /** fetch aggregated fields from the table: "lists_limit_buy_orders" */
  readonly lists_limit_buy_orders_aggregate: lists_limit_buy_orders_aggregate;
  /** fetch data from the table: "lists_payment_tasks" */
  readonly lists_payment_tasks: ReadonlyArray<lists_payment_tasks>;
  /** fetch aggregated fields from the table: "lists_payment_tasks" */
  readonly lists_payment_tasks_aggregate: lists_payment_tasks_aggregate;
  /** fetch data from the table: "lists_payments" */
  readonly lists_payments: ReadonlyArray<lists_payments>;
  /** fetch aggregated fields from the table: "lists_payments" */
  readonly lists_payments_aggregate: lists_payments_aggregate;
  /** fetch data from the table: "lists_sub_payments" */
  readonly lists_sub_payments: ReadonlyArray<lists_sub_payments>;
  /** fetch aggregated fields from the table: "lists_sub_payments" */
  readonly lists_sub_payments_aggregate: lists_sub_payments_aggregate;
  /** fetch data from the table: "lists_tradeable_contracts" */
  readonly lists_tradeable_contracts: ReadonlyArray<lists_tradeable_contracts>;
  /** fetch aggregated fields from the table: "lists_tradeable_contracts" */
  readonly lists_tradeable_contracts_aggregate: lists_tradeable_contracts_aggregate;
  /** fetch data from the table: "lists_trades" */
  readonly lists_trades: ReadonlyArray<lists_trades>;
  /** fetch aggregated fields from the table: "lists_trades" */
  readonly lists_trades_aggregate: lists_trades_aggregate;
  /** fetch data from the table: "lists_users" */
  readonly lists_users: ReadonlyArray<lists_users>;
  /** fetch aggregated fields from the table: "lists_users" */
  readonly lists_users_aggregate: lists_users_aggregate;
  /** fetch data from the table: "lists_vendor_bank_transactions" */
  readonly lists_vendor_bank_transactions: ReadonlyArray<lists_vendor_bank_transactions>;
  /** fetch aggregated fields from the table: "lists_vendor_bank_transactions" */
  readonly lists_vendor_bank_transactions_aggregate: lists_vendor_bank_transactions_aggregate;
  /** fetch data from the table: "low_end_experiment_candidates" */
  readonly low_end_experiment_candidates: ReadonlyArray<low_end_experiment_candidates>;
  /** An array relationship */
  readonly manual_data_sources: ReadonlyArray<manual_data_sources>;
  /** fetch data from the table: "manual_ops" */
  readonly manual_ops: ReadonlyArray<manual_ops>;
  /** An array relationship */
  readonly pipe_agreements: ReadonlyArray<pipe_agreements>;
  /** An aggregate relationship */
  readonly pipe_agreements_aggregate: pipe_agreements_aggregate;
  /** An array relationship */
  readonly pipe_proposals: ReadonlyArray<pipe_proposals>;
  /** An aggregate relationship */
  readonly pipe_proposals_aggregate: pipe_proposals_aggregate;
  /** An array relationship */
  readonly plaid_items: ReadonlyArray<plaid_items>;
  /** An aggregate relationship */
  readonly plaid_items_aggregate: plaid_items_aggregate;
  /** An array relationship */
  readonly profit_and_loss_reports: ReadonlyArray<profit_and_loss_reports>;
  /** fetch data from the table: "ratings_churn_metrics_usd" */
  readonly ratings_churn_metrics_usd: ReadonlyArray<ratings_churn_metrics_usd>;
  /** fetch data from the table: "ratings_concern_evaluation_overrides" */
  readonly ratings_concern_evaluation_overrides: ReadonlyArray<ratings_concern_evaluation_overrides>;
  /** fetch data from the table: "ratings_concern_evaluations" */
  readonly ratings_concern_evaluations: ReadonlyArray<ratings_concern_evaluations>;
  /** fetch data from the table: "ratings_concerns" */
  readonly ratings_concerns: ReadonlyArray<ratings_concerns>;
  /** fetch data from the table: "ratings_historical_bank_balances_usd" */
  readonly ratings_historical_bank_balances_usd: ReadonlyArray<ratings_historical_bank_balances_usd>;
  /** fetch data from the table: "ratings_revenue_metrics_usd" */
  readonly ratings_revenue_metrics_usd: ReadonlyArray<ratings_revenue_metrics_usd>;
  /** fetch data from the table: "recurly_data_sources" */
  readonly recurly_data_sources: ReadonlyArray<recurly_data_sources>;
  /** An array relationship */
  readonly revenue_streams: ReadonlyArray<revenue_streams>;
  /** An aggregate relationship */
  readonly revenue_streams_aggregate: revenue_streams_aggregate;
  /** fetch data from the table: "roles" */
  readonly roles: ReadonlyArray<roles>;
  /** fetch data from the table: "roles_permissions" */
  readonly roles_permissions: ReadonlyArray<roles_permissions>;
  /** fetch data from the table: "sell_orders" */
  readonly sell_orders: ReadonlyArray<sell_orders>;
  /** fetch aggregated fields from the table: "sell_orders" */
  readonly sell_orders_aggregate: sell_orders_aggregate;
  /** fetch data from the table: "sell_orders_ownership" */
  readonly sell_orders_ownership: ReadonlyArray<sell_orders_ownership>;
  /** fetch aggregated fields from the table: "sell_orders_ownership" */
  readonly sell_orders_ownership_aggregate: sell_orders_ownership_aggregate;
  /** fetch data from the table: "snapshot_historical_bank_data_view" */
  readonly snapshot_historical_bank_data_view: ReadonlyArray<snapshot_historical_bank_data_view>;
  /** fetch data from the table: "snapshot_overrides" */
  readonly snapshot_overrides: ReadonlyArray<snapshot_overrides>;
  /** An array relationship */
  readonly snapshots: ReadonlyArray<snapshots>;
  /** fetch data from the table: "stripe_data_sources" */
  readonly stripe_data_sources: ReadonlyArray<stripe_data_sources>;
  /** An array relationship */
  readonly subscription_payments: ReadonlyArray<subscription_payments>;
  /** An aggregate relationship */
  readonly subscription_payments_aggregate: subscription_payments_aggregate;
  /** fetch data from the table: "trade_volume_history" */
  readonly trade_volume_history: ReadonlyArray<trade_volume_history>;
  /** An array relationship */
  readonly transactions: ReadonlyArray<transactions>;
  /** An array relationship */
  readonly uploaded_vendor_data: ReadonlyArray<uploaded_vendor_data>;
  /** An aggregate relationship */
  readonly uploaded_vendor_data_aggregate: uploaded_vendor_data_aggregate;
  /** An array relationship */
  readonly users: ReadonlyArray<users>;
  /** An aggregate relationship */
  readonly users_aggregate: users_aggregate;
  /** fetch data from the table: "users_permissions" */
  readonly users_permissions: ReadonlyArray<users_permissions>;
  /** An array relationship */
  readonly users_roles: ReadonlyArray<users_roles>;
  /** An array relationship */
  readonly vendor_active_ratings: ReadonlyArray<vendor_active_ratings>;
  /** An array relationship */
  readonly vendor_bank_balances: ReadonlyArray<vendor_bank_balances>;
  /** fetch data from the table: "vendor_current_sales_funnel_stage" */
  readonly vendor_current_sales_funnel_stage: ReadonlyArray<vendor_current_sales_funnel_stage>;
  /** An array relationship */
  readonly vendor_custom_fields: ReadonlyArray<vendor_custom_fields>;
  /** fetch data from the table: "vendor_decisions" */
  readonly vendor_decisions: ReadonlyArray<vendor_decisions>;
  /** fetch data from the table: "vendor_events_summary" */
  readonly vendor_events_summary: ReadonlyArray<vendor_events_summary>;
  /** fetch data from the table: "vendor_financials" */
  readonly vendor_financials: ReadonlyArray<vendor_financials>;
  /** fetch aggregated fields from the table: "vendor_financials" */
  readonly vendor_financials_aggregate: vendor_financials_aggregate;
  /** fetch data from the table: "vendor_financials_mrr_mom" */
  readonly vendor_financials_mrr_mom: ReadonlyArray<vendor_financials_mrr_mom>;
  /** An array relationship */
  readonly vendor_metric_jobs: ReadonlyArray<vendor_metric_jobs>;
  /** An array relationship */
  readonly vendor_metrics: ReadonlyArray<vendor_metrics>;
  /** fetch data from the table: "vendor_metrics_latest" */
  readonly vendor_metrics_latest: ReadonlyArray<vendor_metrics_latest>;
  /** fetch data from the table: "vendor_payment_status" */
  readonly vendor_payment_status: ReadonlyArray<vendor_payment_status>;
  /** fetch aggregated fields from the table: "vendor_payment_status" */
  readonly vendor_payment_status_aggregate: vendor_payment_status_aggregate;
  /** An array relationship */
  readonly vendor_payments: ReadonlyArray<vendor_payments>;
  /** An aggregate relationship */
  readonly vendor_payments_aggregate: vendor_payments_aggregate;
  /** fetch data from the table: "vendor_payments_enriched" */
  readonly vendor_payments_enriched: ReadonlyArray<vendor_payments_enriched>;
  /** fetch data from the table: "vendor_payments_extended" */
  readonly vendor_payments_extended: ReadonlyArray<vendor_payments_extended>;
  /** fetch data from the table: "vendor_product_waitlist" */
  readonly vendor_product_waitlist: ReadonlyArray<vendor_product_waitlist>;
  /** fetch aggregated fields from the table: "vendor_product_waitlist" */
  readonly vendor_product_waitlist_aggregate: vendor_product_waitlist_aggregate;
  /** fetch data from the table: "vendor_ratings" */
  readonly vendor_ratings: ReadonlyArray<vendor_ratings>;
  /** An array relationship */
  readonly vendor_ratings_metadata: ReadonlyArray<vendor_ratings_metadata>;
  /** An array relationship */
  readonly vendor_supported_currencies: ReadonlyArray<vendor_supported_currencies>;
  /** fetch data from the table: "vendor_trades" */
  readonly vendor_trades: ReadonlyArray<vendor_trades>;
  /** An array relationship */
  readonly vendor_treasury_accounts: ReadonlyArray<vendor_treasury_accounts>;
  /** fetch data from the table: "vendor_treasury_balances" */
  readonly vendor_treasury_balances: ReadonlyArray<vendor_treasury_balances>;
  /** An array relationship */
  readonly vendor_treasury_daily_cumulative_balances: ReadonlyArray<vendor_treasury_daily_cumulative_balances>;
  /** An array relationship */
  readonly vendor_treasury_transactions: ReadonlyArray<vendor_treasury_transactions>;
  /** fetch data from the table: "vendor_visit_summary" */
  readonly vendor_visit_summary: ReadonlyArray<vendor_visit_summary>;
  /** An array relationship */
  readonly vendors: ReadonlyArray<vendors>;
  /** An aggregate relationship */
  readonly vendors_aggregate: vendors_aggregate;
  /** An array relationship */
  readonly visits: ReadonlyArray<visits>;
  /** An aggregate relationship */
  readonly visits_aggregate: visits_aggregate;
};


export type query_rootaccount_bank_balance_deltasArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<account_bank_balance_deltas_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<account_bank_balance_deltas_order_by>>;
  where?: InputMaybe<account_bank_balance_deltas_bool_exp>;
};


export type query_rootaccount_bank_balance_deltas_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<account_bank_balance_deltas_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<account_bank_balance_deltas_order_by>>;
  where?: InputMaybe<account_bank_balance_deltas_bool_exp>;
};


export type query_rootaccounting_metricsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<accounting_metrics_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<accounting_metrics_order_by>>;
  where?: InputMaybe<accounting_metrics_bool_exp>;
};


export type query_rootaccountsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<accounts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<accounts_order_by>>;
  where?: InputMaybe<accounts_bool_exp>;
};


export type query_rootactivity_logArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<activity_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<activity_log_order_by>>;
  where?: InputMaybe<activity_log_bool_exp>;
};


export type query_rootactivity_log_file_uploadArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<activity_log_file_upload_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<activity_log_file_upload_order_by>>;
  where?: InputMaybe<activity_log_file_upload_bool_exp>;
};


export type query_rootactivity_log_integration_statusArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<activity_log_integration_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<activity_log_integration_status_order_by>>;
  where?: InputMaybe<activity_log_integration_status_bool_exp>;
};


export type query_rootactivity_log_settings_updateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<activity_log_settings_update_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<activity_log_settings_update_order_by>>;
  where?: InputMaybe<activity_log_settings_update_bool_exp>;
};


export type query_rootactivity_log_traded_revenue_streamsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<activity_log_traded_revenue_streams_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<activity_log_traded_revenue_streams_order_by>>;
  where?: InputMaybe<activity_log_traded_revenue_streams_bool_exp>;
};


export type query_rootactivity_log_underwriting_updateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<activity_log_underwriting_update_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<activity_log_underwriting_update_order_by>>;
  where?: InputMaybe<activity_log_underwriting_update_bool_exp>;
};


export type query_rootapi_key_allowed_ipsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<api_key_allowed_ips_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<api_key_allowed_ips_order_by>>;
  where?: InputMaybe<api_key_allowed_ips_bool_exp>;
};


export type query_rootapi_keysArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<api_keys_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<api_keys_order_by>>;
  where?: InputMaybe<api_keys_bool_exp>;
};


export type query_rootapple_data_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<apple_data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<apple_data_sources_order_by>>;
  where?: InputMaybe<apple_data_sources_bool_exp>;
};


export type query_rootasset_churn_batchesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<asset_churn_batches_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<asset_churn_batches_order_by>>;
  where?: InputMaybe<asset_churn_batches_bool_exp>;
};


export type query_rootasset_swapsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<asset_swaps_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<asset_swaps_order_by>>;
  where?: InputMaybe<asset_swaps_bool_exp>;
};


export type query_rootbackground_jobsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<background_jobs_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<background_jobs_order_by>>;
  where?: InputMaybe<background_jobs_bool_exp>;
};


export type query_rootbackground_jobs_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<background_jobs_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<background_jobs_order_by>>;
  where?: InputMaybe<background_jobs_bool_exp>;
};


export type query_rootbackground_jobs_settingsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<background_jobs_settings_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<background_jobs_settings_order_by>>;
  where?: InputMaybe<background_jobs_settings_bool_exp>;
};


export type query_rootbalance_sheet_reportsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<balance_sheet_reports_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<balance_sheet_reports_order_by>>;
  where?: InputMaybe<balance_sheet_reports_bool_exp>;
};


export type query_rootcalculate_accounting_metricsArgs = {
  args: calculate_accounting_metrics_args;
  distinct_on?: InputMaybe<ReadonlyArray<accounting_metrics_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<accounting_metrics_order_by>>;
  where?: InputMaybe<accounting_metrics_bool_exp>;
};


export type query_rootchargebee_data_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<chargebee_data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<chargebee_data_sources_order_by>>;
  where?: InputMaybe<chargebee_data_sources_bool_exp>;
};


export type query_rootchargify_data_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<chargify_data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<chargify_data_sources_order_by>>;
  where?: InputMaybe<chargify_data_sources_bool_exp>;
};


export type query_rootclearbit_companiesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<clearbit_companies_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<clearbit_companies_order_by>>;
  where?: InputMaybe<clearbit_companies_bool_exp>;
};


export type query_rootcodat_companiesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<codat_companies_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<codat_companies_order_by>>;
  where?: InputMaybe<codat_companies_bool_exp>;
};


export type query_rootcsv_tasks_for_vendorsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<csv_tasks_for_vendors_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<csv_tasks_for_vendors_order_by>>;
  where?: InputMaybe<csv_tasks_for_vendors_bool_exp>;
};


export type query_rootcustomersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<customers_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<customers_order_by>>;
  where?: InputMaybe<customers_bool_exp>;
};


export type query_rootcustomers_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<customers_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<customers_order_by>>;
  where?: InputMaybe<customers_bool_exp>;
};


export type query_rootdata_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<data_sources_order_by>>;
  where?: InputMaybe<data_sources_bool_exp>;
};


export type query_rootdb_admin_indexesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<db_admin_indexes_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<db_admin_indexes_order_by>>;
  where?: InputMaybe<db_admin_indexes_bool_exp>;
};


export type query_rootdb_admin_queriesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<db_admin_queries_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<db_admin_queries_order_by>>;
  where?: InputMaybe<db_admin_queries_bool_exp>;
};


export type query_rootdb_admin_relationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<db_admin_relations_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<db_admin_relations_order_by>>;
  where?: InputMaybe<db_admin_relations_bool_exp>;
};


export type query_rootfingerprintsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<fingerprints_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<fingerprints_order_by>>;
  where?: InputMaybe<fingerprints_bool_exp>;
};


export type query_rootfingerprints_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<fingerprints_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<fingerprints_order_by>>;
  where?: InputMaybe<fingerprints_bool_exp>;
};


export type query_rootgocardless_data_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<gocardless_data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<gocardless_data_sources_order_by>>;
  where?: InputMaybe<gocardless_data_sources_bool_exp>;
};


export type query_rootheron_annotated_transactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<heron_annotated_transactions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<heron_annotated_transactions_order_by>>;
  where?: InputMaybe<heron_annotated_transactions_bool_exp>;
};


export type query_rootheron_categoriesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<heron_categories_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<heron_categories_order_by>>;
  where?: InputMaybe<heron_categories_bool_exp>;
};


export type query_roothistorical_bank_dataArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<historical_bank_data_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<historical_bank_data_order_by>>;
  where?: InputMaybe<historical_bank_data_bool_exp>;
};


export type query_rootinvestor_asset_receiptsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<investor_asset_receipts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<investor_asset_receipts_order_by>>;
  where?: InputMaybe<investor_asset_receipts_bool_exp>;
};


export type query_rootinvestor_asset_receipts_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<investor_asset_receipts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<investor_asset_receipts_order_by>>;
  where?: InputMaybe<investor_asset_receipts_bool_exp>;
};


export type query_rootinvestor_payment_batchesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<investor_payment_batches_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<investor_payment_batches_order_by>>;
  where?: InputMaybe<investor_payment_batches_bool_exp>;
};


export type query_rootinvestor_paymentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<investor_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<investor_payments_order_by>>;
  where?: InputMaybe<investor_payments_bool_exp>;
};


export type query_rootinvestor_portfolio_settingsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<investor_portfolio_settings_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<investor_portfolio_settings_order_by>>;
  where?: InputMaybe<investor_portfolio_settings_bool_exp>;
};


export type query_rootinvestorsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<investors_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<investors_order_by>>;
  where?: InputMaybe<investors_bool_exp>;
};


export type query_rootlegal_entitiesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<legal_entities_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<legal_entities_order_by>>;
  where?: InputMaybe<legal_entities_bool_exp>;
};


export type query_rootlegal_entities_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<legal_entities_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<legal_entities_order_by>>;
  where?: InputMaybe<legal_entities_bool_exp>;
};


export type query_rootlimit_buy_ordersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<limit_buy_orders_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<limit_buy_orders_order_by>>;
  where?: InputMaybe<limit_buy_orders_bool_exp>;
};


export type query_rootlistsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_order_by>>;
  where?: InputMaybe<lists_bool_exp>;
};


export type query_rootlists_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_order_by>>;
  where?: InputMaybe<lists_bool_exp>;
};


export type query_rootlists_investor_asset_receiptsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_investor_asset_receipts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_investor_asset_receipts_order_by>>;
  where?: InputMaybe<lists_investor_asset_receipts_bool_exp>;
};


export type query_rootlists_investor_asset_receipts_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_investor_asset_receipts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_investor_asset_receipts_order_by>>;
  where?: InputMaybe<lists_investor_asset_receipts_bool_exp>;
};


export type query_rootlists_investor_paymentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_investor_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_investor_payments_order_by>>;
  where?: InputMaybe<lists_investor_payments_bool_exp>;
};


export type query_rootlists_investor_payments_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_investor_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_investor_payments_order_by>>;
  where?: InputMaybe<lists_investor_payments_bool_exp>;
};


export type query_rootlists_investor_transfersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_investor_transfers_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_investor_transfers_order_by>>;
  where?: InputMaybe<lists_investor_transfers_bool_exp>;
};


export type query_rootlists_investor_transfers_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_investor_transfers_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_investor_transfers_order_by>>;
  where?: InputMaybe<lists_investor_transfers_bool_exp>;
};


export type query_rootlists_investor_usersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_investor_users_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_investor_users_order_by>>;
  where?: InputMaybe<lists_investor_users_bool_exp>;
};


export type query_rootlists_investor_users_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_investor_users_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_investor_users_order_by>>;
  where?: InputMaybe<lists_investor_users_bool_exp>;
};


export type query_rootlists_limit_buy_ordersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_limit_buy_orders_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_limit_buy_orders_order_by>>;
  where?: InputMaybe<lists_limit_buy_orders_bool_exp>;
};


export type query_rootlists_limit_buy_orders_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_limit_buy_orders_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_limit_buy_orders_order_by>>;
  where?: InputMaybe<lists_limit_buy_orders_bool_exp>;
};


export type query_rootlists_payment_tasksArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_payment_tasks_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_payment_tasks_order_by>>;
  where?: InputMaybe<lists_payment_tasks_bool_exp>;
};


export type query_rootlists_payment_tasks_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_payment_tasks_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_payment_tasks_order_by>>;
  where?: InputMaybe<lists_payment_tasks_bool_exp>;
};


export type query_rootlists_paymentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_payments_order_by>>;
  where?: InputMaybe<lists_payments_bool_exp>;
};


export type query_rootlists_payments_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_payments_order_by>>;
  where?: InputMaybe<lists_payments_bool_exp>;
};


export type query_rootlists_sub_paymentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_sub_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_sub_payments_order_by>>;
  where?: InputMaybe<lists_sub_payments_bool_exp>;
};


export type query_rootlists_sub_payments_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_sub_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_sub_payments_order_by>>;
  where?: InputMaybe<lists_sub_payments_bool_exp>;
};


export type query_rootlists_tradeable_contractsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_tradeable_contracts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_tradeable_contracts_order_by>>;
  where?: InputMaybe<lists_tradeable_contracts_bool_exp>;
};


export type query_rootlists_tradeable_contracts_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_tradeable_contracts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_tradeable_contracts_order_by>>;
  where?: InputMaybe<lists_tradeable_contracts_bool_exp>;
};


export type query_rootlists_tradesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_trades_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_trades_order_by>>;
  where?: InputMaybe<lists_trades_bool_exp>;
};


export type query_rootlists_trades_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_trades_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_trades_order_by>>;
  where?: InputMaybe<lists_trades_bool_exp>;
};


export type query_rootlists_usersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_users_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_users_order_by>>;
  where?: InputMaybe<lists_users_bool_exp>;
};


export type query_rootlists_users_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_users_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_users_order_by>>;
  where?: InputMaybe<lists_users_bool_exp>;
};


export type query_rootlists_vendor_bank_transactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_vendor_bank_transactions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_vendor_bank_transactions_order_by>>;
  where?: InputMaybe<lists_vendor_bank_transactions_bool_exp>;
};


export type query_rootlists_vendor_bank_transactions_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_vendor_bank_transactions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_vendor_bank_transactions_order_by>>;
  where?: InputMaybe<lists_vendor_bank_transactions_bool_exp>;
};


export type query_rootlow_end_experiment_candidatesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<low_end_experiment_candidates_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<low_end_experiment_candidates_order_by>>;
  where?: InputMaybe<low_end_experiment_candidates_bool_exp>;
};


export type query_rootmanual_data_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<manual_data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<manual_data_sources_order_by>>;
  where?: InputMaybe<manual_data_sources_bool_exp>;
};


export type query_rootmanual_opsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<manual_ops_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<manual_ops_order_by>>;
  where?: InputMaybe<manual_ops_bool_exp>;
};


export type query_rootpipe_agreementsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_agreements_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_agreements_order_by>>;
  where?: InputMaybe<pipe_agreements_bool_exp>;
};


export type query_rootpipe_agreements_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_agreements_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_agreements_order_by>>;
  where?: InputMaybe<pipe_agreements_bool_exp>;
};


export type query_rootpipe_proposalsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_proposals_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_proposals_order_by>>;
  where?: InputMaybe<pipe_proposals_bool_exp>;
};


export type query_rootpipe_proposals_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_proposals_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_proposals_order_by>>;
  where?: InputMaybe<pipe_proposals_bool_exp>;
};


export type query_rootplaid_itemsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<plaid_items_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<plaid_items_order_by>>;
  where?: InputMaybe<plaid_items_bool_exp>;
};


export type query_rootplaid_items_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<plaid_items_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<plaid_items_order_by>>;
  where?: InputMaybe<plaid_items_bool_exp>;
};


export type query_rootprofit_and_loss_reportsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<profit_and_loss_reports_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<profit_and_loss_reports_order_by>>;
  where?: InputMaybe<profit_and_loss_reports_bool_exp>;
};


export type query_rootratings_churn_metrics_usdArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<ratings_churn_metrics_usd_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<ratings_churn_metrics_usd_order_by>>;
  where?: InputMaybe<ratings_churn_metrics_usd_bool_exp>;
};


export type query_rootratings_concern_evaluation_overridesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<ratings_concern_evaluation_overrides_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<ratings_concern_evaluation_overrides_order_by>>;
  where?: InputMaybe<ratings_concern_evaluation_overrides_bool_exp>;
};


export type query_rootratings_concern_evaluationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<ratings_concern_evaluations_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<ratings_concern_evaluations_order_by>>;
  where?: InputMaybe<ratings_concern_evaluations_bool_exp>;
};


export type query_rootratings_concernsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<ratings_concerns_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<ratings_concerns_order_by>>;
  where?: InputMaybe<ratings_concerns_bool_exp>;
};


export type query_rootratings_historical_bank_balances_usdArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<ratings_historical_bank_balances_usd_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<ratings_historical_bank_balances_usd_order_by>>;
  where?: InputMaybe<ratings_historical_bank_balances_usd_bool_exp>;
};


export type query_rootratings_revenue_metrics_usdArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<ratings_revenue_metrics_usd_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<ratings_revenue_metrics_usd_order_by>>;
  where?: InputMaybe<ratings_revenue_metrics_usd_bool_exp>;
};


export type query_rootrecurly_data_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<recurly_data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<recurly_data_sources_order_by>>;
  where?: InputMaybe<recurly_data_sources_bool_exp>;
};


export type query_rootrevenue_streamsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<revenue_streams_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<revenue_streams_order_by>>;
  where?: InputMaybe<revenue_streams_bool_exp>;
};


export type query_rootrevenue_streams_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<revenue_streams_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<revenue_streams_order_by>>;
  where?: InputMaybe<revenue_streams_bool_exp>;
};


export type query_rootrolesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<roles_order_by>>;
  where?: InputMaybe<roles_bool_exp>;
};


export type query_rootroles_permissionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<roles_permissions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<roles_permissions_order_by>>;
  where?: InputMaybe<roles_permissions_bool_exp>;
};


export type query_rootsell_ordersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<sell_orders_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<sell_orders_order_by>>;
  where?: InputMaybe<sell_orders_bool_exp>;
};


export type query_rootsell_orders_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<sell_orders_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<sell_orders_order_by>>;
  where?: InputMaybe<sell_orders_bool_exp>;
};


export type query_rootsell_orders_ownershipArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<sell_orders_ownership_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<sell_orders_ownership_order_by>>;
  where?: InputMaybe<sell_orders_ownership_bool_exp>;
};


export type query_rootsell_orders_ownership_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<sell_orders_ownership_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<sell_orders_ownership_order_by>>;
  where?: InputMaybe<sell_orders_ownership_bool_exp>;
};


export type query_rootsnapshot_historical_bank_data_viewArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<snapshot_historical_bank_data_view_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<snapshot_historical_bank_data_view_order_by>>;
  where?: InputMaybe<snapshot_historical_bank_data_view_bool_exp>;
};


export type query_rootsnapshot_overridesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<snapshot_overrides_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<snapshot_overrides_order_by>>;
  where?: InputMaybe<snapshot_overrides_bool_exp>;
};


export type query_rootsnapshotsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<snapshots_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<snapshots_order_by>>;
  where?: InputMaybe<snapshots_bool_exp>;
};


export type query_rootstripe_data_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<stripe_data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<stripe_data_sources_order_by>>;
  where?: InputMaybe<stripe_data_sources_bool_exp>;
};


export type query_rootsubscription_paymentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<subscription_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<subscription_payments_order_by>>;
  where?: InputMaybe<subscription_payments_bool_exp>;
};


export type query_rootsubscription_payments_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<subscription_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<subscription_payments_order_by>>;
  where?: InputMaybe<subscription_payments_bool_exp>;
};


export type query_roottrade_volume_historyArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<trade_volume_history_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<trade_volume_history_order_by>>;
  where?: InputMaybe<trade_volume_history_bool_exp>;
};


export type query_roottransactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<transactions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<transactions_order_by>>;
  where?: InputMaybe<transactions_bool_exp>;
};


export type query_rootuploaded_vendor_dataArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<uploaded_vendor_data_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<uploaded_vendor_data_order_by>>;
  where?: InputMaybe<uploaded_vendor_data_bool_exp>;
};


export type query_rootuploaded_vendor_data_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<uploaded_vendor_data_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<uploaded_vendor_data_order_by>>;
  where?: InputMaybe<uploaded_vendor_data_bool_exp>;
};


export type query_rootusersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<users_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<users_order_by>>;
  where?: InputMaybe<users_bool_exp>;
};


export type query_rootusers_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<users_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<users_order_by>>;
  where?: InputMaybe<users_bool_exp>;
};


export type query_rootusers_permissionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<users_permissions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<users_permissions_order_by>>;
  where?: InputMaybe<users_permissions_bool_exp>;
};


export type query_rootusers_rolesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<users_roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<users_roles_order_by>>;
  where?: InputMaybe<users_roles_bool_exp>;
};


export type query_rootvendor_active_ratingsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_active_ratings_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_active_ratings_order_by>>;
  where?: InputMaybe<vendor_active_ratings_bool_exp>;
};


export type query_rootvendor_bank_balancesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_bank_balances_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_bank_balances_order_by>>;
  where?: InputMaybe<vendor_bank_balances_bool_exp>;
};


export type query_rootvendor_current_sales_funnel_stageArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_current_sales_funnel_stage_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_current_sales_funnel_stage_order_by>>;
  where?: InputMaybe<vendor_current_sales_funnel_stage_bool_exp>;
};


export type query_rootvendor_custom_fieldsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_custom_fields_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_custom_fields_order_by>>;
  where?: InputMaybe<vendor_custom_fields_bool_exp>;
};


export type query_rootvendor_decisionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_decisions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_decisions_order_by>>;
  where?: InputMaybe<vendor_decisions_bool_exp>;
};


export type query_rootvendor_events_summaryArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_events_summary_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_events_summary_order_by>>;
  where?: InputMaybe<vendor_events_summary_bool_exp>;
};


export type query_rootvendor_financialsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_financials_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_financials_order_by>>;
  where?: InputMaybe<vendor_financials_bool_exp>;
};


export type query_rootvendor_financials_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_financials_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_financials_order_by>>;
  where?: InputMaybe<vendor_financials_bool_exp>;
};


export type query_rootvendor_financials_mrr_momArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_financials_mrr_mom_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_financials_mrr_mom_order_by>>;
  where?: InputMaybe<vendor_financials_mrr_mom_bool_exp>;
};


export type query_rootvendor_metric_jobsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_metric_jobs_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_metric_jobs_order_by>>;
  where?: InputMaybe<vendor_metric_jobs_bool_exp>;
};


export type query_rootvendor_metricsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_metrics_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_metrics_order_by>>;
  where?: InputMaybe<vendor_metrics_bool_exp>;
};


export type query_rootvendor_metrics_latestArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_metrics_latest_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_metrics_latest_order_by>>;
  where?: InputMaybe<vendor_metrics_latest_bool_exp>;
};


export type query_rootvendor_payment_statusArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_payment_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_payment_status_order_by>>;
  where?: InputMaybe<vendor_payment_status_bool_exp>;
};


export type query_rootvendor_payment_status_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_payment_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_payment_status_order_by>>;
  where?: InputMaybe<vendor_payment_status_bool_exp>;
};


export type query_rootvendor_paymentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_payments_order_by>>;
  where?: InputMaybe<vendor_payments_bool_exp>;
};


export type query_rootvendor_payments_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_payments_order_by>>;
  where?: InputMaybe<vendor_payments_bool_exp>;
};


export type query_rootvendor_payments_enrichedArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_payments_enriched_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_payments_enriched_order_by>>;
  where?: InputMaybe<vendor_payments_enriched_bool_exp>;
};


export type query_rootvendor_payments_extendedArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_payments_extended_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_payments_extended_order_by>>;
  where?: InputMaybe<vendor_payments_extended_bool_exp>;
};


export type query_rootvendor_product_waitlistArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_product_waitlist_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_product_waitlist_order_by>>;
  where?: InputMaybe<vendor_product_waitlist_bool_exp>;
};


export type query_rootvendor_product_waitlist_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_product_waitlist_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_product_waitlist_order_by>>;
  where?: InputMaybe<vendor_product_waitlist_bool_exp>;
};


export type query_rootvendor_ratingsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_ratings_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_ratings_order_by>>;
  where?: InputMaybe<vendor_ratings_bool_exp>;
};


export type query_rootvendor_ratings_metadataArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_ratings_metadata_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_ratings_metadata_order_by>>;
  where?: InputMaybe<vendor_ratings_metadata_bool_exp>;
};


export type query_rootvendor_supported_currenciesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_supported_currencies_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_supported_currencies_order_by>>;
  where?: InputMaybe<vendor_supported_currencies_bool_exp>;
};


export type query_rootvendor_tradesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_trades_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_trades_order_by>>;
  where?: InputMaybe<vendor_trades_bool_exp>;
};


export type query_rootvendor_treasury_accountsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_treasury_accounts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_treasury_accounts_order_by>>;
  where?: InputMaybe<vendor_treasury_accounts_bool_exp>;
};


export type query_rootvendor_treasury_balancesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_treasury_balances_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_treasury_balances_order_by>>;
  where?: InputMaybe<vendor_treasury_balances_bool_exp>;
};


export type query_rootvendor_treasury_daily_cumulative_balancesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_treasury_daily_cumulative_balances_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_treasury_daily_cumulative_balances_order_by>>;
  where?: InputMaybe<vendor_treasury_daily_cumulative_balances_bool_exp>;
};


export type query_rootvendor_treasury_transactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_treasury_transactions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_treasury_transactions_order_by>>;
  where?: InputMaybe<vendor_treasury_transactions_bool_exp>;
};


export type query_rootvendor_visit_summaryArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_visit_summary_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_visit_summary_order_by>>;
  where?: InputMaybe<vendor_visit_summary_bool_exp>;
};


export type query_rootvendorsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendors_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendors_order_by>>;
  where?: InputMaybe<vendors_bool_exp>;
};


export type query_rootvendors_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendors_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendors_order_by>>;
  where?: InputMaybe<vendors_bool_exp>;
};


export type query_rootvisitsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<visits_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<visits_order_by>>;
  where?: InputMaybe<visits_bool_exp>;
};


export type query_rootvisits_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<visits_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<visits_order_by>>;
  where?: InputMaybe<visits_bool_exp>;
};

/**
 * A view of vendor churn metrics from subscriptions data grouped by date. All amounts are in USD and converted using the `exchange_rates` table.
 *
 *
 * columns and relationships of "ratings_churn_metrics_usd"
 */
export type ratings_churn_metrics_usd = {
  readonly __typename?: 'ratings_churn_metrics_usd';
  readonly churned_contract_value?: Maybe<Scalars['float8']>;
  readonly date?: Maybe<Scalars['date']>;
  /** An object relationship */
  readonly vendor?: Maybe<vendors>;
};

/** Boolean expression to filter rows from the table "ratings_churn_metrics_usd". All fields are combined with a logical 'AND'. */
export type ratings_churn_metrics_usd_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<ratings_churn_metrics_usd_bool_exp>>;
  readonly _not?: InputMaybe<ratings_churn_metrics_usd_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<ratings_churn_metrics_usd_bool_exp>>;
  readonly churned_contract_value?: InputMaybe<float8_comparison_exp>;
  readonly date?: InputMaybe<date_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** Ordering options when selecting data from "ratings_churn_metrics_usd". */
export type ratings_churn_metrics_usd_order_by = {
  readonly churned_contract_value?: InputMaybe<order_by>;
  readonly date?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "ratings_churn_metrics_usd" */
export enum ratings_churn_metrics_usd_select_column {
  /** column name */
  churned_contract_value = 'churned_contract_value',
  /** column name */
  date = 'date'
}

/** columns and relationships of "ratings_concern_evaluation_overrides" */
export type ratings_concern_evaluation_overrides = {
  readonly __typename?: 'ratings_concern_evaluation_overrides';
  readonly ends_at: Scalars['timestamptz'];
  readonly public_id: Scalars['String'];
  /** An object relationship */
  readonly ratings_concern: ratings_concerns;
  readonly result: Scalars['Boolean'];
  readonly starts_at: Scalars['timestamptz'];
  /** An object relationship */
  readonly user: users;
};

/** Boolean expression to filter rows from the table "ratings_concern_evaluation_overrides". All fields are combined with a logical 'AND'. */
export type ratings_concern_evaluation_overrides_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<ratings_concern_evaluation_overrides_bool_exp>>;
  readonly _not?: InputMaybe<ratings_concern_evaluation_overrides_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<ratings_concern_evaluation_overrides_bool_exp>>;
  readonly ends_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly ratings_concern?: InputMaybe<ratings_concerns_bool_exp>;
  readonly result?: InputMaybe<Boolean_comparison_exp>;
  readonly starts_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly user?: InputMaybe<users_bool_exp>;
};

/** Ordering options when selecting data from "ratings_concern_evaluation_overrides". */
export type ratings_concern_evaluation_overrides_order_by = {
  readonly ends_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly ratings_concern?: InputMaybe<ratings_concerns_order_by>;
  readonly result?: InputMaybe<order_by>;
  readonly starts_at?: InputMaybe<order_by>;
  readonly user?: InputMaybe<users_order_by>;
};

/** select columns of table "ratings_concern_evaluation_overrides" */
export enum ratings_concern_evaluation_overrides_select_column {
  /** column name */
  ends_at = 'ends_at',
  /** column name */
  public_id = 'public_id',
  /** column name */
  result = 'result',
  /** column name */
  starts_at = 'starts_at'
}

/** columns and relationships of "ratings_concern_evaluations" */
export type ratings_concern_evaluations = {
  readonly __typename?: 'ratings_concern_evaluations';
  readonly evaluated_at: Scalars['timestamptz'];
  readonly evaluation_data?: Maybe<Scalars['jsonb']>;
  readonly original_result: Scalars['Boolean'];
  readonly original_result_reasons: Scalars['_jsonb'];
  readonly public_id: Scalars['String'];
  /** An object relationship */
  readonly ratings_concern: ratings_concerns;
  /** An object relationship */
  readonly ratings_concern_evaluation_override?: Maybe<ratings_concern_evaluation_overrides>;
  readonly result: Scalars['Boolean'];
  /** An object relationship */
  readonly user?: Maybe<users>;
  /** An object relationship */
  readonly vendor: vendors;
};


/** columns and relationships of "ratings_concern_evaluations" */
export type ratings_concern_evaluationsevaluation_dataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "ratings_concern_evaluations" */
export type ratings_concern_evaluations_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<ratings_concern_evaluations_max_order_by>;
  readonly min?: InputMaybe<ratings_concern_evaluations_min_order_by>;
};

/** Boolean expression to filter rows from the table "ratings_concern_evaluations". All fields are combined with a logical 'AND'. */
export type ratings_concern_evaluations_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<ratings_concern_evaluations_bool_exp>>;
  readonly _not?: InputMaybe<ratings_concern_evaluations_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<ratings_concern_evaluations_bool_exp>>;
  readonly evaluated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly evaluation_data?: InputMaybe<jsonb_comparison_exp>;
  readonly original_result?: InputMaybe<Boolean_comparison_exp>;
  readonly original_result_reasons?: InputMaybe<_jsonb_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly ratings_concern?: InputMaybe<ratings_concerns_bool_exp>;
  readonly ratings_concern_evaluation_override?: InputMaybe<ratings_concern_evaluation_overrides_bool_exp>;
  readonly result?: InputMaybe<Boolean_comparison_exp>;
  readonly user?: InputMaybe<users_bool_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** order by max() on columns of table "ratings_concern_evaluations" */
export type ratings_concern_evaluations_max_order_by = {
  readonly evaluated_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** order by min() on columns of table "ratings_concern_evaluations" */
export type ratings_concern_evaluations_min_order_by = {
  readonly evaluated_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "ratings_concern_evaluations". */
export type ratings_concern_evaluations_order_by = {
  readonly evaluated_at?: InputMaybe<order_by>;
  readonly evaluation_data?: InputMaybe<order_by>;
  readonly original_result?: InputMaybe<order_by>;
  readonly original_result_reasons?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly ratings_concern?: InputMaybe<ratings_concerns_order_by>;
  readonly ratings_concern_evaluation_override?: InputMaybe<ratings_concern_evaluation_overrides_order_by>;
  readonly result?: InputMaybe<order_by>;
  readonly user?: InputMaybe<users_order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "ratings_concern_evaluations" */
export enum ratings_concern_evaluations_select_column {
  /** column name */
  evaluated_at = 'evaluated_at',
  /** column name */
  evaluation_data = 'evaluation_data',
  /** column name */
  original_result = 'original_result',
  /** column name */
  original_result_reasons = 'original_result_reasons',
  /** column name */
  public_id = 'public_id',
  /** column name */
  result = 'result'
}

/** columns and relationships of "ratings_concerns" */
export type ratings_concerns = {
  readonly __typename?: 'ratings_concerns';
  readonly is_required: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly public_id: Scalars['String'];
  /** fetch data from the table: "ratings_concern_evaluations" */
  readonly ratings_concern_evaluations: ReadonlyArray<ratings_concern_evaluations>;
  /** An object relationship */
  readonly vendor: vendors;
};


/** columns and relationships of "ratings_concerns" */
export type ratings_concernsratings_concern_evaluationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<ratings_concern_evaluations_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<ratings_concern_evaluations_order_by>>;
  where?: InputMaybe<ratings_concern_evaluations_bool_exp>;
};

/** Boolean expression to filter rows from the table "ratings_concerns". All fields are combined with a logical 'AND'. */
export type ratings_concerns_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<ratings_concerns_bool_exp>>;
  readonly _not?: InputMaybe<ratings_concerns_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<ratings_concerns_bool_exp>>;
  readonly is_required?: InputMaybe<Boolean_comparison_exp>;
  readonly name?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly ratings_concern_evaluations?: InputMaybe<ratings_concern_evaluations_bool_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** response of any mutation on the table "ratings_concerns" */
export type ratings_concerns_mutation_response = {
  readonly __typename?: 'ratings_concerns_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<ratings_concerns>;
};

/** Ordering options when selecting data from "ratings_concerns". */
export type ratings_concerns_order_by = {
  readonly is_required?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly ratings_concern_evaluations_aggregate?: InputMaybe<ratings_concern_evaluations_aggregate_order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "ratings_concerns" */
export enum ratings_concerns_select_column {
  /** column name */
  is_required = 'is_required',
  /** column name */
  name = 'name',
  /** column name */
  public_id = 'public_id'
}

/** input type for updating data in table "ratings_concerns" */
export type ratings_concerns_set_input = {
  readonly is_required?: InputMaybe<Scalars['Boolean']>;
};

/**
 * A view of historical bank balances aggregated by date for each vendor. Amounts are in USD and are converted from their native currencies using the `exchange_rates` table. We do a simple conversion of the balances determined by the `ratings_historical_bank_balances` view rather than converting the individual transactions so that historical values do not change over time.
 *
 *
 * columns and relationships of "ratings_historical_bank_balances_usd"
 */
export type ratings_historical_bank_balances_usd = {
  readonly __typename?: 'ratings_historical_bank_balances_usd';
  readonly balance?: Maybe<Scalars['float8']>;
  readonly date?: Maybe<Scalars['date']>;
  /** An object relationship */
  readonly vendor?: Maybe<vendors>;
};

/** Boolean expression to filter rows from the table "ratings_historical_bank_balances_usd". All fields are combined with a logical 'AND'. */
export type ratings_historical_bank_balances_usd_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<ratings_historical_bank_balances_usd_bool_exp>>;
  readonly _not?: InputMaybe<ratings_historical_bank_balances_usd_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<ratings_historical_bank_balances_usd_bool_exp>>;
  readonly balance?: InputMaybe<float8_comparison_exp>;
  readonly date?: InputMaybe<date_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** Ordering options when selecting data from "ratings_historical_bank_balances_usd". */
export type ratings_historical_bank_balances_usd_order_by = {
  readonly balance?: InputMaybe<order_by>;
  readonly date?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "ratings_historical_bank_balances_usd" */
export enum ratings_historical_bank_balances_usd_select_column {
  /** column name */
  balance = 'balance',
  /** column name */
  date = 'date'
}

/**
 * A view of vendor revenue metrics from subscriptions data grouped by date. All amounts are in USD and converted using the `exchange_rates` table.
 *
 *
 * columns and relationships of "ratings_revenue_metrics_usd"
 */
export type ratings_revenue_metrics_usd = {
  readonly __typename?: 'ratings_revenue_metrics_usd';
  readonly date?: Maybe<Scalars['date']>;
  readonly delinquent_contract_value?: Maybe<Scalars['float8']>;
  readonly recurring_contract_value?: Maybe<Scalars['float8']>;
  /** An object relationship */
  readonly vendor?: Maybe<vendors>;
};

/** Boolean expression to filter rows from the table "ratings_revenue_metrics_usd". All fields are combined with a logical 'AND'. */
export type ratings_revenue_metrics_usd_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<ratings_revenue_metrics_usd_bool_exp>>;
  readonly _not?: InputMaybe<ratings_revenue_metrics_usd_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<ratings_revenue_metrics_usd_bool_exp>>;
  readonly date?: InputMaybe<date_comparison_exp>;
  readonly delinquent_contract_value?: InputMaybe<float8_comparison_exp>;
  readonly recurring_contract_value?: InputMaybe<float8_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** Ordering options when selecting data from "ratings_revenue_metrics_usd". */
export type ratings_revenue_metrics_usd_order_by = {
  readonly date?: InputMaybe<order_by>;
  readonly delinquent_contract_value?: InputMaybe<order_by>;
  readonly recurring_contract_value?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "ratings_revenue_metrics_usd" */
export enum ratings_revenue_metrics_usd_select_column {
  /** column name */
  date = 'date',
  /** column name */
  delinquent_contract_value = 'delinquent_contract_value',
  /** column name */
  recurring_contract_value = 'recurring_contract_value'
}

/** columns and relationships of "recurly_data_sources" */
export type recurly_data_sources = {
  readonly __typename?: 'recurly_data_sources';
  readonly public_id: Scalars['String'];
  readonly subdomain: Scalars['String'];
};

/** Boolean expression to filter rows from the table "recurly_data_sources". All fields are combined with a logical 'AND'. */
export type recurly_data_sources_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<recurly_data_sources_bool_exp>>;
  readonly _not?: InputMaybe<recurly_data_sources_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<recurly_data_sources_bool_exp>>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly subdomain?: InputMaybe<String_comparison_exp>;
};

/** Ordering options when selecting data from "recurly_data_sources". */
export type recurly_data_sources_order_by = {
  readonly public_id?: InputMaybe<order_by>;
  readonly subdomain?: InputMaybe<order_by>;
};

/** select columns of table "recurly_data_sources" */
export enum recurly_data_sources_select_column {
  /** column name */
  public_id = 'public_id',
  /** column name */
  subdomain = 'subdomain'
}

/** Boolean expression to compare columns of type "revenue_stream_source". All fields are combined with logical 'AND'. */
export type revenue_stream_source_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['revenue_stream_source']>;
  readonly _gt?: InputMaybe<Scalars['revenue_stream_source']>;
  readonly _gte?: InputMaybe<Scalars['revenue_stream_source']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['revenue_stream_source']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['revenue_stream_source']>;
  readonly _lte?: InputMaybe<Scalars['revenue_stream_source']>;
  readonly _neq?: InputMaybe<Scalars['revenue_stream_source']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['revenue_stream_source']>>;
};

/**
 * A revenue_streams row represents recurring revenue for a customer that may be piped.
 *
 *
 * columns and relationships of "revenue_streams"
 */
export type revenue_streams = {
  readonly __typename?: 'revenue_streams';
  readonly created_at: Scalars['timestamp'];
  readonly currency: Scalars['currency'];
  /** An object relationship */
  readonly customer: customers;
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count: Scalars['Int'];
  /** An array relationship */
  readonly pipe_agreements: ReadonlyArray<pipe_agreements>;
  /** An aggregate relationship */
  readonly pipe_agreements_aggregate: pipe_agreements_aggregate;
  /** An object relationship */
  readonly pipe_proposal?: Maybe<pipe_proposals>;
  readonly public_id: Scalars['String'];
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period: Scalars['bigint'];
  readonly revenue_stream_source: Scalars['revenue_stream_source'];
  readonly updated_at: Scalars['timestamp'];
  readonly valid_until?: Maybe<Scalars['date']>;
  /** An object relationship */
  readonly vendor: vendors;
};


/**
 * A revenue_streams row represents recurring revenue for a customer that may be piped.
 *
 *
 * columns and relationships of "revenue_streams"
 */
export type revenue_streamspipe_agreementsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_agreements_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_agreements_order_by>>;
  where?: InputMaybe<pipe_agreements_bool_exp>;
};


/**
 * A revenue_streams row represents recurring revenue for a customer that may be piped.
 *
 *
 * columns and relationships of "revenue_streams"
 */
export type revenue_streamspipe_agreements_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_agreements_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_agreements_order_by>>;
  where?: InputMaybe<pipe_agreements_bool_exp>;
};

/** aggregated selection of "revenue_streams" */
export type revenue_streams_aggregate = {
  readonly __typename?: 'revenue_streams_aggregate';
  readonly aggregate?: Maybe<revenue_streams_aggregate_fields>;
  readonly nodes: ReadonlyArray<revenue_streams>;
};

/** aggregate fields of "revenue_streams" */
export type revenue_streams_aggregate_fields = {
  readonly __typename?: 'revenue_streams_aggregate_fields';
  readonly avg?: Maybe<revenue_streams_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<revenue_streams_max_fields>;
  readonly min?: Maybe<revenue_streams_min_fields>;
  readonly stddev?: Maybe<revenue_streams_stddev_fields>;
  readonly stddev_pop?: Maybe<revenue_streams_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<revenue_streams_stddev_samp_fields>;
  readonly sum?: Maybe<revenue_streams_sum_fields>;
  readonly var_pop?: Maybe<revenue_streams_var_pop_fields>;
  readonly var_samp?: Maybe<revenue_streams_var_samp_fields>;
  readonly variance?: Maybe<revenue_streams_variance_fields>;
};


/** aggregate fields of "revenue_streams" */
export type revenue_streams_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<revenue_streams_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "revenue_streams" */
export type revenue_streams_aggregate_order_by = {
  readonly avg?: InputMaybe<revenue_streams_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<revenue_streams_max_order_by>;
  readonly min?: InputMaybe<revenue_streams_min_order_by>;
  readonly stddev?: InputMaybe<revenue_streams_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<revenue_streams_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<revenue_streams_stddev_samp_order_by>;
  readonly sum?: InputMaybe<revenue_streams_sum_order_by>;
  readonly var_pop?: InputMaybe<revenue_streams_var_pop_order_by>;
  readonly var_samp?: InputMaybe<revenue_streams_var_samp_order_by>;
  readonly variance?: InputMaybe<revenue_streams_variance_order_by>;
};

/** aggregate avg on columns */
export type revenue_streams_avg_fields = {
  readonly __typename?: 'revenue_streams_avg_fields';
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: Maybe<Scalars['Float']>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "revenue_streams" */
export type revenue_streams_avg_order_by = {
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: InputMaybe<order_by>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "revenue_streams". All fields are combined with a logical 'AND'. */
export type revenue_streams_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<revenue_streams_bool_exp>>;
  readonly _not?: InputMaybe<revenue_streams_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<revenue_streams_bool_exp>>;
  readonly created_at?: InputMaybe<timestamp_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly customer?: InputMaybe<customers_bool_exp>;
  readonly interval_count?: InputMaybe<Int_comparison_exp>;
  readonly pipe_agreements?: InputMaybe<pipe_agreements_bool_exp>;
  readonly pipe_proposal?: InputMaybe<pipe_proposals_bool_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly revenue_per_period?: InputMaybe<bigint_comparison_exp>;
  readonly revenue_stream_source?: InputMaybe<revenue_stream_source_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamp_comparison_exp>;
  readonly valid_until?: InputMaybe<date_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** aggregate max on columns */
export type revenue_streams_max_fields = {
  readonly __typename?: 'revenue_streams_max_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: Maybe<Scalars['Int']>;
  readonly public_id?: Maybe<Scalars['String']>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly valid_until?: Maybe<Scalars['date']>;
};

/** order by max() on columns of table "revenue_streams" */
export type revenue_streams_max_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly valid_until?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type revenue_streams_min_fields = {
  readonly __typename?: 'revenue_streams_min_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: Maybe<Scalars['Int']>;
  readonly public_id?: Maybe<Scalars['String']>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly valid_until?: Maybe<Scalars['date']>;
};

/** order by min() on columns of table "revenue_streams" */
export type revenue_streams_min_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly valid_until?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "revenue_streams". */
export type revenue_streams_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly customer?: InputMaybe<customers_order_by>;
  readonly interval_count?: InputMaybe<order_by>;
  readonly pipe_agreements_aggregate?: InputMaybe<pipe_agreements_aggregate_order_by>;
  readonly pipe_proposal?: InputMaybe<pipe_proposals_order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly revenue_per_period?: InputMaybe<order_by>;
  readonly revenue_stream_source?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly valid_until?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "revenue_streams" */
export enum revenue_streams_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  interval_count = 'interval_count',
  /** column name */
  public_id = 'public_id',
  /** column name */
  revenue_per_period = 'revenue_per_period',
  /** column name */
  revenue_stream_source = 'revenue_stream_source',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  valid_until = 'valid_until'
}

/** aggregate stddev on columns */
export type revenue_streams_stddev_fields = {
  readonly __typename?: 'revenue_streams_stddev_fields';
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: Maybe<Scalars['Float']>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "revenue_streams" */
export type revenue_streams_stddev_order_by = {
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: InputMaybe<order_by>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type revenue_streams_stddev_pop_fields = {
  readonly __typename?: 'revenue_streams_stddev_pop_fields';
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: Maybe<Scalars['Float']>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "revenue_streams" */
export type revenue_streams_stddev_pop_order_by = {
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: InputMaybe<order_by>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type revenue_streams_stddev_samp_fields = {
  readonly __typename?: 'revenue_streams_stddev_samp_fields';
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: Maybe<Scalars['Float']>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "revenue_streams" */
export type revenue_streams_stddev_samp_order_by = {
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: InputMaybe<order_by>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: InputMaybe<order_by>;
};

/** aggregate sum on columns */
export type revenue_streams_sum_fields = {
  readonly __typename?: 'revenue_streams_sum_fields';
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: Maybe<Scalars['Int']>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "revenue_streams" */
export type revenue_streams_sum_order_by = {
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: InputMaybe<order_by>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type revenue_streams_var_pop_fields = {
  readonly __typename?: 'revenue_streams_var_pop_fields';
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: Maybe<Scalars['Float']>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "revenue_streams" */
export type revenue_streams_var_pop_order_by = {
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: InputMaybe<order_by>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type revenue_streams_var_samp_fields = {
  readonly __typename?: 'revenue_streams_var_samp_fields';
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: Maybe<Scalars['Float']>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "revenue_streams" */
export type revenue_streams_var_samp_order_by = {
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: InputMaybe<order_by>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type revenue_streams_variance_fields = {
  readonly __typename?: 'revenue_streams_variance_fields';
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: Maybe<Scalars['Float']>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "revenue_streams" */
export type revenue_streams_variance_order_by = {
  /** Cadence at which revenue is collected (in months). This is the source of truth, and should be used for payment calculation etc. */
  readonly interval_count?: InputMaybe<order_by>;
  /** Revenue collected per `interval_count` months. This is the source of truth, and should be used for payment calculation etc. */
  readonly revenue_per_period?: InputMaybe<order_by>;
};

/** columns and relationships of "roles" */
export type roles = {
  readonly __typename?: 'roles';
  readonly name: Scalars['String'];
  readonly public_id: Scalars['String'];
  readonly user_type: user_type_enum_enum;
  /** An array relationship */
  readonly users_roles: ReadonlyArray<users_roles>;
};


/** columns and relationships of "roles" */
export type rolesusers_rolesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<users_roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<users_roles_order_by>>;
  where?: InputMaybe<users_roles_bool_exp>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type roles_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<roles_bool_exp>>;
  readonly _not?: InputMaybe<roles_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<roles_bool_exp>>;
  readonly name?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly user_type?: InputMaybe<user_type_enum_enum_comparison_exp>;
  readonly users_roles?: InputMaybe<users_roles_bool_exp>;
};

/** Ordering options when selecting data from "roles". */
export type roles_order_by = {
  readonly name?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly user_type?: InputMaybe<order_by>;
  readonly users_roles_aggregate?: InputMaybe<users_roles_aggregate_order_by>;
};

/** columns and relationships of "roles_permissions" */
export type roles_permissions = {
  readonly __typename?: 'roles_permissions';
  readonly permission: permission_enum_enum;
  /** An object relationship */
  readonly role: roles;
};

/** Boolean expression to filter rows from the table "roles_permissions". All fields are combined with a logical 'AND'. */
export type roles_permissions_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<roles_permissions_bool_exp>>;
  readonly _not?: InputMaybe<roles_permissions_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<roles_permissions_bool_exp>>;
  readonly permission?: InputMaybe<permission_enum_enum_comparison_exp>;
  readonly role?: InputMaybe<roles_bool_exp>;
};

/** Ordering options when selecting data from "roles_permissions". */
export type roles_permissions_order_by = {
  readonly permission?: InputMaybe<order_by>;
  readonly role?: InputMaybe<roles_order_by>;
};

/** select columns of table "roles_permissions" */
export enum roles_permissions_select_column {
  /** column name */
  permission = 'permission'
}

/** select columns of table "roles" */
export enum roles_select_column {
  /** column name */
  name = 'name',
  /** column name */
  public_id = 'public_id',
  /** column name */
  user_type = 'user_type'
}

export enum sell_order_fill_status_enum_enum {
  /** this order has been fully filled */
  filled = 'filled',
  /** this order has been partially filled */
  partially_filled = 'partially_filled',
  /** this order has yet to be filled (i.e. assets matched to a buyer) */
  unfilled = 'unfilled'
}

/** Boolean expression to compare columns of type "sell_order_fill_status_enum_enum". All fields are combined with logical 'AND'. */
export type sell_order_fill_status_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<sell_order_fill_status_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<sell_order_fill_status_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<sell_order_fill_status_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<sell_order_fill_status_enum_enum>>;
};

export enum sell_order_status_enum_enum {
  /** Sell order failed to process. No changes were made. (terminal) */
  failed = 'failed',
  /** Sell order is being processed by a background job. */
  in_progress = 'in_progress',
  /** User has accepted sell order and its waiting to be processed. */
  pending = 'pending',
  /** User is currently previewing the sell order. They have to explicitly accept or reject it. */
  previewing = 'previewing',
  /** User has rejected the sell order, so we should not process it. (terminal) */
  rejected = 'rejected',
  /** Sell order has been successfully processed. (terminal) */
  succeeded = 'succeeded'
}

/** Boolean expression to compare columns of type "sell_order_status_enum_enum". All fields are combined with logical 'AND'. */
export type sell_order_status_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<sell_order_status_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<sell_order_status_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<sell_order_status_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<sell_order_status_enum_enum>>;
};

/** columns and relationships of "sell_orders" */
export type sell_orders = {
  readonly __typename?: 'sell_orders';
  readonly balance: Scalars['bigint'];
  readonly created_at: Scalars['timestamptz'];
  readonly currency: Scalars['currency'];
  readonly expected_payout?: Maybe<Scalars['bigint']>;
  readonly fill_status: sell_order_fill_status_enum_enum;
  readonly ip_address: Scalars['String'];
  readonly num_contracts: Scalars['Int'];
  readonly payout: Scalars['bigint'];
  readonly public_id: Scalars['String'];
  readonly status: sell_order_status_enum_enum;
  readonly term_length: Scalars['Int'];
  readonly updated_at: Scalars['timestamptz'];
  /** An object relationship */
  readonly user: users;
  /** An object relationship */
  readonly vendor: vendors;
};

/** aggregated selection of "sell_orders" */
export type sell_orders_aggregate = {
  readonly __typename?: 'sell_orders_aggregate';
  readonly aggregate?: Maybe<sell_orders_aggregate_fields>;
  readonly nodes: ReadonlyArray<sell_orders>;
};

/** aggregate fields of "sell_orders" */
export type sell_orders_aggregate_fields = {
  readonly __typename?: 'sell_orders_aggregate_fields';
  readonly avg?: Maybe<sell_orders_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<sell_orders_max_fields>;
  readonly min?: Maybe<sell_orders_min_fields>;
  readonly stddev?: Maybe<sell_orders_stddev_fields>;
  readonly stddev_pop?: Maybe<sell_orders_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<sell_orders_stddev_samp_fields>;
  readonly sum?: Maybe<sell_orders_sum_fields>;
  readonly var_pop?: Maybe<sell_orders_var_pop_fields>;
  readonly var_samp?: Maybe<sell_orders_var_samp_fields>;
  readonly variance?: Maybe<sell_orders_variance_fields>;
};


/** aggregate fields of "sell_orders" */
export type sell_orders_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<sell_orders_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type sell_orders_avg_fields = {
  readonly __typename?: 'sell_orders_avg_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly expected_payout?: Maybe<Scalars['Float']>;
  readonly num_contracts?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly term_length?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "sell_orders". All fields are combined with a logical 'AND'. */
export type sell_orders_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<sell_orders_bool_exp>>;
  readonly _not?: InputMaybe<sell_orders_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<sell_orders_bool_exp>>;
  readonly balance?: InputMaybe<bigint_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly expected_payout?: InputMaybe<bigint_comparison_exp>;
  readonly fill_status?: InputMaybe<sell_order_fill_status_enum_enum_comparison_exp>;
  readonly ip_address?: InputMaybe<String_comparison_exp>;
  readonly num_contracts?: InputMaybe<Int_comparison_exp>;
  readonly payout?: InputMaybe<bigint_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly status?: InputMaybe<sell_order_status_enum_enum_comparison_exp>;
  readonly term_length?: InputMaybe<Int_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly user?: InputMaybe<users_bool_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** aggregate max on columns */
export type sell_orders_max_fields = {
  readonly __typename?: 'sell_orders_max_fields';
  readonly balance?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly expected_payout?: Maybe<Scalars['bigint']>;
  readonly ip_address?: Maybe<Scalars['String']>;
  readonly num_contracts?: Maybe<Scalars['Int']>;
  readonly payout?: Maybe<Scalars['bigint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly term_length?: Maybe<Scalars['Int']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type sell_orders_min_fields = {
  readonly __typename?: 'sell_orders_min_fields';
  readonly balance?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly expected_payout?: Maybe<Scalars['bigint']>;
  readonly ip_address?: Maybe<Scalars['String']>;
  readonly num_contracts?: Maybe<Scalars['Int']>;
  readonly payout?: Maybe<Scalars['bigint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly term_length?: Maybe<Scalars['Int']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "sell_orders". */
export type sell_orders_order_by = {
  readonly balance?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly expected_payout?: InputMaybe<order_by>;
  readonly fill_status?: InputMaybe<order_by>;
  readonly ip_address?: InputMaybe<order_by>;
  readonly num_contracts?: InputMaybe<order_by>;
  readonly payout?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly status?: InputMaybe<order_by>;
  readonly term_length?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly user?: InputMaybe<users_order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** columns and relationships of "sell_orders_ownership" */
export type sell_orders_ownership = {
  readonly __typename?: 'sell_orders_ownership';
  readonly currency?: Maybe<Scalars['currency']>;
  readonly exchange_fee_amount?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  readonly investor?: Maybe<investors>;
  readonly investor_client_id?: Maybe<Scalars['String']>;
  readonly investor_name?: Maybe<Scalars['String']>;
  readonly investor_public_id?: Maybe<Scalars['String']>;
  readonly num_receipts?: Maybe<Scalars['bigint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly purchase_amount?: Maybe<Scalars['numeric']>;
  readonly ratings?: Maybe<Scalars['_text']>;
  readonly receivable_amount?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "sell_orders_ownership" */
export type sell_orders_ownership_aggregate = {
  readonly __typename?: 'sell_orders_ownership_aggregate';
  readonly aggregate?: Maybe<sell_orders_ownership_aggregate_fields>;
  readonly nodes: ReadonlyArray<sell_orders_ownership>;
};

/** aggregate fields of "sell_orders_ownership" */
export type sell_orders_ownership_aggregate_fields = {
  readonly __typename?: 'sell_orders_ownership_aggregate_fields';
  readonly avg?: Maybe<sell_orders_ownership_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<sell_orders_ownership_max_fields>;
  readonly min?: Maybe<sell_orders_ownership_min_fields>;
  readonly stddev?: Maybe<sell_orders_ownership_stddev_fields>;
  readonly stddev_pop?: Maybe<sell_orders_ownership_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<sell_orders_ownership_stddev_samp_fields>;
  readonly sum?: Maybe<sell_orders_ownership_sum_fields>;
  readonly var_pop?: Maybe<sell_orders_ownership_var_pop_fields>;
  readonly var_samp?: Maybe<sell_orders_ownership_var_samp_fields>;
  readonly variance?: Maybe<sell_orders_ownership_variance_fields>;
};


/** aggregate fields of "sell_orders_ownership" */
export type sell_orders_ownership_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<sell_orders_ownership_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type sell_orders_ownership_avg_fields = {
  readonly __typename?: 'sell_orders_ownership_avg_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['Float']>;
  readonly num_receipts?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "sell_orders_ownership". All fields are combined with a logical 'AND'. */
export type sell_orders_ownership_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<sell_orders_ownership_bool_exp>>;
  readonly _not?: InputMaybe<sell_orders_ownership_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<sell_orders_ownership_bool_exp>>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly exchange_fee_amount?: InputMaybe<numeric_comparison_exp>;
  readonly investor?: InputMaybe<investors_bool_exp>;
  readonly investor_client_id?: InputMaybe<String_comparison_exp>;
  readonly investor_name?: InputMaybe<String_comparison_exp>;
  readonly investor_public_id?: InputMaybe<String_comparison_exp>;
  readonly num_receipts?: InputMaybe<bigint_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly purchase_amount?: InputMaybe<numeric_comparison_exp>;
  readonly ratings?: InputMaybe<_text_comparison_exp>;
  readonly receivable_amount?: InputMaybe<numeric_comparison_exp>;
};

/** aggregate max on columns */
export type sell_orders_ownership_max_fields = {
  readonly __typename?: 'sell_orders_ownership_max_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['numeric']>;
  readonly investor_client_id?: Maybe<Scalars['String']>;
  readonly investor_name?: Maybe<Scalars['String']>;
  readonly investor_public_id?: Maybe<Scalars['String']>;
  readonly num_receipts?: Maybe<Scalars['bigint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly purchase_amount?: Maybe<Scalars['numeric']>;
  readonly receivable_amount?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type sell_orders_ownership_min_fields = {
  readonly __typename?: 'sell_orders_ownership_min_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['numeric']>;
  readonly investor_client_id?: Maybe<Scalars['String']>;
  readonly investor_name?: Maybe<Scalars['String']>;
  readonly investor_public_id?: Maybe<Scalars['String']>;
  readonly num_receipts?: Maybe<Scalars['bigint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly purchase_amount?: Maybe<Scalars['numeric']>;
  readonly receivable_amount?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "sell_orders_ownership". */
export type sell_orders_ownership_order_by = {
  readonly currency?: InputMaybe<order_by>;
  readonly exchange_fee_amount?: InputMaybe<order_by>;
  readonly investor?: InputMaybe<investors_order_by>;
  readonly investor_client_id?: InputMaybe<order_by>;
  readonly investor_name?: InputMaybe<order_by>;
  readonly investor_public_id?: InputMaybe<order_by>;
  readonly num_receipts?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly purchase_amount?: InputMaybe<order_by>;
  readonly ratings?: InputMaybe<order_by>;
  readonly receivable_amount?: InputMaybe<order_by>;
};

/** select columns of table "sell_orders_ownership" */
export enum sell_orders_ownership_select_column {
  /** column name */
  currency = 'currency',
  /** column name */
  exchange_fee_amount = 'exchange_fee_amount',
  /** column name */
  investor_client_id = 'investor_client_id',
  /** column name */
  investor_name = 'investor_name',
  /** column name */
  investor_public_id = 'investor_public_id',
  /** column name */
  num_receipts = 'num_receipts',
  /** column name */
  public_id = 'public_id',
  /** column name */
  purchase_amount = 'purchase_amount',
  /** column name */
  ratings = 'ratings',
  /** column name */
  receivable_amount = 'receivable_amount'
}

/** aggregate stddev on columns */
export type sell_orders_ownership_stddev_fields = {
  readonly __typename?: 'sell_orders_ownership_stddev_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['Float']>;
  readonly num_receipts?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type sell_orders_ownership_stddev_pop_fields = {
  readonly __typename?: 'sell_orders_ownership_stddev_pop_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['Float']>;
  readonly num_receipts?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type sell_orders_ownership_stddev_samp_fields = {
  readonly __typename?: 'sell_orders_ownership_stddev_samp_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['Float']>;
  readonly num_receipts?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type sell_orders_ownership_sum_fields = {
  readonly __typename?: 'sell_orders_ownership_sum_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['numeric']>;
  readonly num_receipts?: Maybe<Scalars['bigint']>;
  readonly purchase_amount?: Maybe<Scalars['numeric']>;
  readonly receivable_amount?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type sell_orders_ownership_var_pop_fields = {
  readonly __typename?: 'sell_orders_ownership_var_pop_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['Float']>;
  readonly num_receipts?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type sell_orders_ownership_var_samp_fields = {
  readonly __typename?: 'sell_orders_ownership_var_samp_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['Float']>;
  readonly num_receipts?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type sell_orders_ownership_variance_fields = {
  readonly __typename?: 'sell_orders_ownership_variance_fields';
  readonly exchange_fee_amount?: Maybe<Scalars['Float']>;
  readonly num_receipts?: Maybe<Scalars['Float']>;
  readonly purchase_amount?: Maybe<Scalars['Float']>;
  readonly receivable_amount?: Maybe<Scalars['Float']>;
};

/** select columns of table "sell_orders" */
export enum sell_orders_select_column {
  /** column name */
  balance = 'balance',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  expected_payout = 'expected_payout',
  /** column name */
  fill_status = 'fill_status',
  /** column name */
  ip_address = 'ip_address',
  /** column name */
  num_contracts = 'num_contracts',
  /** column name */
  payout = 'payout',
  /** column name */
  public_id = 'public_id',
  /** column name */
  status = 'status',
  /** column name */
  term_length = 'term_length',
  /** column name */
  updated_at = 'updated_at'
}

/** aggregate stddev on columns */
export type sell_orders_stddev_fields = {
  readonly __typename?: 'sell_orders_stddev_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly expected_payout?: Maybe<Scalars['Float']>;
  readonly num_contracts?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly term_length?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type sell_orders_stddev_pop_fields = {
  readonly __typename?: 'sell_orders_stddev_pop_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly expected_payout?: Maybe<Scalars['Float']>;
  readonly num_contracts?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly term_length?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type sell_orders_stddev_samp_fields = {
  readonly __typename?: 'sell_orders_stddev_samp_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly expected_payout?: Maybe<Scalars['Float']>;
  readonly num_contracts?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly term_length?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type sell_orders_sum_fields = {
  readonly __typename?: 'sell_orders_sum_fields';
  readonly balance?: Maybe<Scalars['bigint']>;
  readonly expected_payout?: Maybe<Scalars['bigint']>;
  readonly num_contracts?: Maybe<Scalars['Int']>;
  readonly payout?: Maybe<Scalars['bigint']>;
  readonly term_length?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type sell_orders_var_pop_fields = {
  readonly __typename?: 'sell_orders_var_pop_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly expected_payout?: Maybe<Scalars['Float']>;
  readonly num_contracts?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly term_length?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type sell_orders_var_samp_fields = {
  readonly __typename?: 'sell_orders_var_samp_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly expected_payout?: Maybe<Scalars['Float']>;
  readonly num_contracts?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly term_length?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type sell_orders_variance_fields = {
  readonly __typename?: 'sell_orders_variance_fields';
  readonly balance?: Maybe<Scalars['Float']>;
  readonly expected_payout?: Maybe<Scalars['Float']>;
  readonly num_contracts?: Maybe<Scalars['Float']>;
  readonly payout?: Maybe<Scalars['Float']>;
  readonly term_length?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type smallint_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['smallint']>;
  readonly _gt?: InputMaybe<Scalars['smallint']>;
  readonly _gte?: InputMaybe<Scalars['smallint']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['smallint']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['smallint']>;
  readonly _lte?: InputMaybe<Scalars['smallint']>;
  readonly _neq?: InputMaybe<Scalars['smallint']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['smallint']>>;
};

/** columns and relationships of "snapshot_historical_bank_data_view" */
export type snapshot_historical_bank_data_view = {
  readonly __typename?: 'snapshot_historical_bank_data_view';
  readonly adjusted_burn?: Maybe<Scalars['bigint']>;
  readonly balance?: Maybe<Scalars['bigint']>;
  readonly burn?: Maybe<Scalars['bigint']>;
  readonly currency?: Maybe<Scalars['currency']>;
  readonly inferred_from_transactions?: Maybe<Scalars['Boolean']>;
  readonly institution_name?: Maybe<Scalars['String']>;
  readonly ledger_timestamp?: Maybe<Scalars['timestamptz']>;
  readonly mask?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "snapshot_historical_bank_data_view" */
export type snapshot_historical_bank_data_view_aggregate_order_by = {
  readonly avg?: InputMaybe<snapshot_historical_bank_data_view_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<snapshot_historical_bank_data_view_max_order_by>;
  readonly min?: InputMaybe<snapshot_historical_bank_data_view_min_order_by>;
  readonly stddev?: InputMaybe<snapshot_historical_bank_data_view_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<snapshot_historical_bank_data_view_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<snapshot_historical_bank_data_view_stddev_samp_order_by>;
  readonly sum?: InputMaybe<snapshot_historical_bank_data_view_sum_order_by>;
  readonly var_pop?: InputMaybe<snapshot_historical_bank_data_view_var_pop_order_by>;
  readonly var_samp?: InputMaybe<snapshot_historical_bank_data_view_var_samp_order_by>;
  readonly variance?: InputMaybe<snapshot_historical_bank_data_view_variance_order_by>;
};

/** order by avg() on columns of table "snapshot_historical_bank_data_view" */
export type snapshot_historical_bank_data_view_avg_order_by = {
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  readonly burn?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "snapshot_historical_bank_data_view". All fields are combined with a logical 'AND'. */
export type snapshot_historical_bank_data_view_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<snapshot_historical_bank_data_view_bool_exp>>;
  readonly _not?: InputMaybe<snapshot_historical_bank_data_view_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<snapshot_historical_bank_data_view_bool_exp>>;
  readonly adjusted_burn?: InputMaybe<bigint_comparison_exp>;
  readonly balance?: InputMaybe<bigint_comparison_exp>;
  readonly burn?: InputMaybe<bigint_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly inferred_from_transactions?: InputMaybe<Boolean_comparison_exp>;
  readonly institution_name?: InputMaybe<String_comparison_exp>;
  readonly ledger_timestamp?: InputMaybe<timestamptz_comparison_exp>;
  readonly mask?: InputMaybe<String_comparison_exp>;
  readonly name?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
};

/** order by max() on columns of table "snapshot_historical_bank_data_view" */
export type snapshot_historical_bank_data_view_max_order_by = {
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  readonly burn?: InputMaybe<order_by>;
  readonly institution_name?: InputMaybe<order_by>;
  readonly ledger_timestamp?: InputMaybe<order_by>;
  readonly mask?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** order by min() on columns of table "snapshot_historical_bank_data_view" */
export type snapshot_historical_bank_data_view_min_order_by = {
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  readonly burn?: InputMaybe<order_by>;
  readonly institution_name?: InputMaybe<order_by>;
  readonly ledger_timestamp?: InputMaybe<order_by>;
  readonly mask?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "snapshot_historical_bank_data_view". */
export type snapshot_historical_bank_data_view_order_by = {
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  readonly burn?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly inferred_from_transactions?: InputMaybe<order_by>;
  readonly institution_name?: InputMaybe<order_by>;
  readonly ledger_timestamp?: InputMaybe<order_by>;
  readonly mask?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** select columns of table "snapshot_historical_bank_data_view" */
export enum snapshot_historical_bank_data_view_select_column {
  /** column name */
  adjusted_burn = 'adjusted_burn',
  /** column name */
  balance = 'balance',
  /** column name */
  burn = 'burn',
  /** column name */
  currency = 'currency',
  /** column name */
  inferred_from_transactions = 'inferred_from_transactions',
  /** column name */
  institution_name = 'institution_name',
  /** column name */
  ledger_timestamp = 'ledger_timestamp',
  /** column name */
  mask = 'mask',
  /** column name */
  name = 'name',
  /** column name */
  public_id = 'public_id'
}

/** order by stddev() on columns of table "snapshot_historical_bank_data_view" */
export type snapshot_historical_bank_data_view_stddev_order_by = {
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  readonly burn?: InputMaybe<order_by>;
};

/** order by stddev_pop() on columns of table "snapshot_historical_bank_data_view" */
export type snapshot_historical_bank_data_view_stddev_pop_order_by = {
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  readonly burn?: InputMaybe<order_by>;
};

/** order by stddev_samp() on columns of table "snapshot_historical_bank_data_view" */
export type snapshot_historical_bank_data_view_stddev_samp_order_by = {
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  readonly burn?: InputMaybe<order_by>;
};

/** order by sum() on columns of table "snapshot_historical_bank_data_view" */
export type snapshot_historical_bank_data_view_sum_order_by = {
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  readonly burn?: InputMaybe<order_by>;
};

/** order by var_pop() on columns of table "snapshot_historical_bank_data_view" */
export type snapshot_historical_bank_data_view_var_pop_order_by = {
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  readonly burn?: InputMaybe<order_by>;
};

/** order by var_samp() on columns of table "snapshot_historical_bank_data_view" */
export type snapshot_historical_bank_data_view_var_samp_order_by = {
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  readonly burn?: InputMaybe<order_by>;
};

/** order by variance() on columns of table "snapshot_historical_bank_data_view" */
export type snapshot_historical_bank_data_view_variance_order_by = {
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  readonly burn?: InputMaybe<order_by>;
};

/** columns and relationships of "snapshot_overrides" */
export type snapshot_overrides = {
  readonly __typename?: 'snapshot_overrides';
  readonly adjusted_burn?: Maybe<Scalars['bigint']>;
  readonly balance?: Maybe<Scalars['bigint']>;
  readonly burn?: Maybe<Scalars['bigint']>;
  readonly cash_balance?: Maybe<Scalars['bigint']>;
  readonly cogs?: Maybe<Scalars['bigint']>;
  readonly currency: Scalars['currency'];
  readonly current_assets?: Maybe<Scalars['bigint']>;
  readonly current_liabilities?: Maybe<Scalars['bigint']>;
  readonly customer_ratio_qoq?: Maybe<Scalars['float8']>;
  readonly customer_retention_1m_avg?: Maybe<Scalars['float8']>;
  readonly customer_retention_6m_avg?: Maybe<Scalars['float8']>;
  readonly customer_retention_12m_avg?: Maybe<Scalars['float8']>;
  readonly delinquent_ratio?: Maybe<Scalars['float8']>;
  readonly gross_margin?: Maybe<Scalars['float8']>;
  readonly net_income_trend_qoq?: Maybe<Scalars['float8']>;
  readonly public_id: Scalars['String'];
  readonly revenue?: Maybe<Scalars['bigint']>;
  readonly revenue_ratio_qoq?: Maybe<Scalars['float8']>;
  readonly revenue_retention_1m_avg?: Maybe<Scalars['float8']>;
  readonly revenue_retention_6m_avg?: Maybe<Scalars['float8']>;
  readonly revenue_retention_12m_avg?: Maybe<Scalars['float8']>;
  readonly total_addressable_arr?: Maybe<Scalars['bigint']>;
  readonly total_arr?: Maybe<Scalars['bigint']>;
  readonly total_assets?: Maybe<Scalars['bigint']>;
  readonly total_expenses?: Maybe<Scalars['bigint']>;
  readonly total_liabilities?: Maybe<Scalars['bigint']>;
};

/** Boolean expression to filter rows from the table "snapshot_overrides". All fields are combined with a logical 'AND'. */
export type snapshot_overrides_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<snapshot_overrides_bool_exp>>;
  readonly _not?: InputMaybe<snapshot_overrides_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<snapshot_overrides_bool_exp>>;
  readonly adjusted_burn?: InputMaybe<bigint_comparison_exp>;
  readonly balance?: InputMaybe<bigint_comparison_exp>;
  readonly burn?: InputMaybe<bigint_comparison_exp>;
  readonly cash_balance?: InputMaybe<bigint_comparison_exp>;
  readonly cogs?: InputMaybe<bigint_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly current_assets?: InputMaybe<bigint_comparison_exp>;
  readonly current_liabilities?: InputMaybe<bigint_comparison_exp>;
  readonly customer_ratio_qoq?: InputMaybe<float8_comparison_exp>;
  readonly customer_retention_1m_avg?: InputMaybe<float8_comparison_exp>;
  readonly customer_retention_6m_avg?: InputMaybe<float8_comparison_exp>;
  readonly customer_retention_12m_avg?: InputMaybe<float8_comparison_exp>;
  readonly delinquent_ratio?: InputMaybe<float8_comparison_exp>;
  readonly gross_margin?: InputMaybe<float8_comparison_exp>;
  readonly net_income_trend_qoq?: InputMaybe<float8_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly revenue?: InputMaybe<bigint_comparison_exp>;
  readonly revenue_ratio_qoq?: InputMaybe<float8_comparison_exp>;
  readonly revenue_retention_1m_avg?: InputMaybe<float8_comparison_exp>;
  readonly revenue_retention_6m_avg?: InputMaybe<float8_comparison_exp>;
  readonly revenue_retention_12m_avg?: InputMaybe<float8_comparison_exp>;
  readonly total_addressable_arr?: InputMaybe<bigint_comparison_exp>;
  readonly total_arr?: InputMaybe<bigint_comparison_exp>;
  readonly total_assets?: InputMaybe<bigint_comparison_exp>;
  readonly total_expenses?: InputMaybe<bigint_comparison_exp>;
  readonly total_liabilities?: InputMaybe<bigint_comparison_exp>;
};

/** Ordering options when selecting data from "snapshot_overrides". */
export type snapshot_overrides_order_by = {
  readonly adjusted_burn?: InputMaybe<order_by>;
  readonly balance?: InputMaybe<order_by>;
  readonly burn?: InputMaybe<order_by>;
  readonly cash_balance?: InputMaybe<order_by>;
  readonly cogs?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly current_assets?: InputMaybe<order_by>;
  readonly current_liabilities?: InputMaybe<order_by>;
  readonly customer_ratio_qoq?: InputMaybe<order_by>;
  readonly customer_retention_1m_avg?: InputMaybe<order_by>;
  readonly customer_retention_6m_avg?: InputMaybe<order_by>;
  readonly customer_retention_12m_avg?: InputMaybe<order_by>;
  readonly delinquent_ratio?: InputMaybe<order_by>;
  readonly gross_margin?: InputMaybe<order_by>;
  readonly net_income_trend_qoq?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly revenue?: InputMaybe<order_by>;
  readonly revenue_ratio_qoq?: InputMaybe<order_by>;
  readonly revenue_retention_1m_avg?: InputMaybe<order_by>;
  readonly revenue_retention_6m_avg?: InputMaybe<order_by>;
  readonly revenue_retention_12m_avg?: InputMaybe<order_by>;
  readonly total_addressable_arr?: InputMaybe<order_by>;
  readonly total_arr?: InputMaybe<order_by>;
  readonly total_assets?: InputMaybe<order_by>;
  readonly total_expenses?: InputMaybe<order_by>;
  readonly total_liabilities?: InputMaybe<order_by>;
};

/** select columns of table "snapshot_overrides" */
export enum snapshot_overrides_select_column {
  /** column name */
  adjusted_burn = 'adjusted_burn',
  /** column name */
  balance = 'balance',
  /** column name */
  burn = 'burn',
  /** column name */
  cash_balance = 'cash_balance',
  /** column name */
  cogs = 'cogs',
  /** column name */
  currency = 'currency',
  /** column name */
  current_assets = 'current_assets',
  /** column name */
  current_liabilities = 'current_liabilities',
  /** column name */
  customer_ratio_qoq = 'customer_ratio_qoq',
  /** column name */
  customer_retention_1m_avg = 'customer_retention_1m_avg',
  /** column name */
  customer_retention_6m_avg = 'customer_retention_6m_avg',
  /** column name */
  customer_retention_12m_avg = 'customer_retention_12m_avg',
  /** column name */
  delinquent_ratio = 'delinquent_ratio',
  /** column name */
  gross_margin = 'gross_margin',
  /** column name */
  net_income_trend_qoq = 'net_income_trend_qoq',
  /** column name */
  public_id = 'public_id',
  /** column name */
  revenue = 'revenue',
  /** column name */
  revenue_ratio_qoq = 'revenue_ratio_qoq',
  /** column name */
  revenue_retention_1m_avg = 'revenue_retention_1m_avg',
  /** column name */
  revenue_retention_6m_avg = 'revenue_retention_6m_avg',
  /** column name */
  revenue_retention_12m_avg = 'revenue_retention_12m_avg',
  /** column name */
  total_addressable_arr = 'total_addressable_arr',
  /** column name */
  total_arr = 'total_arr',
  /** column name */
  total_assets = 'total_assets',
  /** column name */
  total_expenses = 'total_expenses',
  /** column name */
  total_liabilities = 'total_liabilities'
}

/** columns and relationships of "snapshots" */
export type snapshots = {
  readonly __typename?: 'snapshots';
  /** An object relationship */
  readonly accounting_metrics: accounting_metrics;
  /** An array relationship */
  readonly activity_logs: ReadonlyArray<activity_log>;
  /** An object relationship */
  readonly applied_vendor_decision?: Maybe<vendor_decisions>;
  /** An array relationship */
  readonly bank_data: ReadonlyArray<snapshot_historical_bank_data_view>;
  readonly created_at: Scalars['timestamptz'];
  readonly currency: Scalars['currency'];
  readonly decision_is_applied?: Maybe<Scalars['Boolean']>;
  /** Whether the snapshot data has been manually validated */
  readonly is_validated: Scalars['Boolean'];
  readonly notes?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  readonly overrides?: Maybe<snapshot_overrides>;
  readonly public_id: Scalars['String'];
  /** An object relationship */
  readonly suggested_vendor_decision?: Maybe<vendor_decisions>;
  /** An object relationship */
  readonly user: users;
  /** An object relationship */
  readonly vendor: vendors;
  /** An object relationship */
  readonly vendor_metrics: vendor_metrics;
};


/** columns and relationships of "snapshots" */
export type snapshotsactivity_logsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<activity_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<activity_log_order_by>>;
  where?: InputMaybe<activity_log_bool_exp>;
};


/** columns and relationships of "snapshots" */
export type snapshotsbank_dataArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<snapshot_historical_bank_data_view_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<snapshot_historical_bank_data_view_order_by>>;
  where?: InputMaybe<snapshot_historical_bank_data_view_bool_exp>;
};


/** columns and relationships of "snapshots" */
export type snapshotsnotesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "snapshots" */
export type snapshots_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<snapshots_max_order_by>;
  readonly min?: InputMaybe<snapshots_min_order_by>;
};

/** Boolean expression to filter rows from the table "snapshots". All fields are combined with a logical 'AND'. */
export type snapshots_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<snapshots_bool_exp>>;
  readonly _not?: InputMaybe<snapshots_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<snapshots_bool_exp>>;
  readonly accounting_metrics?: InputMaybe<accounting_metrics_bool_exp>;
  readonly activity_logs?: InputMaybe<activity_log_bool_exp>;
  readonly applied_vendor_decision?: InputMaybe<vendor_decisions_bool_exp>;
  readonly bank_data?: InputMaybe<snapshot_historical_bank_data_view_bool_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly decision_is_applied?: InputMaybe<Boolean_comparison_exp>;
  readonly is_validated?: InputMaybe<Boolean_comparison_exp>;
  readonly notes?: InputMaybe<jsonb_comparison_exp>;
  readonly overrides?: InputMaybe<snapshot_overrides_bool_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly suggested_vendor_decision?: InputMaybe<vendor_decisions_bool_exp>;
  readonly user?: InputMaybe<users_bool_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
  readonly vendor_metrics?: InputMaybe<vendor_metrics_bool_exp>;
};

/** order by max() on columns of table "snapshots" */
export type snapshots_max_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** order by min() on columns of table "snapshots" */
export type snapshots_min_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "snapshots". */
export type snapshots_order_by = {
  readonly accounting_metrics?: InputMaybe<accounting_metrics_order_by>;
  readonly activity_logs_aggregate?: InputMaybe<activity_log_aggregate_order_by>;
  readonly applied_vendor_decision?: InputMaybe<vendor_decisions_order_by>;
  readonly bank_data_aggregate?: InputMaybe<snapshot_historical_bank_data_view_aggregate_order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly decision_is_applied?: InputMaybe<order_by>;
  readonly is_validated?: InputMaybe<order_by>;
  readonly notes?: InputMaybe<order_by>;
  readonly overrides?: InputMaybe<snapshot_overrides_order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly suggested_vendor_decision?: InputMaybe<vendor_decisions_order_by>;
  readonly user?: InputMaybe<users_order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
  readonly vendor_metrics?: InputMaybe<vendor_metrics_order_by>;
};

/** select columns of table "snapshots" */
export enum snapshots_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  decision_is_applied = 'decision_is_applied',
  /** column name */
  is_validated = 'is_validated',
  /** column name */
  notes = 'notes',
  /** column name */
  public_id = 'public_id'
}

/** columns and relationships of "stripe_data_sources" */
export type stripe_data_sources = {
  readonly __typename?: 'stripe_data_sources';
  readonly public_id: Scalars['String'];
  /** Used for accounts connected via OAuth. */
  readonly stripe_account_id?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "stripe_data_sources". All fields are combined with a logical 'AND'. */
export type stripe_data_sources_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<stripe_data_sources_bool_exp>>;
  readonly _not?: InputMaybe<stripe_data_sources_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<stripe_data_sources_bool_exp>>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly stripe_account_id?: InputMaybe<String_comparison_exp>;
};

/** Ordering options when selecting data from "stripe_data_sources". */
export type stripe_data_sources_order_by = {
  readonly public_id?: InputMaybe<order_by>;
  readonly stripe_account_id?: InputMaybe<order_by>;
};

/** select columns of table "stripe_data_sources" */
export enum stripe_data_sources_select_column {
  /** column name */
  public_id = 'public_id',
  /** column name */
  stripe_account_id = 'stripe_account_id'
}

/** columns and relationships of "subscription_payments" */
export type subscription_payments = {
  readonly __typename?: 'subscription_payments';
  readonly amount: Scalars['Int'];
  readonly created_at: Scalars['timestamptz'];
  /** Category of payment or payout, e.g. rebate or payout */
  readonly payment_subtype?: Maybe<payment_subtype_enum_enum>;
  readonly payment_type: Scalars['String'];
  /** An object relationship */
  readonly pipe_agreement: pipe_agreements;
  readonly public_id: Scalars['String'];
  readonly sequence_number?: Maybe<Scalars['Int']>;
  readonly updated_at: Scalars['timestamptz'];
  /** An object relationship */
  readonly vendor_payment: vendor_payments;
};

/** aggregated selection of "subscription_payments" */
export type subscription_payments_aggregate = {
  readonly __typename?: 'subscription_payments_aggregate';
  readonly aggregate?: Maybe<subscription_payments_aggregate_fields>;
  readonly nodes: ReadonlyArray<subscription_payments>;
};

/** aggregate fields of "subscription_payments" */
export type subscription_payments_aggregate_fields = {
  readonly __typename?: 'subscription_payments_aggregate_fields';
  readonly avg?: Maybe<subscription_payments_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<subscription_payments_max_fields>;
  readonly min?: Maybe<subscription_payments_min_fields>;
  readonly stddev?: Maybe<subscription_payments_stddev_fields>;
  readonly stddev_pop?: Maybe<subscription_payments_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<subscription_payments_stddev_samp_fields>;
  readonly sum?: Maybe<subscription_payments_sum_fields>;
  readonly var_pop?: Maybe<subscription_payments_var_pop_fields>;
  readonly var_samp?: Maybe<subscription_payments_var_samp_fields>;
  readonly variance?: Maybe<subscription_payments_variance_fields>;
};


/** aggregate fields of "subscription_payments" */
export type subscription_payments_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<subscription_payments_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "subscription_payments" */
export type subscription_payments_aggregate_order_by = {
  readonly avg?: InputMaybe<subscription_payments_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<subscription_payments_max_order_by>;
  readonly min?: InputMaybe<subscription_payments_min_order_by>;
  readonly stddev?: InputMaybe<subscription_payments_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<subscription_payments_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<subscription_payments_stddev_samp_order_by>;
  readonly sum?: InputMaybe<subscription_payments_sum_order_by>;
  readonly var_pop?: InputMaybe<subscription_payments_var_pop_order_by>;
  readonly var_samp?: InputMaybe<subscription_payments_var_samp_order_by>;
  readonly variance?: InputMaybe<subscription_payments_variance_order_by>;
};

/** aggregate avg on columns */
export type subscription_payments_avg_fields = {
  readonly __typename?: 'subscription_payments_avg_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly sequence_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "subscription_payments" */
export type subscription_payments_avg_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly sequence_number?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscription_payments". All fields are combined with a logical 'AND'. */
export type subscription_payments_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<subscription_payments_bool_exp>>;
  readonly _not?: InputMaybe<subscription_payments_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<subscription_payments_bool_exp>>;
  readonly amount?: InputMaybe<Int_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly payment_subtype?: InputMaybe<payment_subtype_enum_enum_comparison_exp>;
  readonly payment_type?: InputMaybe<String_comparison_exp>;
  readonly pipe_agreement?: InputMaybe<pipe_agreements_bool_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly sequence_number?: InputMaybe<Int_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly vendor_payment?: InputMaybe<vendor_payments_bool_exp>;
};

/** aggregate max on columns */
export type subscription_payments_max_fields = {
  readonly __typename?: 'subscription_payments_max_fields';
  readonly amount?: Maybe<Scalars['Int']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly payment_type?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly sequence_number?: Maybe<Scalars['Int']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "subscription_payments" */
export type subscription_payments_max_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly payment_type?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly sequence_number?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscription_payments_min_fields = {
  readonly __typename?: 'subscription_payments_min_fields';
  readonly amount?: Maybe<Scalars['Int']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly payment_type?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly sequence_number?: Maybe<Scalars['Int']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "subscription_payments" */
export type subscription_payments_min_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly payment_type?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly sequence_number?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "subscription_payments". */
export type subscription_payments_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly payment_subtype?: InputMaybe<order_by>;
  readonly payment_type?: InputMaybe<order_by>;
  readonly pipe_agreement?: InputMaybe<pipe_agreements_order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly sequence_number?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly vendor_payment?: InputMaybe<vendor_payments_order_by>;
};

/** select columns of table "subscription_payments" */
export enum subscription_payments_select_column {
  /** column name */
  amount = 'amount',
  /** column name */
  created_at = 'created_at',
  /** column name */
  payment_subtype = 'payment_subtype',
  /** column name */
  payment_type = 'payment_type',
  /** column name */
  public_id = 'public_id',
  /** column name */
  sequence_number = 'sequence_number',
  /** column name */
  updated_at = 'updated_at'
}

/** aggregate stddev on columns */
export type subscription_payments_stddev_fields = {
  readonly __typename?: 'subscription_payments_stddev_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly sequence_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "subscription_payments" */
export type subscription_payments_stddev_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly sequence_number?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscription_payments_stddev_pop_fields = {
  readonly __typename?: 'subscription_payments_stddev_pop_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly sequence_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "subscription_payments" */
export type subscription_payments_stddev_pop_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly sequence_number?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscription_payments_stddev_samp_fields = {
  readonly __typename?: 'subscription_payments_stddev_samp_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly sequence_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "subscription_payments" */
export type subscription_payments_stddev_samp_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly sequence_number?: InputMaybe<order_by>;
};

/** aggregate sum on columns */
export type subscription_payments_sum_fields = {
  readonly __typename?: 'subscription_payments_sum_fields';
  readonly amount?: Maybe<Scalars['Int']>;
  readonly sequence_number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "subscription_payments" */
export type subscription_payments_sum_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly sequence_number?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type subscription_payments_var_pop_fields = {
  readonly __typename?: 'subscription_payments_var_pop_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly sequence_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "subscription_payments" */
export type subscription_payments_var_pop_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly sequence_number?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscription_payments_var_samp_fields = {
  readonly __typename?: 'subscription_payments_var_samp_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly sequence_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "subscription_payments" */
export type subscription_payments_var_samp_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly sequence_number?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscription_payments_variance_fields = {
  readonly __typename?: 'subscription_payments_variance_fields';
  readonly amount?: Maybe<Scalars['Float']>;
  readonly sequence_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "subscription_payments" */
export type subscription_payments_variance_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly sequence_number?: InputMaybe<order_by>;
};

export type subscription_root = {
  readonly __typename?: 'subscription_root';
  /** fetch data from the table: "account_bank_balance_deltas" */
  readonly account_bank_balance_deltas: ReadonlyArray<account_bank_balance_deltas>;
  /** fetch aggregated fields from the table: "account_bank_balance_deltas" */
  readonly account_bank_balance_deltas_aggregate: account_bank_balance_deltas_aggregate;
  /** fetch data from the table: "accounting_metrics" */
  readonly accounting_metrics: ReadonlyArray<accounting_metrics>;
  /** An array relationship */
  readonly accounts: ReadonlyArray<accounts>;
  /** fetch data from the table: "activity_log" */
  readonly activity_log: ReadonlyArray<activity_log>;
  /** fetch data from the table: "activity_log_file_upload" */
  readonly activity_log_file_upload: ReadonlyArray<activity_log_file_upload>;
  /** fetch data from the table: "activity_log_integration_status" */
  readonly activity_log_integration_status: ReadonlyArray<activity_log_integration_status>;
  /** fetch data from the table: "activity_log_settings_update" */
  readonly activity_log_settings_update: ReadonlyArray<activity_log_settings_update>;
  /** fetch data from the table: "activity_log_traded_revenue_streams" */
  readonly activity_log_traded_revenue_streams: ReadonlyArray<activity_log_traded_revenue_streams>;
  /** fetch data from the table: "activity_log_underwriting_update" */
  readonly activity_log_underwriting_update: ReadonlyArray<activity_log_underwriting_update>;
  /** An array relationship */
  readonly api_key_allowed_ips: ReadonlyArray<api_key_allowed_ips>;
  /** fetch data from the table: "api_keys" */
  readonly api_keys: ReadonlyArray<api_keys>;
  /** fetch data from the table: "apple_data_sources" */
  readonly apple_data_sources: ReadonlyArray<apple_data_sources>;
  /** fetch data from the table: "asset_churn_batches" */
  readonly asset_churn_batches: ReadonlyArray<asset_churn_batches>;
  /** fetch data from the table: "asset_swaps" */
  readonly asset_swaps: ReadonlyArray<asset_swaps>;
  /** fetch data from the table: "background_jobs" */
  readonly background_jobs: ReadonlyArray<background_jobs>;
  /** fetch aggregated fields from the table: "background_jobs" */
  readonly background_jobs_aggregate: background_jobs_aggregate;
  /** fetch data from the table: "background_jobs_settings" */
  readonly background_jobs_settings: ReadonlyArray<background_jobs_settings>;
  /** An array relationship */
  readonly balance_sheet_reports: ReadonlyArray<balance_sheet_reports>;
  /** execute function "calculate_accounting_metrics" which returns "accounting_metrics" */
  readonly calculate_accounting_metrics: ReadonlyArray<accounting_metrics>;
  /** fetch data from the table: "chargebee_data_sources" */
  readonly chargebee_data_sources: ReadonlyArray<chargebee_data_sources>;
  /** fetch data from the table: "chargify_data_sources" */
  readonly chargify_data_sources: ReadonlyArray<chargify_data_sources>;
  /** fetch data from the table: "clearbit_companies" */
  readonly clearbit_companies: ReadonlyArray<clearbit_companies>;
  /** fetch data from the table: "codat_companies" */
  readonly codat_companies: ReadonlyArray<codat_companies>;
  /** fetch data from the table: "csv_tasks_for_vendors" */
  readonly csv_tasks_for_vendors: ReadonlyArray<csv_tasks_for_vendors>;
  /** An array relationship */
  readonly customers: ReadonlyArray<customers>;
  /** An aggregate relationship */
  readonly customers_aggregate: customers_aggregate;
  /** An array relationship */
  readonly data_sources: ReadonlyArray<data_sources>;
  /** fetch data from the table: "db_admin_indexes" */
  readonly db_admin_indexes: ReadonlyArray<db_admin_indexes>;
  /** fetch data from the table: "db_admin_queries" */
  readonly db_admin_queries: ReadonlyArray<db_admin_queries>;
  /** fetch data from the table: "db_admin_relations" */
  readonly db_admin_relations: ReadonlyArray<db_admin_relations>;
  /** fetch data from the table: "fingerprints" */
  readonly fingerprints: ReadonlyArray<fingerprints>;
  /** fetch aggregated fields from the table: "fingerprints" */
  readonly fingerprints_aggregate: fingerprints_aggregate;
  /** fetch data from the table: "gocardless_data_sources" */
  readonly gocardless_data_sources: ReadonlyArray<gocardless_data_sources>;
  /** fetch data from the table: "heron_annotated_transactions" */
  readonly heron_annotated_transactions: ReadonlyArray<heron_annotated_transactions>;
  /** fetch data from the table: "heron_categories" */
  readonly heron_categories: ReadonlyArray<heron_categories>;
  /** An array relationship */
  readonly historical_bank_data: ReadonlyArray<historical_bank_data>;
  /** An array relationship */
  readonly investor_asset_receipts: ReadonlyArray<investor_asset_receipts>;
  /** An aggregate relationship */
  readonly investor_asset_receipts_aggregate: investor_asset_receipts_aggregate;
  /** fetch data from the table: "investor_payment_batches" */
  readonly investor_payment_batches: ReadonlyArray<investor_payment_batches>;
  /** An array relationship */
  readonly investor_payments: ReadonlyArray<investor_payments>;
  /** fetch data from the table: "investor_portfolio_settings" */
  readonly investor_portfolio_settings: ReadonlyArray<investor_portfolio_settings>;
  /** fetch data from the table: "investors" */
  readonly investors: ReadonlyArray<investors>;
  /** An array relationship */
  readonly legal_entities: ReadonlyArray<legal_entities>;
  /** An aggregate relationship */
  readonly legal_entities_aggregate: legal_entities_aggregate;
  /** fetch data from the table: "limit_buy_orders" */
  readonly limit_buy_orders: ReadonlyArray<limit_buy_orders>;
  /** An array relationship */
  readonly lists: ReadonlyArray<lists>;
  /** An aggregate relationship */
  readonly lists_aggregate: lists_aggregate;
  /** fetch data from the table: "lists_investor_asset_receipts" */
  readonly lists_investor_asset_receipts: ReadonlyArray<lists_investor_asset_receipts>;
  /** fetch aggregated fields from the table: "lists_investor_asset_receipts" */
  readonly lists_investor_asset_receipts_aggregate: lists_investor_asset_receipts_aggregate;
  /** fetch data from the table: "lists_investor_payments" */
  readonly lists_investor_payments: ReadonlyArray<lists_investor_payments>;
  /** fetch aggregated fields from the table: "lists_investor_payments" */
  readonly lists_investor_payments_aggregate: lists_investor_payments_aggregate;
  /** fetch data from the table: "lists_investor_transfers" */
  readonly lists_investor_transfers: ReadonlyArray<lists_investor_transfers>;
  /** fetch aggregated fields from the table: "lists_investor_transfers" */
  readonly lists_investor_transfers_aggregate: lists_investor_transfers_aggregate;
  /** fetch data from the table: "lists_investor_users" */
  readonly lists_investor_users: ReadonlyArray<lists_investor_users>;
  /** fetch aggregated fields from the table: "lists_investor_users" */
  readonly lists_investor_users_aggregate: lists_investor_users_aggregate;
  /** fetch data from the table: "lists_limit_buy_orders" */
  readonly lists_limit_buy_orders: ReadonlyArray<lists_limit_buy_orders>;
  /** fetch aggregated fields from the table: "lists_limit_buy_orders" */
  readonly lists_limit_buy_orders_aggregate: lists_limit_buy_orders_aggregate;
  /** fetch data from the table: "lists_payment_tasks" */
  readonly lists_payment_tasks: ReadonlyArray<lists_payment_tasks>;
  /** fetch aggregated fields from the table: "lists_payment_tasks" */
  readonly lists_payment_tasks_aggregate: lists_payment_tasks_aggregate;
  /** fetch data from the table: "lists_payments" */
  readonly lists_payments: ReadonlyArray<lists_payments>;
  /** fetch aggregated fields from the table: "lists_payments" */
  readonly lists_payments_aggregate: lists_payments_aggregate;
  /** fetch data from the table: "lists_sub_payments" */
  readonly lists_sub_payments: ReadonlyArray<lists_sub_payments>;
  /** fetch aggregated fields from the table: "lists_sub_payments" */
  readonly lists_sub_payments_aggregate: lists_sub_payments_aggregate;
  /** fetch data from the table: "lists_tradeable_contracts" */
  readonly lists_tradeable_contracts: ReadonlyArray<lists_tradeable_contracts>;
  /** fetch aggregated fields from the table: "lists_tradeable_contracts" */
  readonly lists_tradeable_contracts_aggregate: lists_tradeable_contracts_aggregate;
  /** fetch data from the table: "lists_trades" */
  readonly lists_trades: ReadonlyArray<lists_trades>;
  /** fetch aggregated fields from the table: "lists_trades" */
  readonly lists_trades_aggregate: lists_trades_aggregate;
  /** fetch data from the table: "lists_users" */
  readonly lists_users: ReadonlyArray<lists_users>;
  /** fetch aggregated fields from the table: "lists_users" */
  readonly lists_users_aggregate: lists_users_aggregate;
  /** fetch data from the table: "lists_vendor_bank_transactions" */
  readonly lists_vendor_bank_transactions: ReadonlyArray<lists_vendor_bank_transactions>;
  /** fetch aggregated fields from the table: "lists_vendor_bank_transactions" */
  readonly lists_vendor_bank_transactions_aggregate: lists_vendor_bank_transactions_aggregate;
  /** fetch data from the table: "low_end_experiment_candidates" */
  readonly low_end_experiment_candidates: ReadonlyArray<low_end_experiment_candidates>;
  /** An array relationship */
  readonly manual_data_sources: ReadonlyArray<manual_data_sources>;
  /** fetch data from the table: "manual_ops" */
  readonly manual_ops: ReadonlyArray<manual_ops>;
  /** An array relationship */
  readonly pipe_agreements: ReadonlyArray<pipe_agreements>;
  /** An aggregate relationship */
  readonly pipe_agreements_aggregate: pipe_agreements_aggregate;
  /** An array relationship */
  readonly pipe_proposals: ReadonlyArray<pipe_proposals>;
  /** An aggregate relationship */
  readonly pipe_proposals_aggregate: pipe_proposals_aggregate;
  /** An array relationship */
  readonly plaid_items: ReadonlyArray<plaid_items>;
  /** An aggregate relationship */
  readonly plaid_items_aggregate: plaid_items_aggregate;
  /** An array relationship */
  readonly profit_and_loss_reports: ReadonlyArray<profit_and_loss_reports>;
  /** fetch data from the table: "ratings_churn_metrics_usd" */
  readonly ratings_churn_metrics_usd: ReadonlyArray<ratings_churn_metrics_usd>;
  /** fetch data from the table: "ratings_concern_evaluation_overrides" */
  readonly ratings_concern_evaluation_overrides: ReadonlyArray<ratings_concern_evaluation_overrides>;
  /** fetch data from the table: "ratings_concern_evaluations" */
  readonly ratings_concern_evaluations: ReadonlyArray<ratings_concern_evaluations>;
  /** fetch data from the table: "ratings_concerns" */
  readonly ratings_concerns: ReadonlyArray<ratings_concerns>;
  /** fetch data from the table: "ratings_historical_bank_balances_usd" */
  readonly ratings_historical_bank_balances_usd: ReadonlyArray<ratings_historical_bank_balances_usd>;
  /** fetch data from the table: "ratings_revenue_metrics_usd" */
  readonly ratings_revenue_metrics_usd: ReadonlyArray<ratings_revenue_metrics_usd>;
  /** fetch data from the table: "recurly_data_sources" */
  readonly recurly_data_sources: ReadonlyArray<recurly_data_sources>;
  /** An array relationship */
  readonly revenue_streams: ReadonlyArray<revenue_streams>;
  /** An aggregate relationship */
  readonly revenue_streams_aggregate: revenue_streams_aggregate;
  /** fetch data from the table: "roles" */
  readonly roles: ReadonlyArray<roles>;
  /** fetch data from the table: "roles_permissions" */
  readonly roles_permissions: ReadonlyArray<roles_permissions>;
  /** fetch data from the table: "sell_orders" */
  readonly sell_orders: ReadonlyArray<sell_orders>;
  /** fetch aggregated fields from the table: "sell_orders" */
  readonly sell_orders_aggregate: sell_orders_aggregate;
  /** fetch data from the table: "sell_orders_ownership" */
  readonly sell_orders_ownership: ReadonlyArray<sell_orders_ownership>;
  /** fetch aggregated fields from the table: "sell_orders_ownership" */
  readonly sell_orders_ownership_aggregate: sell_orders_ownership_aggregate;
  /** fetch data from the table: "snapshot_historical_bank_data_view" */
  readonly snapshot_historical_bank_data_view: ReadonlyArray<snapshot_historical_bank_data_view>;
  /** fetch data from the table: "snapshot_overrides" */
  readonly snapshot_overrides: ReadonlyArray<snapshot_overrides>;
  /** An array relationship */
  readonly snapshots: ReadonlyArray<snapshots>;
  /** fetch data from the table: "stripe_data_sources" */
  readonly stripe_data_sources: ReadonlyArray<stripe_data_sources>;
  /** An array relationship */
  readonly subscription_payments: ReadonlyArray<subscription_payments>;
  /** An aggregate relationship */
  readonly subscription_payments_aggregate: subscription_payments_aggregate;
  /** fetch data from the table: "trade_volume_history" */
  readonly trade_volume_history: ReadonlyArray<trade_volume_history>;
  /** An array relationship */
  readonly transactions: ReadonlyArray<transactions>;
  /** An array relationship */
  readonly uploaded_vendor_data: ReadonlyArray<uploaded_vendor_data>;
  /** An aggregate relationship */
  readonly uploaded_vendor_data_aggregate: uploaded_vendor_data_aggregate;
  /** An array relationship */
  readonly users: ReadonlyArray<users>;
  /** An aggregate relationship */
  readonly users_aggregate: users_aggregate;
  /** fetch data from the table: "users_permissions" */
  readonly users_permissions: ReadonlyArray<users_permissions>;
  /** An array relationship */
  readonly users_roles: ReadonlyArray<users_roles>;
  /** An array relationship */
  readonly vendor_active_ratings: ReadonlyArray<vendor_active_ratings>;
  /** An array relationship */
  readonly vendor_bank_balances: ReadonlyArray<vendor_bank_balances>;
  /** fetch data from the table: "vendor_current_sales_funnel_stage" */
  readonly vendor_current_sales_funnel_stage: ReadonlyArray<vendor_current_sales_funnel_stage>;
  /** An array relationship */
  readonly vendor_custom_fields: ReadonlyArray<vendor_custom_fields>;
  /** fetch data from the table: "vendor_decisions" */
  readonly vendor_decisions: ReadonlyArray<vendor_decisions>;
  /** fetch data from the table: "vendor_events_summary" */
  readonly vendor_events_summary: ReadonlyArray<vendor_events_summary>;
  /** fetch data from the table: "vendor_financials" */
  readonly vendor_financials: ReadonlyArray<vendor_financials>;
  /** fetch aggregated fields from the table: "vendor_financials" */
  readonly vendor_financials_aggregate: vendor_financials_aggregate;
  /** fetch data from the table: "vendor_financials_mrr_mom" */
  readonly vendor_financials_mrr_mom: ReadonlyArray<vendor_financials_mrr_mom>;
  /** An array relationship */
  readonly vendor_metric_jobs: ReadonlyArray<vendor_metric_jobs>;
  /** An array relationship */
  readonly vendor_metrics: ReadonlyArray<vendor_metrics>;
  /** fetch data from the table: "vendor_metrics_latest" */
  readonly vendor_metrics_latest: ReadonlyArray<vendor_metrics_latest>;
  /** fetch data from the table: "vendor_payment_status" */
  readonly vendor_payment_status: ReadonlyArray<vendor_payment_status>;
  /** fetch aggregated fields from the table: "vendor_payment_status" */
  readonly vendor_payment_status_aggregate: vendor_payment_status_aggregate;
  /** An array relationship */
  readonly vendor_payments: ReadonlyArray<vendor_payments>;
  /** An aggregate relationship */
  readonly vendor_payments_aggregate: vendor_payments_aggregate;
  /** fetch data from the table: "vendor_payments_enriched" */
  readonly vendor_payments_enriched: ReadonlyArray<vendor_payments_enriched>;
  /** fetch data from the table: "vendor_payments_extended" */
  readonly vendor_payments_extended: ReadonlyArray<vendor_payments_extended>;
  /** fetch data from the table: "vendor_product_waitlist" */
  readonly vendor_product_waitlist: ReadonlyArray<vendor_product_waitlist>;
  /** fetch aggregated fields from the table: "vendor_product_waitlist" */
  readonly vendor_product_waitlist_aggregate: vendor_product_waitlist_aggregate;
  /** fetch data from the table: "vendor_ratings" */
  readonly vendor_ratings: ReadonlyArray<vendor_ratings>;
  /** An array relationship */
  readonly vendor_ratings_metadata: ReadonlyArray<vendor_ratings_metadata>;
  /** An array relationship */
  readonly vendor_supported_currencies: ReadonlyArray<vendor_supported_currencies>;
  /** fetch data from the table: "vendor_trades" */
  readonly vendor_trades: ReadonlyArray<vendor_trades>;
  /** An array relationship */
  readonly vendor_treasury_accounts: ReadonlyArray<vendor_treasury_accounts>;
  /** fetch data from the table: "vendor_treasury_balances" */
  readonly vendor_treasury_balances: ReadonlyArray<vendor_treasury_balances>;
  /** An array relationship */
  readonly vendor_treasury_daily_cumulative_balances: ReadonlyArray<vendor_treasury_daily_cumulative_balances>;
  /** An array relationship */
  readonly vendor_treasury_transactions: ReadonlyArray<vendor_treasury_transactions>;
  /** fetch data from the table: "vendor_visit_summary" */
  readonly vendor_visit_summary: ReadonlyArray<vendor_visit_summary>;
  /** An array relationship */
  readonly vendors: ReadonlyArray<vendors>;
  /** An aggregate relationship */
  readonly vendors_aggregate: vendors_aggregate;
  /** An array relationship */
  readonly visits: ReadonlyArray<visits>;
  /** An aggregate relationship */
  readonly visits_aggregate: visits_aggregate;
};


export type subscription_rootaccount_bank_balance_deltasArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<account_bank_balance_deltas_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<account_bank_balance_deltas_order_by>>;
  where?: InputMaybe<account_bank_balance_deltas_bool_exp>;
};


export type subscription_rootaccount_bank_balance_deltas_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<account_bank_balance_deltas_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<account_bank_balance_deltas_order_by>>;
  where?: InputMaybe<account_bank_balance_deltas_bool_exp>;
};


export type subscription_rootaccounting_metricsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<accounting_metrics_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<accounting_metrics_order_by>>;
  where?: InputMaybe<accounting_metrics_bool_exp>;
};


export type subscription_rootaccountsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<accounts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<accounts_order_by>>;
  where?: InputMaybe<accounts_bool_exp>;
};


export type subscription_rootactivity_logArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<activity_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<activity_log_order_by>>;
  where?: InputMaybe<activity_log_bool_exp>;
};


export type subscription_rootactivity_log_file_uploadArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<activity_log_file_upload_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<activity_log_file_upload_order_by>>;
  where?: InputMaybe<activity_log_file_upload_bool_exp>;
};


export type subscription_rootactivity_log_integration_statusArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<activity_log_integration_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<activity_log_integration_status_order_by>>;
  where?: InputMaybe<activity_log_integration_status_bool_exp>;
};


export type subscription_rootactivity_log_settings_updateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<activity_log_settings_update_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<activity_log_settings_update_order_by>>;
  where?: InputMaybe<activity_log_settings_update_bool_exp>;
};


export type subscription_rootactivity_log_traded_revenue_streamsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<activity_log_traded_revenue_streams_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<activity_log_traded_revenue_streams_order_by>>;
  where?: InputMaybe<activity_log_traded_revenue_streams_bool_exp>;
};


export type subscription_rootactivity_log_underwriting_updateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<activity_log_underwriting_update_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<activity_log_underwriting_update_order_by>>;
  where?: InputMaybe<activity_log_underwriting_update_bool_exp>;
};


export type subscription_rootapi_key_allowed_ipsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<api_key_allowed_ips_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<api_key_allowed_ips_order_by>>;
  where?: InputMaybe<api_key_allowed_ips_bool_exp>;
};


export type subscription_rootapi_keysArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<api_keys_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<api_keys_order_by>>;
  where?: InputMaybe<api_keys_bool_exp>;
};


export type subscription_rootapple_data_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<apple_data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<apple_data_sources_order_by>>;
  where?: InputMaybe<apple_data_sources_bool_exp>;
};


export type subscription_rootasset_churn_batchesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<asset_churn_batches_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<asset_churn_batches_order_by>>;
  where?: InputMaybe<asset_churn_batches_bool_exp>;
};


export type subscription_rootasset_swapsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<asset_swaps_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<asset_swaps_order_by>>;
  where?: InputMaybe<asset_swaps_bool_exp>;
};


export type subscription_rootbackground_jobsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<background_jobs_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<background_jobs_order_by>>;
  where?: InputMaybe<background_jobs_bool_exp>;
};


export type subscription_rootbackground_jobs_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<background_jobs_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<background_jobs_order_by>>;
  where?: InputMaybe<background_jobs_bool_exp>;
};


export type subscription_rootbackground_jobs_settingsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<background_jobs_settings_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<background_jobs_settings_order_by>>;
  where?: InputMaybe<background_jobs_settings_bool_exp>;
};


export type subscription_rootbalance_sheet_reportsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<balance_sheet_reports_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<balance_sheet_reports_order_by>>;
  where?: InputMaybe<balance_sheet_reports_bool_exp>;
};


export type subscription_rootcalculate_accounting_metricsArgs = {
  args: calculate_accounting_metrics_args;
  distinct_on?: InputMaybe<ReadonlyArray<accounting_metrics_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<accounting_metrics_order_by>>;
  where?: InputMaybe<accounting_metrics_bool_exp>;
};


export type subscription_rootchargebee_data_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<chargebee_data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<chargebee_data_sources_order_by>>;
  where?: InputMaybe<chargebee_data_sources_bool_exp>;
};


export type subscription_rootchargify_data_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<chargify_data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<chargify_data_sources_order_by>>;
  where?: InputMaybe<chargify_data_sources_bool_exp>;
};


export type subscription_rootclearbit_companiesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<clearbit_companies_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<clearbit_companies_order_by>>;
  where?: InputMaybe<clearbit_companies_bool_exp>;
};


export type subscription_rootcodat_companiesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<codat_companies_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<codat_companies_order_by>>;
  where?: InputMaybe<codat_companies_bool_exp>;
};


export type subscription_rootcsv_tasks_for_vendorsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<csv_tasks_for_vendors_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<csv_tasks_for_vendors_order_by>>;
  where?: InputMaybe<csv_tasks_for_vendors_bool_exp>;
};


export type subscription_rootcustomersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<customers_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<customers_order_by>>;
  where?: InputMaybe<customers_bool_exp>;
};


export type subscription_rootcustomers_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<customers_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<customers_order_by>>;
  where?: InputMaybe<customers_bool_exp>;
};


export type subscription_rootdata_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<data_sources_order_by>>;
  where?: InputMaybe<data_sources_bool_exp>;
};


export type subscription_rootdb_admin_indexesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<db_admin_indexes_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<db_admin_indexes_order_by>>;
  where?: InputMaybe<db_admin_indexes_bool_exp>;
};


export type subscription_rootdb_admin_queriesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<db_admin_queries_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<db_admin_queries_order_by>>;
  where?: InputMaybe<db_admin_queries_bool_exp>;
};


export type subscription_rootdb_admin_relationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<db_admin_relations_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<db_admin_relations_order_by>>;
  where?: InputMaybe<db_admin_relations_bool_exp>;
};


export type subscription_rootfingerprintsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<fingerprints_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<fingerprints_order_by>>;
  where?: InputMaybe<fingerprints_bool_exp>;
};


export type subscription_rootfingerprints_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<fingerprints_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<fingerprints_order_by>>;
  where?: InputMaybe<fingerprints_bool_exp>;
};


export type subscription_rootgocardless_data_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<gocardless_data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<gocardless_data_sources_order_by>>;
  where?: InputMaybe<gocardless_data_sources_bool_exp>;
};


export type subscription_rootheron_annotated_transactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<heron_annotated_transactions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<heron_annotated_transactions_order_by>>;
  where?: InputMaybe<heron_annotated_transactions_bool_exp>;
};


export type subscription_rootheron_categoriesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<heron_categories_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<heron_categories_order_by>>;
  where?: InputMaybe<heron_categories_bool_exp>;
};


export type subscription_roothistorical_bank_dataArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<historical_bank_data_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<historical_bank_data_order_by>>;
  where?: InputMaybe<historical_bank_data_bool_exp>;
};


export type subscription_rootinvestor_asset_receiptsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<investor_asset_receipts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<investor_asset_receipts_order_by>>;
  where?: InputMaybe<investor_asset_receipts_bool_exp>;
};


export type subscription_rootinvestor_asset_receipts_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<investor_asset_receipts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<investor_asset_receipts_order_by>>;
  where?: InputMaybe<investor_asset_receipts_bool_exp>;
};


export type subscription_rootinvestor_payment_batchesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<investor_payment_batches_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<investor_payment_batches_order_by>>;
  where?: InputMaybe<investor_payment_batches_bool_exp>;
};


export type subscription_rootinvestor_paymentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<investor_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<investor_payments_order_by>>;
  where?: InputMaybe<investor_payments_bool_exp>;
};


export type subscription_rootinvestor_portfolio_settingsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<investor_portfolio_settings_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<investor_portfolio_settings_order_by>>;
  where?: InputMaybe<investor_portfolio_settings_bool_exp>;
};


export type subscription_rootinvestorsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<investors_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<investors_order_by>>;
  where?: InputMaybe<investors_bool_exp>;
};


export type subscription_rootlegal_entitiesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<legal_entities_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<legal_entities_order_by>>;
  where?: InputMaybe<legal_entities_bool_exp>;
};


export type subscription_rootlegal_entities_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<legal_entities_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<legal_entities_order_by>>;
  where?: InputMaybe<legal_entities_bool_exp>;
};


export type subscription_rootlimit_buy_ordersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<limit_buy_orders_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<limit_buy_orders_order_by>>;
  where?: InputMaybe<limit_buy_orders_bool_exp>;
};


export type subscription_rootlistsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_order_by>>;
  where?: InputMaybe<lists_bool_exp>;
};


export type subscription_rootlists_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_order_by>>;
  where?: InputMaybe<lists_bool_exp>;
};


export type subscription_rootlists_investor_asset_receiptsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_investor_asset_receipts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_investor_asset_receipts_order_by>>;
  where?: InputMaybe<lists_investor_asset_receipts_bool_exp>;
};


export type subscription_rootlists_investor_asset_receipts_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_investor_asset_receipts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_investor_asset_receipts_order_by>>;
  where?: InputMaybe<lists_investor_asset_receipts_bool_exp>;
};


export type subscription_rootlists_investor_paymentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_investor_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_investor_payments_order_by>>;
  where?: InputMaybe<lists_investor_payments_bool_exp>;
};


export type subscription_rootlists_investor_payments_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_investor_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_investor_payments_order_by>>;
  where?: InputMaybe<lists_investor_payments_bool_exp>;
};


export type subscription_rootlists_investor_transfersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_investor_transfers_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_investor_transfers_order_by>>;
  where?: InputMaybe<lists_investor_transfers_bool_exp>;
};


export type subscription_rootlists_investor_transfers_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_investor_transfers_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_investor_transfers_order_by>>;
  where?: InputMaybe<lists_investor_transfers_bool_exp>;
};


export type subscription_rootlists_investor_usersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_investor_users_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_investor_users_order_by>>;
  where?: InputMaybe<lists_investor_users_bool_exp>;
};


export type subscription_rootlists_investor_users_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_investor_users_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_investor_users_order_by>>;
  where?: InputMaybe<lists_investor_users_bool_exp>;
};


export type subscription_rootlists_limit_buy_ordersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_limit_buy_orders_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_limit_buy_orders_order_by>>;
  where?: InputMaybe<lists_limit_buy_orders_bool_exp>;
};


export type subscription_rootlists_limit_buy_orders_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_limit_buy_orders_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_limit_buy_orders_order_by>>;
  where?: InputMaybe<lists_limit_buy_orders_bool_exp>;
};


export type subscription_rootlists_payment_tasksArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_payment_tasks_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_payment_tasks_order_by>>;
  where?: InputMaybe<lists_payment_tasks_bool_exp>;
};


export type subscription_rootlists_payment_tasks_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_payment_tasks_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_payment_tasks_order_by>>;
  where?: InputMaybe<lists_payment_tasks_bool_exp>;
};


export type subscription_rootlists_paymentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_payments_order_by>>;
  where?: InputMaybe<lists_payments_bool_exp>;
};


export type subscription_rootlists_payments_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_payments_order_by>>;
  where?: InputMaybe<lists_payments_bool_exp>;
};


export type subscription_rootlists_sub_paymentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_sub_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_sub_payments_order_by>>;
  where?: InputMaybe<lists_sub_payments_bool_exp>;
};


export type subscription_rootlists_sub_payments_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_sub_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_sub_payments_order_by>>;
  where?: InputMaybe<lists_sub_payments_bool_exp>;
};


export type subscription_rootlists_tradeable_contractsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_tradeable_contracts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_tradeable_contracts_order_by>>;
  where?: InputMaybe<lists_tradeable_contracts_bool_exp>;
};


export type subscription_rootlists_tradeable_contracts_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_tradeable_contracts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_tradeable_contracts_order_by>>;
  where?: InputMaybe<lists_tradeable_contracts_bool_exp>;
};


export type subscription_rootlists_tradesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_trades_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_trades_order_by>>;
  where?: InputMaybe<lists_trades_bool_exp>;
};


export type subscription_rootlists_trades_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_trades_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_trades_order_by>>;
  where?: InputMaybe<lists_trades_bool_exp>;
};


export type subscription_rootlists_usersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_users_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_users_order_by>>;
  where?: InputMaybe<lists_users_bool_exp>;
};


export type subscription_rootlists_users_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_users_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_users_order_by>>;
  where?: InputMaybe<lists_users_bool_exp>;
};


export type subscription_rootlists_vendor_bank_transactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_vendor_bank_transactions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_vendor_bank_transactions_order_by>>;
  where?: InputMaybe<lists_vendor_bank_transactions_bool_exp>;
};


export type subscription_rootlists_vendor_bank_transactions_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_vendor_bank_transactions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_vendor_bank_transactions_order_by>>;
  where?: InputMaybe<lists_vendor_bank_transactions_bool_exp>;
};


export type subscription_rootlow_end_experiment_candidatesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<low_end_experiment_candidates_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<low_end_experiment_candidates_order_by>>;
  where?: InputMaybe<low_end_experiment_candidates_bool_exp>;
};


export type subscription_rootmanual_data_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<manual_data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<manual_data_sources_order_by>>;
  where?: InputMaybe<manual_data_sources_bool_exp>;
};


export type subscription_rootmanual_opsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<manual_ops_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<manual_ops_order_by>>;
  where?: InputMaybe<manual_ops_bool_exp>;
};


export type subscription_rootpipe_agreementsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_agreements_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_agreements_order_by>>;
  where?: InputMaybe<pipe_agreements_bool_exp>;
};


export type subscription_rootpipe_agreements_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_agreements_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_agreements_order_by>>;
  where?: InputMaybe<pipe_agreements_bool_exp>;
};


export type subscription_rootpipe_proposalsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_proposals_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_proposals_order_by>>;
  where?: InputMaybe<pipe_proposals_bool_exp>;
};


export type subscription_rootpipe_proposals_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_proposals_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_proposals_order_by>>;
  where?: InputMaybe<pipe_proposals_bool_exp>;
};


export type subscription_rootplaid_itemsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<plaid_items_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<plaid_items_order_by>>;
  where?: InputMaybe<plaid_items_bool_exp>;
};


export type subscription_rootplaid_items_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<plaid_items_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<plaid_items_order_by>>;
  where?: InputMaybe<plaid_items_bool_exp>;
};


export type subscription_rootprofit_and_loss_reportsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<profit_and_loss_reports_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<profit_and_loss_reports_order_by>>;
  where?: InputMaybe<profit_and_loss_reports_bool_exp>;
};


export type subscription_rootratings_churn_metrics_usdArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<ratings_churn_metrics_usd_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<ratings_churn_metrics_usd_order_by>>;
  where?: InputMaybe<ratings_churn_metrics_usd_bool_exp>;
};


export type subscription_rootratings_concern_evaluation_overridesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<ratings_concern_evaluation_overrides_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<ratings_concern_evaluation_overrides_order_by>>;
  where?: InputMaybe<ratings_concern_evaluation_overrides_bool_exp>;
};


export type subscription_rootratings_concern_evaluationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<ratings_concern_evaluations_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<ratings_concern_evaluations_order_by>>;
  where?: InputMaybe<ratings_concern_evaluations_bool_exp>;
};


export type subscription_rootratings_concernsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<ratings_concerns_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<ratings_concerns_order_by>>;
  where?: InputMaybe<ratings_concerns_bool_exp>;
};


export type subscription_rootratings_historical_bank_balances_usdArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<ratings_historical_bank_balances_usd_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<ratings_historical_bank_balances_usd_order_by>>;
  where?: InputMaybe<ratings_historical_bank_balances_usd_bool_exp>;
};


export type subscription_rootratings_revenue_metrics_usdArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<ratings_revenue_metrics_usd_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<ratings_revenue_metrics_usd_order_by>>;
  where?: InputMaybe<ratings_revenue_metrics_usd_bool_exp>;
};


export type subscription_rootrecurly_data_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<recurly_data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<recurly_data_sources_order_by>>;
  where?: InputMaybe<recurly_data_sources_bool_exp>;
};


export type subscription_rootrevenue_streamsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<revenue_streams_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<revenue_streams_order_by>>;
  where?: InputMaybe<revenue_streams_bool_exp>;
};


export type subscription_rootrevenue_streams_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<revenue_streams_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<revenue_streams_order_by>>;
  where?: InputMaybe<revenue_streams_bool_exp>;
};


export type subscription_rootrolesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<roles_order_by>>;
  where?: InputMaybe<roles_bool_exp>;
};


export type subscription_rootroles_permissionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<roles_permissions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<roles_permissions_order_by>>;
  where?: InputMaybe<roles_permissions_bool_exp>;
};


export type subscription_rootsell_ordersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<sell_orders_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<sell_orders_order_by>>;
  where?: InputMaybe<sell_orders_bool_exp>;
};


export type subscription_rootsell_orders_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<sell_orders_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<sell_orders_order_by>>;
  where?: InputMaybe<sell_orders_bool_exp>;
};


export type subscription_rootsell_orders_ownershipArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<sell_orders_ownership_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<sell_orders_ownership_order_by>>;
  where?: InputMaybe<sell_orders_ownership_bool_exp>;
};


export type subscription_rootsell_orders_ownership_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<sell_orders_ownership_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<sell_orders_ownership_order_by>>;
  where?: InputMaybe<sell_orders_ownership_bool_exp>;
};


export type subscription_rootsnapshot_historical_bank_data_viewArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<snapshot_historical_bank_data_view_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<snapshot_historical_bank_data_view_order_by>>;
  where?: InputMaybe<snapshot_historical_bank_data_view_bool_exp>;
};


export type subscription_rootsnapshot_overridesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<snapshot_overrides_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<snapshot_overrides_order_by>>;
  where?: InputMaybe<snapshot_overrides_bool_exp>;
};


export type subscription_rootsnapshotsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<snapshots_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<snapshots_order_by>>;
  where?: InputMaybe<snapshots_bool_exp>;
};


export type subscription_rootstripe_data_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<stripe_data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<stripe_data_sources_order_by>>;
  where?: InputMaybe<stripe_data_sources_bool_exp>;
};


export type subscription_rootsubscription_paymentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<subscription_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<subscription_payments_order_by>>;
  where?: InputMaybe<subscription_payments_bool_exp>;
};


export type subscription_rootsubscription_payments_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<subscription_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<subscription_payments_order_by>>;
  where?: InputMaybe<subscription_payments_bool_exp>;
};


export type subscription_roottrade_volume_historyArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<trade_volume_history_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<trade_volume_history_order_by>>;
  where?: InputMaybe<trade_volume_history_bool_exp>;
};


export type subscription_roottransactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<transactions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<transactions_order_by>>;
  where?: InputMaybe<transactions_bool_exp>;
};


export type subscription_rootuploaded_vendor_dataArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<uploaded_vendor_data_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<uploaded_vendor_data_order_by>>;
  where?: InputMaybe<uploaded_vendor_data_bool_exp>;
};


export type subscription_rootuploaded_vendor_data_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<uploaded_vendor_data_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<uploaded_vendor_data_order_by>>;
  where?: InputMaybe<uploaded_vendor_data_bool_exp>;
};


export type subscription_rootusersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<users_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<users_order_by>>;
  where?: InputMaybe<users_bool_exp>;
};


export type subscription_rootusers_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<users_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<users_order_by>>;
  where?: InputMaybe<users_bool_exp>;
};


export type subscription_rootusers_permissionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<users_permissions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<users_permissions_order_by>>;
  where?: InputMaybe<users_permissions_bool_exp>;
};


export type subscription_rootusers_rolesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<users_roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<users_roles_order_by>>;
  where?: InputMaybe<users_roles_bool_exp>;
};


export type subscription_rootvendor_active_ratingsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_active_ratings_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_active_ratings_order_by>>;
  where?: InputMaybe<vendor_active_ratings_bool_exp>;
};


export type subscription_rootvendor_bank_balancesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_bank_balances_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_bank_balances_order_by>>;
  where?: InputMaybe<vendor_bank_balances_bool_exp>;
};


export type subscription_rootvendor_current_sales_funnel_stageArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_current_sales_funnel_stage_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_current_sales_funnel_stage_order_by>>;
  where?: InputMaybe<vendor_current_sales_funnel_stage_bool_exp>;
};


export type subscription_rootvendor_custom_fieldsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_custom_fields_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_custom_fields_order_by>>;
  where?: InputMaybe<vendor_custom_fields_bool_exp>;
};


export type subscription_rootvendor_decisionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_decisions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_decisions_order_by>>;
  where?: InputMaybe<vendor_decisions_bool_exp>;
};


export type subscription_rootvendor_events_summaryArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_events_summary_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_events_summary_order_by>>;
  where?: InputMaybe<vendor_events_summary_bool_exp>;
};


export type subscription_rootvendor_financialsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_financials_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_financials_order_by>>;
  where?: InputMaybe<vendor_financials_bool_exp>;
};


export type subscription_rootvendor_financials_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_financials_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_financials_order_by>>;
  where?: InputMaybe<vendor_financials_bool_exp>;
};


export type subscription_rootvendor_financials_mrr_momArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_financials_mrr_mom_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_financials_mrr_mom_order_by>>;
  where?: InputMaybe<vendor_financials_mrr_mom_bool_exp>;
};


export type subscription_rootvendor_metric_jobsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_metric_jobs_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_metric_jobs_order_by>>;
  where?: InputMaybe<vendor_metric_jobs_bool_exp>;
};


export type subscription_rootvendor_metricsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_metrics_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_metrics_order_by>>;
  where?: InputMaybe<vendor_metrics_bool_exp>;
};


export type subscription_rootvendor_metrics_latestArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_metrics_latest_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_metrics_latest_order_by>>;
  where?: InputMaybe<vendor_metrics_latest_bool_exp>;
};


export type subscription_rootvendor_payment_statusArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_payment_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_payment_status_order_by>>;
  where?: InputMaybe<vendor_payment_status_bool_exp>;
};


export type subscription_rootvendor_payment_status_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_payment_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_payment_status_order_by>>;
  where?: InputMaybe<vendor_payment_status_bool_exp>;
};


export type subscription_rootvendor_paymentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_payments_order_by>>;
  where?: InputMaybe<vendor_payments_bool_exp>;
};


export type subscription_rootvendor_payments_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_payments_order_by>>;
  where?: InputMaybe<vendor_payments_bool_exp>;
};


export type subscription_rootvendor_payments_enrichedArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_payments_enriched_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_payments_enriched_order_by>>;
  where?: InputMaybe<vendor_payments_enriched_bool_exp>;
};


export type subscription_rootvendor_payments_extendedArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_payments_extended_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_payments_extended_order_by>>;
  where?: InputMaybe<vendor_payments_extended_bool_exp>;
};


export type subscription_rootvendor_product_waitlistArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_product_waitlist_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_product_waitlist_order_by>>;
  where?: InputMaybe<vendor_product_waitlist_bool_exp>;
};


export type subscription_rootvendor_product_waitlist_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_product_waitlist_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_product_waitlist_order_by>>;
  where?: InputMaybe<vendor_product_waitlist_bool_exp>;
};


export type subscription_rootvendor_ratingsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_ratings_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_ratings_order_by>>;
  where?: InputMaybe<vendor_ratings_bool_exp>;
};


export type subscription_rootvendor_ratings_metadataArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_ratings_metadata_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_ratings_metadata_order_by>>;
  where?: InputMaybe<vendor_ratings_metadata_bool_exp>;
};


export type subscription_rootvendor_supported_currenciesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_supported_currencies_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_supported_currencies_order_by>>;
  where?: InputMaybe<vendor_supported_currencies_bool_exp>;
};


export type subscription_rootvendor_tradesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_trades_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_trades_order_by>>;
  where?: InputMaybe<vendor_trades_bool_exp>;
};


export type subscription_rootvendor_treasury_accountsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_treasury_accounts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_treasury_accounts_order_by>>;
  where?: InputMaybe<vendor_treasury_accounts_bool_exp>;
};


export type subscription_rootvendor_treasury_balancesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_treasury_balances_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_treasury_balances_order_by>>;
  where?: InputMaybe<vendor_treasury_balances_bool_exp>;
};


export type subscription_rootvendor_treasury_daily_cumulative_balancesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_treasury_daily_cumulative_balances_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_treasury_daily_cumulative_balances_order_by>>;
  where?: InputMaybe<vendor_treasury_daily_cumulative_balances_bool_exp>;
};


export type subscription_rootvendor_treasury_transactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_treasury_transactions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_treasury_transactions_order_by>>;
  where?: InputMaybe<vendor_treasury_transactions_bool_exp>;
};


export type subscription_rootvendor_visit_summaryArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_visit_summary_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_visit_summary_order_by>>;
  where?: InputMaybe<vendor_visit_summary_bool_exp>;
};


export type subscription_rootvendorsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendors_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendors_order_by>>;
  where?: InputMaybe<vendors_bool_exp>;
};


export type subscription_rootvendors_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendors_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendors_order_by>>;
  where?: InputMaybe<vendors_bool_exp>;
};


export type subscription_rootvisitsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<visits_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<visits_order_by>>;
  where?: InputMaybe<visits_bool_exp>;
};


export type subscription_rootvisits_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<visits_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<visits_order_by>>;
  where?: InputMaybe<visits_bool_exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type timestamp_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['timestamp']>;
  readonly _gt?: InputMaybe<Scalars['timestamp']>;
  readonly _gte?: InputMaybe<Scalars['timestamp']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['timestamp']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['timestamp']>;
  readonly _lte?: InputMaybe<Scalars['timestamp']>;
  readonly _neq?: InputMaybe<Scalars['timestamp']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type timestamptz_comparison_exp = {
  readonly _eq?: InputMaybe<Scalars['timestamptz']>;
  readonly _gt?: InputMaybe<Scalars['timestamptz']>;
  readonly _gte?: InputMaybe<Scalars['timestamptz']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['timestamptz']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _lt?: InputMaybe<Scalars['timestamptz']>;
  readonly _lte?: InputMaybe<Scalars['timestamptz']>;
  readonly _neq?: InputMaybe<Scalars['timestamptz']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['timestamptz']>>;
};

/**
 * Historical trade volume.
 *
 *
 * columns and relationships of "trade_volume_history"
 */
export type trade_volume_history = {
  readonly __typename?: 'trade_volume_history';
  readonly month?: Maybe<Scalars['float8']>;
  readonly payout_volume_cents?: Maybe<Scalars['bigint']>;
  readonly volume_cents?: Maybe<Scalars['bigint']>;
  readonly year?: Maybe<Scalars['float8']>;
};

/** Boolean expression to filter rows from the table "trade_volume_history". All fields are combined with a logical 'AND'. */
export type trade_volume_history_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<trade_volume_history_bool_exp>>;
  readonly _not?: InputMaybe<trade_volume_history_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<trade_volume_history_bool_exp>>;
  readonly month?: InputMaybe<float8_comparison_exp>;
  readonly payout_volume_cents?: InputMaybe<bigint_comparison_exp>;
  readonly volume_cents?: InputMaybe<bigint_comparison_exp>;
  readonly year?: InputMaybe<float8_comparison_exp>;
};

/** Ordering options when selecting data from "trade_volume_history". */
export type trade_volume_history_order_by = {
  readonly month?: InputMaybe<order_by>;
  readonly payout_volume_cents?: InputMaybe<order_by>;
  readonly volume_cents?: InputMaybe<order_by>;
  readonly year?: InputMaybe<order_by>;
};

/** select columns of table "trade_volume_history" */
export enum trade_volume_history_select_column {
  /** column name */
  month = 'month',
  /** column name */
  payout_volume_cents = 'payout_volume_cents',
  /** column name */
  volume_cents = 'volume_cents',
  /** column name */
  year = 'year'
}

/** columns and relationships of "transactions" */
export type transactions = {
  readonly __typename?: 'transactions';
  /** An object relationship */
  readonly account: accounts;
  readonly amount: Scalars['bigint'];
  readonly category: Scalars['_text'];
  readonly category_id: Scalars['String'];
  readonly created_at: Scalars['timestamptz'];
  readonly currency: Scalars['currency'];
  readonly is_excluded_from_runway_calculations: Scalars['Boolean'];
  readonly is_pending: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly public_id: Scalars['String'];
  readonly transacted_at: Scalars['timestamptz'];
  readonly updated_at: Scalars['timestamptz'];
};

/** order by aggregate values of table "transactions" */
export type transactions_aggregate_order_by = {
  readonly avg?: InputMaybe<transactions_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<transactions_max_order_by>;
  readonly min?: InputMaybe<transactions_min_order_by>;
  readonly stddev?: InputMaybe<transactions_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<transactions_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<transactions_stddev_samp_order_by>;
  readonly sum?: InputMaybe<transactions_sum_order_by>;
  readonly var_pop?: InputMaybe<transactions_var_pop_order_by>;
  readonly var_samp?: InputMaybe<transactions_var_samp_order_by>;
  readonly variance?: InputMaybe<transactions_variance_order_by>;
};

/** order by avg() on columns of table "transactions" */
export type transactions_avg_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "transactions". All fields are combined with a logical 'AND'. */
export type transactions_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<transactions_bool_exp>>;
  readonly _not?: InputMaybe<transactions_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<transactions_bool_exp>>;
  readonly account?: InputMaybe<accounts_bool_exp>;
  readonly amount?: InputMaybe<bigint_comparison_exp>;
  readonly category?: InputMaybe<_text_comparison_exp>;
  readonly category_id?: InputMaybe<String_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly is_excluded_from_runway_calculations?: InputMaybe<Boolean_comparison_exp>;
  readonly is_pending?: InputMaybe<Boolean_comparison_exp>;
  readonly name?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly transacted_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** order by max() on columns of table "transactions" */
export type transactions_max_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly category_id?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly transacted_at?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** order by min() on columns of table "transactions" */
export type transactions_min_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly category_id?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly transacted_at?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "transactions" */
export type transactions_mutation_response = {
  readonly __typename?: 'transactions_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<transactions>;
};

/** Ordering options when selecting data from "transactions". */
export type transactions_order_by = {
  readonly account?: InputMaybe<accounts_order_by>;
  readonly amount?: InputMaybe<order_by>;
  readonly category?: InputMaybe<order_by>;
  readonly category_id?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly is_excluded_from_runway_calculations?: InputMaybe<order_by>;
  readonly is_pending?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly transacted_at?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** select columns of table "transactions" */
export enum transactions_select_column {
  /** column name */
  amount = 'amount',
  /** column name */
  category = 'category',
  /** column name */
  category_id = 'category_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  is_excluded_from_runway_calculations = 'is_excluded_from_runway_calculations',
  /** column name */
  is_pending = 'is_pending',
  /** column name */
  name = 'name',
  /** column name */
  public_id = 'public_id',
  /** column name */
  transacted_at = 'transacted_at',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "transactions" */
export type transactions_set_input = {
  readonly is_excluded_from_runway_calculations?: InputMaybe<Scalars['Boolean']>;
};

/** order by stddev() on columns of table "transactions" */
export type transactions_stddev_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by stddev_pop() on columns of table "transactions" */
export type transactions_stddev_pop_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by stddev_samp() on columns of table "transactions" */
export type transactions_stddev_samp_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by sum() on columns of table "transactions" */
export type transactions_sum_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by var_pop() on columns of table "transactions" */
export type transactions_var_pop_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by var_samp() on columns of table "transactions" */
export type transactions_var_samp_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by variance() on columns of table "transactions" */
export type transactions_variance_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** columns and relationships of "uploaded_vendor_data" */
export type uploaded_vendor_data = {
  readonly __typename?: 'uploaded_vendor_data';
  readonly confirmed_at?: Maybe<Scalars['timestamptz']>;
  readonly created_at: Scalars['timestamptz'];
  readonly data_type: uploaded_vendor_data_type_enum_enum;
  readonly error_reason?: Maybe<Scalars['String']>;
  readonly is_rejected: Scalars['Boolean'];
  /** An object relationship */
  readonly last_reviewed_by?: Maybe<users>;
  /** An object relationship */
  readonly manual_data_source?: Maybe<manual_data_sources>;
  readonly model?: Maybe<uploaded_vendor_data_source_model_enum_enum>;
  readonly public_id: Scalars['String'];
  readonly resource_slug: Scalars['String'];
  readonly status?: Maybe<uploaded_vendor_data_source_status_enum_enum>;
  readonly updated_at: Scalars['timestamptz'];
  /** An object relationship */
  readonly uploaded_by: users;
  /** An object relationship */
  readonly vendor: vendors;
};

/** aggregated selection of "uploaded_vendor_data" */
export type uploaded_vendor_data_aggregate = {
  readonly __typename?: 'uploaded_vendor_data_aggregate';
  readonly aggregate?: Maybe<uploaded_vendor_data_aggregate_fields>;
  readonly nodes: ReadonlyArray<uploaded_vendor_data>;
};

/** aggregate fields of "uploaded_vendor_data" */
export type uploaded_vendor_data_aggregate_fields = {
  readonly __typename?: 'uploaded_vendor_data_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<uploaded_vendor_data_max_fields>;
  readonly min?: Maybe<uploaded_vendor_data_min_fields>;
};


/** aggregate fields of "uploaded_vendor_data" */
export type uploaded_vendor_data_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<uploaded_vendor_data_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "uploaded_vendor_data" */
export type uploaded_vendor_data_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<uploaded_vendor_data_max_order_by>;
  readonly min?: InputMaybe<uploaded_vendor_data_min_order_by>;
};

/** Boolean expression to filter rows from the table "uploaded_vendor_data". All fields are combined with a logical 'AND'. */
export type uploaded_vendor_data_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<uploaded_vendor_data_bool_exp>>;
  readonly _not?: InputMaybe<uploaded_vendor_data_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<uploaded_vendor_data_bool_exp>>;
  readonly confirmed_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly data_type?: InputMaybe<uploaded_vendor_data_type_enum_enum_comparison_exp>;
  readonly error_reason?: InputMaybe<String_comparison_exp>;
  readonly is_rejected?: InputMaybe<Boolean_comparison_exp>;
  readonly last_reviewed_by?: InputMaybe<users_bool_exp>;
  readonly manual_data_source?: InputMaybe<manual_data_sources_bool_exp>;
  readonly model?: InputMaybe<uploaded_vendor_data_source_model_enum_enum_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly resource_slug?: InputMaybe<String_comparison_exp>;
  readonly status?: InputMaybe<uploaded_vendor_data_source_status_enum_enum_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly uploaded_by?: InputMaybe<users_bool_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** aggregate max on columns */
export type uploaded_vendor_data_max_fields = {
  readonly __typename?: 'uploaded_vendor_data_max_fields';
  readonly confirmed_at?: Maybe<Scalars['timestamptz']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly error_reason?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly resource_slug?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "uploaded_vendor_data" */
export type uploaded_vendor_data_max_order_by = {
  readonly confirmed_at?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly error_reason?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly resource_slug?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type uploaded_vendor_data_min_fields = {
  readonly __typename?: 'uploaded_vendor_data_min_fields';
  readonly confirmed_at?: Maybe<Scalars['timestamptz']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly error_reason?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly resource_slug?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "uploaded_vendor_data" */
export type uploaded_vendor_data_min_order_by = {
  readonly confirmed_at?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly error_reason?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly resource_slug?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "uploaded_vendor_data". */
export type uploaded_vendor_data_order_by = {
  readonly confirmed_at?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly data_type?: InputMaybe<order_by>;
  readonly error_reason?: InputMaybe<order_by>;
  readonly is_rejected?: InputMaybe<order_by>;
  readonly last_reviewed_by?: InputMaybe<users_order_by>;
  readonly manual_data_source?: InputMaybe<manual_data_sources_order_by>;
  readonly model?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly resource_slug?: InputMaybe<order_by>;
  readonly status?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly uploaded_by?: InputMaybe<users_order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "uploaded_vendor_data" */
export enum uploaded_vendor_data_select_column {
  /** column name */
  confirmed_at = 'confirmed_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  data_type = 'data_type',
  /** column name */
  error_reason = 'error_reason',
  /** column name */
  is_rejected = 'is_rejected',
  /** column name */
  model = 'model',
  /** column name */
  public_id = 'public_id',
  /** column name */
  resource_slug = 'resource_slug',
  /** column name */
  status = 'status',
  /** column name */
  updated_at = 'updated_at'
}

export enum uploaded_vendor_data_source_model_enum_enum {
  subscriptions = 'subscriptions'
}

/** Boolean expression to compare columns of type "uploaded_vendor_data_source_model_enum_enum". All fields are combined with logical 'AND'. */
export type uploaded_vendor_data_source_model_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<uploaded_vendor_data_source_model_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<uploaded_vendor_data_source_model_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<uploaded_vendor_data_source_model_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<uploaded_vendor_data_source_model_enum_enum>>;
};

export enum uploaded_vendor_data_source_status_enum_enum {
  error = 'error',
  processing = 'processing',
  succeeded = 'succeeded'
}

/** Boolean expression to compare columns of type "uploaded_vendor_data_source_status_enum_enum". All fields are combined with logical 'AND'. */
export type uploaded_vendor_data_source_status_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<uploaded_vendor_data_source_status_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<uploaded_vendor_data_source_status_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<uploaded_vendor_data_source_status_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<uploaded_vendor_data_source_status_enum_enum>>;
};

export enum uploaded_vendor_data_type_enum_enum {
  accounting = 'accounting',
  bank = 'bank',
  billing = 'billing'
}

/** Boolean expression to compare columns of type "uploaded_vendor_data_type_enum_enum". All fields are combined with logical 'AND'. */
export type uploaded_vendor_data_type_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<uploaded_vendor_data_type_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<uploaded_vendor_data_type_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<uploaded_vendor_data_type_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<uploaded_vendor_data_type_enum_enum>>;
};

export enum user_type_enum_enum {
  investor = 'investor',
  pipe_admin = 'pipe_admin',
  vendor_admin = 'vendor_admin'
}

/** Boolean expression to compare columns of type "user_type_enum_enum". All fields are combined with logical 'AND'. */
export type user_type_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<user_type_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<user_type_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<user_type_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<user_type_enum_enum>>;
};

/** columns and relationships of "users" */
export type users = {
  readonly __typename?: 'users';
  /** Time when the user first completed the demo. */
  readonly completed_demo_at?: Maybe<Scalars['timestamptz']>;
  readonly created_at: Scalars['timestamp'];
  readonly email: Scalars['String'];
  readonly invite_code: Scalars['String'];
  readonly invite_code_emailed_at?: Maybe<Scalars['timestamptz']>;
  readonly is_email_valid?: Maybe<Scalars['Boolean']>;
  /** This column is for manually disabling users (e.g. ex-employees). */
  readonly is_enabled: Scalars['Boolean'];
  /** The latest version of the app seen (semver). */
  readonly latest_version_seen?: Maybe<Scalars['String']>;
  /** An array relationship */
  readonly lists: ReadonlyArray<lists>;
  /** An aggregate relationship */
  readonly lists_aggregate: lists_aggregate;
  readonly name: Scalars['String'];
  readonly partnership_referral?: Maybe<Scalars['String']>;
  readonly password_reset_requested_at?: Maybe<Scalars['timestamptz']>;
  readonly phone?: Maybe<Scalars['String']>;
  /** An array relationship */
  readonly pipe_agreements: ReadonlyArray<pipe_agreements>;
  /** An aggregate relationship */
  readonly pipe_agreements_aggregate: pipe_agreements_aggregate;
  /** The preferred theme of the vendor. */
  readonly preferred_theme?: Maybe<Scalars['String']>;
  readonly public_id: Scalars['String'];
  readonly updated_at: Scalars['timestamp'];
  /** An array relationship */
  readonly uploadedVendorDataByLastReviewedByUserId: ReadonlyArray<uploaded_vendor_data>;
  /** An aggregate relationship */
  readonly uploadedVendorDataByLastReviewedByUserId_aggregate: uploaded_vendor_data_aggregate;
  /** An array relationship */
  readonly uploaded_vendor_data: ReadonlyArray<uploaded_vendor_data>;
  /** An aggregate relationship */
  readonly uploaded_vendor_data_aggregate: uploaded_vendor_data_aggregate;
  readonly user_type: user_type_enum_enum;
  /** An array relationship */
  readonly users_roles: ReadonlyArray<users_roles>;
  /** An object relationship */
  readonly vendor: vendors;
  /** An array relationship */
  readonly visits: ReadonlyArray<visits>;
  /** An aggregate relationship */
  readonly visits_aggregate: visits_aggregate;
};


/** columns and relationships of "users" */
export type userslistsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_order_by>>;
  where?: InputMaybe<lists_bool_exp>;
};


/** columns and relationships of "users" */
export type userslists_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_order_by>>;
  where?: InputMaybe<lists_bool_exp>;
};


/** columns and relationships of "users" */
export type userspipe_agreementsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_agreements_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_agreements_order_by>>;
  where?: InputMaybe<pipe_agreements_bool_exp>;
};


/** columns and relationships of "users" */
export type userspipe_agreements_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_agreements_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_agreements_order_by>>;
  where?: InputMaybe<pipe_agreements_bool_exp>;
};


/** columns and relationships of "users" */
export type usersuploadedVendorDataByLastReviewedByUserIdArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<uploaded_vendor_data_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<uploaded_vendor_data_order_by>>;
  where?: InputMaybe<uploaded_vendor_data_bool_exp>;
};


/** columns and relationships of "users" */
export type usersuploadedVendorDataByLastReviewedByUserId_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<uploaded_vendor_data_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<uploaded_vendor_data_order_by>>;
  where?: InputMaybe<uploaded_vendor_data_bool_exp>;
};


/** columns and relationships of "users" */
export type usersuploaded_vendor_dataArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<uploaded_vendor_data_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<uploaded_vendor_data_order_by>>;
  where?: InputMaybe<uploaded_vendor_data_bool_exp>;
};


/** columns and relationships of "users" */
export type usersuploaded_vendor_data_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<uploaded_vendor_data_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<uploaded_vendor_data_order_by>>;
  where?: InputMaybe<uploaded_vendor_data_bool_exp>;
};


/** columns and relationships of "users" */
export type usersusers_rolesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<users_roles_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<users_roles_order_by>>;
  where?: InputMaybe<users_roles_bool_exp>;
};


/** columns and relationships of "users" */
export type usersvisitsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<visits_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<visits_order_by>>;
  where?: InputMaybe<visits_bool_exp>;
};


/** columns and relationships of "users" */
export type usersvisits_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<visits_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<visits_order_by>>;
  where?: InputMaybe<visits_bool_exp>;
};

/** aggregated selection of "users" */
export type users_aggregate = {
  readonly __typename?: 'users_aggregate';
  readonly aggregate?: Maybe<users_aggregate_fields>;
  readonly nodes: ReadonlyArray<users>;
};

/** aggregate fields of "users" */
export type users_aggregate_fields = {
  readonly __typename?: 'users_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<users_max_fields>;
  readonly min?: Maybe<users_min_fields>;
};


/** aggregate fields of "users" */
export type users_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<users_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type users_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<users_max_order_by>;
  readonly min?: InputMaybe<users_min_order_by>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type users_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<users_bool_exp>>;
  readonly _not?: InputMaybe<users_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<users_bool_exp>>;
  readonly completed_demo_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly created_at?: InputMaybe<timestamp_comparison_exp>;
  readonly email?: InputMaybe<String_comparison_exp>;
  readonly invite_code?: InputMaybe<String_comparison_exp>;
  readonly invite_code_emailed_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly is_email_valid?: InputMaybe<Boolean_comparison_exp>;
  readonly is_enabled?: InputMaybe<Boolean_comparison_exp>;
  readonly latest_version_seen?: InputMaybe<String_comparison_exp>;
  readonly lists?: InputMaybe<lists_bool_exp>;
  readonly name?: InputMaybe<String_comparison_exp>;
  readonly partnership_referral?: InputMaybe<String_comparison_exp>;
  readonly password_reset_requested_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly phone?: InputMaybe<String_comparison_exp>;
  readonly pipe_agreements?: InputMaybe<pipe_agreements_bool_exp>;
  readonly preferred_theme?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamp_comparison_exp>;
  readonly uploadedVendorDataByLastReviewedByUserId?: InputMaybe<uploaded_vendor_data_bool_exp>;
  readonly uploaded_vendor_data?: InputMaybe<uploaded_vendor_data_bool_exp>;
  readonly user_type?: InputMaybe<user_type_enum_enum_comparison_exp>;
  readonly users_roles?: InputMaybe<users_roles_bool_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
  readonly visits?: InputMaybe<visits_bool_exp>;
};

/** aggregate max on columns */
export type users_max_fields = {
  readonly __typename?: 'users_max_fields';
  /** Time when the user first completed the demo. */
  readonly completed_demo_at?: Maybe<Scalars['timestamptz']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly invite_code?: Maybe<Scalars['String']>;
  readonly invite_code_emailed_at?: Maybe<Scalars['timestamptz']>;
  /** The latest version of the app seen (semver). */
  readonly latest_version_seen?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly partnership_referral?: Maybe<Scalars['String']>;
  readonly password_reset_requested_at?: Maybe<Scalars['timestamptz']>;
  readonly phone?: Maybe<Scalars['String']>;
  /** The preferred theme of the vendor. */
  readonly preferred_theme?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "users" */
export type users_max_order_by = {
  /** Time when the user first completed the demo. */
  readonly completed_demo_at?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly email?: InputMaybe<order_by>;
  readonly invite_code?: InputMaybe<order_by>;
  readonly invite_code_emailed_at?: InputMaybe<order_by>;
  /** The latest version of the app seen (semver). */
  readonly latest_version_seen?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly partnership_referral?: InputMaybe<order_by>;
  readonly password_reset_requested_at?: InputMaybe<order_by>;
  readonly phone?: InputMaybe<order_by>;
  /** The preferred theme of the vendor. */
  readonly preferred_theme?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type users_min_fields = {
  readonly __typename?: 'users_min_fields';
  /** Time when the user first completed the demo. */
  readonly completed_demo_at?: Maybe<Scalars['timestamptz']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly invite_code?: Maybe<Scalars['String']>;
  readonly invite_code_emailed_at?: Maybe<Scalars['timestamptz']>;
  /** The latest version of the app seen (semver). */
  readonly latest_version_seen?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly partnership_referral?: Maybe<Scalars['String']>;
  readonly password_reset_requested_at?: Maybe<Scalars['timestamptz']>;
  readonly phone?: Maybe<Scalars['String']>;
  /** The preferred theme of the vendor. */
  readonly preferred_theme?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "users" */
export type users_min_order_by = {
  /** Time when the user first completed the demo. */
  readonly completed_demo_at?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly email?: InputMaybe<order_by>;
  readonly invite_code?: InputMaybe<order_by>;
  readonly invite_code_emailed_at?: InputMaybe<order_by>;
  /** The latest version of the app seen (semver). */
  readonly latest_version_seen?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly partnership_referral?: InputMaybe<order_by>;
  readonly password_reset_requested_at?: InputMaybe<order_by>;
  readonly phone?: InputMaybe<order_by>;
  /** The preferred theme of the vendor. */
  readonly preferred_theme?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "users" */
export type users_mutation_response = {
  readonly __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<users>;
};

/** Ordering options when selecting data from "users". */
export type users_order_by = {
  readonly completed_demo_at?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly email?: InputMaybe<order_by>;
  readonly invite_code?: InputMaybe<order_by>;
  readonly invite_code_emailed_at?: InputMaybe<order_by>;
  readonly is_email_valid?: InputMaybe<order_by>;
  readonly is_enabled?: InputMaybe<order_by>;
  readonly latest_version_seen?: InputMaybe<order_by>;
  readonly lists_aggregate?: InputMaybe<lists_aggregate_order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly partnership_referral?: InputMaybe<order_by>;
  readonly password_reset_requested_at?: InputMaybe<order_by>;
  readonly phone?: InputMaybe<order_by>;
  readonly pipe_agreements_aggregate?: InputMaybe<pipe_agreements_aggregate_order_by>;
  readonly preferred_theme?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly uploadedVendorDataByLastReviewedByUserId_aggregate?: InputMaybe<uploaded_vendor_data_aggregate_order_by>;
  readonly uploaded_vendor_data_aggregate?: InputMaybe<uploaded_vendor_data_aggregate_order_by>;
  readonly user_type?: InputMaybe<order_by>;
  readonly users_roles_aggregate?: InputMaybe<users_roles_aggregate_order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
  readonly visits_aggregate?: InputMaybe<visits_aggregate_order_by>;
};

/** columns and relationships of "users_permissions" */
export type users_permissions = {
  readonly __typename?: 'users_permissions';
  readonly permission?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly user?: Maybe<users>;
};

/** Boolean expression to filter rows from the table "users_permissions". All fields are combined with a logical 'AND'. */
export type users_permissions_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<users_permissions_bool_exp>>;
  readonly _not?: InputMaybe<users_permissions_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<users_permissions_bool_exp>>;
  readonly permission?: InputMaybe<String_comparison_exp>;
  readonly user?: InputMaybe<users_bool_exp>;
};

/** Ordering options when selecting data from "users_permissions". */
export type users_permissions_order_by = {
  readonly permission?: InputMaybe<order_by>;
  readonly user?: InputMaybe<users_order_by>;
};

/** select columns of table "users_permissions" */
export enum users_permissions_select_column {
  /** column name */
  permission = 'permission'
}

/** columns and relationships of "users_roles" */
export type users_roles = {
  readonly __typename?: 'users_roles';
  /** An object relationship */
  readonly role: roles;
  readonly role_name: Scalars['String'];
  /** An object relationship */
  readonly user: users;
};

/** order by aggregate values of table "users_roles" */
export type users_roles_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<users_roles_max_order_by>;
  readonly min?: InputMaybe<users_roles_min_order_by>;
};

/** Boolean expression to filter rows from the table "users_roles". All fields are combined with a logical 'AND'. */
export type users_roles_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<users_roles_bool_exp>>;
  readonly _not?: InputMaybe<users_roles_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<users_roles_bool_exp>>;
  readonly role?: InputMaybe<roles_bool_exp>;
  readonly role_name?: InputMaybe<String_comparison_exp>;
  readonly user?: InputMaybe<users_bool_exp>;
};

/** order by max() on columns of table "users_roles" */
export type users_roles_max_order_by = {
  readonly role_name?: InputMaybe<order_by>;
};

/** order by min() on columns of table "users_roles" */
export type users_roles_min_order_by = {
  readonly role_name?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "users_roles". */
export type users_roles_order_by = {
  readonly role?: InputMaybe<roles_order_by>;
  readonly role_name?: InputMaybe<order_by>;
  readonly user?: InputMaybe<users_order_by>;
};

/** select columns of table "users_roles" */
export enum users_roles_select_column {
  /** column name */
  role_name = 'role_name'
}

/** select columns of table "users" */
export enum users_select_column {
  /** column name */
  completed_demo_at = 'completed_demo_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  email = 'email',
  /** column name */
  invite_code = 'invite_code',
  /** column name */
  invite_code_emailed_at = 'invite_code_emailed_at',
  /** column name */
  is_email_valid = 'is_email_valid',
  /** column name */
  is_enabled = 'is_enabled',
  /** column name */
  latest_version_seen = 'latest_version_seen',
  /** column name */
  name = 'name',
  /** column name */
  partnership_referral = 'partnership_referral',
  /** column name */
  password_reset_requested_at = 'password_reset_requested_at',
  /** column name */
  phone = 'phone',
  /** column name */
  preferred_theme = 'preferred_theme',
  /** column name */
  public_id = 'public_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  user_type = 'user_type'
}

/** input type for updating data in table "users" */
export type users_set_input = {
  /** The latest version of the app seen (semver). */
  readonly latest_version_seen?: InputMaybe<Scalars['String']>;
  readonly phone?: InputMaybe<Scalars['String']>;
  /** The preferred theme of the vendor. */
  readonly preferred_theme?: InputMaybe<Scalars['String']>;
  readonly public_id?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "vendor_active_ratings" */
export type vendor_active_ratings = {
  readonly __typename?: 'vendor_active_ratings';
  readonly created_at: Scalars['timestamptz'];
  readonly currency: Scalars['currency'];
  readonly expires_at: Scalars['timestamptz'];
  readonly is_expired: Scalars['Boolean'];
  readonly public_id: Scalars['String'];
  /** An object relationship */
  readonly vendor: vendors;
  /** An object relationship */
  readonly vendor_rating: vendor_ratings;
};

/** order by aggregate values of table "vendor_active_ratings" */
export type vendor_active_ratings_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<vendor_active_ratings_max_order_by>;
  readonly min?: InputMaybe<vendor_active_ratings_min_order_by>;
};

/** Boolean expression to filter rows from the table "vendor_active_ratings". All fields are combined with a logical 'AND'. */
export type vendor_active_ratings_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_active_ratings_bool_exp>>;
  readonly _not?: InputMaybe<vendor_active_ratings_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_active_ratings_bool_exp>>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly expires_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly is_expired?: InputMaybe<Boolean_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
  readonly vendor_rating?: InputMaybe<vendor_ratings_bool_exp>;
};

/** order by max() on columns of table "vendor_active_ratings" */
export type vendor_active_ratings_max_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly expires_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** order by min() on columns of table "vendor_active_ratings" */
export type vendor_active_ratings_min_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly expires_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "vendor_active_ratings". */
export type vendor_active_ratings_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly expires_at?: InputMaybe<order_by>;
  readonly is_expired?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
  readonly vendor_rating?: InputMaybe<vendor_ratings_order_by>;
};

/** select columns of table "vendor_active_ratings" */
export enum vendor_active_ratings_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  expires_at = 'expires_at',
  /** column name */
  is_expired = 'is_expired',
  /** column name */
  public_id = 'public_id'
}

/** columns and relationships of "vendor_bank_balances" */
export type vendor_bank_balances = {
  readonly __typename?: 'vendor_bank_balances';
  readonly bank_balance?: Maybe<Scalars['numeric']>;
  readonly currency?: Maybe<Scalars['currency']>;
  readonly is_below_balance_threshold?: Maybe<Scalars['Boolean']>;
  readonly min_bank_balance?: Maybe<Scalars['bigint']>;
};

/** order by aggregate values of table "vendor_bank_balances" */
export type vendor_bank_balances_aggregate_order_by = {
  readonly avg?: InputMaybe<vendor_bank_balances_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<vendor_bank_balances_max_order_by>;
  readonly min?: InputMaybe<vendor_bank_balances_min_order_by>;
  readonly stddev?: InputMaybe<vendor_bank_balances_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<vendor_bank_balances_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<vendor_bank_balances_stddev_samp_order_by>;
  readonly sum?: InputMaybe<vendor_bank_balances_sum_order_by>;
  readonly var_pop?: InputMaybe<vendor_bank_balances_var_pop_order_by>;
  readonly var_samp?: InputMaybe<vendor_bank_balances_var_samp_order_by>;
  readonly variance?: InputMaybe<vendor_bank_balances_variance_order_by>;
};

/** order by avg() on columns of table "vendor_bank_balances" */
export type vendor_bank_balances_avg_order_by = {
  readonly bank_balance?: InputMaybe<order_by>;
  readonly min_bank_balance?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "vendor_bank_balances". All fields are combined with a logical 'AND'. */
export type vendor_bank_balances_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_bank_balances_bool_exp>>;
  readonly _not?: InputMaybe<vendor_bank_balances_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_bank_balances_bool_exp>>;
  readonly bank_balance?: InputMaybe<numeric_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly is_below_balance_threshold?: InputMaybe<Boolean_comparison_exp>;
  readonly min_bank_balance?: InputMaybe<bigint_comparison_exp>;
};

/** order by max() on columns of table "vendor_bank_balances" */
export type vendor_bank_balances_max_order_by = {
  readonly bank_balance?: InputMaybe<order_by>;
  readonly min_bank_balance?: InputMaybe<order_by>;
};

/** order by min() on columns of table "vendor_bank_balances" */
export type vendor_bank_balances_min_order_by = {
  readonly bank_balance?: InputMaybe<order_by>;
  readonly min_bank_balance?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "vendor_bank_balances". */
export type vendor_bank_balances_order_by = {
  readonly bank_balance?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly is_below_balance_threshold?: InputMaybe<order_by>;
  readonly min_bank_balance?: InputMaybe<order_by>;
};

/** select columns of table "vendor_bank_balances" */
export enum vendor_bank_balances_select_column {
  /** column name */
  bank_balance = 'bank_balance',
  /** column name */
  currency = 'currency',
  /** column name */
  is_below_balance_threshold = 'is_below_balance_threshold',
  /** column name */
  min_bank_balance = 'min_bank_balance'
}

/** order by stddev() on columns of table "vendor_bank_balances" */
export type vendor_bank_balances_stddev_order_by = {
  readonly bank_balance?: InputMaybe<order_by>;
  readonly min_bank_balance?: InputMaybe<order_by>;
};

/** order by stddev_pop() on columns of table "vendor_bank_balances" */
export type vendor_bank_balances_stddev_pop_order_by = {
  readonly bank_balance?: InputMaybe<order_by>;
  readonly min_bank_balance?: InputMaybe<order_by>;
};

/** order by stddev_samp() on columns of table "vendor_bank_balances" */
export type vendor_bank_balances_stddev_samp_order_by = {
  readonly bank_balance?: InputMaybe<order_by>;
  readonly min_bank_balance?: InputMaybe<order_by>;
};

/** order by sum() on columns of table "vendor_bank_balances" */
export type vendor_bank_balances_sum_order_by = {
  readonly bank_balance?: InputMaybe<order_by>;
  readonly min_bank_balance?: InputMaybe<order_by>;
};

/** order by var_pop() on columns of table "vendor_bank_balances" */
export type vendor_bank_balances_var_pop_order_by = {
  readonly bank_balance?: InputMaybe<order_by>;
  readonly min_bank_balance?: InputMaybe<order_by>;
};

/** order by var_samp() on columns of table "vendor_bank_balances" */
export type vendor_bank_balances_var_samp_order_by = {
  readonly bank_balance?: InputMaybe<order_by>;
  readonly min_bank_balance?: InputMaybe<order_by>;
};

/** order by variance() on columns of table "vendor_bank_balances" */
export type vendor_bank_balances_variance_order_by = {
  readonly bank_balance?: InputMaybe<order_by>;
  readonly min_bank_balance?: InputMaybe<order_by>;
};

/** columns and relationships of "vendor_current_sales_funnel_stage" */
export type vendor_current_sales_funnel_stage = {
  readonly __typename?: 'vendor_current_sales_funnel_stage';
  readonly sales_stage?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "vendor_current_sales_funnel_stage". All fields are combined with a logical 'AND'. */
export type vendor_current_sales_funnel_stage_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_current_sales_funnel_stage_bool_exp>>;
  readonly _not?: InputMaybe<vendor_current_sales_funnel_stage_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_current_sales_funnel_stage_bool_exp>>;
  readonly sales_stage?: InputMaybe<String_comparison_exp>;
};

/** Ordering options when selecting data from "vendor_current_sales_funnel_stage". */
export type vendor_current_sales_funnel_stage_order_by = {
  readonly sales_stage?: InputMaybe<order_by>;
};

/** select columns of table "vendor_current_sales_funnel_stage" */
export enum vendor_current_sales_funnel_stage_select_column {
  /** column name */
  sales_stage = 'sales_stage'
}

/**
 * Configuration of the custom fields that a vendor sets on their entities.
 *
 *
 * columns and relationships of "vendor_custom_fields"
 */
export type vendor_custom_fields = {
  readonly __typename?: 'vendor_custom_fields';
  /** The model of the custom field. */
  readonly model: Scalars['String'];
  /** The name of the field. */
  readonly name: Scalars['String'];
  /** Additional options of the field, e.g. for a select field it could be the options of the field. */
  readonly options?: Maybe<Scalars['jsonb']>;
  readonly public_id: Scalars['String'];
  /** The title of the field, i.e. the column header. */
  readonly title: Scalars['String'];
  /** The type of the field. */
  readonly type: Scalars['String'];
  /** An object relationship */
  readonly vendor: vendors;
};


/**
 * Configuration of the custom fields that a vendor sets on their entities.
 *
 *
 * columns and relationships of "vendor_custom_fields"
 */
export type vendor_custom_fieldsoptionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "vendor_custom_fields" */
export type vendor_custom_fields_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<vendor_custom_fields_max_order_by>;
  readonly min?: InputMaybe<vendor_custom_fields_min_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type vendor_custom_fields_append_input = {
  /** Additional options of the field, e.g. for a select field it could be the options of the field. */
  readonly options?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "vendor_custom_fields". All fields are combined with a logical 'AND'. */
export type vendor_custom_fields_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_custom_fields_bool_exp>>;
  readonly _not?: InputMaybe<vendor_custom_fields_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_custom_fields_bool_exp>>;
  readonly model?: InputMaybe<String_comparison_exp>;
  readonly name?: InputMaybe<String_comparison_exp>;
  readonly options?: InputMaybe<jsonb_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly title?: InputMaybe<String_comparison_exp>;
  readonly type?: InputMaybe<String_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** unique or primary key constraints on table "vendor_custom_fields" */
export enum vendor_custom_fields_constraint {
  /** unique or primary key constraint */
  vendor_custom_fields_pkey = 'vendor_custom_fields_pkey',
  /** unique or primary key constraint */
  vendor_custom_fields_public_id_key = 'vendor_custom_fields_public_id_key',
  /** unique or primary key constraint */
  vendor_custom_fields_vendor_id_name_key = 'vendor_custom_fields_vendor_id_name_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type vendor_custom_fields_delete_at_path_input = {
  /** Additional options of the field, e.g. for a select field it could be the options of the field. */
  readonly options?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type vendor_custom_fields_delete_elem_input = {
  /** Additional options of the field, e.g. for a select field it could be the options of the field. */
  readonly options?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type vendor_custom_fields_delete_key_input = {
  /** Additional options of the field, e.g. for a select field it could be the options of the field. */
  readonly options?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "vendor_custom_fields" */
export type vendor_custom_fields_insert_input = {
  /** The model of the custom field. */
  readonly model?: InputMaybe<Scalars['String']>;
  /** The name of the field. */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Additional options of the field, e.g. for a select field it could be the options of the field. */
  readonly options?: InputMaybe<Scalars['jsonb']>;
  /** The title of the field, i.e. the column header. */
  readonly title?: InputMaybe<Scalars['String']>;
  /** The type of the field. */
  readonly type?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "vendor_custom_fields" */
export type vendor_custom_fields_max_order_by = {
  /** The model of the custom field. */
  readonly model?: InputMaybe<order_by>;
  /** The name of the field. */
  readonly name?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  /** The title of the field, i.e. the column header. */
  readonly title?: InputMaybe<order_by>;
  /** The type of the field. */
  readonly type?: InputMaybe<order_by>;
};

/** order by min() on columns of table "vendor_custom_fields" */
export type vendor_custom_fields_min_order_by = {
  /** The model of the custom field. */
  readonly model?: InputMaybe<order_by>;
  /** The name of the field. */
  readonly name?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  /** The title of the field, i.e. the column header. */
  readonly title?: InputMaybe<order_by>;
  /** The type of the field. */
  readonly type?: InputMaybe<order_by>;
};

/** response of any mutation on the table "vendor_custom_fields" */
export type vendor_custom_fields_mutation_response = {
  readonly __typename?: 'vendor_custom_fields_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<vendor_custom_fields>;
};

/** on_conflict condition type for table "vendor_custom_fields" */
export type vendor_custom_fields_on_conflict = {
  readonly constraint: vendor_custom_fields_constraint;
  readonly update_columns?: ReadonlyArray<vendor_custom_fields_update_column>;
  readonly where?: InputMaybe<vendor_custom_fields_bool_exp>;
};

/** Ordering options when selecting data from "vendor_custom_fields". */
export type vendor_custom_fields_order_by = {
  readonly model?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly options?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly title?: InputMaybe<order_by>;
  readonly type?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type vendor_custom_fields_prepend_input = {
  /** Additional options of the field, e.g. for a select field it could be the options of the field. */
  readonly options?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "vendor_custom_fields" */
export enum vendor_custom_fields_select_column {
  /** column name */
  model = 'model',
  /** column name */
  name = 'name',
  /** column name */
  options = 'options',
  /** column name */
  public_id = 'public_id',
  /** column name */
  title = 'title',
  /** column name */
  type = 'type'
}

/** input type for updating data in table "vendor_custom_fields" */
export type vendor_custom_fields_set_input = {
  /** The model of the custom field. */
  readonly model?: InputMaybe<Scalars['String']>;
  /** The name of the field. */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Additional options of the field, e.g. for a select field it could be the options of the field. */
  readonly options?: InputMaybe<Scalars['jsonb']>;
  /** The title of the field, i.e. the column header. */
  readonly title?: InputMaybe<Scalars['String']>;
  /** The type of the field. */
  readonly type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "vendor_custom_fields" */
export enum vendor_custom_fields_update_column {
  /** column name */
  model = 'model',
  /** column name */
  name = 'name',
  /** column name */
  options = 'options',
  /** column name */
  title = 'title',
  /** column name */
  type = 'type'
}

/** columns and relationships of "vendor_decisions" */
export type vendor_decisions = {
  readonly __typename?: 'vendor_decisions';
  readonly decision: Scalars['String'];
  /** Is the decision suggested by the ratings model? */
  readonly is_suggested: Scalars['Boolean'];
  readonly max_term: Scalars['Int'];
  /** Commit SHA for the ratings model code used to create the decision. Null if decision not suggested by the ratings model */
  readonly model_sha?: Maybe<Scalars['String']>;
  readonly public_id: Scalars['String'];
  readonly rate_months_1: Scalars['Int'];
  readonly rating?: Maybe<asset_rating_enum_enum>;
  readonly score: Scalars['float8'];
  /** An array relationship */
  readonly snapshots: ReadonlyArray<snapshots>;
  /** An array relationship */
  readonly snapshotsByAppliedDecision: ReadonlyArray<snapshots>;
  readonly trading_limit: Scalars['bigint'];
  readonly trading_limit_expiration: Scalars['timestamptz'];
};


/** columns and relationships of "vendor_decisions" */
export type vendor_decisionssnapshotsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<snapshots_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<snapshots_order_by>>;
  where?: InputMaybe<snapshots_bool_exp>;
};


/** columns and relationships of "vendor_decisions" */
export type vendor_decisionssnapshotsByAppliedDecisionArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<snapshots_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<snapshots_order_by>>;
  where?: InputMaybe<snapshots_bool_exp>;
};

/** Boolean expression to filter rows from the table "vendor_decisions". All fields are combined with a logical 'AND'. */
export type vendor_decisions_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_decisions_bool_exp>>;
  readonly _not?: InputMaybe<vendor_decisions_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_decisions_bool_exp>>;
  readonly decision?: InputMaybe<String_comparison_exp>;
  readonly is_suggested?: InputMaybe<Boolean_comparison_exp>;
  readonly max_term?: InputMaybe<Int_comparison_exp>;
  readonly model_sha?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly rate_months_1?: InputMaybe<Int_comparison_exp>;
  readonly rating?: InputMaybe<asset_rating_enum_enum_comparison_exp>;
  readonly score?: InputMaybe<float8_comparison_exp>;
  readonly snapshots?: InputMaybe<snapshots_bool_exp>;
  readonly snapshotsByAppliedDecision?: InputMaybe<snapshots_bool_exp>;
  readonly trading_limit?: InputMaybe<bigint_comparison_exp>;
  readonly trading_limit_expiration?: InputMaybe<timestamptz_comparison_exp>;
};

/** Ordering options when selecting data from "vendor_decisions". */
export type vendor_decisions_order_by = {
  readonly decision?: InputMaybe<order_by>;
  readonly is_suggested?: InputMaybe<order_by>;
  readonly max_term?: InputMaybe<order_by>;
  readonly model_sha?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly rate_months_1?: InputMaybe<order_by>;
  readonly rating?: InputMaybe<order_by>;
  readonly score?: InputMaybe<order_by>;
  readonly snapshotsByAppliedDecision_aggregate?: InputMaybe<snapshots_aggregate_order_by>;
  readonly snapshots_aggregate?: InputMaybe<snapshots_aggregate_order_by>;
  readonly trading_limit?: InputMaybe<order_by>;
  readonly trading_limit_expiration?: InputMaybe<order_by>;
};

/** select columns of table "vendor_decisions" */
export enum vendor_decisions_select_column {
  /** column name */
  decision = 'decision',
  /** column name */
  is_suggested = 'is_suggested',
  /** column name */
  max_term = 'max_term',
  /** column name */
  model_sha = 'model_sha',
  /** column name */
  public_id = 'public_id',
  /** column name */
  rate_months_1 = 'rate_months_1',
  /** column name */
  rating = 'rating',
  /** column name */
  score = 'score',
  /** column name */
  trading_limit = 'trading_limit',
  /** column name */
  trading_limit_expiration = 'trading_limit_expiration'
}

/** columns and relationships of "vendor_events_summary" */
export type vendor_events_summary = {
  readonly __typename?: 'vendor_events_summary';
  readonly activated_at?: Maybe<Scalars['timestamptz']>;
  readonly bank_disconnected_at?: Maybe<Scalars['timestamptz']>;
  readonly credit_limit_last_updated_at?: Maybe<Scalars['timestamptz']>;
  readonly entered_underwriting_at?: Maybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "vendor_events_summary". All fields are combined with a logical 'AND'. */
export type vendor_events_summary_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_events_summary_bool_exp>>;
  readonly _not?: InputMaybe<vendor_events_summary_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_events_summary_bool_exp>>;
  readonly activated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly bank_disconnected_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly credit_limit_last_updated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly entered_underwriting_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** Ordering options when selecting data from "vendor_events_summary". */
export type vendor_events_summary_order_by = {
  readonly activated_at?: InputMaybe<order_by>;
  readonly bank_disconnected_at?: InputMaybe<order_by>;
  readonly credit_limit_last_updated_at?: InputMaybe<order_by>;
  readonly entered_underwriting_at?: InputMaybe<order_by>;
};

/** select columns of table "vendor_events_summary" */
export enum vendor_events_summary_select_column {
  /** column name */
  activated_at = 'activated_at',
  /** column name */
  bank_disconnected_at = 'bank_disconnected_at',
  /** column name */
  credit_limit_last_updated_at = 'credit_limit_last_updated_at',
  /** column name */
  entered_underwriting_at = 'entered_underwriting_at'
}

/**
 * Financials report for each vendor. Visible to the vendor.
 *
 *
 * columns and relationships of "vendor_financials"
 */
export type vendor_financials = {
  readonly __typename?: 'vendor_financials';
  readonly cash_balance?: Maybe<Scalars['bigint']>;
  readonly cash_inflow?: Maybe<Scalars['bigint']>;
  readonly cash_outflow?: Maybe<Scalars['bigint']>;
  readonly created_at: Scalars['timestamptz'];
  readonly currency: Scalars['currency'];
  readonly financials_date_end: Scalars['timestamptz'];
  readonly financials_date_start: Scalars['timestamptz'];
  /** Revenue from all recurring contracts active during the period */
  readonly mrr_active?: Maybe<Scalars['bigint']>;
  /** Revenue from all recurring contracts that were churned during the period */
  readonly mrr_churned?: Maybe<Scalars['bigint']>;
  /** Revenue from all recurring contracts that were contracted. Customer is paying less */
  readonly mrr_contractions?: Maybe<Scalars['bigint']>;
  /** Revenue from all recurring contracts that were expanded. Customer is paying more */
  readonly mrr_expansions?: Maybe<Scalars['bigint']>;
  /** Revenue from all recurring contracts that were acquired during the period */
  readonly mrr_new?: Maybe<Scalars['bigint']>;
  readonly mrr_traded?: Maybe<Scalars['bigint']>;
  readonly public_id: Scalars['String'];
  readonly updated_at: Scalars['timestamptz'];
  /** An object relationship */
  readonly vendor: vendors;
};

/** aggregated selection of "vendor_financials" */
export type vendor_financials_aggregate = {
  readonly __typename?: 'vendor_financials_aggregate';
  readonly aggregate?: Maybe<vendor_financials_aggregate_fields>;
  readonly nodes: ReadonlyArray<vendor_financials>;
};

/** aggregate fields of "vendor_financials" */
export type vendor_financials_aggregate_fields = {
  readonly __typename?: 'vendor_financials_aggregate_fields';
  readonly avg?: Maybe<vendor_financials_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<vendor_financials_max_fields>;
  readonly min?: Maybe<vendor_financials_min_fields>;
  readonly stddev?: Maybe<vendor_financials_stddev_fields>;
  readonly stddev_pop?: Maybe<vendor_financials_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<vendor_financials_stddev_samp_fields>;
  readonly sum?: Maybe<vendor_financials_sum_fields>;
  readonly var_pop?: Maybe<vendor_financials_var_pop_fields>;
  readonly var_samp?: Maybe<vendor_financials_var_samp_fields>;
  readonly variance?: Maybe<vendor_financials_variance_fields>;
};


/** aggregate fields of "vendor_financials" */
export type vendor_financials_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<vendor_financials_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type vendor_financials_avg_fields = {
  readonly __typename?: 'vendor_financials_avg_fields';
  readonly cash_balance?: Maybe<Scalars['Float']>;
  readonly cash_inflow?: Maybe<Scalars['Float']>;
  readonly cash_outflow?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts active during the period */
  readonly mrr_active?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were churned during the period */
  readonly mrr_churned?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were contracted. Customer is paying less */
  readonly mrr_contractions?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were expanded. Customer is paying more */
  readonly mrr_expansions?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were acquired during the period */
  readonly mrr_new?: Maybe<Scalars['Float']>;
  readonly mrr_traded?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "vendor_financials". All fields are combined with a logical 'AND'. */
export type vendor_financials_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_financials_bool_exp>>;
  readonly _not?: InputMaybe<vendor_financials_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_financials_bool_exp>>;
  readonly cash_balance?: InputMaybe<bigint_comparison_exp>;
  readonly cash_inflow?: InputMaybe<bigint_comparison_exp>;
  readonly cash_outflow?: InputMaybe<bigint_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly financials_date_end?: InputMaybe<timestamptz_comparison_exp>;
  readonly financials_date_start?: InputMaybe<timestamptz_comparison_exp>;
  readonly mrr_active?: InputMaybe<bigint_comparison_exp>;
  readonly mrr_churned?: InputMaybe<bigint_comparison_exp>;
  readonly mrr_contractions?: InputMaybe<bigint_comparison_exp>;
  readonly mrr_expansions?: InputMaybe<bigint_comparison_exp>;
  readonly mrr_new?: InputMaybe<bigint_comparison_exp>;
  readonly mrr_traded?: InputMaybe<bigint_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** aggregate max on columns */
export type vendor_financials_max_fields = {
  readonly __typename?: 'vendor_financials_max_fields';
  readonly cash_balance?: Maybe<Scalars['bigint']>;
  readonly cash_inflow?: Maybe<Scalars['bigint']>;
  readonly cash_outflow?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly financials_date_end?: Maybe<Scalars['timestamptz']>;
  readonly financials_date_start?: Maybe<Scalars['timestamptz']>;
  /** Revenue from all recurring contracts active during the period */
  readonly mrr_active?: Maybe<Scalars['bigint']>;
  /** Revenue from all recurring contracts that were churned during the period */
  readonly mrr_churned?: Maybe<Scalars['bigint']>;
  /** Revenue from all recurring contracts that were contracted. Customer is paying less */
  readonly mrr_contractions?: Maybe<Scalars['bigint']>;
  /** Revenue from all recurring contracts that were expanded. Customer is paying more */
  readonly mrr_expansions?: Maybe<Scalars['bigint']>;
  /** Revenue from all recurring contracts that were acquired during the period */
  readonly mrr_new?: Maybe<Scalars['bigint']>;
  readonly mrr_traded?: Maybe<Scalars['bigint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type vendor_financials_min_fields = {
  readonly __typename?: 'vendor_financials_min_fields';
  readonly cash_balance?: Maybe<Scalars['bigint']>;
  readonly cash_inflow?: Maybe<Scalars['bigint']>;
  readonly cash_outflow?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly financials_date_end?: Maybe<Scalars['timestamptz']>;
  readonly financials_date_start?: Maybe<Scalars['timestamptz']>;
  /** Revenue from all recurring contracts active during the period */
  readonly mrr_active?: Maybe<Scalars['bigint']>;
  /** Revenue from all recurring contracts that were churned during the period */
  readonly mrr_churned?: Maybe<Scalars['bigint']>;
  /** Revenue from all recurring contracts that were contracted. Customer is paying less */
  readonly mrr_contractions?: Maybe<Scalars['bigint']>;
  /** Revenue from all recurring contracts that were expanded. Customer is paying more */
  readonly mrr_expansions?: Maybe<Scalars['bigint']>;
  /** Revenue from all recurring contracts that were acquired during the period */
  readonly mrr_new?: Maybe<Scalars['bigint']>;
  readonly mrr_traded?: Maybe<Scalars['bigint']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "vendor_financials_mrr_mom" */
export type vendor_financials_mrr_mom = {
  readonly __typename?: 'vendor_financials_mrr_mom';
  readonly currency?: Maybe<Scalars['currency']>;
  readonly financials_date_end?: Maybe<Scalars['timestamptz']>;
  readonly financials_date_start?: Maybe<Scalars['timestamptz']>;
  readonly gain_mrr_percentage?: Maybe<Scalars['bigint']>;
  readonly loss_mrr_percentage?: Maybe<Scalars['bigint']>;
  readonly mrr_active?: Maybe<Scalars['bigint']>;
  readonly retained_mrr_percentage?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  readonly vendor?: Maybe<vendors>;
};

/** Boolean expression to filter rows from the table "vendor_financials_mrr_mom". All fields are combined with a logical 'AND'. */
export type vendor_financials_mrr_mom_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_financials_mrr_mom_bool_exp>>;
  readonly _not?: InputMaybe<vendor_financials_mrr_mom_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_financials_mrr_mom_bool_exp>>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly financials_date_end?: InputMaybe<timestamptz_comparison_exp>;
  readonly financials_date_start?: InputMaybe<timestamptz_comparison_exp>;
  readonly gain_mrr_percentage?: InputMaybe<bigint_comparison_exp>;
  readonly loss_mrr_percentage?: InputMaybe<bigint_comparison_exp>;
  readonly mrr_active?: InputMaybe<bigint_comparison_exp>;
  readonly retained_mrr_percentage?: InputMaybe<bigint_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** Ordering options when selecting data from "vendor_financials_mrr_mom". */
export type vendor_financials_mrr_mom_order_by = {
  readonly currency?: InputMaybe<order_by>;
  readonly financials_date_end?: InputMaybe<order_by>;
  readonly financials_date_start?: InputMaybe<order_by>;
  readonly gain_mrr_percentage?: InputMaybe<order_by>;
  readonly loss_mrr_percentage?: InputMaybe<order_by>;
  readonly mrr_active?: InputMaybe<order_by>;
  readonly retained_mrr_percentage?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "vendor_financials_mrr_mom" */
export enum vendor_financials_mrr_mom_select_column {
  /** column name */
  currency = 'currency',
  /** column name */
  financials_date_end = 'financials_date_end',
  /** column name */
  financials_date_start = 'financials_date_start',
  /** column name */
  gain_mrr_percentage = 'gain_mrr_percentage',
  /** column name */
  loss_mrr_percentage = 'loss_mrr_percentage',
  /** column name */
  mrr_active = 'mrr_active',
  /** column name */
  retained_mrr_percentage = 'retained_mrr_percentage'
}

/** Ordering options when selecting data from "vendor_financials". */
export type vendor_financials_order_by = {
  readonly cash_balance?: InputMaybe<order_by>;
  readonly cash_inflow?: InputMaybe<order_by>;
  readonly cash_outflow?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly financials_date_end?: InputMaybe<order_by>;
  readonly financials_date_start?: InputMaybe<order_by>;
  readonly mrr_active?: InputMaybe<order_by>;
  readonly mrr_churned?: InputMaybe<order_by>;
  readonly mrr_contractions?: InputMaybe<order_by>;
  readonly mrr_expansions?: InputMaybe<order_by>;
  readonly mrr_new?: InputMaybe<order_by>;
  readonly mrr_traded?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "vendor_financials" */
export enum vendor_financials_select_column {
  /** column name */
  cash_balance = 'cash_balance',
  /** column name */
  cash_inflow = 'cash_inflow',
  /** column name */
  cash_outflow = 'cash_outflow',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  financials_date_end = 'financials_date_end',
  /** column name */
  financials_date_start = 'financials_date_start',
  /** column name */
  mrr_active = 'mrr_active',
  /** column name */
  mrr_churned = 'mrr_churned',
  /** column name */
  mrr_contractions = 'mrr_contractions',
  /** column name */
  mrr_expansions = 'mrr_expansions',
  /** column name */
  mrr_new = 'mrr_new',
  /** column name */
  mrr_traded = 'mrr_traded',
  /** column name */
  public_id = 'public_id',
  /** column name */
  updated_at = 'updated_at'
}

/** aggregate stddev on columns */
export type vendor_financials_stddev_fields = {
  readonly __typename?: 'vendor_financials_stddev_fields';
  readonly cash_balance?: Maybe<Scalars['Float']>;
  readonly cash_inflow?: Maybe<Scalars['Float']>;
  readonly cash_outflow?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts active during the period */
  readonly mrr_active?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were churned during the period */
  readonly mrr_churned?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were contracted. Customer is paying less */
  readonly mrr_contractions?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were expanded. Customer is paying more */
  readonly mrr_expansions?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were acquired during the period */
  readonly mrr_new?: Maybe<Scalars['Float']>;
  readonly mrr_traded?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type vendor_financials_stddev_pop_fields = {
  readonly __typename?: 'vendor_financials_stddev_pop_fields';
  readonly cash_balance?: Maybe<Scalars['Float']>;
  readonly cash_inflow?: Maybe<Scalars['Float']>;
  readonly cash_outflow?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts active during the period */
  readonly mrr_active?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were churned during the period */
  readonly mrr_churned?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were contracted. Customer is paying less */
  readonly mrr_contractions?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were expanded. Customer is paying more */
  readonly mrr_expansions?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were acquired during the period */
  readonly mrr_new?: Maybe<Scalars['Float']>;
  readonly mrr_traded?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type vendor_financials_stddev_samp_fields = {
  readonly __typename?: 'vendor_financials_stddev_samp_fields';
  readonly cash_balance?: Maybe<Scalars['Float']>;
  readonly cash_inflow?: Maybe<Scalars['Float']>;
  readonly cash_outflow?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts active during the period */
  readonly mrr_active?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were churned during the period */
  readonly mrr_churned?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were contracted. Customer is paying less */
  readonly mrr_contractions?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were expanded. Customer is paying more */
  readonly mrr_expansions?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were acquired during the period */
  readonly mrr_new?: Maybe<Scalars['Float']>;
  readonly mrr_traded?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type vendor_financials_sum_fields = {
  readonly __typename?: 'vendor_financials_sum_fields';
  readonly cash_balance?: Maybe<Scalars['bigint']>;
  readonly cash_inflow?: Maybe<Scalars['bigint']>;
  readonly cash_outflow?: Maybe<Scalars['bigint']>;
  /** Revenue from all recurring contracts active during the period */
  readonly mrr_active?: Maybe<Scalars['bigint']>;
  /** Revenue from all recurring contracts that were churned during the period */
  readonly mrr_churned?: Maybe<Scalars['bigint']>;
  /** Revenue from all recurring contracts that were contracted. Customer is paying less */
  readonly mrr_contractions?: Maybe<Scalars['bigint']>;
  /** Revenue from all recurring contracts that were expanded. Customer is paying more */
  readonly mrr_expansions?: Maybe<Scalars['bigint']>;
  /** Revenue from all recurring contracts that were acquired during the period */
  readonly mrr_new?: Maybe<Scalars['bigint']>;
  readonly mrr_traded?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type vendor_financials_var_pop_fields = {
  readonly __typename?: 'vendor_financials_var_pop_fields';
  readonly cash_balance?: Maybe<Scalars['Float']>;
  readonly cash_inflow?: Maybe<Scalars['Float']>;
  readonly cash_outflow?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts active during the period */
  readonly mrr_active?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were churned during the period */
  readonly mrr_churned?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were contracted. Customer is paying less */
  readonly mrr_contractions?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were expanded. Customer is paying more */
  readonly mrr_expansions?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were acquired during the period */
  readonly mrr_new?: Maybe<Scalars['Float']>;
  readonly mrr_traded?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type vendor_financials_var_samp_fields = {
  readonly __typename?: 'vendor_financials_var_samp_fields';
  readonly cash_balance?: Maybe<Scalars['Float']>;
  readonly cash_inflow?: Maybe<Scalars['Float']>;
  readonly cash_outflow?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts active during the period */
  readonly mrr_active?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were churned during the period */
  readonly mrr_churned?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were contracted. Customer is paying less */
  readonly mrr_contractions?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were expanded. Customer is paying more */
  readonly mrr_expansions?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were acquired during the period */
  readonly mrr_new?: Maybe<Scalars['Float']>;
  readonly mrr_traded?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type vendor_financials_variance_fields = {
  readonly __typename?: 'vendor_financials_variance_fields';
  readonly cash_balance?: Maybe<Scalars['Float']>;
  readonly cash_inflow?: Maybe<Scalars['Float']>;
  readonly cash_outflow?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts active during the period */
  readonly mrr_active?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were churned during the period */
  readonly mrr_churned?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were contracted. Customer is paying less */
  readonly mrr_contractions?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were expanded. Customer is paying more */
  readonly mrr_expansions?: Maybe<Scalars['Float']>;
  /** Revenue from all recurring contracts that were acquired during the period */
  readonly mrr_new?: Maybe<Scalars['Float']>;
  readonly mrr_traded?: Maybe<Scalars['Float']>;
};

export enum vendor_lead_status_enum_enum {
  call_scheduled = 'call_scheduled',
  contacted = 'contacted',
  not_fit_right_now = 'not_fit_right_now',
  onboarding = 'onboarding',
  pending = 'pending',
  unqualified_bank = 'unqualified_bank',
  unqualified_billing_manager = 'unqualified_billing_manager',
  unqualified_international = 'unqualified_international',
  unqualified_investor = 'unqualified_investor',
  unqualified_not_saas = 'unqualified_not_saas',
  unqualified_reconnect_later = 'unqualified_reconnect_later',
  unqualified_spam = 'unqualified_spam'
}

/** Boolean expression to compare columns of type "vendor_lead_status_enum_enum". All fields are combined with logical 'AND'. */
export type vendor_lead_status_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<vendor_lead_status_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<vendor_lead_status_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<vendor_lead_status_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<vendor_lead_status_enum_enum>>;
};

/** columns and relationships of "vendor_metric_jobs" */
export type vendor_metric_jobs = {
  readonly __typename?: 'vendor_metric_jobs';
  readonly completed_at?: Maybe<Scalars['timestamptz']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "vendor_metric_jobs" */
export type vendor_metric_jobs_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<vendor_metric_jobs_max_order_by>;
  readonly min?: InputMaybe<vendor_metric_jobs_min_order_by>;
};

/** Boolean expression to filter rows from the table "vendor_metric_jobs". All fields are combined with a logical 'AND'. */
export type vendor_metric_jobs_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_metric_jobs_bool_exp>>;
  readonly _not?: InputMaybe<vendor_metric_jobs_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_metric_jobs_bool_exp>>;
  readonly completed_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly status?: InputMaybe<String_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly vendor_public_id?: InputMaybe<String_comparison_exp>;
};

/** order by max() on columns of table "vendor_metric_jobs" */
export type vendor_metric_jobs_max_order_by = {
  readonly completed_at?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly status?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly vendor_public_id?: InputMaybe<order_by>;
};

/** order by min() on columns of table "vendor_metric_jobs" */
export type vendor_metric_jobs_min_order_by = {
  readonly completed_at?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly status?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly vendor_public_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "vendor_metric_jobs". */
export type vendor_metric_jobs_order_by = {
  readonly completed_at?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly status?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly vendor_public_id?: InputMaybe<order_by>;
};

/** select columns of table "vendor_metric_jobs" */
export enum vendor_metric_jobs_select_column {
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  public_id = 'public_id',
  /** column name */
  status = 'status',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  vendor_public_id = 'vendor_public_id'
}

/**
 * Metrics used to decide whether or not to underwrite a vendor. Re-calculated when the underlying data changes
 *
 *
 * columns and relationships of "vendor_metrics"
 */
export type vendor_metrics = {
  readonly __typename?: 'vendor_metrics';
  readonly adjusted_runway?: Maybe<Scalars['float8']>;
  /** { customer_retention: { month: average }, revenue_retention: { month: average } } */
  readonly charge_based_retention_averages?: Maybe<Scalars['jsonb']>;
  readonly created_at: Scalars['timestamptz'];
  readonly credit_usage?: Maybe<Scalars['bigint']>;
  readonly currency: Scalars['currency'];
  readonly current_bank_balance?: Maybe<Scalars['bigint']>;
  /** Total ARR of all currently traded revenue streams */
  readonly currently_traded_arr?: Maybe<Scalars['bigint']>;
  readonly customer_ratio_qoq?: Maybe<Scalars['float8']>;
  readonly customer_retention?: Maybe<Scalars['jsonb']>;
  readonly customer_retention_qoq_data?: Maybe<Scalars['jsonb']>;
  readonly customer_retention_qoq_ok?: Maybe<Scalars['Boolean']>;
  /** arr of delinquent subscriptions / arr of active + delinquent subscriptions, null if no data. Includes only USD subs */
  readonly delinquent_ratio?: Maybe<Scalars['float8']>;
  readonly public_id: Scalars['String'];
  readonly revenue_ratio_qoq?: Maybe<Scalars['float8']>;
  readonly revenue_retention_qoq_data?: Maybe<Scalars['jsonb']>;
  readonly revenue_retention_qoq_ok?: Maybe<Scalars['Boolean']>;
  readonly runway?: Maybe<Scalars['float8']>;
  /** { customer_retention: { month: average }, revenue_retention: { month: average } } */
  readonly sub_based_retention_averages?: Maybe<Scalars['jsonb']>;
  readonly timestamp: Scalars['timestamptz'];
  readonly total_addressable_arr?: Maybe<Scalars['bigint']>;
  readonly total_arr?: Maybe<Scalars['bigint']>;
  readonly total_nsf_fees?: Maybe<Scalars['bigint']>;
  readonly updated_at: Scalars['timestamptz'];
  /** An object relationship */
  readonly vendor: vendors;
};


/**
 * Metrics used to decide whether or not to underwrite a vendor. Re-calculated when the underlying data changes
 *
 *
 * columns and relationships of "vendor_metrics"
 */
export type vendor_metricscharge_based_retention_averagesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/**
 * Metrics used to decide whether or not to underwrite a vendor. Re-calculated when the underlying data changes
 *
 *
 * columns and relationships of "vendor_metrics"
 */
export type vendor_metricscustomer_retentionArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/**
 * Metrics used to decide whether or not to underwrite a vendor. Re-calculated when the underlying data changes
 *
 *
 * columns and relationships of "vendor_metrics"
 */
export type vendor_metricscustomer_retention_qoq_dataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/**
 * Metrics used to decide whether or not to underwrite a vendor. Re-calculated when the underlying data changes
 *
 *
 * columns and relationships of "vendor_metrics"
 */
export type vendor_metricsrevenue_retention_qoq_dataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/**
 * Metrics used to decide whether or not to underwrite a vendor. Re-calculated when the underlying data changes
 *
 *
 * columns and relationships of "vendor_metrics"
 */
export type vendor_metricssub_based_retention_averagesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "vendor_metrics" */
export type vendor_metrics_aggregate_order_by = {
  readonly avg?: InputMaybe<vendor_metrics_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<vendor_metrics_max_order_by>;
  readonly min?: InputMaybe<vendor_metrics_min_order_by>;
  readonly stddev?: InputMaybe<vendor_metrics_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<vendor_metrics_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<vendor_metrics_stddev_samp_order_by>;
  readonly sum?: InputMaybe<vendor_metrics_sum_order_by>;
  readonly var_pop?: InputMaybe<vendor_metrics_var_pop_order_by>;
  readonly var_samp?: InputMaybe<vendor_metrics_var_samp_order_by>;
  readonly variance?: InputMaybe<vendor_metrics_variance_order_by>;
};

/** order by avg() on columns of table "vendor_metrics" */
export type vendor_metrics_avg_order_by = {
  readonly adjusted_runway?: InputMaybe<order_by>;
  readonly credit_usage?: InputMaybe<order_by>;
  readonly current_bank_balance?: InputMaybe<order_by>;
  /** Total ARR of all currently traded revenue streams */
  readonly currently_traded_arr?: InputMaybe<order_by>;
  readonly customer_ratio_qoq?: InputMaybe<order_by>;
  /** arr of delinquent subscriptions / arr of active + delinquent subscriptions, null if no data. Includes only USD subs */
  readonly delinquent_ratio?: InputMaybe<order_by>;
  readonly revenue_ratio_qoq?: InputMaybe<order_by>;
  readonly runway?: InputMaybe<order_by>;
  readonly total_addressable_arr?: InputMaybe<order_by>;
  readonly total_arr?: InputMaybe<order_by>;
  readonly total_nsf_fees?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "vendor_metrics". All fields are combined with a logical 'AND'. */
export type vendor_metrics_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_metrics_bool_exp>>;
  readonly _not?: InputMaybe<vendor_metrics_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_metrics_bool_exp>>;
  readonly adjusted_runway?: InputMaybe<float8_comparison_exp>;
  readonly charge_based_retention_averages?: InputMaybe<jsonb_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly credit_usage?: InputMaybe<bigint_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly current_bank_balance?: InputMaybe<bigint_comparison_exp>;
  readonly currently_traded_arr?: InputMaybe<bigint_comparison_exp>;
  readonly customer_ratio_qoq?: InputMaybe<float8_comparison_exp>;
  readonly customer_retention?: InputMaybe<jsonb_comparison_exp>;
  readonly customer_retention_qoq_data?: InputMaybe<jsonb_comparison_exp>;
  readonly customer_retention_qoq_ok?: InputMaybe<Boolean_comparison_exp>;
  readonly delinquent_ratio?: InputMaybe<float8_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly revenue_ratio_qoq?: InputMaybe<float8_comparison_exp>;
  readonly revenue_retention_qoq_data?: InputMaybe<jsonb_comparison_exp>;
  readonly revenue_retention_qoq_ok?: InputMaybe<Boolean_comparison_exp>;
  readonly runway?: InputMaybe<float8_comparison_exp>;
  readonly sub_based_retention_averages?: InputMaybe<jsonb_comparison_exp>;
  readonly timestamp?: InputMaybe<timestamptz_comparison_exp>;
  readonly total_addressable_arr?: InputMaybe<bigint_comparison_exp>;
  readonly total_arr?: InputMaybe<bigint_comparison_exp>;
  readonly total_nsf_fees?: InputMaybe<bigint_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/**
 * Latest computed vendor metrics for each vendor.
 *
 *
 * columns and relationships of "vendor_metrics_latest"
 */
export type vendor_metrics_latest = {
  readonly __typename?: 'vendor_metrics_latest';
  readonly adjusted_runway?: Maybe<Scalars['float8']>;
  readonly charge_based_retention_averages?: Maybe<Scalars['jsonb']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly credit_limit?: Maybe<Scalars['bigint']>;
  readonly credit_usage?: Maybe<Scalars['bigint']>;
  readonly currency?: Maybe<Scalars['currency']>;
  readonly current_bank_balance?: Maybe<Scalars['bigint']>;
  readonly customer_ratio_qoq?: Maybe<Scalars['float8']>;
  readonly customer_retention?: Maybe<Scalars['jsonb']>;
  readonly customer_retention_qoq_data?: Maybe<Scalars['jsonb']>;
  readonly customer_retention_qoq_ok?: Maybe<Scalars['Boolean']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly revenue_ratio_qoq?: Maybe<Scalars['float8']>;
  readonly revenue_retention_qoq_data?: Maybe<Scalars['jsonb']>;
  readonly revenue_retention_qoq_ok?: Maybe<Scalars['Boolean']>;
  readonly runway?: Maybe<Scalars['float8']>;
  readonly sub_based_retention_averages?: Maybe<Scalars['jsonb']>;
  readonly timestamp?: Maybe<Scalars['timestamptz']>;
  readonly total_addressable_arr?: Maybe<Scalars['bigint']>;
  readonly total_arr?: Maybe<Scalars['bigint']>;
  readonly total_nsf_fees?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};


/**
 * Latest computed vendor metrics for each vendor.
 *
 *
 * columns and relationships of "vendor_metrics_latest"
 */
export type vendor_metrics_latestcharge_based_retention_averagesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/**
 * Latest computed vendor metrics for each vendor.
 *
 *
 * columns and relationships of "vendor_metrics_latest"
 */
export type vendor_metrics_latestcustomer_retentionArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/**
 * Latest computed vendor metrics for each vendor.
 *
 *
 * columns and relationships of "vendor_metrics_latest"
 */
export type vendor_metrics_latestcustomer_retention_qoq_dataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/**
 * Latest computed vendor metrics for each vendor.
 *
 *
 * columns and relationships of "vendor_metrics_latest"
 */
export type vendor_metrics_latestrevenue_retention_qoq_dataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/**
 * Latest computed vendor metrics for each vendor.
 *
 *
 * columns and relationships of "vendor_metrics_latest"
 */
export type vendor_metrics_latestsub_based_retention_averagesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "vendor_metrics_latest". All fields are combined with a logical 'AND'. */
export type vendor_metrics_latest_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_metrics_latest_bool_exp>>;
  readonly _not?: InputMaybe<vendor_metrics_latest_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_metrics_latest_bool_exp>>;
  readonly adjusted_runway?: InputMaybe<float8_comparison_exp>;
  readonly charge_based_retention_averages?: InputMaybe<jsonb_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly credit_limit?: InputMaybe<bigint_comparison_exp>;
  readonly credit_usage?: InputMaybe<bigint_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly current_bank_balance?: InputMaybe<bigint_comparison_exp>;
  readonly customer_ratio_qoq?: InputMaybe<float8_comparison_exp>;
  readonly customer_retention?: InputMaybe<jsonb_comparison_exp>;
  readonly customer_retention_qoq_data?: InputMaybe<jsonb_comparison_exp>;
  readonly customer_retention_qoq_ok?: InputMaybe<Boolean_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly revenue_ratio_qoq?: InputMaybe<float8_comparison_exp>;
  readonly revenue_retention_qoq_data?: InputMaybe<jsonb_comparison_exp>;
  readonly revenue_retention_qoq_ok?: InputMaybe<Boolean_comparison_exp>;
  readonly runway?: InputMaybe<float8_comparison_exp>;
  readonly sub_based_retention_averages?: InputMaybe<jsonb_comparison_exp>;
  readonly timestamp?: InputMaybe<timestamptz_comparison_exp>;
  readonly total_addressable_arr?: InputMaybe<bigint_comparison_exp>;
  readonly total_arr?: InputMaybe<bigint_comparison_exp>;
  readonly total_nsf_fees?: InputMaybe<bigint_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** Ordering options when selecting data from "vendor_metrics_latest". */
export type vendor_metrics_latest_order_by = {
  readonly adjusted_runway?: InputMaybe<order_by>;
  readonly charge_based_retention_averages?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly credit_limit?: InputMaybe<order_by>;
  readonly credit_usage?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly current_bank_balance?: InputMaybe<order_by>;
  readonly customer_ratio_qoq?: InputMaybe<order_by>;
  readonly customer_retention?: InputMaybe<order_by>;
  readonly customer_retention_qoq_data?: InputMaybe<order_by>;
  readonly customer_retention_qoq_ok?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly revenue_ratio_qoq?: InputMaybe<order_by>;
  readonly revenue_retention_qoq_data?: InputMaybe<order_by>;
  readonly revenue_retention_qoq_ok?: InputMaybe<order_by>;
  readonly runway?: InputMaybe<order_by>;
  readonly sub_based_retention_averages?: InputMaybe<order_by>;
  readonly timestamp?: InputMaybe<order_by>;
  readonly total_addressable_arr?: InputMaybe<order_by>;
  readonly total_arr?: InputMaybe<order_by>;
  readonly total_nsf_fees?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** select columns of table "vendor_metrics_latest" */
export enum vendor_metrics_latest_select_column {
  /** column name */
  adjusted_runway = 'adjusted_runway',
  /** column name */
  charge_based_retention_averages = 'charge_based_retention_averages',
  /** column name */
  created_at = 'created_at',
  /** column name */
  credit_limit = 'credit_limit',
  /** column name */
  credit_usage = 'credit_usage',
  /** column name */
  currency = 'currency',
  /** column name */
  current_bank_balance = 'current_bank_balance',
  /** column name */
  customer_ratio_qoq = 'customer_ratio_qoq',
  /** column name */
  customer_retention = 'customer_retention',
  /** column name */
  customer_retention_qoq_data = 'customer_retention_qoq_data',
  /** column name */
  customer_retention_qoq_ok = 'customer_retention_qoq_ok',
  /** column name */
  public_id = 'public_id',
  /** column name */
  revenue_ratio_qoq = 'revenue_ratio_qoq',
  /** column name */
  revenue_retention_qoq_data = 'revenue_retention_qoq_data',
  /** column name */
  revenue_retention_qoq_ok = 'revenue_retention_qoq_ok',
  /** column name */
  runway = 'runway',
  /** column name */
  sub_based_retention_averages = 'sub_based_retention_averages',
  /** column name */
  timestamp = 'timestamp',
  /** column name */
  total_addressable_arr = 'total_addressable_arr',
  /** column name */
  total_arr = 'total_arr',
  /** column name */
  total_nsf_fees = 'total_nsf_fees',
  /** column name */
  updated_at = 'updated_at'
}

/** order by max() on columns of table "vendor_metrics" */
export type vendor_metrics_max_order_by = {
  readonly adjusted_runway?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly credit_usage?: InputMaybe<order_by>;
  readonly current_bank_balance?: InputMaybe<order_by>;
  /** Total ARR of all currently traded revenue streams */
  readonly currently_traded_arr?: InputMaybe<order_by>;
  readonly customer_ratio_qoq?: InputMaybe<order_by>;
  /** arr of delinquent subscriptions / arr of active + delinquent subscriptions, null if no data. Includes only USD subs */
  readonly delinquent_ratio?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly revenue_ratio_qoq?: InputMaybe<order_by>;
  readonly runway?: InputMaybe<order_by>;
  readonly timestamp?: InputMaybe<order_by>;
  readonly total_addressable_arr?: InputMaybe<order_by>;
  readonly total_arr?: InputMaybe<order_by>;
  readonly total_nsf_fees?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** order by min() on columns of table "vendor_metrics" */
export type vendor_metrics_min_order_by = {
  readonly adjusted_runway?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly credit_usage?: InputMaybe<order_by>;
  readonly current_bank_balance?: InputMaybe<order_by>;
  /** Total ARR of all currently traded revenue streams */
  readonly currently_traded_arr?: InputMaybe<order_by>;
  readonly customer_ratio_qoq?: InputMaybe<order_by>;
  /** arr of delinquent subscriptions / arr of active + delinquent subscriptions, null if no data. Includes only USD subs */
  readonly delinquent_ratio?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly revenue_ratio_qoq?: InputMaybe<order_by>;
  readonly runway?: InputMaybe<order_by>;
  readonly timestamp?: InputMaybe<order_by>;
  readonly total_addressable_arr?: InputMaybe<order_by>;
  readonly total_arr?: InputMaybe<order_by>;
  readonly total_nsf_fees?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "vendor_metrics". */
export type vendor_metrics_order_by = {
  readonly adjusted_runway?: InputMaybe<order_by>;
  readonly charge_based_retention_averages?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly credit_usage?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly current_bank_balance?: InputMaybe<order_by>;
  readonly currently_traded_arr?: InputMaybe<order_by>;
  readonly customer_ratio_qoq?: InputMaybe<order_by>;
  readonly customer_retention?: InputMaybe<order_by>;
  readonly customer_retention_qoq_data?: InputMaybe<order_by>;
  readonly customer_retention_qoq_ok?: InputMaybe<order_by>;
  readonly delinquent_ratio?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly revenue_ratio_qoq?: InputMaybe<order_by>;
  readonly revenue_retention_qoq_data?: InputMaybe<order_by>;
  readonly revenue_retention_qoq_ok?: InputMaybe<order_by>;
  readonly runway?: InputMaybe<order_by>;
  readonly sub_based_retention_averages?: InputMaybe<order_by>;
  readonly timestamp?: InputMaybe<order_by>;
  readonly total_addressable_arr?: InputMaybe<order_by>;
  readonly total_arr?: InputMaybe<order_by>;
  readonly total_nsf_fees?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "vendor_metrics" */
export enum vendor_metrics_select_column {
  /** column name */
  adjusted_runway = 'adjusted_runway',
  /** column name */
  charge_based_retention_averages = 'charge_based_retention_averages',
  /** column name */
  created_at = 'created_at',
  /** column name */
  credit_usage = 'credit_usage',
  /** column name */
  currency = 'currency',
  /** column name */
  current_bank_balance = 'current_bank_balance',
  /** column name */
  currently_traded_arr = 'currently_traded_arr',
  /** column name */
  customer_ratio_qoq = 'customer_ratio_qoq',
  /** column name */
  customer_retention = 'customer_retention',
  /** column name */
  customer_retention_qoq_data = 'customer_retention_qoq_data',
  /** column name */
  customer_retention_qoq_ok = 'customer_retention_qoq_ok',
  /** column name */
  delinquent_ratio = 'delinquent_ratio',
  /** column name */
  public_id = 'public_id',
  /** column name */
  revenue_ratio_qoq = 'revenue_ratio_qoq',
  /** column name */
  revenue_retention_qoq_data = 'revenue_retention_qoq_data',
  /** column name */
  revenue_retention_qoq_ok = 'revenue_retention_qoq_ok',
  /** column name */
  runway = 'runway',
  /** column name */
  sub_based_retention_averages = 'sub_based_retention_averages',
  /** column name */
  timestamp = 'timestamp',
  /** column name */
  total_addressable_arr = 'total_addressable_arr',
  /** column name */
  total_arr = 'total_arr',
  /** column name */
  total_nsf_fees = 'total_nsf_fees',
  /** column name */
  updated_at = 'updated_at'
}

/** order by stddev() on columns of table "vendor_metrics" */
export type vendor_metrics_stddev_order_by = {
  readonly adjusted_runway?: InputMaybe<order_by>;
  readonly credit_usage?: InputMaybe<order_by>;
  readonly current_bank_balance?: InputMaybe<order_by>;
  /** Total ARR of all currently traded revenue streams */
  readonly currently_traded_arr?: InputMaybe<order_by>;
  readonly customer_ratio_qoq?: InputMaybe<order_by>;
  /** arr of delinquent subscriptions / arr of active + delinquent subscriptions, null if no data. Includes only USD subs */
  readonly delinquent_ratio?: InputMaybe<order_by>;
  readonly revenue_ratio_qoq?: InputMaybe<order_by>;
  readonly runway?: InputMaybe<order_by>;
  readonly total_addressable_arr?: InputMaybe<order_by>;
  readonly total_arr?: InputMaybe<order_by>;
  readonly total_nsf_fees?: InputMaybe<order_by>;
};

/** order by stddev_pop() on columns of table "vendor_metrics" */
export type vendor_metrics_stddev_pop_order_by = {
  readonly adjusted_runway?: InputMaybe<order_by>;
  readonly credit_usage?: InputMaybe<order_by>;
  readonly current_bank_balance?: InputMaybe<order_by>;
  /** Total ARR of all currently traded revenue streams */
  readonly currently_traded_arr?: InputMaybe<order_by>;
  readonly customer_ratio_qoq?: InputMaybe<order_by>;
  /** arr of delinquent subscriptions / arr of active + delinquent subscriptions, null if no data. Includes only USD subs */
  readonly delinquent_ratio?: InputMaybe<order_by>;
  readonly revenue_ratio_qoq?: InputMaybe<order_by>;
  readonly runway?: InputMaybe<order_by>;
  readonly total_addressable_arr?: InputMaybe<order_by>;
  readonly total_arr?: InputMaybe<order_by>;
  readonly total_nsf_fees?: InputMaybe<order_by>;
};

/** order by stddev_samp() on columns of table "vendor_metrics" */
export type vendor_metrics_stddev_samp_order_by = {
  readonly adjusted_runway?: InputMaybe<order_by>;
  readonly credit_usage?: InputMaybe<order_by>;
  readonly current_bank_balance?: InputMaybe<order_by>;
  /** Total ARR of all currently traded revenue streams */
  readonly currently_traded_arr?: InputMaybe<order_by>;
  readonly customer_ratio_qoq?: InputMaybe<order_by>;
  /** arr of delinquent subscriptions / arr of active + delinquent subscriptions, null if no data. Includes only USD subs */
  readonly delinquent_ratio?: InputMaybe<order_by>;
  readonly revenue_ratio_qoq?: InputMaybe<order_by>;
  readonly runway?: InputMaybe<order_by>;
  readonly total_addressable_arr?: InputMaybe<order_by>;
  readonly total_arr?: InputMaybe<order_by>;
  readonly total_nsf_fees?: InputMaybe<order_by>;
};

/** order by sum() on columns of table "vendor_metrics" */
export type vendor_metrics_sum_order_by = {
  readonly adjusted_runway?: InputMaybe<order_by>;
  readonly credit_usage?: InputMaybe<order_by>;
  readonly current_bank_balance?: InputMaybe<order_by>;
  /** Total ARR of all currently traded revenue streams */
  readonly currently_traded_arr?: InputMaybe<order_by>;
  readonly customer_ratio_qoq?: InputMaybe<order_by>;
  /** arr of delinquent subscriptions / arr of active + delinquent subscriptions, null if no data. Includes only USD subs */
  readonly delinquent_ratio?: InputMaybe<order_by>;
  readonly revenue_ratio_qoq?: InputMaybe<order_by>;
  readonly runway?: InputMaybe<order_by>;
  readonly total_addressable_arr?: InputMaybe<order_by>;
  readonly total_arr?: InputMaybe<order_by>;
  readonly total_nsf_fees?: InputMaybe<order_by>;
};

/** order by var_pop() on columns of table "vendor_metrics" */
export type vendor_metrics_var_pop_order_by = {
  readonly adjusted_runway?: InputMaybe<order_by>;
  readonly credit_usage?: InputMaybe<order_by>;
  readonly current_bank_balance?: InputMaybe<order_by>;
  /** Total ARR of all currently traded revenue streams */
  readonly currently_traded_arr?: InputMaybe<order_by>;
  readonly customer_ratio_qoq?: InputMaybe<order_by>;
  /** arr of delinquent subscriptions / arr of active + delinquent subscriptions, null if no data. Includes only USD subs */
  readonly delinquent_ratio?: InputMaybe<order_by>;
  readonly revenue_ratio_qoq?: InputMaybe<order_by>;
  readonly runway?: InputMaybe<order_by>;
  readonly total_addressable_arr?: InputMaybe<order_by>;
  readonly total_arr?: InputMaybe<order_by>;
  readonly total_nsf_fees?: InputMaybe<order_by>;
};

/** order by var_samp() on columns of table "vendor_metrics" */
export type vendor_metrics_var_samp_order_by = {
  readonly adjusted_runway?: InputMaybe<order_by>;
  readonly credit_usage?: InputMaybe<order_by>;
  readonly current_bank_balance?: InputMaybe<order_by>;
  /** Total ARR of all currently traded revenue streams */
  readonly currently_traded_arr?: InputMaybe<order_by>;
  readonly customer_ratio_qoq?: InputMaybe<order_by>;
  /** arr of delinquent subscriptions / arr of active + delinquent subscriptions, null if no data. Includes only USD subs */
  readonly delinquent_ratio?: InputMaybe<order_by>;
  readonly revenue_ratio_qoq?: InputMaybe<order_by>;
  readonly runway?: InputMaybe<order_by>;
  readonly total_addressable_arr?: InputMaybe<order_by>;
  readonly total_arr?: InputMaybe<order_by>;
  readonly total_nsf_fees?: InputMaybe<order_by>;
};

/** order by variance() on columns of table "vendor_metrics" */
export type vendor_metrics_variance_order_by = {
  readonly adjusted_runway?: InputMaybe<order_by>;
  readonly credit_usage?: InputMaybe<order_by>;
  readonly current_bank_balance?: InputMaybe<order_by>;
  /** Total ARR of all currently traded revenue streams */
  readonly currently_traded_arr?: InputMaybe<order_by>;
  readonly customer_ratio_qoq?: InputMaybe<order_by>;
  /** arr of delinquent subscriptions / arr of active + delinquent subscriptions, null if no data. Includes only USD subs */
  readonly delinquent_ratio?: InputMaybe<order_by>;
  readonly revenue_ratio_qoq?: InputMaybe<order_by>;
  readonly runway?: InputMaybe<order_by>;
  readonly total_addressable_arr?: InputMaybe<order_by>;
  readonly total_arr?: InputMaybe<order_by>;
  readonly total_nsf_fees?: InputMaybe<order_by>;
};

/** columns and relationships of "vendor_payment_status" */
export type vendor_payment_status = {
  readonly __typename?: 'vendor_payment_status';
  readonly credit_usage?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  readonly vendor?: Maybe<vendors>;
};

/** aggregated selection of "vendor_payment_status" */
export type vendor_payment_status_aggregate = {
  readonly __typename?: 'vendor_payment_status_aggregate';
  readonly aggregate?: Maybe<vendor_payment_status_aggregate_fields>;
  readonly nodes: ReadonlyArray<vendor_payment_status>;
};

/** aggregate fields of "vendor_payment_status" */
export type vendor_payment_status_aggregate_fields = {
  readonly __typename?: 'vendor_payment_status_aggregate_fields';
  readonly avg?: Maybe<vendor_payment_status_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<vendor_payment_status_max_fields>;
  readonly min?: Maybe<vendor_payment_status_min_fields>;
  readonly stddev?: Maybe<vendor_payment_status_stddev_fields>;
  readonly stddev_pop?: Maybe<vendor_payment_status_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<vendor_payment_status_stddev_samp_fields>;
  readonly sum?: Maybe<vendor_payment_status_sum_fields>;
  readonly var_pop?: Maybe<vendor_payment_status_var_pop_fields>;
  readonly var_samp?: Maybe<vendor_payment_status_var_samp_fields>;
  readonly variance?: Maybe<vendor_payment_status_variance_fields>;
};


/** aggregate fields of "vendor_payment_status" */
export type vendor_payment_status_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<vendor_payment_status_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type vendor_payment_status_avg_fields = {
  readonly __typename?: 'vendor_payment_status_avg_fields';
  readonly credit_usage?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "vendor_payment_status". All fields are combined with a logical 'AND'. */
export type vendor_payment_status_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_payment_status_bool_exp>>;
  readonly _not?: InputMaybe<vendor_payment_status_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_payment_status_bool_exp>>;
  readonly credit_usage?: InputMaybe<bigint_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** aggregate max on columns */
export type vendor_payment_status_max_fields = {
  readonly __typename?: 'vendor_payment_status_max_fields';
  readonly credit_usage?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type vendor_payment_status_min_fields = {
  readonly __typename?: 'vendor_payment_status_min_fields';
  readonly credit_usage?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "vendor_payment_status". */
export type vendor_payment_status_order_by = {
  readonly credit_usage?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "vendor_payment_status" */
export enum vendor_payment_status_select_column {
  /** column name */
  credit_usage = 'credit_usage'
}

/** aggregate stddev on columns */
export type vendor_payment_status_stddev_fields = {
  readonly __typename?: 'vendor_payment_status_stddev_fields';
  readonly credit_usage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type vendor_payment_status_stddev_pop_fields = {
  readonly __typename?: 'vendor_payment_status_stddev_pop_fields';
  readonly credit_usage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type vendor_payment_status_stddev_samp_fields = {
  readonly __typename?: 'vendor_payment_status_stddev_samp_fields';
  readonly credit_usage?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type vendor_payment_status_sum_fields = {
  readonly __typename?: 'vendor_payment_status_sum_fields';
  readonly credit_usage?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type vendor_payment_status_var_pop_fields = {
  readonly __typename?: 'vendor_payment_status_var_pop_fields';
  readonly credit_usage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type vendor_payment_status_var_samp_fields = {
  readonly __typename?: 'vendor_payment_status_var_samp_fields';
  readonly credit_usage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type vendor_payment_status_variance_fields = {
  readonly __typename?: 'vendor_payment_status_variance_fields';
  readonly credit_usage?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "vendor_payments" */
export type vendor_payments = {
  readonly __typename?: 'vendor_payments';
  readonly account_institution_logo?: Maybe<Scalars['String']>;
  readonly account_institution_name?: Maybe<Scalars['String']>;
  readonly account_mask?: Maybe<Scalars['String']>;
  readonly account_name?: Maybe<Scalars['String']>;
  readonly cleared_at?: Maybe<Scalars['timestamptz']>;
  readonly created_at: Scalars['timestamptz'];
  readonly currency: Scalars['currency'];
  readonly due_at: Scalars['timestamptz'];
  readonly fee?: Maybe<Scalars['Int']>;
  readonly initiated_at?: Maybe<Scalars['timestamptz']>;
  readonly internal_payment_id?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly payment_medium?: Maybe<Scalars['String']>;
  readonly payment_type: Scalars['String'];
  readonly public_id: Scalars['String'];
  /** An array relationship */
  readonly subscription_payments: ReadonlyArray<subscription_payments>;
  /** An aggregate relationship */
  readonly subscription_payments_aggregate: subscription_payments_aggregate;
  readonly updated_at: Scalars['timestamptz'];
  /** An object relationship */
  readonly vendor: vendors;
  /** An object relationship */
  readonly vendor_payments_enriched?: Maybe<vendor_payments_enriched>;
  /** An object relationship */
  readonly vendor_payments_extended?: Maybe<vendor_payments_extended>;
};


/** columns and relationships of "vendor_payments" */
export type vendor_paymentssubscription_paymentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<subscription_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<subscription_payments_order_by>>;
  where?: InputMaybe<subscription_payments_bool_exp>;
};


/** columns and relationships of "vendor_payments" */
export type vendor_paymentssubscription_payments_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<subscription_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<subscription_payments_order_by>>;
  where?: InputMaybe<subscription_payments_bool_exp>;
};

/** aggregated selection of "vendor_payments" */
export type vendor_payments_aggregate = {
  readonly __typename?: 'vendor_payments_aggregate';
  readonly aggregate?: Maybe<vendor_payments_aggregate_fields>;
  readonly nodes: ReadonlyArray<vendor_payments>;
};

/** aggregate fields of "vendor_payments" */
export type vendor_payments_aggregate_fields = {
  readonly __typename?: 'vendor_payments_aggregate_fields';
  readonly avg?: Maybe<vendor_payments_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<vendor_payments_max_fields>;
  readonly min?: Maybe<vendor_payments_min_fields>;
  readonly stddev?: Maybe<vendor_payments_stddev_fields>;
  readonly stddev_pop?: Maybe<vendor_payments_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<vendor_payments_stddev_samp_fields>;
  readonly sum?: Maybe<vendor_payments_sum_fields>;
  readonly var_pop?: Maybe<vendor_payments_var_pop_fields>;
  readonly var_samp?: Maybe<vendor_payments_var_samp_fields>;
  readonly variance?: Maybe<vendor_payments_variance_fields>;
};


/** aggregate fields of "vendor_payments" */
export type vendor_payments_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<vendor_payments_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vendor_payments" */
export type vendor_payments_aggregate_order_by = {
  readonly avg?: InputMaybe<vendor_payments_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<vendor_payments_max_order_by>;
  readonly min?: InputMaybe<vendor_payments_min_order_by>;
  readonly stddev?: InputMaybe<vendor_payments_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<vendor_payments_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<vendor_payments_stddev_samp_order_by>;
  readonly sum?: InputMaybe<vendor_payments_sum_order_by>;
  readonly var_pop?: InputMaybe<vendor_payments_var_pop_order_by>;
  readonly var_samp?: InputMaybe<vendor_payments_var_samp_order_by>;
  readonly variance?: InputMaybe<vendor_payments_variance_order_by>;
};

/** aggregate avg on columns */
export type vendor_payments_avg_fields = {
  readonly __typename?: 'vendor_payments_avg_fields';
  readonly fee?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vendor_payments" */
export type vendor_payments_avg_order_by = {
  readonly fee?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "vendor_payments". All fields are combined with a logical 'AND'. */
export type vendor_payments_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_payments_bool_exp>>;
  readonly _not?: InputMaybe<vendor_payments_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_payments_bool_exp>>;
  readonly account_institution_logo?: InputMaybe<String_comparison_exp>;
  readonly account_institution_name?: InputMaybe<String_comparison_exp>;
  readonly account_mask?: InputMaybe<String_comparison_exp>;
  readonly account_name?: InputMaybe<String_comparison_exp>;
  readonly cleared_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly due_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly fee?: InputMaybe<Int_comparison_exp>;
  readonly initiated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly internal_payment_id?: InputMaybe<String_comparison_exp>;
  readonly notes?: InputMaybe<String_comparison_exp>;
  readonly payment_medium?: InputMaybe<String_comparison_exp>;
  readonly payment_type?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly subscription_payments?: InputMaybe<subscription_payments_bool_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
  readonly vendor_payments_enriched?: InputMaybe<vendor_payments_enriched_bool_exp>;
  readonly vendor_payments_extended?: InputMaybe<vendor_payments_extended_bool_exp>;
};

/** columns and relationships of "vendor_payments_enriched" */
export type vendor_payments_enriched = {
  readonly __typename?: 'vendor_payments_enriched';
  readonly amount?: Maybe<Scalars['bigint']>;
  readonly currency?: Maybe<Scalars['currency']>;
  readonly due_at?: Maybe<Scalars['timestamptz']>;
  readonly initiated_at?: Maybe<Scalars['timestamptz']>;
  readonly is_initiated?: Maybe<Scalars['Boolean']>;
  readonly num_sub_payments?: Maybe<Scalars['bigint']>;
  readonly payment_type?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly vendor?: Maybe<vendors>;
  readonly vendor_client_id?: Maybe<Scalars['String']>;
  readonly vendor_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly vendor_payment?: Maybe<vendor_payments>;
  readonly vendor_public_id?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "vendor_payments_enriched". All fields are combined with a logical 'AND'. */
export type vendor_payments_enriched_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_payments_enriched_bool_exp>>;
  readonly _not?: InputMaybe<vendor_payments_enriched_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_payments_enriched_bool_exp>>;
  readonly amount?: InputMaybe<bigint_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly due_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly initiated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly is_initiated?: InputMaybe<Boolean_comparison_exp>;
  readonly num_sub_payments?: InputMaybe<bigint_comparison_exp>;
  readonly payment_type?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
  readonly vendor_client_id?: InputMaybe<String_comparison_exp>;
  readonly vendor_name?: InputMaybe<String_comparison_exp>;
  readonly vendor_payment?: InputMaybe<vendor_payments_bool_exp>;
  readonly vendor_public_id?: InputMaybe<String_comparison_exp>;
};

/** Ordering options when selecting data from "vendor_payments_enriched". */
export type vendor_payments_enriched_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly due_at?: InputMaybe<order_by>;
  readonly initiated_at?: InputMaybe<order_by>;
  readonly is_initiated?: InputMaybe<order_by>;
  readonly num_sub_payments?: InputMaybe<order_by>;
  readonly payment_type?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
  readonly vendor_client_id?: InputMaybe<order_by>;
  readonly vendor_name?: InputMaybe<order_by>;
  readonly vendor_payment?: InputMaybe<vendor_payments_order_by>;
  readonly vendor_public_id?: InputMaybe<order_by>;
};

/** select columns of table "vendor_payments_enriched" */
export enum vendor_payments_enriched_select_column {
  /** column name */
  amount = 'amount',
  /** column name */
  currency = 'currency',
  /** column name */
  due_at = 'due_at',
  /** column name */
  initiated_at = 'initiated_at',
  /** column name */
  is_initiated = 'is_initiated',
  /** column name */
  num_sub_payments = 'num_sub_payments',
  /** column name */
  payment_type = 'payment_type',
  /** column name */
  public_id = 'public_id',
  /** column name */
  vendor_client_id = 'vendor_client_id',
  /** column name */
  vendor_name = 'vendor_name',
  /** column name */
  vendor_public_id = 'vendor_public_id'
}

/**
 * Additional information about a vendor payment
 *
 *
 * columns and relationships of "vendor_payments_extended"
 */
export type vendor_payments_extended = {
  readonly __typename?: 'vendor_payments_extended';
  readonly amount?: Maybe<Scalars['bigint']>;
  readonly amount_inverse?: Maybe<Scalars['bigint']>;
  readonly num_sub_payments?: Maybe<Scalars['bigint']>;
  readonly payment_status?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly vendor?: Maybe<vendors>;
  /** An object relationship */
  readonly vendor_payment?: Maybe<vendor_payments>;
};

/** Boolean expression to filter rows from the table "vendor_payments_extended". All fields are combined with a logical 'AND'. */
export type vendor_payments_extended_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_payments_extended_bool_exp>>;
  readonly _not?: InputMaybe<vendor_payments_extended_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_payments_extended_bool_exp>>;
  readonly amount?: InputMaybe<bigint_comparison_exp>;
  readonly amount_inverse?: InputMaybe<bigint_comparison_exp>;
  readonly num_sub_payments?: InputMaybe<bigint_comparison_exp>;
  readonly payment_status?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
  readonly vendor_payment?: InputMaybe<vendor_payments_bool_exp>;
};

/** Ordering options when selecting data from "vendor_payments_extended". */
export type vendor_payments_extended_order_by = {
  readonly amount?: InputMaybe<order_by>;
  readonly amount_inverse?: InputMaybe<order_by>;
  readonly num_sub_payments?: InputMaybe<order_by>;
  readonly payment_status?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
  readonly vendor_payment?: InputMaybe<vendor_payments_order_by>;
};

/** select columns of table "vendor_payments_extended" */
export enum vendor_payments_extended_select_column {
  /** column name */
  amount = 'amount',
  /** column name */
  amount_inverse = 'amount_inverse',
  /** column name */
  num_sub_payments = 'num_sub_payments',
  /** column name */
  payment_status = 'payment_status',
  /** column name */
  public_id = 'public_id'
}

/** aggregate max on columns */
export type vendor_payments_max_fields = {
  readonly __typename?: 'vendor_payments_max_fields';
  readonly account_institution_logo?: Maybe<Scalars['String']>;
  readonly account_institution_name?: Maybe<Scalars['String']>;
  readonly account_mask?: Maybe<Scalars['String']>;
  readonly account_name?: Maybe<Scalars['String']>;
  readonly cleared_at?: Maybe<Scalars['timestamptz']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly due_at?: Maybe<Scalars['timestamptz']>;
  readonly fee?: Maybe<Scalars['Int']>;
  readonly initiated_at?: Maybe<Scalars['timestamptz']>;
  readonly internal_payment_id?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly payment_medium?: Maybe<Scalars['String']>;
  readonly payment_type?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "vendor_payments" */
export type vendor_payments_max_order_by = {
  readonly account_institution_logo?: InputMaybe<order_by>;
  readonly account_institution_name?: InputMaybe<order_by>;
  readonly account_mask?: InputMaybe<order_by>;
  readonly account_name?: InputMaybe<order_by>;
  readonly cleared_at?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly due_at?: InputMaybe<order_by>;
  readonly fee?: InputMaybe<order_by>;
  readonly initiated_at?: InputMaybe<order_by>;
  readonly internal_payment_id?: InputMaybe<order_by>;
  readonly notes?: InputMaybe<order_by>;
  readonly payment_medium?: InputMaybe<order_by>;
  readonly payment_type?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type vendor_payments_min_fields = {
  readonly __typename?: 'vendor_payments_min_fields';
  readonly account_institution_logo?: Maybe<Scalars['String']>;
  readonly account_institution_name?: Maybe<Scalars['String']>;
  readonly account_mask?: Maybe<Scalars['String']>;
  readonly account_name?: Maybe<Scalars['String']>;
  readonly cleared_at?: Maybe<Scalars['timestamptz']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly due_at?: Maybe<Scalars['timestamptz']>;
  readonly fee?: Maybe<Scalars['Int']>;
  readonly initiated_at?: Maybe<Scalars['timestamptz']>;
  readonly internal_payment_id?: Maybe<Scalars['String']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly payment_medium?: Maybe<Scalars['String']>;
  readonly payment_type?: Maybe<Scalars['String']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "vendor_payments" */
export type vendor_payments_min_order_by = {
  readonly account_institution_logo?: InputMaybe<order_by>;
  readonly account_institution_name?: InputMaybe<order_by>;
  readonly account_mask?: InputMaybe<order_by>;
  readonly account_name?: InputMaybe<order_by>;
  readonly cleared_at?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly due_at?: InputMaybe<order_by>;
  readonly fee?: InputMaybe<order_by>;
  readonly initiated_at?: InputMaybe<order_by>;
  readonly internal_payment_id?: InputMaybe<order_by>;
  readonly notes?: InputMaybe<order_by>;
  readonly payment_medium?: InputMaybe<order_by>;
  readonly payment_type?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "vendor_payments". */
export type vendor_payments_order_by = {
  readonly account_institution_logo?: InputMaybe<order_by>;
  readonly account_institution_name?: InputMaybe<order_by>;
  readonly account_mask?: InputMaybe<order_by>;
  readonly account_name?: InputMaybe<order_by>;
  readonly cleared_at?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly due_at?: InputMaybe<order_by>;
  readonly fee?: InputMaybe<order_by>;
  readonly initiated_at?: InputMaybe<order_by>;
  readonly internal_payment_id?: InputMaybe<order_by>;
  readonly notes?: InputMaybe<order_by>;
  readonly payment_medium?: InputMaybe<order_by>;
  readonly payment_type?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly subscription_payments_aggregate?: InputMaybe<subscription_payments_aggregate_order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
  readonly vendor_payments_enriched?: InputMaybe<vendor_payments_enriched_order_by>;
  readonly vendor_payments_extended?: InputMaybe<vendor_payments_extended_order_by>;
};

/** select columns of table "vendor_payments" */
export enum vendor_payments_select_column {
  /** column name */
  account_institution_logo = 'account_institution_logo',
  /** column name */
  account_institution_name = 'account_institution_name',
  /** column name */
  account_mask = 'account_mask',
  /** column name */
  account_name = 'account_name',
  /** column name */
  cleared_at = 'cleared_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  due_at = 'due_at',
  /** column name */
  fee = 'fee',
  /** column name */
  initiated_at = 'initiated_at',
  /** column name */
  internal_payment_id = 'internal_payment_id',
  /** column name */
  notes = 'notes',
  /** column name */
  payment_medium = 'payment_medium',
  /** column name */
  payment_type = 'payment_type',
  /** column name */
  public_id = 'public_id',
  /** column name */
  updated_at = 'updated_at'
}

/** aggregate stddev on columns */
export type vendor_payments_stddev_fields = {
  readonly __typename?: 'vendor_payments_stddev_fields';
  readonly fee?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vendor_payments" */
export type vendor_payments_stddev_order_by = {
  readonly fee?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type vendor_payments_stddev_pop_fields = {
  readonly __typename?: 'vendor_payments_stddev_pop_fields';
  readonly fee?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vendor_payments" */
export type vendor_payments_stddev_pop_order_by = {
  readonly fee?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type vendor_payments_stddev_samp_fields = {
  readonly __typename?: 'vendor_payments_stddev_samp_fields';
  readonly fee?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vendor_payments" */
export type vendor_payments_stddev_samp_order_by = {
  readonly fee?: InputMaybe<order_by>;
};

/** aggregate sum on columns */
export type vendor_payments_sum_fields = {
  readonly __typename?: 'vendor_payments_sum_fields';
  readonly fee?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vendor_payments" */
export type vendor_payments_sum_order_by = {
  readonly fee?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type vendor_payments_var_pop_fields = {
  readonly __typename?: 'vendor_payments_var_pop_fields';
  readonly fee?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vendor_payments" */
export type vendor_payments_var_pop_order_by = {
  readonly fee?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type vendor_payments_var_samp_fields = {
  readonly __typename?: 'vendor_payments_var_samp_fields';
  readonly fee?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vendor_payments" */
export type vendor_payments_var_samp_order_by = {
  readonly fee?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type vendor_payments_variance_fields = {
  readonly __typename?: 'vendor_payments_variance_fields';
  readonly fee?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vendor_payments" */
export type vendor_payments_variance_order_by = {
  readonly fee?: InputMaybe<order_by>;
};

export enum vendor_pipeline_state_enum_enum {
  account_created = 'account_created',
  approved = 'approved',
  approved_no_pipe_yet = 'approved_no_pipe_yet',
  denied = 'denied',
  new = 'new',
  onboarding = 'onboarding',
  pending_demo = 'pending_demo',
  underwriting = 'underwriting'
}

/** Boolean expression to compare columns of type "vendor_pipeline_state_enum_enum". All fields are combined with logical 'AND'. */
export type vendor_pipeline_state_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<vendor_pipeline_state_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<vendor_pipeline_state_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<vendor_pipeline_state_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<vendor_pipeline_state_enum_enum>>;
};

/** columns and relationships of "vendor_product_waitlist" */
export type vendor_product_waitlist = {
  readonly __typename?: 'vendor_product_waitlist';
  readonly created_at: Scalars['timestamptz'];
  readonly public_id: Scalars['String'];
  readonly status: vendor_product_waitlist_status_enum_enum;
  readonly updated_at: Scalars['timestamptz'];
  /** An object relationship */
  readonly vendor: vendors;
  readonly vendor_product: vendor_products_enum_enum;
};

/** aggregated selection of "vendor_product_waitlist" */
export type vendor_product_waitlist_aggregate = {
  readonly __typename?: 'vendor_product_waitlist_aggregate';
  readonly aggregate?: Maybe<vendor_product_waitlist_aggregate_fields>;
  readonly nodes: ReadonlyArray<vendor_product_waitlist>;
};

/** aggregate fields of "vendor_product_waitlist" */
export type vendor_product_waitlist_aggregate_fields = {
  readonly __typename?: 'vendor_product_waitlist_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<vendor_product_waitlist_max_fields>;
  readonly min?: Maybe<vendor_product_waitlist_min_fields>;
};


/** aggregate fields of "vendor_product_waitlist" */
export type vendor_product_waitlist_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<vendor_product_waitlist_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "vendor_product_waitlist". All fields are combined with a logical 'AND'. */
export type vendor_product_waitlist_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_product_waitlist_bool_exp>>;
  readonly _not?: InputMaybe<vendor_product_waitlist_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_product_waitlist_bool_exp>>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly status?: InputMaybe<vendor_product_waitlist_status_enum_enum_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
  readonly vendor_product?: InputMaybe<vendor_products_enum_enum_comparison_exp>;
};

/** aggregate max on columns */
export type vendor_product_waitlist_max_fields = {
  readonly __typename?: 'vendor_product_waitlist_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type vendor_product_waitlist_min_fields = {
  readonly __typename?: 'vendor_product_waitlist_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "vendor_product_waitlist". */
export type vendor_product_waitlist_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly status?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
  readonly vendor_product?: InputMaybe<order_by>;
};

/** select columns of table "vendor_product_waitlist" */
export enum vendor_product_waitlist_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  public_id = 'public_id',
  /** column name */
  status = 'status',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  vendor_product = 'vendor_product'
}

export enum vendor_product_waitlist_status_enum_enum {
  approved = 'approved',
  rejected = 'rejected',
  waitlisted = 'waitlisted'
}

/** Boolean expression to compare columns of type "vendor_product_waitlist_status_enum_enum". All fields are combined with logical 'AND'. */
export type vendor_product_waitlist_status_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<vendor_product_waitlist_status_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<vendor_product_waitlist_status_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<vendor_product_waitlist_status_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<vendor_product_waitlist_status_enum_enum>>;
};

export enum vendor_products_enum_enum {
  treasury = 'treasury'
}

/** Boolean expression to compare columns of type "vendor_products_enum_enum". All fields are combined with logical 'AND'. */
export type vendor_products_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<vendor_products_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<vendor_products_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<vendor_products_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<vendor_products_enum_enum>>;
};

/** columns and relationships of "vendor_ratings" */
export type vendor_ratings = {
  readonly __typename?: 'vendor_ratings';
  readonly bid_prices: Scalars['jsonb'];
  readonly created_at: Scalars['timestamptz'];
  readonly currency: Scalars['currency'];
  readonly debug_info?: Maybe<Scalars['jsonb']>;
  readonly explanations: Scalars['_jsonb'];
  readonly max_tradable_term_length: Scalars['Int'];
  readonly model_version_id?: Maybe<Scalars['String']>;
  readonly public_id: Scalars['String'];
  readonly rate_months_1: Scalars['Int'];
  readonly rating: asset_rating_enum_enum;
  readonly trade_limit: Scalars['bigint'];
  /** An object relationship */
  readonly user?: Maybe<users>;
  readonly utilization_limits: Scalars['jsonb'];
  /** An object relationship */
  readonly vendor: vendors;
  /** An array relationship */
  readonly vendor_active_ratings: ReadonlyArray<vendor_active_ratings>;
};


/** columns and relationships of "vendor_ratings" */
export type vendor_ratingsbid_pricesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "vendor_ratings" */
export type vendor_ratingsdebug_infoArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "vendor_ratings" */
export type vendor_ratingsutilization_limitsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "vendor_ratings" */
export type vendor_ratingsvendor_active_ratingsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_active_ratings_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_active_ratings_order_by>>;
  where?: InputMaybe<vendor_active_ratings_bool_exp>;
};

/** Boolean expression to filter rows from the table "vendor_ratings". All fields are combined with a logical 'AND'. */
export type vendor_ratings_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_ratings_bool_exp>>;
  readonly _not?: InputMaybe<vendor_ratings_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_ratings_bool_exp>>;
  readonly bid_prices?: InputMaybe<jsonb_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly debug_info?: InputMaybe<jsonb_comparison_exp>;
  readonly explanations?: InputMaybe<_jsonb_comparison_exp>;
  readonly max_tradable_term_length?: InputMaybe<Int_comparison_exp>;
  readonly model_version_id?: InputMaybe<String_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly rate_months_1?: InputMaybe<Int_comparison_exp>;
  readonly rating?: InputMaybe<asset_rating_enum_enum_comparison_exp>;
  readonly trade_limit?: InputMaybe<bigint_comparison_exp>;
  readonly user?: InputMaybe<users_bool_exp>;
  readonly utilization_limits?: InputMaybe<jsonb_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
  readonly vendor_active_ratings?: InputMaybe<vendor_active_ratings_bool_exp>;
};

/** columns and relationships of "vendor_ratings_metadata" */
export type vendor_ratings_metadata = {
  readonly __typename?: 'vendor_ratings_metadata';
  readonly created_at: Scalars['timestamptz'];
  readonly heron_transactions_last_downloaded_at?: Maybe<Scalars['timestamptz']>;
  readonly heron_transactions_last_uploaded_at?: Maybe<Scalars['timestamptz']>;
  readonly public_id: Scalars['String'];
  readonly updated_at: Scalars['timestamptz'];
  /** An object relationship */
  readonly vendor: vendors;
};

/** order by aggregate values of table "vendor_ratings_metadata" */
export type vendor_ratings_metadata_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<vendor_ratings_metadata_max_order_by>;
  readonly min?: InputMaybe<vendor_ratings_metadata_min_order_by>;
};

/** Boolean expression to filter rows from the table "vendor_ratings_metadata". All fields are combined with a logical 'AND'. */
export type vendor_ratings_metadata_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_ratings_metadata_bool_exp>>;
  readonly _not?: InputMaybe<vendor_ratings_metadata_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_ratings_metadata_bool_exp>>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly heron_transactions_last_downloaded_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly heron_transactions_last_uploaded_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly vendor?: InputMaybe<vendors_bool_exp>;
};

/** order by max() on columns of table "vendor_ratings_metadata" */
export type vendor_ratings_metadata_max_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly heron_transactions_last_downloaded_at?: InputMaybe<order_by>;
  readonly heron_transactions_last_uploaded_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** order by min() on columns of table "vendor_ratings_metadata" */
export type vendor_ratings_metadata_min_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly heron_transactions_last_downloaded_at?: InputMaybe<order_by>;
  readonly heron_transactions_last_uploaded_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "vendor_ratings_metadata". */
export type vendor_ratings_metadata_order_by = {
  readonly created_at?: InputMaybe<order_by>;
  readonly heron_transactions_last_downloaded_at?: InputMaybe<order_by>;
  readonly heron_transactions_last_uploaded_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
};

/** select columns of table "vendor_ratings_metadata" */
export enum vendor_ratings_metadata_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  heron_transactions_last_downloaded_at = 'heron_transactions_last_downloaded_at',
  /** column name */
  heron_transactions_last_uploaded_at = 'heron_transactions_last_uploaded_at',
  /** column name */
  public_id = 'public_id',
  /** column name */
  updated_at = 'updated_at'
}

/** Ordering options when selecting data from "vendor_ratings". */
export type vendor_ratings_order_by = {
  readonly bid_prices?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly debug_info?: InputMaybe<order_by>;
  readonly explanations?: InputMaybe<order_by>;
  readonly max_tradable_term_length?: InputMaybe<order_by>;
  readonly model_version_id?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly rate_months_1?: InputMaybe<order_by>;
  readonly rating?: InputMaybe<order_by>;
  readonly trade_limit?: InputMaybe<order_by>;
  readonly user?: InputMaybe<users_order_by>;
  readonly utilization_limits?: InputMaybe<order_by>;
  readonly vendor?: InputMaybe<vendors_order_by>;
  readonly vendor_active_ratings_aggregate?: InputMaybe<vendor_active_ratings_aggregate_order_by>;
};

/** select columns of table "vendor_ratings" */
export enum vendor_ratings_select_column {
  /** column name */
  bid_prices = 'bid_prices',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  debug_info = 'debug_info',
  /** column name */
  explanations = 'explanations',
  /** column name */
  max_tradable_term_length = 'max_tradable_term_length',
  /** column name */
  model_version_id = 'model_version_id',
  /** column name */
  public_id = 'public_id',
  /** column name */
  rate_months_1 = 'rate_months_1',
  /** column name */
  rating = 'rating',
  /** column name */
  trade_limit = 'trade_limit',
  /** column name */
  utilization_limits = 'utilization_limits'
}

export enum vendor_sales_funnel_stage_enum_enum {
  approved = 'approved',
  closed_lost = 'closed_lost',
  contacted = 'contacted',
  declined = 'declined',
  leads = 'leads',
  leads_assigned = 'leads_assigned',
  meeting_scheduled = 'meeting_scheduled',
  onboarding = 'onboarding',
  opportunity = 'opportunity',
  piped = 'piped',
  qualified = 'qualified',
  reviewed_approved = 'reviewed_approved',
  reviewed_need_more_info = 'reviewed_need_more_info',
  scheduled_demo = 'scheduled_demo',
  uncategorized = 'uncategorized',
  visited_but_no_signup = 'visited_but_no_signup'
}

/** Boolean expression to compare columns of type "vendor_sales_funnel_stage_enum_enum". All fields are combined with logical 'AND'. */
export type vendor_sales_funnel_stage_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<vendor_sales_funnel_stage_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<vendor_sales_funnel_stage_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<vendor_sales_funnel_stage_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<vendor_sales_funnel_stage_enum_enum>>;
};

/** columns and relationships of "vendor_supported_currencies" */
export type vendor_supported_currencies = {
  readonly __typename?: 'vendor_supported_currencies';
  readonly currency: Scalars['currency'];
};

/** order by aggregate values of table "vendor_supported_currencies" */
export type vendor_supported_currencies_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "vendor_supported_currencies". All fields are combined with a logical 'AND'. */
export type vendor_supported_currencies_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_supported_currencies_bool_exp>>;
  readonly _not?: InputMaybe<vendor_supported_currencies_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_supported_currencies_bool_exp>>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
};

/** Ordering options when selecting data from "vendor_supported_currencies". */
export type vendor_supported_currencies_order_by = {
  readonly currency?: InputMaybe<order_by>;
};

/** select columns of table "vendor_supported_currencies" */
export enum vendor_supported_currencies_select_column {
  /** column name */
  currency = 'currency'
}

/**
 * Information on vendor trades
 *
 *
 * columns and relationships of "vendor_trades"
 */
export type vendor_trades = {
  readonly __typename?: 'vendor_trades';
  readonly last_trade_at?: Maybe<Scalars['timestamptz']>;
  readonly trades_count?: Maybe<Scalars['bigint']>;
};

/** Boolean expression to filter rows from the table "vendor_trades". All fields are combined with a logical 'AND'. */
export type vendor_trades_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_trades_bool_exp>>;
  readonly _not?: InputMaybe<vendor_trades_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_trades_bool_exp>>;
  readonly last_trade_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly trades_count?: InputMaybe<bigint_comparison_exp>;
};

/** Ordering options when selecting data from "vendor_trades". */
export type vendor_trades_order_by = {
  readonly last_trade_at?: InputMaybe<order_by>;
  readonly trades_count?: InputMaybe<order_by>;
};

/** select columns of table "vendor_trades" */
export enum vendor_trades_select_column {
  /** column name */
  last_trade_at = 'last_trade_at',
  /** column name */
  trades_count = 'trades_count'
}

/** columns and relationships of "vendor_treasury_accounts" */
export type vendor_treasury_accounts = {
  readonly __typename?: 'vendor_treasury_accounts';
  readonly annual_yield_bps: Scalars['bigint'];
  readonly currency: Scalars['currency'];
  readonly deposit_limit: Scalars['bigint'];
  readonly public_id: Scalars['String'];
  /** An object relationship */
  readonly vendor_treasury_balance?: Maybe<vendor_treasury_balances>;
  /** An array relationship */
  readonly vendor_treasury_daily_cumulative_balances: ReadonlyArray<vendor_treasury_daily_cumulative_balances>;
  /** An array relationship */
  readonly vendor_treasury_transactions: ReadonlyArray<vendor_treasury_transactions>;
};


/** columns and relationships of "vendor_treasury_accounts" */
export type vendor_treasury_accountsvendor_treasury_daily_cumulative_balancesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_treasury_daily_cumulative_balances_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_treasury_daily_cumulative_balances_order_by>>;
  where?: InputMaybe<vendor_treasury_daily_cumulative_balances_bool_exp>;
};


/** columns and relationships of "vendor_treasury_accounts" */
export type vendor_treasury_accountsvendor_treasury_transactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_treasury_transactions_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_treasury_transactions_order_by>>;
  where?: InputMaybe<vendor_treasury_transactions_bool_exp>;
};

/** order by aggregate values of table "vendor_treasury_accounts" */
export type vendor_treasury_accounts_aggregate_order_by = {
  readonly avg?: InputMaybe<vendor_treasury_accounts_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<vendor_treasury_accounts_max_order_by>;
  readonly min?: InputMaybe<vendor_treasury_accounts_min_order_by>;
  readonly stddev?: InputMaybe<vendor_treasury_accounts_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<vendor_treasury_accounts_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<vendor_treasury_accounts_stddev_samp_order_by>;
  readonly sum?: InputMaybe<vendor_treasury_accounts_sum_order_by>;
  readonly var_pop?: InputMaybe<vendor_treasury_accounts_var_pop_order_by>;
  readonly var_samp?: InputMaybe<vendor_treasury_accounts_var_samp_order_by>;
  readonly variance?: InputMaybe<vendor_treasury_accounts_variance_order_by>;
};

/** order by avg() on columns of table "vendor_treasury_accounts" */
export type vendor_treasury_accounts_avg_order_by = {
  readonly annual_yield_bps?: InputMaybe<order_by>;
  readonly deposit_limit?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "vendor_treasury_accounts". All fields are combined with a logical 'AND'. */
export type vendor_treasury_accounts_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_treasury_accounts_bool_exp>>;
  readonly _not?: InputMaybe<vendor_treasury_accounts_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_treasury_accounts_bool_exp>>;
  readonly annual_yield_bps?: InputMaybe<bigint_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly deposit_limit?: InputMaybe<bigint_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly vendor_treasury_balance?: InputMaybe<vendor_treasury_balances_bool_exp>;
  readonly vendor_treasury_daily_cumulative_balances?: InputMaybe<vendor_treasury_daily_cumulative_balances_bool_exp>;
  readonly vendor_treasury_transactions?: InputMaybe<vendor_treasury_transactions_bool_exp>;
};

/** order by max() on columns of table "vendor_treasury_accounts" */
export type vendor_treasury_accounts_max_order_by = {
  readonly annual_yield_bps?: InputMaybe<order_by>;
  readonly deposit_limit?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** order by min() on columns of table "vendor_treasury_accounts" */
export type vendor_treasury_accounts_min_order_by = {
  readonly annual_yield_bps?: InputMaybe<order_by>;
  readonly deposit_limit?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "vendor_treasury_accounts". */
export type vendor_treasury_accounts_order_by = {
  readonly annual_yield_bps?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly deposit_limit?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly vendor_treasury_balance?: InputMaybe<vendor_treasury_balances_order_by>;
  readonly vendor_treasury_daily_cumulative_balances_aggregate?: InputMaybe<vendor_treasury_daily_cumulative_balances_aggregate_order_by>;
  readonly vendor_treasury_transactions_aggregate?: InputMaybe<vendor_treasury_transactions_aggregate_order_by>;
};

/** select columns of table "vendor_treasury_accounts" */
export enum vendor_treasury_accounts_select_column {
  /** column name */
  annual_yield_bps = 'annual_yield_bps',
  /** column name */
  currency = 'currency',
  /** column name */
  deposit_limit = 'deposit_limit',
  /** column name */
  public_id = 'public_id'
}

/** order by stddev() on columns of table "vendor_treasury_accounts" */
export type vendor_treasury_accounts_stddev_order_by = {
  readonly annual_yield_bps?: InputMaybe<order_by>;
  readonly deposit_limit?: InputMaybe<order_by>;
};

/** order by stddev_pop() on columns of table "vendor_treasury_accounts" */
export type vendor_treasury_accounts_stddev_pop_order_by = {
  readonly annual_yield_bps?: InputMaybe<order_by>;
  readonly deposit_limit?: InputMaybe<order_by>;
};

/** order by stddev_samp() on columns of table "vendor_treasury_accounts" */
export type vendor_treasury_accounts_stddev_samp_order_by = {
  readonly annual_yield_bps?: InputMaybe<order_by>;
  readonly deposit_limit?: InputMaybe<order_by>;
};

/** order by sum() on columns of table "vendor_treasury_accounts" */
export type vendor_treasury_accounts_sum_order_by = {
  readonly annual_yield_bps?: InputMaybe<order_by>;
  readonly deposit_limit?: InputMaybe<order_by>;
};

/** order by var_pop() on columns of table "vendor_treasury_accounts" */
export type vendor_treasury_accounts_var_pop_order_by = {
  readonly annual_yield_bps?: InputMaybe<order_by>;
  readonly deposit_limit?: InputMaybe<order_by>;
};

/** order by var_samp() on columns of table "vendor_treasury_accounts" */
export type vendor_treasury_accounts_var_samp_order_by = {
  readonly annual_yield_bps?: InputMaybe<order_by>;
  readonly deposit_limit?: InputMaybe<order_by>;
};

/** order by variance() on columns of table "vendor_treasury_accounts" */
export type vendor_treasury_accounts_variance_order_by = {
  readonly annual_yield_bps?: InputMaybe<order_by>;
  readonly deposit_limit?: InputMaybe<order_by>;
};

/** columns and relationships of "vendor_treasury_balances" */
export type vendor_treasury_balances = {
  readonly __typename?: 'vendor_treasury_balances';
  readonly current_balance?: Maybe<Scalars['bigint']>;
  readonly day_elapsed_percent?: Maybe<Scalars['float8']>;
  readonly deposit_limit?: Maybe<Scalars['bigint']>;
  readonly end_of_day_yield_payment?: Maybe<Scalars['bigint']>;
  readonly posted_yield?: Maybe<Scalars['bigint']>;
  readonly roi_bps?: Maybe<Scalars['bigint']>;
  readonly withdrawable_balance?: Maybe<Scalars['bigint']>;
};

/** Boolean expression to filter rows from the table "vendor_treasury_balances". All fields are combined with a logical 'AND'. */
export type vendor_treasury_balances_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_treasury_balances_bool_exp>>;
  readonly _not?: InputMaybe<vendor_treasury_balances_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_treasury_balances_bool_exp>>;
  readonly current_balance?: InputMaybe<bigint_comparison_exp>;
  readonly day_elapsed_percent?: InputMaybe<float8_comparison_exp>;
  readonly deposit_limit?: InputMaybe<bigint_comparison_exp>;
  readonly end_of_day_yield_payment?: InputMaybe<bigint_comparison_exp>;
  readonly posted_yield?: InputMaybe<bigint_comparison_exp>;
  readonly roi_bps?: InputMaybe<bigint_comparison_exp>;
  readonly withdrawable_balance?: InputMaybe<bigint_comparison_exp>;
};

/** Ordering options when selecting data from "vendor_treasury_balances". */
export type vendor_treasury_balances_order_by = {
  readonly current_balance?: InputMaybe<order_by>;
  readonly day_elapsed_percent?: InputMaybe<order_by>;
  readonly deposit_limit?: InputMaybe<order_by>;
  readonly end_of_day_yield_payment?: InputMaybe<order_by>;
  readonly posted_yield?: InputMaybe<order_by>;
  readonly roi_bps?: InputMaybe<order_by>;
  readonly withdrawable_balance?: InputMaybe<order_by>;
};

/** select columns of table "vendor_treasury_balances" */
export enum vendor_treasury_balances_select_column {
  /** column name */
  current_balance = 'current_balance',
  /** column name */
  day_elapsed_percent = 'day_elapsed_percent',
  /** column name */
  deposit_limit = 'deposit_limit',
  /** column name */
  end_of_day_yield_payment = 'end_of_day_yield_payment',
  /** column name */
  posted_yield = 'posted_yield',
  /** column name */
  roi_bps = 'roi_bps',
  /** column name */
  withdrawable_balance = 'withdrawable_balance'
}

/** columns and relationships of "vendor_treasury_daily_cumulative_balances" */
export type vendor_treasury_daily_cumulative_balances = {
  readonly __typename?: 'vendor_treasury_daily_cumulative_balances';
  readonly cumulative_balance?: Maybe<Scalars['bigint']>;
  readonly cumulative_yield?: Maybe<Scalars['bigint']>;
  readonly date?: Maybe<Scalars['timestamptz']>;
  readonly yield?: Maybe<Scalars['bigint']>;
};

/** order by aggregate values of table "vendor_treasury_daily_cumulative_balances" */
export type vendor_treasury_daily_cumulative_balances_aggregate_order_by = {
  readonly avg?: InputMaybe<vendor_treasury_daily_cumulative_balances_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<vendor_treasury_daily_cumulative_balances_max_order_by>;
  readonly min?: InputMaybe<vendor_treasury_daily_cumulative_balances_min_order_by>;
  readonly stddev?: InputMaybe<vendor_treasury_daily_cumulative_balances_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<vendor_treasury_daily_cumulative_balances_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<vendor_treasury_daily_cumulative_balances_stddev_samp_order_by>;
  readonly sum?: InputMaybe<vendor_treasury_daily_cumulative_balances_sum_order_by>;
  readonly var_pop?: InputMaybe<vendor_treasury_daily_cumulative_balances_var_pop_order_by>;
  readonly var_samp?: InputMaybe<vendor_treasury_daily_cumulative_balances_var_samp_order_by>;
  readonly variance?: InputMaybe<vendor_treasury_daily_cumulative_balances_variance_order_by>;
};

/** order by avg() on columns of table "vendor_treasury_daily_cumulative_balances" */
export type vendor_treasury_daily_cumulative_balances_avg_order_by = {
  readonly cumulative_balance?: InputMaybe<order_by>;
  readonly cumulative_yield?: InputMaybe<order_by>;
  readonly yield?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "vendor_treasury_daily_cumulative_balances". All fields are combined with a logical 'AND'. */
export type vendor_treasury_daily_cumulative_balances_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_treasury_daily_cumulative_balances_bool_exp>>;
  readonly _not?: InputMaybe<vendor_treasury_daily_cumulative_balances_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_treasury_daily_cumulative_balances_bool_exp>>;
  readonly cumulative_balance?: InputMaybe<bigint_comparison_exp>;
  readonly cumulative_yield?: InputMaybe<bigint_comparison_exp>;
  readonly date?: InputMaybe<timestamptz_comparison_exp>;
  readonly yield?: InputMaybe<bigint_comparison_exp>;
};

/** order by max() on columns of table "vendor_treasury_daily_cumulative_balances" */
export type vendor_treasury_daily_cumulative_balances_max_order_by = {
  readonly cumulative_balance?: InputMaybe<order_by>;
  readonly cumulative_yield?: InputMaybe<order_by>;
  readonly date?: InputMaybe<order_by>;
  readonly yield?: InputMaybe<order_by>;
};

/** order by min() on columns of table "vendor_treasury_daily_cumulative_balances" */
export type vendor_treasury_daily_cumulative_balances_min_order_by = {
  readonly cumulative_balance?: InputMaybe<order_by>;
  readonly cumulative_yield?: InputMaybe<order_by>;
  readonly date?: InputMaybe<order_by>;
  readonly yield?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "vendor_treasury_daily_cumulative_balances". */
export type vendor_treasury_daily_cumulative_balances_order_by = {
  readonly cumulative_balance?: InputMaybe<order_by>;
  readonly cumulative_yield?: InputMaybe<order_by>;
  readonly date?: InputMaybe<order_by>;
  readonly yield?: InputMaybe<order_by>;
};

/** select columns of table "vendor_treasury_daily_cumulative_balances" */
export enum vendor_treasury_daily_cumulative_balances_select_column {
  /** column name */
  cumulative_balance = 'cumulative_balance',
  /** column name */
  cumulative_yield = 'cumulative_yield',
  /** column name */
  date = 'date',
  /** column name */
  yield = 'yield'
}

/** order by stddev() on columns of table "vendor_treasury_daily_cumulative_balances" */
export type vendor_treasury_daily_cumulative_balances_stddev_order_by = {
  readonly cumulative_balance?: InputMaybe<order_by>;
  readonly cumulative_yield?: InputMaybe<order_by>;
  readonly yield?: InputMaybe<order_by>;
};

/** order by stddev_pop() on columns of table "vendor_treasury_daily_cumulative_balances" */
export type vendor_treasury_daily_cumulative_balances_stddev_pop_order_by = {
  readonly cumulative_balance?: InputMaybe<order_by>;
  readonly cumulative_yield?: InputMaybe<order_by>;
  readonly yield?: InputMaybe<order_by>;
};

/** order by stddev_samp() on columns of table "vendor_treasury_daily_cumulative_balances" */
export type vendor_treasury_daily_cumulative_balances_stddev_samp_order_by = {
  readonly cumulative_balance?: InputMaybe<order_by>;
  readonly cumulative_yield?: InputMaybe<order_by>;
  readonly yield?: InputMaybe<order_by>;
};

/** order by sum() on columns of table "vendor_treasury_daily_cumulative_balances" */
export type vendor_treasury_daily_cumulative_balances_sum_order_by = {
  readonly cumulative_balance?: InputMaybe<order_by>;
  readonly cumulative_yield?: InputMaybe<order_by>;
  readonly yield?: InputMaybe<order_by>;
};

/** order by var_pop() on columns of table "vendor_treasury_daily_cumulative_balances" */
export type vendor_treasury_daily_cumulative_balances_var_pop_order_by = {
  readonly cumulative_balance?: InputMaybe<order_by>;
  readonly cumulative_yield?: InputMaybe<order_by>;
  readonly yield?: InputMaybe<order_by>;
};

/** order by var_samp() on columns of table "vendor_treasury_daily_cumulative_balances" */
export type vendor_treasury_daily_cumulative_balances_var_samp_order_by = {
  readonly cumulative_balance?: InputMaybe<order_by>;
  readonly cumulative_yield?: InputMaybe<order_by>;
  readonly yield?: InputMaybe<order_by>;
};

/** order by variance() on columns of table "vendor_treasury_daily_cumulative_balances" */
export type vendor_treasury_daily_cumulative_balances_variance_order_by = {
  readonly cumulative_balance?: InputMaybe<order_by>;
  readonly cumulative_yield?: InputMaybe<order_by>;
  readonly yield?: InputMaybe<order_by>;
};

export enum vendor_treasury_transaction_payment_medium_enum_enum {
  modern_treasury = 'modern_treasury',
  virtual = 'virtual'
}

/** Boolean expression to compare columns of type "vendor_treasury_transaction_payment_medium_enum_enum". All fields are combined with logical 'AND'. */
export type vendor_treasury_transaction_payment_medium_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<vendor_treasury_transaction_payment_medium_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<vendor_treasury_transaction_payment_medium_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<vendor_treasury_transaction_payment_medium_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<vendor_treasury_transaction_payment_medium_enum_enum>>;
};

export enum vendor_treasury_transaction_type_enum_enum {
  deposit = 'deposit',
  withdrawal = 'withdrawal',
  yield = 'yield'
}

/** Boolean expression to compare columns of type "vendor_treasury_transaction_type_enum_enum". All fields are combined with logical 'AND'. */
export type vendor_treasury_transaction_type_enum_enum_comparison_exp = {
  readonly _eq?: InputMaybe<vendor_treasury_transaction_type_enum_enum>;
  readonly _in?: InputMaybe<ReadonlyArray<vendor_treasury_transaction_type_enum_enum>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']>;
  readonly _neq?: InputMaybe<vendor_treasury_transaction_type_enum_enum>;
  readonly _nin?: InputMaybe<ReadonlyArray<vendor_treasury_transaction_type_enum_enum>>;
};

/** columns and relationships of "vendor_treasury_transactions" */
export type vendor_treasury_transactions = {
  readonly __typename?: 'vendor_treasury_transactions';
  readonly account_institution_logo?: Maybe<Scalars['String']>;
  readonly account_institution_name?: Maybe<Scalars['String']>;
  readonly account_mask?: Maybe<Scalars['String']>;
  readonly account_name?: Maybe<Scalars['String']>;
  readonly amount: Scalars['bigint'];
  readonly currency: Scalars['currency'];
  readonly initiated_at?: Maybe<Scalars['timestamptz']>;
  readonly payment_medium?: Maybe<vendor_treasury_transaction_payment_medium_enum_enum>;
  readonly posted_at?: Maybe<Scalars['timestamptz']>;
  readonly public_id: Scalars['String'];
  readonly status: payment_status_enum_enum;
  readonly transaction_type: vendor_treasury_transaction_type_enum_enum;
};

/** order by aggregate values of table "vendor_treasury_transactions" */
export type vendor_treasury_transactions_aggregate_order_by = {
  readonly avg?: InputMaybe<vendor_treasury_transactions_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<vendor_treasury_transactions_max_order_by>;
  readonly min?: InputMaybe<vendor_treasury_transactions_min_order_by>;
  readonly stddev?: InputMaybe<vendor_treasury_transactions_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<vendor_treasury_transactions_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<vendor_treasury_transactions_stddev_samp_order_by>;
  readonly sum?: InputMaybe<vendor_treasury_transactions_sum_order_by>;
  readonly var_pop?: InputMaybe<vendor_treasury_transactions_var_pop_order_by>;
  readonly var_samp?: InputMaybe<vendor_treasury_transactions_var_samp_order_by>;
  readonly variance?: InputMaybe<vendor_treasury_transactions_variance_order_by>;
};

/** order by avg() on columns of table "vendor_treasury_transactions" */
export type vendor_treasury_transactions_avg_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "vendor_treasury_transactions". All fields are combined with a logical 'AND'. */
export type vendor_treasury_transactions_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_treasury_transactions_bool_exp>>;
  readonly _not?: InputMaybe<vendor_treasury_transactions_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_treasury_transactions_bool_exp>>;
  readonly account_institution_logo?: InputMaybe<String_comparison_exp>;
  readonly account_institution_name?: InputMaybe<String_comparison_exp>;
  readonly account_mask?: InputMaybe<String_comparison_exp>;
  readonly account_name?: InputMaybe<String_comparison_exp>;
  readonly amount?: InputMaybe<bigint_comparison_exp>;
  readonly currency?: InputMaybe<currency_comparison_exp>;
  readonly initiated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly payment_medium?: InputMaybe<vendor_treasury_transaction_payment_medium_enum_enum_comparison_exp>;
  readonly posted_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly status?: InputMaybe<payment_status_enum_enum_comparison_exp>;
  readonly transaction_type?: InputMaybe<vendor_treasury_transaction_type_enum_enum_comparison_exp>;
};

/** order by max() on columns of table "vendor_treasury_transactions" */
export type vendor_treasury_transactions_max_order_by = {
  readonly account_institution_logo?: InputMaybe<order_by>;
  readonly account_institution_name?: InputMaybe<order_by>;
  readonly account_mask?: InputMaybe<order_by>;
  readonly account_name?: InputMaybe<order_by>;
  readonly amount?: InputMaybe<order_by>;
  readonly initiated_at?: InputMaybe<order_by>;
  readonly posted_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** order by min() on columns of table "vendor_treasury_transactions" */
export type vendor_treasury_transactions_min_order_by = {
  readonly account_institution_logo?: InputMaybe<order_by>;
  readonly account_institution_name?: InputMaybe<order_by>;
  readonly account_mask?: InputMaybe<order_by>;
  readonly account_name?: InputMaybe<order_by>;
  readonly amount?: InputMaybe<order_by>;
  readonly initiated_at?: InputMaybe<order_by>;
  readonly posted_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "vendor_treasury_transactions". */
export type vendor_treasury_transactions_order_by = {
  readonly account_institution_logo?: InputMaybe<order_by>;
  readonly account_institution_name?: InputMaybe<order_by>;
  readonly account_mask?: InputMaybe<order_by>;
  readonly account_name?: InputMaybe<order_by>;
  readonly amount?: InputMaybe<order_by>;
  readonly currency?: InputMaybe<order_by>;
  readonly initiated_at?: InputMaybe<order_by>;
  readonly payment_medium?: InputMaybe<order_by>;
  readonly posted_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly status?: InputMaybe<order_by>;
  readonly transaction_type?: InputMaybe<order_by>;
};

/** select columns of table "vendor_treasury_transactions" */
export enum vendor_treasury_transactions_select_column {
  /** column name */
  account_institution_logo = 'account_institution_logo',
  /** column name */
  account_institution_name = 'account_institution_name',
  /** column name */
  account_mask = 'account_mask',
  /** column name */
  account_name = 'account_name',
  /** column name */
  amount = 'amount',
  /** column name */
  currency = 'currency',
  /** column name */
  initiated_at = 'initiated_at',
  /** column name */
  payment_medium = 'payment_medium',
  /** column name */
  posted_at = 'posted_at',
  /** column name */
  public_id = 'public_id',
  /** column name */
  status = 'status',
  /** column name */
  transaction_type = 'transaction_type'
}

/** order by stddev() on columns of table "vendor_treasury_transactions" */
export type vendor_treasury_transactions_stddev_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by stddev_pop() on columns of table "vendor_treasury_transactions" */
export type vendor_treasury_transactions_stddev_pop_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by stddev_samp() on columns of table "vendor_treasury_transactions" */
export type vendor_treasury_transactions_stddev_samp_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by sum() on columns of table "vendor_treasury_transactions" */
export type vendor_treasury_transactions_sum_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by var_pop() on columns of table "vendor_treasury_transactions" */
export type vendor_treasury_transactions_var_pop_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by var_samp() on columns of table "vendor_treasury_transactions" */
export type vendor_treasury_transactions_var_samp_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/** order by variance() on columns of table "vendor_treasury_transactions" */
export type vendor_treasury_transactions_variance_order_by = {
  readonly amount?: InputMaybe<order_by>;
};

/**
 * Information about how often a vendor visits.
 *
 *
 * columns and relationships of "vendor_visit_summary"
 */
export type vendor_visit_summary = {
  readonly __typename?: 'vendor_visit_summary';
  readonly first_visited_at?: Maybe<Scalars['timestamptz']>;
  readonly last_visited_at?: Maybe<Scalars['timestamptz']>;
  readonly num_visits?: Maybe<Scalars['numeric']>;
};

/** Boolean expression to filter rows from the table "vendor_visit_summary". All fields are combined with a logical 'AND'. */
export type vendor_visit_summary_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendor_visit_summary_bool_exp>>;
  readonly _not?: InputMaybe<vendor_visit_summary_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendor_visit_summary_bool_exp>>;
  readonly first_visited_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly last_visited_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly num_visits?: InputMaybe<numeric_comparison_exp>;
};

/** Ordering options when selecting data from "vendor_visit_summary". */
export type vendor_visit_summary_order_by = {
  readonly first_visited_at?: InputMaybe<order_by>;
  readonly last_visited_at?: InputMaybe<order_by>;
  readonly num_visits?: InputMaybe<order_by>;
};

/** select columns of table "vendor_visit_summary" */
export enum vendor_visit_summary_select_column {
  /** column name */
  first_visited_at = 'first_visited_at',
  /** column name */
  last_visited_at = 'last_visited_at',
  /** column name */
  num_visits = 'num_visits'
}

/** columns and relationships of "vendors" */
export type vendors = {
  readonly __typename?: 'vendors';
  /** An array relationship */
  readonly accounts: ReadonlyArray<accounts>;
  /** An array relationship */
  readonly activity_logs: ReadonlyArray<activity_log>;
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly automated_payments_enabled: Scalars['Boolean'];
  readonly can_trade: Scalars['Boolean'];
  readonly city?: Maybe<Scalars['String']>;
  readonly clearbit_last_updated_at?: Maybe<Scalars['timestamptz']>;
  readonly clearbit_raw?: Maybe<Scalars['jsonb']>;
  readonly client_id: Scalars['String'];
  readonly corporate_entity?: Maybe<Scalars['String']>;
  readonly country?: Maybe<country_code_enum_enum>;
  readonly created_at: Scalars['timestamptz'];
  readonly credit_limit: Scalars['bigint'];
  /** When the credit limit is no longer valid */
  readonly credit_limit_expires_at?: Maybe<Scalars['timestamptz']>;
  /** Additional information about the credit limit */
  readonly credit_limit_notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly csv_tasks_for_vendor?: Maybe<csv_tasks_for_vendors>;
  /** Key/value mapping of custom fields. Set by admin only */
  readonly custom_fields?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  readonly customers: ReadonlyArray<customers>;
  /** An aggregate relationship */
  readonly customers_aggregate: customers_aggregate;
  /** An array relationship */
  readonly data_sources: ReadonlyArray<data_sources>;
  readonly ein?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly events_summary?: Maybe<vendor_events_summary>;
  readonly has_uploaded_accounting_csv: Scalars['Boolean'];
  readonly has_uploaded_billing_manager_csv: Scalars['Boolean'];
  /** An array relationship */
  readonly investor_asset_receipts: ReadonlyArray<investor_asset_receipts>;
  /** An aggregate relationship */
  readonly investor_asset_receipts_aggregate: investor_asset_receipts_aggregate;
  readonly is_activated: Scalars['Boolean'];
  readonly is_additional_accounting_link_required: Scalars['Boolean'];
  readonly is_additional_bank_link_required: Scalars['Boolean'];
  readonly is_additional_billing_manager_required: Scalars['Boolean'];
  readonly is_declined: Scalars['Boolean'];
  readonly is_on_hold: Scalars['Boolean'];
  readonly last_rated_at?: Maybe<Scalars['timestamptz']>;
  readonly lead_status: vendor_lead_status_enum_enum;
  /** An array relationship */
  readonly legal_entities: ReadonlyArray<legal_entities>;
  /** An aggregate relationship */
  readonly legal_entities_aggregate: legal_entities_aggregate;
  /** An array relationship */
  readonly lists: ReadonlyArray<lists>;
  /** An aggregate relationship */
  readonly lists_aggregate: lists_aggregate;
  readonly live_ratings_application_enabled: Scalars['Boolean'];
  readonly live_ratings_generation_disabled: Scalars['Boolean'];
  readonly logo_url?: Maybe<Scalars['String']>;
  readonly main_contact_email?: Maybe<Scalars['String']>;
  readonly main_contact_first_name?: Maybe<Scalars['String']>;
  readonly main_contact_last_name?: Maybe<Scalars['String']>;
  readonly main_contact_phone?: Maybe<Scalars['String']>;
  readonly main_contact_title?: Maybe<Scalars['String']>;
  /** An array relationship */
  readonly manual_data_sources: ReadonlyArray<manual_data_sources>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months: Scalars['Int'];
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: Maybe<Scalars['bigint']>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: Maybe<Scalars['numeric']>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: Maybe<Scalars['numeric']>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: Maybe<Scalars['Int']>;
  readonly modern_treasury_counterparty_id?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly onboarding_triggered_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  readonly payment_method?: Maybe<accounts>;
  /** An object relationship */
  readonly payment_status?: Maybe<vendor_payment_status>;
  /** An object relationship */
  readonly payout_method?: Maybe<accounts>;
  /** An array relationship */
  readonly pipe_agreements: ReadonlyArray<pipe_agreements>;
  /** An aggregate relationship */
  readonly pipe_agreements_aggregate: pipe_agreements_aggregate;
  /** An array relationship */
  readonly pipe_proposals: ReadonlyArray<pipe_proposals>;
  /** An aggregate relationship */
  readonly pipe_proposals_aggregate: pipe_proposals_aggregate;
  readonly pipeline_state: vendor_pipeline_state_enum_enum;
  /** An array relationship */
  readonly plaid_items: ReadonlyArray<plaid_items>;
  /** An aggregate relationship */
  readonly plaid_items_aggregate: plaid_items_aggregate;
  readonly public_id: Scalars['String'];
  readonly rate_months_1: Scalars['Int'];
  readonly rating?: Maybe<asset_rating_enum_enum>;
  readonly reported_estimated_arr?: Maybe<Scalars['String']>;
  readonly revenue_stream_inference_disabled: Scalars['Boolean'];
  /** An array relationship */
  readonly revenue_streams: ReadonlyArray<revenue_streams>;
  /** An aggregate relationship */
  readonly revenue_streams_aggregate: revenue_streams_aggregate;
  readonly sales_funnel_stage?: Maybe<vendor_sales_funnel_stage_enum_enum>;
  readonly score?: Maybe<Scalars['float8']>;
  readonly signup_source?: Maybe<Scalars['String']>;
  /** An array relationship */
  readonly snapshots: ReadonlyArray<snapshots>;
  readonly state?: Maybe<Scalars['String']>;
  readonly state_incorporated?: Maybe<Scalars['String']>;
  readonly updated_at: Scalars['timestamptz'];
  /** An array relationship */
  readonly uploaded_vendor_data: ReadonlyArray<uploaded_vendor_data>;
  /** An aggregate relationship */
  readonly uploaded_vendor_data_aggregate: uploaded_vendor_data_aggregate;
  /** An array relationship */
  readonly users: ReadonlyArray<users>;
  /** An aggregate relationship */
  readonly users_aggregate: users_aggregate;
  /** An array relationship */
  readonly vendor_active_ratings: ReadonlyArray<vendor_active_ratings>;
  /** An array relationship */
  readonly vendor_bank_balances: ReadonlyArray<vendor_bank_balances>;
  /** An object relationship */
  readonly vendor_current_sales_funnel_stage?: Maybe<vendor_current_sales_funnel_stage>;
  /** An array relationship */
  readonly vendor_custom_fields: ReadonlyArray<vendor_custom_fields>;
  /** An array relationship */
  readonly vendor_metric_jobs: ReadonlyArray<vendor_metric_jobs>;
  /** An array relationship */
  readonly vendor_metrics: ReadonlyArray<vendor_metrics>;
  /** An object relationship */
  readonly vendor_metrics_latest?: Maybe<vendor_metrics_latest>;
  /** An array relationship */
  readonly vendor_payments: ReadonlyArray<vendor_payments>;
  /** An aggregate relationship */
  readonly vendor_payments_aggregate: vendor_payments_aggregate;
  /** An array relationship */
  readonly vendor_ratings_metadata: ReadonlyArray<vendor_ratings_metadata>;
  /** An array relationship */
  readonly vendor_supported_currencies: ReadonlyArray<vendor_supported_currencies>;
  /** An object relationship */
  readonly vendor_trades?: Maybe<vendor_trades>;
  /** An array relationship */
  readonly vendor_treasury_accounts: ReadonlyArray<vendor_treasury_accounts>;
  /** An object relationship */
  readonly vendor_visit_summary?: Maybe<vendor_visit_summary>;
  readonly website?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vendors" */
export type vendorsaccountsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<accounts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<accounts_order_by>>;
  where?: InputMaybe<accounts_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsactivity_logsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<activity_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<activity_log_order_by>>;
  where?: InputMaybe<activity_log_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsclearbit_rawArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "vendors" */
export type vendorscustom_fieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "vendors" */
export type vendorscustomersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<customers_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<customers_order_by>>;
  where?: InputMaybe<customers_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorscustomers_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<customers_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<customers_order_by>>;
  where?: InputMaybe<customers_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsdata_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<data_sources_order_by>>;
  where?: InputMaybe<data_sources_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsinvestor_asset_receiptsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<investor_asset_receipts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<investor_asset_receipts_order_by>>;
  where?: InputMaybe<investor_asset_receipts_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsinvestor_asset_receipts_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<investor_asset_receipts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<investor_asset_receipts_order_by>>;
  where?: InputMaybe<investor_asset_receipts_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorslegal_entitiesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<legal_entities_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<legal_entities_order_by>>;
  where?: InputMaybe<legal_entities_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorslegal_entities_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<legal_entities_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<legal_entities_order_by>>;
  where?: InputMaybe<legal_entities_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorslistsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_order_by>>;
  where?: InputMaybe<lists_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorslists_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<lists_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<lists_order_by>>;
  where?: InputMaybe<lists_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsmanual_data_sourcesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<manual_data_sources_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<manual_data_sources_order_by>>;
  where?: InputMaybe<manual_data_sources_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorspipe_agreementsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_agreements_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_agreements_order_by>>;
  where?: InputMaybe<pipe_agreements_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorspipe_agreements_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_agreements_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_agreements_order_by>>;
  where?: InputMaybe<pipe_agreements_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorspipe_proposalsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_proposals_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_proposals_order_by>>;
  where?: InputMaybe<pipe_proposals_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorspipe_proposals_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<pipe_proposals_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<pipe_proposals_order_by>>;
  where?: InputMaybe<pipe_proposals_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsplaid_itemsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<plaid_items_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<plaid_items_order_by>>;
  where?: InputMaybe<plaid_items_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsplaid_items_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<plaid_items_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<plaid_items_order_by>>;
  where?: InputMaybe<plaid_items_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsrevenue_streamsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<revenue_streams_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<revenue_streams_order_by>>;
  where?: InputMaybe<revenue_streams_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsrevenue_streams_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<revenue_streams_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<revenue_streams_order_by>>;
  where?: InputMaybe<revenue_streams_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorssnapshotsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<snapshots_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<snapshots_order_by>>;
  where?: InputMaybe<snapshots_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsuploaded_vendor_dataArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<uploaded_vendor_data_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<uploaded_vendor_data_order_by>>;
  where?: InputMaybe<uploaded_vendor_data_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsuploaded_vendor_data_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<uploaded_vendor_data_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<uploaded_vendor_data_order_by>>;
  where?: InputMaybe<uploaded_vendor_data_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsusersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<users_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<users_order_by>>;
  where?: InputMaybe<users_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsusers_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<users_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<users_order_by>>;
  where?: InputMaybe<users_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsvendor_active_ratingsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_active_ratings_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_active_ratings_order_by>>;
  where?: InputMaybe<vendor_active_ratings_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsvendor_bank_balancesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_bank_balances_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_bank_balances_order_by>>;
  where?: InputMaybe<vendor_bank_balances_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsvendor_custom_fieldsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_custom_fields_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_custom_fields_order_by>>;
  where?: InputMaybe<vendor_custom_fields_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsvendor_metric_jobsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_metric_jobs_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_metric_jobs_order_by>>;
  where?: InputMaybe<vendor_metric_jobs_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsvendor_metricsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_metrics_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_metrics_order_by>>;
  where?: InputMaybe<vendor_metrics_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsvendor_paymentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_payments_order_by>>;
  where?: InputMaybe<vendor_payments_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsvendor_payments_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_payments_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_payments_order_by>>;
  where?: InputMaybe<vendor_payments_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsvendor_ratings_metadataArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_ratings_metadata_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_ratings_metadata_order_by>>;
  where?: InputMaybe<vendor_ratings_metadata_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsvendor_supported_currenciesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_supported_currencies_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_supported_currencies_order_by>>;
  where?: InputMaybe<vendor_supported_currencies_bool_exp>;
};


/** columns and relationships of "vendors" */
export type vendorsvendor_treasury_accountsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<vendor_treasury_accounts_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<vendor_treasury_accounts_order_by>>;
  where?: InputMaybe<vendor_treasury_accounts_bool_exp>;
};

/** aggregated selection of "vendors" */
export type vendors_aggregate = {
  readonly __typename?: 'vendors_aggregate';
  readonly aggregate?: Maybe<vendors_aggregate_fields>;
  readonly nodes: ReadonlyArray<vendors>;
};

/** aggregate fields of "vendors" */
export type vendors_aggregate_fields = {
  readonly __typename?: 'vendors_aggregate_fields';
  readonly avg?: Maybe<vendors_avg_fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<vendors_max_fields>;
  readonly min?: Maybe<vendors_min_fields>;
  readonly stddev?: Maybe<vendors_stddev_fields>;
  readonly stddev_pop?: Maybe<vendors_stddev_pop_fields>;
  readonly stddev_samp?: Maybe<vendors_stddev_samp_fields>;
  readonly sum?: Maybe<vendors_sum_fields>;
  readonly var_pop?: Maybe<vendors_var_pop_fields>;
  readonly var_samp?: Maybe<vendors_var_samp_fields>;
  readonly variance?: Maybe<vendors_variance_fields>;
};


/** aggregate fields of "vendors" */
export type vendors_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<vendors_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vendors" */
export type vendors_aggregate_order_by = {
  readonly avg?: InputMaybe<vendors_avg_order_by>;
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<vendors_max_order_by>;
  readonly min?: InputMaybe<vendors_min_order_by>;
  readonly stddev?: InputMaybe<vendors_stddev_order_by>;
  readonly stddev_pop?: InputMaybe<vendors_stddev_pop_order_by>;
  readonly stddev_samp?: InputMaybe<vendors_stddev_samp_order_by>;
  readonly sum?: InputMaybe<vendors_sum_order_by>;
  readonly var_pop?: InputMaybe<vendors_var_pop_order_by>;
  readonly var_samp?: InputMaybe<vendors_var_samp_order_by>;
  readonly variance?: InputMaybe<vendors_variance_order_by>;
};

/** aggregate avg on columns */
export type vendors_avg_fields = {
  readonly __typename?: 'vendors_avg_fields';
  readonly credit_limit?: Maybe<Scalars['Float']>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: Maybe<Scalars['Float']>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: Maybe<Scalars['Float']>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: Maybe<Scalars['Float']>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: Maybe<Scalars['Float']>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: Maybe<Scalars['Float']>;
  readonly rate_months_1?: Maybe<Scalars['Float']>;
  readonly score?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vendors" */
export type vendors_avg_order_by = {
  readonly credit_limit?: InputMaybe<order_by>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: InputMaybe<order_by>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: InputMaybe<order_by>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: InputMaybe<order_by>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: InputMaybe<order_by>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: InputMaybe<order_by>;
  readonly rate_months_1?: InputMaybe<order_by>;
  readonly score?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "vendors". All fields are combined with a logical 'AND'. */
export type vendors_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<vendors_bool_exp>>;
  readonly _not?: InputMaybe<vendors_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<vendors_bool_exp>>;
  readonly accounts?: InputMaybe<accounts_bool_exp>;
  readonly activity_logs?: InputMaybe<activity_log_bool_exp>;
  readonly address1?: InputMaybe<String_comparison_exp>;
  readonly address2?: InputMaybe<String_comparison_exp>;
  readonly automated_payments_enabled?: InputMaybe<Boolean_comparison_exp>;
  readonly can_trade?: InputMaybe<Boolean_comparison_exp>;
  readonly city?: InputMaybe<String_comparison_exp>;
  readonly clearbit_last_updated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly clearbit_raw?: InputMaybe<jsonb_comparison_exp>;
  readonly client_id?: InputMaybe<String_comparison_exp>;
  readonly corporate_entity?: InputMaybe<String_comparison_exp>;
  readonly country?: InputMaybe<country_code_enum_enum_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly credit_limit?: InputMaybe<bigint_comparison_exp>;
  readonly credit_limit_expires_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly credit_limit_notes?: InputMaybe<String_comparison_exp>;
  readonly csv_tasks_for_vendor?: InputMaybe<csv_tasks_for_vendors_bool_exp>;
  readonly custom_fields?: InputMaybe<jsonb_comparison_exp>;
  readonly customers?: InputMaybe<customers_bool_exp>;
  readonly data_sources?: InputMaybe<data_sources_bool_exp>;
  readonly ein?: InputMaybe<String_comparison_exp>;
  readonly events_summary?: InputMaybe<vendor_events_summary_bool_exp>;
  readonly has_uploaded_accounting_csv?: InputMaybe<Boolean_comparison_exp>;
  readonly has_uploaded_billing_manager_csv?: InputMaybe<Boolean_comparison_exp>;
  readonly investor_asset_receipts?: InputMaybe<investor_asset_receipts_bool_exp>;
  readonly is_activated?: InputMaybe<Boolean_comparison_exp>;
  readonly is_additional_accounting_link_required?: InputMaybe<Boolean_comparison_exp>;
  readonly is_additional_bank_link_required?: InputMaybe<Boolean_comparison_exp>;
  readonly is_additional_billing_manager_required?: InputMaybe<Boolean_comparison_exp>;
  readonly is_declined?: InputMaybe<Boolean_comparison_exp>;
  readonly is_on_hold?: InputMaybe<Boolean_comparison_exp>;
  readonly last_rated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly lead_status?: InputMaybe<vendor_lead_status_enum_enum_comparison_exp>;
  readonly legal_entities?: InputMaybe<legal_entities_bool_exp>;
  readonly lists?: InputMaybe<lists_bool_exp>;
  readonly live_ratings_application_enabled?: InputMaybe<Boolean_comparison_exp>;
  readonly live_ratings_generation_disabled?: InputMaybe<Boolean_comparison_exp>;
  readonly logo_url?: InputMaybe<String_comparison_exp>;
  readonly main_contact_email?: InputMaybe<String_comparison_exp>;
  readonly main_contact_first_name?: InputMaybe<String_comparison_exp>;
  readonly main_contact_last_name?: InputMaybe<String_comparison_exp>;
  readonly main_contact_phone?: InputMaybe<String_comparison_exp>;
  readonly main_contact_title?: InputMaybe<String_comparison_exp>;
  readonly manual_data_sources?: InputMaybe<manual_data_sources_bool_exp>;
  readonly max_contract_term_months?: InputMaybe<Int_comparison_exp>;
  readonly min_bank_balance?: InputMaybe<bigint_comparison_exp>;
  readonly min_customer_retention_percent?: InputMaybe<numeric_comparison_exp>;
  readonly min_revenue_retention_percent?: InputMaybe<numeric_comparison_exp>;
  readonly min_runway_months?: InputMaybe<Int_comparison_exp>;
  readonly modern_treasury_counterparty_id?: InputMaybe<String_comparison_exp>;
  readonly name?: InputMaybe<String_comparison_exp>;
  readonly onboarding_triggered_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly payment_method?: InputMaybe<accounts_bool_exp>;
  readonly payment_status?: InputMaybe<vendor_payment_status_bool_exp>;
  readonly payout_method?: InputMaybe<accounts_bool_exp>;
  readonly pipe_agreements?: InputMaybe<pipe_agreements_bool_exp>;
  readonly pipe_proposals?: InputMaybe<pipe_proposals_bool_exp>;
  readonly pipeline_state?: InputMaybe<vendor_pipeline_state_enum_enum_comparison_exp>;
  readonly plaid_items?: InputMaybe<plaid_items_bool_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly rate_months_1?: InputMaybe<Int_comparison_exp>;
  readonly rating?: InputMaybe<asset_rating_enum_enum_comparison_exp>;
  readonly reported_estimated_arr?: InputMaybe<String_comparison_exp>;
  readonly revenue_stream_inference_disabled?: InputMaybe<Boolean_comparison_exp>;
  readonly revenue_streams?: InputMaybe<revenue_streams_bool_exp>;
  readonly sales_funnel_stage?: InputMaybe<vendor_sales_funnel_stage_enum_enum_comparison_exp>;
  readonly score?: InputMaybe<float8_comparison_exp>;
  readonly signup_source?: InputMaybe<String_comparison_exp>;
  readonly snapshots?: InputMaybe<snapshots_bool_exp>;
  readonly state?: InputMaybe<String_comparison_exp>;
  readonly state_incorporated?: InputMaybe<String_comparison_exp>;
  readonly updated_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly uploaded_vendor_data?: InputMaybe<uploaded_vendor_data_bool_exp>;
  readonly users?: InputMaybe<users_bool_exp>;
  readonly vendor_active_ratings?: InputMaybe<vendor_active_ratings_bool_exp>;
  readonly vendor_bank_balances?: InputMaybe<vendor_bank_balances_bool_exp>;
  readonly vendor_current_sales_funnel_stage?: InputMaybe<vendor_current_sales_funnel_stage_bool_exp>;
  readonly vendor_custom_fields?: InputMaybe<vendor_custom_fields_bool_exp>;
  readonly vendor_metric_jobs?: InputMaybe<vendor_metric_jobs_bool_exp>;
  readonly vendor_metrics?: InputMaybe<vendor_metrics_bool_exp>;
  readonly vendor_metrics_latest?: InputMaybe<vendor_metrics_latest_bool_exp>;
  readonly vendor_payments?: InputMaybe<vendor_payments_bool_exp>;
  readonly vendor_ratings_metadata?: InputMaybe<vendor_ratings_metadata_bool_exp>;
  readonly vendor_supported_currencies?: InputMaybe<vendor_supported_currencies_bool_exp>;
  readonly vendor_trades?: InputMaybe<vendor_trades_bool_exp>;
  readonly vendor_treasury_accounts?: InputMaybe<vendor_treasury_accounts_bool_exp>;
  readonly vendor_visit_summary?: InputMaybe<vendor_visit_summary_bool_exp>;
  readonly website?: InputMaybe<String_comparison_exp>;
  readonly zip?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type vendors_max_fields = {
  readonly __typename?: 'vendors_max_fields';
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly clearbit_last_updated_at?: Maybe<Scalars['timestamptz']>;
  readonly client_id?: Maybe<Scalars['String']>;
  readonly corporate_entity?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly credit_limit?: Maybe<Scalars['bigint']>;
  /** When the credit limit is no longer valid */
  readonly credit_limit_expires_at?: Maybe<Scalars['timestamptz']>;
  /** Additional information about the credit limit */
  readonly credit_limit_notes?: Maybe<Scalars['String']>;
  readonly ein?: Maybe<Scalars['String']>;
  readonly last_rated_at?: Maybe<Scalars['timestamptz']>;
  readonly logo_url?: Maybe<Scalars['String']>;
  readonly main_contact_email?: Maybe<Scalars['String']>;
  readonly main_contact_first_name?: Maybe<Scalars['String']>;
  readonly main_contact_last_name?: Maybe<Scalars['String']>;
  readonly main_contact_phone?: Maybe<Scalars['String']>;
  readonly main_contact_title?: Maybe<Scalars['String']>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: Maybe<Scalars['Int']>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: Maybe<Scalars['bigint']>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: Maybe<Scalars['numeric']>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: Maybe<Scalars['numeric']>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: Maybe<Scalars['Int']>;
  readonly modern_treasury_counterparty_id?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly onboarding_triggered_at?: Maybe<Scalars['timestamptz']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly rate_months_1?: Maybe<Scalars['Int']>;
  readonly reported_estimated_arr?: Maybe<Scalars['String']>;
  readonly score?: Maybe<Scalars['float8']>;
  readonly signup_source?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly state_incorporated?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly website?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "vendors" */
export type vendors_max_order_by = {
  readonly address1?: InputMaybe<order_by>;
  readonly address2?: InputMaybe<order_by>;
  readonly city?: InputMaybe<order_by>;
  readonly clearbit_last_updated_at?: InputMaybe<order_by>;
  readonly client_id?: InputMaybe<order_by>;
  readonly corporate_entity?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly credit_limit?: InputMaybe<order_by>;
  /** When the credit limit is no longer valid */
  readonly credit_limit_expires_at?: InputMaybe<order_by>;
  /** Additional information about the credit limit */
  readonly credit_limit_notes?: InputMaybe<order_by>;
  readonly ein?: InputMaybe<order_by>;
  readonly last_rated_at?: InputMaybe<order_by>;
  readonly logo_url?: InputMaybe<order_by>;
  readonly main_contact_email?: InputMaybe<order_by>;
  readonly main_contact_first_name?: InputMaybe<order_by>;
  readonly main_contact_last_name?: InputMaybe<order_by>;
  readonly main_contact_phone?: InputMaybe<order_by>;
  readonly main_contact_title?: InputMaybe<order_by>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: InputMaybe<order_by>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: InputMaybe<order_by>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: InputMaybe<order_by>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: InputMaybe<order_by>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: InputMaybe<order_by>;
  readonly modern_treasury_counterparty_id?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly onboarding_triggered_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly rate_months_1?: InputMaybe<order_by>;
  readonly reported_estimated_arr?: InputMaybe<order_by>;
  readonly score?: InputMaybe<order_by>;
  readonly signup_source?: InputMaybe<order_by>;
  readonly state?: InputMaybe<order_by>;
  readonly state_incorporated?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly website?: InputMaybe<order_by>;
  readonly zip?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type vendors_min_fields = {
  readonly __typename?: 'vendors_min_fields';
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly clearbit_last_updated_at?: Maybe<Scalars['timestamptz']>;
  readonly client_id?: Maybe<Scalars['String']>;
  readonly corporate_entity?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly credit_limit?: Maybe<Scalars['bigint']>;
  /** When the credit limit is no longer valid */
  readonly credit_limit_expires_at?: Maybe<Scalars['timestamptz']>;
  /** Additional information about the credit limit */
  readonly credit_limit_notes?: Maybe<Scalars['String']>;
  readonly ein?: Maybe<Scalars['String']>;
  readonly last_rated_at?: Maybe<Scalars['timestamptz']>;
  readonly logo_url?: Maybe<Scalars['String']>;
  readonly main_contact_email?: Maybe<Scalars['String']>;
  readonly main_contact_first_name?: Maybe<Scalars['String']>;
  readonly main_contact_last_name?: Maybe<Scalars['String']>;
  readonly main_contact_phone?: Maybe<Scalars['String']>;
  readonly main_contact_title?: Maybe<Scalars['String']>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: Maybe<Scalars['Int']>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: Maybe<Scalars['bigint']>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: Maybe<Scalars['numeric']>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: Maybe<Scalars['numeric']>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: Maybe<Scalars['Int']>;
  readonly modern_treasury_counterparty_id?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly onboarding_triggered_at?: Maybe<Scalars['timestamptz']>;
  readonly public_id?: Maybe<Scalars['String']>;
  readonly rate_months_1?: Maybe<Scalars['Int']>;
  readonly reported_estimated_arr?: Maybe<Scalars['String']>;
  readonly score?: Maybe<Scalars['float8']>;
  readonly signup_source?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly state_incorporated?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly website?: Maybe<Scalars['String']>;
  readonly zip?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "vendors" */
export type vendors_min_order_by = {
  readonly address1?: InputMaybe<order_by>;
  readonly address2?: InputMaybe<order_by>;
  readonly city?: InputMaybe<order_by>;
  readonly clearbit_last_updated_at?: InputMaybe<order_by>;
  readonly client_id?: InputMaybe<order_by>;
  readonly corporate_entity?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly credit_limit?: InputMaybe<order_by>;
  /** When the credit limit is no longer valid */
  readonly credit_limit_expires_at?: InputMaybe<order_by>;
  /** Additional information about the credit limit */
  readonly credit_limit_notes?: InputMaybe<order_by>;
  readonly ein?: InputMaybe<order_by>;
  readonly last_rated_at?: InputMaybe<order_by>;
  readonly logo_url?: InputMaybe<order_by>;
  readonly main_contact_email?: InputMaybe<order_by>;
  readonly main_contact_first_name?: InputMaybe<order_by>;
  readonly main_contact_last_name?: InputMaybe<order_by>;
  readonly main_contact_phone?: InputMaybe<order_by>;
  readonly main_contact_title?: InputMaybe<order_by>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: InputMaybe<order_by>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: InputMaybe<order_by>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: InputMaybe<order_by>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: InputMaybe<order_by>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: InputMaybe<order_by>;
  readonly modern_treasury_counterparty_id?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly onboarding_triggered_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly rate_months_1?: InputMaybe<order_by>;
  readonly reported_estimated_arr?: InputMaybe<order_by>;
  readonly score?: InputMaybe<order_by>;
  readonly signup_source?: InputMaybe<order_by>;
  readonly state?: InputMaybe<order_by>;
  readonly state_incorporated?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly website?: InputMaybe<order_by>;
  readonly zip?: InputMaybe<order_by>;
};

/** response of any mutation on the table "vendors" */
export type vendors_mutation_response = {
  readonly __typename?: 'vendors_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<vendors>;
};

/** Ordering options when selecting data from "vendors". */
export type vendors_order_by = {
  readonly accounts_aggregate?: InputMaybe<accounts_aggregate_order_by>;
  readonly activity_logs_aggregate?: InputMaybe<activity_log_aggregate_order_by>;
  readonly address1?: InputMaybe<order_by>;
  readonly address2?: InputMaybe<order_by>;
  readonly automated_payments_enabled?: InputMaybe<order_by>;
  readonly can_trade?: InputMaybe<order_by>;
  readonly city?: InputMaybe<order_by>;
  readonly clearbit_last_updated_at?: InputMaybe<order_by>;
  readonly clearbit_raw?: InputMaybe<order_by>;
  readonly client_id?: InputMaybe<order_by>;
  readonly corporate_entity?: InputMaybe<order_by>;
  readonly country?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly credit_limit?: InputMaybe<order_by>;
  readonly credit_limit_expires_at?: InputMaybe<order_by>;
  readonly credit_limit_notes?: InputMaybe<order_by>;
  readonly csv_tasks_for_vendor?: InputMaybe<csv_tasks_for_vendors_order_by>;
  readonly custom_fields?: InputMaybe<order_by>;
  readonly customers_aggregate?: InputMaybe<customers_aggregate_order_by>;
  readonly data_sources_aggregate?: InputMaybe<data_sources_aggregate_order_by>;
  readonly ein?: InputMaybe<order_by>;
  readonly events_summary?: InputMaybe<vendor_events_summary_order_by>;
  readonly has_uploaded_accounting_csv?: InputMaybe<order_by>;
  readonly has_uploaded_billing_manager_csv?: InputMaybe<order_by>;
  readonly investor_asset_receipts_aggregate?: InputMaybe<investor_asset_receipts_aggregate_order_by>;
  readonly is_activated?: InputMaybe<order_by>;
  readonly is_additional_accounting_link_required?: InputMaybe<order_by>;
  readonly is_additional_bank_link_required?: InputMaybe<order_by>;
  readonly is_additional_billing_manager_required?: InputMaybe<order_by>;
  readonly is_declined?: InputMaybe<order_by>;
  readonly is_on_hold?: InputMaybe<order_by>;
  readonly last_rated_at?: InputMaybe<order_by>;
  readonly lead_status?: InputMaybe<order_by>;
  readonly legal_entities_aggregate?: InputMaybe<legal_entities_aggregate_order_by>;
  readonly lists_aggregate?: InputMaybe<lists_aggregate_order_by>;
  readonly live_ratings_application_enabled?: InputMaybe<order_by>;
  readonly live_ratings_generation_disabled?: InputMaybe<order_by>;
  readonly logo_url?: InputMaybe<order_by>;
  readonly main_contact_email?: InputMaybe<order_by>;
  readonly main_contact_first_name?: InputMaybe<order_by>;
  readonly main_contact_last_name?: InputMaybe<order_by>;
  readonly main_contact_phone?: InputMaybe<order_by>;
  readonly main_contact_title?: InputMaybe<order_by>;
  readonly manual_data_sources_aggregate?: InputMaybe<manual_data_sources_aggregate_order_by>;
  readonly max_contract_term_months?: InputMaybe<order_by>;
  readonly min_bank_balance?: InputMaybe<order_by>;
  readonly min_customer_retention_percent?: InputMaybe<order_by>;
  readonly min_revenue_retention_percent?: InputMaybe<order_by>;
  readonly min_runway_months?: InputMaybe<order_by>;
  readonly modern_treasury_counterparty_id?: InputMaybe<order_by>;
  readonly name?: InputMaybe<order_by>;
  readonly onboarding_triggered_at?: InputMaybe<order_by>;
  readonly payment_method?: InputMaybe<accounts_order_by>;
  readonly payment_status?: InputMaybe<vendor_payment_status_order_by>;
  readonly payout_method?: InputMaybe<accounts_order_by>;
  readonly pipe_agreements_aggregate?: InputMaybe<pipe_agreements_aggregate_order_by>;
  readonly pipe_proposals_aggregate?: InputMaybe<pipe_proposals_aggregate_order_by>;
  readonly pipeline_state?: InputMaybe<order_by>;
  readonly plaid_items_aggregate?: InputMaybe<plaid_items_aggregate_order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly rate_months_1?: InputMaybe<order_by>;
  readonly rating?: InputMaybe<order_by>;
  readonly reported_estimated_arr?: InputMaybe<order_by>;
  readonly revenue_stream_inference_disabled?: InputMaybe<order_by>;
  readonly revenue_streams_aggregate?: InputMaybe<revenue_streams_aggregate_order_by>;
  readonly sales_funnel_stage?: InputMaybe<order_by>;
  readonly score?: InputMaybe<order_by>;
  readonly signup_source?: InputMaybe<order_by>;
  readonly snapshots_aggregate?: InputMaybe<snapshots_aggregate_order_by>;
  readonly state?: InputMaybe<order_by>;
  readonly state_incorporated?: InputMaybe<order_by>;
  readonly updated_at?: InputMaybe<order_by>;
  readonly uploaded_vendor_data_aggregate?: InputMaybe<uploaded_vendor_data_aggregate_order_by>;
  readonly users_aggregate?: InputMaybe<users_aggregate_order_by>;
  readonly vendor_active_ratings_aggregate?: InputMaybe<vendor_active_ratings_aggregate_order_by>;
  readonly vendor_bank_balances_aggregate?: InputMaybe<vendor_bank_balances_aggregate_order_by>;
  readonly vendor_current_sales_funnel_stage?: InputMaybe<vendor_current_sales_funnel_stage_order_by>;
  readonly vendor_custom_fields_aggregate?: InputMaybe<vendor_custom_fields_aggregate_order_by>;
  readonly vendor_metric_jobs_aggregate?: InputMaybe<vendor_metric_jobs_aggregate_order_by>;
  readonly vendor_metrics_aggregate?: InputMaybe<vendor_metrics_aggregate_order_by>;
  readonly vendor_metrics_latest?: InputMaybe<vendor_metrics_latest_order_by>;
  readonly vendor_payments_aggregate?: InputMaybe<vendor_payments_aggregate_order_by>;
  readonly vendor_ratings_metadata_aggregate?: InputMaybe<vendor_ratings_metadata_aggregate_order_by>;
  readonly vendor_supported_currencies_aggregate?: InputMaybe<vendor_supported_currencies_aggregate_order_by>;
  readonly vendor_trades?: InputMaybe<vendor_trades_order_by>;
  readonly vendor_treasury_accounts_aggregate?: InputMaybe<vendor_treasury_accounts_aggregate_order_by>;
  readonly vendor_visit_summary?: InputMaybe<vendor_visit_summary_order_by>;
  readonly website?: InputMaybe<order_by>;
  readonly zip?: InputMaybe<order_by>;
};

/** select columns of table "vendors" */
export enum vendors_select_column {
  /** column name */
  address1 = 'address1',
  /** column name */
  address2 = 'address2',
  /** column name */
  automated_payments_enabled = 'automated_payments_enabled',
  /** column name */
  can_trade = 'can_trade',
  /** column name */
  city = 'city',
  /** column name */
  clearbit_last_updated_at = 'clearbit_last_updated_at',
  /** column name */
  clearbit_raw = 'clearbit_raw',
  /** column name */
  client_id = 'client_id',
  /** column name */
  corporate_entity = 'corporate_entity',
  /** column name */
  country = 'country',
  /** column name */
  created_at = 'created_at',
  /** column name */
  credit_limit = 'credit_limit',
  /** column name */
  credit_limit_expires_at = 'credit_limit_expires_at',
  /** column name */
  credit_limit_notes = 'credit_limit_notes',
  /** column name */
  custom_fields = 'custom_fields',
  /** column name */
  ein = 'ein',
  /** column name */
  has_uploaded_accounting_csv = 'has_uploaded_accounting_csv',
  /** column name */
  has_uploaded_billing_manager_csv = 'has_uploaded_billing_manager_csv',
  /** column name */
  is_activated = 'is_activated',
  /** column name */
  is_additional_accounting_link_required = 'is_additional_accounting_link_required',
  /** column name */
  is_additional_bank_link_required = 'is_additional_bank_link_required',
  /** column name */
  is_additional_billing_manager_required = 'is_additional_billing_manager_required',
  /** column name */
  is_declined = 'is_declined',
  /** column name */
  is_on_hold = 'is_on_hold',
  /** column name */
  last_rated_at = 'last_rated_at',
  /** column name */
  lead_status = 'lead_status',
  /** column name */
  live_ratings_application_enabled = 'live_ratings_application_enabled',
  /** column name */
  live_ratings_generation_disabled = 'live_ratings_generation_disabled',
  /** column name */
  logo_url = 'logo_url',
  /** column name */
  main_contact_email = 'main_contact_email',
  /** column name */
  main_contact_first_name = 'main_contact_first_name',
  /** column name */
  main_contact_last_name = 'main_contact_last_name',
  /** column name */
  main_contact_phone = 'main_contact_phone',
  /** column name */
  main_contact_title = 'main_contact_title',
  /** column name */
  max_contract_term_months = 'max_contract_term_months',
  /** column name */
  min_bank_balance = 'min_bank_balance',
  /** column name */
  min_customer_retention_percent = 'min_customer_retention_percent',
  /** column name */
  min_revenue_retention_percent = 'min_revenue_retention_percent',
  /** column name */
  min_runway_months = 'min_runway_months',
  /** column name */
  modern_treasury_counterparty_id = 'modern_treasury_counterparty_id',
  /** column name */
  name = 'name',
  /** column name */
  onboarding_triggered_at = 'onboarding_triggered_at',
  /** column name */
  pipeline_state = 'pipeline_state',
  /** column name */
  public_id = 'public_id',
  /** column name */
  rate_months_1 = 'rate_months_1',
  /** column name */
  rating = 'rating',
  /** column name */
  reported_estimated_arr = 'reported_estimated_arr',
  /** column name */
  revenue_stream_inference_disabled = 'revenue_stream_inference_disabled',
  /** column name */
  sales_funnel_stage = 'sales_funnel_stage',
  /** column name */
  score = 'score',
  /** column name */
  signup_source = 'signup_source',
  /** column name */
  state = 'state',
  /** column name */
  state_incorporated = 'state_incorporated',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  website = 'website',
  /** column name */
  zip = 'zip'
}

/** input type for updating data in table "vendors" */
export type vendors_set_input = {
  readonly automated_payments_enabled?: InputMaybe<Scalars['Boolean']>;
  readonly can_trade?: InputMaybe<Scalars['Boolean']>;
  readonly is_activated?: InputMaybe<Scalars['Boolean']>;
  readonly is_additional_accounting_link_required?: InputMaybe<Scalars['Boolean']>;
  readonly is_additional_bank_link_required?: InputMaybe<Scalars['Boolean']>;
  readonly is_additional_billing_manager_required?: InputMaybe<Scalars['Boolean']>;
  readonly is_on_hold?: InputMaybe<Scalars['Boolean']>;
  readonly live_ratings_application_enabled?: InputMaybe<Scalars['Boolean']>;
  readonly live_ratings_generation_disabled?: InputMaybe<Scalars['Boolean']>;
  readonly signup_source?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type vendors_stddev_fields = {
  readonly __typename?: 'vendors_stddev_fields';
  readonly credit_limit?: Maybe<Scalars['Float']>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: Maybe<Scalars['Float']>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: Maybe<Scalars['Float']>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: Maybe<Scalars['Float']>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: Maybe<Scalars['Float']>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: Maybe<Scalars['Float']>;
  readonly rate_months_1?: Maybe<Scalars['Float']>;
  readonly score?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vendors" */
export type vendors_stddev_order_by = {
  readonly credit_limit?: InputMaybe<order_by>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: InputMaybe<order_by>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: InputMaybe<order_by>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: InputMaybe<order_by>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: InputMaybe<order_by>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: InputMaybe<order_by>;
  readonly rate_months_1?: InputMaybe<order_by>;
  readonly score?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type vendors_stddev_pop_fields = {
  readonly __typename?: 'vendors_stddev_pop_fields';
  readonly credit_limit?: Maybe<Scalars['Float']>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: Maybe<Scalars['Float']>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: Maybe<Scalars['Float']>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: Maybe<Scalars['Float']>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: Maybe<Scalars['Float']>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: Maybe<Scalars['Float']>;
  readonly rate_months_1?: Maybe<Scalars['Float']>;
  readonly score?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vendors" */
export type vendors_stddev_pop_order_by = {
  readonly credit_limit?: InputMaybe<order_by>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: InputMaybe<order_by>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: InputMaybe<order_by>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: InputMaybe<order_by>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: InputMaybe<order_by>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: InputMaybe<order_by>;
  readonly rate_months_1?: InputMaybe<order_by>;
  readonly score?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type vendors_stddev_samp_fields = {
  readonly __typename?: 'vendors_stddev_samp_fields';
  readonly credit_limit?: Maybe<Scalars['Float']>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: Maybe<Scalars['Float']>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: Maybe<Scalars['Float']>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: Maybe<Scalars['Float']>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: Maybe<Scalars['Float']>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: Maybe<Scalars['Float']>;
  readonly rate_months_1?: Maybe<Scalars['Float']>;
  readonly score?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vendors" */
export type vendors_stddev_samp_order_by = {
  readonly credit_limit?: InputMaybe<order_by>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: InputMaybe<order_by>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: InputMaybe<order_by>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: InputMaybe<order_by>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: InputMaybe<order_by>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: InputMaybe<order_by>;
  readonly rate_months_1?: InputMaybe<order_by>;
  readonly score?: InputMaybe<order_by>;
};

/** aggregate sum on columns */
export type vendors_sum_fields = {
  readonly __typename?: 'vendors_sum_fields';
  readonly credit_limit?: Maybe<Scalars['bigint']>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: Maybe<Scalars['Int']>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: Maybe<Scalars['bigint']>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: Maybe<Scalars['numeric']>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: Maybe<Scalars['numeric']>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: Maybe<Scalars['Int']>;
  readonly rate_months_1?: Maybe<Scalars['Int']>;
  readonly score?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "vendors" */
export type vendors_sum_order_by = {
  readonly credit_limit?: InputMaybe<order_by>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: InputMaybe<order_by>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: InputMaybe<order_by>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: InputMaybe<order_by>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: InputMaybe<order_by>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: InputMaybe<order_by>;
  readonly rate_months_1?: InputMaybe<order_by>;
  readonly score?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type vendors_var_pop_fields = {
  readonly __typename?: 'vendors_var_pop_fields';
  readonly credit_limit?: Maybe<Scalars['Float']>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: Maybe<Scalars['Float']>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: Maybe<Scalars['Float']>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: Maybe<Scalars['Float']>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: Maybe<Scalars['Float']>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: Maybe<Scalars['Float']>;
  readonly rate_months_1?: Maybe<Scalars['Float']>;
  readonly score?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vendors" */
export type vendors_var_pop_order_by = {
  readonly credit_limit?: InputMaybe<order_by>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: InputMaybe<order_by>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: InputMaybe<order_by>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: InputMaybe<order_by>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: InputMaybe<order_by>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: InputMaybe<order_by>;
  readonly rate_months_1?: InputMaybe<order_by>;
  readonly score?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type vendors_var_samp_fields = {
  readonly __typename?: 'vendors_var_samp_fields';
  readonly credit_limit?: Maybe<Scalars['Float']>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: Maybe<Scalars['Float']>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: Maybe<Scalars['Float']>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: Maybe<Scalars['Float']>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: Maybe<Scalars['Float']>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: Maybe<Scalars['Float']>;
  readonly rate_months_1?: Maybe<Scalars['Float']>;
  readonly score?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vendors" */
export type vendors_var_samp_order_by = {
  readonly credit_limit?: InputMaybe<order_by>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: InputMaybe<order_by>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: InputMaybe<order_by>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: InputMaybe<order_by>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: InputMaybe<order_by>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: InputMaybe<order_by>;
  readonly rate_months_1?: InputMaybe<order_by>;
  readonly score?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type vendors_variance_fields = {
  readonly __typename?: 'vendors_variance_fields';
  readonly credit_limit?: Maybe<Scalars['Float']>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: Maybe<Scalars['Float']>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: Maybe<Scalars['Float']>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: Maybe<Scalars['Float']>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: Maybe<Scalars['Float']>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: Maybe<Scalars['Float']>;
  readonly rate_months_1?: Maybe<Scalars['Float']>;
  readonly score?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vendors" */
export type vendors_variance_order_by = {
  readonly credit_limit?: InputMaybe<order_by>;
  /** Maximum number of months a subcription can be piped */
  readonly max_contract_term_months?: InputMaybe<order_by>;
  /** Minimum bank balance in cents */
  readonly min_bank_balance?: InputMaybe<order_by>;
  /** Minimum customer retention percentage (0-100) */
  readonly min_customer_retention_percent?: InputMaybe<order_by>;
  /** Minimum revenue retention percentage (0-100) */
  readonly min_revenue_retention_percent?: InputMaybe<order_by>;
  /** Minimum number of months of runway */
  readonly min_runway_months?: InputMaybe<order_by>;
  readonly rate_months_1?: InputMaybe<order_by>;
  readonly score?: InputMaybe<order_by>;
};

/**
 * Browser visits
 *
 *
 * columns and relationships of "visits"
 */
export type visits = {
  readonly __typename?: 'visits';
  /** Unique identifier for the tracking cookie of the browser. (Sensitive!) */
  readonly cookie_tag: Scalars['String'];
  /** The start of the visit. */
  readonly created_at: Scalars['timestamptz'];
  /** An array relationship */
  readonly fingerprints_by_ip: ReadonlyArray<fingerprints>;
  /** An aggregate relationship */
  readonly fingerprints_by_ip_aggregate: fingerprints_aggregate;
  /** The IP address associated with the visit. */
  readonly ip: Scalars['String'];
  /** The last time the visitor made a request to the server. */
  readonly last_visited_at: Scalars['timestamptz'];
  readonly public_id: Scalars['String'];
  /** An object relationship */
  readonly user?: Maybe<users>;
};


/**
 * Browser visits
 *
 *
 * columns and relationships of "visits"
 */
export type visitsfingerprints_by_ipArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<fingerprints_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<fingerprints_order_by>>;
  where?: InputMaybe<fingerprints_bool_exp>;
};


/**
 * Browser visits
 *
 *
 * columns and relationships of "visits"
 */
export type visitsfingerprints_by_ip_aggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<fingerprints_select_column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ReadonlyArray<fingerprints_order_by>>;
  where?: InputMaybe<fingerprints_bool_exp>;
};

/** aggregated selection of "visits" */
export type visits_aggregate = {
  readonly __typename?: 'visits_aggregate';
  readonly aggregate?: Maybe<visits_aggregate_fields>;
  readonly nodes: ReadonlyArray<visits>;
};

/** aggregate fields of "visits" */
export type visits_aggregate_fields = {
  readonly __typename?: 'visits_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<visits_max_fields>;
  readonly min?: Maybe<visits_min_fields>;
};


/** aggregate fields of "visits" */
export type visits_aggregate_fieldscountArgs = {
  columns?: InputMaybe<ReadonlyArray<visits_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "visits" */
export type visits_aggregate_order_by = {
  readonly count?: InputMaybe<order_by>;
  readonly max?: InputMaybe<visits_max_order_by>;
  readonly min?: InputMaybe<visits_min_order_by>;
};

/** Boolean expression to filter rows from the table "visits". All fields are combined with a logical 'AND'. */
export type visits_bool_exp = {
  readonly _and?: InputMaybe<ReadonlyArray<visits_bool_exp>>;
  readonly _not?: InputMaybe<visits_bool_exp>;
  readonly _or?: InputMaybe<ReadonlyArray<visits_bool_exp>>;
  readonly cookie_tag?: InputMaybe<String_comparison_exp>;
  readonly created_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly fingerprints_by_ip?: InputMaybe<fingerprints_bool_exp>;
  readonly ip?: InputMaybe<String_comparison_exp>;
  readonly last_visited_at?: InputMaybe<timestamptz_comparison_exp>;
  readonly public_id?: InputMaybe<String_comparison_exp>;
  readonly user?: InputMaybe<users_bool_exp>;
};

/** aggregate max on columns */
export type visits_max_fields = {
  readonly __typename?: 'visits_max_fields';
  /** Unique identifier for the tracking cookie of the browser. (Sensitive!) */
  readonly cookie_tag?: Maybe<Scalars['String']>;
  /** The start of the visit. */
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  /** The IP address associated with the visit. */
  readonly ip?: Maybe<Scalars['String']>;
  /** The last time the visitor made a request to the server. */
  readonly last_visited_at?: Maybe<Scalars['timestamptz']>;
  readonly public_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "visits" */
export type visits_max_order_by = {
  /** Unique identifier for the tracking cookie of the browser. (Sensitive!) */
  readonly cookie_tag?: InputMaybe<order_by>;
  /** The start of the visit. */
  readonly created_at?: InputMaybe<order_by>;
  /** The IP address associated with the visit. */
  readonly ip?: InputMaybe<order_by>;
  /** The last time the visitor made a request to the server. */
  readonly last_visited_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type visits_min_fields = {
  readonly __typename?: 'visits_min_fields';
  /** Unique identifier for the tracking cookie of the browser. (Sensitive!) */
  readonly cookie_tag?: Maybe<Scalars['String']>;
  /** The start of the visit. */
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  /** The IP address associated with the visit. */
  readonly ip?: Maybe<Scalars['String']>;
  /** The last time the visitor made a request to the server. */
  readonly last_visited_at?: Maybe<Scalars['timestamptz']>;
  readonly public_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "visits" */
export type visits_min_order_by = {
  /** Unique identifier for the tracking cookie of the browser. (Sensitive!) */
  readonly cookie_tag?: InputMaybe<order_by>;
  /** The start of the visit. */
  readonly created_at?: InputMaybe<order_by>;
  /** The IP address associated with the visit. */
  readonly ip?: InputMaybe<order_by>;
  /** The last time the visitor made a request to the server. */
  readonly last_visited_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "visits". */
export type visits_order_by = {
  readonly cookie_tag?: InputMaybe<order_by>;
  readonly created_at?: InputMaybe<order_by>;
  readonly fingerprints_by_ip_aggregate?: InputMaybe<fingerprints_aggregate_order_by>;
  readonly ip?: InputMaybe<order_by>;
  readonly last_visited_at?: InputMaybe<order_by>;
  readonly public_id?: InputMaybe<order_by>;
  readonly user?: InputMaybe<users_order_by>;
};

/** select columns of table "visits" */
export enum visits_select_column {
  /** column name */
  cookie_tag = 'cookie_tag',
  /** column name */
  created_at = 'created_at',
  /** column name */
  ip = 'ip',
  /** column name */
  last_visited_at = 'last_visited_at',
  /** column name */
  public_id = 'public_id'
}
