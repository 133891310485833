import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import {
  IBorderStyles,
  IBoxStyles,
  IGridStyles,
  withBorderStyles,
  withBoxStyles,
  withGridStyles,
} from "~src/shared/helpers/sharedComponentProps";

export type ICheckboxProps = IGridStyles &
  IBoxStyles &
  IBorderStyles &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    label?: string;
    children?: React.ReactNode;
    danger?: boolean;
  } & React.RefAttributes<HTMLInputElement>;

const _Checkbox = React.forwardRef<HTMLInputElement, ICheckboxProps>(
  (
    {
      id,
      label,
      children,
      className,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      danger: _danger,
      ...inputProps
    }: ICheckboxProps,
    ref,
  ) => {
    return (
      <label htmlFor={id} className={className}>
        <input ref={ref} type="checkbox" id={id} {...inputProps} />
        <span>{children ?? label}</span>
      </label>
    );
  },
);
_Checkbox.displayName = "Checkbox";

export const Checkbox = styled(_Checkbox)<ICheckboxProps>`
  display: flex;
  align-items: center;
  position: relative;
  ${(props) => withGridStyles(props)};
  ${(props) => withBoxStyles(props)};
  ${(props) => withBorderStyles(props)};

  cursor: ${(props) => (props.disabled === true ? "not-allowed" : "pointer")};
  & > span {
    margin-left: 10px;
  }
  input[type="checkbox"] {
    /* Without this property, the checkbox styles don't work on Firefox. */
    -moz-appearance: initial;

    width: 0;
    height: 0;
    margin-right: 15px;
    margin-bottom: 15px;
    position: relative;
    cursor: pointer;
    /* background */
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 15px;
      height: 15px;
      top: 0;
      left: 0;
      border: 1px solid ${(props) => props.theme.components.Checkbox.unchecked.border.normal};
      border-radius: 4px;
      background-color: ${(props) => props.theme.components.Checkbox.unchecked.background.normal};
      transition: all 0.2s ease;

      &:hover {
        background-color: ${(props) => props.theme.components.Checkbox.unchecked.background.hover};
      }
    }
    &:checked:before {
      ${(props) =>
        props.danger === true
          ? css`
              background-color: #b63831;
              border-color: #b63831;
            `
          : css`
              background-color: ${props.theme.components.Checkbox.checked.background.normal};
              border-color: ${props.theme.components.Checkbox.checked.border.normal};
            `}
    }
    &:disabled {
      cursor: not-allowed;
    }
    &:disabled:before {
      background-color: ${(props) => props.theme.components.Checkbox.unchecked.background.disabled};
      border-color: ${(props) => props.theme.components.Checkbox.unchecked.border.disabled};
    }
    /* checkmark */
    &:checked:after {
      content: "";
      display: block;
      width: 5px;
      height: 8px;
      border: solid ${(props) => props.theme.components.Checkbox.checked.check.normal};
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 3px;
      left: 5px;
    }
  }
`;
